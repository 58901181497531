//Library imports
import React from 'react'
import {Box, Layer, Text, Image, Button} from 'grommet'
import styled from 'styled-components'
import {removeApplicant} from 'clientloandetail/controllers/loan'

const LayerStyle = styled(Layer)`
  width: 35%;
`

const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`
const ApplicantUnLink = ({
  event,
  route,
  applicantwarningpopup,
  setapplicantwarningpopup,
  setselectedapplicanttoremove,
  selectedapplicanttoremove,
  applicants,
  setToastData,
  setToastWarning,
}) => {
  const handleCancel = () => {
    setapplicantwarningpopup(!applicantwarningpopup)
  }

  const handleremoveapplicant = () => {
    if (applicants.length > 1) {
      removeApplicant(
        event,
        selectedapplicanttoremove.applicant_mail_pk,
        route.params.id,
        selectedapplicanttoremove.pk,
      )
    } else {
      setToastWarning(true)
      setToastData({
        open: true,
        message: "More than one applicants must be associated with the loan to remove the applicant",
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    }
  }

  return (
    <Box>
      <LayerStyle
        position="center"
        onEsc={() => setapplicantwarningpopup(false)}
      >
        <Box pad={{bottom: 'small'}}>
          <Box width="100%" height="100%">
            <MoreDetailsWrapper direction="row" justify="between">
              <MoreDetailsTextWrapper
                color="dark-6"
                size="16px"
                margin={{left: '20px', top: '15px', bottom: '15px'}}
              >
                {'Remove Applicant'}
              </MoreDetailsTextWrapper>

              <Button
                margin={{right: 'small', bottom: 'small'}}
                icon={
                  <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                }
                onClick={() => setapplicantwarningpopup(!applicantwarningpopup)}
              />
            </MoreDetailsWrapper>

            <Box
              margin={{
                top: 'small',
                bottom: 'small',
                left: 'small',
                right: 'small',
              }}
            >
              <Box
                margin={{
                  top: 'small',
                  left: 'small',
                  right: 'small',
                  bottom: 'small',
                }}
                direction="column"
                gap="medium"
                pad={{bottom: 'medium'}}
              >
                <Text>
                  {
                    'Are you sure you want to remove the applicant from this loan.'
                  }
                </Text>
              </Box>

              <Box
                pad={{right: 'small'}}
                gap="medium"
                fill="horizontal"
                direction="row"
                elevation="none"
                justify="end"
                margin={{top: 'medium'}}
              >
                <Button
                  type="submit"
                  alignSelf="end"
                  label={'Remove Applicant'}
                  onClick={() => handleremoveapplicant()}
                  primary
                />
                <Button
                  onClick={() => handleCancel()}
                  label={'Cancel'}
                  primary
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </LayerStyle>
    </Box>
  )
}

export default ApplicantUnLink
