class OverDueALR {
  constructor(item) {
    const OutcomeVAlues = {
      1: '--No Changes--',
      2: '$Money saved, no further action',
      3: '$!Action required',
      4: '$!No money saved, action recquired',
    }

    this.main_pk = item.pk
    this.pk = item.pk
    this.loanpk = item.loan.pk
    this.applicant_pk = item.loan.applicant ? item.loan.applicant.pk : ''
    this.name = item.loan.applicant ? item.loan.applicant.name : ''
    this.email = item.loan.applicant ? item.loan.applicant.email : ''
    this.phone = item.loan.applicant ? item.loan.applicant.phone : ''
    this.balance = item.loan.balance
    this.outcome = OutcomeVAlues[item.loan.out_come]
    this.date = item.modified_date
  }
}

export default OverDueALR
