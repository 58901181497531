//Library imports
import React, {useState} from 'react'
import {Box, Image, Text, TextInput, Button} from 'grommet'
import {useHistory, useLocation} from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'
import LoaderUI from 'granite/components/NumLoader'

//Application imports
// import {ExportData} from 'clientlist/controllers/client'

const TableWrapper = styled(Box)`
  overflow-x: auto;
`

const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
`
const ButtonStyleCommon = styled(Button)`
  height: 36px;
  width: 110px;
`

const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius: 0px 4px 4px 0px;
  padding-left: 14px !important;
  top: 115px;
  left: 237px;
  width: 500px;
  height: 38px;
`

const ButtonStyle = styled(Button)`
  height: 38px;
  width: 110px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 0px 4px 4px 0px;
  padding: 0px !important;
  margin: 0px !important;
`

const COLUMNS = [
  {
    accessor: `name`,
    Header: 'Name',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'phone',
    Header: 'Phone No.',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'email',
    Header: 'Email Address',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="blue-email"
        />
      )
    },
  },

  {
    accessor: 'client_type',
    Header: 'Client Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'company_name',
    Header: 'Company Name',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'company_ABN',
    Header: 'ABN',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  // {
  //   accessor: 'review_cycle',
  //   Header: 'Review Cycle',
  //   Cell: ({cell: {value}, ...rest}) => {
  //     return (
  //       <TextCell
  //         {...rest}
  //         data={value}
  //         editMode={false}
  //         size="14px"
  //         color="dark-6"
  //       />
  //     )
  //   },
  // },
  // {
  //   accessor: 'pk',
  //   Header: 'Client Detail',
  //   Cell: ({cell: {value, row, column}, updateCellData, ...rest}) => {
  //     return (
  //       <TextCell
  //         {...rest}
  //         data={
  //           <Image
  //             alt="logo"
  //             height="14px"
  //             src={process.env.PUBLIC_URL + '/Images/goto.svg'}
  //           />
  //         }
  //         editMode={false}
  //         color="text-2"
  //       />
  //     )
  //   },
  // },
]

const OALRTable = ({
  tableData,
  event,
  page,
  size,
  rows,
  setPage,
  setSize,
  eventEmitter,
  loader,
  setLoader,
  navigateToParams,
  permissions,
}) => {
  const history = useHistory()
  const [value, setValue] = useState('')
  const location = useLocation()
  const handleSearch = () => {
    if (value.length > 0) {
      setLoader(true)
      navigateToParams({page: 1, page_size: 20, search: value})
    }
  }

  const handleSearchCancel = () => {
    setLoader(true)
    setValue('')
    navigateToParams({page: 1, page_size: 20, search: null})
  }
  // const handleExport = async () => {
  //   ExportData(eventEmitter)
  // }

  const handleImport = () => {
    history.push('/clients_import')
  }
  const [data, setData] = useState([
    {
      main_pk: 1,
      pk: 1,
      name: 'Cusotmer',
      email: '',
      phone: '',
      balance: 0,
      outcome: '1',
      date: '15-Apr-2020',
    },
  ])
  const handlePagination = val => {
    setLoader(true)
    let params = {}
    params['page'] = val.page
    params['page_size'] = val.size
    navigateToParams({page: val.page, page_size: val.size})
  }

  const updateCellData = (rowId, column, value) => {
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  const handlecolumnClick = cell => {
    history.push('/clients/' + cell.row.original.pk)
  }

  const handleSort = sortOrder => {
    if (!sortOrder.length) navigateToParams({ordering: null})
    else {
      const [{id, desc}] = sortOrder
      setLoader(true)
      navigateToParams({ordering: desc ? '-' + id : id})
    }
  }

  const handleAdd = () => {
    history.push('/clients/add')
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  return (
    <Box
      pad={{
        top: 'none',
        bottom: 'none',
        left: 'medium',
        right: 'medium',
      }}
    >
      <Box
        direction="row"
        width="100%"
        gap="medium"
        pad={{
          top: 'small',
          bottom: 'medium',
          left: 'none',
          right: 'none',
        }}
      >
        <Box width="60%" align="end" gap="medium" direction="row">
          <SearchBoxWrapper
            width="380px"
            direction="row"
            height="36px"
            align="end"
            background="light-1"
          >
            <TextInputWrapper
              placeholder="Name, Phone No., Email Search"
              value={value}
              onChange={event => {
                setValue(event.target.value)
              }}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleSearch()
                }
              }}
            />
            <Button
              alignSelf="center"
              icon={
                <Image
                  src={process.env.PUBLIC_URL + '/img/search_cancel.png'}
                  fit="contain"
                />
              }
              onClick={handleSearchCancel}
            />
            <ButtonStyle
              onClick={handleSearch}
              type="submit"
              label={'Search'}
              primary
            />
          </SearchBoxWrapper>
        </Box>

        <Box align="end" width="40%">
          <Box
            direction="row"
            gap="medium"
            pad={{
              top: 'small',
              bottom: 'none',
              left: 'none',
              right: 'none',
            }}
          >
            <Box elevation="medium">
              {permissions !== null && permissions.includes('note_add') && (
                <ButtonStyleCommon
                  label="Add"
                  onClick={() => {
                    handleAdd()
                  }}
                  primary
                />
              )}
            </Box>
            <Box elevation="medium">
              {permissions !== null && permissions.includes('import') && (
                <ButtonStyleCommon
                  label="Import"
                  onClick={() => {
                    handleImport()
                  }}
                  primary
                />
              )}
            </Box>
            {/* <Box elevation="medium">
              {permissions !== null && permissions.includes('export') && (
                <Button
                  type="submit"
                  label={'Export'}
                  onClick={handleExport}
                  primary
                />
              )}
            </Box> */}
          </Box>
        </Box>
      </Box>

      {loader ? (
        <Box
          background="brand-light"
          width="100%"
          align="center"
          justify="center"
          height="100%"
        >
          <LoaderUI />
        </Box>
      ) : (
        <Box>
          <TableWrapper>
            <ReactTable
              initialSortOrder={getInitialSorting()}
              data={tableData}
              columns={COLUMNS}
              onSortChange={handleSort}
              updateCellData={updateCellData}
              rowClickHandler={row => {}}
              columnClickHandler={cell => handlecolumnClick(cell)}
            />
          </TableWrapper>
        </Box>
      )}
      <Box>
        {tableData.length > 0 ? (
          <Box direction="row" width="100%" margin={{top: 'medium'}}>
            <Box width="50%" alignContent="end" align="start">
              {/* <Text color="dark-6">
                Page {page} out of {Math.ceil(rows / size)}
              </Text> */}
              <Text color="dark-6">
                {rows < size
                  ? rows + ' out of ' + rows
                  : tableData.length + ' out of ' + rows}
              </Text>
            </Box>
            <Box width="50%" alignContent="end" align="end">
              {tableData.length > 0 ? (
                <Pagination
                  onPageChange={val => {
                    async function sample() {
                      setValue('')
                      setPage(val.page)
                      setSize(val.size)
                      await handlePagination(val)
                    }
                    sample()
                  }}
                  showSizeChanger
                  select={true}
                  pageData={{page: page, size: size, total: rows}}
                />
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}

export default OALRTable
