class LoanDetails {
  constructor({
    pk,
    loan,
    applicant,
    lender,
    product,
    new_rate,
    modified_date,
    status,
    savings,
  }) {
    this.pk = pk

    this.loan_pk = loan.pk

    this.loan_purpose = loan.loan_purpose
    this.loan_term = loan.term
    this.loan_settlement_date = loan.settlement_date
    this.loan_limit = loan.loan_limit

    this.balance = loan.balance
    this.loan_reply_choice = loan.reply_choice
    this.loan_rate_type_choice = loan.rate_type_choice

    this.loan_out_come = loan.out_come
    this.loan_package = loan.package
    this.applicant_pk = loan.applicant ? loan.applicant.pk : ''
    this.name = loan.applicant ? loan.applicant.name : ''
    this.first_name = loan.applicant ? loan.applicant.first_name : ''
    this.last_name = loan.applicant ? loan.applicant.last_name : ''
    this.applicant_email = loan.applicant ? loan.applicant.email : ''
    this.applicant_phone = loan.applicant ? loan.applicant.phone : ''
    this.lender_pk = loan.lender.pk

    this.lender = loan.lender.name
    this.product_name = loan.product.name
    this.product_pk = loan.product.pk
    this.product_expiry = loan.product.expiry
    this.status = status

    this.new_rate = parseFloat(loan.new_rate).toFixed(2)
    this.difference = new_rate - loan.rate
    this.lender_bsb_code = loan.lender.bsb_code
    this.modified_date = modified_date

    this.new_discount = loan.new_discount
    this.fixed_expiry = loan.fixed_expiry
    this.io_expiry = loan.io_expiry
    this.account_number = loan.account_number
    this.account_bsb_no = `${loan.account_number} / ${loan.lender.bsb_code}`

    this.discount = {
      current_discount: parseFloat(loan.discount),
      difference: parseFloat(loan.new_discount - loan.discount).toFixed(1),
    }
    this.io_expiry = loan.io_expiry
    // this.savings = savings.toString().includes('-')
    //   ? '-$' + savings.toString().replace('-', '')
    //   : '$' + savings.toString()
    this.savings = savings

    this.purpose = loan.purpose
    if (loan.out_come === '1') this.loan_out_come = '--No Changes--'
    else if (loan.out_come === '2')
      this.loan_out_come = '$!Money saved, no further action'
    else if (loan.out_come === '3')
      this.loan_out_come = '$!Money saved, further action required'
    else if (loan.out_come === '4')
      this.loan_out_come = '$!No money saved, further action required'

    if (loan.loan_status === '1') this.loan_status = 'Variation'
    else if (loan.loan_status === '2') this.loan_status = 'No Action'
    else if (loan.loan_status === '3') this.loan_status = 'Broker Review'

    this.repayment_type = loan.repayment_type
    this.repayment_choice = loan.repayment_choice
    if (this.purpose === 'INV') {
      if (this.repayment_type === 'P&I') this.selected_risk = 'inv_p_and_i'
      else if (
        this.repayment_type === 'IO' ||
        this.repayment_type === 'IO in Adv'
      )
        this.selected_risk = 'inv_io'
    } else if (this.purpose === 'OO') {
      if (this.repayment_type === 'P&I') this.selected_risk = 'oop_and_i'
      else if (
        this.repayment_type === 'IO' ||
        this.repayment_type === 'IO in Adv'
      )
        this.selected_risk = 'oo_io'
    }
    this.rate = {
      current_rate: parseFloat(loan.rate),
      difference: undefined,
      selected_risk: this.selected_risk,
      new_rate: loan.new_rate,
    }

    const GenerateString = data => {
      let temp_arr = []
      data.forEach(element => {
        let state = element.state ? statesAbbr[element.state] : ''
        let addr =
          element.address_line_1 +
          ' ' +
          element.address_line_2 +
          ' ' +
          element.city +
          ' ' +
          state +
          ' ' +
          element.postcode
        temp_arr.push(addr)
      })
      return temp_arr.join('\r\n\n')
    }

    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }
    
    this.security = GenerateString(loan.security)
    this.loan_loan_status = loan.status
  }
}

export default LoanDetails
