const LOGIN_MESSAGES = {
  INVALID_CREDENTIALS: 'Email & Password are required.',
  LOGIN_SUCCESS: 'Logged in successfully',
  LOGIN_FAILURE: "Email and Password don't match",
  EMAIL_TOO_SHORT: 'Too Short',
  EMAIL_TOO_LONG: 'Too Long',
  EMAIL_INVALID: 'Invalid Email',
  EMAIL_REQUIRED: 'Email is required',
  PASS_TOO_SHORT: 'Password is too short - should be 6 chars minimum.',
  PASS_TOO_LONG: 'Too Long!',
  PASS_REQUIRED: 'Password is required',
  EMAIL_LABEL: 'Email Address',
  PASS_LABEL: 'Password',
  EMAIL_PLACEHOLDER: 'Email',
  PASS_PLACEHOLDER: 'Password',
  SUBMIT_BUTTON_LABEL_SUBMITTING: 'Sign in...',
  SUBMIT_BUTTON_LABEL: 'Login',
  FORGOT_PASS_LINK_LABEL: 'Forgot password?',
  REGISTER_LINK_LABEL: 'Create new account',
}

export default LOGIN_MESSAGES
