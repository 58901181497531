import React, {useState} from 'react'
import {useLocation} from 'react-router-dom'
import styled from 'styled-components'
import {Box, Button, Text, CheckBox, ThemeContext, Image} from 'grommet'
import {CurrencyToInt} from 'granite/CurrencyConvertor'

import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'

import {addLoansToCampaign} from 'apiriskreportsettings/controllers/riskreportcontroller'
import {showMoreDetails} from 'alr/controllers/loanReview'
import queryString from 'query-string'

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 210px;
`

const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}

const TableWrapper = styled(Box)`
  overflow-x: auto;
`

const COLUMNS = [
  {
    accessor: 'selection',
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          {true && <CheckBox {...getToggleAllRowsSelectedProps()} />}
        </ThemeContext.Extend>
      </span>
    ),
    Cell: ({row}) => (
      <span title={'Select'}>
        <ThemeContext.Extend value={bodyValue}>
          {row.original.is_active && !row.original.is_current_campaign && (
            <CheckBox {...row.getToggleRowSelectedProps()} />
          )}
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'name',
    Header: 'Clients',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" gap="small">
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  handleApplicant(row.original.loan_pk)
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/more_info.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
              <Box>
                <Text size="14px">{value}</Text>
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'lender',
    Header: 'Lender',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'account',
    Header: 'Account No',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'rate_type_choice',
    Header: 'Rate Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'purpose',
    Header: 'Purpose',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'repayment_type',
    Header: 'Repayment Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'loan_limit',
    Header: 'Loan Limit',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'balance',
    Header: 'Loan Balance',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    //  disableSortBy: true,
  },
  {
    accessor: 'io_expiry',
    Header: 'Interest Only Expiry',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'fixed_term_expiry',
    Header: 'Fixed Term Expiry',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'settlement_date',
    Header: 'Settlement Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'rate',
    Header: 'Current Interest',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value + '% p.a'}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  // {
  //   accessor: 'new_rate',
  //   Header: 'Rate',
  //   Cell: ({cell: {value}, ...rest}) => {
  //     return (
  //       <TextCell
  //         {...rest}
  //         data={value + '% p.a'}
  //         editMode={false}
  //         size="14px"
  //         color="dark-6"
  //       />
  //     )
  //   },
  //   disableSortBy: true,
  // },
]

const Table = ({
  tableData,
  navigateToParams,
  eventEmitter,
  campaignData,
  completeTableData,
  setLoader,
  setMoreDetailLayer,
  setMoreLoader,
  riekRate,
  setToastData,
  filterData: {page, page_size: size},
}) => {
  const total = completeTableData.rowsCount

  let location = useLocation()
  const {search} = location
  const [selected, setSelected] = useState([])

  const getInitialSorting = () => {
    const queryParams = queryString.parse(search)
    if (queryParams['sorting']) {
      let colName
      let desc
      if (queryParams['sorting'].indexOf('-') === 0) {
        colName = queryParams['sorting'].substring(1)
        desc = true
      } else {
        colName = queryParams['sorting'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSortChange = sortOrder => {
    if (!sortOrder.length) navigateToParams({sorting: null})
    else {
      setLoader(true)
      const [{id, desc}] = sortOrder
      navigateToParams({sorting: desc ? '-' + id : id})
    }
  }

  const handleApplicant = id => {
    showMoreDetails(eventEmitter, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }
  const handleAddLoan = () => {
    if (selected.length > 0) {
      const selectedLoans = selected.map(item => {
        if (item.is_active && !item.is_current_campaign) {
          const newItem = {
            new_rate: item.rate,
            loan: item.loan_pk,
            campaign_id: campaignData.pk,
            status: 'InProcess',
            balance: CurrencyToInt(item.balance),
          }
          return newItem
        }
        return null
      })
      var selectedLoansFiltered = selectedLoans.filter(function(x) {
        return x !== undefined && x !== null
      })

      setLoader(true)
      addLoansToCampaign(eventEmitter, selectedLoansFiltered)
    } else {
      setToastData({
        open: true,
        message: 'Please select loans to add.',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 2000)
    }
  }
  const handlePagination = ({page, size: page_size}) => {
    const queryParams = queryString.parse(search)
    setLoader(true)
    navigateToParams({
      page: page,
      page_size: page_size,
      inv_io: queryParams.inv_io,
      inv_p_and_i: queryParams.inv_p_and_i,
      oo_io: queryParams.oo_io,
      oop_and_i: queryParams.oop_and_i,
      rate_type_choice: queryParams.rate_type_choice,
      search: queryParams.search,
    })
  }

  return (
    <Box gap="medium" pad={{top: 'medium'}}>
      <Box
        gap="large"
        direction="row"
        justify="end"
        align="end"
        alignSelf="end"
        width="100%"
        margin={{top: 'medium', bottom: 'small'}}
      >
        <Box align="end" gap="medium" height="36px" direction="row">
          <ButtonStyle
            type="submit"
            alignSelf="end"
            label={'Add To Campaign'}
            primary
            disabled={false}
            onClick={handleAddLoan}
          />
        </Box>
      </Box>

      <Box elevation="medium" background="brand-light1">
        <TableWrapper>
          <ReactTable
            data={tableData}
            columns={COLUMNS}
            setSelected={setSelected}
            initialSortOrder={getInitialSorting()}
            onSortChange={onSortChange}
            handleApplicant={handleApplicant}
          />
        </TableWrapper>
      </Box>
      {tableData.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : tableData.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={tableData.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default Table
