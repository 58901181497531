class clientDetail {
  constructor(item) {
    const Client_Type_Mapping = {
      Individual: 'IN',
      Company: 'CO',
      INDIVIDUAL: 'IN',
      COMPANY: 'CO',
      IN: 'Individual',
      CO: 'Company',
    }
    this.pk = item.pk
    this.name = item.name
    this.first_name = item.first_name
    this.last_name = item.last_name
    this.email = item.email
    this.phone = item.phone.includes('+') ? item.phone :  item.phone.length > 0 ? '+61' + item.phone : item.phone
    this.pk_Update = item.pk
    this.company = item.name
    this.company_name = item.company_name
    this.company_ABN = item.company_abn
    this.client_type = Client_Type_Mapping[item.client_type]
  }
}

export default clientDetail
