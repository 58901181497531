//Library imports
import React from 'react'
import {Box, Text, Image} from 'grommet'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'
import queryString from 'query-string'

//Application imports
import CLIENT_LIST_MESSAGES from 'messages/ClientList'
import {sampleFileDownload} from 'clientlist/controllers/client'
import CUSTOMUPLOAD from './customUpload'
const TextStyle = styled(Text)`
  font-weight: 500;
`

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: auto;
`
const COLUMNS = [
  {
    accessor: 'original_file_name',
    Header: 'File Name',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: false,
  },
  {
    accessor: 'user_first_name',
    Header: 'Updated By',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: false,
  },
  {
    accessor: 'create_date',
    Header: 'Updated Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: false,
  },
  {
    accessor: 'import_status',
    Header: 'Status',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: false,
  },
]

const LoanBookTable = ({
  navigateToParams,
  setShowErroredTable,
  setShowLoanBookTable,
  setFileData,
  eventEmitter,
  data: {data, loan_total},
  filterData: {loan_page, loan_size},
  setloader,
  location,
  setToastData,
  setStatus,
}) => {
  const handlePagination = ({page, size}) => {
    navigateToParams({loan_page: page, loan_size: size})
  }

  const handleLoanBookRow = cell => {
    setStatus(cell.row.original.import_status.replace('_', ' '))
    setFileData(cell.row.original)
    setShowErroredTable(true)
    setShowLoanBookTable(false)
  }
  const history = useHistory()
  const handleBackButton = () => {
    history.push('/clients')
  }

  const handleDownload = () => {
    sampleFileDownload(eventEmitter)
  }

  const updateCellData = (rowId, column, value) => {}

  const handleRowClicked = row => {}

  const handleSort = sortOrder => {
    if (!sortOrder.length) navigateToParams({ordering: null})
    else {
      const [{id, desc}] = sortOrder

      navigateToParams({ordering: desc ? '-' + id : id})
    }
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  return (
    <Box>
      <Box direction="row" gap="small">
        <Box
          alignSelf="start"
          pad={{top: 'xxsmall'}}
          onClick={() => {
            handleBackButton()
          }}
        >
          <Image
            fit="contain"
            src={process.env.PUBLIC_URL + '/img/back.png'}
            style={{cursor: 'pointer'}}
          />
        </Box>
        <Box>
          <TextStyle size="18px" color="brand">
            {CLIENT_LIST_MESSAGES.IMPORT_RECORDS}
          </TextStyle>
          <Text size="10px" color="dark-1">
            {CLIENT_LIST_MESSAGES.IMPORT_LOAN_BOOK_RECORDS}
          </Text>
        </Box>
      </Box>
      <Box margin={{top: 'medium'}}>
        <Text size="12px" color="dark-1">
          {CLIENT_LIST_MESSAGES.BROWSE_FILE_PATH}
        </Text>
      </Box>
      <Box
        width="50%"
        gap="medium"
        direction="row"
        justify="between"
        margin={{top: 'small'}}
      >
        {
          <CUSTOMUPLOAD
            eventEmitter={eventEmitter}
            setloader={setloader}
            setToastData={setToastData}
          />
        }
      </Box>
      <Box
        margin={{top: 'xxsmall'}}
        alignSelf="start"
        onClick={() => handleDownload()}
      >
        <Text
          color="blue-email"
          style={{
            textDecoration: 'underline',
            fontSize: '10px',
          }}
        >
          Download Sample File
        </Text>
      </Box>

      <Box margin={{top: 'medium'}}>
        <BoxWrapper>
          <ReactTable
            initialSortOrder={getInitialSorting()}
            data={data}
            columns={COLUMNS} // pass in our table columns
            onSortChange={handleSort}
            updateCellData={updateCellData}
            rowClickHandler={row => handleRowClicked(row)}
            columnClickHandler={cell => handleLoanBookRow(cell)}
          />
        </BoxWrapper>
      </Box>

      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            <Text color="dark-6">
              {/* Page {loan_page} out of {Math.ceil(loan_total / loan_size)} */}
              {loan_total < loan_size ? loan_total + 'out of' + loan_total : data.length + 'out of' + loan_total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              select={true}
              pageData={{page: loan_page, size: loan_size, total: loan_total}}
              dataLength={data.length}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default LoanBookTable
