// Lib Imports
import React, {useState} from 'react'
import {Box, Button, Text, Heading} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'

// CORE Imports
import FormField from 'granite/components/FormField'
import PasswordInput from 'granite/components/PasswordInput'
import Tooltip from 'granite/components/Tooltip'
import SIGNUP_MESSAGES from 'messages/signup'

const ResetPasswordForm = ({onSubmit}) => {
  const [toolTip, setToolTip] = useState(false)
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .max(21, 'Too Long!')
      .matches(/[a-z]/, 'Must contain 1 small letter')
      .matches(/[A-Z]/, 'Must contain 1 capital Letter')
      .matches(/[0-9]/, 'Must contain 1 Numeric value')
      .matches(/[@$!%*#?&]/, 'Must contain 1 special character')
      .required('Password is required'),
    re_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must be same.')
      .required('Password is required'),
  })

  return (
    <Formik
      initialValues={{
        password: '',
        re_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {/** FIXME: Move strings to messages */}
          <Heading color="brand" level={1} margin={{bottom: 'small'}}>
            Set Login Password
          </Heading>
          <FormField
            name="password"
            label={SIGNUP_MESSAGES.NEW_PASS_LABEL}
            error={touched.password && errors.password}
          >
            <Tooltip
              content={
                <Box>
                  <Text
                    size="10px"
                    weight="bold"
                    margin={{top: 'small', left: 'small'}}
                  >
                    Password Guidelines:
                  </Text>
                  <Text size="9px" weight="normal" margin={{right: 'small'}}>
                    <ul>
                      <li>Password length must be 8-20 characters.</li>
                      <li>Must use atleast one number.</li>
                      <li>Must use one uppercase letter.</li>
                      <li>Must use one lowercase letter.</li>
                      <li>Must use one special character.</li>
                    </ul>
                  </Text>
                </Box>
              }
              align="right"
            >
              <PasswordInput
                placeholder={SIGNUP_MESSAGES.NEW_PASS_PLACEHOLDER}
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                onClick={() => setToolTip(!toolTip)}
                eyeVisible={true}
              />
            </Tooltip>
          </FormField>
          <FormField
            name="re_password"
            label={SIGNUP_MESSAGES.CONFIRM_PASS_LABEL}
            error={touched.re_password && errors.re_password}
          >
            <PasswordInput
              placeholder={SIGNUP_MESSAGES.CONFIRM_PASS_PLACEHOLDER}
              name="re_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.re_password}
              eyevisible={false}
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            pad="none"
            gap="xsmall"
          >
            <Button
              type="submit"
              alignSelf="end"
              margin={{vertical: 'small'}}
              label={
                isSubmitting
                  ? SIGNUP_MESSAGES.PASSWORD_SUBMIT_LABEL_SUBMITTING
                  : SIGNUP_MESSAGES.PASSWORD_SUBMIT_LABEL
              }
              disabled={isSubmitting}
              primary
            />
          </Box>
          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
