//Library Imports
import React from 'react'
import {Box, Image, Text, Button} from 'grommet'
import styled from 'styled-components'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'
import LoaderUI from 'granite/components/NumLoader'
import queryString from 'query-string'

//Application imports
import CLIENT_LIST_MESSAGES from 'messages/ClientList'
import {incorrectFileDownload} from 'clientlist/controllers/client'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: auto;
`

const BoxStyleInner = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px 0px 0px 4px;
`

const BoxStyleOuter = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
`
const COLUMNS = [
  {
    accessor: 'row_number',
    Header: 'Row Number',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: false,
  },
  {
    accessor: 'error_data',
    Header: 'Error Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: false,
  },
]
const ErroredRowsTable = ({
  loader,
  navigateToParams,
  fileData,
  setShowErroredTable,
  setShowLoanBookTable,
  eventEmitter,
  location,
  status,
  data: {data, error_total},
  filterData: {error_page, error_size},
}) => {
  const handlePagination = ({page, size}) => {
    navigateToParams({error_page: page, error_size: size})
  }
  const handleBackButton = () => {
    setShowErroredTable(false)
    setShowLoanBookTable(true)
  }

  const handleDownload = () => {
    incorrectFileDownload(eventEmitter, fileData.pk)
  }

  const updateCellData = (rowId, column, value) => {}

  const handleRowClicked = row => {}

  const handlecolumnClick = cell => {}

  const handleSort = sortOrder => {
    if (!sortOrder.length) navigateToParams({ordering: null})
    else {
      const [{id, desc}] = sortOrder
      navigateToParams({ordering: desc ? '-' + id : id})
    }
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  return (
    <Box>
      <Box alignSelf="start" pad={{top: 'xxsmall'}} direction="row">
        <Button
          onClick={() => {
            handleBackButton()
          }}
          icon={
            <Image
              fit="contain"
              src={process.env.PUBLIC_URL + '/img/back.png'}
              style={{cursor: 'pointer'}}
            />
          }
        />
        <Box alignSelf="center">
          <Text size="16px" color="dark-6">
            {fileData ? fileData.original_file_name : ''}
          </Text>
        </Box>
      </Box>
      <Box
        direction="row"
        justify="between"
        margin={{top: 'medium'}}
        pad={{bottom: 'medium'}}
      >
        <BoxStyleOuter
          round="4px"
          background="light-1"
          width="18%"
          height="40px"
          direction="row"
        >
          <BoxStyleInner width="10px" height="100%" background="savings" />
          <Box alignSelf="center">
            <Text
              margin={{left: 'small'}}
              size="12px"
              weight="bold"
              color="dark-1"
            >
              {fileData ? fileData.create_success_count : ''}/
              {fileData ? fileData.total_row_count : ''}
            </Text>
          </Box>
          <Box alignSelf="center">
            <Text margin={{left: 'small'}} size="12px" color="dark-1">
              {CLIENT_LIST_MESSAGES.RECORDS_CREATED_SUCCESSFULLY}
            </Text>
          </Box>
        </BoxStyleOuter>
        <BoxStyleOuter
          round="4px"
          background="light-1"
          width="18%"
          height="40px"
          direction="row"
        >
          <BoxStyleInner width="10px" height="100%" background="dark-8" />
          <Box alignSelf="center">
            <Text
              margin={{left: 'small'}}
              size="12px"
              weight="bold"
              color="dark-1"
            >
              {fileData ? fileData.create_fail_count : ''}/
              {fileData ? fileData.total_row_count : ''}
            </Text>
          </Box>
          <Box alignSelf="center">
            <Text margin={{left: 'small'}} size="12px" color="dark-1">
              {CLIENT_LIST_MESSAGES.RECORDS_CREATION_FAILED}
            </Text>
          </Box>
        </BoxStyleOuter>
        <BoxStyleOuter
          round="4px"
          background="light-1"
          width="18%"
          height="40px"
          direction="row"
        >
          <BoxStyleInner width="10px" height="100%" background="savings" />
          <Box alignSelf="center">
            <Text
              margin={{left: 'small'}}
              size="12px"
              weight="bold"
              color="dark-1"
            >
              {fileData ? fileData.update_success_count : ''}/
              {fileData ? fileData.total_row_count : ''}
            </Text>
          </Box>
          <Box alignSelf="center">
            <Text margin={{left: 'small'}} size="12px" color="dark-1">
              {CLIENT_LIST_MESSAGES.RECORDS_UPLOADED_SUCCESSFULLY}
            </Text>
          </Box>
        </BoxStyleOuter>
        <BoxStyleOuter
          round="4px"
          background="light-1"
          width="18%"
          height="40px"
          direction="row"
        >
          <BoxStyleInner width="10px" height="100%" background="dark-8" />
          <Box alignSelf="center">
            <Text
              margin={{left: 'small'}}
              size="12px"
              weight="bold"
              color="dark-1"
            >
              {fileData ? fileData.update_fail_count : ''}/
              {fileData ? fileData.total_row_count : ''}
            </Text>
          </Box>
          <Box alignSelf="center">
            <Text margin={{left: 'small'}} size="12px" color="dark-1">
              {CLIENT_LIST_MESSAGES.RECORDS_UPDATION_FAILED}
            </Text>
          </Box>
        </BoxStyleOuter>
      </Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <Box>
          <Box
            pad={{bottom: 'small', top: 'medium'}}
            justify="between"
            direction="row"
          >
            <Text color="dark-6" size="14px" weight="bold" alignSelf="end">
              Please fix the errors below.
            </Text>

            <Box direction="row" gap="small">
              <Text
                weight="bold"
                color="dark-6"
                style={{
                  fontSize: '14px',
                }}
              >
                {'Import Status:'}
              </Text>
              <Text
                weight="bold"
                color="dark-6"
                style={{
                  fontSize: '12px',
                }}
              >
                {status}
              </Text>
              <Text
                onClick={() => handleDownload()}
                color="blue-email"
                style={{
                  textDecoration: 'underline',
                  fontSize: '14px',
                }}
              >
                Download Incorrect Records
              </Text>
            </Box>
          </Box>
          <BoxWrapper>
            <ReactTable
              initialSortOrder={getInitialSorting()}
              data={data}
              columns={COLUMNS} // pass in our table columns
              onSortChange={handleSort}
              updateCellData={updateCellData}
              rowClickHandler={row => handleRowClicked(row)}
              columnClickHandler={cell => handlecolumnClick(cell)}
            />
          </BoxWrapper>
        </Box>
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            <Text color="dark-6">
              {/* Page {error_page} out of {Math.ceil(error_total / error_size)} */}
              {error_total < error_size ? error_total + ' out of ' + error_total : data.length + ' out of ' + error_total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              select={true}
              pageData={{
                page: error_page,
                size: error_size,
                total: error_total,
              }}
              dataLength={data.length}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ErroredRowsTable
