import LOGOUT_EVENT from 'pages/header/constants'
import LogoutAPI from 'accounts/gateways/logout-api'

async function logout(eventEmitter) {
  try {
    const resp = await LogoutAPI.LogoutAPI()
    eventEmitter.emit(LOGOUT_EVENT.LOGOUT_SUCCESS, resp)
  } catch {
    eventEmitter.emit(LOGOUT_EVENT.LOGOUT_FAILURE)
  }
}

export {logout}
