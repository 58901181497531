import GraniteAPIClient from 'utils/granite-client'
import SideMenu from 'accounts/entities/sidemenu'
// import PermessionsToArray from 'accounts/entities/permessions'
/**
 *  GetSidemenuItems api call implementation.
 * @returns {object} containing a array: result, count
 * @throws {GraniteError} if xhr request fails
 */
async function getPermissionsAndSideMenuItems() {

  const response =  await GraniteAPIClient().get('/organisation/side-menus/',
  {
    Authorization:'Bearer ab55It6zLzgUOw2wijS4Zhzm78zT13',
    organisation:'1'
  })
  const sidemenu = response.data.map(item => new SideMenu(item))
  return sidemenu
}


async function getPermissions() {
   const response = await GraniteAPIClient().get('/organisation/employee/permissions/')
   const permessions_array = []
   // const permessions_array = [
  //   'applicant_delete',
  //   'applicant_add',
  //   'applicant_edit',
  //   'applicant_view',
  //   'loan_applicant_mapping_delete',
  //   'loan_applicant_mapping_edit',
  //   'loan_applicant_mapping_add',
  //   'loan_applicant_mapping_view',
  //   'role_delete',
  //   'role_edit',
  //   'role_add',
  //   'role_view',
  //   'state_delete',
  //   'state_edit',
  //   'state_add',
  //   'state_view',
  //   'country_edit',
  //   'country_view',
  //   'country_delete',
  //   'country_add',
  //   'address_delete',
  //   'address_add',
  //   'address_edit',
  //   'address_view',
  //   'side_menu_add',
  //   'side_menu_delete',
  //   'side_menu_edit',
  //   'side_menu_view',
  //   'repayment_view',
  //   'repayment_edit',
  //   'repayment_delete',
  //   'repayment_add',
  //   'product_edit',
  //   'product_add',
  //   'product_delete',
  //   'product_view',
  //   'loan_info_delete',
  //   'loan_info_add',
  //   'loan_info_edit',
  //   'loan_info_view',
  //   'lender_edit',
  //   'lender_add',
  //   'lender_delete',
  //   'lender_view',
  //   'note_edit',
  //   'note_delete',
  //   'note_add',
  //   'note_view',
  //   'document_delete',
  //   'document_add',
  //   'document_edit',
  //   'document_view',
  //   'audit_add',
  //   'audit_delete',
  //   'audit_edit',
  //   'audit_view',
  //   'security_address_edit',
  //   'security_address_delete',
  //   'security_address_add',
  //   'security_address_view',
  //   'risk_setting_add',
  //   'risk_setting_delete',
  //   'risk_setting_edit',
  //   'risk_setting_view',
  //   'loan_review_delete',
  //   'loan_review_add',
  //   'loan_review_edit',
  //   'loan_review_view',
  //   'campaign_edit',
  //   'campaign_add',
  //   'campaign_delete',
  //   'campaign_view',
  //   'user_delete',
  //   'user_add',
  //   'user_edit',
  //   'user_view',
  //   'customer_add',
  //   'customer_delete',
  //   'customer_edit',
  //   'customer_view',
  //   'organisation_delete',
  //   'organisation_add',
  //   'organisation_edit',
  //   'organisation_view',
  //   'employee_add',
  //   'employee_delete',
  //   'employee_edit',
  //   'employee_view',
  //   'loan view',
  //   'full access',
  //   'import',
  //   'export',
  //   'Write',
  //   'Read',
  //   'Admin',
  // ]
 
  response.data.forEach(item => {
    permessions_array.push(item.name)
  })
 
  return permessions_array
}

export {getPermissionsAndSideMenuItems, getPermissions}
