import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import ClientDetails from 'clientDetails/entities/ClientInfo'
import LoanDetails from 'clientDetails/entities/loanDetails'
import Notes from 'clientDetails/entities/notes'
import Savings from 'clientDetails/entities/savings'
import ScreenActivity from 'clientDetails/entities/screenactivity'
import LoanReview from 'clientDetails/entities/loanReview'
import MailList from 'clientDetails/entities/mailtemplatelist'
import {client_type_mapping} from 'granite/CurrencyConvertor'
// import {
//   ReverseCamelCasemonthObject,
// } from 'granite/Months_Object'
async function editClientDetails(values, Id) {
  try {
    const response = await GraniteAPIClient().patch(
      '/account/' + Id + '/',
      values,
    )
    return response.data.results
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function editClientDetailsType(values, ID) {
  try {
    const response = await GraniteAPIClient().put(
      '/loan/applicants/' + ID + '/',
      values,
    )
    return response.data.results
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getclientInfo(clientId) {
  try {
    const response = await GraniteAPIClient().get(
      `/loan/applicants/` + clientId + `/`,
    )
    let dataToProcess = []
    dataToProcess.push(response.data)
    const data = dataToProcess.map(item => {
      return new ClientDetails(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function updateclientInfo(values) {
  try {
    const response = await GraniteAPIClient().get(`api_path` + values)
    return response.data.results
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getLoanList(params, loan_list_params) {
  try {
    const response = await GraniteAPIClient().get(
      '/loan/applicant/' + params.applicant_id + '/',
      {params: loan_list_params},
    )
    const data = response.data.results.map(item => {
      return new LoanDetails(item)
    })
    const count = response.data.count
    const next_page = response.data.next_page
    const previous_page = response.data.previous_page
    return {data, count, next_page, previous_page}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getLoanListfilter(values) {
  try {
    const response = await GraniteAPIClient().get(`loan/loans`, {
      params: values,
    })
    return response.data.results
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getScreenActivity(values) {
  try {
    const response = await GraniteAPIClient().get(`common/audits/`, {
      params: values,
    })
    const data = response.data.results.map(item => {
      return new ScreenActivity(item)
    })
    // return data
    return {
      tableData: data,
      count: response.data.count,
      next_page: response.data.next_page,
      previous_page: response.data.previous_page,
      rows: data ? data.length : 0,
    }
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function updateScreenActivity(values) {
  try {
    const response = await GraniteAPIClient().post(`api_path` + values)
    return response.data.results
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getNotes(values) {
  try {
    const response = await GraniteAPIClient().get(
      `/common/notes/?page=1&page_size=100&entity_id=` + values,
    )
    const data = response.data.results.map(item => {
      return new Notes(item)
    })
    return {data}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function addNotes(data) {
  const payload = data
  try {
    const response = await GraniteAPIClient().post(`/common/notes/`, payload)
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function deleteNotes(id) {
  try {
    const response = await GraniteAPIClient().delete(
      `/common/notes/` + id + `/`,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getSavings(params) {
  try {
    const response = await GraniteAPIClient().get(`/alr/savings/`, {
      params: params,
    })
    const entityData = []
    const tempResponse = response.data
    entityData.push(tempResponse)
    const data = entityData.map(item => {
      return new Savings(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function mailClientInfo(name, clientid) {
  const params = {
    screen_name: name,
  }
  try {
    const response = await GraniteAPIClient().get(
      `loan/applicant_mail/` + clientid + '/',
      {params: params},
    )
    const data = response.data.map(item => {
      return new MailList(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// addNewClient
async function addNewClientAPI(clientInfo) {
  const payload = {
    name: clientInfo.name,
    first_name: clientInfo.first_name,
    last_name: clientInfo.last_name,
    email: clientInfo.email,
    title: '',
    phone: clientInfo.phone,
    // review_cycle: ReverseCamelCasemonthObject[clientInfo.review_month],
    company_name: clientInfo.company_name,
    company_abn: clientInfo.company_ABN,
    client_type: await client_type_mapping(clientInfo.client_type),
    organisation_id: localStorage.getItem('organisation'),
  }

  try {
    const response = await GraniteAPIClient().post(`/loan/applicants/`, payload)
    return response
  } catch (error) {
    if (error.errors.status === 400) {
      let data = error.errors.invalid_params[0]
      let response = {
        data: data,
        title: error.errors.title,
      }
      return response
    }

    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function mailClientInfoNew(name, Id, values, content) {
  const payload = {
    template_name: name,
    content: content,
  }

  try {
    const response = await GraniteAPIClient().post(
      `loan/applicant_mail/` + Id + `/`,
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function exportloanlistAPI(clientid) {
  try {
    const response = await GraniteAPIClient().get(
      // '/aggregate/export-loan-book/?applicant_id=' + clientid,
      '/loan/loans/' + clientid + '/export/',
    )
    return response.data.url
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getMoreDetails(id) {
  try {
    const response = await GraniteAPIClient().get('/loan/loan-info/' + id + '/')
    const moreData = new LoanReview(response.data)
    const savings = await getSavings({applicant_id: moreData.applicant_pk, loan_id: id})
    return {moreData, savings}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

export default {
  editClientDetails,
  editClientDetailsType,
  getclientInfo,
  updateclientInfo,
  mailClientInfo,
  getLoanList,
  getLoanListfilter,
  getScreenActivity,
  updateScreenActivity,
  getNotes,
  addNotes,
  deleteNotes,
  getSavings,
  addNewClientAPI,
  mailClientInfoNew,
  exportloanlistAPI,
  getMoreDetails,
}
