import React from 'react'
import {
  Box,
  Select,
  Button,
  Text,
  Image,
  TextInput,
  RadioButtonGroup,
  ThemeContext,
} from 'grommet'
import {Formik} from 'formik'
import ProgressBar from './progressbar'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import styled from 'styled-components'

// import LOAN_DETAIL_MESSAGE from 'messages/clientloandetails'
import Datepicker from 'granite/components/DatePicker'
import {updateLoanInfo} from 'clientloandetail/controllers/loan'
import {useRouteMatch} from 'react-router-dom'
import CLIENT_DETAIL_MESSAGES from 'messages/clientDetails'
import {format} from 'date-fns'
import InputCurrency from 'granite/components/CurrencyField'

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const BoxTextField = styled(Box)`
  border-top: 1px solid #b1b8c0;
  border-bottom: 1px solid #b1b8c0;
  // border-left: 1px solid #b1b8c0;
  border-right: 1px solid #b1b8c0;
`

const AddSecurityButton = styled(Button)`
  // height: 36px;
  padding-left: 0px;
  padding-right: 0px;
  min-width: 180px;
`
const bodyValue = {
  button: {
    default: {
      padding: {
        horizontal: 'small',
        vertical: '0px',
      },
    },
    primary: {
      padding: {horizontal: 'small', vertical: '0px'},
    },
  },
}

const TextInput1 = styled(TextInput)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  opacity: 1;
  top: 115px;
  left: 237px;
  width: 100%;
  height: 30.8px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`
const TextStyle = styled(Text)`
  font-weight: 700;
`
const StyledSelect = styled(Select)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  opacity: 1;
  top: 115px;
  left: 237px;
  width: 100%;
  height: 30.8px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`

const LoanSummaryForm = ({
  particularloanDetail,
  isLoanActive,
  eventEmitter,
  edit,
  setEdit,
  setToastWarning,
  setToastData,
  setshowmailmodal,
  showmailmodal,
  selectedapplicant,
  permissions,
  securityaddresses,
  securityAddressFormVisibility,
  setsecurityAddressFormVisibility,
  userProfile,
  Lenders,
}) => {
  var lendersArray = []

  Lenders.data.forEach(element => {
    lendersArray.push(element.lender_name)
  })

  const heading = 'Loan Summary'

  const handleEdit = () => {
    setEdit(false)
  }

  const validationSchema = Yup.object().shape({
    re_payment_date: Yup.string()
      .min(1, 'Rwpayment date must be from 1 to 31')
      .max(2, 'Rwpayment date must be from 1 to 31')
      .matches(
        /\b(0?[1-9]|[12][0-9]|3[01])\b/,
        'Rwpayment date must be from 1 to 31',
      ),
    // Required Fields
    settlementDate: Yup.string().required('Settlement Date required'),
    bsb_code: Yup.number('Must be valid number')
      .typeError('BSB No must be a number')
      .required('BSB No required'),
    lender_name: Yup.string().required('Lender name required'),
    account: Yup.string().required('Account no required'),
    package: Yup.string().required('Package required'),
    product: Yup.string().required('Product required'),
    purpose: Yup.string().required('Purpose required'),
    re_payment_type: Yup.string().required('Repayment type required'),
    rate_type_choice: Yup.string().required('Rate type required'),
    loanlimit: Yup.string().required('Loan Limit required'),
    loanrate: Yup.string().required('Laon rate required'),
    loan_expiry_date: Yup.string().required('Loan expiry date required'),
    loan_term: Yup.number('Loan term must be number').typeError(
      'Loan Term (in months) eg 12',
    ),
    //  .required('Loan term required'),

    exp_date: Yup.string().when('rate_type_choice', {
      is: 'FIXED',
      then: Yup.string().required('Fixed expiry is required.'),
    }),
    io_expiry: Yup.string().when('re_payment_type', {
      is: 'IO',
      then: Yup.string().required('IO expiry is required.'),
    }),
  })

  const handleFixedExpDateEdit = (option, setFieldValue) => {}

  const handleIoExpiryEdit = (option, setFieldValue) => {}
  const forminitalValues = {
    company: particularloanDetail.company,
    account: particularloanDetail.account_number,
    status: particularloanDetail.status,
    bsb_code: particularloanDetail.bsb_code,
    settlementDate: particularloanDetail.settlement_date,
    loanlimit: particularloanDetail.loan_limit,
    balance: particularloanDetail.balance,
    loanrate: particularloanDetail.rate,
    purpose: particularloanDetail.purpose,
    rate_type_choice: particularloanDetail.rate_type_choice,
    exp_date: particularloanDetail.fixed_expiry,
    re_payment_amount: particularloanDetail.repayment_amount,
    repayment_account: particularloanDetail.repayment_account,
    re_payment_type: particularloanDetail.repayment_type,
    re_payment_date: particularloanDetail.repayment_date,
    repayment_period: particularloanDetail.repayment_period,
    io_expiry: particularloanDetail.io_expiry,
    offset_amount: particularloanDetail.off_set,
    product: particularloanDetail.product_name,
    product_exp: particularloanDetail.product_expiry,
    loan_term: particularloanDetail.term,
    package: particularloanDetail.loan_package,
    lender_name: particularloanDetail.lender_name,
    loan_expiry_date: particularloanDetail.loan_expiry_date,
    redraw_fund: particularloanDetail.redraw_fund,
    // company_name: particularloanDetail.company_name,
  }

  const route = useRouteMatch()
  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      for (let key in values) {
        if (values[key] !== forminitalValues[key]) {
          await updateLoanInfo(
            eventEmitter,
            {...values},
            route.params.id,
            particularloanDetail,
            userProfile,
          )
          break
        }
      }
    } catch (e) {
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  const handleMouseDown = () => {
    if (edit) {
      setToastWarning(false)
      setToastData({
        open: true,
        message: CLIENT_DETAIL_MESSAGES.EDIT_BUTTON_NOT_CLICKED,
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 2000)
    }
  }
  const handleMail = () => {
    if (selectedapplicant) {
      setshowmailmodal(true)
    }
  }

  const handleAddSecurityAddress = () => {
    setsecurityAddressFormVisibility({
      type: 'Add',
      show: true,
      formdata: {
        addr_line_1: '',
        addr_line_2: '',
        city: '',
        state: '',
        postcode: '',
        country: 'Australia',
      },
    })
  }

  const handleEditSecurityAddress = (value, index) => {
    setsecurityAddressFormVisibility({
      type: 'Edit',
      show: true,
      formdata: {
        addr_line_1: value.address_line_1,
        addr_line_2: value.address_line_2,
        city: value.city,
        state: value.state,
        postcode: value.postcode,
        country: value.country,
        security_id: value.pk,
      },
    })
  }

  const handleDeleteSecurityAddress = (value, index) => {
    setsecurityAddressFormVisibility({
      type: 'Delete',
      show: true,
      formdata: {
        addr_line_1: value.address_line_1,
        addr_line_2: value.address_line_2,
        city: value.city,
        state: value.state,
        postcode: value.postcode,
        country: value.country,
        security_id: value.pk,
      },
    })
  }

  return (
    <Formik
      initialValues={forminitalValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
        <Box
          width="100%"
          background="light-1"
          elevation="medium"
          pad="medium"
          gap="small"
        >
          <form onSubmit={handleSubmit}>
            <Box
              pad={{bottom: 'small'}}
              justify="between"
              direction="row"
              background="brand-light"
            >
              <Box
                basis={edit ? '60%' : '50%'}
                alignSelf="center"
                pad={{right: 'small'}}
              >
                <ProgressBar
                  particularloanDetail={particularloanDetail}
                  route={route}
                />
              </Box>
              <Box>
                {edit ? (
                  <Box
                    direction="row"
                    justify="end"
                    gap="small"
                    pad={{left: 'medium'}}
                  >
                    {permissions !== null &&
                      permissions.includes('security_address_add') && (
                        <ThemeContext.Extend value={bodyValue}>
                          <AddSecurityButton
                            margin={{vertical: 'small'}}
                            label={'Add Security'}
                            onClick={handleAddSecurityAddress}
                            disabled={isLoanActive}
                            primary
                          />
                        </ThemeContext.Extend>
                      )}

                    <ButtonStyle
                      margin={{vertical: 'small'}}
                      label={'Mail'}
                      onClick={handleMail}
                      disabled={isLoanActive}
                      primary
                    />
                    {permissions !== null &&
                      permissions.includes('loan_info_edit') && (
                        <ButtonStyle
                          margin={{vertical: 'small'}}
                          label={'Edit'}
                          onClick={handleEdit}
                          disabled={isLoanActive}
                          primary
                        />
                      )}
                  </Box>
                ) : (
                  <Box
                    direction="row"
                    justify="end"
                    gap="small"
                    pad={{left: 'medium'}}
                  >
                    {permissions !== null &&
                      permissions.includes('security_address_add') && (
                        <ThemeContext.Extend value={bodyValue}>
                          <AddSecurityButton
                            margin={{vertical: 'small'}}
                            label={'Add Security'}
                            onClick={handleAddSecurityAddress}
                            disabled={isLoanActive}
                            primary
                          />
                        </ThemeContext.Extend>
                      )}
                    <ButtonStyle
                      margin={{vertical: 'small'}}
                      type="submit"
                      alignSelf="end"
                      label={isSubmitting ? 'Saving...' : 'Save'}
                      disabled={isSubmitting}
                      primary
                    />

                    <ButtonStyle
                      type="reset"
                      alignSelf="end"
                      margin={{vertical: 'small'}}
                      label={'Cancel'}
                      onClick={() => {
                        resetForm(forminitalValues)
                        setEdit(true)
                      }}
                      primary
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {false && (
              <Box
                pad={{bottom: 'small', top: 'small'}}
                direction="row"
                width="100%"
              >
                <TextStyle weight="bold" color="dark-1" size="16px">
                  {heading}
                </TextStyle>
              </Box>
            )}{' '}
            <Box
              direction="row-responsive"
              elevation="none"
              justify="between"
              pad={{top: 'small', bottom: 'small'}}
            >
              <Box
                pad={{top: 'small', bottom: 'small'}}
                direction="row"
                gap="small"
              >
                <TextStyle weight="bold" color="dark-1" size="16px">
                  {heading}
                </TextStyle>
              </Box>
              <Box pad="xxsmall" direction="row" alignSelf="center">
                <Box pad={{right: 'none'}} direction="row" gap="medium">
                  <TextStyle
                    margin={{top: 'xsmall'}}
                    weight="bold"
                    color="dark-1"
                    size="12px"
                  >
                    {'Last Updated : ' + particularloanDetail.modified_date}
                  </TextStyle>
                </Box>

                <Box margin={{top: '6px'}} pad={{left: 'xsmall'}}>
                  <Image
                    height="10px"
                    src={process.env.PUBLIC_URL + '/img/mini_calendar.svg'}
                  />
                </Box>
              </Box>
            </Box>
            {securityaddresses.map((value, index) => {
              return (
                <Box>
                  <Box
                    // direction="row-responsive"
                    elevation="none"
                    justify="between"
                    direction="row"
                  >
                    <Box
                      pad={{top: 'small', bottom: 'small'}}
                      direction="row"
                      // border={{color: 'red', size: 'small'}}
                      gap="small"
                    >
                      <TextStyle color="dark" size="13px">
                        Security Address :
                      </TextStyle>
                      <Text color="dark" size="13px">
                        {value.complete_addr}
                      </Text>
                      <Box
                        // pad="xxsmall"
                        direction="row"
                        // border={{color: 'red', size: 'small'}}
                      >
                        {permissions !== null &&
                          permissions.includes('security_address_edit') && (
                            <Box>
                              <Image
                                height="14px"
                                src={process.env.PUBLIC_URL + '/img/edit.png'}
                                onClick={() => {
                                  if (!isLoanActive) {
                                    handleEditSecurityAddress(value, index)
                                  }
                                }}
                              />
                            </Box>
                          )}

                        {securityaddresses.length > 1 && (
                          <Box
                            // margin={{top: '6px'}}
                            pad={{left: 'xsmall'}}
                          >
                            {permissions !== null &&
                              permissions.includes(
                                'security_address_delete',
                              ) && (
                                <Image
                                  height="xsmall"
                                  src={
                                    process.env.PUBLIC_URL +
                                    '/Images/Delete.png'
                                  }
                                  onClick={() => {
                                    if (!isLoanActive) {
                                      handleDeleteSecurityAddress(value, index)
                                    }
                                  }}
                                />
                              )}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}
            <Box margin="small">
              {false && (
                <Box
                  direction="row-responsive"
                  elevation="none"
                  justify="between"
                  pad={{top: 'small', bottom: 'small'}}
                >
                  <Box
                    pad={{top: 'small', bottom: 'small'}}
                    direction="row"
                    gap="small"
                  >
                    <TextStyle color="dark" size="13px">
                      Security Address :
                    </TextStyle>
                    <Text color="dark" size="13px">
                      {particularloanDetail.security_name}
                    </Text>
                  </Box>
                  <Box pad="xxsmall" direction="row" alignSelf="center">
                    <Box
                      pad={{right: 'none'}}
                      direction="row"
                      gap="medium"
                    ></Box>
                    {false && (
                      <Box margin={{top: '6px'}} pad={{left: 'xsmall'}}>
                        <Image
                          height="10px"
                          src={
                            process.env.PUBLIC_URL + '/img/mini_calendar.svg'
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              )}{' '}
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="account"
                    label="Account No."
                    error={touched.account && errors.account}
                    backgroundLabel="dark-9"
                    required={'yes'}
                    info={'Sample information'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="account"
                      name="account"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Account {eg: 123456789}"
                      value={values.account}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="bsb_code"
                    label="BSB No."
                    error={touched.bsb_code && errors.bsb_code}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="bsb_code"
                      name="bsb_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="BSB Code eg: 00000"
                      value={values.bsb_code}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="lender_name"
                    label="Lender name"
                    error={touched.lender_name && errors.lender_name}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {edit ? (
                      <TextInput1
                      color="dark"
                      placeholder="Lender Name"
                      value={values.lender_name}
                      disabled={edit}
                      />
                    ) : (
                    <StyledSelect
                      options={lendersArray}
                      value={values.lender_name}
                      dropHeight="small"
                      size="small"
                      placeholder={'Lender Name'}
                      disabled={edit}
                      // dropAlign={{right: 'left'}}
                      onChange={({option}) =>
                        setFieldValue('lender_name', option)
                      }
                    />
                    )}
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="settlementDate"
                    label="Settlement Date"
                    error={touched.settlementDate && errors.settlementDate}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {edit ? (
                      <Box
                        direction="row"
                        align="end"
                        pad={{
                          top: '6px',
                          bottom: '6px',
                          right: '8px',
                          left: '4px',
                        }}
                        justify="between"
                        height="30.8px"
                      >
                        <Text size="12px">{values.settlementDate}</Text>
                        <Image
                          height="20px"
                          src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
                        />
                      </Box>
                    ) : (
                      <Box height="30.8px">
                        <Datepicker
                          initialDate={values.settlementDate}
                          imageHeight="20px"
                          textSize="12px"
                          onClose={val => {
                            if (val === null) {
                              setFieldValue('settlementDate', '')
                            } else {
                              setFieldValue(
                                'settlementDate',
                                format(new Date(val), 'yyyy-MM-dd'),
                              )
                            }
                          }}
                        />
                      </Box>
                    )}
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="loan_expiry_date"
                    label="Loan Expiry Date"
                    error={touched.loan_expiry_date && errors.loan_expiry_date}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {edit ? (
                      <Box
                        direction="row"
                        align="end"
                        pad={{
                          top: '6px',
                          bottom: '6px',
                          right: '8px',
                          left: '4px',
                        }}
                        justify="between"
                        height="30.8px"
                      >
                        <Text size="12px">{values.loan_expiry_date}</Text>
                        <Image
                          height="20px"
                          src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
                        />
                      </Box>
                    ) : (
                      <Box height="30.8px">
                        <Datepicker
                          initialDate={values.loan_expiry_date}
                          imageHeight="20px"
                          textSize="12px"
                          onClose={val => {
                            if (val === null) {
                              setFieldValue('loan_expiry_date', '')
                            } else {
                              setFieldValue(
                                'loan_expiry_date',
                                format(new Date(val), 'yyyy-MM-dd'),
                              )
                            }
                          }}
                        />
                      </Box>
                    )}
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="loanlimit"
                    label="Loan Limit"
                    error={touched.loanlimit && errors.loanlimit}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {false && (
                      <TextInput1
                        pad="loanlimit"
                        type="loanlimit"
                        name="loanlimit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Loan Limit eg: $15,000.00"
                        value={values.loanlimit}
                        disabled={edit}
                      />
                    )}
                    <InputCurrency
                      placeholder="Loan Limit eg: $15,000.00"
                      name="loanlimit"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.loanlimit}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="balance"
                    label="Balance"
                    error={touched.balance && errors.balance}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {false && (
                      <TextInput1
                        type="balance"
                        name="balance"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Balance eg: $500.00"
                        value={values.balance}
                        disabled={edit}
                      />
                    )}
                    <InputCurrency
                      placeholder="Balance eg: $500.00"
                      name="balance"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.balance}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="purpose"
                    label="Purpose"
                    error={touched.purpose && errors.purpose}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <RadioButtonGroup
                      name="purpose"
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      disabled={edit}
                      options={['INV', 'OO']}
                      value={values.purpose}
                      onChange={event =>
                        setFieldValue('purpose', event.target.value)
                      }
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              {' '}
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="rate_type_choice"
                    label="Rate Type"
                    error={touched.rate_type_choice && errors.rate_type_choice}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <RadioButtonGroup
                      name="rate_type_choice"
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      disabled={edit}
                      options={['FIXED', 'VAR']}
                      value={values.rate_type_choice}
                      onChange={event => {
                        setFieldValue('rate_type_choice', event.target.value)
                        if (event.target.value === 'VAR') {
                          setFieldValue('exp_date', '')
                        }

                        handleFixedExpDateEdit(
                          event.target.value,
                          setFieldValue,
                        )
                      }}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="exp_date"
                    label="Fixed Expiry Date"
                    error={touched.exp_date && errors.exp_date}
                    backgroundLabel="dark-9"
                    required={values.rate_type_choice === 'FIXED' ? 'yes' : ''}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {true && !edit ? (
                      <Box height="30.8px">
                        <Datepicker
                          initialDate={values.exp_date ? values.exp_date : null}
                          textSize="12px"
                          imageHeight="20px"
                          onClose={val => {
                            if (val === null) {
                              setFieldValue('exp_date', '')
                            } else {
                              setFieldValue(
                                'exp_date',
                                format(new Date(val), 'yyyy-MM-dd'),
                              )
                            }
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        direction="row"
                        align="end"
                        pad={{
                          top: '6px',
                          bottom: '6px',
                          right: '8px',
                          left: '4px',
                        }}
                        justify="between"
                        height="30.8px"
                      >
                        <Text size="12px">{values.exp_date}</Text>
                        <Image
                          height="20px"
                          src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
                        />
                      </Box>
                    )}
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="product"
                    label="Product"
                    error={touched.product && errors.product}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="product"
                      name="product"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Product"
                      value={values.product}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="product_exp"
                    label="Product Expiry"
                    error={touched.product_exp && errors.product_exp}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {edit ? (
                      <Box
                        direction="row"
                        align="end"
                        pad={{
                          top: '6px',
                          bottom: '6px',
                          right: '8px',
                          left: '4px',
                        }}
                        justify="between"
                        height="30.8px"
                      >
                        <Text size="12px">{values.product_exp}</Text>
                        <Image
                          height="20px"
                          src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
                        />
                      </Box>
                    ) : (
                      <Box height="30.8px">
                        <Datepicker
                          initialDate={values.product_exp}
                          textSize="12px"
                          imageHeight="20px"
                          onClose={val => {
                            if (val === null) {
                              setFieldValue('product_exp', '')
                            } else {
                              setFieldValue(
                                'product_exp',
                                format(new Date(val), 'yyyy-MM-dd'),
                              )
                            }
                          }}
                        />
                      </Box>
                    )}
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              {' '}
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="package"
                    label="Package"
                    error={touched.package && errors.package}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <RadioButtonGroup
                      name={'package'}
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      disabled={edit}
                      options={['YES', 'NO']}
                      value={values.package}
                      onChange={event => {
                        setFieldValue('package', event.target.value)
                      }}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="loan_term"
                    label="Loan Term"
                    error={touched.loan_term && errors.loan_term}
                    backgroundLabel="dark-9"
                    // required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="loan_term"
                      name="loan_term"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Loan Term (in months) eg 12"
                      value={values.loan_term}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="re_payment_amount"
                    label="Repayment Amount"
                    error={
                      touched.re_payment_amount && errors.re_payment_amount
                    }
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {false && (
                      <TextInput1
                        pad="re_payment_amount"
                        type="re_payment_amount"
                        name="re_payment_amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Repayment Amount"
                        value={values.re_payment_amount}
                        disabled={edit}
                      />
                    )}
                    <InputCurrency
                      placeholder="Repayment Amount eg: $15,000.00"
                      name="re_payment_amount"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.re_payment_amount}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="repayment_account"
                    label="Repayment Account"
                    error={
                      touched.repayment_account && errors.repayment_account
                    }
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      pad="repayment_account"
                      type="repayment_account"
                      name="repayment_account"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Repayment Account"
                      value={values.repayment_account}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="redraw_fund"
                    label="Redraw Fund"
                    error={touched.redraw_fund && errors.redraw_fund}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <InputCurrency
                      placeholder="Redraw Fund eg: $500.00"
                      name="redraw_fund"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.redraw_fund}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="re_payment_type"
                    label="Repayment Type"
                    error={touched.re_payment_type && errors.re_payment_type}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <RadioButtonGroup
                      name="re_payment_type"
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      disabled={edit}
                      options={['IO', 'P&I']}
                      value={values.re_payment_type}
                      onChange={event => {
                        setFieldValue('re_payment_type', event.target.value)
                        if (event.target.value === 'P&I') {
                          setFieldValue('io_expiry', '')
                        }

                        handleIoExpiryEdit(event.target.value, setFieldValue)
                      }}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="io_expiry"
                    label="IO Expiry"
                    error={touched.io_expiry && errors.io_expiry}
                    backgroundLabel="dark-9"
                    required={values.re_payment_type === 'IO' ? 'yes' : ''}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {true && !edit ? (
                      <Box height="30.8px">
                        <Datepicker
                          initialDate={values.io_expiry}
                          textSize="12px"
                          imageHeight="20px"
                          onClose={val => {
                            if (val === null) {
                              setFieldValue('io_expiry', '')
                            } else {
                              setFieldValue(
                                'io_expiry',
                                format(new Date(val), 'yyyy-MM-dd'),
                              )
                            }
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        direction="row"
                        align="end"
                        pad={{
                          top: '6px',
                          bottom: '6px',
                          right: '8px',
                          left: '4px',
                        }}
                        justify="between"
                        height="30.8px"
                      >
                        <Text size="12px">{values.io_expiry}</Text>
                        <Image
                          height="20px"
                          src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
                        />
                      </Box>
                    )}
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="re_payment_date"
                    label="Repayment Date"
                    error={touched.re_payment_date && errors.re_payment_date}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="re_payment_date"
                      name="re_payment_date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Repayment Date eg:{Must be in range from 1 to 31} "
                      value={values.re_payment_date}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
            <Box direction="row">
              <Box width="50%" direction="row">
                <Box width="20%">
                  <FormField
                    name="repayment_period"
                    label="Repayment Period"
                    error={touched.repayment_period && errors.repayment_period}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {edit ? (
                      <TextInput1
                      color="dark"
                      placeholder="Repayment Period"
                      value={values.repayment_period}
                      disabled={edit}
                      />
                    ) : (
                    <StyledSelect
                      options={['Yearly', 'Monthly', 'Fortnightly', 'Weekly']}
                      value={values.repayment_period}
                      dropHeight="small"
                      size="small"
                      placeholder={'Repayment Period'}
                      disabled={edit}
                      // dropAlign={{right: 'left'}}
                      onChange={({option}) =>
                        setFieldValue('repayment_period', option)
                      }
                    />
                    )}
                  </BoxTextField>
                </Box>
              </Box>
              <Box width="50%" direction="row">
                <Box width="20%">
                  {' '}
                  <FormField
                    name="offset_amount"
                    label="Offset Account"
                    error={touched.offset_amount && errors.offset_amount}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="offset_amount"
                      name="offset_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Offset Account"
                      value={values.offset_amount}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      )}
    </Formik>
  )
}
export default LoanSummaryForm
