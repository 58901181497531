// CORE IMPORTS
import React from 'react'
import {Box, Text} from 'grommet'
import Slider from 'granite/components/Slider'

const ProgreesBar = ({particularloanDetail}) => {
  const review_cycle = particularloanDetail.review_cycle
  const monthObj = {
    '1': 'JANUARY',
    '2': 'FEBRUARY',
    '3': 'MARCH',
    '4': 'APRIL',
    '5': 'MAY',
    '6': 'JUNE',
    '7': 'JULY',
    '8': 'AUGUST',
    '9': 'SEPTEMBER',
    '10': 'OCTOBER',
    '11': 'NOVEMBER',
    '12': 'DECEMBER',
  }
  const date = new Date()

  var modified_date
  var settlement_date
  var settlement_date_year

  const alrMonth = parseInt(getKeyByValue(monthObj, review_cycle))
  const currentMonth = parseInt(String(date.getMonth() + 1).padStart(2, '0'))

  if (alrMonth <= currentMonth) {
    modified_date =
      '01-' + monthObj[alrMonth] + '-' + String(date.getFullYear())
    settlement_date =
      '01-' + monthObj[alrMonth] + '-' + String(date.getFullYear() + 1)
    settlement_date_year = settlement_date.split('-')[2] || '0000'
  } else if (alrMonth > currentMonth) {
    modified_date =
      '01-' + monthObj[alrMonth] + '-' + String(date.getFullYear())
    settlement_date =
      '01-' + monthObj[alrMonth] + '-' + String(date.getFullYear())
    settlement_date_year = settlement_date.split('-')[2] || '0000'
  } else {
    modified_date = '01-FEBRUARY-2050'
    settlement_date = '01-FEBRUARY-2051'
  }

  const current_date = [
    String(date.getDate()).padStart(2, '0'),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getFullYear()),
  ]

  let temp_1 = modified_date && montvalue(modified_date.split('-'))
  let temp_cd = current_date
  let temp_2 = settlement_date && montvalue(settlement_date.split('-'))
  var date1 = new Date(temp_1[2], temp_1[1], temp_1[0])
  var date_cd = new Date(temp_cd[2], temp_cd[1], temp_cd[0])
  var date2 = new Date(temp_2[2], temp_2[1], temp_2[0])
  var msDiff = date2 - date1
  var daysDiff = msDiff / 1000 / 60 / 60 / 24
  var pcnt
  if (daysDiff === 0) {
    pcnt = 100 - ((date2 - date_cd) / 1000 / 60 / 60 / 24 / 366) * 100
  } else {
    pcnt = 100 - ((date2 - date_cd) / 1000 / 60 / 60 / 24 / daysDiff) * 100
  }

  const days_rem = (date2 - date_cd) / 1000 / 60 / 60 / 24

  function getKeyByValue(object, value) {
    for (var prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] === value) return prop
      }
    }
  }

  function montvalue(arr) {
    for (var key in monthObj) {
      if (monthObj[key] === arr[1].toUpperCase()) {
        arr[1] = key
        return arr
      }
    }
  }

  return (
    <Box>
      <Box width="100%" direction="row" pad={{bottom: 'small'}}>
        <Box align="start" width="50%">
          <Text size="12px"   color="dark-1">
            {parseFloat(days_rem).toFixed(0)  + ' days remaining'}
          </Text>
        </Box>
        <Box align="end" width="50%">
          <Text size="12px" color="dark-1">
            {`${
              review_cycle ? review_cycle + ' ' + settlement_date_year : ''
            }` || ''}
          </Text>
        </Box>
      </Box>
      <Slider
        value={pcnt}
        showValue={false}
        background="comp-3"
        background_back="#FFFFFF"
        thumb="comp-3"
        height="7px"
        thumbWidth="0px"
        thumbHeight="0px"
      />
    </Box>
  )
}

export default ProgreesBar
