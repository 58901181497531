import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {Box, Keyboard} from 'grommet'

import MenuButton from 'granite/components/MenuButton'

// const getFlatChildrenIds = children =>
//   children.reduce((flatChildren, item) => {
//     if (item.getchildren()) {
//       flatChildren.push(item.id || item.label)
//       flatChildren = flatChildren.concat(getFlatChildrenIds(item.getchildren()))
//     }
//     return flatChildren
//   }, [])

const VerticalMenu = ({data, hoverIndicatorOptions, isCollapsed, pathname}) => {
  let rawPath = window.location.pathname
  let processPath = rawPath.replace(/%20/g, ' ')
  let path = processPath.split('/')

  const initiallyExpanded =
    data &&
    data.find(
      item =>
        item.children && item.children.find(child => path.includes(child.name)),
    )

  const onMenuChange = (item, expanded) => {
    if (item.name === expanded) setExpanded(false)
    else setExpanded(item.name)
  }
  const [expanded, setExpanded] = useState(
    initiallyExpanded && initiallyExpanded.name,
  )
  const loanReviewTabs = {
    'gatherloandetails': 'loanreview',
    'notifyclientofreview': 'loanreview',
    'negotiatepricing': "loanreview",
    'contactclientofresult': "loanreview",
    'completed': "loanreview",
  }

  const rendermenuItems = (item, level = 0) => {
    const itemKey = `item_${item.pk}_${level}`
    const content = (
      <MenuButton
        name={item.name}
        label={item.label === 'Overview' ? 'Campaign' : item.label}
        id={item.pk}
        level={level}
        isActive={
          item.name === path[path.length - 1].toLowerCase() ||
          item.name === loanReviewTabs[path[path.length - 1]] ||
          path.includes(item.name)
            ? true
            : false
        }
        activeColor="brand"
        isExpandable={item.children && Object.keys(item.children).length > 0}
        isExpanded={expanded === item.name}
        isCollapsed={isCollapsed}
        hoverIndicatorOptions={hoverIndicatorOptions}
        onClick={() => onMenuChange(item, expanded)}
      />
    )

    return (
      <Box key={itemKey}>
        {item.children ? (
          <Keyboard
            onDown={() => this.onMenuChange(item, false)}
            onRight={() => this.onMenuChange(item, false)}
            onUp={() => this.onMenuChange(item, true)}
            onLeft={() => this.onMenuChange(item, true)}
          >
            {content}
          </Keyboard>
        ) : (
          <Link to={'/' + (item.name === 'loanreview' ? 'gatherloandetails' : item.name)}>{content}</Link>
        )}
        {expanded === item.name &&
          item.children &&
          item.children.map(item => rendermenuItems(item, level + 1))}
      </Box>
    )
  }

  return (
    <Box flex={{grow: 1}}>
      {data && data.map(item => rendermenuItems(item))}
    </Box>
  )
}

export default VerticalMenu
