// lib imports
import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Box, Image, Button, Heading, ResponsiveContext} from 'grommet'
import {Menu} from 'grommet-icons/icons/'

// CORE imports
import * as SidebarDucks from 'accounts/ducks/sidebar'
import * as UserDucks from 'accounts/ducks/user'
import EventEmitter from 'utils/event-emitter'
import VerticalMenu from 'granite/components/VerticalMenu'
// Application imports
import logoImage from 'assets/logosb.png'
import {loadMenuItemsAndPermissions} from 'accounts/controllers/sidebar'

const hoverIndicatorOptions = {
  color: 'sidebar_hover',
}

function fetchSidemenuItems(dispatch) {
  loadMenuItemsAndPermissions().then(response => {
    if (response && response.MenuItems) {
      dispatch({
        type: 'accounts/sidebar/UPDATE_SIDEBAR',
        data: response.MenuItems,
      })
      dispatch({
        type: 'accounts/user/UPDATE_PERMISSIONS',
        data: response.permissions,
      })
    } else {
      fetchSidemenuItems(dispatch)
    }
  })
}

function listenEvents(eventEmitter) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    // console.log(event)
    switch (event.type) {
      default:
        break
    }
  })
  return subscription
}

const Sidebar = ({
  dispatch,
  history,
  pathname,
  title,
  width,
  collapsible,
  headingProps,
  sidebarItems,
  userProfile,
  userPermissions,
  MenuItems,
  collapsed,
  ...rest
}) => {
  let eventEmitter = new EventEmitter()

  const setCollapsed = collapsed => {
    dispatch({
      type: 'accounts/sidebar/UPDATE_COLLAPSED',
      data: collapsed,
    })
  }

  useEffect(
    function init() {
      const subscription = listenEvents(eventEmitter)

      return function cleanUp() {
        subscription.unsubscribe()
      }
    },
    [eventEmitter],
  )

  if (sidebarItems.length === 0) {
    fetchSidemenuItems(dispatch)
  }
  return (
    <ResponsiveContext.Consumer>
      {size => {
        const isCollapsed =
          (collapsible && size === 'xsmall' && collapsed === undefined) ||
          collapsed === true

        return (
          <Box
            width={isCollapsed ? undefined : width}
            style={{minWidth: isCollapsed ? '47px' : width}}
            {...rest}
            elevation="medium"
          >
            <Box flex>
              <Box height="none" gap="none" direction="column">
                <Box
                  alignSelf="stretch"
                  height={{min: '10%', max: '25%'}}
                  align={isCollapsed ? 'center' : 'end'}
                >
                  <Button
                    size="small"
                    icon={<Menu color="brand" style={{fontWeight: 50}} />}
                    onClick={() => setCollapsed(!collapsed)}
                  />
                </Box>
                {!isCollapsed && title && (
                  <Box
                    align="center"
                    gap="none"
                    pad="small"
                    height={{min: '75%', max: '75%'}}
                    width="100%"
                    flex={false}
                    tag="header"
                    {...headingProps}
                  >
                    {typeof title === 'string' ? (
                      <Heading margin="none" level={3}>
                        {title}
                      </Heading>
                    ) : (
                      title
                    )}
                  </Box>
                )}
              </Box>
              {sidebarItems.length === 0 || Boolean(userProfile) === false ? (
                <Box align="center" justify="center" fill></Box>
              ) : (
                <>
                  <Box overflow={{vertical: 'auto'}}>
                    <VerticalMenu
                      data={sidebarItems}
                      pathname={pathname}
                      isCollapsed={isCollapsed}
                      hoverIndicatorOptions={hoverIndicatorOptions}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )
      }}
    </ResponsiveContext.Consumer>
  )
}

Sidebar.defaultProps = {
  width: '250px',
  title: (
    <Link to="/dashboard">
      <Image
        alignSelf="center"
        src={logoImage}
        alt="Granite Logo"
        width="159px"
        height="64px"
      />
    </Link>
  ),
}

const mapStateToProps = state => ({
  sidebarItems: SidebarDucks.sidebarItems(state),
  userProfile: UserDucks.profile(state),
  userPermissions: UserDucks.permissions(state),
  collapsed: SidebarDucks.sidebarCollapsed(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
