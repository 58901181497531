import React, {Children, useRef, useState} from 'react'
import styled, {withTheme} from 'styled-components'
import {Box, Button, Drop, Text} from 'grommet'
import PropTypes from 'prop-types'

// NOTE: drop-shadow not supported in most browsers
const ArrowDown = styled.span`
  position: relative;
  top: ${props => props.theme.global.edgeSize.small};
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: ${props =>
    `10px solid ${props.theme.global.colors[props.background]}`};
  transform: translate(0, 100%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  align-self: center;
`

const ArrowUp = styled.span`
  position: relative;
  top: ${props => '-' + props.theme.global.edgeSize.small};
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: ${props =>
    `10px solid ${props.theme.global.colors[props.background]}`};
  transform: translate(0, -100%);
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  align-self: center;
`

const Popconfirm = ({
  align,
  background,
  cancelText,
  children,
  okText,
  onCancel: userOnCancel,
  onConfirm: userOnConfirm,
  title,
  theme,
  ...rest
}) => {
  const cancelBtnColor = theme.global.colors['status-critical']
  const defaultIsVisible = false
  const [isVisible, setIsVisible] = useState(defaultIsVisible)

  const childRef = useRef()

  const handleConfirmClick = () => {
    userOnConfirm()
    setIsVisible(false)
  }
  const handleCancel = () => {
    userOnCancel()
    setIsVisible(false)
  }

  const getAlignmentObj = () => {
    const alignmentObj = {}
    switch (align) {
      case 'left':
        alignmentObj.right = 'left'
        break
      case 'right':
        alignmentObj.left = 'right'
        break
      case 'top':
        alignmentObj.bottom = 'top'
        break
      case 'bottom':
        alignmentObj.top = 'bottom'
        break
      default:
        break
    }
    return alignmentObj
  }
  return (
    <>
      <span
        style={{display: 'inline-block'}}
        onClick={() => setIsVisible(true)}
        ref={childRef}
      >
        {Children.only(children)}
      </span>
      {childRef.current && isVisible && (
        <Drop
          align={getAlignmentObj()}
          elevation="small"
          target={childRef.current}
          overflow="visible"
          onClickOutside={handleCancel}
          plain
        >
          <Box
            gap="xsmall"
            margin="xsmall"
            pad="small"
            background={background}
            round="xsmall"
            {...rest}
          >
            {align === 'bottom' && (
              <ArrowUp background={background} theme={theme} />
            )}
            <Text>{title}</Text>
            <Box gap="small" direction="row" align="center" justify="center">
              <Button
                label={okText}
                onClick={handleConfirmClick}
                color="brand"
                primary
              />
              <Button
                label={
                  <Text color="white">
                    <strong>{cancelText}</strong>
                  </Text>
                }
                onClick={handleCancel}
                primary
                color={cancelBtnColor}
              />
            </Box>
            {align === 'top' && (
              <ArrowDown background={background} theme={theme} />
            )}
          </Box>
        </Drop>
      )}
    </>
  )
}

Popconfirm.defaultProps = {
  align: 'top',
  background: 'light-4',
  cancelText: 'No',
  onCancel: () => {},
  okText: 'Yes',
  title: 'Are you sure ?',
}

Popconfirm.propTypes = {
  align: PropTypes.oneOf(['bottom', 'left', 'right', 'top']),
  background: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  okText: PropTypes.string,
  title: PropTypes.string,
}

export default withTheme(Popconfirm)
