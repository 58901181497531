import React, {Children, cloneElement} from 'react'
import {Box, Text} from 'grommet'
import {withTheme} from 'styled-components'

const FormField = ({
  label,
  name,
  help,
  error,
  theme,
  children,
  backgroundLabel,
  boxMargin,
  required,
  info,
  ...rest
}) => {
  const {formField} = theme
  const borderColor = theme.global.colors['status-critical']

  const normalizedChildren = children
    ? Children.map(children, child => {
        if (child) {
          return cloneElement(child, {
            style: {
              borderColor: error ? borderColor : null,
            },
            error,
          })
        }
        return child
      })
    : null

  return (
    <Box margin={{...formField.margin}} {...rest} background={backgroundLabel}>
      <Box direction="row">
        <Text as="label" htmlFor={name} size="small" {...formField.label}>
          {label}
        </Text>
        {required === 'yes' ? <Text color="warning"> {' * '}</Text> : ''}
        {false && <Text size="xsmall"> {info}</Text>}
      </Box>

      {help && (
        <Text
          {...formField.help}
          color={formField.help.color[theme.dark ? 'dark' : 'light']}
        >
          {help}
        </Text>
      )}
      {normalizedChildren}
      {error && (
        <Text {...formField.error} color="warning">
          {error}
        </Text>
      )}
    </Box>
  )
}

export default withTheme(FormField)
