// Lib Imports
import React, {useEffect, useState} from 'react'
import {Box, Heading} from 'grommet'
import {withRouter} from 'react-router-dom'

// CORE Imports
import EventEmitter from 'utils/event-emitter'
import REGISTER_MESSAGES from 'messages/register'
import Toast from 'granite/components/Toast'

// Application Imports
import REGISTER_EVENTS from './constants'
import {registerClicked} from 'accounts/controllers/user'
import RegisterForm from './components/RegisterForm'

function listenEvents(eventEmitter, setToastData, history) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case REGISTER_EVENTS.REGISTER_SUCCESS:
        setToastData({
          open: true,
          message: REGISTER_MESSAGES.REGISTER_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
          history.push('/dashboard')
        }, 1000)
        // dispatch({
        //   type: 'accounts/user/UPDATE_PROFILE',
        //   data: event.data,
        // })
        break
      case REGISTER_EVENTS.REGISTER_FAILURE:
        setToastData({
          open: true,
          message: REGISTER_MESSAGES.REGISTER_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        break
      default:
        break
    }
  })
  return subscription
}

const Register = ({dispatch, history}) => {
  const eventEmitter = new EventEmitter()
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  useEffect(
    function init() {
      const subscription = listenEvents(eventEmitter, setToastData, history)
      return () => subscription.unsubscribe()
    },
    [eventEmitter, history],
  )

  return (
    <>
      <Box
        align="center"
        background="brand-3p"
        flex="grow"
        justify="center"
        margin="none"
        pad="medium"
      >
        <Box width="large" animation="fadeIn" pad="medium">
          <Heading color="dark-1" level={1} margin={{bottom: 'large'}}>
            Register to Granite
          </Heading>
          <RegisterForm
            registerClicked={registerClicked}
            eventEmitter={eventEmitter}
          />
        </Box>
        {toastData.open ? <Toast text={toastData.message} /> : null}
      </Box>
    </>
  )
}

export default withRouter(Register)
