import React, {useState, useEffect} from 'react'
import {Box, Text, Button} from 'grommet'
import Toast from 'granite/components/Toast'
import {useRouteMatch} from 'react-router-dom'
import EventEmitter from 'utils/event-emitter'
import CLIENT_DETAIL_EVENTS from './constants'
import {useHistory, useLocation} from 'react-router-dom'
import ClientInfo from './components/ClientInfo'
import ScreenActivity from './components/ScreenActivity'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'
import NewHeader from 'granite/components/HeaderWithDropDown'
import LoaderUI from 'granite/components/NumLoader'
import Notes from './components/notes'
import Overview from './components/overview'
import CLIENT_DETAIL_EVENTS_MESSAGES from 'messages/clientDetails'
import LOAN_DETAIL_MESSAGE from 'messages/clientloandetails'
import {getClientInfo, getLoanList} from 'clientDetails/controllers/client'
import {
  getLoan,
  getApplicants,
  getScreenACtivity,
  getClientSavings,
  getMailTemplateList,
  getSecurities,
  getLenders,
} from 'clientloandetail/controllers/loan'
import LoanSummaryForm from './components/LoanSummaryForm'
import AddLoanSummaryForm from './components/addLoanSumaryForm'
import {getClientDetailsNotes} from 'clientDetails/controllers/client'
import LoanNotes from 'pages/clientdetails/components/notes'
import styled from 'styled-components'
import MailModal from './components/mailmodal'
import ApplicantUnLink from './components/applicantUnLinkwarning'
import SECURITYADDRESSFORM from './components/security_address_form'
import queryString from 'query-string'
// import { FormatListNumbered } from 'styled-icons/material'

const ButtonStyle = styled(Button)`
  height: 36px;
`

let eventEmitter = new EventEmitter()

function listenEvents({
  history,
  eventEmitter,
  setToastData,
  setToastWarning,
  setClientData,
  clientId,
  setNotesData,
  setLayer,
  setID,
  setparticularloanDetail,
  setLoader,
  particularloanDetail,
  setisLoanActive,
  setapplicants,
  route,
  setLoanNotesData,
  setLoanNotesLayer,
  setscreenActivityData,
  settotalSavings,
  setalrSavings,
  setmaillist,
  settemplatename,
  setselectedapplicant,
  setEdit,
  settemplatecontent,
  setapplicantwarningpopup,
  setsecurityaddresses,
  setsecurityAddressFormVisibility,
  SetLenders,
  setEditableType,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SUCCESS:
        setClientData([
          ['Name', `${event.data[0].name}`],
          ['Lender', `${event.data[0].client_type}`],
          ['Phone no.', `${event.data[0].phone}`],
          ['Email', `${event.data[0].email}`],
          // ['Company', `${event.data[0].company}`],
        ])
        setID(`${event.data[0].pk_Update}`)
        // setTimeout(() => {
        //   setLoader(false)
        // }, 1000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_GET_INFO_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_SUCCESS:
        let tempObj = event.data[0]
        setparticularloanDetail(tempObj)
        setisLoanActive(tempObj.is_active)
        setTimeout(() => {
          setLoader(false)
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.LOAN_APPLICANTS_GET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_UPDATE_SUCCESS:
        // getLoan(eventEmitter, route.params.id)
        getClientSavings(eventEmitter, route.params.clientid, route.params.id)
        setEdit(true)
        setLoanNotesLayer(false)
        setToastWarning(false)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.LOAN_SUMMARY_SAVE_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setTimeout(() => {
          setLoader(false)
        }, 1000)

        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_UPDATE_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.LOAN_SUMMARY_SAVE_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setTimeout(() => {
          setLoader(false)
        }, 1000)

        break

      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_APPLICANTS_SUCCESS:
        setapplicants(event.data)
        setselectedapplicant(event.data[0])
        setTimeout(() => {
          setLoader(false)
        }, 1000)

        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_APPLICANTS_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_SUCCESS:
        if (event.data) setLoanNotesData(event.data.data)
        setToastWarning(false)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_GET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_SUCCESS:
        setToastWarning(false)
        // getClientDetailsNotes(eventEmitter, route.params.id)
        setLoanNotesLayer(true)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_ADD_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_FAILURE:
        setLoanNotesLayer(false)
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_ADD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_SUCCESS:
        getClientDetailsNotes(eventEmitter, route.params.id)
        setToastWarning(false)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_SCREEN_ACTIVITY_SUCCESS:
        if (event.data.tableData.length > 0) setscreenActivityData(event.data)
        else
          setscreenActivityData({
            tableData: [],
            count: 0,
            next_page: null,
            previous_page: null,
            rows: 0,
          })
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_SCREEN_ACTIVITY_FAILURE:
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_SAVINGS_SUCCESS:
        setalrSavings(event.data[0].alrSavings)
        settotalSavings(event.data[0].totalSavings)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_SAVINGS_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_SAVINGS_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      // CLIENT_LOAN_ADD_SUCCESS

      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_ADD_SUCCESS:
        history.push(
          '/clients/' +
            route.params.clientid +
            '/' +
            event.data.response_loan.data.pk,
        )
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_ADD_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_ADD_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_ADD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break

      // GET MAIL
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_GET_MAIL_TEMPLATE_SUCCESS:
        setmaillist(event.data[0].list)
        settemplatename(event.data[0].activity_name)
        const tempcontentarr = {}
        if (event.data.length > 0) {
          event.data.forEach(item => {
            tempcontentarr[item.email_template.name] =
              item.email_template.content
          })
        }
        settemplatecontent(tempcontentarr)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_GET_MAIL_TEMPLATE_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_ADD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      // SEND MAIL
      case CLIENT_DETAIL_EVENTS.CLIENT_SEND_MAIL_SUCCESS:
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_SEND_MAIL_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_SEND_MAIL_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_SEND_MAIL_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      // CLIENT_UPDATE_OVERVIEW_SUCCESS

      case CLIENT_DETAIL_EVENTS.CLIENT_UPDATE_OVERVIEW_SUCCESS:
        // console.log('I am id ',route.params.id)
        // getLoan(eventEmitter, route.params.id)
        getClientSavings(eventEmitter, route.params.clientid, route.params.id)
        setToastWarning(false)
        setToastData({
          open: true,
          message: LOAN_DETAIL_MESSAGE.CLIENT_UPDATE_OVERVIEW_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_UPDATE_OVERVIEW_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.CLIENT_UPDATE_OVERVIEW_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break

      // Remove applicants

      case CLIENT_DETAIL_EVENTS.CLIENT_REMOVE_APPLICANT_SUCCESS:
        setToastWarning(false)
        setToastData({
          open: true,
          message: LOAN_DETAIL_MESSAGE.CLIENT_REMOVE_APPLICANT_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setapplicantwarningpopup(false)
        setTimeout(() => {
          setLoader(true)
        }, 1000)
        // getApplicants(eventEmitter, route.params.id)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_REMOVE_APPLICANT_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.CLIENT_REMOVE_APPLICANT_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break

      // SECURITY ADDRESS CRUD GET

      case CLIENT_DETAIL_EVENTS.LOAN_GET_SECURITY_SUCCESS:
        setsecurityaddresses(event.data)

        break
      case CLIENT_DETAIL_EVENTS.LOAN_GET_SECURITY_FAILURE:
        setsecurityaddresses([])
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.LOAN_GET_SECURITY_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break

      // SECURITY ADDRESS CRUD ADD

      case CLIENT_DETAIL_EVENTS.LOAN_ADD_SECURITY_SUCCESS:
        // getSecurities(eventEmitter, route.params.clientid, route.params.id)
        setsecurityAddressFormVisibility({show: false})
        setToastWarning(false)
        setToastData({
          open: true,
          message: LOAN_DETAIL_MESSAGE.LOAN_ADD_SECURITY_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break
      case CLIENT_DETAIL_EVENTS.LOAN_ADD_SECURITY_FAILURE:
        setsecurityAddressFormVisibility({show: false})
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.LOAN_ADD_SECURITY_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break
      // SECURITY ADDRESS CRUD UPDATE
      case CLIENT_DETAIL_EVENTS.LOAN_UPDATE_SECURITY_SUCCESS:
        setsecurityAddressFormVisibility({show: false})
        // getSecurities(eventEmitter, route.params.clientid, route.params.id)
        setToastWarning(false)
        setToastData({
          open: true,
          message: LOAN_DETAIL_MESSAGE.LOAN_UPDATE_SECURITY_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break
      case CLIENT_DETAIL_EVENTS.LOAN_UPDATE_SECURITY_FAILURE:
        setsecurityAddressFormVisibility({show: false})
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.LOAN_UPDATE_SECURITY_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break

      // SECURITY ADDRESS CRUD DELETE
      case CLIENT_DETAIL_EVENTS.LOAN_DELETE_SECURITY_SUCCESS:
        // getSecurities(eventEmitter, route.params.clientid, route.params.id)
        setsecurityAddressFormVisibility({show: false})

        setToastWarning(false)
        setToastData({
          open: true,
          message: LOAN_DETAIL_MESSAGE.LOAN_DELETE_SECURITY_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break
      case CLIENT_DETAIL_EVENTS.LOAN_DELETE_SECURITY_FAILURE:
        setToastWarning(true)
        setsecurityAddressFormVisibility({show: false})
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.LOAN_DELETE_SECURITY_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEdit(true)
        break

      case CLIENT_DETAIL_EVENTS.LOAN_GET_ALL_LENDERS_SUCCESS:
        SetLenders(event.data)

        break
      case CLIENT_DETAIL_EVENTS.LOAN_GET_ALL_LENDERS_FAILURE:
        console.log('I am here in get lenders fail')

        break
      case CLIENT_DETAIL_EVENTS.UPDATE_APPLICANT_TYPE_SUCCESS:
        setToastWarning(false)
        setToastData({
          open: true,
          message: LOAN_DETAIL_MESSAGE.UPDATE_APPLICANT_TYPE_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEditableType(false)
        break
      case CLIENT_DETAIL_EVENTS.UPDATE_APPLICANT_TYPE_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : LOAN_DETAIL_MESSAGE.UPDATE_APPLICANT_TYPE_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setEditableType(false)
        break
      default:
        break
    }
  })
  return subsciption
}
const ClientDetail = ({userProfile, permissions}) => {
  const history = useHistory()
  const route = useRouteMatch()
  let location = useLocation()
  const {search} = location
  let defaultFilterData = {
    page: 1,
    page_size: 20,
  }
  const [loader, setLoader] = useState(true)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [clientId, setclientId] = useState()
  const [toastWarning, setToastWarning] = useState(false)
  const [layer, setLayer] = useState(false)
  const [notesData, setNotesData] = useState([])
  const [ID, setID] = useState()
  const [particularloanDetail, setparticularloanDetail] = useState([])
  const [isLoanActive, setisLoanActive] = useState(true)
  const [applicants, setapplicants] = useState([])
  const [loanNotesLayer, setLoanNotesLayer] = useState(false)
  const [loanNotesData, setLoanNotesData] = useState([])
  const [edit, setEdit] = useState(search !== '' ? false : true)
  const [totalSavings, settotalSavings] = useState(0)
  const [alrSavings, setalrSavings] = useState(0)
  const [screenActivityData, setscreenActivityData] = useState([])
  const [addloanbtndisable, setaddloanbtndisable] = useState(false)
  const [showmailmodal, setshowmailmodal] = useState(false)
  const [maillist, setmaillist] = useState([
    'Notify to Review ALR',
    'No Action Required ALR',
    'Rate Reduction ALR Email',
    'Interest / Fixed Expiry Template',
    'Missed Call Template',
    'Actions to be completed ALR Email',
    'Recently Dealt with Indigo ALR Email',
  ])
  const [templatename, settemplatename] = useState()
  const [selectedapplicant, setselectedapplicant] = useState()
  const [applicantwarningpopup, setapplicantwarningpopup] = useState(false)
  const [selectedapplicanttoremove, setselectedapplicanttoremove] = useState()
  const [tempcontent, settemplatecontent] = useState('')
  const [refreshState, setrefreshState] = useState(true)
  const [
    securityAddressFormVisibility,
    setsecurityAddressFormVisibility,
  ] = useState({
    type: 'Add',
    show: false,
    formdata: {
      name: '',
      street_name: '',
      street_type: '',
      street_number: '',
      city: '',
      state: '',
      postcode: '',
      unit_number: '',
      building: '',
    },
  })
  const [clientData, setClientData] = useState([
    ['Name', ''],
    ['Lender', ''],
    ['Phone', ''],
    ['Email', ''],
    ['Company', ''],
  ])
  const [securityaddresses, setsecurityaddresses] = useState([])
  const [Lenders, SetLenders] = useState()
  const [editableType, setEditableType] = useState(false)

  const handleDischarge = () => {
    if (isLoanActive) {
      setLayer(true)
    }
  }

  const handleRefresh = () => {
    if (route.path === '/clients/:clientid/addloan') {
      setrefreshState(!refreshState)
    } else {
      setLoader(true)
      if (!loader) {
        setEdit(true)
        setLayer(false)
        setLoanNotesLayer(false)
        getClientSavings(eventEmitter, route.params.clientid, route.params.id)
        getApplicants(eventEmitter, route.params.id)
        getClientInfo(eventEmitter, route.params.clientid)
        getLoanList(eventEmitter, route.params.id)
        let screenactivityparams = {
          entity_id: route.params.id,
          entity_type_name: 'loaninfo',
        }
        getScreenACtivity(screenactivityparams, eventEmitter)
        getSecurities(eventEmitter, route.params.clientid, route.params.id)
      }
    }
  }

  // const handleBackNavigation = () => {
  //   history.goBack()
  // }
  const handleLoanNotes = () => {
    setLoanNotesLayer(true)
  }

  // let history = useHistory()5

  let queryParam = queryString.parse(search)
  // const [value, setValue] = useState(queryParam.search ? queryParam.search : '')
  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    setclientId(route.params.id)
    const subscription = listenEvents({
      eventEmitter,
      history,
      setToastData,
      setToastWarning,
      setClientData,
      setNotesData,
      setLayer,
      setID,
      setparticularloanDetail,
      setLoader,
      setisLoanActive,
      setapplicants,
      route,
      setLoanNotesData,
      setLoanNotesLayer,
      setscreenActivityData,
      settotalSavings,
      setalrSavings,
      setmaillist,
      settemplatename,
      setselectedapplicant,
      setEdit,
      settemplatecontent,
      setapplicantwarningpopup,
      setsecurityaddresses,
      setsecurityAddressFormVisibility,
      SetLenders,
      setEditableType,
    })
    function init() {
      if (route.path === '/clients/:clientid/addloan') {
        getClientInfo(eventEmitter, route.params.clientid)
        getLenders(eventEmitter, route.params.clientid, route.params.id)
        setaddloanbtndisable(true)
        setLoader(false)
        setEdit(false)
      } else {
        // API CALLS TO GET GET CLIENT INFO AND LOAN DETAILS AND SCREEN ACTIVITY
        getClientInfo(eventEmitter, route.params.clientid)
        getLoan(eventEmitter, route.params.id)
        getApplicants(eventEmitter, route.params.id)
        getClientDetailsNotes(eventEmitter, route.params.id)
        let screenactivityparams = {
          entity_id: route.params.id,
          entity_type_name: 'loaninfo',
          page: filterData.page,
          page_size: filterData.page_size,
        }

        getScreenACtivity(screenactivityparams, eventEmitter)
        getClientSavings(eventEmitter, route.params.clientid, route.params.id)
        getMailTemplateList(eventEmitter, 'LOAN_SUMMARY', route.params.clientid)
        getSecurities(eventEmitter, route.params.clientid, route.params.id)
        getLenders(eventEmitter, route.params.clientid, route.params.id)
      }
    }

    init()
    return () => {
      subscription.unsubscribe()
    }
  }, [route, history, filterData])
  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'Client List / Client Details / Loan Detail'}
        showSavings={true}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
        totalSavings={totalSavings}
        alrSavings={alrSavings}
        hideAlrSavings
      />
      <Box overflow={{vertical: 'auto'}} fill>
        {loader ? (
          <Box align="center" justify="center" fill>
            <LoaderUI />
          </Box>
        ) : (
          <>
            <Box
              pad={{
                top: 'none',
                bottom: 'none',
                left: 'medium',
                right: 'medium',
              }}
            >
              <Box direction="row" width="100%">
                <Box
                  pad={{
                    top: 'xxsmall',
                    bottom: 'small',
                    left: 'none',
                    right: 'xxsmall',
                  }}
                  alignSelf="end"
                  width="50%"
                  direction="row"
                >
                  {/* <Box
                    width="2%"
                    align="start"
                    pad={{
                      top: 'medium',
                      bottom: 'none',
                      left: 'small',
                      right: 'none',
                    }}
                    onClick={() => {
                      handleBackNavigation()
                    }}
                  >
                    <Image
                      margin={{top: 'xxsmall'}}
                      height="14px"
                      fit="contain"
                      src={process.env.PUBLIC_URL + '/img/left_arrow.png'}
                      style={{cursor: 'pointer'}}
                    />
                  </Box>*/}
                  <Box
                    gap="xsmall"
                    direction="row"
                    pad={{
                      top: 'medium',
                      bottom: 'none',
                      left: 'small',
                      right: 'none',
                    }}
                  >
                    <Text
                      weight="bold"
                      color="dark"
                      size="14px"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        history.push('/clients')
                      }}
                    >
                      {'Clients'}
                    </Text>
                    <Text color="dark" size="14px">
                      {'/'}
                    </Text>

                    <Text
                      weight="bold"
                      color="dark"
                      size="14px"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        history.push('/clients/' + route.params.clientid)
                      }}
                    >
                      {clientData[0][1].length > 1
                        ? clientData[0][1]
                        : 'Client Details'}
                    </Text>
                    <Text color="dark" size="14px">
                      {' / '}
                    </Text>
                    <Text color="dark" size="14px">
                      {'Loan Details'}
                    </Text>
                    {/* <Text
                      weight="bold"
                      color="dark"
                      size="14px"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        handleBackNavigation()
                      }}
                    >
                      {'Clients / ' + clientData[0][1] + '/ Loan Details'}
                    </Text>*/}
                  </Box>
                </Box>
                <Box width="50%" direction="row" justify="end" pad="small" wrap>
                  <Box direction="row" pad="none" gap="small">
                    {permissions !== null &&
                      permissions.includes('loan_info_edit') && (
                        <Button
                          primary
                          disabled={!isLoanActive || addloanbtndisable}
                          label="Associate Applicant"
                          onClick={() => {
                            history.push(
                              '/addApplicants/' +
                                route.params.clientid +
                                '/' +
                                route.params.id +
                                '?client_type=IN',
                            )
                          }}
                        />
                      )}

                    <ButtonStyle
                      primary
                      disabled={!isLoanActive || addloanbtndisable}
                      label="Loan Notes"
                      onClick={() => {
                        handleLoanNotes()
                      }}
                    />
                    {permissions !== null &&
                      permissions.includes('loan_info_edit') && (
                        <ButtonStyle
                          primary
                          color={'warningButton'}
                          disabled={!isLoanActive || addloanbtndisable}
                          label="Discharge"
                          onClick={() => {
                            handleDischarge()
                          }}
                        />
                      )}
                  </Box>
                </Box>
              </Box>
              {loanNotesLayer && (
                <LoanNotes
                  layer={loanNotesLayer}
                  setLayer={setLoanNotesLayer}
                  notesData={loanNotesData}
                  event={eventEmitter}
                  route={route}
                  setshowmailmodal={setshowmailmodal}
                  showmailmodal={showmailmodal}
                  permissions={permissions}
                />
              )}
              {layer && (
                <Notes
                  layer={layer}
                  setLayer={setLayer}
                  notesData={notesData}
                  event={eventEmitter}
                  route={route}
                  permissions={permissions}
                  loanNotesLayer={loanNotesLayer}
                  setLoanNotesLayer={setLoanNotesLayer}
                />
              )}
              {showmailmodal && (
                <MailModal
                  setshowmailmodal={setshowmailmodal}
                  showmailmodal={showmailmodal}
                  event={eventEmitter}
                  route={route}
                  loggedinuser={userProfile.email}
                  client={selectedapplicant.email}
                  clientfirdtname={selectedapplicant.first_name}
                  sendername={userProfile.name}
                  designation={userProfile.designation}
                  maillist={maillist}
                  templatename={templatename}
                  selectedapplicant={selectedapplicant}
                  tempcontent={tempcontent}
                ></MailModal>
              )}

              {applicantwarningpopup && (
                <ApplicantUnLink
                  applicantwarningpopup={applicantwarningpopup}
                  setapplicantwarningpopup={setapplicantwarningpopup}
                  event={eventEmitter}
                  route={route}
                  setselectedapplicanttoremove={setselectedapplicanttoremove}
                  selectedapplicanttoremove={selectedapplicanttoremove}
                  applicants={applicants}
                  setToastData={setToastData}
                  setToastWarning={setToastWarning}
                />
              )}
              <Box gap="medium">
                {route.path !== '/clients/:clientid/addloan' && (
                  <ClientInfo
                    applicants={applicants}
                    setselectedapplicant={setselectedapplicant}
                    applicantwarningpopup={applicantwarningpopup}
                    setapplicantwarningpopup={setapplicantwarningpopup}
                    setselectedapplicanttoremove={setselectedapplicanttoremove}
                    permissions={permissions}
                    isLoanActive={isLoanActive}
                    eventEmitter={eventEmitter}
                    loanId={route.params.id}
                    editableType={editableType}
                    setEditableType={setEditableType}
                  />
                )}
                {securityAddressFormVisibility.show && (
                  <SECURITYADDRESSFORM
                    event={eventEmitter}
                    route={route}
                    setToastData={setToastData}
                    setToastWarning={setToastWarning}
                    setsecurityAddressFormVisibility={
                      setsecurityAddressFormVisibility
                    }
                    securityAddressFormVisibility={
                      securityAddressFormVisibility
                    }
                  />
                )}
                <Box width="100%" direction="row">
                  <Box width="20%">
                    {route.path === '/clients/:clientid/addloan' ? (
                      <Overview
                        clientData={clientData}
                        eventEmitter={eventEmitter}
                        clientId={clientId}
                        ID={ID}
                        particularloanDetail={particularloanDetail}
                        route={route}
                        edit={true}
                        setEdit={setEdit}
                        alrSavings={alrSavings}
                      />
                    ) : (
                      <Overview
                        clientData={clientData}
                        eventEmitter={eventEmitter}
                        clientId={clientId}
                        ID={ID}
                        particularloanDetail={particularloanDetail}
                        route={route}
                        edit={edit}
                        setEdit={setEdit}
                        setToastWarning={setToastWarning}
                        setToastData={setToastData}
                        alrSavings={alrSavings}
                      />
                    )}
                  </Box>
                  <Box width="80%" align="stretch" gap="small">
                    {route.path === '/clients/:clientid/addloan' ? (
                      <AddLoanSummaryForm
                        eventEmitter={eventEmitter}
                        particularloanDetail={particularloanDetail}
                        isLoanActive={!isLoanActive}
                        edit={edit}
                        setEdit={setEdit}
                        setToastWarning={setToastWarning}
                        setToastData={setToastData}
                        route={route}
                        clientData={clientData}
                        setrefreshState={setrefreshState}
                        refreshState={refreshState}
                        Lenders={Lenders}
                      />
                    ) : (
                      <LoanSummaryForm
                        eventEmitter={eventEmitter}
                        particularloanDetail={particularloanDetail}
                        isLoanActive={!isLoanActive}
                        edit={edit}
                        setEdit={setEdit}
                        setToastWarning={setToastWarning}
                        setToastData={setToastData}
                        setshowmailmodal={setshowmailmodal}
                        showmailmodal={showmailmodal}
                        selectedapplicant={selectedapplicant}
                        permissions={permissions}
                        userProfile={userProfile}
                        securityaddresses={securityaddresses}
                        Lenders={Lenders}
                        setsecurityAddressFormVisibility={
                          setsecurityAddressFormVisibility
                        }
                        securityAddressFormVisibility={
                          securityAddressFormVisibility
                        }
                      />
                    )}
                    <Box pad={{top: 'medium', bottom: 'medium'}}>
                      <ScreenActivity
                        route={route}
                        screenActivityData={screenActivityData}
                        event={eventEmitter}
                        navigateToParams={navigateToParams}
                        filterData={filterData}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
        {toastData.open ? (
          toastWarning ? (
            <Toast
              text={toastData.message}
              background="warning"
              onClose={() => {
                setToastData({
                  open: false,
                  message: '',
                })
              }}
            />
          ) : (
            <Toast
              text={toastData.message}
              onClose={() => {
                setToastData({
                  open: false,
                  message: '',
                })
              }}
            />
          )
        ) : null}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})

export default connect(mapStateToProps)(ClientDetail)
