import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {Box, Button, Text} from 'grommet'
import styled, {withTheme} from 'styled-components'
import Divider from 'granite/components/Divider'

const StyledBox = styled(Box)`
  cursor: pointer;
`

const Steps = React.memo(
  ({
    async,
    children,
    direction,
    activeStep: defaultActiveStep,
    onChange,
    navigationVisible,
    steps,
    theme,
    ...rest
  }) => {
    const [activeStep, setActiveStep] = useState(defaultActiveStep)
    if (!Array.isArray(children)) children = [children]

    useEffect(() => {
      setActiveStep(defaultActiveStep)
    }, [defaultActiveStep])

    const handleStepTitleClickAsync = async index => {
      try {
        let isAllowed = true
        isAllowed = await onChange(index, activeStep)
        if (isAllowed) setActiveStep(index)
      } catch (error) {
        console.error(error)
      }
    }

    const handleStepTitleClick = index => {
      const isAllowed = onChange(index, activeStep)
      if (isAllowed) setActiveStep(index)
    }

    const stepsNavigation = steps.map((step, i) => {
      if (!navigationVisible) return null
      const isActive = i === activeStep
      return (
        <React.Fragment key={step.title}>
          <StyledBox
            align="center"
            direction="row"
            key={step.title}
            gap="xsmall"
            onClick={() =>
              async ? handleStepTitleClickAsync(i) : handleStepTitleClick(i)
            }
          >
            {/* if render icon provided by user else render button */}
            {step.icon ? (
              step.icon
            ) : (
              <Button label={i + 1} primary={isActive} />
            )}
            <Text weight={isActive ? 'bold' : 'normal'}>{step.title}</Text>
          </StyledBox>
          {/* Prevent last divider from rendering */}
          {i !== steps.length - 1 && direction === 'row' && (
            <Box flex="grow">
              <Divider
                align="start"
                color={i < activeStep ? theme.global.colors.brand : null}
              />
            </Box>
          )}
        </React.Fragment>
      )
    })

    return (
      <Box direction={direction === 'column' ? 'row' : 'column'}>
        <Box
          align={direction === 'row' ? 'center' : null}
          direction={direction}
          gap="medium"
          {...rest}
        >
          {navigationVisible && stepsNavigation}
        </Box>
        {/* Note to user: Wrap content in flex-box for easy use */}
        {steps[activeStep].content}
      </Box>
    )
  },
)

Steps.defaultProps = {
  async: false,
  direction: 'row',
  activeStep: 0,
  onChange: () => true,
  navigationVisible: true,
}

Steps.propTypes = {
  async: PropTypes.bool,
  activeStep: PropTypes.number,
  direction: PropTypes.oneOf(['row', 'column']),
  onChange: PropTypes.func,
  navigationVisible: PropTypes.bool,
  steps: PropTypes.array,
}

export default withTheme(Steps)
