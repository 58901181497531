import React, {useState} from 'react'
import {withTheme} from 'styled-components'
import {Box, TextInput, Button, Image} from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'
const PasswordInput = ({error, value, theme, eyeVisible, ...rest}) => {
  const [reveal, setReveal] = useState(false)

  const ButtonStyle = styled(Button)`
    position: absolute;
    right: 0px;
  `

  // FIXME: pad should not be used
  return (
    <Box
      direction="row"
      align="center"
      round="xsmall"
      background="white"
      pad="hair"
      style={{position: 'relative'}}
    >
      <TextInput type={reveal ? 'text' : 'password'} {...rest} />
      {eyeVisible && (
        <ButtonStyle
          icon={
            reveal ? (
              <Box>
                <Image
                  height="20px"
                  src={process.env.PUBLIC_URL + 'img/Hide.png'}
                />
              </Box>
            ) : (
              <Box>
                <Image
                  height="18px"
                  src={process.env.PUBLIC_URL + 'img/View.png'}
                />
              </Box>
            )
          }
          onClick={() => setReveal(!reveal)}
          style={{padding: '8px'}}
        />
      )}
    </Box>
  )
}

PasswordInput.defaultProps = {
  error: null,
  theme: null,
}

PasswordInput.propTypes = {
  error: PropTypes.object,
  theme: PropTypes.object,
}

export default withTheme(PasswordInput)
