import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {Box} from 'grommet'
import EventEmitter from 'utils/event-emitter'
import RESET_MESSAGES from 'messages/signup'
import PiggyImage from 'granite/components/PiggyImage'
import Steps from 'granite/components/Steps'
import SignupForm from './components/SignupForm'
import SuccessOnPasswordSubmit from './components/SuccessPage'
import ResetPasswordForm from './components/ResetPasswordForm'
import {
  setNewPasswordClicked,
  requestTokenClicked,
  signupLanding,
} from 'accounts/controllers/user'
import RESET_EVENTS from './constants'
import Toast from 'granite/components/Toast'
import useQuery from 'hooks/useQuery'

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setActiveStep,
  history,
  setTempPassword,
  setError,
  setEmail,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case RESET_EVENTS.SIGNUP_SUCCESS:
        if (event.data) {
          setTempPassword(event.data.data.tmp_password)
          setEmail(event.data.data.email)
        }

        break
      case RESET_EVENTS.SIGNUP_FAILURE:
        setError(true)
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        break

      case RESET_EVENTS.RESET_PASSWORD_SUCCESS:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_PASSWORD_SUCCESS,
        })
        setTimeout(() => {
          setActiveStep(2)
          setToastData({open: false, message: ''})
        }, 1000)
        break
      case RESET_EVENTS.RESET_PASSWORD_FAILURE:
        setError(true)
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_PASSWORD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        break

      case RESET_EVENTS.SIGNUP_SUBMIT_SUCCESS:
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_SUCCESS,
        })
        setTimeout(() => {
          setActiveStep(1)
          setToastData({open: false, message: ''})
        }, 1000)
        break
      case RESET_EVENTS.SIGNUP_SUBMIT_FAILURE:
        setError(true)
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        break
      default:
        break
    }
  })
  return subsciption
}

const Signup = ({dispatch, history}) => {
  const queryParams = useQuery()
   const token = queryParams.get('token')
  //  const email = queryParams.get('email')
  const defaultActiveStep = 0
  const [activeStep, setActiveStep] = useState(defaultActiveStep)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [tempPassword, setTempPassword] = useState()
  const [error, setError] = useState(false)
  const [email, setEmail] = useState('')

  const getSteps = () => {
    const signupFormOnSubmit = (values, {setSubmitting, setErrors}) => {
      setErrors({})
      setSubmitting(true)
      requestTokenClicked(values, eventEmitter)
      setSubmitting(false)
    }
    const resetPasswordFormOnSubmit = (values, {setSubmitting, setErrors}) => {
      setErrors({})
      setSubmitting(true)
      setNewPasswordClicked(tempPassword, values, eventEmitter)
      setSubmitting(false)
    }

    return [
      {
        content: <SignupForm onSubmit={signupFormOnSubmit} email={email} />,
        icon: <></>,
      },
      {
        content: <ResetPasswordForm onSubmit={resetPasswordFormOnSubmit} />,
        icon: <></>,
      },
      {
        content: <SuccessOnPasswordSubmit />,
        icon: <></>,
      },
    ]
  }
  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setActiveStep,
        setTempPassword,
        setError,
        setEmail,
      })

      signupLanding(token, eventEmitter)
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [token])

  return (
    <>
      <Box
        direction="row"
        width="100%"
        background=""
        flex="grow"
        justify="center"
        margin="none"
      >
        <Box align="center" background="" flex="grow" justify="center">
          <PiggyImage />
        </Box>
        <Box
          align="start"
          background=""
          flex="grow"
          justify="center"
          margin="none"
          pad={{top: 'large', bottom: 'large', left: 'none', right: 'large'}}
        >
          <Box
            width="60%"
            justify="start"
            animation="fadeIn"
            pad={{top: 'large', bottom: 'large', left: 'none', right: 'large'}}
          >
            <Steps
              margin="xsmall"
              steps={getSteps()}
              activeStep={activeStep}
              onChange={() => false}
              navigationVisible={false}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        {toastData.open ? (
          error ? (
            <Toast text={toastData.message} background="warning" />
          ) : (
            <Toast text={toastData.message} />
          )
        ) : null}
      </Box>
    </>
  )
}

export default withRouter(Signup)
