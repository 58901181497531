//Library imports
import React, {useState, useEffect} from 'react'
import {Box} from 'grommet'
import {connect} from 'react-redux'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'

//Core imports
import EventEmitter from 'utils/event-emitter'
import Header from 'granite/components/HeaderWithDropDown'
import Toast from 'granite/components/Toast'
import TabComponent from 'granite/components/TabComponent'
import BackNavigation from 'granite/components/BackNavigation'

//Application imports
import {
  fetchNegotiatePricingData,
  campaignListcommon,
} from 'alr/controllers/loanReview'
import NEGOTIATE_PRICING_EVENTS from 'pages/negotiatePricing/constants'
import NEGOTIATE_PRICING_MESSAGES from 'messages/negotiatePricing'
import * as UserDucks from 'accounts/ducks/user'
import MoreDetails from 'pages/contacting/components/MoreDetails'
import CampaignInfo from 'pages/contacting/components/CampaignInfo'
import NegotiatePricingTable from './components/NegotiatePricingTable'
import RiskRate from 'pages/alr/components/RiskRate'

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setCampaignDetails,
  setToastData,
  setData,
  setMoreData,
  setLoader,
  setMoreLoader,
  setRisk,
  setSavings,
  filterData,
  setselectcampaignlist,
  setMoreDataEdit,
  setTabs,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case NEGOTIATE_PRICING_EVENTS.GET_RISK_SUCCESS:
        setRisk(event.data)
        break

      case NEGOTIATE_PRICING_EVENTS.GET_RISK_FAILURE:
        break

      case NEGOTIATE_PRICING_EVENTS.GET_CAMPAIGN_SUCCESS:
        setCampaignDetails(event.data)
        break
      case NEGOTIATE_PRICING_EVENTS.GET_CAMPAIGN_FAILURE:
        break

      case NEGOTIATE_PRICING_EVENTS.GET_LOAN_DETAILS_SUCCESS:
        setTabs({
          new: event.data.tabs_count.new,
          contacting: event.data.tabs_count.contacting,
          Negotiate_Pricing: event.data.tabs_count.Negotiate_Pricing,
          completed: event.data.tabs_count.completed,
          Contact_Client_Of_Result: event.data.tabs_count.Contact_Client_Of_Result,
        })
        setData({data: event.data.loanData, total: event.data.rowsCount})
        setLoader(false)
        break

      case NEGOTIATE_PRICING_EVENTS.GET_LOAN_DETAILS_FAILURE:
        break

      case NEGOTIATE_PRICING_EVENTS.STATUS_CHANGED_SUCCESS:
        const state = event.data === 'Closed' ? 'Pricing Completed' : 'Pricing Requested'
        setToastData({
          open: true,
          message: `Loan Marked ${state} Successfully`,
        })

        const obj = {
          // params: {page: 1, page_size: 20},
          params: filterData
        }
        fetchNegotiatePricingData(eventEmitter, obj, filterData.campaign_id)
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case NEGOTIATE_PRICING_EVENTS.STATUS_CHANGED_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : NEGOTIATE_PRICING_MESSAGES.STATUS_SAVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case NEGOTIATE_PRICING_EVENTS.SHOW_DATA_CHANGED:
        setData(event.data)
        break

      case NEGOTIATE_PRICING_EVENTS.GET_MORE_DETAILS_SUCCESS:
        setMoreData(event.data.moreDetails)
        setMoreDataEdit(event.data)
        setMoreLoader(false)
        break
      case NEGOTIATE_PRICING_EVENTS.GET_MORE_DETAILS_FAILURE:
        break

      case NEGOTIATE_PRICING_EVENTS.SHOW_LOADER:
        setLoader(true)
        break
      case NEGOTIATE_PRICING_EVENTS.HIDE_LOADER:
        setLoader(false)
        break

      case NEGOTIATE_PRICING_EVENTS.GET_SAVINGS_SUCCESS:
        setSavings(event.data)
        break
      case NEGOTIATE_PRICING_EVENTS.GET_SAVINGS_FAILURE:
        break
      case NEGOTIATE_PRICING_EVENTS.GET_CAMPAIGN_LIST_SUCCESS:
        setselectcampaignlist(event.data)
        break
      case NEGOTIATE_PRICING_EVENTS.GET_CAMPAIGN_LIST_FAILURE:
        break
      default:
        break
    }
  })
  return subscription
}

const NegotiatePricing = ({userProfile}) => {
  const [tabs, setTabs] = useState({
    new: 0,
    contacting: 0,
    Negotiate_Pricing: 0,
    completed: 0,
    Contact_Client_Of_Result: 0,
  })
  const [campaignDetails, setCampaignDetails] = useState()
  const [data, setData] = useState({data: [], total: ''})
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [moreDetailLayer, setMoreDetailLayer] = useState(false)
  const history = useHistory()
  const [moreData, setMoreData] = useState([])
  const [moreDataEdit, setMoreDataEdit] = useState()
  const [loader, setLoader] = useState(false)
  const [moreLoader, setMoreLoader] = useState(false)
  const [risk, setRisk] = useState({})
  const [savings, setSavings] = useState()
  const [selectcampaignlist, setselectcampaignlist] = useState({
    campaignData: '',
    temp: [],
  })
  let defaultFilterData = {
    page: 1,
    page_size: 20,
    select_page: 1,
    select_page_size: 20,
    status: 'All_Pricing',
    sorting: 'lender'
  }

  let location = useLocation()
  const {search} = location

  let queryParam = queryString.parse(search)
  const [statusValue, setStatusValue] = useState(
    queryParam.status ? queryParam.status : '',
  )
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )
  const [selcetdcampaign, setselcetdcampaign] = useState(
    queryParam.month_year ? queryParam.month_year : '',
  )

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)

  const handleApplicantClick = selectedClient => {
    let campaign_id = filterData.campaign_id ? filterData.campaign_id : null
    let month_year = filterData.month_year ? filterData.month_year : null
    history.push(
      `/contactclientofresult/${selectedClient.applicant_pk}?campaign_id=${campaign_id}&month_year=${month_year}`,
    )
    // history.push(`/outcome/${selectedClient.applicant_pk}`)
  }

  const handleRefresh = () => {
    setLoader(true)
    setStatusValue('All_Pricing')
    setSearchValue('')
    navigateToParams({
      page: 1,
      page_size: 20,
      search: null,
      status: 'All_Pricing',
      sorting: 'lender'
    })
  }

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setCampaignDetails,
        setToastData,
        setData,
        setMoreData,
        setLoader,
        setMoreLoader,
        setRisk,
        setSavings,
        setselectcampaignlist,
        setMoreDataEdit,
        filterData,
        setTabs,
      })
      const obj = {
        params: filterData,
      }
      fetchNegotiatePricingData(eventEmitter, obj, filterData.campaign_id)
      let select_params = {
        page: filterData.select_page,
        page_size: filterData.select_page_size,
      }
      campaignListcommon(eventEmitter, 'NegotiatePricing', select_params)
      // return () => {
      //   subscription.unsubscribe()
      // }
      return subscription
    }
    let temp = init()
    return () => {
      temp.unsubscribe()
    }
  }, [location, filterData])
  return (
    <Box flex={{grow: 1}}>
      <Header
        pageTitle="Loan Review / Negotiate Pricing"
        userName={`${userProfile.title} ${userProfile.name}`}
        showSavings={true}
        handleRefresh={handleRefresh}
        alrSavings={savings ? savings.alrSavings : ''}
        totalSavings={savings ? savings.totalSavings : ''}
      />
      <Box
        overflow={{vertical: 'auto'}}
        fill
        pad={{left: 'medium', right: 'medium', bottom: 'medium'}}
      >
        <BackNavigation label={'Back'} />

        <Box direction="row-responsive" width="100%" gap="small">
          <Box width="75%">
            <CampaignInfo
              campaignDetails={campaignDetails}
              selcetdcampaign={selcetdcampaign}
              setselcetdcampaign={setselcetdcampaign}
              selectcampaignlist={selectcampaignlist}
              history={history}
              navigateToParams={navigateToParams}
              filterData={filterData}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
          <Box width="25%" background="light-3">
            <RiskRate risk={risk} />
          </Box>
        </Box>

        <TabComponent stats={tabs} history={history} />
        <Box>
          <NegotiatePricingTable
            data={data}
            setData={setData}
            setMoreDetailLayer={setMoreDetailLayer}
            handleApplicantClick={handleApplicantClick}
            eventEmitter={eventEmitter}
            loader={loader}
            setMoreLoader={setMoreLoader}
            navigateToParams={navigateToParams}
            filterData={filterData}
            statusValue={statusValue}
            setStatusValue={setStatusValue}
            setToastData={setToastData}
            risk={risk}
          />
        </Box>
        {moreDetailLayer && (
          <MoreDetails
            setMoreDetailLayer={setMoreDetailLayer}
            moreData={moreData}
            moreLoader={moreLoader}
            moreDataEdit={moreDataEdit}
          />
        )}
      </Box>
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(NegotiatePricing)
