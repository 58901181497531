// Lib imports
import _ from 'lodash/fp'

// Core imports
import GraniteError from 'utils/granite-error'
import {setAuthToken, resetAuthToken} from 'utils/auth-singleton'

// Application imports
import userApi from 'accounts/gateways/user-api'
import RESET_EVENTS from '../../pages/signup/constants'
import UPDATE_EVENTS from '../../pages/userProfile/constants'
import ADD_USER_EVENTS from '../../pages/adduser/constants'
import RESET_PASSWORD_EVENTS from '../../pages/resetpasswordforms/constants'

async function loginClicked(email, password) {
  if (_.isNil(email) || _.isNil(password)) {
    throw new GraniteError('Credentials are invalid')
  }
  const data = await userApi.login(email, password)
  setAuthToken(data.access_token)
  return data
}

async function getRoles(eventEmitter) {
  try {
    const response = await userApi.getRolesAPI()
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_ROLES_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_ROLES_SUCCESS, err.message)
  }
}

async function editUserButtonClicked(values, editPayload, eventEmitter) {
  try {
    await userApi.editUser(values, editPayload)
    eventEmitter.emit(ADD_USER_EVENTS.EDIT_SUCCESS)
  } catch (err) {
    eventEmitter.emit(ADD_USER_EVENTS.EDIT_FAILURE, err.message)
  }
}

async function getUserDetails(eventEmitter, pk) {
  try {
    const response = await userApi.getUserDetails(pk)
    eventEmitter.emit(ADD_USER_EVENTS.EDIT_CLICK_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(ADD_USER_EVENTS.EDIT_CLICK_FAILURE, err.message)
  }
}

async function saveAddUserButtonClicked(values, pk, eventEmitter) {
  try {
    await userApi.saveAddUser(values, pk)
    eventEmitter.emit(ADD_USER_EVENTS.ADD_SUCCESS)
  } catch (err) {
    eventEmitter.emit(ADD_USER_EVENTS.ADD_FAILURE, err.message)
  }
}
async function fetchUsers(eventEmitter, params) {
  if (params) {
    var data = params.params
  }
  try {
    const response = await userApi.getUsers(data)
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_FAILURE, err.message)
  }
}

async function changePasswordClicked({
  old_password,
  new_password,
  re_password,
}) {
  console.log('.....controller.......')
  const {changePassword} = userApi
  if (_.isNil(old_password) || _.isNil(new_password) || _.isNil(re_password)) {
    throw new GraniteError('Password and New Password are required')
  }
  return await changePassword(old_password, new_password, re_password)
}

async function resetPasswordClickedFP({jwt_token, password, re_password}) {
  console.log('Middle ware ', jwt_token, password, re_password)
  const {resetPassword} = userApi
  // if (_.isNil(jwt_token) || _.isNil(password) || _.isNil(re_password)) {
  //   throw new GraniteError('Password and re_password is required')
  // }
  await resetPassword(jwt_token, password, re_password)
}

async function requestTokenClickedForgotPassword({email}, eventEmitter) {
  const {requestTokenFP} = userApi
  if (_.isNil(email)) {
    throw new GraniteError('Email is required')
  }
  return await requestTokenFP(email)
}

async function submitTokenClicked({token}) {
  const {submitToken} = userApi
  if (_.isNil(token)) {
    throw new GraniteError('Token is required')
  }

  return await submitToken(token)
}

// TO RESET PASSWORD IF USER FORFOTS IT
async function resetPasswordClicked(tempPassword) {
  try {
    if (
      _.isNil(tempPassword.jwt_token) ||
      _.isNil(tempPassword.password) ||
      _.isNil(tempPassword.re_password)
    ) {
      throw new GraniteError('')
    }
    const {resetPassword} = userApi
    await resetPassword(
      tempPassword.jwt_token,
      tempPassword.password,
      tempPassword.re_password,
    )
    tempPassword.eventEmitter.emit(RESET_PASSWORD_EVENTS.RESET_SUCCESS)
  } catch {
    tempPassword.eventEmitter.emit(RESET_PASSWORD_EVENTS.RESET_FAILURE)
  }

  // const {resetPassword} = userApi
  // if (_.isNil(tempPassword.jwt_token) || _.isNil(tempPassword.password) || _.isNil(tempPassword.re_password)) {
  //   //throw new GraniteError('')
  // }
  // await resetPassword(tempPassword.jwt_token, tempPassword.password, tempPassword.re_password)
}

// TO VERIFY TOKEN AND EMAIL IF USER WANTS TO RESET PASSWORD
async function resetPasswordCheck({token, eventEmitter}) {
  try {
    const {resetPasswordVerify} = userApi
    if (_.isNil(token)) {
      throw new GraniteError('Something went wrong')
    }
    const type = 'EMAIL'
    const response = await resetPasswordVerify(token, type)
    eventEmitter.emit(RESET_PASSWORD_EVENTS.RESET_TOKEN_SUCCESS, response)
  } catch {
    eventEmitter.emit(RESET_PASSWORD_EVENTS.RESET_TOKEN_FAILURE)
  }
}

// For LogOut

async function LogoutAPI() {
  console.log('Logout middleware')
  const {LogoutAPIGateway} = userApi
  const response = await LogoutAPIGateway()
  return response
}

async function registerClicked(values) {
  const {register} = userApi
  if (_.isNil(values.password)) {
    throw new GraniteError('Credentials are invalid')
  }
  const token = await register(values)
  setAuthToken(token)
}

async function updateProfileClicked({email, first_name, last_name, username}) {
  const {updateMyProfile} = userApi
  if (_.isNil(email) && _.isNil(first_name) && _.isNil(username)) {
    throw new GraniteError('Credentials are invalid')
  }
  const response = await updateMyProfile(email, first_name, last_name, username)
  return response
}

async function verifyAccountClicked(values) {}

async function updateUserProfileInfo(values, eventEmitter) {
  let payload = {}
  for (var property in values) {
    if (values[property] !== '') {
      payload[property] = values[property]
    } else {
    }
  }

  if (
    _.isNil(values.old_password) ||
    _.isNil(values.new_password) ||
    _.isNil(values.re_password)
  ) {
    throw new GraniteError('Password and New Password are required')
  }
  try {
    await userApi.updateUserProfileInfoAPI(payload)
    eventEmitter.emit(UPDATE_EVENTS.UPDATE_SUCCESS)
  } catch (e) {
    eventEmitter.emit(UPDATE_EVENTS.UPDATE_FAILURE)
  }
}

async function setNewPasswordClicked(
  tempPassword,
  {password, re_password},
  eventEmitter,
) {
  const {setNewPassword} = userApi
  if (_.isNil(password) || _.isNil(re_password)) {
    throw new GraniteError('Password and Confirm password is required')
  }
  try {
    await setNewPassword(tempPassword, password, re_password)
    resetAuthToken()
    eventEmitter.emit(RESET_EVENTS.RESET_PASSWORD_SUCCESS)
  } catch (e) {
    eventEmitter.emit(RESET_EVENTS.RESET_PASSWORD_FAILURE)
  }
}

async function requestTokenClicked({employee_name, email}, eventEmitter) {
  const {requestTokenOnSignup} = userApi
  if (_.isNil(employee_name)) {
    throw new GraniteError('Employee Name is required')
  }
  try {
    await requestTokenOnSignup(employee_name, email)
    eventEmitter.emit(RESET_EVENTS.SIGNUP_SUBMIT_SUCCESS)
  } catch (e) {
    eventEmitter.emit(RESET_EVENTS.SIGNUP_SUBMIT_FAILURE)
  }
}

async function signupLanding(token, eventEmitter) {
  try {
        const response = await userApi.signup(token)
        setAuthToken(response.data.access_token)
        eventEmitter.emit(RESET_EVENTS.SIGNUP_SUCCESS, response)
        // eventEmitter.emit(RESET_EVENTS.SIGNUP_SUCCESS, response.data.tmp_password)
      } catch (e) {
    eventEmitter.emit(RESET_EVENTS.SIGNUP_FAILURE)
  }
}

async function fetchUsers_Search(eventEmitter, val) {
  try {
    const response = await userApi.getUsers(val)
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_SEARCH_SUCCESS, response)
  } catch (e) {
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_SEARCH_FAILURE)
  }
}

async function deleteUser(eventEmitter, val) {
  try {
    const response = await userApi.deleteUser(val)
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_DELETE_SUCCESS, response)
  } catch (e) {
    eventEmitter.emit(ADD_USER_EVENTS.GET_USERS_DELETE_FAILURE)
  }
}

async function makeUserActiveInactive(props, event) {
  const payload = {
    is_active: !props.is_active,
    name: props.name,
    // pk: props.pk,
    // designation: props.designation,
    // user: props.user,
  }
  try {
    const response = await userApi.activeInactiveUser(payload, props.pk)
    event.emit(ADD_USER_EVENTS.ACTIVE_SUCCESS, response)
  } catch (err) {
    event.emit(ADD_USER_EVENTS.ACTIVE_FAILURE, err.message)
  }
}

export {
  changePasswordClicked,
  loginClicked,
  registerClicked,
  resetPasswordClicked,
  requestTokenClicked,
  submitTokenClicked,
  updateProfileClicked,
  verifyAccountClicked,
  signupLanding,
  getRoles,
  fetchUsers,
  saveAddUserButtonClicked,
  editUserButtonClicked,
  getUserDetails,
  resetPasswordCheck,
  LogoutAPI,
  requestTokenClickedForgotPassword,
  resetPasswordClickedFP,
  updateUserProfileInfo,
  setNewPasswordClicked,
  fetchUsers_Search,
  deleteUser,
  makeUserActiveInactive,
}
