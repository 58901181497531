// CORE Imports
import React from 'react'
import {Box, Button, Text, TextArea} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import styled from 'styled-components'

const DischargeNotes = ({onSubmit, handleAddNotes, permissions}) => {
  const AddNotes = styled(TextArea)`
    top: 163px;
    left: 428px;
    width: 507px;
    height: 241px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
  `

  const validationSchema = Yup.object().shape({
    notes: Yup.string()
      .min(0, 'Please enter reason why you want to discharge the loan.')
      .required('Please enter reason why you want to discharge the loan.'),
  })

  return (
    <>
      <Formik
        initialValues={{
          notes: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box direction="row">
              <Box width="100%" pad={{left: 'small', right: 'small'}}>
                <FormField name="dischargenotes" label="">
                  <Box>
                    <AddNotes
                      type="notes"
                      name="notes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Reason why you want to discharge the loan."
                      size="small"
                      value={values.notes}
                      disabled={false}
                    />
                  </Box>
                </FormField>
              </Box>
            </Box>
            <Text as="div" color="status-critical" margin={{left: 'small'}}>
              {touched.notes && errors.notes}
            </Text>

            <Box
              pad={{right: 'small'}}
              gap="medium"
              fill="horizontal"
              direction="row"
              elevation="none"
              justify="end"
            >
              <Button
                type="submit"
                alignSelf="end"
                label={isSubmitting ? 'Discharge...' : 'Discharge'}
                disabled={isSubmitting}
                primary
              />
              <Button
                onClick={() => handleAddNotes()}
                label={isSubmitting ? 'Cancel...' : 'Cancel'}
                disabled={isSubmitting}
                primary
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
export default DischargeNotes
