class SecurityAddress {
  constructor(item) {
    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }
    this.pk = item.pk
    this.address_line_1 = item.address_line_1
    this.address_line_2 = item.address_line_2
    this.city = item.city
    this.state = item.state
    this.postcode = item.postcode
    this.country = item.country
    this.complete_addr =
      (item.address_line_1 === null ? '' : item.address_line_1) +
      ' ' +
      (item.address_line_2 === null ? '' : item.address_line_2) +
      ' ' +
      (item.city === null ? '' : item.city) +
      ' ' +
      (item.state === null || item.state === '' ? '' : statesAbbr[item.state]) +
      ' ' +
      (item.postcode === null ? '' : item.postcode)
  }
}

export default SecurityAddress
