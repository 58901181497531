class LAONAPPLICANT {
  constructor(item) {
    const Type = {
      B: 'BORROWER',
      G: 'GUARANTOR',
      TT: 'TRUSTEE',
      SG: 'SIGNATORY',
      SF: 'SUPERFUND',
    }
    const Client_Type_Mapping = {
      Individual: 'IN',
      Company: 'CO',
      INDIVIDUAL: 'IN',
      COMPANY: 'CO',
      IN: 'Individual',
      CO: 'Company',
    }
    this.pk = item.pk
    this.applicant_mail_pk = item.applicant.pk
    this.name = item.applicant.name
    this.email = item.applicant.email
    this.phone = item.applicant.phone
    this.applicant_type = Type[item.applicant_type]
    this.first_name = item.applicant.first_name
    this.last_name = item.applicant.last_name
    this.primary = item.is_primary_applicant
    this.client_type = Client_Type_Mapping[item.applicant.client_type]
    this.contact_person = item.contact_person
    this.designation = item.designation
    this.company_name  = item.applicant.company_name
  }
}
export default LAONAPPLICANT
