// Lib Imports
import React from 'react'
import {Box, Heading} from 'grommet'
// import EventEmitter from 'utils/event-emitter'
import {changePasswordClicked} from 'accounts/controllers/user'
import ChangePasswordForm from './components/ChangePasswordForm'

const Profile = ({user}) => {
  // let eventEmitter = new EventEmitter()
  // const defaultToastData = {open: false, message: ''}
  // const [toastData, setToastData] = useState(defaultToastData)

  // useEffect(function init() {
  //   const observable = eventEmitter.getObservable()
  //   listenObservable()
  //   let subscription

  //   function listenObservable() {
  //     subscription = observable.subscribe(event => {
  //       console.log('EVENT FIRED---', event)
  //       switch (event.type) {
  //         case CHANGE_PASSWORD_EVENTS.CHANGE_PASSWORD_SUCCESS:
  //           setToastData({
  //             open: true,
  //             message: CHANGE_PASSWORD_MESSAGES.CHANGE_PASSWORD_SUCCESS,
  //           })
  //           setTimeout(() => {
  //             setToastData({
  //               open: false,
  //               message: '',
  //             })
  //           }, 1000)
  //           break
  //         case CHANGE_PASSWORD_EVENTS.CHANGE_PASSWORD_FAILURE:
  //           setToastData({
  //             open: true,
  //             message: CHANGE_PASSWORD_MESSAGES.CHANGE_PASSWORD_FAILURE,
  //           })
  //           setTimeout(() => {
  //             setToastData({
  //               open: false,
  //               message: '',
  //             })
  //           }, 2000)
  //           break
  //         default:
  //       }
  //     })
  //   }

  //   return function cleanUp() {
  //     subscription.unsubscribe()
  //   }
  // }, [])

  return (
    <Box pad="large" animation="fadeIn" flex={{grow: 1}}>
      <Heading level={1}>Change Password</Heading>
      <ChangePasswordForm
        changePasswordClicked={changePasswordClicked}
        // eventEmitter={eventEmitter}
      />
      {/* {toastData.open ? <Toast text={toastData.message} /> : null} */}
    </Box>
  )
}

export default Profile
