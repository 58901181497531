const ADD_USER_MESSAGES = {
  GET_USERS_SUCCESS: 'Successfully fetched user!',

  GET_USERS_FAILURE: 'Please retry,something went wrong!',

  GET_USERS_ROLES_SUCCESS: 'Successfully fetched user roles!',

  GET_USERS_ROLES_FAILURE: 'Please retry,something went wrong!',

  ADD_CLICK_SUCCESS: 'User created successfully! ',

  ADD_CLICK_FAILURE: "We're sorry, something went wrong.Please refresh the page and try again.",

  EDIT_CLICK_SUCCESS: 'Changes saved successfully! ',

  EDIT_CLICK_FAILURE: 'Failed to save your changes, please try again!',

  LOGGEINUSER_ACTIVE_FAILURE: "The user is currently logged in.",

  ACTIVE_SUCCESS: 'The user is active now! ',

  ACTIVE_FAILURE: 'Failed to save your changes, please try again!',
}
export default ADD_USER_MESSAGES

