// Lib Imports
import React from 'react'
import {Box, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'

// CORE Imports
import PasswordInput from 'granite/components/PasswordInput'
import FormField from 'granite/components/FormField'

// Application Imports
import CHANGE_PASSWORD_EVENTS from '../constants'

const ChangePasswordForm = ({eventEmitter, changePasswordClicked}) => {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .max(50, 'Too Long!')
      .required('Password is required'),
    newPassword: Yup.string()
      .min(6, 'Too short, should be atleast 6 character!')
      .max(50, 'Too Long!')
      .required('New Password is required'),
    re_password: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must be same.')
      .required('Confirm Password is required'),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    /**
     * NOTES:-
     *  - directly pass usecase method to be used for submit as Prop.
     *  - Only click method as props, no State data as
     *    thats managed by Formik itself.
     *  - loginClicked can only raise non-field errors
     *    as part of its business logic.
     */
    setErrors({})
    setSubmitting(true)
    try {
      await changePasswordClicked(values)
      eventEmitter.emit(CHANGE_PASSWORD_EVENTS.CHANGE_PASSWORD_SUCCESS)
    } catch (e) {

      eventEmitter.emit(CHANGE_PASSWORD_EVENTS.CHANGE_PASSWORD_FAILURE)
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  /**
   * NOTES:-
   *  - forms should be wrapped as a Component and they don't need
   *    state for fields as that's managed by Formik
   */
  return (
    <Formik
      initialValues={{
        password: '',
        newPassword: '',
        re_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <FormField
            name="password"
            label="Current password"
            required
            error={touched.password && errors.password}
          >
            <PasswordInput
              placeholder="Enter password"
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
          </FormField>
          <FormField
            name="newPassword"
            label="New password"
            required
            error={touched.newPassword && errors.newPassword}
          >
            <PasswordInput
              placeholder="Enter password"
              name="newPassword"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.newPassword}
            />
          </FormField>
          <FormField
            name="re_password"
            label="Confirm password"
            required
            error={touched.re_password && errors.re_password}
          >
            <PasswordInput
              placeholder="Enter password"
              name="re_password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.re_password}
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            justify="between"
            pad="none"
          >
            <Button
              type="submit"
              alignSelf="start"
              margin={{vertical: 'small'}}
              label={isSubmitting ? 'Changing password...' : 'Change password'}
              disabled={isSubmitting}
              primary
            />
          </Box>

          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default ChangePasswordForm
