import React from 'react'
import {Box, Image, Text} from 'grommet'

const Error = () => {
  const errorType = '404'
  return (
    <Box
      width="100%"
      flex={{grow: 1}}
      fill
    >
      <Box
        margin={{top: 'medium'}}
        align="center"
        direction="row"
        alignSelf="center"
        pad={{top: 'xxlarge'}}
      >
        <Image
          width="328px"
          src={process.env.PUBLIC_URL + 'img/error_piggy.png'}
        />
      </Box>
      {errorType === '500' ? (
        <Box>
          <Text
            size="30px"
            weight="400"
            textAlign="center"
            margin={{top: 'medium'}}
          >
            Something Went Wrong!!!
          </Text>

          <Box width="538px" margin={{top: 'small'}} alignSelf="center">
            <Text size="16px" weight="400" textAlign="center" color="dark-5">
              Error 500 : It is a long established fact that a reader will be
              distracted by the readable content of a page when looking at its
              layout.
            </Text>
          </Box>
        </Box>
      ) : (
        <Box>
          <Text
            size="30px"
            weight="400"
            textAlign="center"
            margin={{top: 'medium'}}
          >
            Page Not Found!!!
          </Text>

          <Box width="538px" margin={{top: 'small'}} alignSelf="center">
            <Text size="16px" weight="400" textAlign="center" color="dark-5">
              Error 404 : It is a long established fact that a reader will be
              distracted by the readable content of a page when looking at its
              layout.
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Error
