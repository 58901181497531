import CustomerAPI from 'clientDetails/gateways/client-api'
import CLIENT_DETAIL_EVENTS from 'pages/clientdetails/constants'
import {client_type_mapping} from 'granite/CurrencyConvertor'

async function getClientInfo(eventEmitter, clientId) {
  try {
    const response = await CustomerAPI.getclientInfo(clientId)
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_FAILURE, err.message)
  }
}

async function updateClientInfo(values, eventEmitter) {
  try {
    await CustomerAPI.updateclientInfo(values)
  } catch (err) {}
}

async function getLoanList(eventEmitter, values, loan_list_params) {
  const params = {
    applicant_id: values,
  }
  try {
    const response = await CustomerAPI.getLoanList(params, loan_list_params)
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_GET_LOAN_DETAIL_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_GET_LOAN_DETAIL_FAILURE,
      err.message,
    )
  }
}

async function getClientDetailsNotes(eventEmitter, values) {
  try {
    const response = await CustomerAPI.getNotes(values)
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_FAILURE,
      err.message,
    )
  }
}

async function addClientDetailsNotes(eventEmitter, values, id, route, screen) {
  const data = {
    // model_name: route.path ==="/clients/:clientid/:id" ?  'loan_info':  'customer',
    // app: route.path ==="/clients/:clientid/:id" ?  'loan':  'customer',
    model_name:
      route.path === '/clients/:clientid/:id' ? 'loaninfo' : 'applicant',
    app: route.path === '/clients/:clientid/:id' ? 'loan' : 'loan',
    entity_id: route.path === '/clients/:clientid/:id' ? route.params.id : id,
    note: values.notes,
  }
  try {
    const response = await CustomerAPI.addNotes(data)
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_SUCCESS, response)
    if (screen === 'clientLoanDetails') {
      await getClientDetailsNotes(eventEmitter, route.params.id)
    }  
    else if (screen === 'clientDetails') {
      await getClientDetailsNotes(eventEmitter, id)
    }
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_FAILURE,
      err.message,
    )
  }
}

async function deleteClientDetailsNotes(eventEmitter, id) {
  try {
    const response = await CustomerAPI.deleteNotes(id)
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_FAILURE,
      err.message,
    )
  }
}

async function getLoanListOnFilterparams(event, params) {
  try {
    const response = await CustomerAPI.getLoanList(params)
    event.emit(CLIENT_DETAIL_EVENTS.CLIENT_GET_LOAN_DETAIL_SUCCESS, response)
  } catch (err) {
    event.emit(CLIENT_DETAIL_EVENTS.CLIENT_GET_LOAN_DETAIL_FAILURE, err.message)
  }
}

async function getScreenActivityList(values, eventEmitter) {
  try {
    const response = await CustomerAPI.getScreenActivity(values)
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_GET_SCREEN_ACTIVITY_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_GET_SCREEN_ACTIVITY_FAILURE,
      err.message,
    )
  }
}

async function editClientInfo(eventEmitter, values, Id, clientId) {
  let payload = {
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email,
    phone: `${values.phone.includes('+') ? values.phone : '+' + values.phone}`,
    company_name: values.company_name,
    company_abn: values.company_ABN,
    client_type: await client_type_mapping(values.client_type),
  }

  try {
    const response = await CustomerAPI.editClientDetailsType(payload, Id)
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SAVE_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SAVE_FAILURE,
      err.message,
    )
  }
}

async function getClientSavings(eventEmitter, clientId) {
  let params = {
    applicant_id: clientId,
  }
  try {
    const response = await CustomerAPI.getSavings(params)
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_SAVINGS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_SAVINGS_FAILURE, err.message)
  }
}

async function mailClientInfo(eventEmitter, name, clientid) {
  try {
    const response = await CustomerAPI.mailClientInfo(name, clientid)
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_GET_MAIL_TEMPLATES_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_GET_MAIL_TEMPLATES_FAILURE,
      err.message,
    )
  }
}

async function mailClientInfoNew(eventEmitter, values, name, Id, content) {
  try {
    const response = await CustomerAPI.mailClientInfoNew(
      name,
      Id,
      values,
      content,
    )
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_MAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_MAIL_FAILURE,
      err.message,
    )
  }
}

async function addNewClient(eventEmitter, values) {
  try {
    const response = await CustomerAPI.addNewClientAPI(values)
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_ADD_NEW_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CLIENT_DETAIL_EVENTS.CLIENT_ADD_NEW_FAILURE, err.message)
  }
}

async function exportloanlist(eventEmitter, clientid) {
  try {
    const response = await CustomerAPI.exportloanlistAPI(clientid)
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_LOAN_LIST_EXPORT_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.CLIENT_LOAN_LIST_EXPORT_FAILURE,
      err.message,
    )
  }
}

async function showMoreDetails(eventEmitter, id) {
  try {
    const { moreData: response, savings } = await CustomerAPI.getMoreDetails(id)
    const moreDetails = [
      {
        sNo: 1,
        infoAbout: 'Security Property',
        info: response.security_name,
      },
      {
        sNo: 2,
        infoAbout: 'Applicants',
        info: response.applicant_name,
      },
      {
        sNo: 3,
        infoAbout: 'Lender',
        info: response.lender_name,
      },
      {
        sNo: 4,
        infoAbout: 'Account NO./BSB No.',
        info: response.account_bsb_no,
      },
      {
        sNo: 5,
        infoAbout: 'Settlement Date',
        info: response.settlement_date,
      },
      {
        sNo: 6,
        infoAbout: 'Loan Limit',
        info: response.loan_limit,
      },
      {
        sNo: 7,
        infoAbout: 'Loan Balance',
        info: response.balance,
      },
      {
        sNo: 8,
        infoAbout: 'Interest Rate',
        info: response.rate + '%',
      },
      {
        sNo: 9,
        infoAbout: 'Discount',
        info: response.discount + '%',
      },
      {
        sNo: 10,
        infoAbout: 'Repayment Type',
        info: response.repayment_type,
      },
      {
        sNo: 11,
        infoAbout: 'Rate Type',
        info: response.rate_type_choice,
      },
      {
        sNo: 12,
        infoAbout: 'Repayment Amount',
        info: response.repayment_amount,
      },
      {
        sNo: 13,
        infoAbout: 'Repayment Date',
        info: response.repayment_date,
      },
      {
        sNo: 14,
        infoAbout: 'Loan Product',
        info: response.product_name,
      },
      {
        sNo: 15,
        infoAbout: 'Product Expiry',
        info: response.product_expiry,
      },
      {
        sNo: 16,
        infoAbout: 'Package',
        info: response.loan_package,
      },
      {
        sNo: 17,
        infoAbout: 'Loan Term',
        info: response.term,
      },
      {
        sNo: 18,
        infoAbout: 'Offset Account',
        info: response.off_set_account,
      },
      {
        sNo: 19,
        infoAbout: 'Purpose',
        info: response.purpose,
      },
      {
        sNo: 20,
        infoAbout: 'Io Expiry',
        info: response.io_expiry,
      },
      {
        sNo: 21,
        infoAbout: 'Fixed Expiry',
        info: response.fixed_expiry,
      },
      // {
      //   sNo: 22,
      //   infoAbout: 'Old rate',
      //   info: response.old_rate,
      // },
      {
        sNo: 22,
        infoAbout: 'New rate',
        info: response.rate + '%',
      },
      {
        sNo: 23,
        infoAbout: 'Savings',
        info: savings.alrSavings,
      },
    ]
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.GET_MORE_DETAILS_SUCCESS,
      moreDetails,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_DETAIL_EVENTS.GET_MORE_DETAILS_FAILURE,
      err.message,
    )
  }
}

export {
  getClientInfo,
  updateClientInfo,
  getLoanList,
  getLoanListOnFilterparams,
  getScreenActivityList,
  editClientInfo,
  getClientDetailsNotes,
  addClientDetailsNotes,
  deleteClientDetailsNotes,
  getClientSavings,
  mailClientInfo,
  addNewClient,
  mailClientInfoNew,
  exportloanlist,
  showMoreDetails,
}
