import PropTypes from 'prop-types'
import React from 'react'
import {Box, Button, Text, Layer} from 'grommet'
import {FormClose} from 'grommet-icons'

const Toast = ({text, background, onClose,color}) => {
  return (
    <Layer
      position="top-right"
      modal={false}
      margin={{vertical: 'medium', horizontal: 'small'}}
      responsive={false}
      plain
    >
      <Box
        // align="center"
        direction="row"
        gap="medium"
        // justify="end"
        // round="medium"
        elevation="medium"
        pad={{vertical: 'none', horizontal: 'medium'}}
        background={background ? background : 'status-ok'}
      >
        <Box  direction="row" pad = "medium"  gap="medium" justify="end">
          <Text color={color ? color:"white"} >{text}</Text>
        </Box>
        {onClose ? (
          <Button icon={<FormClose />} onClick={onClose} plain />
        ) : null}
      </Box>
    </Layer>
  )
}

Toast.defaultProps = {
  text: '',
  background: null,
  onClose: () => console.log("Toast Closed"),
}

Toast.propTypes = {
  text: PropTypes.string,
  background: PropTypes.string,
  onClose: PropTypes.func,
}

export default Toast
