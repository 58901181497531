const CLIENT_LIST = {
  GET_CLIENTSLIST_SUCCESS: 'GET_CLIENTSLIST_SUCCESS',
  GET_CLIENTSLIST_FAILURE: 'GET_CLIENTSLIST_FAILURE',
  GET_CLIENTSLISTSORTED_SUCCESS: 'GET_CLIENTSLIST_SORTED_SUCCESS',
  GET_CLIENTSLISTSORTED_FAILURE: 'GET_CLIENTSLIST_SORTED_FAILURE',
  GET_CLIENTSLIST_PAGINATION_SUCCESS: 'GET_CLIENTSLIST_PAGINATION_SUCCESS',
  GET_CLIENTSLIST_PAGINATION_FAILURE: 'GET_CLIENTSLIST_PAGINATION_FAILURE',
  GET_CLIENTSLIST_SEARCH_SUCCESS: 'GET_CLIENTSLIST_SEARCH_SUCCESS',
  GET_CLIENTSLIST_SEARCH_FAILURE: 'GET_CLIENTSLIST_SEARCH_FAILURE',
  GET_CLIENTSLIST_EXPORT_SUCCESS: 'GET_CLIENTSLIST_EXPORT_SUCCESS',
  GET_CLIENTSLIST_EXPORT_FAILURE: 'GET_CLIENTSLIST_EXPORT_FAILURE',
}
export default CLIENT_LIST
