class MailList {
  constructor(item) {
    this.pk = item.pk
    this.sms_template = item.sms_template
    this.push_template = item.push_template
    this.is_audit = item.is_audit
    this.email_template = item.email_template
    this.content = item.email_template.content
    this.list = [item.email_template.name]
    this.notification_type = item.email_template.notification_type
    this.subject = item.email_template.subject
    this.email_template_pk = item.email_template.pk
    this.activity_name = item.activity_name

  }
}

export default MailList
