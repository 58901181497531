import React from 'react'
import styled, { withTheme } from 'styled-components'
// import spin from 'granite/components/Animations/spin'
import './style.css'

const OuterDiv = styled.div`
  width: 130px;
  height: 170px;
  position: relative;
`
const NumLoader = ({ ...rest }) => {
  return (
    <OuterDiv className="loader JS_on">
      <span className="binary"></span>
      <span className="binary"></span>
      <span className="getting-there">SAVING MONEY...</span>
    </OuterDiv>
  )
}

export default withTheme(NumLoader)