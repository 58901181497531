class RiskReportTable {
  constructor(item) {
    this.pk = item.pk
    this.applicant_pk = item.applicant ? item.applicant.pk : ''
    this.name = item.applicant ? item.applicant.name : ''
    this.first_name = item.applicant ? item.applicant.first_name : ''
    this.last_name = item.applicant ? item.applicant.last_name : ''
    this.email = item.applicant ? item.applicant.email : ''
    this.phone = item.applicant ? item.applicant.phone : ''
    this.lender_pk = item.lender.pk
    this.lender = item.lender.name
    this.lender_bsb_code = item.lender.bsb_code
    this.loan_limit = item.loan_limit
    this.balance = item.balance
    this.new_rate = item.new_rate
    this.package = item.loan_package
    this.product_name = item.product.name
    this.product_pk = item.product.pk
    this.product_expiry = item.product.expiry
    this.settlement_date = item.settlement_date
    this.status = item.status
    this.rate = item.rate
    this.io_expiry = item.io_expiry
    this.loan_pk = item.pk
    this.fixed_term_expiry = item.fixed_expiry
    this.account = `${item.account_number}`
    this.email = item.applicant ? item.applicant.email : ''
    this.purpose = item.purpose
    this.repayment_type = item.repayment_type
    this.rate_type_choice = item.rate_type_choice
    this.is_active = item.is_active
    this.is_current_campaign = item.is_current_campaign ?  true:false
  }
}

export default RiskReportTable
