import React from 'react'
import {Box, Image, Text, Button, Layer} from 'grommet'

const ConfirmationLayer = ({setOpen, layerHeader, layerBody, parameters, onConfirm}) => {
    
    return (
            <Box>
                <Layer
                    onEsc={() => setOpen(false)}
                    onClickOutside={() => setOpen(false)}
                >
                    <Box width="100%" height="100%">
                        <Box direction="row" justify="between" style={{borderBottom:'2px solid #37236a'}}>
                            <Text
                                color="dark-6"
                                size="16px"
                                margin={{left: '20px', top: '15px', bottom: '15px'}}
                                style={{fontWeight:'700'}}
                            >
                                {layerHeader}
                            </Text>
                            <Button
                                margin={{right: 'small', bottom: 'small'}}
                                icon={
                                    <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                                }
                                onClick={() => setOpen(false)}
                            />
                        </Box>
                        <Box
                            margin='20px'
                        >
                            <Text>{layerBody}</Text>
                        </Box>
                    </Box>
                    <Box margin='15px' gap='small' direction='row' alignSelf='end'>
                        <Button 
                            label='Confirm'
                            primary 
                            onClick={() => {
                                onConfirm(parameters)
                                setOpen(false)
                            }}
                        />
                        <Button 
                            label='Cancel'
                            primary 
                            onClick={() => setOpen(false)} 
                        />
                    </Box>
                </Layer>
            </Box>
    )
}

export default ConfirmationLayer