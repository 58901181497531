//Library imports
import React from 'react'
import {Box, Image, Text, Button, Layer} from 'grommet'
import { Share } from 'grommet-icons';
import styled from 'styled-components'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
const LayerStyle = styled(Layer)`
  height: 100%;
`

const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const COLUMNS = [
  {
    accessor: 'sNo',
    Header: 'SNo.',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="dark-6" />
    },
    disableSortBy: true,
  },
  {
    accessor: 'infoAbout',
    Header: 'Information About',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          weight="bold"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'info',
    Header: 'Information',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="dark-6" />
    },
    disableSortBy: true,
  },
]

const MoreDetails = ({
  setMoreDetailLayer,
  moreData,
  moreLoader,
  moreDataEdit,
  editVisibility,
}) => {
  const handleGoTOLoan = () => {
    window.open(
      '/clients/' + moreDataEdit.applicant_pk + '/' + moreDataEdit.loan_pk,
    )
  }
  return (
    <Box>
      <LayerStyle
        position="right"
        onEsc={() => setMoreDetailLayer(false)}
        onClickOutside={() => setMoreDetailLayer(false)}
      >
        <Box>
          <MoreDetailsWrapper direction="row" justify="between" height="50px">
            <MoreDetailsTextWrapper
              color="dark-6"
              size="16px"
              margin={{left: '20px', top: '15px', bottom: '15px'}}
            >
              {moreData.length > 0
                ? `More Details > ${moreData[1].info}`
                : 'More Details'}
            </MoreDetailsTextWrapper>
            <Box direction="row">
              {editVisibility && (
                <Box>
                  <ButtonStyle
                    alignSelf="end"
                    margin={{top: 'xsmall'}}
                    icon={<Share size='15px' />}
                    label={'Edit'}
                    primary
                    reverse
                    onClick={handleGoTOLoan}
                  />
                </Box>
              )}

              <Box
                margin={{
                  right: 'small',
                  top: 'small',
                  bottom: 'small',
                  left: 'small',
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/Cross.png'}
                  onClick={() => setMoreDetailLayer(false)}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                  height="14px"
                />
              </Box>
            </Box>
          </MoreDetailsWrapper>
          <Box
            margin={{
              top: 'small',
              left: '20px',
              right: '20px',
              bottom: 'small',
            }}
            overflow="auto"
          >
            <BoxWrapper>
              {moreLoader ? (
                <Box justify="center" align="center" height="100%">
                  <LoaderUI />
                </Box>
              ) : (
                <ReactTable
                  data={moreData.length > 0 ? moreData : moreData.moreDetails}
                  columns={COLUMNS} // pass in our table columns
                  setColumns={() => {}} // sets columns returned by react-table instance
                  rowClickHandler={() => {}}
                  columnClickHandler={() => {}}
                />
              )}
            </BoxWrapper>
          </Box>
        </Box>
      </LayerStyle>
    </Box>
  )
}

export default MoreDetails
