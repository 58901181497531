import React from 'react'
import {
  Box,
  Select,
  Button,
  Text,
  Image,
  TextInput,
  RadioButtonGroup,
} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import SECURITY_MESSAGES from 'messages/Security_form'
import styled from 'styled-components'
import Slider from 'granite/components/Slider'
import Datepicker from 'granite/components/DatePicker'
import InputCurrency from 'granite/components/CurrencyField'
import {addLoan} from 'clientloandetail/controllers/loan'
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const BoxTextField = styled(Box)`
  border-top: 1px solid #b1b8c0;
  border-bottom: 1px solid #b1b8c0;
`

const TextInput1 = styled(TextInput)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  opacity: 1;
  top: 115px;
  left: 237px;
  width: 100%;
  height: 30.8px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`

const StyledSelect = styled(Select)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  opacity: 1;
  top: 115px;
  left: 237px;
  width: 100%;
  height: 30.8px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`
const TextStyle = styled(Text)`
  font-weight: 700;
`

const addLoanSummaryForm = ({
  isLoanActive,
  eventEmitter,
  edit,
  setEdit,
  setToastWarning,
  setToastData,
  route,
  clientData,
  refreshState,
  Lenders,
}) => {
  const heading = 'Loan Summary'
  var lendersArray = []
  if (Lenders && Lenders.data && Lenders.data.length > 0) {
    Lenders.data.forEach(element => {
      lendersArray.push(element.lender_name)
    })
  }

  console.log('Final lender array us ', Lenders)

  const validationSchema = Yup.object().shape({
    // Must be in range [1-31]
    re_payment_date: Yup.string()
      .min(1, 'Invalid date')
      .max(2, 'Invalid date')
      .matches(/\b(0?[1-9]|[12][0-9]|3[01])\b/, 'Must be valid date'),

    // Must be of type number but not required
    balance: Yup.number('Must be valid number').typeError(
      'Balance must be a number',
    ),
    redraw_fund: Yup.number('Must be valid number').typeError(
      'Must be valid number',
    ),
    // repayment_account: Yup.number('Must be valid number').typeError(
    //   'Must be valid number',
    // ),
    re_payment_amount: Yup.number('Must be valid number').typeError(
      'Must be valid number',
    ),
    // offset_amount: Yup.number('Must be valid number').typeError(
    //   'Offset must be a number',
    // ),
    discount: Yup.number('Must be valid number').typeError(
      'Discount must ba a number',
    ),

    // New Fixes  Required Fields
    // name: Yup.string().required('Security name required'),
    lender_name: Yup.string().required('Lender name required'),
    settlementDate: Yup.string().required('Settlement Date required'),
    bsb_code: Yup.number('Must be valid number')
      .typeError('BSB No must be a number')
      .required('BSB No required'),
    account: Yup.string().required('Account no required'),
    package: Yup.string().required('Package required'),
    product: Yup.string().required('Product required'),
    purpose: Yup.string().required('Purpose required'),
    re_payment_type: Yup.string().required('Repayment Type required'),
    rate_type_choice: Yup.string().required('Rate Type required'),
    loanlimit: Yup.number('Must be valid number')
      .typeError('Loan Limit must ba a number')
      .required('Loan Limit required'),
    rate: Yup.number('Must be valid number')
      .typeError('Rate must be a number')
      .required('Interest Rate required'),

    // .required('Interest Rate required'),
    loan_expiry_date: Yup.string().required('Loan expiry date required'),
    review_month: Yup.string().required('Review cycle required'),
    postcode: Yup.number('Must be valid number')
      .typeError('Postal code must be valid number')
      .required('Postcode required'),
    loan_term: Yup.number('Must be valid number').typeError(
      'Loan Term (in months) eg 12',
    ),
    // .required('Loan Term required'),
    applicant_type: Yup.string().required('Please select applicant type.'),
    exp_date: Yup.string().when('rate_type_choice', {
      is: 'FIXED',
      then: Yup.string().required('Fixed expiry is required.'),
    }),
    io_expiry: Yup.string().when('re_payment_type', {
      is: 'IO',
      then: Yup.string().required('IO expiry is required.'),
    }),
  })

  const handleFixedExpDateEdit = (option, setFieldValue) => {}

  const handleIoExpiryEdit = (option, setFieldValue) => {}
  const forminitalValues = {
    applicant_type: '',
    company: '',
    account: '',
    status: '',
    bsb_code: '',
    settlementDate: '',
    loanlimit: '',
    balance: '',
    loanrate: '',
    purpose: '',
    rate_type_choice: '',
    exp_date: '',
    re_payment_amount: '',
    re_payment_type: '',
    re_payment_date: '',
    repayment_period: '',
    io_expiry: '',
    offset_amount: '',
    review_month: '',
    product: '',
    product_exp: '',
    loan_term: '',
    package: '',

    // New Security Address
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    postcode: '',
    country: '',

    rate: '',
    repayment_account: '',
    redraw_fund: '',
    loan_expiry_date: '',
    lender_name: '',
    discount: '',
    // loan_expiry_date: '',
    // redraw_fund: '',
    // company_name: '',
  }

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    await addLoan(eventEmitter, route.params.clientid, {...values})
    setSubmitting(false)
  }

  const handleMouseDown = () => {
    // if (edit) {
    //   setToastWarning(false)
    //   setToastData({
    //     open: true,
    //     message: CLIENT_DETAIL_MESSAGES.EDIT_BUTTON_NOT_CLICKED,
    //   })
    //   setTimeout(() => {
    //     setToastData({open: false, message: ''})
    //   }, 2000)
    // }
  }

  return (
    <Formik
      initialValues={forminitalValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
        <Box
          width="100%"
          background="light-1"
          elevation="medium"
          pad="medium"
          gap="small"
        >
          <form onSubmit={handleSubmit}>
            <Box
              pad={{bottom: 'small'}}
              justify="between"
              direction="row"
              background="brand-light"
            >
              <Box basis="75%" alignSelf="center">
                <Box>
                  <Box width="100%" direction="row" pad={{bottom: 'small'}}>
                    <Box align="start" width="50%">
                      <Text size="12px" color="dark-1">
                        {'Days remaining'}
                      </Text>
                    </Box>
                    <Box align="end" width="50%">
                      <Text size="12px" color="dark-1">
                        {'Review Month'}
                      </Text>
                    </Box>
                  </Box>
                  <Slider
                    value={0}
                    showValue={false}
                    background="comp-3"
                    background_back="#FFFFFF"
                    thumb="comp-3"
                    height="7px"
                    thumbWidth="0px"
                    thumbHeight="0px"
                  />
                </Box>
              </Box>
              <Box>
                <Box
                  direction="row"
                  justify="end"
                  gap="small"
                  pad={{left: 'medium'}}
                >
                  <ButtonStyle
                    margin={{vertical: 'small'}}
                    type="submit"
                    alignSelf="end"
                    label={isSubmitting ? 'Saving...' : 'Save'}
                    disabled={isSubmitting}
                    primary
                  />

                  <ButtonStyle
                    type="reset"
                    alignSelf="end"
                    margin={{vertical: 'small'}}
                    label={'Cancel'}
                    onClick={() => {
                      resetForm(forminitalValues)
                      setEdit(true)
                    }}
                    primary
                  />
                </Box>
              </Box>
            </Box>
            <Box
              pad={{bottom: 'small', top: 'small'}}
              direction="row"
              width="100%"
            >
              <TextStyle weight="bold" color="dark-1" size="16px">
                {heading}
              </TextStyle>
            </Box>
            <Box
              //fill="horizontal"
              direction="row-responsive"
              elevation="none"
              justify="between"
              pad={{top: 'small', bottom: 'small'}}
            ></Box>
            <Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="name"
                    label="Name"
                    error={touched.name && errors.name}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      // type="account"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Name"
                      value={clientData[0][1]}
                      disabled={true}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="applicant_type"
                    label="Applicant Type"
                    error={touched.applicant_type && errors.applicant_type}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <StyledSelect
                      options={[
                        'BORROWER',
                        'GUARANTOR',
                        'TRUSTEE',
                        'SIGNATORY',
                        'SUPERFUND',
                      ]}
                      value={values.applicant_type}
                      dropHeight="small"
                      size="small"
                      placeholder={'Applicant type {eg: Borrower}'}
                      onChange={({option}) =>
                        setFieldValue('applicant_type', option)
                      }
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="account"
                    label="Account No."
                    error={touched.account && errors.account}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="account"
                      name="account"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Account {eg: 123456789}"
                      value={values.account}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="review_month"
                    label="Review Cycle"
                    error={touched.review_month && errors.review_month}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <StyledSelect
                      options={[
                        'January',
                        'February',
                        'March',
                        'April',
                        'May',
                        'June',
                        'July',
                        'August',
                        'September',
                        'October',
                        'November',
                        'December',
                      ]}
                      value={values.review_month}
                      dropHeight="small"
                      size="small"
                      placeholder={'Review Cycle eg: January, February'}
                      onChange={({option}) =>
                        setFieldValue('review_month', option)
                      }
                    />
                  </BoxTextField>
                </Box>
              </Box>
              {false && (
                <Box direction="row">
                  <Box width="25%">
                    <FormField
                      name="company_name"
                      label="Company"
                      error={touched.company_name && errors.company_name}
                      backgroundLabel="dark-9"
                    />
                  </Box>
                  <Box width="75%">
                    <BoxTextField
                      margin={{bottom: 'small'}}
                      onClick={handleMouseDown}
                    >
                      <TextInput1
                        type="company_name"
                        name="company_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Company name"
                        value={values.company_name}
                        // disabled={edit}
                      />
                    </BoxTextField>
                  </Box>
                </Box>
              )}{' '}
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="bsb_code"
                    label="BSB No."
                    error={touched.bsb_code && errors.bsb_code}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="bsb_code"
                      name="bsb_code"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="BSB Code eg: 00000"
                      value={values.bsb_code}

                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="lender_name"
                    label="Lender Name"
                    error={touched.lender_name && errors.lender_name}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <StyledSelect
                      options={lendersArray}
                      value={values.lender_name}
                      dropHeight="small"
                      size="small"
                      placeholder={'Lender Name'}
                      disabled={edit}
                      // dropAlign={{right: 'left'}}
                      onChange={({option}) =>
                        setFieldValue('lender_name', option)
                      }
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="settlementDate"
                    label="Settlement Date"
                    error={touched.settlementDate && errors.settlementDate}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                    direction="row"
                  >
                    <Box width="98%" height="30.8px">
                      <Datepicker
                        initialDate={values.settlementDate}
                        imageHeight="20px"
                        textSize="12px"
                        onClose={val => {
                          if (val === null) {
                            setFieldValue('settlementDate', '')
                          } else {
                            setFieldValue(
                              'settlementDate',
                              new Date(val).toJSON().split('T')[0],
                            )
                          }
                        }}
                      />
                    </Box>
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="loanlimit"
                    label="Loan Limit"
                    error={touched.loanlimit && errors.loanlimit}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {false && (
                      <TextInput1
                        pad="loanlimit"
                        type="loanlimit"
                        name="loanlimit"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Loan Limit eg: $1,000"
                        value={values.loanlimit}
                        // disabled={edit}
                      />
                    )}
                    <InputCurrency
                      placeholder="Loan Limit eg: $15,000.00"
                      name="loanlimit"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.loanlimit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="balance"
                    label="Balance"
                    error={touched.balance && errors.balance}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {false && (
                      <TextInput1
                        type="balance"
                        name="balance"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Balance eg: $500.00"
                        value={values.balance}
                        // disabled={edit}
                      />
                    )}
                    <InputCurrency
                      placeholder="Balance eg: $500.00"
                      name="balance"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.balance}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="purpose"
                    label="Purpose"
                    error={touched.purpose && errors.purpose}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <RadioButtonGroup
                      name="purpose"
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      // disabled={edit}
                      options={['INV', 'OO']}
                      value={values.purpose}
                      onChange={event =>
                        setFieldValue('purpose', event.target.value)
                      }
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="rate_type_choice"
                    label="Rate Type"
                    error={touched.rate_type_choice && errors.rate_type_choice}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <RadioButtonGroup
                      name="rate_type_choice"
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      // disabled={edit}
                      options={['FIXED', 'VAR']}
                      value={values.rate_type_choice}
                      onChange={event => {
                        setFieldValue('rate_type_choice', event.target.value)
                        if (event.target.value === 'VAR') {
                          setFieldValue('exp_date', '')
                        }

                        handleFixedExpDateEdit(
                          event.target.value,
                          setFieldValue,
                        )
                      }}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              {values.rate_type_choice === 'FIXED' ? (
                <Box direction="row">
                  <Box width="25%">
                    <FormField
                      name="exp_date"
                      label="Fixed Expiry Date"
                      error={touched.exp_date && errors.exp_date}
                      backgroundLabel="dark-9"
                      required={
                        values.rate_type_choice === 'FIXED' ? 'yes' : ''
                      }
                    />
                  </Box>
                  <Box width="75%">
                    <BoxTextField
                      margin={{bottom: 'small'}}
                      onClick={handleMouseDown}
                    >
                      {' '}
                      {'fixedExpiryCalender' && true ? (
                        <Box height="30.8px">
                          <Datepicker
                            initialDate={values.exp_date}
                            textSize="12px"
                            imageHeight="20px"
                            onClose={val => {
                              if (val === null) {
                                setFieldValue('exp_date', '')
                              } else {
                                setFieldValue(
                                  'exp_date',
                                  new Date(val).toJSON().split('T')[0],
                                )
                              }
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          direction="row"
                          align="end"
                          pad={{
                            top: '6px',
                            bottom: '6px',
                            right: '8px',
                            left: '4px',
                          }}
                          justify="between"
                          height="30.8px"
                        >
                          <Text size="12px">{values.exp_date}</Text>
                          <Image
                            height="20px"
                            src={
                              process.env.PUBLIC_URL + '/Images/datesmall.png'
                            }
                          />
                        </Box>
                      )}
                    </BoxTextField>
                  </Box>
                </Box>
              ) : (
                <></>
              )}{' '}
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="product"
                    label="Product"
                    error={touched.product && errors.product}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="product"
                      name="product"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Product eg: Star, Gold"
                      value={values.product}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="product_exp"
                    label="Product Expiry"
                    error={touched.product_exp && errors.product_exp}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    {false ? (
                      <Box
                        direction="row"
                        align="end"
                        pad={{
                          top: '6px',
                          bottom: '6px',
                          right: '8px',
                          left: '4px',
                        }}
                        justify="between"
                        height="30.8px"
                      >
                        <Text size="12px">{values.product_exp}</Text>
                        <Image
                          height="20px"
                          src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
                        />
                      </Box>
                    ) : (
                      <Box height="30.8px">
                        <Datepicker
                          initialDate={values.product_exp}
                          textSize="12px"
                          imageHeight="20px"
                          onClose={val => {
                            if (val === null) {
                              setFieldValue('product_exp', '')
                            } else {
                              setFieldValue(
                                'product_exp',
                                new Date(val).toJSON().split('T')[0],
                              )
                            }
                          }}
                        />
                      </Box>
                    )}
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="package"
                    label="Package"
                    error={touched.package && errors.package}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <RadioButtonGroup
                      name={'package'}
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      // disabled={edit}
                      options={['YES', 'NO']}
                      value={values.package}
                      onChange={event => {
                        setFieldValue('package', event.target.value)
                      }}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="loan_term"
                    label="Loan Term"
                    error={touched.loan_term && errors.loan_term}
                    backgroundLabel="dark-9"
                    // required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="loan_term"
                      name="loan_term"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Loan Term (in months) eg 12"
                      value={values.loan_term}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="rate"
                    label="Interest Rate"
                    error={touched.rate && errors.rate}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="rate"
                      name="rate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Interest Rate eg: 4.0"
                      value={values.rate}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="discount"
                    label="Discount"
                    error={touched.discount && errors.discount}
                    backgroundLabel="dark-9"
                    // required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="discount"
                      name="discount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Discount eg: 4.0"
                      value={values.discount}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="loan_expiry_date"
                    label="Loan expiry date"
                    error={touched.loan_expiry_date && errors.loan_expiry_date}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <Box height="30.8px">
                      <Datepicker
                        initialDate={values.loan_expiry_date}
                        textSize="12px"
                        imageHeight="20px"
                        onClose={val => {
                          if (val === null) {
                            setFieldValue('loan_expiry_date', '')
                          } else {
                            setFieldValue(
                              'loan_expiry_date',
                              new Date(val).toJSON().split('T')[0],
                            )
                          }
                        }}
                      />
                    </Box>
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="re_payment_amount"
                    label="Repayment amount"
                    error={
                      touched.re_payment_amount && errors.re_payment_amount
                    }
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {false && (
                      <TextInput1
                        pad="re_payment_amount"
                        type="re_payment_amount"
                        name="re_payment_amount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Repayment Amount eg: 2,000"
                        value={values.re_payment_amount}
                        // disabled={edit}
                      />
                    )}

                    <InputCurrency
                      placeholder="Repayment Amount eg: $2,000.00"
                      name="re_payment_amount"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.re_payment_amount}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="redraw_fund"
                    label="Redraw Fund"
                    error={touched.redraw_fund && errors.redraw_fund}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <InputCurrency
                      placeholder="Redraw Fund eg: $500.00"
                      name="redraw_fund"
                      handleChange={handleChange}
                      onBlur={handleBlur}
                      value={values.redraw_fund}
                      disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="repayment_account"
                    label="Repayment Account"
                    error={
                      touched.repayment_account && errors.repayment_account
                    }
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      pad="repayment_account"
                      type="repayment_account"
                      name="repayment_account"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Repayment Account  eg: 1234567890"
                      value={values.repayment_account}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="re_payment_type"
                    label="Repayment Type"
                    error={touched.re_payment_type && errors.re_payment_type}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <RadioButtonGroup
                      name="re_payment_type"
                      pad={{top: '4px', bottom: '4px'}}
                      style={{fontSize: '12px'}}
                      gap="xxlarge"
                      direction="row"
                      // disabled={edit}
                      options={['IO', 'P&I']}
                      value={values.re_payment_type}
                      onChange={event => {
                        setFieldValue('re_payment_type', event.target.value)
                        if (event.target.value === 'P&I') {
                          setFieldValue('io_expiry', '')
                        }

                        handleIoExpiryEdit(event.target.value, setFieldValue)
                      }}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              {values.re_payment_type === 'IO' && (
                <Box direction="row">
                  <Box width="25%">
                    {' '}
                    <FormField
                      name="io_expiry"
                      label="IO Expiry"
                      error={touched.io_expiry && errors.io_expiry}
                      backgroundLabel="dark-9"
                      required={values.re_payment_type === 'IO' ? 'yes' : ''}
                    />
                  </Box>
                  <Box width="75%">
                    <BoxTextField
                      margin={{bottom: 'small'}}
                      onClick={handleMouseDown}
                    >
                      {'ioExpiryCalender' && true ? (
                        <Box height="30.8px">
                          <Datepicker
                            initialDate={values.io_expiry}
                            textSize="12px"
                            imageHeight="20px"
                            onClose={val => {
                              if (val === null) {
                                setFieldValue('io_expiry', '')
                              } else {
                                setFieldValue(
                                  'io_expiry',
                                  new Date(val).toJSON().split('T')[0],
                                )
                              }
                            }}
                          />
                        </Box>
                      ) : (
                        <Box
                          direction="row"
                          align="end"
                          pad={{
                            top: '6px',
                            bottom: '6px',
                            right: '8px',
                            left: '4px',
                          }}
                          justify="between"
                          height="30.8px"
                        >
                          <Text size="12px">{values.io_expiry}</Text>
                          <Image
                            height="20px"
                            src={
                              process.env.PUBLIC_URL + '/Images/datesmall.png'
                            }
                          />
                        </Box>
                      )}
                    </BoxTextField>
                  </Box>
                </Box>
              )}{' '}
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="re_payment_date"
                    label="Repayment Date"
                    error={touched.re_payment_date && errors.re_payment_date}
                    backgroundLabel="dark-9"
                    info={'Repayment date must be in range 1 to 31'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    {' '}
                    <TextInput1
                      type="re_payment_date"
                      name="re_payment_date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Repayment Date eg:{Must be in range from 1 to 31}"
                      value={values.re_payment_date}

                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="repayment_period"
                    label="Repayment Period"
                    error={touched.repayment_period && errors.repayment_period}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <StyledSelect
                      options={['Yearly', 'Monthly', 'Fortnightly', 'Weekly']}
                      value={values.repayment_period}
                      dropHeight="small"
                      size="small"
                      placeholder={'Repayment Period'}
                      // dropAlign={{right: 'left'}}
                      onChange={({option}) =>
                        setFieldValue('repayment_period', option)
                      }
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  {' '}
                  <FormField
                    name="offset_amount"
                    label="Offset Account"
                    error={touched.offset_amount && errors.offset_amount}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField
                    margin={{bottom: 'small'}}
                    onClick={handleMouseDown}
                  >
                    <TextInput1
                      type="offset_amount"
                      name="offset_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Offset Account eg: 50,000"
                      value={values.offset_amount}
                      // disabled={edit}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="address_line_1"
                    label={SECURITY_MESSAGES.ADDRESS_LINE_1}
                    error={touched.address_line_1 && errors.address_line_1}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField margin={{bottom: 'small'}}>
                    {' '}
                    <TextInput1
                      type="address_line_1"
                      name="address_line_1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder={SECURITY_MESSAGES.ADDRESS_LINE_1}
                      value={values.address_line_1}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="address_line_2"
                    label={SECURITY_MESSAGES.ADDRESS_LINE_2}
                    error={touched.address_line_2 && errors.address_line_2}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField margin={{bottom: 'small'}}>
                    {' '}
                    <TextInput1
                      type="address_line_2"
                      name="address_line_2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder={SECURITY_MESSAGES.ADDRESS_LINE_2}
                      value={values.address_line_2}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="city"
                    label={SECURITY_MESSAGES.CITY}
                    error={touched.city && errors.city}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField margin={{bottom: 'small'}}>
                    {' '}
                    <TextInput1
                      type="city"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder={SECURITY_MESSAGES.CITY}
                      value={values.city}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="state"
                    label={SECURITY_MESSAGES.STATE}
                    error={touched.state && errors.state}
                    backgroundLabel="dark-9"
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField margin={{bottom: 'small'}}>
                    <StyledSelect
                      options={[
                        'New South Wales',
                        'Victoria',
                        'Queensland',
                        'Tasmania',
                        'South Australia',
                        'Western Australia',
                        'Northern Territory',
                        'Australian Capital Territory',
                      ]}
                      value={values.state}
                      dropHeight="small"
                      size="small"
                      placeholder={SECURITY_MESSAGES.STATE}
                      onChange={({option}) => setFieldValue('state', option)}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="postcode"
                    label={SECURITY_MESSAGES.POSTCODE}
                    error={touched.postcode && errors.postcode}
                    backgroundLabel="dark-9"
                    required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField margin={{bottom: 'small'}}>
                    {' '}
                    <TextInput1
                      type="postcode"
                      name="postcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder={SECURITY_MESSAGES.POSTCODE}
                      value={values.postcode}
                    />
                  </BoxTextField>
                </Box>
              </Box>
              <Box direction="row">
                <Box width="25%">
                  <FormField
                    name="country"
                    label={SECURITY_MESSAGES.COUNTRY}
                    error={touched.country && errors.country}
                    backgroundLabel="dark-9"
                    // required={'yes'}
                  />
                </Box>
                <Box width="75%">
                  <BoxTextField margin={{bottom: 'small'}}>
                    <StyledSelect
                      options={['Australia']}
                      value={values.country}
                      dropHeight="small"
                      size="small"
                      placeholder={SECURITY_MESSAGES.COUNTRY}
                      onChange={({option}) => setFieldValue('country', option)}
                    />
                  </BoxTextField>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      )}
    </Formik>
  )
}
export default addLoanSummaryForm
