import riskAPI from 'alr/gateways/risk-api'
import RISK_RATE_EVENTS from 'pages/risk/constants'

async function riskRateClicked(eventEmitter) {
  try {
    const response = await riskAPI.riskRate()
    eventEmitter.emit(RISK_RATE_EVENTS.GET_RISK_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(RISK_RATE_EVENTS.GET_RISK_FAILURE, err.message)
  }
}

async function campaignRiskRate(eventEmitter, id) {
  try {
    const response = await riskAPI.getCampaignRiskRate(id)
    eventEmitter.emit(RISK_RATE_EVENTS.GET_RISK_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(RISK_RATE_EVENTS.GET_RISK_FAILURE, err.message)
  }
}

async function saveRiskRateClicked(
  eventEmitter,
  risk,
  riskRate,
) {
  try {
    await riskAPI.saveRisk(
      risk,
      riskRate.riskRate1,
      riskRate.riskRate2,
      riskRate.riskRate3,
      riskRate.riskRate4,
    )
    eventEmitter.emit(RISK_RATE_EVENTS.SAVE_RISK_SUCCESS)
  } catch (err) {
    eventEmitter.emit(RISK_RATE_EVENTS.SAVE_RISK_FAILURE, err.message)
  }
}

export {riskRateClicked, saveRiskRateClicked, campaignRiskRate}
