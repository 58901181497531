import PropTypes from 'prop-types'
import React from 'react'
import {Box, Heading} from 'grommet'

import Divider from 'granite/components/Divider'

const PageHeading = ({mainHeading, subHeading}) => {
  return (
      <Box direction="column" gap="xxsmall">
        <Heading level={3} margin="none">{mainHeading}</Heading>
        {subHeading && (
          <Heading level={5} margin="none">{subHeading}</Heading>
        )}
        <Divider color="brand" margin={{bottom: 'medium', top: 'xsmall'}} />
      </Box>
  )
}

PageHeading.defaultProps = {
  mainHeading: '',
  subHeading: '',
}

PageHeading.propTypes = {
  mainHeading: PropTypes.string,
  subHeading: PropTypes.string,
}

export default PageHeading
