import React from 'react'
import {Box, Text} from 'grommet'
import styled from 'styled-components'
// import {getAuthToken} from 'utils/auth-singleton'

const PoweredByWrapper = styled(Text)`
  // top: 0px;
  // left: 0px;
  // height: 10px;
  text-align: left;
  font: Semibold 10px/14px Segoe UI;
  letter-spacing: 0px;
  color: #707070;
  text-transform: uppercase;
`

const T2BWrapper = styled(Text)`
  top: 756px;
  left: 1212px;
  height: 14px;
  text-align: left;
  // font: Semibold 10px/14px Segoe UI;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #1964ac;
  opacity: 1;
`
const Footer = () => {
  // const authToken = getAuthToken()
  const goToLink = props => {
    window.open('https://www.thoughts2binary.com/')
  }
  return (
    <Box
      as="footer"
      align="end"
      pad={{
        // vertical: 'small',
        // horizontal: 'medium',
        top: 'small',
        left: 'small',
        bottom: 'xsmall',
        right: 'medium',
      }}
      margin="none"
      // background={authToken ? 'brand-light' : 'brand-white'}
      flex={{grow: 0}}
    >
      <Box direction="row" gap="small">
        <PoweredByWrapper size="10px"> POWERED BY : </PoweredByWrapper>
        <T2BWrapper
          size="10px"
          style={{cursor: 'pointer'}}
          onClick={() => goToLink()}
        >
          T2B Granite
        </T2BWrapper>
      </Box>
    </Box>
  )
}

export default Footer
