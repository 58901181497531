import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Image, Button, Heading } from 'grommet'

const Logout = () => {

  const msg = "You have successfully logged out";
  // const handleLogout = () => {
  // }

  return (
    <Box direction="row" width="100%"
      background=""
      // border={{ color: 'red', size: 'small' }}
      flex="grow"
      justify="center"
      margin="none">
      <Box
        // border={{ color: 'green', size: 'small' }}
        align="center"
        background=""
        flex="grow"
        justify="center"
        margin="none"
        pad="medium"
      >
        <Image alt="logo" height="360px" src={process.env.PUBLIC_URL + '/Images/Piggy.png'} />
      </Box>
      <Box
        // border={{ color: 'red', size: 'small' }}
        align="center"
        background=""
        flex="grow"
        justify="center"
        margin="none"
        pad="medium"
      >
        <Box width="large" animation="fadeIn" pad="medium">
          <Box
            // border={{ color: 'red', size: 'small' }}
            fill="horizontal"
            direction="column"
            elevation="none"
            pad="small"
            align="center"
          >
            <Image alignSelf="center" alt="logo" height="50px" src={process.env.PUBLIC_URL + '/Images/success.svg'} />
          </Box>
          <Box justify="between" pad ={{left:'20px'}} >
          <Heading 
              border={{ color: 'brand', size: 'medium' }}
          textAlign="center" color="dark-1" level={4} margin={{ bottom: 'medium' }}>
            {msg}
          </Heading>
          <Link to="/login">
            <Box
              // border={{ color: 'red', size: 'small' }}
              fill="horizontal"
              direction="column"
              elevation="none"
              justify="between"
              // pad="small"
            >
              <Button
                // onClick={handleLogout}
                type="submit"
                alignSelf="center"
                margin={{ vertical: 'small' }}
                label={'Login'}
                primary
              />
              </Box>
          
          </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default Logout
