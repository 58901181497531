//library imports
import React, {useState} from 'react'
import {Box, Select, Image, Text, Button, Anchor, ThemeContext, CheckBox} from 'grommet'
import queryString from 'query-string'
import {Link, useLocation} from 'react-router-dom'
import styled from 'styled-components'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
import Pagination from 'granite/components/Pagination'
import ConfirmationLayer from 'granite/components/ConfirmationLayer'

//Application imports
import {
  handleNewBulkStatusChanged,
  handleNewBulkStatusChangedAcceptedRejected,
  showMoreDetails,
  updateNewStatus,
  getMailTemplateList,
} from 'alr/controllers/loanReview'
import {saveCampaignClicked} from 'alr/controllers/campaign'
// import CAMPAIGN_MESSAGES from 'messages/campaign'

const SelectWrapper = styled(Select)`
  border: 0.5px solid #b1b8c0;
  border-radius: 4px;
  padding-left: 14px !important;
  height: 36px !important;
  border: none !important;
  font-size: 14px;
  color: #000000;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`
const ButtonStyleNew = styled(Button)`
  height: 36px;
  width: auto;
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`
// const SelectWrapperTable = styled(Select)`
//   font-size: 14px;
// `
const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}
const COLUMNS = [
  {
    accessor: 'selection',
    width: "4%",
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'} style={{textAlign: '-webkit-center'}}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'status',
    Header: 'Action',
    width: "16%",
    Cell: ({
      cell: {value, row, column},
      setOpen,
      setLayer,
      setParameters,
      updateCellData,
      // bulkChanged,
      // handleNewStatusChanged,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            // <SelectWrapperTable
            //   options={['Accepted', 'Rejected']}
            //   placeholder="Status"
            //   value={value === 'InProcess' ? '' : value}
            //   onChange={({option}) => {
            //     bulkChanged(option)
            //     handleNewStatusChanged(option, rest.row.original.pk)
            //   }}
            // />
            <Box direction='row' justify='center'>
              <Anchor 
                onClick={()=>{
                  setOpen(true)
                  setParameters({
                    string: 'Contacting', 
                    pk: rest.row.original.pk
                  })
                }} 
                label='Loan Details Updated'
                margin={{right:'xsmall'}}
              />{' | '}
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters({
                    string: 'Rejected', 
                    pk: rest.row.original.pk
                  })
                }}
                label='Rejected'
                margin={{left:'xsmall'}}
              />
            </Box>  
          }
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'ation',
    Header: 'Edit',
    width: "5%",
    Cell: ({cell: {value, row}, campaignId, handleLoan, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'small'}} align='center'>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  window.open(
                    '/clients/' + row.original.applicant_pk + '/' + row.original.loan_pk 
                      + '?edit=true&campaign_id=' + campaignId,
                  )
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'lender',
    Header: 'Lender',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'name',
    Header: 'Clients',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" gap="small">
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  handleApplicant(row.original.loan_pk)
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/more_info.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
              <Box>
                <Text size="14px">{value}</Text>
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'account_number',
    Header: 'Account No.',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'loan_limit',
    Header: 'Loan Limit',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          textAlign="center"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'settlement_date',
    Header: 'Settlement Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'security',
    Header: 'Security',
    width: "25%",
    Cell: ({cell: {value}, ...rest}) => {
      return (
       <Box style={{whiteSpace: 'pre-line'}}>
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
       </Box>
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'balance',
    Header: 'Loan Balance',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    // disableSortBy: true,
  },
]

const NewLoanTable = ({
  setData,
  eventEmitter,
  loader,
  setMoreDetailLayer,
  setMoreLoader,
  navigateToParams,
  campaignDetails,
  searchValue,
  setSearchValue,
  statusValue,
  setStatusValue,
  data: {data, total},
  filterData: {page, page_size: size, campaign_id},
  noLoan,
  setshowmailmodal,
  showmailmodal,
  setapplicantids,
  setapplicantsemail,
  handleCampaignForm,
  syncvisible,
  campaignId,
  setclientfirdtname,
  permissions,
  setToastData,
  setselectedapplicants,
}) => {
  const [open, setOpen] = useState(false)
  const [layer, setLayer] = useState(false)
  const [parameters, setParameters] = useState({})
  const [acceptedLayer, setAcceptedLayer] = useState(false)
  const [rejectedLayer, setRejectedLayer] = useState(false)
  const [selected, setSelected] = useState([])
  const [filterImageUrl, setFilterImageUrl] = useState(
    process.env.PUBLIC_URL + '/img/down_arrow.png',
  )
  const onConfirm = parameters => {
    bulkChanged(parameters.string)
    handleNewStatusChanged(parameters.string, parameters.pk)
    setParameters({})
  }
  const handleMouseEnter = () => {
    if (statusValue !== '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/search_cancel.png')
    }
  }
  const handleMouseLeave = () => {
    if (statusValue === '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/down_arrow.png')
    }
  }
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }

  let location = useLocation()
  const {search} = location

  const handleApplicant = id => {
    showMoreDetails(eventEmitter, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }
  const handleNewStatusChanged = (value, id) => {
    if (selected.length === 0) {
      updateNewStatus(eventEmitter, id, value, data, setData, total)
    }
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(search)
    if (queryParams['sorting']) {
      let colName
      let desc
      if (queryParams['sorting'].indexOf('-') === 0) {
        colName = queryParams['sorting'].substring(1)
        desc = true
      } else {
        colName = queryParams['sorting'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSortChange = sortOrder => {
    if (!sortOrder.length) navigateToParams({sorting: null})
    else {
      const [{id, desc}] = sortOrder
      // if (id === 'applicant__name') {
      //   navigateToParams({sorting: desc ? '-name' : 'name'})
      // } else if (id === 'applicant__email') {
      //   navigateToParams({sorting: desc ? '-email' : 'email'})
      // } else {
      navigateToParams({sorting: desc ? '-' + id : id})
      // }
    }
  }

  const date = new Date()
  const monthFull = date.toLocaleString('default', {month: 'long'})
  const monthNumber = date.getMonth()
  const year = date.getFullYear()
  const formattedDate = new Date().toJSON().split('T')[0]

  const handleSaveCampaign = (monthFull, formattedDate, monthNumber, year) => {
    saveCampaignClicked(
      monthFull,
      formattedDate,
      monthNumber,
      year,
      eventEmitter,
    )
  }

  const handleFilter = value => {
    navigateToParams({status: value, search: null})
  }

  const bulkChanged = value => {
    if (selected.length > 0) {
      handleNewBulkStatusChanged(
        eventEmitter,
        selected,
        value,
        data,
        setData,
        total,
      )
    }
  }

  const handleAccepted = () => {
    if (selected.length > 0) {
      const newData = selected.map(item => {
        const newItem = {
          id: item.pk,
          keys: {status: 'Contacting'},
        }
        return newItem
      })
      handleNewBulkStatusChangedAcceptedRejected(eventEmitter, newData)
    } else if (selected.length === 0) {
      setToastData({
        open: true,
        message: 'Please select loans to be Accepted',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }

  const handleRejected = () => {
    if (selected.length > 0) {
      const newData = selected.map(item => {
        const newItem = {
          id: item.pk,
          keys: {status: 'Rejected'},
        }
        return newItem
      })
      handleNewBulkStatusChangedAcceptedRejected(eventEmitter, newData)
    } else if (selected.length === 0) {
      setToastData({
        open: true,
        message: 'Please select loans to be Rejected',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }

  const handlemailclicked = () => {
    // console.log('setselectedapplicants', setselectedapplicants)
    // console.log('Selected is ', selected)
    setselectedapplicants(selected)
    if (selected.length) {
      const applicantsid = []
      const applicantsemailid = []
      selected.forEach(item => {
        applicantsid.push(item.applicant_pk)
        applicantsemailid.push(item.applicant_email)
      })
      setclientfirdtname(selected[0].first_name)
      setapplicantids(applicantsid)
      setapplicantsemail(applicantsemailid.join(','))
      getMailTemplateList(
        eventEmitter,
        'LOAN_REVIEW_NEW',
        selected[0].applicant_pk,
      )
      setshowmailmodal(true)
    } else if (selected.length === 0) {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }

  return (
    <Box>
      <Box
        gap="large"
        direction="row"
        justify="between"
        width="100%"
        margin={{top: 'medium', bottom: 'medium'}}
      >
        <Box gap="medium" direction="row" width="30%">
          {false && (<Box
            height="36px"
            background={statusValue ? 'light-1' : 'brand-light1'}
            direction="row"
            border={{color: 'round-border'}}
            round="4px"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <SelectWrapper
              options={['All', 'Rejected']}
              icon={
                <Image
                  src={filterImageUrl}
                  fit="contain"
                  onClick={event => {
                    if (statusValue !== '') {
                      navigateToParams({status: null})
                      setStatusValue('')
                      event.stopPropagation()
                    }
                  }}
                />
              }
              placeholder="Status"
              value={statusValue}
              onChange={({option}) => {
                setStatusValue(option)
                handleFilter(option)
              }}
            />
          </Box>
          )}
        </Box>
        <Box align="end" gap="medium" height="36px" direction="row">
          <ButtonStyleNew
            primary
            disabled={selected.length > 0 && data.length > 0 ? false : true}
            label={
              <Text weight="bold" color=" #339933">
                Loan Details Updated{' '}
              </Text>
            }
            onClick={()=> setAcceptedLayer(true)}
          />

          <ButtonStyleNew
            primary
            disabled={selected.length > 0 && data.length > 0 ? false : true}
            label={
              <Text weight="bold" color="red">
                Rejected
              </Text>
            }
            onClick={()=> setRejectedLayer(true)}
          />

          {permissions !== null && permissions.includes('applicant_add') && (
            <Link to={campaign_id 
                ? "/addClients?campaign_id=" + campaign_id 
                : campaignDetails
                  ? "/addClients?campaign_id=" + campaignDetails.pk
                  : "/addClients"
              }
            >
              <ButtonStyle primary label="Add" />
            </Link>
          )}
          {false && (<ButtonStyle
            primary
            label="Save"
            color={campaignDetails ? 'text-1' : 'brand'}
            onClick={() => {
              handleSaveCampaign(monthFull, formattedDate, monthNumber, year)
            }}
            disabled={campaignDetails ? true : false}
          />
          )}
          {false && (<ButtonStyle
            primary
            label="Mail"
            onClick={handlemailclicked}
            disabled={selected.length > 0 ? false : true}
          />
          )}
        </Box>
      </Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <Box>
          {noLoan ? (
            <BoxWrapper overflow={{horizontal: 'auto'}}>
              <Box style={{minWidth: '1250px'}}>
                <ReactTable data={[]} columns={COLUMNS} />
              </Box>
            </BoxWrapper>
          ) : (
            <BoxWrapper overflow={{horizontal: 'auto'}}>
              <Box style={{minWidth: '1250px'}}>
                <ReactTable
                  data={data}
                  columns={COLUMNS} // pass in our table columns
                  setSelected={setSelected} // sets selected row information
                  initialSortOrder={getInitialSorting()}
                  onSortChange={onSortChange}
                  // bulkChanged={bulkChanged}
                  // handleNewStatusChanged={handleNewStatusChanged}
                  handleApplicant={handleApplicant}
                  setOpen={setOpen}
                  setLayer={setLayer}
                  setParameters={setParameters}
                  campaignId={campaign_id}
                />
              </Box>
            </BoxWrapper>
          )}
        </Box>
      )}
      {open && (
        <ConfirmationLayer 
          setOpen={setOpen}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Update Loan Details'}
          layerBody={'Are you sure you want to Update the loan details?'}
        />
      )}
      {layer && (
        <ConfirmationLayer 
          setOpen={setLayer}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Reject Loan'}
          layerBody={'Are you sure you want to Reject the loan?'}
        />
      )}
      {acceptedLayer && (
        <ConfirmationLayer 
          setOpen={setAcceptedLayer}
          onConfirm={handleAccepted}
          layerHeader={'Update Loan Details'}
          layerBody={'Are you sure you want to Update the loan details?'}
        />
      )}
      {rejectedLayer && (
        <ConfirmationLayer 
          setOpen={setRejectedLayer}
          onConfirm={handleRejected}
          layerHeader={'Rejected'}
          layerBody={'Are you sure you want to Reject the loan details?'}
        />
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size
                ? total + ' out of ' + total
                : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default NewLoanTable
