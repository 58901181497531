const REVIEW_APPLICANT_EVENTS = {
  GET_CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  GET_LOAN_DETAILS_SUCCESS: 'GET_LOAN_SUCCESS',
  GET_LOAN_DETAILS_FAILURE: 'GET_LOAN_FAILURE',
  STATUS_CHANGED: 'STATUS_CHANGED_SUCCESS',
  GET_SEARCH_SUCCESS: 'GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE: 'GET_SEARCH_FAILURE',
  SHOW_DATA_CHANGED: 'DATA_CHANGED_SUCCESS',
  GET_CLIENT_LOAN_DETAILS_SUCCESS: 'GET_CLIENT_LOAN_DETAILS_SUCCESS',
  GET_CLIENT_LOAN_DETAILS_FAILURE: 'GET_CLIENT_LOAN_DETAILS_FAILURE',
  CLIENT_DETAIL_SUCCESS: 'CLIENT_DETAIL_SUCCESS',
  CLIENT_DETAIL_FAILURE: 'CLIENT_DETAIL_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  STATUS_CHANGED_SUCCESS: 'STATUS_CHANGED_SUCCESS',
  STATUS_CHANGED_FAILURE: 'STATUS_CHANGED_FAILURE',
  GET_SAVINGS_SUCCESS: 'GET_SAVINGS_SUCCESS',
  GET_SAVINGS_FAILURE: 'GET_SAVINGS_FAILURE',
  GET_MAIL_LIST_SUCCESS: 'GET_MAIL_LIST_SUCCESS',
  GET_MAIL_LIST_FAILURE: 'GET_MAIL_LIST_FAILURE',
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SEND_MAIL_FAILURE: 'SEND_MAIL_FAILURE',
  SEND_SMS_SUCCESS: 'SEND_SMS_SUCCESS',
  SEND_SMS_FAILURE: 'SEND_SMS_FAILURE',
}

export default REVIEW_APPLICANT_EVENTS
