// Lib Imports
import React from 'react'
import {Box, Heading, Image, Button} from 'grommet'
import {Formik} from 'formik'
import {useHistory} from 'react-router-dom'

const EmailSentSuccesss = ({onSubmit}) => {
  const history = useHistory()
  return (
    <Formik initialValues={{password: '', re_password: ''}} onSubmit={onSubmit}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Box
            //  border={{ color: 'red', size: 'large' }}
            fill="horizontal"
            direction="column"
            elevation="none"
            pad="medium"
            align="center"
          >
            <Image
              alignSelf="center"
              alt="logo"
              height="50px"
              src={process.env.PUBLIC_URL + '/Images/success.svg'}
            />
          </Box>
          <Box align="center">
            <Heading
              textAlign="center"
              color="dark-1"
              level={4}
              margin={{bottom: 'small'}}
            >
              {'Password resent link has been sent to your email'}
            </Heading>
          </Box>
          <Box
            align="center"
            pad="none"
            // border={{ color: 'brand', size: 'large' }}
          >
            <Box direction="row" gap="xsmall">
              <Heading
                onClick={handleSubmit}
                textAlign="start"
                color="dark-1"
                level={6}
                margin={{bottom: 'none'}}
              >
                {"Didn't recieved the email ? "}
              </Heading>
              <Heading
                onClick={handleSubmit}
                color="brand-1p"
                level={6}
                margin={{top: 'none', bottom: 'none'}}
              >
                {'Resend Link'}
              </Heading>
            </Box>

            <Box
              fill="horizontal"
              direction="column"
              elevation="none"
              justify="between"
              pad="medium"
              // border={{ color: 'red', size: 'large' }}
            >
              <Button
                onClick={() => {
                  history.push('/login')
                }}
                type="submit"
                alignSelf="center"
                margin={{vertical: 'small'}}
                label={'Login'}
                primary
              />
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}

export default EmailSentSuccesss
