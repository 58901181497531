import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import RiskReportTable from 'apiriskreportsettings/entities/riskReportTable'
import CampaignDetail from 'apiriskreportsettings/entities/campaignDetail'

async function getRiskTableAPI({params}) {
  if (params){
    params['rate_type_choice'] = params.rate_type_choice === 'All' ? '' : params.rate_type_choice
  }

    try {
      const response = await GraniteAPIClient().get('loan/loan-risk/', {
        params: params,
      })

      const loanData = response.data.results.map(item => {
        return new RiskReportTable(item)
      })

      const rowsCount = response.data.count

      return {loanData, rowsCount}
    } catch (error) {
      const errorMessage = error.errors.title ? error.errors.title : ''
      throw new GraniteError(errorMessage)
    }
}

async function addLoansToCampaignAPI(data) {
  try {
    const response = await GraniteAPIClient().post('/alr/loan/reviews/', data)
    return response.data.message
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}
async function getCampaignDetails() {
  try {
    const response = await GraniteAPIClient().get('/alr/campaign/')
    const campaign = new CampaignDetail(response.data)
    return campaign
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

export default {
  getRiskTableAPI,
  addLoansToCampaignAPI,
  getCampaignDetails,
}
