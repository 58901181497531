const CAMPAIGN_EVENTS = {
  GET_CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  SAVE_CAMPAIGN_SUCCESS: 'CAMPAIGN_SAVE_SUCCESS',
  SAVE_CAMPAIGN_FAILURE: 'CAMPAIGN_SAVE_FAILURE',
  GET_LOAN_DETAILS_SUCCESS: 'GET_LOAN_SUCCESS',
  GET_LOAN_DETAILS_FAILURE: 'GET_LOAN_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  GET_RISK_SUCCESS: 'GET_RISK_SUCCESS',
  GET_RISK_FAILURE: 'GET_RISK_FAILURE',
  GET_MAIL_TEMPLATE_LIST_SUCCESS: 'GET_MAIL_TEMPLATE_LIST_SUCCESS',
  GET_MAIL_TEMPLATE_LIST_FAILURE: 'GET_MAIL_TEMPLATE_LIST_FAILURE',
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SEND_MAIL_FAILURE: 'SEND_MAIL_FAILURE',
  GET_CAMPAIGN_ID_SUCCESS: 'GET_CAMPAIGN_ID_SUCCESS',
  GET_CAMPAIGN_ID_FAILURE: 'GET_CAMPAIGN_ID_FAILURE',
  SAVE_NEW_CAMPAIGN_SUCCESS: 'CAMPAIGN_NEW_SAVE_SUCCESS',
  SAVE_NEW_CAMPAIGN_FAILURE: 'CAMPAIGN_NEW_SAVE_FAILURE',
  GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
  GET_CAMPAIGN_LIST_FAILURE: 'GET_CAMPAIGN_LIST_FAILURE',
  NEW_LOANS_ACCEPTED_REJECTED_SUCCESS: 'NEW_LOANS_ACCEPTED_REJECTED_SUCCESS',
  NEW_LOANS_ACCEPTED_REJECTED_FAILURE: 'NEW_LOANS_ACCEPTED_REJECTED_FAILURE',
}

export default CAMPAIGN_EVENTS
