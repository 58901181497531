const CONTACTING_MESSAGES = {
  GET_LOAN_DETAILS_SUCCESS: 'Successfully fetched user!',

  GET_LOAN_DETAILS_FAILURE:
    "We are sorry something went wrong, or you don't have permission to perform this action ",

  GET_SEARCH_SUCCESS: 'Successfully fetched user!',

  GET_SEARCH_FAILURE:
    "We are sorry something went wrong, or you don't have permission to perform this action ",

  REJECTED_NEW_INTEREST_CHANGE: 'Mark loan as accepted to change this field',

  STATUS_SAVE_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible.",
  
  SEND_MAIL_SUCCESS: 'Email sent successfully!',

  SEND_MAIL_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",  
}

export default CONTACTING_MESSAGES
