let auth = {}

function loadAuthToken() {
  const authToken = localStorage.getItem('authToken')
  auth.token = authToken
}

function setAuthToken(authToken) {
  localStorage.setItem('authToken', authToken)
  auth.token = authToken
}

function getAuthToken() {
  return auth.token
}

function resetAuthToken() {
  localStorage.clear()
  auth.token = null
}

function setOrganisation(organisation) {
  localStorage.setItem('organisation', organisation)
  auth.organisation = organisation
}

function getOrganisation() {
  return auth.organisation
}

function loadOrganisation() {
  const organisation = localStorage.getItem('organisation')
  auth.organisation = organisation
}

export {
  loadAuthToken,
  setAuthToken,
  getAuthToken,
  resetAuthToken,
  setOrganisation,
  getOrganisation,
  loadOrganisation,
}
