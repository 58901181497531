import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import NewLoanDetail from 'alr/entities/newLoanDetail'
import AlrDetails from 'alr/entities/alrDetail'
import CampaignList from 'alr/entities/campaignList'
import CampaignListCommon from 'alr/entities/campaignListCommon'
import LoanDetails from 'alr/entities/loanDetail'
import ClientLoanDetails from '../entities/clientLoanDetail'
import OverviewInnerList from '../entities/outcomeinnerlist'
import ClientDetails from '../entities/clientDetail'
import LoanReview from 'alr/entities/loanReview'
import Savings from 'alr/entities/saving'
import MailList from 'alr/entities/mailtemplatelist'

async function getLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get('/alr/loan/review/', {
      params: values,
    })
    const loanData = response.data.results.map(item => {
      return new LoanDetails(item)
    })
    const rowsCount = response.data.count
    return {loanData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getClientResultReviewLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get(
      '/loan/notify/',
      {
        params: values,
      },
    )
    const loanData = response.data.results
    const rowsCount = response.data.count
    const tabs_count = response.data.tabs_count
    return {loanData, rowsCount, tabs_count}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getNegotiatePricingLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get(
      '/alr/loan/review/contracting/',
      {
        params: values,
      },
    )

    const loanData = response.data.results.map(item => {
      return new LoanDetails(item)
    })

    const rowsCount = response.data.count
    const tabs_count = response.data.tabs_count
    return {loanData, rowsCount, tabs_count}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getOutcomeLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get('/alr/loan/review/outcome/', {
      params: values,
    })
    const loanData = response.data.results.map(item => {
      return new LoanDetails(item)
    })
    const tabs_count = response.data.tabs_count
    const rowsCount = response.data.count
    return {loanData, rowsCount, tabs_count}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getClosedLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get('/alr/loan/review/closed/', {
      params: values,
    })
    const loanData = response.data.results.map(item => {
      return new LoanDetails(item)
    })
    const rowsCount = response.data.count
    const tabs_count = response.data.tabs_count
    return {loanData, rowsCount, tabs_count}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getClientLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get('/loan/loans/', {
      params: values,
    })
    const clientLoanData = response.data.results.map(item => {
      return new ClientLoanDetails(item)
    })
    const rowsCount = response.data.count
    return {clientLoanData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getClientsList(values) {
  try {
    const response = await GraniteAPIClient().get(`/loan/applicants/`, {
      params: values,
    })
    const clientData = response.data.results.map(item => {
      return new ClientDetails(item)
    })
    const rowsCount = response.data.count
    return {clientData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getNewLoanDetailsList(values) {
  try {
    const response = await GraniteAPIClient().get('/alr/loan/review/new/', {
      params: values,
    })
    const loanData = response.data.results.map(item => {
      return new NewLoanDetail(item)
    })

    const rowsCount = response.data.count
    const tabs_count = response.data.tabs_count

    return {loanData, rowsCount, tabs_count}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function statusChanged(payload, id) {
  try {
    const response = await GraniteAPIClient().patch(
      '/loan/loan-info/' + id + '/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function statusChangedOutcomeScreen(payload, id, loanPk) {
  // try {
  //   const response = await GraniteAPIClient().patch(
  //     'alr/loan-review/' + loanPk + '/',
  //     payload,
  //   )
  //   return response
  // } catch (error) {
  //   const errorMessage = error.response.data.message
  //   throw new GraniteError(errorMessage)
  // }
  try {
    const response = await GraniteAPIClient().patch(
      '/loan/loan-info/' + id + '/',
      payload,
    )
    await GraniteAPIClient().patch('alr/loan-review/' + loanPk + '/', {
      status: 'Closed',
    })
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}
async function getMoreDetails(id) {
  try {
    const response = await GraniteAPIClient().get('/loan/loan-info/' + id + '/')
    const moreData = new LoanReview(response.data)
    const savings = await getSavings({applicant_id: moreData.applicant_pk, loan_id: id})
    return { moreData, savings }
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function newAddedLoans(payload) {
  try {
    const response = await GraniteAPIClient().post(
      '/alr/loan/reviews/',
      payload,
    )
    return response.data.message
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getAlrData(values, id) {
  try {
    const response = await GraniteAPIClient().get('/alr/', {
      params: values,
    })
    const alrLoanData = response.data.results.map(item => {
      return new AlrDetails(item)
    })
    const rowsCount = response.data.count
    return {alrLoanData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getCampaignList(values) {
  try {
    const response = await GraniteAPIClient().get('/alr/campaign-list/', {
      params: values,
    })
    const campaignData = response.data.results.map(item => {
      return new CampaignList(item)
    })

    const rowsCount = response.data.count
    return {campaignData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function bulkStatusChanged(payload) {
  try {
    const response = await GraniteAPIClient().put('/alr/status/', payload)
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function bulkOutcomeChanged(payload) {
  try {
    const response = await GraniteAPIClient().put('/loan/status/', payload)
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function newStatusAndRate(payload, id) {
  try {
    const response = await GraniteAPIClient().patch(
      '/alr/loan-review/' + id + '/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getClientInfo(clientId) {
  try {
    const response = await GraniteAPIClient().get(
      `/loan/applicants/` + clientId + `/`,
    )

    const data = new ClientDetails(response.data)
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function exportCampaign(campaignId) {
  try {
    const response = await GraniteAPIClient().get(
      '/alr/' + campaignId + '/export/',
    )
    return response.data.url
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function newDiscount(id, payload) {
  try {
    const response = await GraniteAPIClient().put(
      '/loan/loan-info/' + id + '/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getSavings(params) {
  try {
    const response = await GraniteAPIClient().get('/alr/savings/', {
      params: params,
    })
    const savingsData = new Savings(response.data)
    return savingsData
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// GATEWAY
async function mailClientInfoNew(name, Id, values) {
  const payload = {
    template_name: name,
    applicant_ids: Id,
    content: values.content,
  }

  try {
    const response = await GraniteAPIClient().post(
      `loan/applicant_mails/`,
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getMailTemplateList(name, clientid) {
  const params = {
    screen_name: name,
  }
  try {
    const response = await GraniteAPIClient().get(
      `loan/applicant_mail/` + clientid + '/',
      {params: params},
    )
    const data = response.data.map(item => {
      return new MailList(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// Closed screen

// GATEWAY
async function mailClientInfoClosed(name, Id, values) {
  const payload = {
    template_name: name,
    applicant_ids: Id,
    content: values.content,
  }
  try {
    const response = await GraniteAPIClient().post(
      `loan/applicant_mails/`,
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function campaignedit(payload) {
  try {
    const response = await GraniteAPIClient().post('loan/campaign-sync/', payload)
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function NewScreenSendMail(name, values, applicant, campaignId, status) {
  try {
      const payload = {
        template_name: name,
        applicant_ids: [applicant.pk],
        content: values.content.replace('{applicant_name}', applicant.first_name),
      }
    let response = await GraniteAPIClient().post(`loan/applicant_mails/`, payload)
    let result = statusChangeOnNotify(applicant, campaignId, status)
    const arr = [response, result]
    result = await Promise.allSettled(arr)
    
    return {response, result}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function statusChangeOnNotify(applicant, campaignId, status) {
  try {
      const payload = {
        status: status,
        campaign_id: campaignId,
        applicant_id: applicant.pk
      }
      const result = await GraniteAPIClient().post(`alr/loan/review-status/`, payload)
      return result
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// OutcomeInnerLoanListAPI

async function OutcomeInnerLoanListAPI(values) {
  const params = {
    applicant_id: values.applicant_id,
    page: values.page,
    page_size: values.page_size,
    campaign_id:
      values.comingCampaignId === 'null' ? values.pk : values.comingCampaignId,
    status: values.status,
  }

  try {
    const response = await GraniteAPIClient().get('alr/loan/review/new/', {
      params: params,
    })

    const clientLoanData = response.data.results.map(item => {
      return new OverviewInnerList(item)
    })
    const rowsCount = response.data.count
    return {clientLoanData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function sendSmsOutcomeAPI(value, pk, phone) {
  const payload = {
    template_name: 'APPLICANT_SMS_NOTIFY',
    applicant_ids: [pk],
    content: value.sms,
  }

  try {
    const response = await GraniteAPIClient().post(
      `loan/applicant_mails/`,
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getcommonCampaignList(select_params) {
  const params = {
    page: select_params.page,
    page_size: select_params.page_size,
    sorting: '-date',
  }
  try {
    const response = await GraniteAPIClient().get('/alr/campaign-list/', {
      params: params,
    })
    const campaignData = response.data.results.map(item => {
      return new CampaignListCommon(item)
    })

    const rowsCount = response.data.count
    return {campaignData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getAllCampaigns(select_params) {
  const params = {
    page: select_params.page,
    page_size: select_params.page_size,
    sorting: '-date',
  }
  try {
    const response = await GraniteAPIClient().get('/alr/campaign-list/', {
      params: params,
    })
    const resultant_array = response
      ? response.data
        ? response.data.results
        : []
      : []
    let last_campaign = 0
    resultant_array.forEach(character => {
      if (character.pk > last_campaign) {
        last_campaign = character
      }
    })

    return last_campaign
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

export default {
  getLoanDetailsList,
  getClientLoanDetailsList,
  getClientsList,
  getNewLoanDetailsList,
  statusChanged,
  newAddedLoans,
  bulkStatusChanged,
  getMoreDetails,
  getAlrData,
  getCampaignList,
  bulkOutcomeChanged,
  newStatusAndRate,
  getClientInfo,
  getClientResultReviewLoanDetailsList,
  getNegotiatePricingLoanDetailsList,
  getOutcomeLoanDetailsList,
  getClosedLoanDetailsList,
  exportCampaign,
  newDiscount,
  getSavings,
  statusChangedOutcomeScreen,
  getMailTemplateList,
  mailClientInfoNew,
  mailClientInfoClosed,
  campaignedit,
  NewScreenSendMail,
  OutcomeInnerLoanListAPI,
  sendSmsOutcomeAPI,
  getcommonCampaignList,
  getAllCampaigns,
  statusChangeOnNotify,
}
