import React from 'react'
import CurrencyInput from 'react-currency-input'
import {useFormikContext} from 'formik'
import styled from 'styled-components'

const StyledInput = styled(CurrencyInput)`
  box-sizing: border-box;
  font-size: 12px;
  font-family: inherit;
  -webkit-appearance: none;
  // color: inherit;
  color:"dark";
  width: 100%;
  padding: 9px;
  font-weight: 400;
  margin: 0;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  background: white;
  font-size: 12px;
`

const InputCurrency = ({handleChange, name,disabled, ...rest}) => {
  const {setFieldValue} = useFormikContext() || {}
  return (
    <StyledInput
      {...rest}
      name
      disabled={disabled ? disabled : false}
      prefix="$ "
      onChangeEvent={(e, v, f) => {
        if (setFieldValue) setFieldValue(name, f)
        else handleChange(e, v, f)
      }}
    />
  )
}

InputCurrency.defaultProps = {
  handleChange: () => {},
}

export default InputCurrency
