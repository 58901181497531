import React, {useState} from 'react'
import {Box, Text, CheckBox, Button, Layer, Select, Image} from 'grommet'

import ConfirmationLayer from 'granite/components/ConfirmationLayer'

import {campaigneditapi} from 'alr/controllers/loanReview'

const CreateCampaign = ({setPopup, eventEmitter}) => {
  const currentYear = new Date().getFullYear()
  const monthArray = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [checked, setChecked] = useState(false)
  const [open, setOpen] = useState(false)

  const handleCampaignForm = () => {
    campaigneditapi(eventEmitter, year, monthArray.indexOf(month)+1, checked)
    setPopup()
  }

  return (
    <Layer onEsc={setPopup} onClickOutside={setPopup}>
      <Box fill>
        <Box
          direction="row"
          justify="between"
          style={{borderBottom: '2px solid #37236a'}}
        >
          <Text
            color="dark-6"
            size="medium"
            margin={{left: '20px', top: '15px', bottom: '15px'}}
            style={{fontWeight: '700'}}
          >
            Create a New Campaign
          </Text>
          <Button
            margin={{right: 'small', bottom: 'small'}}
            icon={<Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />}
            onClick={setPopup}
          />
        </Box>
        <Box margin="medium" gap="medium">
          <Text>Please select a future date</Text>
          <Box direction="row">
            <Text alignSelf="center" weight="bold" margin={{right: 'small'}}>
              Select Month:
            </Text>
            <Select
              options={monthArray}
              value={month}
              onChange={({option}) => setMonth(option)}
            />

            <Text
              alignSelf="center"
              weight="bold"
              margin={{left: 'medium', right: 'small'}}
            >
              Select Year:
            </Text>
            <Select
              options={[currentYear, currentYear + 1, currentYear + 2]}
              value={year}
              onChange={({option}) => setYear(option)}
            />
          </Box>
          <CheckBox
            checked={checked}
            label="Do NOT automatically add respective loans to this campaign?"
            onChange={event => setChecked(event.target.checked)}
          />
        </Box>
      </Box>
      <Box margin="medium" alignSelf="end">
        <Button
          label="Submit"
          primary
          disabled={
            !year ||
            !month ||
            (year &&
              month &&
              year === currentYear &&
              monthArray.indexOf(month) < new Date().getMonth())
          } //disable if selected month year combo generates past date
          onClick={() => {
            setOpen(true)
          }}
        />
      </Box>
      {open && (
        <ConfirmationLayer
          setOpen={setOpen}
          onConfirm={handleCampaignForm}
          layerHeader={'New Campaign Creation'}
          layerBody={
            'This campaign will be treated as current campaign. Are you sure you want to create this?'
          }
        />
      )}
    </Layer>
  )
}

export default CreateCampaign
