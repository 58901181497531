const DASHBOARD_EVENTS = {
  DASHBOARD_GET_PANELS_SUCCESS: 'DASHBOARD_GET_PANELS_SUCCESS',
  DASHBOARD_GET_PANELS_FAILURE: 'DASHBOARD_GET_PANELS_FAILURE',

  DASHBOARD_GET_LIST_SUCCESS: 'DASHBOARD_GET_LIST_SUCCESS',
  DASHBOARD_GET_LIST_FAILURE: 'DASHBOARD_GET_LIST_FAILURE',
  GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'GET_CAMPAIGN_FAILURE',
}
export default DASHBOARD_EVENTS
