import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Box} from 'grommet'
import {useHistory, useLocation} from 'react-router-dom'
import NewHeader from 'granite/components/HeaderWithDropDown'
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'
import LoaderUI from 'granite/components/NumLoader'
import * as UserDucks from 'accounts/ducks/user'
import * as OrganisationDucks from 'organisations/ducks/organisations'
import DASHBOARD_EVENTS from './constants'
import DASHBOARD_MESSAGES from 'messages/dashboard'
// import ALRTable from './components/alrtable'
import OALRTable from './components/outdatedAlr'
import {getPanels} from 'dashboard/controllers/dashboard'
import queryString from 'query-string'
import CONTACTING_EVENTS from 'pages/contacting/constants'
let eventEmitter = new EventEmitter()

function listenEvents({
  eventEmitter,
  // setCount,
  setToastData,
  setToastWarning,
  // setTotalRows,
  // setalrtabledata,
  setpanels,
  setTotal,
  setLoader,
  handleRefresh,
  // setsyncvisible,
  // setcampaignId,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case DASHBOARD_EVENTS.DASHBOARD_GET_LIST_SUCCESS:
        // setalrtabledata(event.data.data)
        // setTotalRows(event.data.count)
        // setCount(Math.ceil(event.data.count / 10))
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break
      case DASHBOARD_EVENTS.DASHBOARD_GET_LIST_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : DASHBOARD_MESSAGES.LIST_GET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
          setLoader(false)
        }, 5000)
        break
      case DASHBOARD_EVENTS.DASHBOARD_GET_PANELS_SUCCESS:
        setpanels(event.data)
        setTotal(event.data.length)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break
      case DASHBOARD_EVENTS.DASHBOARD_GET_PANELS_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : DASHBOARD_MESSAGES.GET_PANELS_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
          setLoader(false)
        }, 5000)
        break
      // case CONTACTING_EVENTS.SEND_MAIL_FAILURE:
      //   setsyncvisible(false)
      //   break
      case CONTACTING_EVENTS.SAVE_NEW_CAMPAIGN_SUCCESS:
        setLoader(true)
        setTimeout(handleRefresh, 1000)
        break
      case CONTACTING_EVENTS.SAVE_NEW_CAMPAIGN_FAILURE:
        break
      // case DASHBOARD_EVENTS.GET_CAMPAIGN_SUCCESS:
      //   if (event.data) {
      //     // setcampaignId(event.data.pk)
      //     setsyncvisible(false)}
      //   break
      // case DASHBOARD_EVENTS.GET_CAMPAIGN_FAILURE:
      //   setsyncvisible(true)
      //   break
      default:
        break
    }
  })
  return subsciption
}

function Dashboard({userProfile}) {
  let defaultFilterData = {
    page: 1,
    page_size: 20,
  }
  // const [alrtabledata, setalrtabledata] = useState([])
  const [panels, setpanels] = useState({})
  const history = useHistory()
  // const [count, setCount] = useState(10)
  // const [size, setSize] = useState(10)
  // const [rows, setTotalRows] = useState(10)
  const [total, setTotal] = useState(0)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [toastWarning, setToastWarning] = useState(false)
  const [loader, setLoader] = useState(true)
  // const [syncvisible, setsyncvisible] = useState(false)
  // const [campaignId, setcampaignId] = useState(-1)

  const handleRefresh = () => {
    if (!loader) {
      // setSize(10)
      setLoader(true)
    }  
      getPanels(filterData, eventEmitter)
      // getCampaign(eventEmitter)
      // getDashBoardTable(eventEmitter)
  }

  let location = useLocation()
  const {search} = location

  const [filterData, setFilterData] = useState(defaultFilterData)
  let queryParam = queryString.parse(search)

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    
    history.push(pathObj)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        history,
        // setCount,
        // setTotalRows,
        setToastWarning,
        // setalrtabledata,
        setpanels,
        setTotal,
        // setSize,
        setLoader,
        handleRefresh,
        // setsyncvisible,
        // setcampaignId, 
      })
      getPanels(filterData, eventEmitter)
      // getCampaign(eventEmitter)
      // getDashBoardTable(eventEmitter, filterData)
      // return () => {
      //   subscription.unsubscribe()
      // }
      return subscription
    }
    let temp = init()
    return () => {
      temp.unsubscribe()
    }
  }, [history, location, filterData])

  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'Loan Management Dashboard'}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
      />
      <Box pad="medium" overflow={{vertical: 'auto'}} fill>
        {loader ? (
          <Box align="center" justify="center" fill>
            <LoaderUI />
          </Box>
        ) : (
        <>
          {/* <ALRTable
            tableData={alrtabledata}
            panels={panels}
            eventEmitter={eventEmitter}
            rows={rows}
            setTotalRows={setTotalRows}
            size={size}
            setSize={setSize}
            count={count}
            navigateToParams={navigateToParams}
            loader={loader}
            setLoader={setLoader}
            syncvisible={syncvisible}
            campaignId ={campaignId}
            handleRefresh ={handleRefresh}
          /> */}
          <OALRTable
            tableData={panels}
            navigateToParams={navigateToParams}
            setLoader={setLoader}
            eventEmitter={eventEmitter}
            // syncvisible={syncvisible}
            handleRefresh ={handleRefresh}
            filterData={filterData}
            total={total}
          />
        </>
        )}
        {toastData.open ? (
          toastWarning ? (
            <Toast
              text={toastData.message}
              background="warning"
              onClose={() => {
                setToastData({
                  open: false,
                  message: '',
                })
              }}
            />
          ) : (
            <Toast
              text={toastData.message}
              onClose={() => {
                setToastData({
                  open: false,
                  message: '',
                })
              }}
            />
          )
        ) : null}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userOrganisations: OrganisationDucks.organisations(state),
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(Dashboard)
