const ALR_EVENTS = {
  GET_RISK_SUCCESS: 'GET_RISK_SUCCESS',
  GET_RISK_FAILURE: 'GET_RISK_FAILURE',
  GET_LOAN_DETAILS_SUCCESS: 'GET_LOAN_SUCCESS',
  GET_LOAN_DETAILS_FAILURE: 'GET_LOAN_FAILURE',
  SHOW_DATA_CHANGED: 'DATA_CHANGED_SUCCESS',
  ALR_DATA_SUCCESS: 'ALR_DATA_SUCCESS',
  ALR_DATA_FAILURE: 'ALR_DATA_FAILURE',
  ALR_SEARCH_SUCCESS: 'ALR_SEARCH_SUCCESS',
  ALR_SEARCH_FAILURE: 'ALR_SEARCH_FAILURE',
  CAMPAIGN_LIST_SUCCESS: 'CAMPAIGN_LIST_SUCCESS',
  CAMPAIGN_LIST_FAILURE: 'CAMPAIGN_LIST_FAILURE',
  GET_CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  GET_MORE_DETAILS_SUCCESS: 'GET_MORE_DETAILS_SUCCESS',
  GET_MORE_DETAILS_FAILURE: 'GET_MORE_DETAILS_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  EXPORT_CAMPAIGN_SUCCESS: 'EXPORT_CAMPAIGN_SUCCESS',
  EXPORT_CAMPAIGN_FAILURE: 'EXPORT_CAMPAIGN_FAILURE',
  GET_SAVINGS_SUCCESS: 'GET_SAVINGS_SUCCESS',
  GET_SAVINGS_FAILURE: 'GET_SAVINGS_FAILURE',
}

export default ALR_EVENTS
