class LoanDetails {
  constructor(item) {
    const Type = {
      B: 'Borrower',
      G: 'Guarantor',
      TT: 'Trustee',
      SG: 'SIGNATORY',
      SF: 'SUPERFUND',
    }
    const OutcomeValues = {
      1: '--No Changes--',
      2: '$!Money saved, no further action',
      3: '$!Money saved, further action required',
      4: '$!No money saved, further action required',
    }

    const GenerateApplicant = data => {
      let temp_arr = []
      data.forEach(element => {
        temp_arr.push(element.name)
      })
      return temp_arr.join('\r\n\n')
    }
    const GenerateString = data => {
      let temp_arr = []
      data.forEach(element => {
        let state = element.state ? statesAbbr[element.state] : ''
        let addr =
          element.address_line_1 +
          ' ' +
          element.address_line_2 +
          ' ' +
          element.city +
          ' ' +
          state +
          ' ' +
          element.postcode
        temp_arr.push(addr)
      })
      return temp_arr.join('\r\n\n')
    }

    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }

    this.pk = item.loan.pk
    this.name = GenerateApplicant(item.applicant)
    this.lender = item.loan.lender.name
    this.term = item.loan.term
    this.loan__rate = item.loan.rate
    this.rate = item.loan.rate
    this.loan_new_rate = item.loan.new_rate
    this.loan__balance = item.loan.balance
    this.loan__loan_limit = item.loan.loan_limit
    this.modified = item.loan.modified_date
    this.purpose = item.loan.purpose
    this.securityDetails = item.loan.security_address
    this.loan__account_number = item.loan.account_number
    this.loan__settlement_date = item.loan.settlement_date
    this.repayment = item.loan.repayment_type
    this.rate_type_choice = item.loan.rate_type_choice
    this.loan_expiry_date = item.loan.loan_expiry_date
    this.fixed_expiry = item.loan.fixed_expiry
    this.io_expiry = item.loan.io_expiry
    this.rowActive = !item.loan.is_active
    this.applicant_type = Type[item.applicant_type]
    this.out_come = OutcomeValues[item.loan.out_come] || ''
    this.new_security_array = item.loan.security_address
    this.securityArraayString = GenerateString(item.loan.security_address)
    this.security =
      (item.loan.security_address[0].address_line_1 === null
        ? ''
        : item.loan.security_address[0].address_line_1) +
      ' ' +
      (item.loan.security_address[0].address_line_2 === null
        ? ''
        : item.loan.security_address[0].address_line_2) +
      ' ' +
      (item.loan.security_address[0].city === null
        ? ''
        : item.loan.security_address[0].city) +
      ' ' +
      (item.loan.security_address[0].state === null || item.loan.security_address[0].state === ''
        ? ''
        : statesAbbr[item.loan.security_address[0].state]) +
      ' ' +
      (item.loan.security_address[0].postcode === null
        ? ''
        : item.loan.security_address[0].postcode)
  }
}

export default LoanDetails
