//Library imports
import React, {useState, useEffect} from 'react'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {Box, Image, Button, TextInput} from 'grommet'
import styled from 'styled-components'

//Core imports
import EventEmitter from 'utils/event-emitter'
import Header from 'granite/components/HeaderWithDropDown'
import Toast from 'granite/components/Toast'

//Application imports
import {loadClients, handleAddClientLoans} from 'alr/controllers/loanReview'
import * as UserDucks from 'accounts/ducks/user'
import AddLoan from './components/AddLoan'
import ADD_CLIENT_EVENTS from './constants'
import ADD_CLIENT_MESSAGES from '../../messages/addClient'
import ClientTable from './components/ClientTable'
import BackNavigation from 'granite/components/BackNavigation'

const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
  color:#000000;
`

const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius-right: 4px !important;
  padding-left: 14px !important;
`
const ButtonStyle = styled(Button)`
height:36px
width:110px
`
const ButtonStyleSearch = styled(Button)`
  height: 35px;
  width: 110px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
`

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setLoanData,
  setClientData,
  setCampaignDetails,
  setLoader,
  setAddLoanLoader,
  history,
  filterData,
  setRows,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case ADD_CLIENT_EVENTS.GET_CLIENT_LOAN_DETAILS_SUCCESS:
        setRows(event.data.rowsCount)
        setLoanData(event.data.clientLoanData)
        setLoader(false)
        break
      case ADD_CLIENT_EVENTS.GET_CLIENT_LOAN_DETAILS_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_CLIENT_MESSAGES.GET_CLIENT_LOAN_DETAILS_FAILURE,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case ADD_CLIENT_EVENTS.GET_CLIENTS_SUCCESS:
        setClientData({
          data: event.data.clientData,
          total: event.data.rowsCount,
        })
        setLoader(false)
        break
      case ADD_CLIENT_EVENTS.GET_CLIENTS_FAILURE:
        break

      case ADD_CLIENT_EVENTS.GET_CAMPAIGN_SUCCESS:
        setCampaignDetails(event.data)
        break
      case ADD_CLIENT_EVENTS.GET_CAMPAIGN_FAILURE:
        break

      case ADD_CLIENT_EVENTS.SAVE_LOAN_SUCCESS:
        setToastData({
          open: true,
          message: event.data,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        history.push(
          '/gatherloandetails?msg=' +
            event.data +
            '&campaign_id=' +
            filterData.campaign_id,
        )

        break
      case ADD_CLIENT_EVENTS.SAVE_LOAN_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_CLIENT_MESSAGES.GET_CLIENTS_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case ADD_CLIENT_EVENTS.SHOW_LOADER:
        setLoader(true)
        break
      case ADD_CLIENT_EVENTS.HIDE_LOADER:
        setLoader(false)
        break

      case ADD_CLIENT_EVENTS.SHOW_ADD_LOAN_LOADER:
        setAddLoanLoader(true)
        break
      case ADD_CLIENT_EVENTS.HIDE_ADD_LOAN_LOADER:
        setAddLoanLoader(false)
        break

      default:
        break
    }
  })
  return subscription
}

const AddClients = ({userProfile}) => {
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [loader, setLoader] = useState(false)
  const [layer, setLayer] = useState(false)
  const [loanData, setLoanData] = useState([])
  const [clientData, setClientData] = useState({data: [], total: ''})
  const [campaignDetails, setCampaignDetails] = useState([])
  const [customerId, setCustomerId] = useState([])
  const [newCustomerId, setNewCustomerId] = useState()
  const [addLoanLoader, setAddLoanLoader] = useState(false)
  const [newAddedLoans, setNewAddedLoans] = useState([])
  const [rows, setRows] = useState(0)

  const history = useHistory()

  let defaultFilterData = {
    page: 1,
    page_size: 20,
    select_page: 1,
    select_page_size: 20,
  }

  let location = useLocation()
  const {search} = location

  let queryParam = queryString.parse(search)

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  const handleRefresh = () => {
    setLoader(true)
    setSearchValue('')
    navigateToParams({
      page: 1, 
      page_size: 20,
      select_page: 1,
      select_page_size: 20, 
      search: null, 
      status: null
    })
  }

  const handleSearchClicked = () => {
    if (searchValue !== '') {
      navigateToParams({search: searchValue, page: 1})
    }
  }

  const handleAdd = () => {
    handleAddClientLoans(eventEmitter, newAddedLoans)
  }

  const handleSearchCancel = () => {
    setSearchValue('')
    navigateToParams({page: 1, page_size: 20, search: null})
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setLoanData,
        setClientData,
        setCampaignDetails,
        setLoader,
        setAddLoanLoader,
        history,
        filterData,
        setRows,
      })
      const obj = {
        params: filterData,
      }
      loadClients(eventEmitter, obj)
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [location, filterData, history])

  return (
    <Box flex={{grow: 1}}>
      <Header
        pageTitle="Client List"
        userName={`${userProfile.title} ${userProfile.name}`}
        showTotalSavings={false}
        showAlrSavings={false}
        handleRefresh={handleRefresh}
      />
      <Box pad={{left: 'medium', right: 'medium'}}>
        <BackNavigation label={'Back'} />
      </Box>

      <Box
        overflow={{vertical: 'auto'}}
        fill
        pad={{left: 'medium', right: 'medium', bottom: 'medium'}}
      >
        {layer && (
          <AddLoan
            layer={layer}
            setLayer={setLayer}
            loanData={loanData}
            eventEmitter={eventEmitter}
            campaignDetails={campaignDetails}
            customerId={customerId}
            setCustomerId={setCustomerId}
            newCustomerId={newCustomerId}
            addLoanLoader={addLoanLoader}
            newAddedLoans={newAddedLoans}
            setNewAddedLoans={setNewAddedLoans}
            navigateToParams={navigateToParams}
            filterData={filterData}
            rows={rows}
            campaignId={queryParam.campaign_id}
          />
        )}

        <Box
          gap="large"
          direction="row"
          justify="between"
          width="100%"
          margin={{top: 'medium'}}
        >
          <Box gap="medium" direction="row" width="40%">
            <SearchBoxWrapper
              width="100%"
              direction="row"
              height="36px"
              align="end"
              round="4px"
            >
              <TextInputWrapper
                placeholder=" Name, Phone No., Email Search"
                value={searchValue}
                onChange={event => {
                  setSearchValue(event.target.value)
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleSearchClicked()
                  }
                }}
              />
              <Button
                alignSelf="center"
                icon={
                  <Image
                    src={process.env.PUBLIC_URL + '/img/search_cancel.png'}
                    fit="contain"
                  />
                }
                onClick={handleSearchCancel}
              />
              <ButtonStyleSearch
                type="submit"
                label={'Search'}
                onClick={() => handleSearchClicked()}
                primary
              />
            </SearchBoxWrapper>
          </Box>
        </Box>
        <Box margin={{top: 'medium'}}>
          <ClientTable
            data={clientData}
            setLayer={setLayer}
            eventEmitter={eventEmitter}
            loader={loader}
            customerId={customerId}
            setNewCustomerId={setNewCustomerId}
            navigateToParams={navigateToParams}
            filterData={filterData}
            campaignId={queryParam.campaign_id}
          />
        </Box>

        <Box
          alignSelf="end"
          gap="medium"
          direction="row"
          margin={{top: 'medium'}}
        >
          <ButtonStyle
            primary
            color={newAddedLoans.length ? 'brand' : 'text-1'}
            disabled={newAddedLoans.length > 0 ? false : true}
            label="Add"
            onClick={() => {
              handleAdd()
            }}
          />

          <Link to="/gatherloandetails">
            <ButtonStyle primary label="Cancel" />
          </Link>
        </Box>
      </Box>
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(AddClients)
