import React from 'react'
import {Box, Image, Text, Button, Layer} from 'grommet'
import styled from 'styled-components'
// import Form from './notesForm'
// import {addClientDetailsNotes} from 'clientDetails/controllers/client'
// import {dischargeLoan} from 'clientloandetail/controllers/loan'
// import ADD_USER_MESSAGE from 'messages/addUser'
import {makeUserActiveInactive} from 'accounts/controllers/user'
const LayerStyle = styled(Layer)``

const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const ActiveInactivePopUp = ({
  layer,
  setLayer,
  makeinactivedata,
  event,
  setLoader,
  warningmessage,
  btnlabel,
}) => {
  // const [view, setView] = useState(true)
  const handleCancel = () => {
    setLayer(false)
  }

  const handleInactive = (makeinactivedata, event) => {
    setLoader(true)
    makeUserActiveInactive(makeinactivedata, event)
    setLayer(false)
  }

  return (
    <>
      {true && (
        <Box>
          <LayerStyle
            onEsc={() => setLayer(false)}
            onClickOutside={() => setLayer(false)}
          >
            <Box width="100%" height="100%">
              <MoreDetailsWrapper direction="row" justify="between">
                <MoreDetailsTextWrapper
                  color="dark-6"
                  size="16px"
                  margin={{left: '20px', top: '15px', bottom: '15px'}}
                >
                  {'User ' + btnlabel}
                </MoreDetailsTextWrapper>

                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                  }
                  onClick={() => setLayer(!layer)}
                />
              </MoreDetailsWrapper>

              <Box
                margin={{
                  top: 'small',
                  left: 'small',
                  right: 'small',
                  bottom: 'none',
                }}
                direction="column"
                gap="medium"
                pad={{bottom: 'none'}}
              >
                <Text>{warningmessage}</Text>
              </Box>
            </Box>
            <Box margin={{bottom: 'medium'}}></Box>
            <Box
              pad={{right: 'small', bottom: 'medium'}}
              gap="medium"
              fill="horizontal"
              direction="row"
              elevation="none"
              justify="end"
              //   border={{color: 'red', size: 'small'}}
            >
              <Button
                type="submit"
                alignSelf="end"
                label={btnlabel}
                onClick={() => handleInactive(makeinactivedata, event)}
                primary
              />
              <Button onClick={() => handleCancel()} label={'Cancel'} primary />
            </Box>
          </LayerStyle>
        </Box>
      )}
    </>
  )
}

export default ActiveInactivePopUp
