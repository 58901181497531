// Lib imports
// import _ from 'lodash/fp'
// import GraniteError from 'utils/granite-error'
import CustomerAPI from 'clientlist/gateways/client-api'
import CLIENT_LIST from 'pages/clientList/constants'
import CLIENT_LIST_IMPORT_EVENTS from 'pages/clientListImport/constants'

async function fetchEmployeeSearch(eventEmitter, params) {
  try {
    const resp = await CustomerAPI.getCustomerList(params)

    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_SEARCH_SUCCESS, resp)
  } catch (err) {
    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_SEARCH_FAILURE, err.message)
  }
}

async function fetchEmployeeSort(eventEmitter, params) {
  try {
    const resp = await CustomerAPI.getCustomerList(params)
    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLISTSORTED_SUCCESS, resp)
  } catch (err) {
    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLISTSORTED_FAILURE, err.message)
  }
}

async function fetchEmployee(eventEmitter, params) {
  try {
    const resp = await CustomerAPI.getCustomerList(params)
    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_SUCCESS, resp)
  } catch (err) {
    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_FAILURE, err.message)
  }
}

async function fetchEmployeePagination(eventEmitter, val) {
  try {
    const resp = await CustomerAPI.getCustomerList(val)
    eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_PAGINATION_SUCCESS, resp)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LIST.GET_CLIENTSLIST_PAGINATION_FAILURE,
      err.message,
    )
  }
}

// async function ExportData(eventEmitter) {
//   try {
//     const resp = await CustomerAPI.ExportCutomerList()
//     eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_EXPORT_SUCCESS, resp)
//   } catch (err) {
//     eventEmitter.emit(CLIENT_LIST.GET_CLIENTSLIST_EXPORT_FAILURE, err.message)
//   }
// }

async function getLoanBook(eventEmitter, params) {
  try {
    let data = params
    const response = await CustomerAPI.loanBook(data)
    eventEmitter.emit(CLIENT_LIST_IMPORT_EVENTS.GET_LOAN_BOOK_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.GET_LOAN_BOOK_FAILURE,
      err.message,
    )
  }
}

async function getErroredRecords(eventEmitter, pk, params) {
  try {
    let data = params
    const response = await CustomerAPI.erroredRecords(pk, data)
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.GET_ERRORED_RECORDS_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.GET_ERRORED_RECORDS_FAILURE,
      err.message,
    )
  }
}

async function incorrectFileDownload(eventEmitter, fileId) {
  try {
    const response = await CustomerAPI.fileDownload(fileId)
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.INCORRECT_DOWNLOAD_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.INCORRECT_DOWNLOAD_FAILURE,
      err.message,
    )
  }
}

async function sampleFileDownload(eventEmitter) {
  try {
    const response = await CustomerAPI.getSampleFileDownload()
    // const saveData = (data, fileName) => {
    //   const a = document.createElement('a')
    //   document.body.appendChild(a)
    //   a.style = 'display: none'
    //   return (function(data, fileName) {
    //     const blob = new Blob([data], {type: 'octet/stream'}),
    //       url = window.URL.createObjectURL(blob)
    //     a.href = url
    //     a.download = fileName
    //     a.click()
    //     window.URL.revokeObjectURL(url)
    //   })(data, fileName)
    // }
    // saveData(response, 'sample.csv')
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.SAMPLE_DOWNLOAD_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.SAMPLE_DOWNLOAD_FAILURE,
      err.message,
    )
  }
}

async function fileImport(eventEmitter, fileData) {
  try {
    const response = await CustomerAPI.fileImport(fileData)

    eventEmitter.emit(CLIENT_LIST_IMPORT_EVENTS.FILE_UPLOAD_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LIST_IMPORT_EVENTS.FILE_UPLOAD_FAILURE,
      err.message,
    )
  }
}

export {
  fetchEmployee,
  fetchEmployeeSearch,
  fetchEmployeeSort,
  fetchEmployeePagination,
  // ExportData,
  getLoanBook,
  getErroredRecords,
  incorrectFileDownload,
  sampleFileDownload,
  fileImport,
}
