import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import OverDueALR from 'dashboard/entities/overdue'
import CampaignDetail from 'dashboard/entities/campaignDetail.js'
import Stats from 'dashboard/entities/stats.js'
async function getPanels(values) {
  try {
    const response = await GraniteAPIClient().get('alr/dashboard/stats/', {
      params: values
    })
    const data = response.data.map(item => {
      return new Stats(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getdashboardList(values) {
  try {
    const response = await GraniteAPIClient().get('/alr/overdue/list/', {
      params: values,
    })
    const count = response.data.count
    const next_page = response.data.next_page
    const previous_page = response.data.previous_page
    const data = response.data.results.map(item => {
      return new OverDueALR(item)
    })
    return {data, count, next_page, previous_page}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getCampaignDetails() {
  try {
    const response = await GraniteAPIClient().get('/alr/campaign/')
    const campaign = new CampaignDetail(response.data)
    return campaign
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}


export default {
  getPanels,
  getdashboardList,
  getCampaignDetails,
}
