// class ScreenActivity {
//   constructor(item) {
//     this.pk = item.pk
//     this.actentity_type_nameion = item.entity_type_name
//     this.modified_date = item.modified_date
//     this.new_data = item.new_data
//     this.old_data = item.old_data
//     this.modified_by = item.user
//   }
// }

// export default ScreenActivity

import {AUDITS_FILTER} from 'granite/audits_filter'

class ScreenActivity {

  constructor(item) {
    this.pk = item.pk
    this.actentity_type_nameion =
      // Object.keys(item.old_data).length === 0 &&
      // Object.keys(item.new_data).length === 0
      //   ? 'Email Sent'
      //   :
      
      item.entity_type_name === 'applicant' ? 'Client' 
        : item.entity_type_name.charAt(0).toUpperCase() +
          item.entity_type_name.substr(1).toLowerCase()
    this.modified_date = item.modified_date
    this.modified_by = item.user
    this.final_old_data = AUDITS_FILTER(item.old_data, "old_data")
    this.final_new_data = AUDITS_FILTER(item.new_data,"new_data")
  }
}

export default ScreenActivity

