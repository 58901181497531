import React, {useState, useCallback} from 'react'
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import {Box, Text} from 'grommet'
import styled from 'styled-components'
import {getScreenACtivity} from 'clientloandetail/controllers/loan'
import Pagination from 'granite/components/Pagination'

const TableWrapper = styled(Box)`
  overflow-x: auto;
`

const handleNewData = value => {
  return Object.keys(value).map((item, index) => (
    <Box gap="small">
      <Text key={index} size="small" color="text-2">
        {
          <Text size="small" color="text-2" weight="bold">
            {item === 'New rate' ? 'Rate' : item}
          </Text>
        }
        :{value[item] ? (item === 'Rate' || item === 'Discount' || item === 'New Rate') ? value[item]+'%' : value[item] : '"",'}
      </Text>
    </Box>
  ))
}

const COLUMNS = [
  // {
  //   accessor: 'campign',
  //   Header: 'Campign',
  //   Cell: ({cell: {value}, ...rest}) => {
  //     return <TextCell {...rest} data={''} editMode={false} color="text-2" />
  //   },
  // },

  {
    accessor: `actentity_type_nameion`,
    Header: 'Action',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
    disableSortBy: true,
  },

  {
    accessor: `modified_date`,
    Header: 'Date',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
  },

  {
    accessor: `modified_by`,
    Header: 'Modified By',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
    disableSortBy: true,
  },

  {
    accessor: `final_old_data`,
    Header: 'Previous Value',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={handleNewData(value)}
          editMode={false}
          color="text-2"
        />
      )
    },
    align: 'start',
    disableSortBy: true,
  },

  {
    accessor: `final_new_data`,
    Header: 'New Value',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={handleNewData(value)}
          editMode={false}
          color="text-2"
        />
      )
    },
    align: 'start',
    disableSortBy: true,
  },
]

const OALRTable = ({
  tableData,
  event,
  route,
  navigateToParams,
  completeData,
  filterData,
}) => {
  const temp = data => {
    data.forEach(element => {
      if (element.new_data) {
        let str = Object.values(element.new_data).join(' ')
        element.new_data = str
      }
      if (element.old_data) {
        let str = Object.values(element.old_data).join(' ')
        element.old_data = str
      }
    })

    tableData = data
  }
  const [data, setData] = useState([])

  temp(tableData)

  const updateCellData = (rowId, column, value) => {
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  const handlecolumnClick = cell => {}

  const handleSort = useCallback(
    props => {
      const col = props[0].id
      const parms = {
        loan: route.params.id,
      }

      props[0].desc
        ? (parms['ordering'] = '-' + col)
        : (parms['ordering'] = col)
      getScreenACtivity(parms, event)
    },
    [event, route.params.id],
  )

  //page=1&page_size=10
  const handlePagination = val => {
    //  setLoader(true)
    let params = {}
    params['page'] = val.page
    params['page_size'] = val.size
    navigateToParams({page: val.page, page_size: val.size})
  }

  return (
    <>
      <TableWrapper>
        <ReactTable
          data={tableData}
          columns={COLUMNS}
          onSortChange={handleSort}
          updateCellData={updateCellData}
          rowClickHandler={row => {}}
          columnClickHandler={cell => handlecolumnClick(cell)}
        />
      </TableWrapper>

      <Box background="brand-light" pad="small">
        {tableData.length > 0 ? (
          <Box direction="row" width="100%" margin={{top: 'small'}}>
            <Box width="50%" alignContent="end" align="start">
              <Text color="dark-6">
                {/* Page {filterData.page} out of{' '}
                {Math.ceil(completeData.count / filterData.page_size)} */}
                {completeData.count < filterData.page_size ? completeData.count + ' out of ' + completeData.count 
                  : tableData.length + ' out of ' + completeData.count}
              </Text>
            </Box>
            <Box width="50%" alignContent="end" align="end">
              {tableData.length > 0 ? (
                <Pagination
                  onPageChange={val => {
                    async function sample() {
                      await handlePagination(val)
                    }
                    sample()
                  }}
                  showSizeChanger
                  select={true}
                  pageData={{
                    page: filterData.page,
                    size: filterData.page_size,
                    total: completeData.count,
                  }}
                />
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>
    </>
  )
}

export default OALRTable
