// Lib Imports
import React, {useRef, useState, useEffect} from 'react'
import {Box, Button, Calendar, Drop, Select, Text, Image} from 'grommet'
import {FormPreviousLink, FormNextLink, FormClose} from 'grommet-icons'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {format} from 'date-fns'

const DropContent = ({
  date: initialDate,
  onSelect,
  bounds,
  showAjacentDays,
  minYear,
  maxYear,
  ...rest
}) => {
  const [date, setDate] = useState(initialDate || new Date().toISOString())
  const yearRange = _.range(parseInt(minYear), parseInt(maxYear) + 1)
  return (
    <Box
      pad="small"
      round="xsmall"
      elevation="small"
      background="white"
      border={{color: 'black'}}
      overflow={{horizontal: 'hidden', vertical: 'auto'}}
    >
      <Calendar
        animate={false}
        date={date}
        onSelect={onSelect}
        bounds={bounds}
        daysOfWeek
        showAdjacentDays={showAjacentDays}
        header={({
          date: currentDate,
          locale,
          onPreviousMonth,
          onNextMonth,
          previousInBound,
          nextInBound,
        }) => (
          <Box direction="row" align="center" justify="between">
            <Button
              disabled={
                !previousInBound ||
                (currentDate.getMonth() === 0 &&
                  currentDate.getFullYear() === parseInt(minYear))
              }
              onClick={onPreviousMonth}
            >
              <Box>
                <FormPreviousLink color="brand" />
              </Box>
            </Button>
            <Box direction="row" align="center">
              <Box width="80px">
                <Select
                  size="small"
                  plain
                  dropHeight="medium"
                  value={currentDate.toLocaleDateString(locale, {
                    month: 'short',
                  })}
                  options={[
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                  ]}
                  onChange={({selected}) =>
                    setDate(currentDate.setMonth(selected, 1))
                  }
                />
              </Box>
              <Box width="90px">
                <Select
                  size="small"
                  plain
                  dropHeight="medium"
                  value={parseInt(
                    currentDate.toLocaleDateString(locale, {
                      year: 'numeric',
                    }),
                  )}
                  options={yearRange}
                  onChange={({option}) =>
                    setDate(currentDate.setFullYear(option))
                  }
                />
              </Box>
            </Box>
            <Button
              disabled={
                !nextInBound ||
                (currentDate.getMonth() === 11 &&
                  currentDate.getFullYear() === parseInt(maxYear))
              }
              onClick={onNextMonth}
            >
              <Box>
                <FormNextLink color="brand" />
              </Box>
            </Button>
          </Box>
        )}
        {...rest}
      />
    </Box>
  )
}

const Datepicker = ({
  initialDate,
  onClose: userOnClose,
  textSize,
  imageHeight,
  boxBackground,
  contentGap,
  textColor,
  ...rest
}) => {
  const defaultIsVisible = false
  const [isVisible, setIsVisible] = useState(defaultIsVisible)
  const [date, setDate] = useState(initialDate || undefined)
  const childRef = useRef()

  useEffect(() => {
    setDate(initialDate)
  }, [initialDate])

  const onSelect = date => {
    setDate(date)
    userOnClose(date)
    setIsVisible(false)
  }

  const handleClear = evt => {
    evt.stopPropagation()
    setDate(null)
    userOnClose(null)
  }

  return (
    <>
      <span
        style={{display: 'inline-block'}}
        onClick={() => setIsVisible(true)}
        ref={childRef}
      >
        <Box
          direction="row"
          // border =  {{color:'red', size:'small'}}
          background={boxBackground ? boxBackground : 'white'}
          //border
          round="xsmall"
          justify="between"
          align="center"
          pad="xsmall"
          gap={contentGap ? contentGap : ''}
        >
          <Text color={date ? 'dark-6' : undefined} size={textSize}>
            {date ? format(new Date(date), 'dd-LLL-yyyy') : `Select date`}
          </Text>
          {date && <FormClose size="20px" onClick={handleClear} />}
         { !date && <Image
            height={imageHeight ? imageHeight : 'xsmall'}
            src={process.env.PUBLIC_URL + '/Images/datesmall.png'}
          />}
        </Box>
      </span>
      {childRef.current && isVisible && (
        <Drop
          stretch={false}
          // align={{top: 'bottom'}}
          align={{top: 'bottom', left: 'left'}}
          margin={{left: 'small'}}
          target={childRef.current}
          overflow="visible"
          onClickOutside={() => setIsVisible(false)}
          plain
        >
          <DropContent date={date} onSelect={onSelect} {...rest} />
        </Drop>
      )}
    </>
  )
}

Datepicker.defaultProps = {
  initialDate: undefined,
  onClose: () => {},
  showAjacentDays: true,
  bounds: undefined,
  minYear: new Date().getFullYear() - 100,
  maxYear: new Date().getFullYear() + 100,
}

Datepicker.propTypes = {
  initialDate: PropTypes.string,
  onClose: PropTypes.func,
  showAjacentDays: PropTypes.bool,
  bounds: PropTypes.array,
  yearRange: PropTypes.array,
  minYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxYear: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}
export default Datepicker
