// import _ from 'lodash/fp'
// import GraniteError from 'utils/granite-error'

class SideMenu {
  constructor(item) {
    this.name  = item.name
    this.sequence = item.sequence
    this.parent_id = item.parent_id
    this.label = item.label
    this.icon = item.icon
    this.permissions = item.permissions
    this.pk = item.pk
  }
}

export default SideMenu
