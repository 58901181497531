// Lib imports
import React, {useState, useEffect} from 'react'
import {Box} from 'grommet'
import {connect} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import queryString from 'query-string'

//Application imports
import EventEmitter from 'utils/event-emitter'
import Toast from 'granite/components/Toast'
import Header from 'granite/components/HeaderWithDropDown'
import TabComponent from 'granite/components/TabComponent'
import BackNavigation from 'granite/components/BackNavigation'
// import CampaignForm from 'pages/campaign//components/Campaignform'
//Core imports
import * as UserDucks from 'accounts/ducks/user'
import {
  fetchNewData,
  campaigneditapi,
  campaignListcommon,
} from 'alr/controllers/loanReview'
// import {campaignList, getLoanReviewSavings} from 'alr/controllers/loanReview'
import CAMPAIGN_EVENTS from 'pages/campaign/constants'
import CONTACTING_EVENTS from 'pages/contacting/constants'
import NEW_EVENTS from 'pages/campaign/constants'
import CAMPAIGN_MESSAGES from 'messages/campaign'
import CampaignInfo from 'pages/campaign/components/CampaignInfo'
import NewLoanTable from './components/NewLoanTable'
import MoreDetails from 'pages/contacting/components/MoreDetails'
import MailTemplate from 'pages/campaign/components/mailmodal'
import RiskRate from 'pages/alr/components/RiskRate'

//import useQuery from 'hooks/useQuery'

// const ButtonStyle = styled(Button)`
//   height: 36px;
//   width: 110px;
// `
let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setCampaignDetails,
  setToastData,
  setData,
  setLoader,
  setMoreData,
  setMoreLoader,
  setNoLoan,
  setSavings,
  setmaillist,
  settemplatename,
  settemplatecontent,
  // setcampaignId,
  setsyncvisible,
  history,
  //  queryParam
  filterData,
  setselectcampaignlist,
  setMoreDataEdit,
  setTabs,
  setRisk,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case CAMPAIGN_EVENTS.GET_RISK_SUCCESS:
        setRisk(event.data)
        break

      case CAMPAIGN_EVENTS.GET_RISK_FAILURE:
        break

      case CAMPAIGN_EVENTS.GET_CAMPAIGN_SUCCESS:
        setsyncvisible(false)
        setCampaignDetails(event.data)
        break
      case CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE:
        setsyncvisible(true)
        setLoader(false)
        break
      case CAMPAIGN_EVENTS.SAVE_CAMPAIGN_SUCCESS:
        setToastData({
          open: true,
          message: CAMPAIGN_MESSAGES.SAVE_CAMPAIGN_SUCCESS,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case CAMPAIGN_EVENTS.SAVE_CAMPAIGN_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CAMPAIGN_MESSAGES.SAVE_CAMPAIGN_FAILURE,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case CONTACTING_EVENTS.GET_LOAN_DETAILS_SUCCESS:
        setTabs({
          new: event.data.tabs_count.new,
          contacting: event.data.tabs_count.contacting,
          Negotiate_Pricing: event.data.tabs_count.Negotiate_Pricing,
          completed: event.data.tabs_count.completed,
          Contact_Client_Of_Result: event.data.tabs_count.Contact_Client_Of_Result,
        })
        setData({data: event.data.loanData, total: event.data.rowsCount})
        if (event.data.loanData.length === 0) setNoLoan(true)
        else setNoLoan(false)
        setLoader(false)
        break
      case CONTACTING_EVENTS.GET_LOAN_DETAILS_FAILURE:
        break

      case CONTACTING_EVENTS.GET_MORE_DETAILS_SUCCESS:
        setMoreData(event.data.moreDetails)
        setMoreDataEdit(event.data)
        setMoreLoader(false)
        break
      case CONTACTING_EVENTS.GET_MORE_DETAILS_FAILURE:
        break

      case CONTACTING_EVENTS.MESSAGE_ARRIVED:
        //console.log('eventEmitter')
        setToastData({
          open: true,
          message: `${event.data}`,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case CAMPAIGN_EVENTS.SHOW_LOADER:
        setLoader(true)
        break
      case CAMPAIGN_EVENTS.HIDE_LOADER:
        setLoader(false)
        break

      case CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS:
        const obj = {
          // params: {page: 1, page_size: 20},
          params: filterData
        }
        let state = event.data === 'Contacting' ? 'Accepted' : event.data 
        setToastData({
          open: true,
          message: `Loan Marked ${state} Successfully`,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 1000)

        fetchNewData(eventEmitter, obj, filterData.campaign_id)
        break
      case CONTACTING_EVENTS.STATUS_CHANGED_FAILURE:
        setToastData({
          open: true,
          message: CAMPAIGN_MESSAGES.STATUS_SAVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case CONTACTING_EVENTS.GET_SAVINGS_SUCCESS:
        setSavings(event.data)
        break
      case CONTACTING_EVENTS.GET_SAVINGS_FAILURE:
        break

      case NEW_EVENTS.GET_MAIL_TEMPLATE_LIST_SUCCESS:
        const temparr = []
        const templatenamearr = {}
        const tempcontentarr = {}
        if (event.data.length > 0) {
          event.data.forEach(item => {
            temparr.push(item.email_template.name)
            templatenamearr[item.email_template.name] = item.activity_name
          })
        }
        if (event.data.length > 0) {
          event.data.forEach(item => {
            tempcontentarr[item.email_template.name] =
              item.email_template.content
          })
        }
        settemplatecontent(tempcontentarr)
        setmaillist(temparr)
        settemplatename(templatenamearr)
        break
      case NEW_EVENTS.GET_MAIL_TEMPLATE_LIST_FAILURE:
        break
      case NEW_EVENTS.SEND_MAIL_SUCCESS:
        setToastData({
          open: true,
          message: CAMPAIGN_MESSAGES.SEND_MAIL_SUCCESS,
          background: '',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case NEW_EVENTS.SEND_MAIL_FAILURE:
        setToastData({
          open: true,
          message: CAMPAIGN_MESSAGES.SEND_MAIL_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: true,
            message: event.data
              ? event.data
              : CAMPAIGN_MESSAGES.SEND_MAIL_FAILURE,
            background: 'warning',
          })
        }, 5000)
        break
      case CONTACTING_EVENTS.SEND_MAIL_FAILURE:
        setsyncvisible(false)
        break
      case CONTACTING_EVENTS.SAVE_NEW_CAMPAIGN_FAILURE:
        break
      //Campaign List

      //    GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
      // GET_CAMPAIGN_LIST_FAILURE: 'GET_CAMPAIGN_LIST_FAILURE',
      case CAMPAIGN_EVENTS.GET_CAMPAIGN_LIST_SUCCESS:
        // console.log('Coming dtaa', event.data.temp)
        setselectcampaignlist(event.data)
        break
      case CAMPAIGN_EVENTS.GET_CAMPAIGN_LIST_FAILURE:
        break

      case NEW_EVENTS.NEW_LOANS_ACCEPTED_REJECTED_SUCCESS:
        const objnew = {
          // params: {page: 1, page_size: 20},
          params: filterData
        }
        setToastData({
          open: true,
          message: `Status updated successfully`,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 1000)

        fetchNewData(eventEmitter, objnew, filterData.campaign_id)
        break
      case NEW_EVENTS.NEW_LOANS_ACCEPTED_REJECTED_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CAMPAIGN_MESSAGES.NEW_LOANS_ACCEPTED_REJECTED_FAILURE,
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      default:
        break
    }
  })
  return subscription
}

const Campaign = ({userProfile, permissions}) => {
  const [tabs, setTabs] = useState({
    new: 0,
    contacting: 0,
    Negotiate_Pricing: 0,
    completed: 0,
    Contact_Client_Of_Result: 0,
  })
  const [campaignDetails, setCampaignDetails] = useState()
  const [risk, setRisk] = useState({})
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [data, setData] = useState({data: [], total: ''})
  const [loader, setLoader] = useState(false)
  const history = useHistory()
  const [moreDetailLayer, setMoreDetailLayer] = useState(false)
  const [moreData, setMoreData] = useState([])
  const [moreLoader, setMoreLoader] = useState(false)
  const [noLoan, setNoLoan] = useState(false)
  const [savings, setSavings] = useState()
  const [showmailmodal, setshowmailmodal] = useState(false)
  const [moreDataEdit, setMoreDataEdit] = useState()
  const [maillist, setmaillist] = useState([
    'Notify to Review ALR',
    'No Action Required ALR',
    'Rate Reduction ALR Email',
    'Interest / Fixed Expiry Template',
    'Missed Call Template',
    'Actions to be completed ALR Email',
    'Recently Dealt with Indigo ALR Email',
  ])
  const [templatename, settemplatename] = useState()
  const [applicantids, setapplicantids] = useState([])
  const [applicantsemail, setapplicantsemail] = useState()
  const [selectedapplicants, setselectedapplicants] = useState([])
  const [tempcontent, settemplatecontent] = useState('')
  const [syncvisible, setsyncvisible] = useState(false)
  const [clientfirdtname, setclientfirdtname] = useState('')
  let defaultFilterData = {
    page: 1,
    page_size: 20,
    select_page: 1,
    select_page_size: 20,
    status: null,
    sorting: 'lender'
  }

  let location = useLocation()
  const {search} = location
  let queryParam = queryString.parse(search)
  const [selcetdcampaign, setselcetdcampaign] = useState(
    queryParam.month_year ? queryParam.month_year : '',
  )

  const [selectcampaignlist, setselectcampaignlist] = useState({
    campaignData: '',
    temp: [],
  })

  const [statusValue, setStatusValue] = useState(
    queryParam.status ? queryParam.status : '',
  )
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })

  const [filterData, setFilterData] = useState(defaultFilterData)

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    const message = queryParam['msg']
    if (message) {
    }
  }, [queryParam])

  const handleRefresh = () => {
    setsyncvisible(false)
    setLoader(true)
    setStatusValue('')
    setSearchValue('')
    navigateToParams({page: 1, page_size: 20, search: null, status: null, sorting: 'lender'})
  }

  const handleCampaignForm = () => {
    campaigneditapi(eventEmitter)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        history,
        eventEmitter,
        setCampaignDetails,
        setToastData,
        setData,
        setLoader,
        setMoreData,
        setMoreLoader,
        setNoLoan,
        setSavings,
        setmaillist,
        settemplatename,
        setsyncvisible,
        settemplatecontent,
        filterData,
        setselectcampaignlist,
        setMoreDataEdit,
        setTabs,
        setRisk,
        // queryParam,
      })
      const obj = {
        params: filterData,
      }
      fetchNewData(eventEmitter, obj, filterData.campaign_id)
      let select_params = {
        page: filterData.select_page,
        page_size: filterData.select_page_size,
      }
      campaignListcommon(eventEmitter, 'New', select_params)

      return subscription
    }
    let temp = init()
    return () => {
      temp.unsubscribe()
    }
  }, [location, filterData, history])

  return (
    <Box flex={{grow: 1}}>
      <Header
        pageTitle="Loan Review / Gather Loan Details"
        userName={`${userProfile.title} ${userProfile.name}`}
        showSavings={true}
        handleRefresh={handleRefresh}
        alrSavings={savings ? savings.alrSavings : ''}
        totalSavings={savings ? savings.totalSavings : ''}
      />
      <Box
        overflow={{vertical: 'auto'}}
        fill
        pad={{left: 'medium', right: 'medium', bottom: 'medium'}}
      >
        <BackNavigation label={'Back'} />

        <Box direction="row-responsive" width="100%" gap="small">
          <Box width="75%">
            <CampaignInfo
              campaignDetails={campaignDetails}
              selcetdcampaign={selcetdcampaign}
              setselcetdcampaign={setselcetdcampaign}
              selectcampaignlist={selectcampaignlist}
              history={history}
              navigateToParams={navigateToParams}
              filterData={filterData}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
          <Box width="25%" background="light-3">
            <RiskRate risk={risk} />
          </Box>
        </Box>
        
        <TabComponent stats={tabs} history={history} />
        {showmailmodal && (
          <MailTemplate
            setshowmailmodal={setshowmailmodal}
            showmailmodal={showmailmodal}
            event={eventEmitter}
            loggedinuser={userProfile.email}
            client={applicantsemail}
            clientfirdtname={clientfirdtname}
            sendername={userProfile.name}
            designation={userProfile.designation}
            maillist={maillist}
            templatename={templatename}
            tempcontent={tempcontent}
            applicantids={applicantids}
            selectedapplicants={selectedapplicants}
          ></MailTemplate>
        )}
        <Box
          direction="row"
          gap="medium"
          pad={{bottom: 'small', left: 'none', right: 'none', top: 'none'}}
        ></Box>

        {/* false && syncvisible && (
          <CampaignForm
            campaignDetails={campaignDetails}
            onSubmit={campaignedit}
          />
        ) */}
        <Box>
          <NewLoanTable
            data={data}
            setData={setData}
            eventEmitter={eventEmitter}
            loader={loader}
            setMoreDetailLayer={setMoreDetailLayer}
            setMoreLoader={setMoreLoader}
            navigateToParams={navigateToParams}
            filterData={filterData}
            campaignDetails={campaignDetails}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            statusValue={statusValue}
            setStatusValue={setStatusValue}
            noLoan={noLoan}
            setshowmailmodal={setshowmailmodal}
            showmailmodal={showmailmodal}
            setapplicantids={setapplicantids}
            setapplicantsemail={setapplicantsemail}
            syncvisible={syncvisible}
            handleCampaignForm={handleCampaignForm}
            setclientfirdtname={setclientfirdtname}
            permissions={permissions}
            setToastData={setToastData}
            setselectedapplicants={setselectedapplicants}
            // campaignId={campaignId}
          />
        </Box>
        {moreDetailLayer && (
          <MoreDetails
            setMoreDetailLayer={setMoreDetailLayer}
            moreData={moreData}
            moreLoader={moreLoader}
            moreDataEdit={moreDataEdit}
          />
        )}
      </Box>
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})

export default connect(mapStateToProps)(Campaign)
