class CampaignList {
  constructor({created_by, date, month, name, pk}) {
    this.created_by_pk = created_by.pk
    this.created_by_name = created_by.name
    this.date = date
    this.month = month
    this.name = name
    this.pk = pk
  }
}

export default CampaignList
