//Library imports
import React, {useState, useEffect} from 'react'
import {Box, Image, Text, Button, Layer} from 'grommet'
import styled from 'styled-components'
import {connect} from 'react-redux'
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom'
import queryString from 'query-string'
//Core imports
import EventEmitter from 'utils/event-emitter'
import Toast from 'granite/components/Toast'
import BackNavigation from 'granite/components/BackNavigation'
import Header from 'granite/components/HeaderWithDropDown'
//Application imports
import * as UserDucks from 'accounts/ducks/user'
import {
  loadClientInfo,
  getClientSavings,
  getMailTemplateListRa,
  OutcomeInnerLoanList,
  sendSmsOutcome,
} from 'alr/controllers/loanReview'
import REVIEW_APPLICANT_MESSAGES from 'messages/reviewApplicant'
import ClientInfo from './components/ClientInfo'
import {loadCampaignDetails} from 'alr/controllers/campaign'
import REVIEW_APPLICANT_EVENTS from './constants'
import Notes from 'pages/clientdetails/components/notes'
import CLIENT_DETAIL_EVENTS from 'pages/clientdetails/constants'
import CLIENT_DETAIL_EVENTS_MESSAGES from 'messages/clientDetails'
import {getClientDetailsNotes} from 'clientDetails/controllers/client'
import ApplicantLoanTable from 'pages/reviewApplicant/components/ApplicantLoanTable'
import MailTemplate from 'pages/reviewApplicant/components/mailmodal'
import SMSForm from 'pages/reviewApplicant/components/smsform'
const MessageWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MessageTextWrapper = styled(Text)`
  font-weight: 500;
`
const ButtonStyle = styled(Button)`
  width: 110px;
  height: 36px;
`

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setData,
  setCampaignDetails,
  setLoader,
  setNotesData,
  setLayer,
  setClientInformation,
  match,
  setSavings,
  setmaillist,
  settemplatename,
  settemplatecontent,
  filterData,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case REVIEW_APPLICANT_EVENTS.GET_CAMPAIGN_SUCCESS:
        setCampaignDetails(event.data)
        break
      case REVIEW_APPLICANT_EVENTS.GET_CAMPAIGN_FAILURE:
        break
      case REVIEW_APPLICANT_EVENTS.GET_CLIENT_LOAN_DETAILS_SUCCESS:
        setData({data: event.data.clientLoanData, total: event.data.rowsCount})
        setLoader(false)
        break

      case REVIEW_APPLICANT_EVENTS.GET_CLIENT_LOAN_DETAILS_FAILURE:
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_SUCCESS:
        if (event.data) setNotesData(event.data.data)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_FAILURE:
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_SUCCESS:
        getClientDetailsNotes(eventEmitter, match.params.clientid)
        setLayer(true)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_ADD_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_FAILURE:
        setLayer(false)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_ADD_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_SUCCESS:
        getClientDetailsNotes(eventEmitter, match.params.clientid)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case REVIEW_APPLICANT_EVENTS.CLIENT_DETAIL_SUCCESS:
        setClientInformation(event.data)
        break
      case REVIEW_APPLICANT_EVENTS.CLIENT_DETAIL_FAILURE:
        break
      case REVIEW_APPLICANT_EVENTS.SHOW_LOADER:
        setLoader(true)
        break
      case REVIEW_APPLICANT_EVENTS.HIDE_LOADER:
        setLoader(false)
        break
      case REVIEW_APPLICANT_EVENTS.STATUS_CHANGED_SUCCESS:
        setToastData({
          open: true,
          message: `Loan Marked ${event.data} Successfully`,
        })
        const objSample = {
          params: filterData,
        }
        OutcomeInnerLoanList(
          eventEmitter,
          match.params.clientid,
          objSample,
          'InReview',
        )
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case REVIEW_APPLICANT_EVENTS.STATUS_CHANGED_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : REVIEW_APPLICANT_MESSAGES.STATUS_SAVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break

      case REVIEW_APPLICANT_EVENTS.GET_SAVINGS_SUCCESS:
        setSavings(event.data)
        break
      case REVIEW_APPLICANT_EVENTS.GET_SAVINGS_FAILURE:
        break
      // GETMAIL
      case REVIEW_APPLICANT_EVENTS.GET_MAIL_LIST_SUCCESS:
        const temparr = []
        const templatenamearr = {}
        const tempcontentarr = {}
        if (event.data.length > 0) {
          event.data.forEach(item => {
            temparr.push(item.email_template.name)
            templatenamearr[item.email_template.name] = item.activity_name
          })
        }
        if (event.data.length > 0) {
          event.data.forEach(item => {
            tempcontentarr[item.email_template.name] =
              item.email_template.content
          })
        }
        setmaillist(temparr)
        settemplatename(templatenamearr)
        settemplatecontent(tempcontentarr)
        break
      case REVIEW_APPLICANT_EVENTS.GET_MAIL_LIST_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : REVIEW_APPLICANT_MESSAGES.STATUS_SAVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      // SEND MAIL
      case REVIEW_APPLICANT_EVENTS.SEND_MAIL_SUCCESS:
        setToastData({
          open: true,
          message: REVIEW_APPLICANT_MESSAGES.SEND_MAIL_SUCCESS,
          background: '',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 1000)

        break
      case REVIEW_APPLICANT_EVENTS.SEND_MAIL_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : REVIEW_APPLICANT_MESSAGES.SEND_MAIL_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      // SEND SMS
      case REVIEW_APPLICANT_EVENTS.SEND_SMS_SUCCESS:
        setToastData({
          open: true,
          message: REVIEW_APPLICANT_MESSAGES.SEND_SMS_SUCCESS,
          background: '',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 1000)
        break
      case REVIEW_APPLICANT_EVENTS.SEND_SMS_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : REVIEW_APPLICANT_MESSAGES.SEND_SMS_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      default:
        break
    }
  })
  return subscription
}

const ReviewApplicant = ({userProfile, permissions}) => {
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [messageLayer, setMessageLayer] = useState(false)
  const history = useHistory()
  const [data, setData] = useState({data: [], total: ''})
  const [campaignDetails, setCampaignDetails] = useState()
  const [loader, setLoader] = useState(false)
  const [layer, setLayer] = useState(false)
  const [notesData, setNotesData] = useState([])
  const [clientInformation, setClientInformation] = useState()
  const [savings, setSavings] = useState()
  const [showmailmodal, setshowmailmodal] = useState(false)
  const [customMonth, setCustomMonth] = useState(null)
  const [maillist, setmaillist] = useState([
    'Notify to Review ALR',
    'No Action Required ALR',
    'Rate Reduction ALR Email',
    'Interest / Fixed Expiry Template',
    'Missed Call Template',
    'Actions to be completed ALR Email',
    'Recently Dealt with Indigo ALR Email',
  ])
  const [templatename, settemplatename] = useState()
  const [tempcontent, settemplatecontent] = useState('')
  const [selected, setSelected] = useState([])

  let defaultFilterData = {
    page: 1,
    page_size: 20,
  }

  let location = useLocation()

  const {search} = location
  let queryParam = queryString.parse(search)

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)
  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
      state: location.state,
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  const handleRefresh = () => {
    setLoader(true)
    navigateToParams({page: 1, page_size: 20})
  }
  const match = useRouteMatch()
  const handlemailclicked = () => {
    if (selected.length === 1) {
      setshowmailmodal(true)
    } else if (selected.length === 0) {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    } else {
      setToastData({
        open: true,
        message: 'Only one client can be selected',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }

  const handleSendSms = async (values, {setSubmitting, setErrors}) => {
    if (selected.length === 1) {
      setErrors({})
      setSubmitting(true)
      await sendSmsOutcome(
        eventEmitter,
        values,
        selected[0].applicant_pk,
        selected[0].applicant_phone,
      )
      setSubmitting(false)
      setMessageLayer(!messageLayer)
    }
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setData,
        setCampaignDetails,
        setLoader,
        setNotesData,
        setLayer,
        setClientInformation,
        match,
        setSavings,
        setmaillist,
        settemplatename,
        settemplatecontent,
        filterData,
      })
      const obj = {
        params: filterData,
      }
      let comingCampaignId = obj.params.campaign_id
        ? obj.params.campaign_id
        : null
      setCustomMonth(obj.params.month_year ? obj.params.month_year : null)
      loadCampaignDetails(eventEmitter)
      OutcomeInnerLoanList(
        eventEmitter,
        match.params.clientid,
        obj,
        'InReview',
        comingCampaignId,
      )

      getClientDetailsNotes(eventEmitter, match.params.clientid)
      loadClientInfo(eventEmitter, match.params.clientid)
      getClientSavings(eventEmitter, match.params.clientid)
      getMailTemplateListRa(eventEmitter, 'OUTCOME', match.params.clientid)

      return subscription
    }
    let temp = init()
    return () => {
      temp.unsubscribe()
    }
  }, [location, match, filterData])

  return (
    <Box flex={{grow: 1}}>
      <Header
        pageTitle={
          clientInformation
            ? `Loan Review / Outcome / ${clientInformation.user_name}`
            : 'Loan Review / Outcome'
        }
        userName={`${userProfile.title} ${userProfile.name}`}
        showTotalSavings={true}
        showAlrSavings={true}
        handleRefresh={handleRefresh}
        alrSavings={savings ? savings.alrSavings : ''}
        totalSavings={savings ? savings.totalSavings : ''}
      />
      <Box
        overflow={{vertical: 'auto'}}
        fill
        pad={{bottom: 'medium', right: 'medium', left: 'medium'}}
      >
        <Box margin={{bottom: 'small'}}>
          {clientInformation && (
            <BackNavigation label={clientInformation.user_name} />
          )}
        </Box>
        <ClientInfo
          clientInformation={clientInformation}
          campaignDetails={campaignDetails}
          customMonth={customMonth}
        />
        <Box
          gap="large"
          direction="row"
          justify="end"
          width="100%"
          margin={{top: 'medium'}}
        >
          <Box gap="medium" direction="row">
            <ButtonStyle
              primary
              label="Notes"
              onClick={() => {
                setLayer(true)
              }}
            />
            <ButtonStyle
              primary
              label="Message"
              onClick={() => {
                if (selected.length === 1) {
                  setMessageLayer(!messageLayer)
                } else if (selected.length === 0) {
                  setToastData({
                    open: true,
                    message: 'Please select client',
                    background: 'warning',
                  })
                  setTimeout(() => {
                    setToastData({
                      open: false,
                      message: '',
                    })
                  }, 2000)
                } else {
                  setToastData({
                    open: true,
                    message: 'Only one client can be selected',
                    background: 'warning',
                  })
                  setTimeout(() => {
                    setToastData({
                      open: false,
                      message: '',
                    })
                  }, 2000)
                }
              }}
              style={{paddingRight: '1rem', paddingLeft: '1rem'}}
            />
            <ButtonStyle primary label="Mail" onClick={handlemailclicked} />
          </Box>
        </Box>
        {layer && (
          <Notes
            layer={layer}
            setLayer={setLayer}
            notesData={notesData}
            event={eventEmitter}
            route={match}
            permissions={permissions}
          />
        )}
        {messageLayer && (
          <Layer
            onEsc={() => setMessageLayer(!messageLayer)}
            onClickOutside={() => setMessageLayer(!messageLayer)}
          >
            <MessageWrapper direction="row" justify="between">
              <MessageTextWrapper
                color="dark-1"
                size="16px"
                margin={{left: '20px', top: '15px', bottom: '15px'}}
              >
                Send Message
              </MessageTextWrapper>
              <Button
                margin={{right: 'small', bottom: 'small'}}
                icon={<Image src={process.env.PUBLIC_URL + '/img/Cross.png'} />}
                onClick={() => setMessageLayer(!messageLayer)}
              />
            </MessageWrapper>
            <Box
              margin={{
                top: 'small',
                left: 'small',
                right: 'small',
                bottom: 'small',
              }}
              width="351px"
            >
              {false && (
                <Text textAlign="left" size="14px" color="dark-1">
                  Lorem ipsum dolor sit amet, consectetur adipiscing Aliquam
                  tincidunt diam ex, ac rhoncus lorem rutrum non.Proin a erat ut
                  magnalacinia aliquet sit amet ac est.
                </Text>
              )}
              <SMSForm
                onSubmit={handleSendSms}
                setMessageLayer={setMessageLayer}
                messageLayer={messageLayer}
              ></SMSForm>
            </Box>
          </Layer>
        )}
        <Box margin={{top: 'medium'}}>
          <ApplicantLoanTable
            data={data}
            eventEmitter={eventEmitter}
            loader={loader}
            setData={setData}
            navigateToParams={navigateToParams}
            filterData={filterData}
            setToastData={setToastData}
            setSelected={setSelected}
            selected={selected}
          />
        </Box>
        {showmailmodal && (
          <MailTemplate
            setshowmailmodal={setshowmailmodal}
            showmailmodal={showmailmodal}
            event={eventEmitter}
            route={match}
            loggedinuser={userProfile.email}
            client={clientInformation.user_email}
            clientfirdtname={clientInformation.first_name}
            sendername={userProfile.name}
            designation={userProfile.designation}
            maillist={maillist}
            templatename={templatename}
            tempcontent={tempcontent}
            selected={selected}
          ></MailTemplate>
        )}
      </Box>
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})

export default connect(mapStateToProps)(ReviewApplicant)
