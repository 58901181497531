//Library imports
import React, {useState, useRef} from 'react'
import {Box, Button, TextInput} from 'grommet'
import styled from 'styled-components'
import {fileImport} from 'clientlist/controllers/client'

const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`
const HiddenFileInp = styled.input`
  display: none;
`

const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  padding-left: 14px !important;
`

const CUSTOMUPLOAD = ({eventEmitter, setloader, setToastData}) => {
  const [file, setFile] = useState()
  const inputRef = useRef()
  const openFileDialog = () => inputRef.current.click()

  const initUpload = () => {
    if (file.name.includes('.csv')) {
      setloader(true)
      const bodyFormData = new FormData()
      bodyFormData.append('file', file)
      fileImport(eventEmitter, bodyFormData)
    } else {
      setToastData({
        open: true,
        message: 'Please enter file in csv format',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    }
  }

  const handleFilesChange = e => {
    setFile(e.target.files[0])
  }

  return (
    <Box width="100%">
      <HiddenFileInp
        accept=".csv"
        onChange={handleFilesChange}
        ref={inputRef}
        type="file"
      />
      <Box direction="row" gap="medium">
        <SearchBoxWrapper
          width="100%"
          direction="row"
          height="36px"
          background="light-1"
        >
          <TextInputWrapper
            placeholder="Choose file..."
            value={file ? file.name : ''}
          />
          <ButtonStyle label="Browse" onClick={openFileDialog} primary />
        </SearchBoxWrapper>
        <ButtonStyle
          label="Import"
          onClick={initUpload}
          primary
          disabled={!file}
        />
      </Box>
    </Box>
  )
}

export default CUSTOMUPLOAD
