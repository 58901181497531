import React from 'react'
import {Route} from 'react-router-dom'

import {getAuthToken} from 'utils/auth-singleton'
import LinearLayout from 'granite/components/LinearLayout'

const PublicRoute = ({component: Component, ...rest}) => {
  const authToken = getAuthToken()
  if (authToken) {
    return (
      <LinearLayout>
        <Component />
      </LinearLayout>
    )
  }
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <LinearLayout>
            <Component {...props} />
          </LinearLayout>
        )
      }}
    />
  )
}

export default PublicRoute
