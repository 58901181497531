function IntToCurrency(currencyasint) {
  var format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  return format.format(currencyasint)
}



function CurrencyToInt(currency) {
  if (currency && typeof currency === 'string') {
    // eslint-disable-next-line
    var number = Number(currency.replace(/[^0-9\.]+/g, ''))
    return number
  }
  return currency
}



async function New_Date_Convertor(input_date) {
  var monthObj = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Oct',
    '11': 'Nov',
    '12': 'Dec',
  }
  if (input_date !== null && input_date !== '') {
    let temp_date = input_date.split('-')
    for (var key in monthObj) {
      if (monthObj[key] === temp_date[1]) {
        temp_date[1] = key
      }
    }
    if (temp_date[0].length >= 4) {
      return temp_date.join('-')
    } else {
      return temp_date.reverse().join('-')
    }
  } else if (input_date === null) {
    return null
  } else if (input_date === '') {
    return null
  }
  return null
}

async function client_type_mapping(value) {
  const type = {
    "Individual": 'IN',
    "Company": 'CO',
    "INDIVIDUAL": 'IN',
    "COMPANY": 'CO',
    "IN": 'Individual',
    "CO": 'Company',
    
  }
  return type[value]
}
export {CurrencyToInt, IntToCurrency, New_Date_Convertor, client_type_mapping}
