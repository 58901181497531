const CAMPAIGN_MESSAGES = {
  GET_CAMPAIGN_SUCCESS: 'Successfully fetched user!',

  GET_CAMPAIGN_FAILURE: 'Please retry,something went wrong!',

  SAVE_CAMPAIGN_SUCCESS: 'Campaign saved successfully!',

  SAVE_CAMPAIGN_FAILURE:
    "We are sorry something went wrong, or you don't have permission to perform this action",

  GET_LOAN_DETAILS_SUCCESS: 'Success!',

  GET_LOAN_DETAILS_FAILURE: 'Please refresh the page!!',

  STATUS_SAVE_FAILURE:
    "We're sorry, something went wrong. Please refresh the page!",

  LEVEL_ZERO_REACHED: 'You have reached level zero',

  SEND_MAIL_SUCCESS: 'Email sent successfully!',

  SEND_MAIL_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",

  NEW_LOANS_ACCEPTED_REJECTED_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",
}

export default CAMPAIGN_MESSAGES
