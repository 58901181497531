import React, {useState} from 'react'
import {Box, Text, Image} from 'grommet'
import styled from 'styled-components'

import ScreenActivityTable from './screenActivityTable'
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-top: 2px solid #37236a;
  top: 665px;
  left: 237px;
  // width: 1101px;
  height: 52px;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
`

const ScreenActivity = ({
  screenActivityData,
  event,
  route,
  navigateToParams,
  filterData,
}) => {
  const [visible, setVisible] = useState(false)

  const handleVisibiity = () => {
    visible === true ? setVisible(false) : setVisible(true)
  }

  return (
    <Box elevation="medium" background="brand-light1">
      <BoxWrapper
        width="100%"
        direction="row"
        background="light-1"
        onClick={() => {
          handleVisibiity()
        }}
      >
        <Box width="100%">
          <Box
            width="100%"
            pad={{
              top: 'small',
              bottom: 'small',
              left: 'small',
              right: 'small',
            }}
            margin={{
              top: 'small',
              bottom: 'small',
              left: 'small',
              right: 'small',
            }}
            gap="medium"
            direction="row"
          >
            <Box width="95%">
              <Text weight="bold" color="dark">
                History
              </Text>
            </Box>

            <Box
              pad={{
                top: 'small',
                bottom: 'none',
                left: 'none',
                right: 'none',
              }}
              width="5%"
            >
              {!visible && (
                <Box
                  width="100%"
                  align="center"
                  onClick={() => {
                    handleVisibiity()
                  }}
                >
                  <Image
                    fit="contain"
                    height="12px"
                    src={process.env.PUBLIC_URL + '/img/new_down.png'}
                    style={{cursor: 'pointer'}}
                  />
                </Box>
              )}
              {visible && (
                <Box
                  width="100%"
                  align="center"
                  onClick={() => {
                    handleVisibiity()
                  }}
                >
                  <Image
                    fit="contain"
                    src={process.env.PUBLIC_URL + '/img/new_up.png'}
                    style={{cursor: 'pointer'}}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </BoxWrapper>

      {visible ? (
        screenActivityData ? (
          <ScreenActivityTable
            tableData={
              screenActivityData.tableData ? screenActivityData.tableData : []
            }
            event={event}
            route={route}
            navigateToParams={navigateToParams}
            completeData={screenActivityData}
            filterData={filterData}
          />
        ) : (
          <Box alignContent="center" pad="xlarge" alignSelf="center">
            <Text>{''}</Text>
          </Box>
        )
      ) : (
        <Box></Box>
      )}
    </Box>
  )
}
export default ScreenActivity
