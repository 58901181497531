import React from 'react'
import {Box, Text} from 'grommet'
import Table from './table'

const RepoertTable = ({
  navigateToParams,
  tableData,
  eventEmitter,
  campaignData,
  filterData,
  searchValue,
  setSearchValue,
  statusValue,
  setStatusValue,
  selcetdcampaign,
  setselcetdcampaign,
  setLoader,
  setMoreDetailLayer,
  setMoreLoader,
  riekRate,
  setToastData,
}) => {
  const rows_error = 'No Loan Details present'

  return (
    <>
      {true ? (
        <Table
          tableData={tableData.loanData}
          eventEmitter={eventEmitter}
          campaignData={campaignData}
          navigateToParams={navigateToParams}
          completeTableData={tableData}
          filterData={filterData}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          statusValue={statusValue}
          setStatusValue={setStatusValue}
          selcetdcampaign={selcetdcampaign}
          setselcetdcampaign={setselcetdcampaign}
          setLoader={setLoader}
          setMoreDetailLayer={setMoreDetailLayer}
          setMoreLoader={setMoreLoader}
          riekRate={riekRate}
          setToastData={setToastData}
        />
      ) : (
        <Box alignContent="center" pad="xlarge" alignSelf="center">
          <Text>{rows_error}</Text>
        </Box>
      )}
    </>
  )
}
export default RepoertTable
