class ErroredRows {
  constructor({error_data, pk, is_successful, row_number, row_data}) {
    this.error_data = Object.values(error_data).join(' , ')
    this.pk = pk
    this.is_successful = is_successful
    this.row_number = row_number
    this.row_data = row_data
  }
}
export default ErroredRows
