const CLIENT_DETAIL_MESSAGES = {
  CLIENT_DETAIL_SUCCESS: 'Details fetched successfully!',

  CLIENT_DETAIL_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_GET_INFO_SUCCESS: 'Details fetched successfully',

  CLIENT_GET_INFO_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again.",

  CLIENT_GET_ALR_SUCCESS: 'Details fetched successfully!',

  CLIENT_GET_ALR_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_GET_SAVINGS_SUCCESS: 'Details fetched successfully!',

  CLIENT_GET_SAVINGS_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_SAVE_INFO_SUCCESS: 'The changes are updated successfully',

  CLIENT_SAVE_INFO_FAILURE:
    "We're sorry, something went wrong or you do not have permissions to perform this action",

  CLIENT_MAIL_INFO_SUCCESS: 'Email sent successfully!',

  CLIENT_MAIL_INFO_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_EXPORT_INFO_SUCCESS: 'Successfull',

  CLIENT_EXPORT_INFO_FAILURE:
    "We're sorry, something went wrong or you do not have permissions to perform this action!",

  CLIENT_GET_LOAN_DETAIL_SUCCESS: 'Details fetched successfully!',

  CLIENT_GET_LOAN_DETAIL_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_GET_LOAN_DETAIL_FILTER_SUCCESS: 'Details fetched successfully!',

  CLIENT_GET_LOAN_DETAIL_FILTER_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_GET_SCREEN_ACTIVITY_SUCCESS: 'Details fetched successfully!',

  CLIENT_GET_SCREEN_ACTIVITY_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_SAVE_SCREEN_ACTIVITY_NOTES_SUCCESS: 'Note added successfully!',

  CLIENT_SAVE_SCREEN_ACTIVITY_NOTES_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_NOTES_GET_SUCCESS: 'Details fetched successfully!',

  CLIENT_NOTES_GET_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_NOTES_DELETE_SUCCESS: 'Notes deleted successfully!',

  CLIENT_NOTES_DELETE_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_NOTES_ADD_SUCCESS: 'Note added successfully!',

  CLIENT_NOTES_ADD_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_SAVINGS_SUCCESS: 'Saved successfully!',

  CLIENT_SAVINGS_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_SUMMARY_SAVE_SUCCESS: 'Details updated successfully',

  LOAN_SUMMARY_SAVE_FAILURE:
    "We're sorry, something went wrong or you do not have permissions to perform this action!",

  CLIENT_ADD_NEW_SUCCESS: 'Client added successfully',

  CLIENT_ADD_NEW_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  EDIT_BUTTON_NOT_CLICKED: 'Please click on the edit button to make changes!',

  CLIENT_GET_MAIL_TEMPLATES_SUCCESS: 'Details fetched successfully!',

  CLIENT_GET_MAIL_TEMPLATES_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",

  CLIENT_LOAN_ADD_SUCCESS: 'Loan added successfully!',

  CLIENT_LOAN_ADD_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",

  CLIENT_SEND_MAIL_SUCCESS: 'Email sent successfully!',

  CLIENT_SEND_MAIL_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",

  CLIENT_LOAN_LIST_EXPORT_SUCCESS: 'File exported successfully',
  CLIENT_LOAN_LIST_EXPORT_WARNING:"No data to download", 
  CLIENT_LOAN_LIST_EXPORT_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",
}

export default CLIENT_DETAIL_MESSAGES
