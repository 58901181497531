// Lib Imports
import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {Box} from 'grommet'

// CORE Imports
import EventEmitter from 'utils/event-emitter'
import Toast from 'granite/components/Toast'
import ACCOUNT_VERIFICATION_MESSAGES from 'messages/verifyAccount'

// Application Imports
import ACCOUNT_VERIFICATION_EVENTS from './constants'
import {verifyAccountClicked} from 'accounts/controllers/user'
import VerificationForm from './components/VerificationForm'

const AccountVerification = ({dispatch, history}) => {
  let eventEmitter = new EventEmitter()
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)

  useEffect(
    function init() {
      const observable = eventEmitter.getObservable()
      listenObservable()

      function listenObservable() {
        observable.subscribe(event => {
   
          switch (event.type) {
            case ACCOUNT_VERIFICATION_EVENTS.VERIFICATION_SUCCESS:
              setToastData({
                open: true,
                message: ACCOUNT_VERIFICATION_MESSAGES.VERIFICATION_SUCCESS,
              })
              setTimeout(() => {
                setToastData({open: false, message: ''})
                history.push('/dashboard')
              }, 1000)
              // dispatch({
              //   type: 'accounts/user/UPDATE_PROFILE',
              //   data: event.data,
              // })
              break
            case ACCOUNT_VERIFICATION_EVENTS.VERIFICATION_FAILURE:
              setToastData({
                open: true,
                message: ACCOUNT_VERIFICATION_MESSAGES.VERIFICATION_FAILURE,
              })
              setTimeout(() => {
                setToastData({open: false, message: ''})
              }, 2000)
              break
            default:
              break
          }
        })
      }
    },
    [eventEmitter, history],
  )

  return (
    <>
      <Box pad="large" margin="none" basis="auto" flex="grow">
        <Box
          alignSelf="center"
          width="medium"
          animation="fadeIn"
          elevation="small"
          pad="medium"
        >
          {/* <PageHeading level="2">Verify Account</PageHeading> */}
          <VerificationForm
            verifyAccountClicked={verifyAccountClicked}
            eventEmitter={eventEmitter}
          />
        </Box>
      </Box>
      {toastData.open ? <Toast text={toastData.message} /> : null}
    </>
  )
}

export default withRouter(AccountVerification)
