const CLIENT_DETAIL_EVENTS = {
  CLIENT_DETAIL_SUCCESS: 'CLIENT_DETAIL_GET_SUCCESS',
  CLIENT_DETAIL_FAILURE: 'CLIENT_DETAIL_GET_FAILURE',

  CLIENT_DETAIL_SAVE_SUCCESS: 'CLIENT_DETAIL_SAVE_SUCCESS',
  CLIENT_DETAIL_SAVE_FAILURE: 'CLIENT_DETAIL_SAVE_FAILURE',

  CLIENT_DETAIL_MAIL_SUCCESS: 'CLIENT_DETAIL_MAIL_SUCCESS',
  CLIENT_DETAIL_MAIL_FAILURE: 'CLIENT_DETAIL_MAIL_FAILURE',

  CLIENT_DETAIL_EXPORT_SUCCESS: 'CLIENT_DETAIL_EXPORT_SUCCESS',
  CLIENT_DETAIL_EXPORT_FAILURE: 'CLIENT_DETAIL_EXPORT_FAILURE',

  CLIENT_GET_LOAN_DETAIL_SUCCESS: 'CLIENT_GET_LOAN_DETAIL_SUCCESS',
  CLIENT_GET_LOAN_DETAIL_FAILURE: 'CLIENT_GET_LOAN_DETAIL_FAILURE',

  CLIENT_GET_LOAN_DETAIL_SELECT_SUCCESS: 'CLIENT_GET_LOAN_DETAIL_SUCCESS',
  CLIENT_GET_LOAN_DETAIL_SELECT_FAILURE: 'CLIENT_GET_LOAN_DETAIL_FAILURE',

  CLIENT_GET_SCREEN_ACTIVITY_SUCCESS: 'CLIENT_GET_SCREEN_ACTIVITY_SUCCESS',
  CLIENT_GET_SCREEN_ACTIVITY_FAILURE: 'CLIENT_GET_SCREEN_ACTIVITY_FAILURE',

  CLIENT_NOTES_SAVE_SUCCESS: 'CLIENT_NOTES_SAVE_SUCCESS',
  CLIENT_NOTES_SAVE_FAILURE: 'CLIENT_NOTES_SAVE_FAILURE',

  CLIENT_NOTES_GET_SUCCESS: 'CLIENT_NOTES_GET_SUCCESS',
  CLIENT_NOTES_GET_FAILURE: 'CLIENT_NOTES_GET_FAILURE',

  CLIENT_NOTES_DELETE_SUCCESS: 'CLIENT_NOTES_DELETE_SUCCESS',
  CLIENT_NOTES_DELETE_FAILURE: 'CLIENT_NOTES_DELETE_FAILURE',

  CLIENT_NOTES_ADD_SUCCESS: 'CLIENT_NOTES_ADD_SUCCESS',
  CLIENT_NOTES_ADD_FAILURE: 'CLIENT_NOTES_ADD_FAILURE',

  CLIENT_LOAN_DETAIL_SUCCESS: 'CLIENT_LOAN_DETAIL_SUCCESS',
  CLIENT_LOAN_DETAIL_FAILURE: 'CLIENT_LOAN_DETAIL_FAILURE',

  CLIENT_LOAN_DETAIL_UPDATE_SUCCESS: 'CLIENT_LOAN_DETAIL_UPDATE_SUCCESS',
  CLIENT_LOAN_DETAIL_UPDATE_FAILURE: 'CLIENT_LOAN_DETAIL_UPDATE_FAILURE',

  CLIENT_LOAN_APPLICANTS_SUCCESS: 'CLIENT_LOAN_APPLICANTS_SUCCESS',
  CLIENT_LOAN_APPLICANTS_FAILURE: 'CLIENT_LOAN_APPLICANTS_FAILURE',

  CLIENT_LOAN_SCREEN_ACTIVITY_SUCCESS: 'CLIENT_LOAN_SCREEN_ACTIVITY_SUCCESS',
  CLIENT_LOAN_SCREEN_ACTIVITY_FAILURE: 'CLIENT_LOAN_SCREEN_ACTIVITY_FAILURE',

  CLIENT_LOAN_SAVINGS_SUCCESS: 'CLIENT_SAVINGS_SUCCESS',
  CLIENT_LOAN_SAVINGS_FAILURE: 'CLIENT_SAVINGS_FAILURE',

  CLIENT_LOAN_ADD_SUCCESS: 'CLIENT_LOAN_ADD_SUCCESS',
  CLIENT_LOAN_ADD_FAILURE: 'CLIENT_LOAN_ADD_FAILURE',

  CLIENT_LOAN_GET_MAIL_TEMPLATE_SUCCESS:
    'CLIENT_LOAN_GET_MAIL_TEMPLATE_SUCCESS',
  CLIENT_LOAN_GET_MAIL_TEMPLATE_FAILURE:
    'CLIENT_LOAN_GET_MAIL_TEMPLATE_FAILURE',

  CLIENT_SEND_MAIL_SUCCESS: 'CLIENT_SEND_MAIL_SUCCESS',
  CLIENT_SEND_MAIL_FAILURE: 'CLIENT_SEND_MAIL_FAILURE',

  CLIENT_UPDATE_OVERVIEW_SUCCESS: 'CLIENT_UPDATE_OVERVIEW_SUCCESS',
  CLIENT_UPDATE_OVERVIEW_FAILURE: 'CLIENT_UPDATE_OVERVIEW_FAILURE',

  CLIENT_REMOVE_APPLICANT_SUCCESS: 'CLIENT_REMOVE_APPLICANT_SUCCESS',
  CLIENT_REMOVE_APPLICANT_FAILURE: 'CLIENT_REMOVE_APPLICANT_FAILURE',

  CLIENT_ADD_APPLICANT_SUCCESS: 'CLIENT_ADD_APPLICANT_SUCCESS',
  CLIENT_ADD_APPLICANT_FAILURE: 'CLIENT_ADD_APPLICANT_FAILURE',

  UPDATE_APPLICANT_TYPE_SUCCESS: 'UPDATE_APPLICANT_TYPE_SUCCESS',
  UPDATE_APPLICANT_TYPE_FAILURE: 'UPDATE_APPLICANT_TYPE_FAILURE',

  LOAN_GET_SECURITY_SUCCESS: 'LOAN_GET_SECURITY_SUCCESS',
  LOAN_GET_SECURITY_FAILURE: 'LOAN_GET_SECURITY_FAILURE',

  LOAN_ADD_SECURITY_SUCCESS: 'LOAN_ADD_SECURITY_SUCCESS',
  LOAN_ADD_SECURITY_FAILURE: 'LOAN_ADD_SECURITY_FAILURE',

  LOAN_UPDATE_SECURITY_SUCCESS: 'LOAN_UPDATE_SECURITY_SUCCESS',
  LOAN_UPDATE_SECURITY_FAILURE: 'LOAN_UPDATE_SECURITY_FAILURE',

  LOAN_DELETE_SECURITY_SUCCESS: 'LOAN_DELETE_SECURITY_SUCCESS',
  LOAN_DELETE_SECURITY_FAILURE: 'LOAN_DELETE_SECURITY_FAILURE',

  LOAN_GET_ALL_LENDERS_SUCCESS: 'LOAN_GET_ALL_LENDERS_SUCCESS',
  LOAN_GET_ALL_LENDERS_FAILURE: 'LOAN_GET_ALL_LENDERSFAILURE',
}
export default CLIENT_DETAIL_EVENTS
