// Lib imports
import React from 'react'
import {Box, TextInput, Anchor, Button, Text} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {Link} from 'react-router-dom'
import styled from 'styled-components'

// Core imports
import PasswordInput from 'granite/components/PasswordInput'
import FormField from 'granite/components/FormField'
import LOGIN_MESSAGES from 'messages/login'

// Application imports
import LOGIN_EVENTS from './../constants'

const LoginForm = ({eventEmitter, loginClicked, error}) => {
  // FIXME: Move strings to messages

  const ErrorText = styled(Text)`
    color: #dd3636;
    font-size: 14px;
  `
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, LOGIN_MESSAGES.EMAIL_TOO_SHORT)
      .max(50, LOGIN_MESSAGES.EMAIL_TOO_LONG)
      .email(LOGIN_MESSAGES.EMAIL_INVALID)
      .required(LOGIN_MESSAGES.EMAIL_REQUIRED),
    password: Yup.string()
      .min(6, LOGIN_MESSAGES.PASS_TOO_SHORT)
      .max(50, LOGIN_MESSAGES.PASS_TOO_LONG)
      .required(LOGIN_MESSAGES.PASS_REQUIRED),
  })

  const onSubmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    try {
      await loginClicked(values.email, values.password)
      eventEmitter.emit(LOGIN_EVENTS.LOGIN_SUCCESS)
    } catch (e) {
      eventEmitter.emit(LOGIN_EVENTS.LOGIN_FAILURE)
      if (e.errors) setErrors(e.errors)
    }
    setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {error ? (
            <ErrorText>
              The email address or password you entered is incorrect
            </ErrorText>
          ) : (
            ''
          )}
          <FormField
            name="email"
            label={LOGIN_MESSAGES.EMAIL_LABEL}
            error={touched.email && errors.email}
          >
            <TextInput
              placeholder={LOGIN_MESSAGES.EMAIL_PLACEHOLDER}
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              autoFocus
            />
          </FormField>
          <FormField
            name="password"
            label={LOGIN_MESSAGES.PASS_LABEL}
            error={touched.password && errors.password}
          >
            <PasswordInput
              placeholder={LOGIN_MESSAGES.PASS_PLACEHOLDER}
              name="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              eyeVisible={true}
            />
          </FormField>

          <Box
            fill="horizontal"
            direction="row-reverse"
            elevation="none"
            justify="between"
            pad="none"
            gap="small"
          >
            <Button
              type="submit"
              alignSelf="end"
              // size="small"
              margin={{vertical: 'small'}}
              label={
                isSubmitting
                  ? LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING
                  : LOGIN_MESSAGES.SUBMIT_BUTTON_LABEL
              }
              disabled={isSubmitting}
              primary
            />
            <Link to="/forgotpassword">
              <Box alignSelf="end">
                <Anchor
                  as="span"
                  label={LOGIN_MESSAGES.FORGOT_PASS_LINK_LABEL}
                  size="small"
                  margin={{top: 'medium'}}
                />
              </Box>
            </Link>
          </Box>

          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
