import React from 'react'
import {Box, DropButton, Text} from 'grommet'
import PropType from 'prop-types'

const DropMenu = ({
  hoverIndicatorOptions,
  label,
  icon,
  renderItems,
  reverse,
  buttonicon,
  isCollapsed,
  labelcolor,
  ...rest
}) => {
  return (
    <Box>
      <DropButton
        dropAlign={{top: 'bottom', right: 'right'}}
        dropContent={renderItems()}
        elevation="medium"
        label={<Text color={labelcolor}>{label}</Text>}
        reverse
        icon={buttonicon}
        plain
      />
    </Box>
  )
}

DropMenu.defaultProps = {
  isCollapsed: true,
  profile: {},
  renderItems: null,
}

DropMenu.propTypes = {
  isCollapsed: PropType.bool,
  profile: PropType.object,
  renderItems: PropType.func,
}

export default DropMenu
