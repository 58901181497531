import RISK_REPORT_API from 'apiriskreportsettings/gateways/risk-report-api'
import RISK_REPORT_EVENTS from 'pages/riskratingreport/constants'
// import {client_type_mapping} from 'granite/CurrencyConvertor'

async function getDataBasedOnRiskFilter(eventEmitter, params) {
  try {
    const response = await RISK_REPORT_API.getRiskTableAPI(params )
    eventEmitter.emit(
      RISK_REPORT_EVENTS.GET_LOAN_TABLE_BASED_ON_RISK_DATA_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      RISK_REPORT_EVENTS.GET_LOAN_TABLE_BASED_ON_RISK_DATA_FAILURE,
      err.message,
    )
  }
}


//addLoansToCampaign
async function addLoansToCampaign(eventEmitter, data) {
 
  try {
    const response = await RISK_REPORT_API.addLoansToCampaignAPI(data)
    eventEmitter.emit(
      RISK_REPORT_EVENTS.ADD_LOAN_BASED_ON_RISK_RATE_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      RISK_REPORT_EVENTS.ADD_LOAN_BASED_ON_RISK_RATE_FAILURE,
      err.message,
    )
  }
}


async function riskreportloadCampaignDetails(eventEmitter, id) {
  if (id) {
    try {
      const response = await RISK_REPORT_API.getCampaignDetailsWithId(id)
      eventEmitter.emit(RISK_REPORT_EVENTS.GET_CAMPAIGN_SUCCESS, response)
      return response
    } catch (err) {
      return false
      // eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
    }
  } else {
    try {
      const response = await RISK_REPORT_API.getCampaignDetails()
      eventEmitter.emit(RISK_REPORT_EVENTS.GET_CAMPAIGN_SUCCESS, response)
      return response
    } catch (err) {
      return false
      // eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
    }
  }
}


export {
  getDataBasedOnRiskFilter,
  addLoansToCampaign,
  riskreportloadCampaignDetails,
}
