//Library imports
import React, {useState} from 'react'
import {Box, Select, Text, Image, Anchor, ThemeContext, CheckBox, Button} from 'grommet'
import styled from 'styled-components'
import queryString from 'query-string'
import {useLocation} from 'react-router-dom'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'
import LoaderUI from 'granite/components/NumLoader'
import ConfirmationLayer from 'granite/components/ConfirmationLayer'

//Application imports
import {
  showMoreDetails,
  handleContactingBulkStatusChanged,
  updateRate,
  saveNewDiscount,
  updateContactingStatus,
} from 'alr/controllers/loanReview'
import CONTACTING_MESSAGES from 'messages/contacting'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: auto;
`
const SelectWrapper = styled(Select)`
  border: 0.5px solid #b1b8c0;
  border-radius: 4px;
  padding-left: 14px !important;
  height: 36px;
  border: none !important;
  font-size: 14px;
  color: #000000;
`
const ButtonStyle = styled(Button)`
  width: auto;
  height: 36px;
`
const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}
const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}
const COLUMNS = [
  {
    accessor: 'selection',
    width: "4%",
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'} style={{textAlign: '-webkit-center'}}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'loan_status',
    Header: 'Status',
    Cell: ({
      cell: {value, row}, 
      setOpen, 
      setLayer,
      setParameters,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction='row' justify='center'>
              {row.original.status === 'Pricing Requested' ? (
              <>
              <Anchor 
                onClick={()=>{
                  setOpen(true)
                  setParameters({
                    string: 'Pricing Completed', 
                    pk: row.original.pk,
                    // newPk: row.original.newpk
                  })
                }} 
                label='Pricing Requested'
                margin={{right:'xsmall'}}
              />
              <Text>|</Text>  
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters({
                    string: 'Closed', 
                    pk: row.original.pk,
                    // newPk: row.original.newpk
                  })
                }}
                label='Pricing Completed'
                margin={{left:'xsmall'}}
              />
              </>
              ) : (
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters({
                    string: 'Closed', 
                    pk: row.original.pk,
                    // newPk: row.original.newpk
                  })
                }}
                label='Pricing Completed'
                margin={{left:'xsmall'}}
              />
              )}
            </Box> 
          }
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
    {
      accessor: 'ation',
      Header: 'Edit',
      Cell: ({cell: {value, row}, campaignId, handleLoan, ...rest}) => {
        return (
          <TextCell
            {...rest}
            data={
              <Box pad={{left: 'small'}}>
                <Box
                  width="15px"
                  height="15px"
                  onClick={() => {
                    window.open(
                        '/clients/' + row.original.applicant_pk + '/' + row.original.loan_pk 
                          + '?edit=true&campaign_id=' + campaignId,
                      )
                  }}
                >
                  <Image
                    src={process.env.PUBLIC_URL + '/img/edit.png'}
                    fit="contain"
                    style={{cursor: 'pointer'}}
                  />
                </Box>
              </Box>
            }
            editMode={false}
            size="14px"
            color="dark-6"
            style={{cursor: 'pointer'}}
          />
        )
      },
      disableSortBy: true,
    },
    {
        accessor: `lender`,
        Header: 'Lender',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
      accessor: 'name',
      Header: 'Clients',
      Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
        return (
          <Box style={{whiteSpace: 'pre-line'}}>
            <TextCell
              {...rest}
              // data={
              //   <Box direction="row" gap="small">
              //     <Box
              //       width="15px"
              //       height="15px"
              //       onClick={() => {
              //         handleApplicant(value, row.original.pk)
              //       }}
              //     >
              //       <Image
              //         src={process.env.PUBLIC_URL + '/img/more_info.png'}
              //         fit="contain"
              //         style={{cursor: 'pointer'}}
              //       />
              //     </Box>
              //     <Box>
              //       <Text size="14px">{value}</Text>
              //     </Box>
              //   </Box>
              // }
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          </Box>
        )
      },
      // disableSortBy: true,
    },
    {
        accessor: `account_number`,
        Header: 'Account No.',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
        accessor: `loan_limit`,
        Header: 'Loan Limit',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
        accessor: `balance`,
        Header: 'Loan Balance',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
        accessor: `product_name`,
        Header: 'Product',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
        accessor: `rate`,
        Header: 'Interest Rate',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value.current_rate + '%'}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
        accessor: `discount`,
        Header: 'Interest Discount',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value.current_discount + '%'}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
    {
      accessor: `security`,
      Header: 'Security',
      Cell: ({cell: {value}, showSecurity, hideSecurity, ...rest}) => {
        return (
          <Box style={{whiteSpace: 'pre-line'}}>
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
            {/* {rest.row.original.new_security_array.length > 1 && (
              <Text
                size="small"
                color="brand"
                style={{cursor: 'pointer', textDecoration: 'underline'}}
                onMouseLeave={() => hideSecurity()}
                onMouseOut={() => hideSecurity()}
                onMouseOver={event => {
                  showSecurity(rest.row.original, event.clientX, event.clientY)
                }}
              >
                {'view more'}
              </Text>
            )} */}
          </Box>
        )
      },
      disableSortBy: true,
    },
    {
        accessor: `purpose`,
        Header: 'Purpose',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        disableSortBy: true,
    },
    {
        accessor: `loan_rate_type_choice`,
        Header: 'Rate Type',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        disableSortBy: true,
    },
    {
        accessor: `fixed_expiry`,
        Header: 'Rate Expiry (Fixed)',
        Cell: ({cell: {value}, hideSecurity, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
              // onMouseEnter={() => hideSecurity()}
            />
          )
        },
        // disableSortBy: true,
    },
    {
        accessor: `repayment_type`,
        Header: 'Repayment Type',
        Cell: ({cell: {value}, hideSecurity, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
              // onMouseEnter={() => hideSecurity()}
            />
          )
        },
        disableSortBy: true,
    },
    {
        accessor: `io_expiry`,
        Header: 'Repayment Expiry IO',
        Cell: ({cell: {value}, ...rest}) => {
          return (
            <TextCell
              {...rest}
              data={value}
              editMode={false}
              size="14px"
              color="dark-6"
              style={{cursor: 'pointer'}}
            />
          )
        },
        // disableSortBy: true,
    },
  ]

const NegotiatePricingTable = ({
  setData,
  setMoreDetailLayer,
  handleApplicantClick,
  eventEmitter,
  loader,
  setMoreLoader,
  navigateToParams,
  statusValue,
  setStatusValue,
  setToastData,
  risk,
  data: {data, total},
  filterData: {page, page_size: size, campaign_id},
}) => {
  const [selected, setSelected] = useState([])
  const [filterImageUrl, setFilterImageUrl] = useState(
    process.env.PUBLIC_URL + '/img/down_arrow.png',
  )
  const [open, setOpen] = useState(false)
  const [layer, setLayer] = useState(false)
  const [parameters, setParameters] = useState({})
  const onConfirm = parameters => {
    if (selected.length > 0) {
      bulkChanged(parameters.string)
      setParameters({})
    } else if (parameters) {
      handleStatusChanged(parameters.string, parameters.pk)
      setParameters({})
    } else {
      setToastData({
        open: true,
        message: 'Please select loan',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }  
  }
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }
  const handleFilter = option => {
    navigateToParams({
      status: option === 'Pricing Requested' ? 'Pricing Completed' : 'All_Pricing',
      search: null,
    })
  }
  const handleStatusChanged = (value, id) => {
    if (selected.length === 0) {
      updateContactingStatus(eventEmitter, id, value, data, setData, total)
    }
  }

  const handleApplicant = id => {
    showMoreDetails(eventEmitter, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }

  let location = useLocation()
  const {search} = location

  const getInitialSorting = () => {
    const queryParams = queryString.parse(search)
    if (queryParams['sorting']) {
      let colName
      let desc
      if (queryParams['sorting'].indexOf('-') === 0) {
        colName = queryParams['sorting'].substring(1)
        desc = true
      } else {
        colName = queryParams['sorting'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSortChange = sortOrder => {
    if (!sortOrder.length) navigateToParams({sorting: null})
    else {
      const [{id, desc}] = sortOrder
      navigateToParams({sorting: desc ? '-' + id : id})
    }
  }

  const bulkChanged = value => {
    if (selected.length > 0) {
      handleContactingBulkStatusChanged(
        eventEmitter,
        selected,
        value,
        data,
        setData,
        total,
      )
    }
  }

  const updateCellData = (rowId, column, value) => {
    if (statusValue === 'Rejected') {
      setToastData({
        open: true,
        message: CONTACTING_MESSAGES.REJECTED_NEW_INTEREST_CHANGE,
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 2000)
    } else {
      if (column === 'new_rate') {
        const newInterest = parseFloat(value).toFixed(2)
        const newData = data.map(item => {
          const newItem = {...item}
          if (item.pk === rowId) {
            newItem.new_rate = newInterest
            newItem.rate.difference = (
              newInterest - risk[newItem.rate.selected_risk]
            ).toFixed(2)
            if (!isNaN(newInterest)) {
              const data = {
                new_rate: parseFloat(newInterest),
              }
              updateRate(eventEmitter, item.pk, data)
            } else if (isNaN(newInterest)) {
              newItem.new_rate = ''
              newItem.loan_rate.difference = undefined
            }
            return newItem
          }
          return newItem
        })
        setData({data: newData, total})
      } else if (column === 'new_discount') {
        const newDiscount = parseFloat(value)
        let currentDiscount
        const newData = data.map(item => {
          const newItem = {...item}
          if (item.pk === rowId) {
            newItem.new_discount = newDiscount
            currentDiscount = parseFloat(newItem.discount.current_discount)
            newItem.discount.difference = (
              newDiscount - currentDiscount
            ).toFixed(1)
            if (!isNaN(newDiscount)) {
              const data = {
                new_discount: newDiscount,
              }
              saveNewDiscount(eventEmitter, item.loan_pk, data)
            } else if (isNaN(newDiscount)) {
              newItem.newDiscount = ''
              newItem.discount.current_discount = 0
            }
            return newItem
          }
          return newItem
        })
        setData({data: newData, total})
      }
    }
  }

  const handleMouseEnter = () => {
    if (statusValue !== '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/search_cancel.png')
    }
  }

  const handleMouseLeave = () => {
    if (statusValue === '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/down_arrow.png')
    }
  }
  return (
    <Box>
      <Box
        gap="large"
        direction="row"
        justify="between"
        width="100%"
        margin={{top: 'medium', bottom: 'medium'}}
      >
        <Box gap="medium" direction="row" width="50%">
          {false && (<Box
            direction="row"
            background={statusValue ? 'light-1' : 'brand-light1'}
            height="36px"
            border={{color: 'round-border'}}
            round="4px"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <SelectWrapper
              options={['All', 'Pricing Requested']}
              icon={
                <Image
                  src={filterImageUrl}
                  fit="contain"
                  onClick={event => {
                    if (statusValue !== '') {
                      navigateToParams({status: 'All_Pricing'})
                      setStatusValue('All_Pricing')
                      event.stopPropagation()
                    }
                  }}
                />
              }
              placeholder="Status"
              value={statusValue}
              onChange={({option}) => {
                setStatusValue(option)
                handleFilter(option)
              }}
            />
          </Box>
          )}
        </Box>
        <Box align="end" height="36px" gap="medium" direction="row">
          <ButtonStyle
            primary
            label="Pricing Requested"
            onClick={() => {
              setOpen(true)
              setParameters({
                string: 'Pricing Completed'
              })
            }}
            disabled={
              selected.length > 0 && 
              selected.filter(item => item.status === "Pricing Completed").length <= 0 
                ? false 
                : true
            }
          />
          <ButtonStyle
            primary
            label="Pricing Completed"
            onClick={() => {
              setLayer(true)
              setParameters({
                string: 'Closed'
              })
            }}
            disabled={selected.length > 0 ? false : true}
          />
        </Box>
      </Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <BoxWrapper overflow={{horizontal: 'auto'}}>
          <Box style={{minWidth: '1250px'}}>
            <ReactTable
              data={data}
              columns={COLUMNS} // pass in our table columns
              setSelected={setSelected} // sets selected row information
              updateCellData={updateCellData}
              initialSortOrder={getInitialSorting()}
              onSortChange={onSortChange}
              bulkChanged={bulkChanged}
              handleApplicantClick={handleApplicantClick}
              statusValue={statusValue}
              handleApplicant={handleApplicant}
              handleStatusChanged={handleStatusChanged}
              risk={risk}
              setOpen={setOpen}
              setLayer={setLayer}
              setParameters={setParameters}
              campaignId={campaign_id}
            />
          </Box>
        </BoxWrapper>
      )}
      {open && (
        <ConfirmationLayer 
          setOpen={setOpen}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Pricing Requested'}
          layerBody={'Are you sure you want to request the pricing?'}
        />
      )}
      {layer && (
        <ConfirmationLayer 
          setOpen={setLayer}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Pricing Completed'}
          layerBody={'Are you sure you want to complete the pricing?'}
        />
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default NegotiatePricingTable
