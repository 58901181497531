//Library imports
import React from 'react'
import {Box, Select, Text, Button, Image, TextInput, CheckBox} from 'grommet'
import styled from 'styled-components'

//Application imports
import LoanTable from './loanDetailTable'
import {exportloanlist} from 'clientDetails/controllers/client'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-top: 2px solid #37236a;
  top: 665px;
  left: 237px;
  height: 52px;
  opacity: 1;
`
const SelectWrapper = styled(Select)`
  padding-left: 14px !important;
  height: 29px;
  top: 279px;
  left: 1117px;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`
const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  padding-left: 14px !important;
`
const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
  color:#000000;
`
const ButtonStyleSearch = styled(Button)`
  height: 35px;
  width: 110px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
`
const LoanDetail = ({
  tableData,
  page,
  rows,
  size,
  setPage,
  setSize,
  event,
  addLoanSummaryVisibility,
  history,
  showAddLoanButton,
  route,
  eventEmitter,
  permissions,
  navigateToParams,
  setLoader,
  setMoreDetailLayer,
  setMoreLoader,
  searchValue,
  setSearchValue,
  filterData,
}) => {
  const prepare_security_filter = tableData => {
    let temp = ['All']
    tableData.forEach(item => {
      item.security_search = []
      let inital_security_array = item.new_security_array
      inital_security_array.forEach(addr => {
        let complete_address =
          (addr.address_line_1 === null ? '' : addr.address_line_1) +
          ' ' +
          (addr.address_line_2 === null ? '' : addr.address_line_2) +
          ' ' +
          (addr.city === null ? '' : addr.city) +
          ' ' +
          (addr.state === null ? '' : addr.state) +
          ' ' +
          (addr.postcode === null ? '' : addr.postcode) +
          ' ' +
          (addr.country === null ? '' : addr.country)
        item.security_search.push(complete_address)
        if (temp.includes(complete_address)) {
        } else {
          temp.push(complete_address)
        }
      })
    })
    return temp
  }

  const temp = prepare_security_filter(tableData)

  // function process_tableData(tableData) {
  //   tableData.forEach(element => {
  //     element.security_search = []
  //     element.new_security_array.forEach(addr => {
  //       element.security_search.push(addr.complete_addr)
  //       if (temp.includes(addr.complete_addr)) {
  //       } else {
  //         temp.push(addr.complete_addr)
  //       }
  //     })
  //   })
  // }

  // process_tableData(tableData)

  const [value, setValue] = React.useState()
  const [checked, setChecked] = React.useState(false);
  const rows_error = 'No Loan Details present'
  let data = tableData.filter(item => checkSecurity(item))

  function checkSecurity(item) {
    if (value === 'All') {
      return item
    } else if (item.security_search.includes(value)) {
      // item.security = value
      return item
    }
  }

  const handleAddLoan = () => {
    if (route.params.clientid) {
      history.push('/clients/' + route.params.clientid + '/addloan')
    }
  }

  const handleExport = () => {
    exportloanlist(eventEmitter, route.params.clientid)
  }

  const handleSearchClicked = () => {
    if (searchValue !== '') {
      navigateToParams({search: searchValue, page: 1})
    }
  }

  const handleSearchCancel = () => {
    setSearchValue('')
    navigateToParams({page: 1, page_size: 20, search: null})
  }

  const handleDischargedLoans = (value) => {
    setChecked(value)
    value ? 
      navigateToParams({page: 1, page_size: 20, is_active: null})
      : navigateToParams({page: 1, page_size: 20, is_active: true})
  }

  return (
    <Box elevation="medium" background="brand-light1">
      <BoxWrapper width="100%" direction="row" background="light-1" pad="small">
        <Box direction="row" width="100%">
          <Box
            width="20%"
            pad={{
              top: 'small',
              bottom: 'small',
              left: 'small',
              right: 'small',
            }}
          >
            <Text weight="bold" color="dark">
              Loan List
            </Text>
          </Box>

          <Box gap="medium" direction="row" align="end">
            <Box
              pad={{right: 'small', bottom: 'xsmall'}}
              align="end"
              width="30%"
              justify="center"
            >
              <CheckBox
                checked={checked}
                fill
                label={<Text size="small">Show Discharged Loans</Text>}
                onChange={event => handleDischargedLoans(event.target.checked)}
              />
            </Box>  
            <Box
              pad={{right: 'medium'}}
              direction="row"
              align="end"
              width="auto"
            >
              <SearchBoxWrapper
                width="100%"
                direction="row"
                height="36px"
                align="end"
                round="4px"
              >
                <TextInputWrapper
                  placeholder="Acc no / BSB no Search"
                  value={searchValue}
                  onChange={event => {
                    setSearchValue(event.target.value)
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      handleSearchClicked()
                    }
                  }}
                />
                <Button
                  alignSelf="center"
                  icon={
                    <Image
                      src={process.env.PUBLIC_URL + '/img/search_cancel.png'}
                      fit="contain"
                    />
                  }
                  onClick={handleSearchCancel}
                />
                <ButtonStyleSearch
                  type="submit"
                  label={'Search'}
                  onClick={() => handleSearchClicked()}
                  primary
                />
              </SearchBoxWrapper>
            </Box>

            {route.params.clientid > 0 &&
              permissions !== null &&
              permissions.includes('export') && (
                <Box width="auto"  pad={{right: 'medium'}}>
                  <ButtonStyle
                    type="submit"
                    alignSelf="end"
                    label={'Export'}
                    primary
                    disabled={false}
                    onClick={handleExport}
                  />
                </Box>
            )}

            <Box width="auto">
              {permissions !== null && permissions.includes('loan_info_add') && (
                <ButtonStyle
                  type="submit"
                  alignSelf="end"
                  label={'Add'}
                  primary
                  disabled={false}
                  onClick={handleAddLoan}
                />
              )}
            </Box>
            <Box width="auto" alignSelf="end">
              <Box alignContent="end">
                <Box border={{color: 'round-border', size: '1px'}} round="4px">
                  <SelectWrapper
                    options={temp}
                    value={value}
                    placeholder="Security Properties"
                    onChange={({option}) => {
                      setValue(option)
                      if (value) {
                        data = tableData.filter(item => item.security === value)
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </BoxWrapper>
      {data ? (
        <LoanTable
          tableData={value ? data : tableData}
          page={page}
          rows={rows}
          size={size}
          setPage={setPage}
          setSize={setSize}
          event={event}
          navigateToParams={navigateToParams}
          setLoader={setLoader}
          setMoreDetailLayer={setMoreDetailLayer}
          setMoreLoader={setMoreLoader}
          filterData={filterData}
        ></LoanTable>
      ) : (
        <Box alignContent="center" pad="xlarge" alignSelf="center">
          <Text>{rows_error}</Text>
        </Box>
      )}
    </Box>
  )
}
export default LoanDetail
