import loanDetailApi from 'alr/gateways/loanReview-api'
import CONTACTING_EVENTS from 'pages/contacting/constants'
import NEGOTIATE_PRICING_EVENTS from 'pages/negotiatePricing/constants'
import CAMPAIGN_EVENTS from 'pages/campaign/constants'
import REVIEW_APPLICANT_EVENTS from 'pages/reviewApplicant/constants'
import REVIEW_EVENTS from 'pages/review/constants'
import NEW_EVENTS from 'pages/campaign/constants'
import ADD_CLIENT_EVENTS from 'pages/addClients/constants'
import {loadCampaignDetails} from 'alr/controllers/campaign'
import ALR_EVENTS from 'pages/alrData/constants'
import CLOSED_EVENTS from 'pages/closed/constants'
import {campaignRiskRate} from 'alr/controllers/risk'

async function fetchContactingData(eventEmitter, {params}, campaign_id) {
  if (campaign_id) {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter, campaign_id)
    await contactingLoanDetails(eventEmitter, {params}, pk)
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
    await campaignRiskRate(eventEmitter, pk)
    await getLoanReviewSavings(eventEmitter, pk)
  } else {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter)
    if (pk) {
      await contactingLoanDetails(eventEmitter, {params}, pk)
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
      await campaignRiskRate(eventEmitter, pk)
      await getLoanReviewSavings(eventEmitter, pk)
    } else if (!pk) {
      let select_params = {
        page: 1,
        page_size: 1000,
      }
      const response = await loanDetailApi.getAllCampaigns(select_params)
      fetchContactingData(eventEmitter, {params}, response.pk)
    } else {
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
    }
  }
  // eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
  // const {pk} = await loadCampaignDetails(eventEmitter)
  // await contactingLoanDetails(eventEmitter, {params}, pk)
  // eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
  // await campaignRiskRate(eventEmitter, pk)
  // await getLoanReviewSavings(eventEmitter, pk)
}

async function contactingLoanDetails(eventEmitter, {params}, pk) {
  try {
    const data = {...params, campaign_id: pk}

    const response = await loanDetailApi.getClientResultReviewLoanDetailsList(data)
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_FAILURE, err.message)
  }
}

async function fetchNegotiatePricingData(eventEmitter, {params}, campaign_id) {
  if (campaign_id) {
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter, campaign_id)
    await negotiatePricingLoanDetails(eventEmitter, {params}, pk)
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.HIDE_LOADER)
    await campaignRiskRate(eventEmitter, pk)
    await getLoanReviewSavings(eventEmitter, pk)
  } else {
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter)
    if (pk) {
      await negotiatePricingLoanDetails(eventEmitter, {params}, pk)
      eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.HIDE_LOADER)
      await campaignRiskRate(eventEmitter, pk)
      await getLoanReviewSavings(eventEmitter, pk)
    } else if (!pk) {
      let select_params = {
        page: 1,
        page_size: 1000,
      }
      const response = await loanDetailApi.getAllCampaigns(select_params)
      fetchNegotiatePricingData(eventEmitter, {params}, response.pk)
    } else {
      eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.HIDE_LOADER)
    }
  }
}

async function negotiatePricingLoanDetails(eventEmitter, {params}, pk) {
  try {
    const data = {...params, campaign_id: pk}

    const response = await loanDetailApi.getNegotiatePricingLoanDetailsList(data)
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.GET_LOAN_DETAILS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.GET_LOAN_DETAILS_FAILURE, err.message)
  }
}

async function fetchOutcomeData(eventEmitter, {params}, campaign_id) {
  if (campaign_id) {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter, campaign_id)
    await outcomeLoanDetails(eventEmitter, {params}, pk)
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
    await campaignRiskRate(eventEmitter, pk)
    await getLoanReviewSavings(eventEmitter, pk)
  } else {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter)
    if (pk) {
      await outcomeLoanDetails(eventEmitter, {params}, pk)
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
      await campaignRiskRate(eventEmitter, pk)
      await getLoanReviewSavings(eventEmitter, pk)
    } else if (!pk) {
      let select_params = {
        page: 1,
        page_size: 1000,
      }
      const response = await loanDetailApi.getAllCampaigns(select_params)
      fetchOutcomeData(eventEmitter, {params}, response.pk)
    } else {
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
    }
  }
}

async function outcomeLoanDetails(eventEmitter, {params}, pk) {
  try {
    const data = {...params, campaign_id: pk}
    const response = await loanDetailApi.getClientResultReviewLoanDetailsList(data)
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_FAILURE, err.message)
  }
}

async function fetchClosedData(eventEmitter, {params}, campaign_id) {
  if (campaign_id) {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter, campaign_id)
    await closedLoanDetails(eventEmitter, {params}, pk)
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
    await campaignRiskRate(eventEmitter, pk)
    await getLoanReviewSavings(eventEmitter, pk)
  } else {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter)
    if (pk) {
      await closedLoanDetails(eventEmitter, {params}, pk)
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
      await campaignRiskRate(eventEmitter, pk)
      await getLoanReviewSavings(eventEmitter, pk)
    } else if (!pk) {
      let select_params = {
        page: 1,
        page_size: 1000,
      }
      const response = await loanDetailApi.getAllCampaigns(select_params)
      fetchClosedData(eventEmitter, {params}, response.pk)
    } else {
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
    }
  }
}

async function closedLoanDetails(eventEmitter, {params}, pk) {
  try {
    const data = {...params, campaign_id: pk}
    const response = await loanDetailApi.getClosedLoanDetailsList(data)
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_FAILURE, err.message)
  }
}
async function loadClientLoanDetails(eventEmitter, value, {params}, campaignId, select_page, select_page_size) {
  try {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_ADD_LOAN_LOADER)
    const data = campaignId ? {
      applicant_id: value,
      is_active: true,
      is_deleted: false,
      campaign_id: campaignId,
      page: select_page,
      page_size: select_page_size,
      ...params,
    } : {
      applicant_id: value,
      is_active: true,
      is_deleted: false,
      ...params,
    }
    const response = await loanDetailApi.getClientLoanDetailsList(data)

    eventEmitter.emit(
      ADD_CLIENT_EVENTS.GET_CLIENT_LOAN_DETAILS_SUCCESS,
      response,
    )
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_ADD_LOAN_LOADER)
  } catch (err) {
    eventEmitter.emit(
      ADD_CLIENT_EVENTS.GET_CLIENT_LOAN_DETAILS_FAILURE,
      err.message,
    )
  }
}

async function loadClients(eventEmitter, {params}) {
  try {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const data = params
    const response = await loanDetailApi.getClientsList(data)
    eventEmitter.emit(ADD_CLIENT_EVENTS.GET_CLIENTS_SUCCESS, response)
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
  } catch (err) {
    eventEmitter.emit(ADD_CLIENT_EVENTS.GET_CLIENTS_FAILURE, err.message)
  }
}

async function fetchNewData(eventEmitter, {params}, campaign_id) {
  if (campaign_id) {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const {pk} = await loadCampaignDetails(eventEmitter, campaign_id)
    if (campaign_id) {
      eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_ID_SUCCESS, campaign_id)
      await newLoanDetails(eventEmitter, {params}, campaign_id)
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
      await campaignRiskRate(eventEmitter, pk)
      await getLoanReviewSavings(eventEmitter, campaign_id)
    } else {
      eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
    }
  } else {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const pk = await loadCampaignDetails(eventEmitter)
    if (pk) {
      eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_ID_SUCCESS, pk.pk)
      await newLoanDetails(eventEmitter, {params}, pk.pk)
      eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
      await campaignRiskRate(eventEmitter, pk.pk)
      await getLoanReviewSavings(eventEmitter, pk.pk)
    } else if (!pk) {
      let select_params = {
        page: 1,
        page_size: 1000,
      }
      const response = await loanDetailApi.getAllCampaigns(select_params)
      fetchNewData(eventEmitter, {params}, response.pk)
    } else {
      eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
    }
  }
  // eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
  // const pk = await loadCampaignDetails(eventEmitter)
  // if (pk) {
  //   eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_ID_SUCCESS, pk.pk)
  //   await newLoanDetails(eventEmitter, {params}, pk.pk)
  //   eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
  //   await getLoanReviewSavings(eventEmitter, pk.pk)
  // } else {
  //   eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
  // }
}

async function newLoanDetails(eventEmitter, {params}, pk) {
  try {
    const data = {...params, campaign_id: pk}
    const response = await loanDetailApi.getNewLoanDetailsList(data)
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_LOAN_DETAILS_FAILURE, err.message)
  }
}

async function getLoanReviewSavings(eventEmitter, campaignId) {
  const params = {
    campaign_id: campaignId,
  }
  try {
    const response = await loanDetailApi.getSavings(params)
    eventEmitter.emit(CONTACTING_EVENTS.GET_SAVINGS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_SAVINGS_FAILURE, err.message)
  }
}

async function statusChangedClicked(
  eventEmitter,
  value,
  id,
  data,
  setData,
  total,
  loanPk,
) {
  try {
    const payload = {loan_status: ''}
    if (value === 'Refinancing') {
      payload.loan_status = '3'
    } else if (value === 'Applying New Rate') {
      payload.loan_status = '1'
    } else if (value === 'Retention') {
      payload.loan_status = '2'
    }

    // await loanDetailApi.statusChanged(payload, id)
    await loanDetailApi.statusChangedOutcomeScreen(payload, id, loanPk)
    const updatedStatus = data.map(item => {
      const newItem = {...item}
      if (newItem.pk === loanPk) {
        newItem.loan_status = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedStatus, total})
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function outcomeChangedClicked(
  eventEmitter,
  value,
  id,
  data,
  setData,
  total,
  loanPk,
) {
  try {
    let outcome
    if (value === '--No Changes--') outcome = '1'
    else if (value === '$!Money saved, no further action') outcome = '2'
    else if (value === '$!Money saved, Further action required') outcome = '3'
    else if (value === '!No Money Saved, further action') outcome = '4'
    const payload = {out_come: outcome}
    await loanDetailApi.statusChanged(payload, id)
    const updatedOutcome = data.map(item => {
      const newItem = {...item}
      if (newItem.pk === loanPk) {
        newItem.loan_out_come = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedOutcome, total})
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}
async function applicantLoanStatusChanged(
  eventEmitter,
  value,
  id,
  data,
  setData,
  total,
  loanPk,
) {
  try {
    const payload = {loan_status: ''}
    if (value === 'Refinancing') {
      payload.loan_status = '3'
    } else if (value === 'Applying New Rates') {
      payload.loan_status = '1'
    } else if (value === 'Retention') {
      payload.loan_status = '2'
    }

    // await loanDetailApi.statusChanged(payload, id)
    await loanDetailApi.statusChangedOutcomeScreen(payload, id, loanPk)
    const updatedStatus = data.map(item => {
      const newItem = {...item}
      if (newItem.pk === loanPk) {
        newItem.loan_status = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedStatus, total})
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
  // try {
  //   const payload = {status: 'Closed'}
  //   await loanDetailApi.statusChanged(payload, id)
  //   const updatedStatus = data.map(item => {
  //     const newItem = {...item}
  //     if (newItem.pk === id) {
  //       newItem.loan_status = value
  //       return newItem
  //     }
  //     return newItem
  //   })
  //   setData({data: updatedStatus, total})
  //   eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  // } catch (err) {
  //   eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  // }
}

async function applicantLoanOutcomeChanged(
  eventEmitter,
  value,
  id,
  data,
  setData,
  total,
) {
  try {
    let outcome
    if (value === '--No Changes--') outcome = '1'
    else if (value === '$!Money saved, no further action') outcome = '2'
    else if (value === '$!Money saved, Further action required') outcome = '3'
    else if (value === '!No Money Saved, further action') outcome = '4'
    const payload = {out_come: outcome}
    await loanDetailApi.statusChanged(payload, id)
    const updatedOutcome = data.map(item => {
      const newItem = {...item}
      if (newItem.pk === id) {
        newItem.out_come = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedOutcome, total})
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}
async function showMoreDetails(eventEmitter, id) {
  try {
    const { moreData: response, savings } = await loanDetailApi.getMoreDetails(id)
    const moreDetails = [
      {
        sNo: 1,
        infoAbout: 'Security Property',
        info: response.security_name,
      },
      {
        sNo: 2,
        infoAbout: 'Client',
        info: response.applicant_name,
      },
      {
        sNo: 3,
        infoAbout: 'Lender',
        info: response.lender_name,
      },
      {
        sNo: 4,
        infoAbout: 'Account NO./BSB No.',
        info: response.account_bsb_no,
      },
      {
        sNo: 5,
        infoAbout: 'Settlement Date',
        info: response.settlement_date,
      },
      {
        sNo: 6,
        infoAbout: 'Loan Limit',
        info: response.loan_limit,
      },
      {
        sNo: 7,
        infoAbout: 'Loan Balance',
        info: response.balance,
      },
      // {
      //   sNo: 8,
      //   infoAbout: 'Interest Rate',
      //   info: response.rate,
      // },
      {
        sNo: 8,
        infoAbout: 'Discount',
        info: response.discount + '%',
      },
      {
        sNo: 9,
        infoAbout: 'Repayment Type',
        info: response.repayment_type,
      },
      {
        sNo: 10,
        infoAbout: 'Rate Type',
        info: response.rate_type_choice,
      },
      {
        sNo: 11,
        infoAbout: 'Repayment Amount',
        info: response.repayment_amount,
      },
      {
        sNo: 12,
        infoAbout: 'Repayment Date',
        info: response.repayment_date,
      },
      {
        sNo: 13,
        infoAbout: 'Loan Product',
        info: response.product_name,
      },
      {
        sNo: 14,
        infoAbout: 'Product Expiry',
        info: response.product_expiry,
      },
      {
        sNo: 15,
        infoAbout: 'Package',
        info: response.loan_package,
      },
      {
        sNo: 16,
        infoAbout: 'Loan Term',
        info: response.term,
      },
      {
        sNo: 17,
        infoAbout: 'Offset Account',
        info: response.off_set_account,
      },
      {
        sNo: 18,
        infoAbout: 'Purpose',
        info: response.purpose,
      },
      {
        sNo: 19,
        infoAbout: 'Io Expiry',
        info: response.io_expiry,
      },
      {
        sNo: 20,
        infoAbout: 'Fixed Expiry',
        info: response.fixed_expiry,
      },
      // {
      //   sNo: 22,
      //   infoAbout: 'Old rate',
      //   info: response.old_rate,
      // },
      {
        sNo: 21,
        infoAbout: 'Rate',
        info: response.rate + '%',
      },
      {
        sNo: 22,
        infoAbout: 'Savings',
        info: savings.alrSavings,
      },
    ]
    const final_response = {
      moreDetails: moreDetails,
      applicant_pk: response.applicant_pk,
      loan_pk: response.pk,
    }
    eventEmitter.emit(
      CONTACTING_EVENTS.GET_MORE_DETAILS_SUCCESS,
      final_response,
    )
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_MORE_DETAILS_FAILURE, err.message)
  }
}

async function handleAddClientLoans(eventEmitter, payload) {
  try {
    const response = await loanDetailApi.newAddedLoans(payload)
    eventEmitter.emit(ADD_CLIENT_EVENTS.SAVE_LOAN_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(ADD_CLIENT_EVENTS.SAVE_LOAN_FAILURE, err.message)
  }
}

async function alrData(eventEmitter, id, {params}) {
  try {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const data = {campaign_id: id, ...params}
    const response = await loanDetailApi.getAlrData(data, id)
    eventEmitter.emit(CONTACTING_EVENTS.ALR_DATA_SUCCESS, response)
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.ALR_DATA_FAILURE, err.message)
  }
}

async function campaignList(eventEmitter, {params}) {
  try {
    let data = params
    let month_selected = ''
    if (params.month !== '') {
      if (params.month === 'January') month_selected = '1'
      else if (params.month === 'February') month_selected = '2'
      else if (params.month === 'March') month_selected = '3'
      else if (params.month === 'April') month_selected = '4'
      else if (params.month === 'May') month_selected = '5'
      else if (params.month === 'June') month_selected = '6'
      else if (params.month === 'July') month_selected = '7'
      else if (params.month === 'August') month_selected = '8'
      else if (params.month === 'September') month_selected = '9'
      else if (params.month === 'October') month_selected = '10'
      else if (params.month === 'November') month_selected = '11'
      else if (params.month === 'December') month_selected = '12'
      data = {...params, month: month_selected}
    }
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_LOADER)
    const response = await loanDetailApi.getCampaignList(data)
    eventEmitter.emit(CONTACTING_EVENTS.CAMPAIGN_LIST_SUCCESS, response)
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.CAMPAIGN_LIST_FAILURE, err.message)
  }
}

async function handleNewBulkStatusChanged(
  eventEmitter,
  selectedLoans,
  value,
  data,
  setData,
  total,
) {
  try {
    const newData = selectedLoans.map(item => {
      const newItem = {
        id: item.pk,
        keys: {status: value === 'Accepted' ? 'Contacting' : value},
      }
      return newItem
    })
    await loanDetailApi.bulkStatusChanged(newData)
    const selectedId = []
    selectedLoans.map(item => {
      selectedId.push(item.pk)
      return selectedId
    })
    const updatedBulkStatus = data.map(item => {
      const newItem = {...item}
      if (selectedId.includes(newItem.pk)) {
        newItem.status = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedBulkStatus, total})
    const newTableData = data.filter(item => {
      if (selectedId.includes(item.pk)) return false
      else return true
    })
    setData({data: newTableData, total})
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function handleContactingBulkStatusChanged(
  eventEmitter,
  selectedLoans,
  value,
  data,
  setData,
  total,
) {
  try {
    const newData = selectedLoans.map(item => {
      const newItem = {
        id: item.pk,
        keys: {status: value},
      }
      return newItem
    })
    await loanDetailApi.bulkStatusChanged(newData)
    const selectedId = []
    selectedLoans.map(item => {
      selectedId.push(item.pk)
      return selectedId
    })
    const updatedBulkStatus = data.map(item => {
      const newItem = {...item}
      if (selectedId.includes(newItem.pk)) {
        newItem.status = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedBulkStatus, total})
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function handleCompletedStatusChanged(eventEmitter, selectedLoans, status) {
  try {
    const newData = selectedLoans.map(item => {
      const newItem = {
        id: item.pk,
        keys: {status: status},
      }
      return newItem
    })
    await loanDetailApi.bulkStatusChanged(newData)
    eventEmitter.emit(CLOSED_EVENTS.STATUS_CHANGED_SUCCESS, status)
  } catch (err) {
    eventEmitter.emit(CLOSED_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function handleBulkOutcomeChanged(eventEmitter, data) {
  try {
    const response = await loanDetailApi.bulkOutcomeChanged(data)
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function updateNewStatus(eventEmitter, id, value, data, setData, total) {
  try {
    const paylaod = {status: value === 'Accepted' ? 'Contacting' : value}
    await loanDetailApi.newStatusAndRate(paylaod, id)
    const newData = data.filter(item => {
      return item.pk !== id
    })
    setData({data: newData, total})
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function updateContactingStatus(
  eventEmitter,
  id,
  value,
  data,
  setData,
  total,
) {
  try {
    const payload = {status: value}
    await loanDetailApi.newStatusAndRate(payload, id)
    const updatedStatus = data.map(item => {
      const newItem = {...item}
      if (newItem.pk === id) {
        newItem.status = value
        return newItem
      }
      return newItem
    })
    setData({data: updatedStatus, total})
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.STATUS_CHANGED_SUCCESS, value)
  } catch (err) {
    eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function updateRate(eventEmitter, id, value) {
  try {
    const data = value
    await loanDetailApi.newStatusAndRate(data, id)
    eventEmitter.emit(CONTACTING_EVENTS.RATE_CHANGED_SUCCESS)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.RATE_CHANGED_FAILURE, err.message)
  }
}

async function loadClientInfo(eventEmitter, clientId) {
  try {
    const response = await loanDetailApi.getClientInfo(clientId)
    eventEmitter.emit(REVIEW_APPLICANT_EVENTS.CLIENT_DETAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      REVIEW_APPLICANT_EVENTS.CLIENT_DETAIL_FAILURE,
      err.message,
    )
  }
}

async function getClientSavings(eventEmitter, clientId) {
  const params = {
    applicant_id: clientId,
  }
  try {
    const response = await loanDetailApi.getSavings(params)
    eventEmitter.emit(CONTACTING_EVENTS.GET_SAVINGS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.GET_SAVINGS_FAILURE, err.message)
  }
}

async function exportCampaignDetails(eventEmitter, campaignId) {
  try {
    const response = await loanDetailApi.exportCampaign(campaignId)
    eventEmitter.emit(ALR_EVENTS.EXPORT_CAMPAIGN_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(ALR_EVENTS.EXPORT_CAMPAIGN_SUCCESS)
  }
}

async function saveNewDiscount(eventEmitter, id, data) {
  try {
    await loanDetailApi.newDiscount(id, data)
    eventEmitter.emit(CONTACTING_EVENTS.DISCOUNT_CHANGED_SUCCESS)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.DISCOUNT_CHANGED_FAILURE, err.message)
  }
}

async function getMailTemplateList(eventEmitter, name, clientid) {
  try {
    const response = await loanDetailApi.getMailTemplateList(name, clientid)
    eventEmitter.emit(NEW_EVENTS.GET_MAIL_TEMPLATE_LIST_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(NEW_EVENTS.GET_MAIL_TEMPLATE_LIST_FAILURE, err.message)
  }
}

async function getMailTemplateListOfOutcome(eventEmitter, name, clientid) {
  try {
    const response = await loanDetailApi.getMailTemplateList(name, clientid)
    eventEmitter.emit(REVIEW_EVENTS.GET_MAIL_TEMPLATE_LIST_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(REVIEW_EVENTS.GET_MAIL_TEMPLATE_LIST_FAILURE, err.message)
  }
}

//mailClientInfoNew

async function mailClientInfoNew(
  eventEmitter,
  values,
  name,
  Id,
  selectedapplicants,
) {
  try {
    const response = await loanDetailApi.NewScreenSendMail(
      name,
      Id,
      values,
      selectedapplicants,
    )
    eventEmitter.emit(NEW_EVENTS.SEND_MAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(NEW_EVENTS.SEND_MAIL_FAILURE, err.message)
  }
}

async function getMailTemplateListRa(eventEmitter, name, clientid) {
  try {
    const response = await loanDetailApi.getMailTemplateList(name, clientid)
    eventEmitter.emit(REVIEW_APPLICANT_EVENTS.GET_MAIL_LIST_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(
      REVIEW_APPLICANT_EVENTS.GET_MAIL_LIST_FAILURE,
      err.message,
    )
  }
}

//mailClientInfoNew

async function mailClientInfoContacting(eventEmitter, values, name, Id, selectedapplicants, campaignId) {
  try {
    const status = 'Pricing Requested'
    let data = []

    selectedapplicants.forEach(applicant => {
      data.push(loanDetailApi.NewScreenSendMail(name, values, applicant, campaignId, status))
    })
    const response = await Promise.allSettled(data)
    eventEmitter.emit(CONTACTING_EVENTS.SEND_MAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.SEND_MAIL_FAILURE, err.message)
  }
}

async function mailClientInfoOutcome(eventEmitter, values, name, Id, selectedapplicants, campaignId) {
  try {
    const status = 'Completed'
    let data = []

    selectedapplicants.forEach(applicant => {
      data.push(loanDetailApi.NewScreenSendMail(name, values, applicant, campaignId, status))
    })
    const response = await Promise.allSettled(data)
    eventEmitter.emit(REVIEW_EVENTS.SEND_MAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(REVIEW_EVENTS.SEND_MAIL_FAILURE, err.message)
  }
}

async function notifyClientStatusChange(eventEmitter, EVENTS, selectedapplicants, campaignId, status) {
  try {
    let data = []
    selectedapplicants.forEach(applicant => {
      data.push(loanDetailApi.statusChangeOnNotify(applicant, campaignId, status))
    })
    const response = await Promise.allSettled(data)
    eventEmitter.emit(EVENTS.STATUS_CHANGED_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(EVENTS.STATUS_CHANGED_FAILURE, err.message)
  }
}

async function mailClientInfoNewRa(eventEmitter, values, name, Id) {
  try {
    const response = await loanDetailApi.mailClientInfoNew(name, Id, values)
    eventEmitter.emit(REVIEW_APPLICANT_EVENTS.SEND_MAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(REVIEW_APPLICANT_EVENTS.SEND_MAIL_FAILURE, err.message)
  }
}

async function getMailTemplateListClosed(eventEmitter, name, clientid) {
  try {
    const response = await loanDetailApi.getMailTemplateList(name, clientid)
    eventEmitter.emit(CLOSED_EVENTS.GET_MAIL_LIST_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CLOSED_EVENTS.GET_MAIL_LIST_FAILURE, err.message)
  }
}

//mailClientInfoNew

async function mailClientInfoClosed(eventEmitter, values, name, Id) {
  try {
    const response = await loanDetailApi.mailClientInfoClosed(name, Id, values)
    eventEmitter.emit(CLOSED_EVENTS.SEND_MAIL_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CLOSED_EVENTS.SEND_MAIL_FAILURE, err.message)
  }
}

async function campaigneditapi(eventEmitter, year, month, checked) {
  try {
    const payload = {
      add_loans: checked ? false : true,
      campaign_date: `${year}-${month}-${new Date().getDate()}`
    }
    const response = await loanDetailApi.campaignedit(payload)
    eventEmitter.emit(CONTACTING_EVENTS.SAVE_NEW_CAMPAIGN_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(CONTACTING_EVENTS.SAVE_NEW_CAMPAIGN_FAILURE, err.message)
  }
}

async function OutcomeInnerLoanList(
  eventEmitter,
  value,
  {params},
  status,
  comingCampaignId,
) {
  if (comingCampaignId === 'null') {
    var {pk} = await loadCampaignDetails(eventEmitter)
  }

  try {
    eventEmitter.emit(CONTACTING_EVENTS.SHOW_ADD_LOAN_LOADER)
    const data = {applicant_id: value, pk, comingCampaignId, status, ...params}
    const response = await loanDetailApi.OutcomeInnerLoanListAPI(data)

    eventEmitter.emit(
      ADD_CLIENT_EVENTS.GET_CLIENT_LOAN_DETAILS_SUCCESS,
      response,
    )
    eventEmitter.emit(CONTACTING_EVENTS.HIDE_ADD_LOAN_LOADER)
  } catch (err) {
    eventEmitter.emit(
      ADD_CLIENT_EVENTS.GET_CLIENT_LOAN_DETAILS_FAILURE,
      err.message,
    )
  }
}

async function sendSmsOutcome(eventEmitter, value, pk, phone) {
  try {
    const response = await loanDetailApi.sendSmsOutcomeAPI(value, pk, phone)
    eventEmitter.emit(REVIEW_APPLICANT_EVENTS.SEND_SMS_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(REVIEW_APPLICANT_EVENTS.SEND_SMS_FAILURE, err.message)
  }
}

async function campaignListcommon(eventEmitter, screen, select_params) {
  try {
    const response = await loanDetailApi.getcommonCampaignList(select_params)
    let temp = []
    response.campaignData.forEach(item => {
      temp.push(item.month_year)
    })
    response['temp'] = temp
    if (screen === 'New') {
      eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_LIST_SUCCESS, response)
    }
    if (screen === 'Closed') {
      eventEmitter.emit(CLOSED_EVENTS.GET_CAMPAIGN_LIST_SUCCESS, response)
    }
    if (screen === 'NegotiatePricing') {
      eventEmitter.emit(NEGOTIATE_PRICING_EVENTS.GET_CAMPAIGN_LIST_SUCCESS, response)
    }
    if (screen === 'Contacting') {
      eventEmitter.emit(CONTACTING_EVENTS.GET_CAMPAIGN_LIST_SUCCESS, response)
    }
    if (screen === 'Outcome') {
      eventEmitter.emit(REVIEW_EVENTS.GET_CAMPAIGN_LIST_SUCCESS, response)
    }
    //Outcome
    // eventEmitter.emit(CONTACTING_EVENTS.CAMPAIGN_LIST_SUCCESS, response)
    // eventEmitter.emit(CONTACTING_EVENTS.HIDE_LOADER)
  } catch (err) {
    // eventEmitter.emit(CONTACTING_EVENTS.CAMPAIGN_LIST_FAILURE, err.message)
  }
}

async function handleNewBulkStatusChangedAcceptedRejected(eventEmitter, data) {
  try {
    await loanDetailApi.bulkStatusChanged(data)
    eventEmitter.emit(NEW_EVENTS.NEW_LOANS_ACCEPTED_REJECTED_SUCCESS)
  } catch (err) {
    eventEmitter.emit(
      NEW_EVENTS.NEW_LOANS_ACCEPTED_REJECTED_FAILURE,
      err.message,
    )
  }
}

export {
  loadClientLoanDetails,
  loadClients,
  newLoanDetails,
  statusChangedClicked,
  outcomeChangedClicked,
  showMoreDetails,
  handleAddClientLoans,
  alrData,
  campaignList,
  handleContactingBulkStatusChanged,
  handleBulkOutcomeChanged,
  loadClientInfo,
  contactingLoanDetails,
  negotiatePricingLoanDetails,
  outcomeLoanDetails,
  closedLoanDetails,
  fetchContactingData,
  fetchNewData,
  fetchNegotiatePricingData,
  fetchOutcomeData,
  fetchClosedData,
  exportCampaignDetails,
  saveNewDiscount,
  updateRate,
  updateNewStatus,
  updateContactingStatus,
  handleNewBulkStatusChanged,
  applicantLoanStatusChanged,
  applicantLoanOutcomeChanged,
  getLoanReviewSavings,
  getClientSavings,
  mailClientInfoNew,
  getMailTemplateList,
  getMailTemplateListRa,
  mailClientInfoNewRa,
  mailClientInfoContacting,
  mailClientInfoOutcome,
  getMailTemplateListClosed,
  mailClientInfoClosed,
  campaigneditapi,
  OutcomeInnerLoanList,
  sendSmsOutcome,
  campaignListcommon,
  handleNewBulkStatusChangedAcceptedRejected,
  getMailTemplateListOfOutcome,
  notifyClientStatusChange,
  handleCompletedStatusChanged,
}
