//Library imports
import React from 'react'
import {Box, Button, Text, TextInput, Select} from 'grommet'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

//Core imports
import FormField from 'granite/components/FormField'

const Form = ({onSubmit, clientData}) => {
  const ButtonStyle = styled(Button)`
    height: 36px;
    width: 110px;
  `

  const SelectWrapper = styled(Select)`
  padding-left: 14px !important;
  height: 29px;
  top: 279px;
  left: 1117px;
  font-size: 12px;
  round:'none'
  borderBottom:'none'
`
  const TextInput1 = styled(TextInput)`
    borderradius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-bottom: 4px;
    padding-top: 0px;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
  `
  const TextStyle = styled(Text)`
    font-weight: 700;
  `

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('First Name Required'),
    last_name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Last Name Required'),
    phone: Yup.string()
      .min(10, 'Too Short!')
      .max(15, 'Too Long!')
      .required('Phone Required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email Required'),
    client_type: Yup.string().required('Client Type Required'),
    company_name: Yup.string().when('client_type', {
      is: 'Company',
      then: Yup.string()
        .required('Company Name Required')
        .max(32, 'Too Long!'),
    }),
    company_ABN: Yup.number('Must be valid number').when('client_type', {
      is: 'Company',
      then: Yup.number('Must be valid number')
        .typeError('Company ABN must be a number')
        .required('Company ABN Required')
        .max(9999999999999999,"Too Long!"),
    }),
  })

  const handleClientTypeChange = (option, setFieldValue) => {
    setFieldValue('client_type', option)
     if (option === 'Individual') {
       setFieldValue('company_name', '')
       setFieldValue('company_ABN', '')
     }
     else {
        setFieldValue('company_name', clientData.company_name)
        setFieldValue('company_ABN', clientData.company_ABN)
     }
  }
  return (
    <>
      <Formik
        initialValues={{
          email: clientData.email,
          phone: clientData.phone,
          first_name: clientData.first_name,
          last_name: clientData.last_name,
          company_name: clientData.company_name,
          company_ABN: clientData.company_ABN,
          client_type: clientData.client_type,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              fill="horizontal"
              direction="row"
              elevation="none"
              justify="between"
            >
              <Box>
                <TextStyle color="dark" size="13px">
                  Client Information
                </TextStyle>
              </Box>
              <ButtonStyle
                type="submit"
                alignSelf="end"
                label={isSubmitting ? 'Saving...' : 'Save'}
                disabled={isSubmitting}
                primary
              />
            </Box>
            <Box direction="row" wrap>
              {false && (
                <Box width="medium" pad="small">
                  <FormField
                    name="name"
                    label="Name"
                    required
                    error={touched.name && errors.name}
                  >
                    <Box
                      pad={{
                        top: 'none',
                        bottom: 'none',
                        left: 'none',
                        right: 'large',
                      }}
                    >
                      <TextInput1
                        pad="none"
                        type="name"
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="name"
                        size="small"
                        value={values.name}
                        disabled={false}
                      />
                    </Box>
                  </FormField>
                </Box>
              )}
              <Box width="medium" pad="small">
                <FormField
                  name="client_type"
                  label="Client Type"
                  required
                  error={touched.client_type && errors.client_type}
                >
                  <Box
                    // border={{color: 'red', size: 'small'}}
                    pad={{
                      top: 'none',
                      bottom: 'none',
                      left: 'none',
                      right: 'large',
                    }}
                  >
                    <SelectWrapper
                      options={['Company', 'Individual']}
                      value={values.client_type}
                      placeholder={'client_type'}
                      onChange={({option}) =>
                        handleClientTypeChange(option, setFieldValue)
                      }
                    />
                  </Box>
                </FormField>
              </Box>
              <Box width="medium" pad="small">
                <FormField
                  name="first_name"
                  label="First Name"
                  required
                  error={touched.first_name && errors.first_name}
                >
                  <Box
                    pad={{
                      top: 'none',
                      bottom: 'none',
                      left: 'none',
                      right: 'large',
                    }}
                  >
                    <TextInput1
                      pad="none"
                      type="first_name"
                      name="first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="First name"
                      size="small"
                      value={values.first_name}
                      disabled={false}
                    />
                  </Box>
                </FormField>
              </Box>
              <Box width="medium" pad="small">
                <FormField
                  name="last_name"
                  label="Last Name"
                  required
                  error={touched.last_name && errors.last_name}
                >
                  <Box
                    pad={{
                      top: 'none',
                      bottom: 'none',
                      left: 'none',
                      right: 'large',
                    }}
                  >
                    <TextInput1
                      pad="none"
                      type="last_name"
                      name="last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Last name"
                      size="small"
                      value={values.last_name}
                      disabled={false}
                    />
                  </Box>
                </FormField>
              </Box>
              <Box
                width="medium"
                pad={{
                  top: 'small',
                  bottom: 'small',
                  left: 'small',
                  right: 'small',
                }}
              >
                <FormField
                  name="phone"
                  label="Phone No."
                  required
                  error={touched.phone && errors.phone}
                >
                  <Box
                    pad={{
                      top: 'none',
                      bottom: 'none',
                      left: 'none',
                      right: 'large',
                    }}
                  >
                    <Box
                      pad={{
                        top: 'none',
                        bottom: 'xxsmall',
                        left: 'none',
                        right: 'none',
                      }}
                    >
                      <PhoneInput
                        containerStyle={{
                          border: '1px solid #b8b8b8',
                          borderTop: 'none',
                          borderLeft: 'none',
                          borderRight: 'none',
                        }}
                        inputStyle={{
                          border: '1px solid #FFFFFF',
                          width: '100%',
                          fontSize: '12px',
                          color: '#000000',
                          height: 'auto',
                        }}
                        country={'au'}
                        value={values.phone}
                        onChange={phone => {
                          setFieldValue('phone', phone)
                        }}
                      />
                    </Box>
                  </Box>
                </FormField>
              </Box>
              <Box width="medium" pad="small">
                <FormField
                  name="email"
                  label="Email"
                  required
                  error={touched.email && errors.email}
                >
                  <Box
                    pad={{
                      top: 'none',
                      bottom: 'none',
                      left: 'none',
                      right: 'large',
                    }}
                  >
                    <TextInput1
                      pad="none"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="white"
                      placeholder="Email Address"
                      size="medium"
                      value={values.email}
                      disabled={false}
                    />
                  </Box>
                </FormField>
              </Box>
              {values.client_type === 'Company' && (
                <Box width="medium" pad="small">
                  <FormField
                    name="company_name"
                    label="Company Name"
                    required
                    error={touched.company_name && errors.company_name}
                  >
                    <Box
                      pad={{
                        top: 'none',
                        bottom: 'none',
                        left: 'none',
                        right: 'large',
                      }}
                    >
                      <TextInput1
                        pad="none"
                        type="company_name "
                        name="company_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="white"
                        placeholder="Company name"
                        size="medium"
                        value={values.company_name}
                        disabled={false}
                      />
                    </Box>
                  </FormField>
                </Box>
              )}
              {values.client_type === 'Company' && (
                <Box width="medium" pad="small">
                  <FormField
                    name="company_ABN"
                    label="Company ABN"
                    required
                    error={touched.company_ABN && errors.company_ABN}
                  >
                    <Box
                      pad={{
                        top: 'none',
                        bottom: 'none',
                        left: 'none',
                        right: 'large',
                      }}
                    >
                      <TextInput1
                        pad="none"
                        type="company_ABN "
                        name="company_ABN"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="white"
                        placeholder="Company ABN"
                        size="medium"
                        value={values.company_ABN}
                        disabled={false}
                      />
                    </Box>
                  </FormField>
                </Box>
              )}{' '}
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        )}
      </Formik>
    </>
  )
}
export default Form
