class clientList {
  constructor(item) {
    const month = {
      1: 'January',
      2: 'February ',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'December',
    }

      const Client_Type_Mapping = {
        Individual: 'IN',
        Company: 'CO',
        INDIVIDUAL: 'IN',
        COMPANY: 'CO',
        IN: 'Individual',
        CO: 'Company',
      }

    this.pk = item.pk
    this.name = item.name
    this.email = item.email
    this.phone = item.phone
    this.title = item.title
    this.review_cycle = month[item.review_cycle]
    this.company_name = item.company_name
    this.company_ABN = item.company_abn
    this.client_type = Client_Type_Mapping[item.client_type]
  }
}

export default clientList
