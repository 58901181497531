import React from 'react'
import {Box, Button, Text, Image, Select} from 'grommet'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SelectWrapper = styled(Select)`
border: 0.5px solid #37236A;
border-radius: 4px;
padding-left: 14px !important;
height: 32px !important;
width: 60px !important;
border: none !important;
font-size: 12px;
color: #000000;
`
const Pagination = ({
  onPageChange,
  pageData: {page = 1, size = 20, total},
  pageSizeOptions,
  showSizeChanger,
  select,
  ...rest
}) => {
  const totalPages = Math.ceil(total / size)
  const [value, setValue] = React.useState(size);
  const selectsize = []
  selectsize[0] = 20
  if (total > 20) 
    selectsize[1] = 50
  if (total > 50 && total <= 1000) {
    for(let i=0;i< Math.ceil(total / 100) ;i++){
      selectsize[i+2] = (i+1)*100
    }
  }
  if (total > 1000) {
    for(let i=0;i< Math.ceil(total / 500) ;i++){
      selectsize[i+2] = (i+1)*500
    }
  }

  const goToPrevPage = () => onPageChange({page: page - 1, size, total})
  const goToNextPage = () => onPageChange({page: page + 1, size, total})
  const goToPage = page => onPageChange({page: page, size, total})

  const hndleup = () => {
    onPageChange({page, size: Math.ceil(size / 5) * 5 + 5, total})
  }
  const hndleDown = () => {
    if (size > 5) {
      onPageChange({page, size: Math.ceil(size / 5) * 5 - 5, total})
    } else {
      onPageChange({page, size: 5, total})
    }
  }
  return (
    <Box direction="row" justify="between" gap="small">
      {showSizeChanger && (
        <Box direction="row" gap="small">
         {!select &&  <Box
            pad="small"
            background="light-1"
            justify="center"
            elevation="medium"
          >
            <Text color="brand">{size}</Text>
          </Box>}
          {/* <Box
            justify="between"
            elevation="medium"
            direction="column"
            pad="small"
            background="light-1"
            style={{background:'#f6f6f6'}}
          > */}
            {!select && (
              <Image
                onClick={hndleDown}
                alt="logo"
                height="10px"
                src={process.env.PUBLIC_URL + '/Images/Up.svg'}
              />
            )}
            {!select && (
              <Image
                onClick={hndleup}
                alt="logo"
                height="10px"
                src={process.env.PUBLIC_URL + '/Images/Down.svg'}
              />
            )}
           {select && total>0 &&  
              <Box 
                gap = "none" 
                border={{color: 'round-border', size: '1px'}} 
                round="4px" 
                style={{boxShadow:'0px 4px 8px rgb(0 0 0 / 20%)', background:'#fff'}}
              >
    
             <SelectWrapper
              options={selectsize}
              plain = 'false'
              value={value}
              onChange={({option}) => {
                onPageChange({page:1, size:option, total})
                setValue(option)}}
            />
            </Box>}
          {/* </Box> */}
        </Box>
      )}

      <Box align="center" direction="row">
        <Box align="center" direction="row" background="light-1">
          <Button
            icon={
              <Image
                alt="logo"
                height="10px"
                src={process.env.PUBLIC_URL + '/Images/Right.svg'}
              />
            }
            onClick={goToPrevPage}
            disabled={page === 1}
          />
          {pagination(page, totalPages).map((item, index) => (
            <Box
              pad="xsmall"
              elevation="small"
              key={index}
              background={index % 2 === 0 ? 'brand-light1' : 'light-1'}
            >
              <Button
                active={item === page}
                margin="xsmall"
                onClick={() => goToPage(item)}
                disabled={item === '...'}
              >
                {item}
              </Button>
            </Box>
          ))}

          <Button
            icon={
              <Image
                alt="logo"
                height="10px"
                src={process.env.PUBLIC_URL + '/Images/Left.svg'}
              />
            }
            onClick={goToNextPage}
            disabled={page === totalPages}
          />
        </Box>
      </Box>
    </Box>
  )
}

function pagination(currentPage, nrOfPages) {
  let pages = [...Array(nrOfPages).keys()].map(item => item + 1)
  const first_start = 2
  const first_count = currentPage - first_start - 1
  if (first_count > 0) {
    pages.splice(first_start - 1, first_count, '...')
  }
  const second_start = currentPage + 2
  const second_count = nrOfPages - second_start
  if (second_count > 0) {
    const start_index = pages.indexOf(currentPage) + 2
    pages.splice(start_index, second_count, '...')
  }
  return pages
}

Pagination.defaultProps = {
  onPageChange: () => {},
  pageData: {},
  showSizeChanger: false,
}

Pagination.propTypes = {
  onPageChange: PropTypes.func,
  pageData: PropTypes.object,
  showSizeChanger: PropTypes.bool,
  pageSizeOptions: PropTypes.array,
}

export default Pagination
