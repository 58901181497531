// Lib imports
// import _ from 'lodash/fp'

// Core imports
// import GraniteError from 'utils/granite-error'
// import {setAuthToken, resetAuthToken} from 'utils/auth-singleton'

// Application imports
import brokerApi from 'brokerDetails/gateways/brokerDetails_api'
import ADD_BROKER_EVENTS from '../../pages/brokerDetails/constants'




// Get Broker Details 
async function getBrokerDetials(eventEmitter, val) {

  try {
    const response = await brokerApi.getBrokerDetails(val)
    eventEmitter.emit(ADD_BROKER_EVENTS.GET_BROKER_SUCCESS, response)
  } catch (e) {
    eventEmitter.emit(ADD_BROKER_EVENTS.GET_BROKER_FAILURE)
  }
}


// ADD Broker Details 
async function addBrokerDetails(val,eventEmitter) {
 
  try {
    const response = await brokerApi.addBrokerDetails(val)
    eventEmitter.emit(ADD_BROKER_EVENTS.ADD_BROKER_SUCCESS, response)
  } catch (e) {
    eventEmitter.emit(ADD_BROKER_EVENTS.ADD_BROKER_FAILURE)
  }
}


// Update Broker Details 
async function updateBrokerDetails(values, eventEmitter) {
 
 
  try {
    const response = await brokerApi.updateBrokerDetails(values)
    eventEmitter.emit(ADD_BROKER_EVENTS.UPDATE_BROKER_SUCCESS, response)
  } catch (e) {
    eventEmitter.emit(ADD_BROKER_EVENTS.UPDATE_BROKER_FAILURE)
  }
}


// Delete Broker Details 
async function deleteBrokerDetails(eventEmitter, val) {
 
  try {
    const response = await brokerApi.deleteBrokerDetails(val)
    eventEmitter.emit(ADD_BROKER_EVENTS.DELETE_BROKER_SUCCESS, response)
  } catch (e) {
    eventEmitter.emit(ADD_BROKER_EVENTS.DELETE_BROKER_FAILURE)
  }
}


export {
  getBrokerDetials,
  addBrokerDetails,
  updateBrokerDetails,
  deleteBrokerDetails,
}
