//Library imports
import React, {useState} from 'react'
import {Box, Image, Text, Button, Layer} from 'grommet'
import styled from 'styled-components'

//Core imports
import Form from './notesForm'

//Application imports
import {
  addClientDetailsNotes,
  deleteClientDetailsNotes,
} from 'clientDetails/controllers/client'

const LayerStyle = styled(Layer)`
  height: 100%;
`

const AddNotesStyle = styled(Layer)``
const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const NotesBox = styled(Box)`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f6f6f6;
  border-radius: 4px;
  opacity: 1;
`

const NotesText = styled(Text)`
  top: 82px;
  left: 1015px;
  width: 322px;
  min-height: 59px;
  text-align: left;
  font: Regular 12px/16px Roboto;
  letter-spacing: 0px;
  color: #212121;
  opacity: 1;
`

const Notes = ({
  layer,
  setLayer,
  notesData,
  event,
  route,
  permissions,
}) => {
  const [view, setView] = useState(true)

  const handleAddNotes = () => {
    setView(!view)
  }

  const editDetail = (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    addClientDetailsNotes(
      event, 
      {...values}, 
      route.params.clientid,
      route, 
      route.params.id ? 'clientLoanDetails' : 'clientDetails'
    )
    setSubmitting(false)
    setView(!view)
  }

  const handleDelete = (event, props) => {
    deleteClientDetailsNotes(event, props)
  }
  
  return (
    <>
      {view && (
        <Box>
          <LayerStyle
            position="right"
            onEsc={() => setLayer(false)}
            onClickOutside={() => setLayer(false)}
          >
            <MoreDetailsWrapper direction="row" justify="between">
              <MoreDetailsTextWrapper
                color="dark-6"
                size="16px"
                margin={{left: '20px', top: '15px', bottom: '15px'}}
              >
                Notes
              </MoreDetailsTextWrapper>

              <Button
                margin={{right: 'small', bottom: 'small'}}
                icon={
                  <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                }
                onClick={() => setLayer(!layer)}
              />
            </MoreDetailsWrapper>

            <Box width="417px" height="100%" overflow="auto">
              <Box
                margin={{
                  top: 'small',
                  left: 'small',
                  right: 'small',
                  bottom: 'small',
                }}
                direction="column"
                gap="medium"
                flex="grow"
                alignSelf="stretch"
              >
                {notesData.map((value, index) => {
                  return (
                    <NotesBox direction="column" gap="medium">
                      <Box
                        width="95%"
                        margin={{
                          top: 'small',
                          left: 'small',
                          right: 'small',
                          bottom: 'small',
                        }}
                      >
                        <NotesText> {value.note} </NotesText>
                      </Box>
                      <Box width="95%" direction="row">
                        <Box
                          width="95%"
                          margin={{
                            top: 'small',
                            left: 'small',
                            right: 'none',
                            bottom: 'none',
                          }}
                          direction="row"
                        >
                          <Text color="dark-5" size="12px">
                            {value.user + ' | '}
                          </Text>
                          <Text color="dark-5" size="12px">
                            {' '}
                            {value.create_date}
                          </Text>
                        </Box>
                        <Box width="5%">
                         { /* // {permissions !== null &&
                         //   permissions.includes('note_') && (   */}
                               { false &&  (
                                <Button
                                size="small"
                                margin={{
                                  top: 'small',
                                  left: 'small',
                                  right: 'none',
                                  bottom: 'none',
                                }}
                                icon={
                                  <Image
                                    width="10px"
                                    height="13px"
                                    src={
                                      process.env.PUBLIC_URL +
                                      '/Images/Delete.png'
                                    }
                                  />
                                }
                                onClick={() => handleDelete(event, value.pk)}
                              />
                            )}
                        </Box>
                      </Box>
                    </NotesBox>
                  )
                })}
              </Box>
            </Box>
            <Box direction="row" justify="between">
              <MoreDetailsTextWrapper
                color="dark-6"
                size="16px"
                margin={{left: '20px', top: '15px', bottom: '15px'}}
              ></MoreDetailsTextWrapper>

              {permissions !== null && permissions.includes('note_add') && (
                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image src={process.env.PUBLIC_URL + '/Images/add.png'} />
                  }
                  onClick={() => handleAddNotes()}
                />
              )}
            </Box>
          </LayerStyle>
        </Box>
      )}

      {!view && (
        <Box>
          <AddNotesStyle
            onEsc={() => setLayer(false)}
            onClickOutside={() => setLayer(false)}
          >
            <Box>
              <MoreDetailsWrapper direction="row" justify="between">
                <MoreDetailsTextWrapper
                  color="dark-6"
                  size="16px"
                  margin={{left: '20px', top: '15px', bottom: '15px'}}
                >
                  Add New Note
                </MoreDetailsTextWrapper>
                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                  }
                  onClick={() => setView(!view)}
                />
              </MoreDetailsWrapper>

              <Box
                margin={{
                  top: 'none',
                  left: 'small',
                  right: 'small',
                  bottom: 'small',
                }}
                direction="column"
              >
                <Form onSubmit={editDetail} />
              </Box>
            </Box>
          </AddNotesStyle>
        </Box>
      )}
    </>
  )
}

export default Notes
