const ADD_CLIENT_EVENTS = {
  GET_CLIENT_SUCCESS: 'CLIENT_SUCCESS',
  GET_CLIENT_FAILURE: 'CLIENT_FAILURE',
  GET_CLIENT_LOAN_DETAILS_SUCCESS: 'GET_CLIENT_LOAN_DETAILS_SUCCESS',
  GET_CLIENT_LOAN_DETAILS_FAILURE: 'GET_CLIENT_LOAN_DETAILS_FAILURE',
  GET_CLIENTS_SUCCESS: 'GET_CLIENTS_SUCCESS',
  GET_CLIENTS_FAILURE: 'GET_CLIENTS_FAILURE',
  GET_CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  SAVE_LOAN_SUCCESS: 'SAVE_LOAN_SUCCESS',
  SAVE_LOAN_FAILURE: 'SAVE_LOAN_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_ADD_LOAN_LOADER: 'SHOW_ADD_LOAN_LOADER',
  HIDE_ADD_LOAN_LOADER: 'HIDE_ADD_LOAN_LOADER',

  ADD_APPLICANT_SUCCESS: 'ADD_APPLICANT_SUCCESS',
  ADD_APPLICANT_FAILURE: 'ADD_APPLICANT_FAILURE',
}

export default ADD_CLIENT_EVENTS
