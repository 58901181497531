import React, {useEffect, useState} from 'react'
import {
  Box,
  Image,
  Text,
  Layer,
  Button,
  TextInput,
  RadioButtonGroup,
} from 'grommet'
import {connect} from 'react-redux'
import styled from 'styled-components'
import UPDATE_EVENTS from './constants'
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'
import * as UserDucks from 'accounts/ducks/user'
// import {getAuthToken} from 'utils/auth-singleton'
import userAPIGateway from 'accounts/gateways/user-api'
import {updateUserProfileInfo} from 'accounts/controllers/user'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import PasswordInput from 'granite/components/PasswordInput'
import PROFILE_UPDATE from 'messages/profile'

let eventEmitter = new EventEmitter()
const UserProfileWrapper = styled(Box)`
  border-color: #37236a;
  border-width: 2px;
  border-top: none;
  border-left: none;
  border-right: none;
`
const RadioButtonGroupWrap = styled(RadioButtonGroup)`
  flex-direction: row;
  row-gap: large;
`
const FormFieldStyle = styled(FormField)`
  color: dark-4;
`
function listenEvents({
  eventEmitter,
  setToastData,
  history,
  setTempPassword,
  dispatch,
  setLayer,
  layer,
  setshow,
  setToastWarning,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case UPDATE_EVENTS.UPDATE_SUCCESS:
        setToastWarning(false)
        setToastData({
          open: true,
          message: PROFILE_UPDATE.PROFILE_POST_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        function fetchUserProfile(dispatch) {
          userAPIGateway
            .myProfile()
            .then(response => {
              dispatch({
                type: 'accounts/user/UPDATE_PROFILE',
                data: response.user,
              })
            })
            .catch(err => {
              console.error(err)
            })
        }
        fetchUserProfile(dispatch)
        // setshow(false)
        break
      case UPDATE_EVENTS.UPDATE_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: PROFILE_UPDATE.PROFILE_POST_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        setLayer(!layer)
        break
      default:
        break
    }
  })
  return subsciption
}

const UserProfile = ({userProfile, history, dispatch, setshow}) => {
  const [layer, setLayer] = useState(true)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [toastWarning, setToastWarning] = useState(false)
  const [visible, setvisible] = useState(false)
  const validationSchema = Yup.object().shape({
    user_name: Yup.string()
      .min(4, 'User Name is too Short')
      .max(21, 'User Name is too long.'),
    new_password: Yup.string()
      .min(2, 'Password is too short - should be 8 chars minimum.')
      .max(21, 'Too Long!')
      .matches(/[a-z]/, 'Must contain 1 small letter')
      .matches(/[A-Z]/, 'Must contain 1 capital Letter')
      .matches(/[0-9]/, 'Must contain 1 Numeric value')
      .matches(/[@$!%*#?&]/, 'Must contain 1 special character'),
    re_password: Yup.string().oneOf(
      [Yup.ref('new_password'), null],
      'Passwords must be same.',
    ),
  })

  const handleVisibiity = () => {
    setvisible(!visible)
  }

  const handleClick = () => {
    setLayer(!layer)
    setshow(false)
  }

  const handleSubmit = async (values, {setSubmitting, setErrors}) => {
    if (values.user_name !== userProfile.name) {
      if (values.user_name.length === 0) {
        setToastWarning(true)
        setToastData({
          open: true,
          message: 'Please enter Name',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
      } else {
        setErrors({})
        setSubmitting(true)
        await updateUserProfileInfo(values, eventEmitter)
        setSubmitting(false)
        setTimeout(() => {
          setshow(false)
        }, 1000)
      }
    } else if (
      values.new_password === values.re_password &&
      values.new_password.length >= 1 &&
      values.re_password.length >= 1 &&
      values.old_password.length >= 1
    ) {
      setErrors({})
      setSubmitting(true)
      await updateUserProfileInfo(values, eventEmitter)
      setSubmitting(false)
      setTimeout(() => {
        setshow(false)
      }, 1000)
    } else if (
      values.new_password === values.re_password &&
      values.old_password.length >= 1
    ) {
      setToastWarning(true)
      setToastData({
        open: true,
        message: 'Please enter New Password and Confirm New Password',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    } else if (
      (values.new_password.length > 0 || values.re_password.length > 0) &&
      values.old_password.length === 0
    ) {
      setToastWarning(true)
      setToastData({
        open: true,
        message: 'Please enter Old Password',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    } else if (
      values.new_password.length === 0 &&
      values.old_password.length > 0
    ) {
      setToastWarning(true)
      setToastData({
        open: true,
        message: 'Please enter New Password',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    } else if (
      values.re_password.length === 0 &&
      values.old_password.length > 0 &&
      values.new_password.length > 0
    ) {
      setToastWarning(true)
      setToastData({
        open: true,
        message: 'Please enter New Confirm Password',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    } else {
      setToastWarning(true)
      setToastData({
        open: true,
        message: 'Please retry,something went wrong!',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 5000)
    }
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        history,
        dispatch,
        setLayer,
        layer,
        setshow,
        setToastWarning,
      })
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [history, dispatch, layer, setshow])

  return (
    <Box>
      {true && (
        <Layer
          onEsc={() => {
            setLayer(!layer)
            setshow(false)
            // window.history.back();
          }}
          onClickOutside={() => {
            setLayer(!layer)
            setshow(false)
            // window.history.back();
          }}
        >
          <Box width="358px" gap="small">
            <Box overflow="auto">
              {/*<UserProfileForm onSubmit={handleSubmit} name={userProfile.name } title = {userProfile.title}/> */}
              <Formik
                initialValues={{
                  title: userProfile.title,
                  user_name: userProfile.name,
                  old_password: '',
                  new_password: '',
                  re_password: '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <UserProfileWrapper
                      direction="row"
                      justify="between"
                      border="10px"
                    >
                      <Text
                        color="dark-4"
                        weight="500"
                        size="14px"
                        margin={{left: 'small', top: 'medium', bottom: 'small'}}
                      >
                        User Profile
                      </Text>
                      <Button
                        margin={{right: 'small', top: 'small', bottom: 'none'}}
                        icon={
                          <Image
                            src={process.env.PUBLIC_URL + 'img/Cross.png'}
                          />
                        }
                        onClick={() => handleClick()}
                      />
                    </UserProfileWrapper>
                    <Box margin={{left: 'medium', right: 'medium'}}>
                      <Box gap="small" pad={{bottom: 'small', top: 'medium'}}>
                        <Text color="dark-4" size="14px" weight="500">
                          Title
                        </Text>
                        <Box direction="row">
                          <RadioButtonGroupWrap
                            gap="large"
                            name="title"
                            direction="row"
                            options={['Mr', 'Ms', 'Other']}
                            value={values.title}
                            onChange={handleChange}
                          />
                        </Box>
                      </Box>

                      <FormField
                        name="user_name"
                        label="Broker Name"
                        error={touched.user_name && errors.user_name}
                      >
                        <TextInput
                          placeholder="Name"
                          type="user_name"
                          name="user_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.user_name}
                        />
                      </FormField>
                      <Box direction="row" gap="small">
                        <Box width="90%">
                          <Text
                            color="dark-4"
                            weight="500"
                            size="16px"
                            margin={{top: 'small', bottom: 'small'}}
                          >
                            Change Password
                          </Text>
                        </Box>
                        <Box width="10%">
                          {!visible && (
                            <Button
                              margin={{
                                right: 'small',
                                bottom: 'small',
                                top: 'small',
                              }}
                              icon={
                                <Image
                                  fit="contain"
                                  onClick={handleVisibiity}
                                  src={
                                    process.env.PUBLIC_URL + '/img/new_down.png'
                                  }
                                />
                              }
                            />
                          )}
                          {visible && (
                            <Button
                              margin={{
                                right: 'small',
                                bottom: 'small',
                                top: 'small',
                              }}
                              icon={
                                <Image
                                  fit="contain"
                                  onClick={handleVisibiity}
                                  src={
                                    process.env.PUBLIC_URL + '/img/new_up.png'
                                  }
                                />
                              }
                            />
                          )}
                        </Box>
                      </Box>
                      {visible ? (
                        <Box
                        // border={{ color: 'brand', size: 'large' }}
                        >
                          <FormFieldStyle
                            name="old_password"
                            label="Old Password"
                            error={touched.old_password && errors.old_password}
                          >
                            <PasswordInput
                              placeholder="Old Password"
                              name="old_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.old_password}
                              eyevisible={false}
                            />
                          </FormFieldStyle>
                          <FormField
                            name="new_password"
                            label="New Password"
                            error={touched.new_password && errors.new_password}
                          >
                            <PasswordInput
                              placeholder="New Password"
                              name="new_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.new_password}
                              eyevisible={false}
                            />
                          </FormField>
                          <FormField
                            name="re_password"
                            label="Confirm New Password"
                            error={touched.re_password && errors.re_password}
                          >
                            <PasswordInput
                              placeholder="Confirm Password"
                              name="re_password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.re_password}
                              eyevisible={false}
                            />
                          </FormField>
                        </Box>
                      ) : (
                        <Box></Box>
                      )}
                      <Box
                        align="end"
                        margin={{bottom: 'medium', top: 'medium'}}
                      >
                        <Button
                          primary
                          label={isSubmitting ? 'Saving...' : 'Save'}
                          disabled={
                            // isSubmitting ||
                            //  !( values.user_name !== userProfile.name) ||
                            //  !(values.old_password.length >= 1)
                            values.user_name !== userProfile.name ||
                            values.old_password.length >= 1
                              ? false
                              : true
                          }
                          type="submit"
                        />
                      </Box>

                      <Text as="div" color="status-critical">
                        {errors.non_field}
                      </Text>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Layer>
      )}
      {toastData.open ? (
        toastWarning ? (
          <Toast
            text={toastData.message}
            background="warning"
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        ) : (
          <Toast
            text={toastData.message}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )
      ) : null}
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
