//Library imports
import React, {useState} from 'react'
import {Box, Text, Image, Button, Select, ThemeContext, CheckBox, Anchor} from 'grommet'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

//Core imports
import Pagination from 'granite/components/Pagination'
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
import ConfirmationLayer from 'granite/components/ConfirmationLayer'

//Application imports
import {
  showMoreDetails,
  getMailTemplateListClosed,
  handleCompletedStatusChanged,
} from 'alr/controllers/loanReview'

const SelectWrapper = styled(Select)`
  border: 0.5px solid #b1b8c0;
  border-radius: 4px;
  padding-left: 14px !important;
  height: 36px;
  border: none !important;
  font-size: 14px;
  color: #000000;
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`
const ButtonStyle = styled(Button)`
  width: auto;
  height: 36px;
`
const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}
const COLUMNS = [
  {
    accessor: 'selection',
    width: "4%",
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'} style={{textAlign: '-webkit-center'}}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'action',
    Header: 'Action',
    width: "10%",
    Cell: ({cell: {value, row, column},setLayer,setParameters, handleMailclicked, handleStatusChanged, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={ 
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters(row.original)
                }}
                label='Gather Loan Details'
              />
          }
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'ation',
    Header: 'View',
    width: "5%",
    Cell: ({cell: {value, row}, handleLoan, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'small'}} align='center'>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  window.open(
                    '/clients/' + row.original.applicant_pk + '/' + row.original.loan_pk,
                  )
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'lender',
    Header: 'Lender',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'name',
    Header: 'Clients',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" gap="small">
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  handleApplicant(value, row.original.loan_pk)
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/more_info.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
              <Box>
                <Text size="14px">{value}</Text>
              </Box>
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
  },
  {
    accessor: 'account_number',
    Header: 'Account No',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'loan_limit',
    Header: 'Loan Limit',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'loan_out_come',
    Header: 'Outcome',
    width: "25%",
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'status',
    Header: 'ALR Status',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
]

const ReviewTable = ({
  eventEmitter,
  loader,
  setMoreDetailLayer,
  setMoreLoader,
  navigateToParams,
  statusValue,
  setStatusValue,
  data: {data, total},
  filterData: {page, page_size: size},
  setshowmailmodal,
  setapplicantsids,
  setclientfirdtname,
  setapplicantsemail,
  setToastData,
}) => {
  const [layer, setLayer] = useState(false)
  const [parameters, setParameters] = useState([])
  const [selected, setSelected] = useState([])
  const [filterImageUrl, setFilterImageUrl] = useState(
    process.env.PUBLIC_URL + '/img/down_arrow.png',
  )
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }

  const handleApplicant = (value, id) => {
    showMoreDetails(eventEmitter, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }

  let location = useLocation()
  const {search} = location

  const getInitialSorting = () => {
    const queryParams = queryString.parse(search)
    if (queryParams['sorting']) {
      let colName
      let desc
      if (queryParams['sorting'].indexOf('-') === 0) {
        colName = queryParams['sorting'].substring(1)
        desc = true
      } else {
        colName = queryParams['sorting'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSortChange = sortOrder => {
    if (!sortOrder.length) navigateToParams({sorting: null})
    else {
      const [{id, desc}] = sortOrder
      navigateToParams({sorting: desc ? '-' + id : id})
    }
  }

  const onConfirm = (parameters) => {
    let selectedRecords = []
    if (selected.length > 0) {
      selectedRecords = selected
    } else if (parameters) {
      selectedRecords = [parameters]
    } else {
      setToastData({
        open: true,
        message: 'Please select loan',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
    handleCompletedStatusChanged(eventEmitter, selectedRecords, 'InProcess')
    setParameters([])
  }

  const handleMailClick = () => {
    if (selected.length === 1) {
      const applicantsid = []
      const applicantsemailid = []
      selected.forEach(item => {
        applicantsemailid.push(item.applicant_email)
        applicantsid.push(item.applicant_pk)
      })
      // setclientfirdtname(selected[0].applicant__name)
      setclientfirdtname(selected[0].first_name)
      setapplicantsemail(applicantsemailid.join(','))
      setapplicantsids(applicantsid)
      getMailTemplateListClosed(
        eventEmitter,
        'CLOSED',
        selected[0].applicant_pk,
      )
      setshowmailmodal(true)
    } else if (selected.length === 0) {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    } else {
      setToastData({
        open: true,
        message: 'Only one client can be selected',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }

  const handleMouseEnter = () => {
    if (statusValue !== '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/search_cancel.png')
    }
  }
  const handleMouseLeave = () => {
    if (statusValue === '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/down_arrow.png')
    }
  }
  const handleFilter = option => {
    navigateToParams({
      status: option === 'All' ? 'All_Completed' : option === 'Completed' ? 'Completed' : 'Rejected',
      search: null,
    })
  }

  return (
    <Box>
      <Box
        gap="large"
        direction="row"
        justify="between"
        width="100%"
        margin={{top: 'medium', bottom: 'medium'}}
      >
        {false && (<Box
          justify="end"
          width="100%"
          gap="medium"
          height="36px"
          direction="row"
        >
          <ButtonStyle
            primary
            disabled={selected.length > 0 ? false : true}
            label="Mail"
            onClick={handleMailClick}
          />
        </Box>
        )}

        <Box gap="medium" direction="row" width="50%">
          <Box
            direction="row"
            background={statusValue ? 'light-1' : 'brand-light1'}
            height="36px"
            border={{color: 'round-border'}}
            round="4px"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <SelectWrapper
              options={['All', 'Completed', 'Rejected']}
              icon={
                <Image
                  src={filterImageUrl}
                  fit="contain"
                  onClick={event => {
                    if (statusValue !== '') {
                      navigateToParams({status: 'All_Completed'})
                      setStatusValue('All_Completed')
                      event.stopPropagation()
                    }
                  }}
                />
              }
              placeholder="Status"
              value={statusValue}
              onChange={({option}) => {
                setStatusValue(option)
                handleFilter(option)
              }}
            />
          </Box>
        </Box>
        <Box align="end" gap="medium" height="36px" direction="row">
          <ButtonStyle
            primary
            label="Gather Loan Details"
            onClick={() => setLayer(true)}
            disabled={selected.length > 0 ? false : true}
          />
        </Box>
      </Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <BoxWrapper overflow={{horizontal: 'auto'}}>
          <Box style={{minWidth: '1250px'}}>
            <ReactTable
              data={data}
              columns={COLUMNS} // pass in our table columns
              setSelected={setSelected}
              initialSortOrder={getInitialSorting()}
              onSortChange={onSortChange}
              handleApplicant={handleApplicant}
              setLayer={setLayer}
              setParameters={setParameters}
            />
          </Box>
        </BoxWrapper>
      )}
      {layer && (
        <ConfirmationLayer 
          setOpen={setLayer}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Gather Loan Details'}
          layerBody={'Are you sure you want to process the loan again?'}
        />
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ReviewTable
