// Lib Imports
import React from 'react'
import { Box, Heading, Button, Text, Image } from 'grommet'
import { Link } from 'react-router-dom'


const ResetPasswordFormSuccess = () => {
  return (
    <>
      <Box
        fill="horizontal"
        direction="column"
        elevation="none"
        pad="medium"
        align="center"
      >
        <Image alignSelf="center" alt="logo" height="50px" src={process.env.PUBLIC_URL + '/Images/success.svg'} />
      </Box>
      <Heading textAlign="center" color="dark-1" level={4} margin={{ bottom: 'medium' }}>
        {"Your password has been updated successfully"}
      </Heading>
      <Link to="/login">
        <Box
          fill="horizontal"
          direction="column"
          elevation="none"
          justify="between"
          pad="small"
        >
          <Button
            type="submit"
            alignSelf="center"
            margin={{ vertical: 'small' }}
            label={'Login'}
            primary
          />
        </Box>
      </Link>
      <Text as="div" color="status-critical">
        {/* {errors.non_field} */}
      </Text>
    </>
  )
}
export default ResetPasswordFormSuccess
