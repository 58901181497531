import campaignApi from 'alr/gateways/campaign-api'
import CAMPAIGN_EVENTS from 'pages/campaign/constants'

async function loadCampaignDetails(eventEmitter, id ) {
  if (id){
           try {
             const response = await campaignApi.getCampaignDetailsWithId(id)
             eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_SUCCESS, response)
             return response
           } catch (err) {
             return false
             // eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
           }
         }
  else{
        try {
          const response = await campaignApi.getCampaignDetails()
          eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_SUCCESS, response)
          return response
        } catch (err) {
          return false
          // eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
        }
      }
 
}

async function saveCampaignClicked(
  monthFull,
  formattedDate,
  monthNumber,
  year,
  eventEmitter,
) {
  try {
    await campaignApi.saveCampaign(monthFull, formattedDate, monthNumber, year)
    eventEmitter.emit(CAMPAIGN_EVENTS.SAVE_CAMPAIGN_SUCCESS)
  } catch (err) {
    eventEmitter.emit(CAMPAIGN_EVENTS.SAVE_CAMPAIGN_FAILURE, err.message)
  }
}

export {loadCampaignDetails, saveCampaignClicked}
