const RESET_MESSAGES = {
  INVALID_CREDENTIALS: 'Please fill the values',
  RESET_SUCCESS: 'Password updated successfully.',
  RESET_FAILURE: 'Something went wrong, OTP could not be sent',
  RESET_TOKEN_SUCCESS: 'OTP submitted successfully',
  RESET_TOKEN_FAILURE: 'Something went wrong, OTP could not be submitted',
  RESET_PASSWORD_SUCCESS: 'New password saved successfully',
  RESET_PASSWORD_FAILURE: 'Reset password failed!',
  RESET_SIGNUP_FAILURE: 'Signup Loading Page Failed!',
}

export default RESET_MESSAGES
