const CLIENT_LOAN_DETAILS_SECURITY_FORM_MESSAGES = {
  // SECURITY FORM
  ADD_SECURITY_POP_UP_HEADING: 'Add Security Address',
  EDIT_SECURITY_POP_UP_HEADING: 'Edit Security Address',
  UPDATE_SECURITY_POP_UP_HEADING: 'Update Security Address',
  DELETE_SECURITY_POP_UP_HEADING: 'Delete Security Address',
  SECURITY_ADDRESS_SAVE: 'Save',
  SECURITY_ADDRESS_SUBMIT: 'Submit',
  SECURITY_ADDRESS_EDIT: 'Edit',
  SECURITY_ADDRESS_DELETE: 'Delete',
  SECURITY_ADDRESS_CANCEL: 'Cancel',
  SECURITY_ADDRESS_DELETE_WARNING_MESSAGE:
    'Are you sure you want to delete the security address.',
  DELETE_SECURITY: 'Delete Security',
  STATE: 'State',
  POSTCODE: 'Postcode',
  ADDRESS_LINE_1: 'Address Line 1',
  ADDRESS_LINE_2: 'Address Line 2',
  CITY: 'City',
  COUNTRY: 'Country',
}

export default CLIENT_LOAN_DETAILS_SECURITY_FORM_MESSAGES
