// Lib Imports
import React, {useState} from 'react'
import {Box, Button, Text, TextInput, Image, RadioButtonGroup} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

// CORE Imports
import FormField from 'granite/components/FormField'
import PasswordInput from 'granite/components/PasswordInput'

const RadioButtonGroupWrap = styled(RadioButtonGroup)`
  flex-direction: row;
  row-gap: large;
`
const FormFieldStyle = styled(FormField)`
  color: dark-4;
`

const UserProfileForm = ({onSubmit, name, title}) => {
  const [visible, setvisible] = useState(false)
  const validationSchema = Yup.object().shape({
    user_name: Yup.string()
      .min(4, 'User Name is too Short')
      .max(21, 'User Name is too long.'),
    // .required('User name is rquired'),
    new_password: Yup.string()
      .min(2, 'Password is too short - should be 8 chars minimum.')
      .max(21, 'Too Long!')
      .matches(/[a-z]/, 'Must contain 1 small letter')
      .matches(/[A-Z]/, 'Must contain 1 capital Letter')
      .matches(/[0-9]/, 'Must contain 1 Numeric value')
      .matches(/[@$!%*#?&]/, 'Must contain 1 special character'),
    // .required('Password is required'),
    re_password: Yup.string().oneOf(
      [Yup.ref('new_password'), null],
      'Passwords must be same.',
    ),
    // .required('Password is required'),
  })

  const handleVisibiity = () => {
    setvisible(!visible)
  }

  return (
    <Formik
      initialValues={{
        title: title,
        user_name: name,
        old_password: '',
        new_password: '',
        re_password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box gap="small">
            <Text color="dark-4" size="14px" weight="500">
              Title
            </Text>
            <Box direction="row">
              <RadioButtonGroupWrap
                gap="large"
                name="title"
                direction="row"
                options={['Mr', 'Ms', 'Other']}
                value={values.title}
                onChange={handleChange}
              />
            </Box>
          </Box>

          <FormField
            name="user_name"
            label="Broker Name"
            error={touched.user_name && errors.user_name}
          >
            <TextInput
              placeholder="Name"
              type="user_name"
              name="user_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.user_name}
            />
          </FormField>
          <Box direction="row" gap="small">
            <Box width="90%">
              <Text
                color="dark-4"
                weight="500"
                size="16px"
                margin={{top: 'small', bottom: 'small'}}
              >
                Change Password
              </Text>
            </Box>
            <Box width="10%">
              {!visible && (
                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image
                      fit="contain"
                      onClick={handleVisibiity}
                      src={process.env.PUBLIC_URL + '/img/down_arrow.png'}
                    />
                  }
                />
              )}
              {visible && (
                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image
                      fit="contain"
                      onClick={handleVisibiity}
                      src={process.env.PUBLIC_URL + '/img/Up.png'}
                    />
                  }
                />
              )}
            </Box>
          </Box>
          {visible ? (
            <Box
            // border={{ color: 'brand', size: 'large' }}
            >
              <FormFieldStyle
                name="old_password"
                label="Old Password"
                error={touched.old_password && errors.old_password}
              >
                <PasswordInput
                  placeholder="Old Password"
                  name="old_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.old_password}
                  eyevisible={false}
                />
              </FormFieldStyle>
              <FormField
                name="new_password"
                label="New Password"
                error={touched.new_password && errors.new_password}
              >
                <PasswordInput
                  placeholder="New Password"
                  name="new_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.new_password}
                  eyevisible={false}
                />
              </FormField>
              <FormField
                name="re_password"
                label="Confirm New Password"
                error={touched.re_password && errors.re_password}
              >
                <PasswordInput
                  placeholder="Confirm Password"
                  name="re_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.re_password}
                  eyevisible={false}
                />
              </FormField>
            </Box>
          ) : (
            <Box></Box>
          )}
          <Box align="end" margin={{bottom: 'medium', top: 'medium'}}>
            <Button primary label="Save" type="submit" />
          </Box>

          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default UserProfileForm
