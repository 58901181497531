import React from 'react'
import {
  Box,
  Button,
  Text,
  TextInput,
  CheckBox,
  Select,
  ThemeContext,
} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
import FormField from 'granite/components/FormField'

const AddApplicantsForm = ({
  onSubmit,
  selected,
  client_type,
  setmarkprimary,
  markprimary,
}) => {
  const ButtonStyle = styled(Button)`
   height:36px
   width:280px
  `
  const bodyValueSelect = {
    select: {
      options: {
        text: {
          size: 'small',
        },
      },
    },
  }

  const SelectWrapper = styled(Select)`
  padding-left: 14px !important;
  height: 29px;
  top: 279px;
  left: 1117px;
  font-size: 12px;
  round:'none'
  borderBottom:'none'
`
  const TextInput1 = styled(TextInput)`
    // borderradius: 0;
    // border-top: none;
    // border-right: none;
    // border-left: none;
    padding-bottom: 4px;
    padding-top: 0px;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    height: 36px;
  `

  const validationSchema = Yup.object().shape({
    type: Yup.string().required('Type Required'),
  })

  return (
    <Box width="100%" background="#FFFFFF" pad="small">
      <Formik
        initialValues={{
          contact_person: '',
          designation: '',
          type: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box direction="row">
              <Box direction="row" gap="small" width="100%">
                <Box direction="row" width="75%" gap="medium">
                  <Box width="25%">
                    <FormField
                      name="type"
                      label="Type"
                      required
                      error={touched.type && errors.type}
                    >
                      <Box
                        pad={{
                          top: 'none',
                          bottom: 'none',
                          left: 'none',
                        }}
                      >
                        <Box margin={{top: 'small'}}>
                          <ThemeContext.Extend value={bodyValueSelect}>
                            <SelectWrapper
                              options={[
                                'BORROWER',
                                'GUARANTOR',
                                'TRUSTEE',
                                'SIGNATORY',
                                'SUPERFUND',
                              ]}
                              value={values.type}
                              placeholder={'Type'}
                              onChange={({option}) =>
                                setFieldValue('type', option)
                              }
                            />
                          </ThemeContext.Extend>
                        </Box>
                      </Box>
                    </FormField>
                  </Box>
                  {client_type === 'Company' && (
                    <Box width="25%">
                      <FormField
                        name="contact_person"
                        label="Contact person"
                        required
                        error={touched.contact_person && errors.contact_person}
                      >
                        <Box>
                          <TextInput1
                            pad="none"
                            type="contact_person"
                            name="contact_person"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            color="dark"
                            placeholder="Contact Person"
                            size="small"
                            value={values.contact_person}
                            disabled={false}
                          />
                        </Box>
                      </FormField>
                    </Box>
                  )}
                  {client_type === 'Company' && (
                    <Box width="25%">
                      <FormField
                        name="designation"
                        label="Designation"
                        required
                        error={touched.designation && errors.designation}
                      >
                        <Box>
                          <TextInput1
                            pad="none"
                            type="designation"
                            name="designation"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            color="dark"
                            placeholder="Designation"
                            size="small"
                            value={values.designation}
                            disabled={false}
                          />
                        </Box>
                      </FormField>
                    </Box>
                  )}{' '}
                </Box>
                <Box width="25%" gap="small">
                  <Box align="end" alignSelf="top">
                    <ButtonStyle
                      primary
                      color={selected.length ? 'brand' : 'text-1'}
                      label={
                        isSubmitting ? 'Saving...' : 'Associate Client to Loan'
                      }
                      disabled={selected.length > 0 ? false : true}
                      type="submit"
                    />
                  </Box>
                  <Box align="center">
                    <CheckBox
                      checked={markprimary}
                      label={
                        <Text size="xsmall" color="brand">
                          {'Make applicant as primary'}
                        </Text>
                      }
                      onChange={event => setmarkprimary(event.target.checked)}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        )}
      </Formik>
    </Box>
  )
}
export default AddApplicantsForm
