// LIB Imports
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Box } from 'grommet'
import EventEmitter from 'utils/event-emitter'
// import * as qs from 'query-string';

// Application Imports
import RequestTokenForm from './components/RequestTokenForm'
import ResetPasswordForm from './components/ResetPasswordForm'
import useQuery from 'hooks/useQuery'
import PiggyImage from 'granite/components/PiggyImage'
import Spinner from 'granite/components/Spinner'
import ErrorPage from 'granite/components/error'
import Toast from 'granite/components/Toast'
import RESET_MESSAGES from 'messages/forgotPassword'
import Steps from 'granite/components/Steps'

//API Imports
import {
  resetPasswordClicked,
  // resetPasswordClickedFP,
  // submitTokenClicked,
  resetPasswordCheck,
} from 'accounts/controllers/user'
import RESET_EVENTS from './constants'

let eventEmitter = new EventEmitter()

function listenEvents({
  eventEmitter,
  setToastData,
  setActiveStep,
  // setUid,
  // history,
  setError,
  setloader,
  setactive,
  setresetPasswordToken,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case RESET_EVENTS.RESET_SUCCESS:
        // setError(false)
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_SUCCESS,
        })
        setTimeout(() => {
          setActiveStep(1)
          setToastData({ open: false, message: '' })
        }, 1000)
        break
      case RESET_EVENTS.RESET_FAILURE:
        // setError(true)
        setToastData({
          open: true,
          message: RESET_MESSAGES.RESET_FAILURE,
        })
        setTimeout(() => {
          setToastData({ open: false, message: '' })
        }, 2000)
        break
      case RESET_EVENTS.RESET_TOKEN_SUCCESS:
        setresetPasswordToken(event.data.data.message)
        break
      case RESET_EVENTS.RESET_TOKEN_FAILURE:
        setloader(false)
        setactive(true)
        break
      default:
        break
    }
  })
  return subsciption
}

const ResetPassword = ({ dispatch, history }) => {
  const defaultActiveStep = 0
  // const [uid, setUid] = useState()
  const [activeStep, setActiveStep] = useState(defaultActiveStep)
  const defaultToastData = { open: false, message: '' }
  const [toastData, setToastData] = useState(defaultToastData)
  // const [emailOrPhone, setEmailOrPhone] = useState({ email: undefined })
  const [loader, setloader] = useState(true)
  const [active, setactive] = useState()
  const [resetPasswordToken, setresetPasswordToken] = useState()
  const queryParams = useQuery()
  const token = queryParams.get('token')
  // const [error, setError] = useState(false)

  const getSteps = () => {
   const resetPasswordFormOnSubmit = (
      values,
      { setSubmitting, setErrors },
    ) => {
      setErrors({})
      setSubmitting(true)
      resetPasswordClicked({ ...values, jwt_token: resetPasswordToken, eventEmitter: eventEmitter })
      setSubmitting(false)
    }


    return [
      {
        content: <RequestTokenForm onSubmit={resetPasswordFormOnSubmit} evnt = {eventEmitter} />,
        icon: <></>,
      },
      {
        content: (<ResetPasswordForm onSubmit={resetPasswordFormOnSubmit} />),
        icon: <></>,
      },
    ]
  }

  useEffect(
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setActiveStep,
        // setUid,
        // history,
        // setError,
        setloader,
        setactive,
        setresetPasswordToken,
        // uid,
        // error,
      })
      // TO CHECK TOKEN AND EMAIL AND GENERATE THE JWT TOKEN
      function checkEmailToken() {
        // const email = queryParams.get('email')
        if (token) {
          resetPasswordCheck({token, eventEmitter})
        } else {
          setloader(false)
          setactive(true)
        }
      }
      checkEmailToken()
      setloader(false)
      return () => subscription.unsubscribe()
    },
    [token],
  )

  return (
    <>
      {loader ? (
        <Box
          direction="row"
          width="100%"
          background=""
          flex="grow"
          justify="center"
          margin="none"
          pad="xlarge"
        >
          <Spinner></Spinner>
        </Box>
      ) : active ? (
        <ErrorPage></ErrorPage>
      ) : (
        <Box
          direction="row"
          width="100%"
          background=""
          flex="grow"
          justify="center"
          margin="none"
        >
          <Box align="center" background="" flex="grow" justify="center">
            <PiggyImage />
          </Box>
          <Box
            align="start"
            background=""
            flex="grow"
            justify="center"
            margin="none"
            pad={{top: 'large', bottom: 'large', left: 'none', right: 'large'}}
          >
            <Box
              width="60%"
              justify="start"
              animation="fadeIn"
              pad={{
                top: 'large',
                bottom: 'large',
                left: 'none',
                right: 'large',
              }}
            >
              <Steps
                margin="xsmall"
                steps={getSteps()}
                activeStep={activeStep}
                onChange={() => false}
                navigationVisible={false}
              />
            </Box>
          </Box>
          {toastData.open && (
            <Toast text={toastData.message} background={toastData.background} />
          )}
        </Box>
      )}
    </>
  )
}
export default withRouter(ResetPassword)
