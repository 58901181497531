//Library imports
import React, {useState} from 'react'
import {Box, Select, Text, Image, Button, ThemeContext, CheckBox, Anchor} from 'grommet'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import styled from 'styled-components'

//Core imports
import Pagination from 'granite/components/Pagination'
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
import ConfirmationLayer from 'granite/components/ConfirmationLayer'

//Application imports
import {
  statusChangedClicked,
  outcomeChangedClicked,
  showMoreDetails,
  getMailTemplateListOfOutcome,
  notifyClientStatusChange,
  // outcomemail,
} from 'alr/controllers/loanReview'
import REVIEW_MESSAGES from 'messages/review'
import REVIEW_EVENTS from 'pages/review/constants'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`
// const SelectWrapperTable = styled(Select)`
//   font-size: 14px;
// `
const ButtonStyle = styled(Button)`
  width: auto;
  height: 36px;
`
const SelectWrapper = styled(Select)`
  border: 0.5px solid #b1b8c0;
  border-radius: 4px;
  padding-left: 14px !important;
  height: 36px;
  border: none !important;
  font-size: 14px;
  color: #000000;
`
// const selectWidthValue = {
//   select: {
//     control: {
//       extend: {
//         width: '100%',
//       },
//     },
//   },
// }
const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}
const COLUMNS = [
  {
    accessor: 'selection',
    width: "4%",
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'} style={{textAlign: '-webkit-center'}}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'loan_status',
    Header: 'Action',
    width: "15%",
    Cell: ({cell: {value, row, column},setLayer,setParameters, handleMailclicked, handleStatusChanged, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            // <SelectWrapperTable
            //   options={['Refinancing', 'Applying New Rate', 'Retention']}
            //   value={value}
            //   onChange={({value}) => {
            //     handleStatusChanged(
            //       value,
            //       rest.row.original.loan_pk,
            //       rest.row.original.pk,
            //     )
            //   }}
            // />
            <>
              <Anchor
                label='Email'
                margin={{right:'xsmall'}}
                onClick={() => {
                  handleMailclicked(row.original)
                }}
              />
              <Text>|</Text>  
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters(row.original)
                }}
                label='Contacted Client'
                margin={{left:'xsmall'}}
              />
            </>
          }
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'ation',
    Header: 'View',
    width: "5%",
    Cell: ({cell: {value, row}, handleLoan, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'small'}} align='center'>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  window.open(
                    '/clients/' + row.original.pk,
                  )
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'name',
    Header: 'Clients',
    Cell: ({
      cell: {value, row},
      handleApplicantClick,
      handleApplicant,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" gap="small">
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  handleApplicant(row.original.loan_pk)
                }}
              >
                {/* <Image
                  src={process.env.PUBLIC_URL + '/img/more_info.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                /> */}
              </Box>
              <Box 
                // onClick={() => handleApplicantClick(rest.row.original)}
              >
                <Text size="14px">{value}</Text>
              </Box>
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
]

const ReviewTable = ({
  eventEmitter,
  loader,
  setMoreDetailLayer,
  setData,
  setMoreLoader,
  navigateToParams,
  statusValue,
  setStatusValue,
  handleApplicantClick,
  setToastData,
  data: {data, total},
  filterData: {page, page_size: size},
  rowsPk,
  setRowsPk,
  setErroredRowPk,
  erroredRowPk,
  campaignId,
  setselectedapplicants,
  setclientfirstname,
  setapplicantids,
  setapplicantsemail,
  setshowmailmodal,
}) => {
  const [layer, setLayer] = useState(false)
  const [parameters, setParameters] = useState([])
  const [selected, setSelected] = useState([])
  let location = useLocation()
  const {search} = location
  // const [rowsPk, setRowsPk] = useState([])
  const [rowError, setRowError] = useState(false)
  // const [erroredRowPk, setErroredRowPk] = useState()
  const [filterImageUrl, setFilterImageUrl] = useState(
    process.env.PUBLIC_URL + '/img/down_arrow.png',
  )

  const handleMouseEnter = () => {
    if (statusValue !== '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/search_cancel.png')
    }
  }

  const handleMouseLeave = () => {
    if (statusValue === '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/down_arrow.png')
    }
  }
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }

  const handleFilter = value => {
    if (value === '--No Changes--') value = '1'
    else if (value === '$!Money saved, no further action') value = '2'
    else if (value === '$!Money saved, Further action required') value = '3'
    else if (value === '!No Money Saved, further action') value = '4'
    navigateToParams({outcome: value, search: null})
  }
  const handleApplicant = id => {
    showMoreDetails(eventEmitter, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }
  const handleStatusChanged = (value, id, pk) => {
    if (selected.length === 0) {
      if (rowsPk.length === 0) {
        setRowError(false)
        statusChangedClicked(eventEmitter, value, id, data, setData, total, pk)
        rowsPk.push(pk)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0 && rowsPk.includes(pk)) {
        setRowError(false)
        statusChangedClicked(eventEmitter, value, id, data, setData, total, pk)
        const index = rowsPk.indexOf(pk)
        rowsPk.splice(index, 1)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0) {
        setRowError(true)
        setErroredRowPk(rowsPk[0])
        setToastData({
          open: true,
          message: REVIEW_MESSAGES.STATUS_OUTCOME_BOTH_WARNING,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 2000)
      }
    }
  }
  const handleOutcomeChanged = (value, id, pk) => {
    if (selected.length === 0) {
      if (rowsPk.length === 0) {
        setRowError(false)
        outcomeChangedClicked(eventEmitter, value, id, data, setData, total, pk)
        rowsPk.push(pk)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0 && rowsPk.includes(pk)) {
        setRowError(false)
        outcomeChangedClicked(eventEmitter, value, id, data, setData, total, pk)
        const index = rowsPk.indexOf(pk)
        rowsPk.splice(index, 1)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0) {
        setRowError(true)
        setErroredRowPk(rowsPk[0])
        setToastData({
          open: true,
          message: REVIEW_MESSAGES.STATUS_OUTCOME_BOTH_WARNING,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 2000)
      }
    }
  }

  // const bulkChanged = value => {
  //   if (selected.length > 0) {
  //     const selectedId = []
  //     selected.map(item => {
  //       selectedId.push(item.pk)
  //       return selectedId
  //     })
  //     const updatedBulkStatus = data.map(item => {
  //       const newItem = {...item}
  //       if (selectedId.includes(newItem.pk)) {
  //         newItem.loan_status = value
  //         return newItem
  //       }
  //       return newItem
  //     })
  //     setData(updatedBulkStatus)
  //     const newData = selected.map(item => {
  //       const newItem = {
  //         id: item.loan_pk,
  //         keys: {status: 'Closed'},
  //       }
  //       return newItem
  //     })
  //     handleBulkStatusChanged(eventEmitter, newData)
  //   }
  // }

  // const bulkOutcomeChanged = value => {
  //   if (selected.length > 0) {
  //     const selectedId = []
  //     selected.map(item => {
  //       selectedId.push(item.pk)
  //     })
  //     const updatedBulkOutcome = data.map(item => {
  //       const newItem = {...item}
  //       if (selectedId.includes(newItem.pk)) {
  //         newItem.loan_out_come = value
  //         return newItem
  //       }
  //       return newItem
  //     })
  //     setData(updatedBulkOutcome)
  //     const newData = selected.map(item => {
  //       const newItem = {
  //         id: item.loan_pk,
  //         keys: {outcome: value},
  //       }
  //       return newItem
  //     })
  //     handleBulkOutcomeChanged(eventEmitter, newData)
  //   }
  // }
  const getInitialSorting = () => {
    const queryParams = queryString.parse(search)
    if (queryParams['sorting']) {
      let colName
      let desc
      if (queryParams['sorting'].indexOf('-') === 0) {
        colName = queryParams['sorting'].substring(1)
        desc = true
      } else {
        colName = queryParams['sorting'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSortChange = sortOrder => {
    if (!sortOrder.length) navigateToParams({sorting: null})
    else {
      const [{id, desc}] = sortOrder
      navigateToParams({sorting: desc ? '-' + id : id})
    }
  }

  const onConfirm = (parameters) => {
    let selectedRecords = []
    if (selected.length > 0) {
      selectedRecords = selected
    } else if (parameters) {
      selectedRecords = [parameters]
    } else {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
    notifyClientStatusChange(eventEmitter, REVIEW_EVENTS, selectedRecords, campaignId, 'Completed')
    setParameters([])
  }

  const handleMailclicked = (selectedData) => {
    if (selected.length > 0) {
      setselectedapplicants(selected)
      const applicantsid = []
      const applicantsemailid = []
      selected.forEach(item => {
        applicantsid.push(item.pk)
        applicantsemailid.push(item.email)
      })
      setclientfirstname(selected[0].first_name)
      setapplicantids(applicantsid)
      setapplicantsemail(applicantsemailid.join(','))
      getMailTemplateListOfOutcome(
        eventEmitter,
        'OUTCOME',
        selected[0].pk,
      )
      setshowmailmodal(true)
    } else if (selectedData) {
      const selectedRows = []
      selectedRows.push(selectedData)
      setselectedapplicants(selectedRows)
      const applicantsid = []
      const applicantsemailid = []
      selectedRows.forEach(item => {
        applicantsid.push(item.pk)
        applicantsemailid.push(item.email)
      })
      setclientfirstname(selectedRows[0].first_name)
      setapplicantids(applicantsid)
      setapplicantsemail(applicantsemailid.join(','))
      getMailTemplateListOfOutcome(
        eventEmitter,
        'OUTCOME',
        selectedRows[0].pk,
      )
      setshowmailmodal(true)
    } else {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }

  return (
    <Box>
      <Box
        gap="large"
        direction="row"
        justify="between"
        width="100%"
        margin={{top: 'medium', bottom: 'medium'}}
      >
        <Box gap="medium" direction="row" width="50%">
          {false && (<Box
            direction="row"
            background={statusValue ? 'light-1' : 'brand-light1'}
            height="36px"
            border={{color: 'round-border'}}
            round="4px"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <SelectWrapper
              options={[
                'All',
                '$!Money saved, no further action',
                '$!Money saved, Further action required',
                '!No Money Saved, further action',
                '--No Changes--',
              ]}
              icon={
                <Image
                  src={filterImageUrl}
                  fit="contain"
                  onClick={event => {
                    if (statusValue !== '') {
                      navigateToParams({status: null})
                      setStatusValue('')
                      event.stopPropagation()
                    }
                  }}
                />
              }
              placeholder="Status"
              value={statusValue}
              onChange={({option}) => {
                setStatusValue(option)
                handleFilter(option)
              }}
            />
          </Box>
          )}
        </Box>
        <Box align="end" gap="medium" height="36px" direction="row">
          <ButtonStyle
            primary
            label="Email"
            onClick={handleMailclicked}
            disabled={selected.length > 0 ? false : true}
          />
          <ButtonStyle
            primary
            label="Contacted Client"
            onClick={() => setLayer(true)}
            disabled={selected.length > 0 ? false : true}
          />
        </Box>
      </Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <BoxWrapper overflow={{horizontal: 'auto'}}>
          <Box style={{minWidth: '1250px'}}>
            <ReactTable
              data={data}
              columns={COLUMNS} // pass in our table columns
              setSelected={setSelected} // sets selected row information
              initialSortOrder={getInitialSorting()}
              onSortChange={onSortChange}
              rowError={rowError}
              erroredRowPk={erroredRowPk}
              handleApplicant={handleApplicant}
              handleApplicantClick={handleApplicantClick}
              handleOutcomeChanged={handleOutcomeChanged}
              handleStatusChanged={handleStatusChanged}
              handleMailclicked={handleMailclicked}
              setLayer={setLayer}
              setParameters={setParameters}
            />
          </Box>
        </BoxWrapper>
      )}
      {layer && (
        <ConfirmationLayer 
          setOpen={setLayer}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Contacted Client'}
          layerBody={'Are you sure you want to mark the client as contacted?'}
        />
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ReviewTable
