//Library imports
import React, {useState} from 'react'
import {Box, CheckBox, Select, Text, Image, ThemeContext, Button, Anchor} from 'grommet'
import styled from 'styled-components'
import queryString from 'query-string'
import {useLocation} from 'react-router-dom'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'
import LoaderUI from 'granite/components/NumLoader'
import ConfirmationLayer from 'granite/components/ConfirmationLayer'

//Application imports
import {
  showMoreDetails,
  handleContactingBulkStatusChanged,
  updateRate,
  saveNewDiscount,
  updateContactingStatus,
  getMailTemplateList,
  notifyClientStatusChange,
} from 'alr/controllers/loanReview'
import CONTACTING_MESSAGES from 'messages/contacting'
import CONTACTING_EVENTS from 'pages/contacting/constants'

// const TextWrapper = styled(Text)`
//   opacity: 1;
// `

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  overflow-x: auto;
`

// const SelectWrapperTable = styled(Select)`
//   font-size: 14px;
// `
const SelectWrapper = styled(Select)`
  border: 0.5px solid #b1b8c0;
  border-radius: 4px;
  padding-left: 14px !important;
  height: 36px;
  border: none !important;
  font-size: 14px;
  color: #000000;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: auto;
`

const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}

const COLUMNS = [
  {
    accessor: 'selection',
    width: "4%",
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'} style={{textAlign: '-webkit-center'}}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'status',
    Header: 'Action',
    width: "15%",
    Cell: ({
      cell: {value, row, column},
      bulkChanged,
      handleStatusChanged,
      handlemailclicked,
      setLayer,
      setParameters,
      ...rest
    }) => {
      return (
        <TextCell
          color="dark-6"
          {...rest}
          data={
            // <SelectWrapperTable
            //   options={['In Review', 'Contacting']}
            //   value={value === 'InReview' ? 'In Review' : value}
            //   onChange={({option}) => {
            //     bulkChanged(option)
            //     handleStatusChanged(option, row.original.pk)
            //   }}
            // />
            <>
              <Anchor
                label='Email'
                margin={{right:'xsmall'}}
                onClick={() => {
                  handlemailclicked(row.original)
                }}
              />
              <Text>|</Text>  
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters(row.original)
                }}
                label='Notified Client'
                margin={{left:'xsmall'}}
              />
            </> 
          }
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'ation',
    Header: 'View',
    width: "5%",
    Cell: ({cell: {value, row}, handleLoan, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'small'}} align='center'>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  window.open(
                    '/clients/' + row.original.pk,
                  )
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'name',
    Header: 'Clients',
    Cell: ({
      cell: {value, row},
      handleApplicant,
      handleApplicantClick,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" gap="small">
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  handleApplicant(row.original.loan_pk)
                }}
              >
                {/* <Image
                  src={process.env.PUBLIC_URL + '/img/more_info.png'}
                  fit="contain"
                /> */}
              </Box>
              <Box 
                // onClick={() => handleApplicantClick(rest.row.original)}
              >
                <Text size="14px">{value}</Text>
              </Box>
            </Box>
          }
          editMode={false}
          style={{cursor: 'pointer'}}
          color="dark-6"
        />
      )
    },
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'phone',
    Header: 'Phone',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
]

const ContactingTable = ({
  setData,
  setMoreDetailLayer,
  handleApplicantClick,
  eventEmitter,
  loader,
  setMoreLoader,
  navigateToParams,
  statusValue,
  setStatusValue,
  setToastData,
  risk,
  data: {data, total},
  filterData: {page, page_size: size},
  campaignId,
  setselectedapplicants,
  setclientfirstname,
  setapplicantids,
  setapplicantsemail,
  setshowmailmodal,
}) => {
  const [layer, setLayer] = useState(false)
  const [parameters, setParameters] = useState([])
  const [selected, setSelected] = useState([])
  const [filterImageUrl, setFilterImageUrl] = useState(
    process.env.PUBLIC_URL + '/img/down_arrow.png',
  )
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }
  const handleFilter = option => {
    navigateToParams({
      status: option === 'In Review' ? 'InReview' : option,
      search: null,
    })
  }
  const handleStatusChanged = (value, id) => {
    if (selected.length === 0) {
      updateContactingStatus(eventEmitter, id, value, data, setData, total)
    }
  }

  const handleApplicant = id => {
    showMoreDetails(eventEmitter, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }

  let location = useLocation()
  const {search} = location

  const getInitialSorting = () => {
    const queryParams = queryString.parse(search)
    if (queryParams['sorting']) {
      let colName
      let desc
      if (queryParams['sorting'].indexOf('-') === 0) {
        colName = queryParams['sorting'].substring(1)
        desc = true
      } else {
        colName = queryParams['sorting'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSortChange = sortOrder => {
    if (!sortOrder.length) navigateToParams({sorting: null})
    else {
      const [{id, desc}] = sortOrder
      navigateToParams({sorting: desc ? '-' + id : id})
    }
  }

  const bulkChanged = value => {
    if (selected.length > 0) {
      handleContactingBulkStatusChanged(
        eventEmitter,
        selected,
        value,
        data,
        setData,
        total,
      )
    }
  }

  const updateCellData = (rowId, column, value) => {
    if (statusValue === 'Rejected') {
      setToastData({
        open: true,
        message: CONTACTING_MESSAGES.REJECTED_NEW_INTEREST_CHANGE,
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 2000)
    } else {
      if (column === 'new_rate') {
        const newInterest = parseFloat(value).toFixed(2)
        const newData = data.map(item => {
          const newItem = {...item}
          if (item.pk === rowId) {
            newItem.new_rate = newInterest
            newItem.rate.difference = (
              newInterest - risk[newItem.rate.selected_risk]
            ).toFixed(2)
            if (!isNaN(newInterest)) {
              const data = {
                new_rate: parseFloat(newInterest),
              }
              updateRate(eventEmitter, item.pk, data)
            } else if (isNaN(newInterest)) {
              newItem.new_rate = ''
              newItem.loan_rate.difference = undefined
            }
            return newItem
          }
          return newItem
        })
        setData({data: newData, total})
      } else if (column === 'new_discount') {
        const newDiscount = parseFloat(value)
        let currentDiscount
        const newData = data.map(item => {
          const newItem = {...item}
          if (item.pk === rowId) {
            newItem.new_discount = newDiscount
            currentDiscount = parseFloat(newItem.discount.current_discount)
            newItem.discount.difference = (
              newDiscount - currentDiscount
            ).toFixed(1)
            if (!isNaN(newDiscount)) {
              const data = {
                new_discount: newDiscount,
              }
              saveNewDiscount(eventEmitter, item.loan_pk, data)
            } else if (isNaN(newDiscount)) {
              newItem.newDiscount = ''
              newItem.discount.current_discount = 0
            }
            return newItem
          }
          return newItem
        })
        setData({data: newData, total})
      }
    }
  }

  const handleMouseEnter = () => {
    if (statusValue !== '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/search_cancel.png')
    }
  }

  const handleMouseLeave = () => {
    if (statusValue === '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/down_arrow.png')
    }
  }
  const onConfirm = (parameters) => {
    let selectedRecords = []
    if (selected.length > 0) {
      selectedRecords = selected
    } else if (parameters) {
      selectedRecords = [parameters]
    } else {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
    notifyClientStatusChange(eventEmitter, CONTACTING_EVENTS, selectedRecords, campaignId, 'Pricing Requested')
    setParameters([])
  }
  const handlemailclicked = (selectedData) => {
    // console.log('setselectedapplicants', setselectedapplicants)
    // console.log('Selected is ', selected)
    if (selected.length > 0) {
      setselectedapplicants(selected)
      const applicantsid = []
      const applicantsemailid = []
      selected.forEach(item => {
        applicantsid.push(item.pk)
        applicantsemailid.push(item.email)
      })
      setclientfirstname(selected[0].first_name)
      setapplicantids(applicantsid)
      setapplicantsemail(applicantsemailid.join(','))
      getMailTemplateList(
        eventEmitter,
        'LOAN_REVIEW_NEW',
        selected[0].pk,
      )
      setshowmailmodal(true)
    } else if (selectedData) {
      const selectedRows = []
      selectedRows.push(selectedData)
      setselectedapplicants(selectedRows)
      const applicantsid = []
      const applicantsemailid = []
      selectedRows.forEach(item => {
        applicantsid.push(item.pk)
        applicantsemailid.push(item.email)
      })
      setclientfirstname(selectedRows[0].first_name)
      setapplicantids(applicantsid)
      setapplicantsemail(applicantsemailid.join(','))
      getMailTemplateList(
        eventEmitter,
        'LOAN_REVIEW_NEW',
        selectedRows[0].pk,
      )
      setshowmailmodal(true)
    } else {
      setToastData({
        open: true,
        message: 'Please select client',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 1000)
    }
  }
  return (
    <Box>
      <Box
        gap="large"
        direction="row"
        justify="between"
        width="100%"
        margin={{top: 'medium', bottom: 'medium'}}
      >
        <Box gap="medium" direction="row" width="50%">
          {false && (<Box
            direction="row"
            background={statusValue ? 'light-1' : 'brand-light1'}
            height="36px"
            border={{color: 'round-border'}}
            round="4px"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <SelectWrapper
              options={['All', 'In Review', 'Contacting', 'Rejected']}
              icon={
                <Image
                  src={filterImageUrl}
                  fit="contain"
                  onClick={event => {
                    if (statusValue !== '') {
                      navigateToParams({status: null})
                      setStatusValue('')
                      event.stopPropagation()
                    }
                  }}
                />
              }
              placeholder="Status"
              value={statusValue === 'InReview' ? 'In Review' : statusValue}
              onChange={({option}) => {
                setStatusValue(option)
                handleFilter(option)
              }}
            />
          </Box>
          )}
        </Box>
        <Box align="end" height="36px" gap="medium" direction="row">
          <ButtonStyle
            primary
            label="Email"
            onClick={handlemailclicked}
            disabled={selected.length > 0 ? false : true}
          />
          <ButtonStyle
            primary
            label="Notified Client"
            onClick={() => setLayer(true)}
            disabled={selected.length > 0 ? false : true}
          />
        </Box>
      </Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <BoxWrapper overflow={{horizontal: 'auto'}}>
          <Box style={{minWidth: '1250px'}}>
            <ReactTable
              data={data}
              columns={COLUMNS} // pass in our table columns
              setSelected={setSelected} // sets selected row information
              updateCellData={updateCellData}
              initialSortOrder={getInitialSorting()}
              onSortChange={onSortChange}
              bulkChanged={bulkChanged}
              handleApplicantClick={handleApplicantClick}
              statusValue={statusValue}
              handleApplicant={handleApplicant}
              handleStatusChanged={handleStatusChanged}
              risk={risk}
              handlemailclicked={handlemailclicked}
              setLayer={setLayer}
              setParameters={setParameters}
            />
          </Box>
        </BoxWrapper>
      )}
      {layer && (
        <ConfirmationLayer 
          setOpen={setLayer}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Notified Client'}
          layerBody={'Are you sure you want to mark the client as notified?'}
        />
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ContactingTable
