function AUDITS_FILTER(value, data_type) {

  const Client_Type_Mapping = {
    Individual: 'IN',
    Company: 'CO',
    INDIVIDUAL: 'IN',
    COMPANY: 'CO',
    IN: 'Individual',
    CO: 'Company',
  }
  const OutcomeVAlues = {
    1: '--No Changes--',
    2: '$!Money saved, no further action',
    3: '$!Money saved, further action required',
    4: '$!No money saved, further action required',
  }

  var temp_object = {}

  for (const key in value) {
    if (key !== 'security') {
      if (key !== 'applicant') {
        let capital_key = key => {
          return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase()
        }
        temp_object[capital_key(key).replace('_', ' ')] = value[key]
      }
      if (key === 'client_type') {
        let capital_key = key => {
          return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase()
        }
        temp_object[capital_key(key).replace('_', ' ')] =
          Client_Type_Mapping[value[key]]
      }
      if (key === 'out_come') {
        let capital_key = key => {
          return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase()
        }
        temp_object[capital_key(key).replace('_', ' ')] =
          OutcomeVAlues[value[key]]
      }
      if (key === 'package') {
        let capital_key = key => {
          return key.charAt(0).toUpperCase() + key.substr(1).toLowerCase()
        }
        if (value[key]) {
          temp_object[capital_key(key).replace('_', ' ')] = 'Yes'
        } else if (!value[key]) {
          temp_object[capital_key(key).replace('_', ' ')] = 'No'
        }
      }
      if (key === 'Email Sent' && data_type === 'old_data') {
        delete value['Email Sent']
        delete temp_object['Email sent']
      }
    }
  }

  return temp_object
}

export {AUDITS_FILTER}
