// Core imports
import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'

// Application imports
import Risk from 'accounts/entities/risk'

async function riskRate() {
  try {
    const response = await GraniteAPIClient().get('/alr/risk_setting/')
    const risk = new Risk(response.data)
    return risk
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }

}

async function getCampaignRiskRate(id) {
  try {
    const response = await GraniteAPIClient().get(
      '/alr/campaign-risk_setting/' + id + '/',
    )
    const risk = new Risk(response.data)
    return risk
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }

}

async function saveRisk(risk, riskRate1, riskRate2, riskRate3, riskRate4) {
  const payload = {
    pk: risk.pk,
    organisation_id: risk.organisation_id,
    oop_and_i: parseFloat(riskRate1),
    oo_io: parseFloat(riskRate2),
    inv_p_and_i: parseFloat(riskRate3),
    inv_io: parseFloat(riskRate4),
  }
  try {
    const response = await GraniteAPIClient().post(
      '/alr/risk_setting/' + 1 + '/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }

}

export default {
  riskRate,
  saveRisk,
  getCampaignRiskRate,
}
