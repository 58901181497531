import React, {useState} from 'react'
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import {Box, Image} from 'grommet'
import {deleteBrokerDetails} from 'brokerDetails/controllers/brokerDetails'
// import ADD_USER_EVENTS from '../constants'
import styled from 'styled-components'
import ActiveInactiveWarningPopUp from '../components/activeInactivepopUp'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

const TableWrapper = styled(Box)`
  overflow-x: auto;
`
const COLUMNS = [
  {
    accessor: 'bank',
    Header: 'Bank',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },

  {
    // align: 'left',
    accessor: `a_code`,
    Header: 'Donna Broker Code',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },

  {
    accessor: 't_code',
    Header: "Mark Broker Code",
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} />
    },
  },
  {
    accessor: 'm_code',
    Header: "Peter Broker Code",
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} />
    },
  },
  {
    accessor: '',
    Header: 'Action',
    Cell: ({cell: {value}, handleApplicantClick, handleDelete, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" align="center">
              <Box width="100%" align="center" direction="row">
                <Box
                  width="50%"
                  align="end"
                  // border={{color: 'red', size: 'small'}}
                >
                  <Image
                    margin={{left: 'small'}}
                    width="20px"
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                     
                      handleApplicantClick(rest.row.original, 'EDIT')
                    }}
                    src={process.env.PUBLIC_URL + '/img/edit.png'}
                  />
                </Box>
                <Box
                  width="50%"
                  // border={{color: 'red', size: 'small'}}
                >
                  <Image
                    margin={{left: 'small'}}
                    width="15px"
                    style={{cursor: 'pointer'}}
                    onClick={
                      () => {
                    
                        handleDelete(rest.row)
                      }
                      // handleActive(rest.row.original)
                      // handleApplicantClick(rest.row.original, 'EDIT')
                    }
                    src={process.env.PUBLIC_URL + '/Images/Delete.png'}
                  />
                </Box>
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },

  // { accessor: 'is_active',
  //   Header: 'Delete',
  //   Cell: ({cell: {value}, handleActive, ...rest}) => {
  //     return (
  //       <TextCell
  //         {...rest}
  //         data={
  //           <Box>
  //             <Box direction="row" gap="medium" align="center">
  //               <Box width="100%" align="center">
  //                 <Image
  //                   width="30px"
  //                   onClick={() => handleActive(rest.row.original)}
  //                   style={{cursor: 'pointer'}}
  //                   src={
  //                     process.env.PUBLIC_URL +
  //                     (rest.row.original.is_active
  //                       ? '/img/active.png'
  //                       : '/img/inactive.png')
  //                   }
  //                 />
  //               </Box>
  //             </Box>
  //           </Box>
  //         }
  //         editMode={false}
  //         size="14px"
  //         color="dark-6"
  //       />
  //     )
  //   },
  // },
]

const UserTable = ({
  tableData,
  event,
  handleEditButton,
  selectedUser,
  setEditLayer,
  editLayer,
  userProfile,
  setLoader,
  setcustomisedMessage,
  navigateToParams,
}) => {
  const [warningpopupvisibility, setwarningpopupvisibility] = useState(false)
  // const [markInactive, setmarkInactive] = useState(false)
  const location = useLocation()
  // const [warningmessage, setwarningmessage] = useState('')
  // const [makeinactivedata, setmakeinactivedata] = useState()
  // const [btnlabel, setbtnlabel] = useState('')

  const handleDelete = props=>{
    // console.log("I am delete clicked",props)
    // console.log('Key', props.original.pk)
    deleteBrokerDetails(event, props.original.pk)
  }
  const handleActive = props => {

    // deleteUser()
    //  if (userProfile.loggedinUserId !== props.pk) {
    //    if (!props.is_active) {
    //      navigateToParams({active: true})
    //      setwarningmessage(
    //        ' Are you sure you want to mark ' +
    //          props.name +
    //          ' active, the user can access the IndigoHub ?',
    //      )
    //      setcustomisedMessage(props.name + ' marked active successfully')
    //      setbtnlabel('Active')
    //      setwarningpopupvisibility(true)
    //      setmakeinactivedata(props)
    //    } else {
    //      navigateToParams({active: false})
    //      setwarningmessage(
    //        ' Are you sure you want to mark ' +
    //          props.name +
    //          ' inactive, the user can no longer login into IndigoHub ?',
    //      )
    //      setcustomisedMessage(props.name + ' marked inactive successfully')
    //      setbtnlabel('Inactive')
    //      setwarningpopupvisibility(true)
    //      setmakeinactivedata(props)
    //    }
    //  } else {
    //    event.emit(ADD_USER_EVENTS.LOGGEINUSER_ACTIVE_FAILURE)
    //  }
  }

  const handleApplicantClick = (props, type) => {
    if (type === 'EDIT') {
      // let designation
      // tableData.forEach(item => {
      //   if (item.pk === props.pk) {
      //     designation = item.designation
      //   }
      // })
   
      selectedUser(props)
      setEditLayer(!editLayer)
    } 
  }

  // let tempRoles = ''
  // tableData.forEach(element => {
  //   tempRoles = ''
  //   element.role.forEach(item => {
  //     tempRoles = tempRoles + ' ' + item.name
  //   })
  //   element['roles_Value'] = tempRoles
  // })

  const [data, setData] = useState([])

  // const updateData = data =>
  //   setData(
  //     data.map(row => {
  //       row.actions = [
  //         {name: 'edit', onClick: () => alert('edit clicked')},
  //         {name: 'delete', onClick: () => alert('delete clicked')},
  //       ]
  //       return row
  //     }),
  //   )

  const updateCellData = (rowId, column, value) => {
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  const handlecolumnClick = cell => {}

  // const handleSort = props => {
  //   const col = props[0].id
  //   const parms = {}
  //   props[0].desc ? (parms['sorting'] = '-' + col) : (parms['sorting'] = col)
  // }

  const handleSort = sortOrder => {
    if (!sortOrder.length) navigateToParams({ordering: null})
    else {
      const [{id, desc}] = sortOrder
      setLoader(true)
      navigateToParams({ordering: desc ? '-' + id : id})
    }
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }
  return (
    <>
      <TableWrapper>
        <ReactTable
          initialSortOrder={getInitialSorting()}
          data={tableData}
          columns={COLUMNS}
          // onSortChange={e => handleSort(e)}
          onSortChange={handleSort}
          updateCellData={updateCellData}
          rowClickHandler={row => {}}
          columnClickHandler={cell => handlecolumnClick(cell)}
          handleActive={handleActive}
          handleDelete = {handleDelete}
          handleApplicantClick={handleApplicantClick}
        />
      </TableWrapper>
      {warningpopupvisibility && (
        <ActiveInactiveWarningPopUp
          layer={warningpopupvisibility}
          setLayer={setwarningpopupvisibility}
          // setmarkInactive={markInactive}
          // makeinactivedata={makeinactivedata}
          // setmarkInactive={setmarkInactive}
          // warningmessage={warningmessage}
          // btnlabel={btnlabel}
          event={event}
          setLoader={setLoader}
        />
      )}
    </>
  )
}

export default UserTable
