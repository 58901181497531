const RESET_EVENTS = {
  RESET_START: 'RESET_START',
  RESET_SUCCESS: 'RESET_SUCCESS',
  RESET_FAILURE: 'RESET_FAILURE',
  RESET_TOKEN_SUCCESS: 'RESET_TOKEN_SUCCESS',
  RESET_TOKEN_FAILURE: 'RESET_TOKEN_FAILURE',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  GET_PROFILE_START: 'GET_PROFILE_START',
  GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
  GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
  SIGNUP_SUCCESS: 'SIGNUP LANDING SUCCESS',
  SIGNUP_FAILURE: 'SIGNUP LANDING SUCCESS',
  SIGNUP_SUBMIT_SUCCESS: 'SIGNUP SUBMIT SUCCESS',
  SIGNUP_SUBMIT_FAILURE: 'SIGNUP SUBMIT FAILURE',
}

export default RESET_EVENTS
