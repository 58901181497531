import _ from 'lodash/fp'
import GraniteError from 'utils/granite-error'

class User {
  constructor({title, username,name, email, ...data}) {
    if (_.isNil(username) || !_.isString(username)) {
      throw new GraniteError('Userrname must be valid string')
    }
    this.title = title || ''
    this.username = username
    this.name= name || ''
    this.email = email || ''
    this.organisation_id = data.organisation_id
    this.loggedinUserId = data.loggedinUserId
    this.designation = data.designation
  }
}

export default User
