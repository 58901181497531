const CLOSED_MESSAGES = {
  GET_LOAN_DETAILS_SUCCESS: 'Successfully fetched user!',

  GET_LOAN_DETAILS_FAILURE: 'Failure',

  SEND_MAIL_SUCCESS: 'Email sent successfully!',

  SEND_MAIL_FAILURE:
    "We're sorry, something went wrong or you don’t have permission to perform this action",
  
  STATUS_SAVE_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",  
}
export default CLOSED_MESSAGES
