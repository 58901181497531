import React, {useState} from 'react'
import {Box, Image} from 'grommet'

const PiggyImage = ({basis, fill, justify, ...rest}) => {
  const [image, setImage] = useState(
    process.env.PUBLIC_URL + '/img/Indigo-ALR-Animation.gif',
  )

  const handleMouseOver = () => {
    setImage(process.env.PUBLIC_URL + '/img/Indigo-ALR-Animation.gif')
  }

  const handlelMouseOut = () => {
    setImage(process.env.PUBLIC_URL + '/img/piggy_image.png')
  }

  return (
    <Box basis={basis} fill={fill} justify={justify} {...rest}>
      <Image
        width="450"
        alignSelf="center"
        src={image}
        onMouseOver={() => handleMouseOver()}
        onMouseOut={() => handlelMouseOut()}
      />
    </Box>
  )
}

export default PiggyImage
