const DASHBOARD_MESSAGES = {
    LIST_GET_SUCCESS: 'SUCCESS',
    LIST_GET_FAILURE: 'Oops!!! Something, Somewhere, Shomehow went wrong...',

    GET_PANELS_SUCCESS: 'SUCCESS',
    GET_PANELS_FAILURE: 'Oops!!! Something, Somewhere, Shomehow went wrong...',

    GET_PAGINATION_SUCCESS: 'SUCCESS',
    GET_PAGINATION_FAILURE: 'Oops!!! Something, Somewhere, Shomehow went wrong...',
    
    PANELS_NEW:             'New',
    PANELS_IN_REVIEW:       'In Review',
    PANELS_CONTACTING:      'Contacting',
    PANELS_CLOSED:          'Completed',
    PANELS_NEEDS_ATTENTION: 'Needs Attention',
   
    HEADING_OVERDUES : "Overdue ALRs",
    HEADING_CURRENT_MONTH: "Current Months ALRs",
    }

export default DASHBOARD_MESSAGES