import React from 'react'
import {Box, TextInput, Button, Text, Heading} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import SIGNUP_MESSAGES from 'messages/signup'
// import useQuery from 'hooks/useQuery'

const SignupForm = ({onSubmit,email}) => {
  // const queryParams = useQuery()
  // const newPath = `${path}?token=861351&email=shobhit.bhatnagar@thoughts2binary.com`
  // const token = queryParams.get('token')
  // const email = queryParams.get('email')
  // const emailid = email;
  const validationSchema = Yup.object().shape({
    employee_name: Yup.string()
      .min(5, SIGNUP_MESSAGES.EMPLOYEE_NAME_TOO_SHORT)
      .max(20, SIGNUP_MESSAGES.EMPLOYEE_NAME_TOO_LONG)
      .required(SIGNUP_MESSAGES.EMPLOYEE_NAME_REQUIRED),
    email: Yup.string()
      .min(2, SIGNUP_MESSAGES.EMAIL_TOO_SHORT)
      .max(50, SIGNUP_MESSAGES.EMAIL_TOO_LONG)
      .email(SIGNUP_MESSAGES.EMAIL_INVALID)
      .required(SIGNUP_MESSAGES.EMAIL_REQUIRED),
  })

  return (
    <Formik
      initialValues={{
        employee_name: '',
        email: email,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          {/** FIXME: Move strings to messages */}
          <Heading color="brand" level={1} margin={{bottom: 'small'}}>
            SignUp
          </Heading>
          <FormField
            name="employee_name"
            label={SIGNUP_MESSAGES.EMPLOYEE_NAME_LABEL}
            error={touched.employee_name && errors.employee_name}
          >
            <TextInput
              placeholder={SIGNUP_MESSAGES.EMPLOYEE_NAME_PLACEHOLDER}
              type="employee_name"
              name="employee_name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.employee_name}
            />
          </FormField>
          <FormField
            name="email"
            label={SIGNUP_MESSAGES.EMAIL_LABEL}
            error={touched.email && errors.email}
          >
            <TextInput
              placeholder={SIGNUP_MESSAGES.EMAIL_PLACEHOLDER}
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              disabled
            />
          </FormField>
          <Box
            fill="horizontal"
            direction="column"
            elevation="none"
            pad="none"
            gap="xsmall"
          >
            <Button
              type="submit"
              alignSelf="end"
              margin={{vertical: 'small'}}
              label={
                isSubmitting
                  ? SIGNUP_MESSAGES.SUBMIT_BUTTON_LABEL_SUBMITTING
                  : SIGNUP_MESSAGES.SUBMIT_BUTTON_LABEL
              }
              disabled={isSubmitting}
              primary
            />
          </Box>

          <Text as="div" color="status-critical">
            {errors.non_field}
          </Text>
        </form>
      )}
    </Formik>
  )
}

export default SignupForm
