class LoanBook {
  constructor({
    create_fail_count,
    create_success_count,
    import_status,
    original_file_name,
    pk,
    total_row_count,
    update_fail_count,
    update_success_count,
    modified_date,
    user,
  }) {
    this.create_fail_count = create_fail_count
    this.create_success_count = create_success_count
    this.import_status = import_status
    this.original_file_name = original_file_name
    this.pk = pk
    this.total_row_count = total_row_count
    this.update_fail_count = update_fail_count
    this.update_success_count = update_success_count
    this.user_first_name = user.first_name
    this.user_last_name = user.last_name
    this.user_pk = user.pk
    this.create_date = modified_date
  }
}
export default LoanBook
