import React, {useState} from 'react'
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import {Box, Image} from 'grommet'
import {deleteUser} from 'accounts/controllers/user'
import ADD_USER_EVENTS from '../constants'
import styled from 'styled-components'
import ActiveInactiveWarningPopUp from '../components/activeInactivepopUp'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

const TableWrapper = styled(Box)`
  overflow-x: auto;
`
const COLUMNS = [
  {
    accessor: 'name',
    Header: 'User Name',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },

  {
    // align: 'left',
    accessor: `user__email`,
    Header: 'Email Address',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
  },

  {
    accessor: 'designation',
    Header: 'Role',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} />
    },
    disableSortBy: true,
  },
  {
    accessor: '',
    Header: 'Edit',
    Cell: ({cell: {value}, handleApplicantClick, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" align="center">
              <Box width="100%" align="center">
                <Image
                  margin={{left: 'small'}}
                  width="20px"
                  style={{cursor: 'pointer'}}
                  onClick={() =>
                    handleApplicantClick(rest.row.original, 'EDIT')
                  }
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'is_active',
    Header: 'Active',
    Cell: ({cell: {value}, handleActive, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <Box direction="row" gap="medium" align="center">
                <Box width="100%" align="center">
                  <Image
                    width="30px"
                    onClick={() => handleActive(rest.row.original)}
                    style={{cursor: 'pointer'}}
                    src={
                      process.env.PUBLIC_URL +
                      (rest.row.original.is_active
                        ? '/img/active.png'
                        : '/img/inactive.png')
                    }
                  />
                </Box>
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
]

const UserTable = ({
  tableData,
  event,
  handleEditButton,
  selectedUser,
  setEditLayer,
  editLayer,
  userProfile,
  setLoader,
  setcustomisedMessage,
  navigateToParams,
}) => {
  const [warningpopupvisibility, setwarningpopupvisibility] = useState(false)
  // const [markInactive, setmarkInactive] = useState(false)
  const location = useLocation()
  const [warningmessage, setwarningmessage] = useState('')
  const [makeinactivedata, setmakeinactivedata] = useState()
  const [btnlabel, setbtnlabel] = useState('')
  const handleActive = props => {
    if (userProfile.loggedinUserId !== props.pk) {
      if (!props.is_active) {
        navigateToParams({active: true})
        setwarningmessage(
          ' Are you sure you want to mark ' +
            props.name +
            ' active, the user can access the IndigoHub ?',
        )
        setcustomisedMessage(props.name + ' marked active successfully')
        setbtnlabel('Active')
        setwarningpopupvisibility(true)
        setmakeinactivedata(props)
      } else {
        navigateToParams({active: false})
        setwarningmessage(
          ' Are you sure you want to mark ' +
            props.name +
            ' inactive, the user can no longer login into IndigoHub ?',
        )
        setcustomisedMessage(props.name + ' marked inactive successfully')
        setbtnlabel('Inactive')
        setwarningpopupvisibility(true)
        setmakeinactivedata(props)
      }
    } else {
      event.emit(ADD_USER_EVENTS.LOGGEINUSER_ACTIVE_FAILURE)
    }
  }

  const handleApplicantClick = (props, type) => {
    if (type === 'EDIT') {
      let designation
      tableData.forEach(item => {
        if (item.pk === props.pk) {
          designation = item.designation
        }
      })
      selectedUser(props.pk, props.user, props.name, designation, props.role)
      setEditLayer(!editLayer)
    } else if (type === 'DELETE') {
      deleteUser(event, props.pk)
    }
  }

  let tempRoles = ''
  tableData.forEach(element => {
    tempRoles = ''
    element.role.forEach(item => {
      tempRoles = tempRoles + ' ' + item.name
    })
    element['roles_Value'] = tempRoles
  })

  const [data, setData] = useState([])

  const updateCellData = (rowId, column, value) => {
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  const handlecolumnClick = cell => {}

  const handleSort = sortOrder => {
    if (!sortOrder.length) navigateToParams({ordering: null})
    else {
      const [{id, desc}] = sortOrder
      setLoader(true)
      navigateToParams({ordering: desc ? '-' + id : id})
    }
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }
  return (
    <>
      <TableWrapper>
        <ReactTable
          initialSortOrder={getInitialSorting()}
          data={tableData}
          columns={COLUMNS}
          onSortChange={handleSort}
          updateCellData={updateCellData}
          rowClickHandler={row => {}}
          columnClickHandler={cell => handlecolumnClick(cell)}
          handleActive={handleActive}
          handleApplicantClick={handleApplicantClick}
        />
      </TableWrapper>
      {warningpopupvisibility && (
        <ActiveInactiveWarningPopUp
          layer={warningpopupvisibility}
          setLayer={setwarningpopupvisibility}
          makeinactivedata={makeinactivedata}
          warningmessage={warningmessage}
          btnlabel={btnlabel}
          event={event}
          setLoader={setLoader}
        />
      )}
    </>
  )
}

export default UserTable
