const ADD_USER_EVENTS = {
  GET_USERS_SUCCESS: 'GET USER SUCCESS',
  GET_USERS_FAILURE: 'GET USER FAILURE',
  GET_USERS_ROLES_SUCCESS: 'GET_USERS_ROLES_SUCCESS',
  GET_USERS_ROLES_FAILURE: ' GET_USERS_ROLES_FAILURE',
  GET_USERS_SEARCH_SUCCESS: 'GET_USERS_SEARCH_SUCCESS',
  GET_USERS_SEARCH_FAILURE: 'GET_USERS_SEARCH_FAILURE',
  GET_USERS_DELETE_SUCCESS: 'GET_USERS_DELETE_SUCCESS',
  GET_USERS_DELETE_FAILURE: 'GET_USERS_DELETE_FAILURE',
  ADD_CLICK_SUCCESS: 'ADD_CLICK_SUCCESS',
  ADD_CLICK_FAILURE: 'ADD_CLICK_FAILURE',
  EDIT_CLICK_SUCCESS: 'EDIT_CLICK_SUCCESS',
  EDIT_CLICK_FAILURE: 'EDIT_CLICK_FAILURE',
  ADD_SUCCESS: 'ADD_SUCCESS',
  ADD_FAILURE: 'ADD_FAILURE',
  EDIT_SUCCESS: 'EDIT_SUCCESS',
  EDIT_FAILURE: 'EDIT_FAILURE',
  ACTIVE_SUCCESS: 'ACTIVE_SUCCESS',
  ACTIVE_FAILURE: 'ACTIVE_FAILURE',
  LOGGEINUSER_ACTIVE_FAILURE: 'LOGGEINUSER_ACTIVE_FAILURE',
}
export default ADD_USER_EVENTS
