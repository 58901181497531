// Lib Imports
import React from 'react'
import {Box, Text, TableHeader, TableCell, TableRow} from 'grommet'
import styled from 'styled-components'

const StyledRow = styled(TableRow)`
  background-color: ${props => {
    return props.theme.global.colors[props.background]
  }};
  border-bottom: ${props =>
    `3px solid ${props.theme.global.colors['brand-3p']}`};
`

function Header({headerGroups}) {
  return (
    <>
      <TableHeader>
        {headerGroups.map(headerGroup => (
          <StyledRow
            background="brand"
            border="bottom"
            {...headerGroup.getHeaderGroupProps()}
          >
            {headerGroup.headers.map(column => {
              return (
                <TableCell
                  width={column.width && column.width + 20}
                  align="center"
                  scope="row"
                  border="right"
                  background="table-header"
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <Box align="center" direction="row" gap="xsmall">
                    <Text
                      as="h4"
                      truncate
                      margin="none"
                      size="small"
                      color="light-2"
                    >
                      {column.render('Header')}
                    </Text>
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </Box>
                </TableCell>
              )
            })}
          </StyledRow>
        ))}
      </TableHeader>
    </>
  )
}

export default Header
