//Library importskmdefkewf
import React from 'react'
import {Box, Button, TextInput} from 'grommet'

import 'react-phone-input-2/lib/style.css'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'
const SMSForm = ({onSubmit, setMessageLayer, messageLayer, selected}) => {
  const ButtonStyle = styled(Button)`
    height: 36px;
    width: 110px;
  `

  const TextInput1 = styled(TextInput)`
    borderradius: 10;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    padding-bottom: 6px;
    padding-top: 0px;
    font-weight: 400;
    font-size: 12px;
    width:100%
    color: #000000;
  `

  const validationSchema = Yup.object().shape({})

  const handleCancel = () => {
    setMessageLayer(!messageLayer)
  }

  return (
    <Box>
      <Formik
        initialValues={{
          sms: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <Box width="100%" direction="row"></Box>
            </Box>

            <mailWrapper pad={{left: 'small'}}>
              {true && (
                <TextInput1
                  pad="none"
                  type="sms"
                  name="sms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  color="dark"
                  placeholder="Message"
                  size="large"
                  value={values.sms}
                  disabled={false}
                />
              )}
            </mailWrapper>
            <Box
              direction="row"
              gap="small"
              pad={{right: 'small', top: 'small', bottom: 'none'}}
              justify="end"
            >
              <ButtonStyle
                alignSelf="end"
                label={'Cancel'}
                disabled={isSubmitting}
                primary
                onClick={handleCancel}
              />
              <ButtonStyle
                type="submit"
                alignSelf="end"
                label={isSubmitting ? 'Sending...' : 'Send'}
                disabled={isSubmitting}
                primary
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
export default SMSForm
