class ClientLoanDetails {
  constructor({
    pk,
    applicant,
    lender,
    security,
    term,
    status,
    loan_limit,
    balance,
    rate,
    reply_choice,
    rate_type_choice,
    out_come,
    is_active,
    modified_date,
    settlement_date,
    purpose,
    new_rate,
    account_number,
    repayment_type,
    loan_status,
    savings,
    loan,
  }) {
    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }
    this.pk = pk

    this.applicant_pk = applicant ? applicant.pk : ''
    this.applicant_name = applicant ? applicant.name : ''
    this.first_name = applicant ? applicant.first_name : ''
    this.last_name = applicant ? applicant.last_name : ''
    this.applicant_email = applicant ? applicant.email : ''
    this.applicant_phone = applicant ? applicant.phone : ''

    this.lender_pk = lender.pk
    this.lender_name = lender.name
    this.lender_bsb_code = account_number  + '/' + lender.bsb_code
    this.security_pk = security[0].pk
    let state = security[0].state ? statesAbbr[security[0].state] : ''
    this.security_name =
      security[0].address_line_1 +
      ' ' +
      security[0].address_line_2 +
      ' ' +
      security[0].city +
      ' ' +
      state +
      ' ' +
      security[0].postcode

    this.status = status
    this.term = term
    this.loan_limit = loan_limit
    this.balance = balance
    this.rate = rate

    this.reply_choice = reply_choice
    this.rate_type_choice = rate_type_choice
    this.out_come = out_come
    this.new_rate = new_rate

    this.is_active = is_active
    this.modified_date = modified_date
    this.settlement_date = settlement_date
    this.purpose = purpose

    this.account_no = account_number
    this.repayment_type = repayment_type
    this.savings = savings

    if (out_come === '1') this.out_come = '--No Changes--'
    else if (out_come === '2')
      this.out_come = '$!Money saved, no further action'
    else if (out_come === '3')
      this.out_come = '$!Money saved, Further action required'
    else if (out_come === '4') this.out_come = '!No Money Saved, further action'

    if (loan_status === '1') this.loan_status = 'Applying New Rate'
    else if (loan_status === '2') this.loan_status = 'Retention'
    else if (loan_status === '3') this.loan_status = 'Refinancing'

    if (applicant && applicant.applicant_type === 'B')
      this.applicant_type = 'Borrower'
    else if (applicant && applicant.applicant_type === 'G')
      this.applicant_type = 'Gurantor'
    else if (applicant && applicant.applicant_type === 'TT')
      this.applicant_type = 'Trustee'
    else if (applicant && applicant.applicant_type === 'SG')
      this.applicant_type = 'Signatory'
    else if (applicant && applicant.applicant_type === 'SF')
      this.applicant_type = 'Superfund'
  }
}
export default ClientLoanDetails
