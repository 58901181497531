const CLIENT_LOAN_DETAILS_MESSAGES = {
  LOAN_INFO_GET_SUCCESS: 'Details fetched successfully!',

  LOAN_INFO_GET_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_INFO_UPDATE_SUCCESS: 'Loan information updated successfully!',

  LOAN_INFO_UPDATE_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_INFO_DISCHARGE_SUCCESS: 'Loan discharged successfully!',

  LOAN_INFO_DISCHARGE_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_APPLICANTS_GET_SUCCESS: 'Details fetched successfully!',

  LOAN_APPLICANTS_GET_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_LOAN_ADD_SUCCESS: 'Loan added successfully.',

  CLIENT_LOAN_ADD_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_LIST: 'Loan List',

  APPLICANT: 'Applicant',

  LOAN_SUMMARY: 'Loan Summary',

  LAST_UPDATED: 'Last Updated',

  // OVERVIEW PANEL MESSAGES

  OVERVIEW: 'Overview',

  CAMPAIGN_MONTH: 'Campaign Month',

  NEW_RATE: 'New Rate',

  OLD_RATE: 'Old Rate',

  SAVINGS: 'Savings',

  PERCENTAGE: 'Percentage',

  // DISCHARGE LOAN SECTION

  DISCHARGE_LOAN: 'Discharge Loan',

  DISCHARGE_LOAN_WARNING: 'Are you sure you want to discharge the loan?',

  SECURITY_ADDRESS: 'Security Address',

  SCREEN_ACTIVITY: 'Screen Activity',

  CLIENT_LOAN_GET_MAIL_TEMPLATE_SUCCESS: 'Details fetched successfully!',

  CLIENT_LOAN_GET_MAIL_TEMPLATE_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_SEND_MAIL_SUCCESS: 'Email sent successfully!',

  CLIENT_SEND_MAIL_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_UPDATE_OVERVIEW_SUCCESS: 'Details updated successfully!',

  CLIENT_UPDATE_OVERVIEW_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_REMOVE_APPLICANT_SUCCESS: 'Applicant removed successfully',
  CLIENT_REMOVE_APPLICANT_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_ADD_APPLICANT_SUCCESS: 'Applicant linked successfully',
  CLIENT_ADD_APPLICANT_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  UPDATE_APPLICANT_TYPE_SUCCESS: 'Applicant type is updated successfully',
  UPDATE_APPLICANT_TYPE_FAILURE: "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_GET_SECURITY_SUCCESS: '',
  LOAN_GET_SECURITY_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_ADD_SECURITY_SUCCESS: 'Security address added successfully',
  LOAN_ADD_SECURITY_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_UPDATE_SECURITY_SUCCESS: 'Security address updated successfully',
  LOAN_UPDATE_SECURITY_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  LOAN_DELETE_SECURITY_SUCCESS: 'Security deleted successfully',
  LOAN_DELETE_SECURITY_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  // SECURITY FORM
  ADD_SECURITY_POP_UP_HEADING: '',
  EDIT_SECURITY_POP_UP_HEADING: '',
  UPDATE_SECURITY_POP_UP_HEADING: '',
  DELETE_SECURITY_POP_UP_HEADING: '',
  SECURITY_ADDRESS_SAVE: 'Save',
  SECURITY_ADDRESS_EDIT: 'Edit',
  SECURITY_ADDRESS_DELETE: 'Delete',
  SECURITY_ADDRESS_CANCEL: 'Cancel',
  SECURITY_ADDRESS_DELETE_WARNING_MESSAGE:
    'Are you sure yow want to delete the security address.',
  DELETE_SECURITY: 'Delete Security',
}

export default CLIENT_LOAN_DETAILS_MESSAGES
