import React, {useState, useEffect} from 'react'
import {TextInput, Text} from 'grommet'

// If Edit mode is true renders a editable cell
// else return whatever data is passed
const TextCell = ({
  data,
  initialValue = data,
  row: {
    original: {pk: rowId},
  },
  column: {id: columnId, isEditable = false},
  updateCellData, // This is a function that we supplied to our table instance
  editMode: initialEditMode = false,
  ...rest
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue)
  const [editMode, setEditMode] = useState(initialEditMode)
  const InputRef = React.useRef()
  const onChange = e => {
    setValue(e.target.value)
  }

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    if (isEditable) setEditMode(false)
    if (value !== initialValue) updateCellData(rowId, columnId, value)
  }

  // If the initialValue is changed externally, sync it up with our state
  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    if (isEditable && InputRef.current) InputRef.current.focus()
  }, [editMode, isEditable])

  return (
    <>
      {editMode ? (
        <TextInput
          value={value ? value : undefined}
          ref={InputRef}
          onChange={onChange}
          onBlur={onBlur}
          onClick={e => {
            e.stopPropagation()
            InputRef.current.selectionEnd = 4
          }}
        />
      ) : (
        <Text
          size="small"
          color="text"
          onClick={e => {
            if (isEditable) {
              setEditMode(true)
              e.stopPropagation()
            } else {
              e.preventDefault()
            }
          }}
          {...rest}
        >
          {data}
        </Text>
      )}
    </>
  )
}

export default TextCell
