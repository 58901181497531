function sampleData(num) {
  const roles = ['Admin', 'Employee', 'Guest']
  return [...Array(num).keys()].map(i => {
    return {
      id: i + 1,
      firstName: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ''),
      lastName: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ''),
      username: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, ''),
      email:
        Math.random()
          .toString(36)
          .replace(/[^a-z]+/g, '')
          .substr(0, 5) + '@gmail.com',
      phoneNumber: Math.floor(Math.random() * 100000),
      role: roles[(i + 1) % 3],
    }
  })
}

function getRows(eventEmitter) {
  try {
    const resp = sampleData(10) // Api call here
    eventEmitter.emit('ROW_SUCCESS', {
      data: resp,
    })
  } catch (e) {
    console.log(e)
    eventEmitter.emit('ROW_FAILURE')
  }
}

export {getRows}
