//Library imports
import React, {useState, useEffect} from 'react'
import {Box, Button, Text} from 'grommet'
import {useRouteMatch, useHistory, useLocation} from 'react-router-dom'

//Core imports
import EventEmitter from 'utils/event-emitter'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'
import NewHeader from 'granite/components/HeaderWithDropDown'
// import BackNavigation from 'granite/components/BackNavigation'
import Toast from 'granite/components/Toast'
import styled from 'styled-components'
import LoaderUI from 'granite/components/NumLoader'
import MoreDetails from 'pages/contacting/components/MoreDetails'

//Application imports
import CLIENT_DETAIL_EVENTS_MESSAGES from 'messages/clientDetails'
import {
  getClientInfo,
  getLoanList,
  getScreenActivityList,
  getClientDetailsNotes,
  getClientSavings,
  addNewClient,
  mailClientInfo,
} from 'clientDetails/controllers/client'
import CLIENT_DETAIL_EVENTS from './constants'
import LoanDetail from './components/LoanDetail'
import ClientInfo from './components/ClientInfo'
import ScreenActivity from './components/ScreenActivity'
import Notes from './components/notes'
import AddClientForm from './components/addClientForm'
import MailTemplate from './components/mailmodal'
import queryString from 'query-string'
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

let eventEmitter = new EventEmitter()

function listenEvents({
  eventEmitter,
  setToastData,
  setToastWarning,
  setClientData,
  setloanDetails,
  setNotesData,
  setLayer,
  setID,
  setLoader,
  setnotesvisibility,
  setalrSavings,
  settotalSavings,
  route,
  setscrrenActivityData,
  setTotalRows,
  setCount,
  setaddLoanSummaryVisibility,
  history,
  setmaillist,
  settemplatename,
  settemplatecontent,
  setMoreData,
  setMoreLoader,
  setMoreDataEdit,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SUCCESS:
        if (event.data[0].client_type === 'Individual') {
          setClientData([
            ['Client Type', `${event.data[0].client_type}`],
            ['Name', `${event.data[0].name}`],
            ['First name', `${event.data[0].first_name}`],
            ['Last name', `${event.data[0].last_name}`],
            ['Phone', `${event.data[0].phone}`],
            ['Email', `${event.data[0].email}`],
          ])
          setID(`${event.data[0].pk_Update}`)
        } else {
          setClientData([
            ['Client Type', `${event.data[0].client_type}`],
            ['Name', `${event.data[0].name}`],
            ['First name', `${event.data[0].first_name}`],
            ['Last name', `${event.data[0].last_name}`],
            ['Phone', `${event.data[0].phone}`],
            ['Email', `${event.data[0].email}`],
            ['Company Name', `${event.data[0].company_name}`],
            ['Company ABN', `${event.data[0].company_ABN}`],
          ])
          setID(`${event.data[0].pk_Update}`)
        }

        setTimeout(() => {
          setLoader(false)
        }, 1000)

        break
      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_GET_INFO_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SAVE_SUCCESS:
        let screenactivityparams = {
          entity_id: route.params.clientid,
          entity_type_name: 'applicant',
        }
        getScreenActivityList(screenactivityparams, eventEmitter)
        setnotesvisibility(true)
        getClientInfo(eventEmitter, route.params.clientid)
        // setLoader(false)
        setToastWarning(false)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_SAVE_INFO_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_SAVE_FAILURE:
        setnotesvisibility(true)
        setLoader(false)
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_SAVE_INFO_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_GET_LOAN_DETAIL_SUCCESS:
        setloanDetails(event.data.data)
        setTotalRows(event.data.count)
        setCount(Math.ceil(event.data.count / 10))
        setTimeout(() => {
          setLoader(false)
        }, 1000)

        break
      case CLIENT_DETAIL_EVENTS.CLIENT_GET_LOAN_DETAIL_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_GET_LOAN_DETAIL_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_SUCCESS:
        if (event.data) setNotesData(event.data.data)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_GET_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_GET_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_SUCCESS:
        setToastWarning(false)
        // getClientDetailsNotes(eventEmitter, route.params.clientid)
        setLayer(true)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_ADD_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_ADD_FAILURE:
        setLayer(false)
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_ADD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_SUCCESS:
        getClientDetailsNotes(eventEmitter, route.params.clientid)
        setToastWarning(false)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_NOTES_DELETE_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_NOTES_DELETE_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      // TOTAL AND ALR SAVINGS
      case CLIENT_DETAIL_EVENTS.CLIENT_SAVINGS_SUCCESS:
        setalrSavings(event.data[0].alrSavings)
        settotalSavings(event.data[0].totalSavings)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_SAVINGS_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_SAVINGS_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_GET_SCREEN_ACTIVITY_SUCCESS:
        if (event.data.tableData.length > 0) setscrrenActivityData(event.data)
        else
          setscrrenActivityData({
            tableData: [],
            count: 0,
            next_page: null,
            previous_page: null,
            rows: 0,
          })
        // setscrrenActivityData(event.data)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_GET_SCREEN_ACTIVITY_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_SAVINGS_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_MAIL_SUCCESS:
        setToastWarning(false)
        setToastData({
          open: true,
          message: CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_MAIL_INFO_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_DETAIL_MAIL_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_MAIL_INFO_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      // CLIENT_ADD_NEW_SUCCESS
      case CLIENT_DETAIL_EVENTS.CLIENT_ADD_NEW_SUCCESS:
        if (event.data.title) {
          history.push(
            '/clients/' + event.data.data.pk + '?msg=' + event.data.title,
          )
        } else {
          history.push('/clients/' + event.data.data.pk)
        }
        // setToastData({
        //   open: true,
        //   message: event.data.data.title
        //     ? event.data.data.title
        //     : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_ADD_NEW_SUCCESS,
        // })
        // setTimeout(() => {
        //   setToastData({open: false, message: ''})
        // }, 5000)
        // if (event.data.data.pk)
        //  history.push('/clients/' + event.data.data.pk)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_ADD_NEW_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_ADD_NEW_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      //  GET MAIl SUCS
      case CLIENT_DETAIL_EVENTS.CLIENT_GET_MAIL_TEMPLATES_SUCCESS:
        const tempcontentarr = {}
        setmaillist(event.data[0].list)
        settemplatename(event.data[0].activity_name)
        if (event.data.length > 0) {
          event.data.forEach(item => {
            tempcontentarr[item.email_template.name] =
              item.email_template.content
          })
        }
        settemplatecontent(tempcontentarr)
        break
      case CLIENT_DETAIL_EVENTS.CLIENT_GET_MAIL_TEMPLATES_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_GET_MAIL_TEMPLATES_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      // Export
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_LIST_EXPORT_SUCCESS:
        if (event.data) {
          window.open(event.data, '_blank')
          setToastWarning(false)
          setToastData({
            open: true,
            message:
              CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_LIST_EXPORT_SUCCESS,
          })
          setTimeout(() => {
            setToastData({open: false, message: ''})
          }, 5000)
        } else {
          setToastWarning(true)
          setToastData({
            open: true,
            message: event.data
              ? event.data
              : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_LIST_EXPORT_WARNING,
          })
          setTimeout(() => {
            setToastData({open: false, message: ''})
          }, 5000)
        }

        break
      case CLIENT_DETAIL_EVENTS.CLIENT_LOAN_LIST_EXPORT_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_DETAIL_EVENTS_MESSAGES.CLIENT_LOAN_LIST_EXPORT_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_DETAIL_EVENTS.GET_MORE_DETAILS_SUCCESS:
        setMoreData(event.data.moreDetails)
        setMoreDataEdit(event.data)
        setMoreLoader(false)
        break
      case CLIENT_DETAIL_EVENTS.GET_MORE_DETAILS_FAILURE:
        break

      default:
        break
    }
  })
  return subsciption
}
const ClientDetail = ({userProfile, permissions}) => {
  const [loader, setLoader] = useState(true)
  const history = useHistory()
  const route = useRouteMatch()
  const [count, setCount] = useState(10)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [rows, setTotalRows] = useState(10)
  const [moreLoader, setMoreLoader] = useState(false)
  const [moreDetailLayer, setMoreDetailLayer] = useState(false)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [clientId, setclientId] = useState()
  const [moreData, setMoreData] = useState([])
  const [toastWarning, setToastWarning] = useState(false)
  const [layer, setLayer] = useState(false)
  const [notesData, setNotesData] = useState([])
  const [ID, setID] = useState()
  const [notesvisibility, setnotesvisibility] = useState(true)
  const [totalSavings, settotalSavings] = useState(0)
  const [alrSavings, setalrSavings] = useState(0)
  const [scrrenActivityData, setscrrenActivityData] = useState([])
  const [moreDataEdit, setMoreDataEdit] = useState()
  const [addLoanSummaryVisibility, setaddLoanSummaryVisibility] = useState(true)
  // const [showAddClientForm, setshowAddClientForm] = useState(false)
  const [showAddLoanButton, setshowAddLoanButton] = useState(false)
  const [clientData, setClientData] = useState([
    ['Name', ''],
    ['First name', ''],
    ['Phone', ''],
    ['Email', ''],
    ['Company', ''],
    ['Company Name', ''],
    ['Company ABN', ''],
    ['Client Type', ''],
  ])

  let [loanDetails, setloanDetails] = useState([])
  const [showmailmodal, setshowmailmodal] = useState(false)
  const [edit, setedit] = useState(true)
  const [maillist, setmaillist] = useState([
    'Notify to Review ALR',
    'No Action Required ALR',
    'Rate Reduction ALR Email',
    'Interest / Fixed Expiry Template',
    'Missed Call Template',
    'Actions to be completed ALR Email',
    'Recently Dealt with Indigo ALR Email',
  ])
  const [templatename, settemplatename] = useState()
  const [tempcontent, settemplatecontent] = useState('')
  const [refreshState, setrefreshState] = useState(true)
  let defaultFilterData = {
    page: 1,
    page_size: 20,
    select_page: 1,
    select_page_size: 20,
    is_active: true,
  }

  // let history = useHistory()
  let location = useLocation()
  const {search} = location

  let queryParam = queryString.parse(search)
  // const [value, setValue] = useState(queryParam.search ? queryParam.search : '')
  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )
  const [filterData, setFilterData] = useState(defaultFilterData)

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  const handleNotes = () => {
    setLayer(!layer)
  }

  const handleAddClient = async (values, {setSubmitting, setErrors}) => {
    setLoader(true)
    setErrors({})
    setSubmitting(true)
    await addNewClient(eventEmitter, {...values})
    setSubmitting(false)
  }

  const handleRefresh = () => {
    if (route.url === '/clients/add') {
      setrefreshState(!refreshState)
    } else {
      if (!loader) {
        setLoader(true)
        setedit(true)
        setnotesvisibility(true)
        setSearchValue('')
        navigateToParams({
          page: 1,
          page_size: 20,
          select_page: 1,
          select_page_size: 20,
          search: null,
          is_active: true,
        })
        getClientInfo(eventEmitter, route.params.clientid)
        let loan_list_params = {
          page: filterData.select_page,
          page_size: filterData.select_page_size,
          is_active: filterData.is_active,
        }
        getLoanList(eventEmitter, route.params.clientid, loan_list_params)
        getClientDetailsNotes(eventEmitter, route.params.clientid)
        let screenactivityparams = {
          entity_id: route.params.clientid,
          entity_type_name: 'applicant',
        }
        getScreenActivityList(screenactivityparams, eventEmitter)
        getClientSavings(eventEmitter, route.params.clientid)
      }
    }
  }

  const handleBackNavigation = () => {
    history.push('/clients')
  }

  useEffect(() => {
    let path = window.location.pathname
    let id = path.split('/')
    setclientId(id[id.length - 1])
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,

        history,
        setCount,
        setTotalRows,
        setToastWarning,
        setClientData,
        setloanDetails,
        clientId,
        setNotesData,
        setLayer,
        setID,
        setLoader,
        setnotesvisibility,
        setalrSavings,
        settotalSavings,
        route,
        setmaillist,
        setscrrenActivityData,
        setaddLoanSummaryVisibility,
        settemplatename,
        settemplatecontent,
        setMoreData,
        setMoreLoader,
        setMoreDataEdit,
      })

      if (route.url === '/clients/add') {
        setshowAddLoanButton(true)
        setLoader(false)
      } else {
        if (filterData.msg) {
          setToastData({open: true, message: filterData.msg})
          setTimeout(() => {
            setToastData({open: false, message: ''})
          }, 5000)
          delete filterData.msg
        }
        getClientInfo(eventEmitter, route.params.clientid)
        let loan_list_params = {
          page: filterData.select_page,
          page_size: filterData.select_page_size,
          ordering: filterData.ordering,
          search: filterData.search,
          is_active: filterData.is_active,
        }
        getLoanList(eventEmitter, route.params.clientid, loan_list_params)
        getClientDetailsNotes(eventEmitter, route.params.clientid)
        getClientSavings(eventEmitter, route.params.clientid)
        let screenactivityparams = {
          entity_id: route.params.clientid,
          entity_type_name: 'applicant',
          page: filterData.page,
          page_size: filterData.page_size,
        }
        getScreenActivityList(screenactivityparams, eventEmitter)
        mailClientInfo(eventEmitter, 'CLIENT_DETAIL', route.params.clientid)
      }

      return subscription
    }
    let subscription = init()
    return () => {
      subscription.unsubscribe()
    }
  }, [clientId, history, route, filterData])

  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'Client List / Client Details'}
        showSavings={true}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
        totalSavings={totalSavings}
        alrSavings={alrSavings}
        hideAlrSavings
      />
      <Box overflow={{vertical: 'auto'}} fill>
        {loader ? (
          <Box align="center" justify="center" fill>
            <LoaderUI />
          </Box>
        ) : (
          <>
            <Box
              pad={{
                top: 'none',
                bottom: 'none',
                left: 'medium',
                right: 'medium',
              }}
            >
              <Box direction="row">
                <Box
                  pad={{
                    top: 'medium',
                    bottom: 'xsmall',
                    left: 'none',
                    right: 'xxsmall',
                  }}
                  // alignSelf="top"
                  width="75%"
                  gap="xlarge"
                >
                  <Box direction="row" gap="xsmall">
                    <Text
                      weight="bold"
                      color="dark"
                      size="14px"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        handleBackNavigation()
                      }}
                    >
                      {'Clients'}
                    </Text>
                    <Text color="dark" size="14px">
                      {' / '}
                    </Text>
                    <Text color="dark" size="14px">
                      {clientData[1][1].length > 1
                        ? clientData[1][1]
                        : 'Client Details'}
                    </Text>
                    {/*<BackNavigation
                      label={
                        clientData[1][1].length > 1
                          ? clientData[1][1]
                          : 'Client Details'
                      }
                    />*/}
                  </Box>
                </Box>
                <Box width="25%" direction="row" justify="end" pad="none">
                  <Box direction="row" pad="none" justify="end">
                    <Box pad="none">
                      {notesvisibility && (
                        <ButtonStyle
                          type="submit"
                          alignSelf="end"
                          margin={{vertical: 'small'}}
                          label={'Notes'}
                          primary
                          onClick={handleNotes}
                          disabled={showAddLoanButton}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>

              {layer && (
                <Notes
                  layer={layer}
                  setLayer={setLayer}
                  notesData={notesData}
                  event={eventEmitter}
                  route={route}
                  permissions={permissions}
                />
              )}
              {showmailmodal && (
                <MailTemplate
                  setshowmailmodal={setshowmailmodal}
                  showmailmodal={showmailmodal}
                  event={eventEmitter}
                  route={route}
                  loggedinuser={userProfile.email}
                  client={clientData[5][1]}
                  clientfirdtname={clientData[2][1]}
                  sendername={userProfile.name}
                  designation={userProfile.designation}
                  maillist={maillist}
                  templatename={templatename}
                  tempcontent={tempcontent}
                />
              )}
              <Box gap="medium">
                {route.url === '/clients/add' ? (
                  <AddClientForm
                    onSubmit={handleAddClient}
                    setrefreshState={setrefreshState}
                    refreshState={refreshState}
                  />
                ) : (
                  <ClientInfo
                    clientData={clientData}
                    event={eventEmitter}
                    clientId={clientId}
                    ID={ID}
                    setnotesvisibility={setnotesvisibility}
                    setLoader={setLoader}
                    eventEmitter={eventEmitter}
                    setshowmailmodal={setshowmailmodal}
                    showmailmodal={showmailmodal}
                    permissions={permissions}
                    edit={edit}
                    setedit={setedit}
                  />
                )}
                {moreDetailLayer && (
                  <MoreDetails
                    setMoreDetailLayer={setMoreDetailLayer}
                    moreData={moreData}
                    moreLoader={moreLoader}
                    moreDataEdit={moreDataEdit}
                    editVisibility={true}
                  />
                )}
                <LoanDetail
                  tableData={loanDetails}
                  event={eventEmitter}
                  count={count}
                  page={page}
                  rows={rows}
                  size={size}
                  setPage={setPage}
                  setSize={setSize}
                  addLoanSummaryVisibility={addLoanSummaryVisibility}
                  history={history}
                  showAddLoanButton={showAddLoanButton}
                  route={route}
                  permissions={permissions}
                  eventEmitter={eventEmitter}
                  navigateToParams={navigateToParams}
                  setLoader={setLoader}
                  setMoreDetailLayer={setMoreDetailLayer}
                  setMoreLoader={setMoreLoader}
                  moreDataEdit={moreDataEdit}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  filterData={filterData}
                />
                <ScreenActivity
                  scrrenActivityData={scrrenActivityData}
                  event={eventEmitter}
                  route={route}
                  navigateToParams={navigateToParams}
                  filterData={filterData}
                />
              </Box>
            </Box>
          </>
        )}
        {toastData.open ? (
          toastWarning ? (
            <Toast
              text={toastData.message}
              background="warning"
              onClose={() => {
                setToastData({
                  open: false,
                  message: '',
                })
              }}
            />
          ) : (
            <Toast
              text={toastData.message}
              onClose={() => {
                setToastData({
                  open: false,
                  message: '',
                })
              }}
            />
          )
        ) : null}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  // userOrganisations: OrganisationDucks.organisations(state),
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})

export default connect(mapStateToProps)(ClientDetail)
