// Lib imports
import React, {useState, useEffect} from 'react'
import {Box, CheckBox, Select} from 'grommet'

// Core imports
import EventEmitter from 'utils/event-emitter'
import TablePagination from 'granite/components/Pagination'
import PageHeading from 'granite/components/PageHeading'
import TextCell from 'granite/components/Table/TextCell'
import ActionsCell from 'granite/components/Table/ActionsCell'

// Application imports
import {getRows} from 'demo/controllers/demo'
import ReactTable from 'granite/components/Table'
import TableFilter from './components/TableFilter'

const COLUMNS = [
  {
    accessor: 'selection',
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <CheckBox {...getToggleAllRowsSelectedProps()} />
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'}>
        <CheckBox {...row.getToggleRowSelectedProps()} />
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'actions',
    Header: 'Actions',
    Cell: ({cell: {value}}) => <ActionsCell data={value} />,
    disableSortBy: true,
  },
  {
    accessor: 'firstName', // accessor needs to be the same as the corresponding key
    Header: 'First Name', // Header is the heading rendered on the UI. Custom component
    Cell: ({cell: {value}, ...rest}) => {
      // inplace of string can also be used.
      return <TextCell {...rest} data={value} editMode={false} />
    },
  },

  {
    accessor: 'lastName',
    Header: 'Last Name',
    isEditable: true,
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} />
    },
  },
  {
    accessor: 'username',
    Header: 'Username',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={true} />
    },
  },
  {
    accessor: 'email',
    Header: 'Email',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} />
    },
  },
  {
    accessor: 'phoneNumber',
    Header: 'Phone',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} />
    },
  },
  {
    accessor: 'role',
    Header: 'Role',
    Cell: ({cell: {value, row, column}, updateCellData, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Select
              options={['Admin', 'Employee', 'Guest']}
              value={value}
              onChange={({value}) =>
                updateCellData(row.original.id, column.id, value)
              }
            />
          }
        />
      )
    },
  },
]

const TableDemo = ({history, location}) => {
  const [eventEmitter] = useState(new EventEmitter())
  const {search} = {search: {}}
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [selected, setSelected] = useState([])
  const defaultPaginationData = {page: 1, page_size: 10, total: 100}

  const updateData = data =>
    setData(
      data.map(row => {
        row.actions = [
          {name: 'edit', onClick: () => alert('edit clicked')},
          {name: 'delete', onClick: () => alert('delete clicked')},
        ]
        return row
      }),
    )

  const updateCellData = (rowId, column, value) => {
    console.log(rowId, column, value)
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      // console.log('EVENT CAUGHT', event)
      switch (event.type) {
        case 'ROW_SUCCESS':
          updateData(event.data.data)
          break
        default:
          console.log(event.data)
      }
    })
    getRows(eventEmitter)
    return subscription.unsubscribe()
  }, [eventEmitter])

  useEffect(() => {
    getRows(eventEmitter)
  }, [search, eventEmitter])

  return (
    <>
      <Box pad="large" flex={{grow: 1}} gap="medium">
        <PageHeading
          mainHeading={'Table Demo'}
          subHeading={'A react-table demo'}
        />
        <TableFilter
          PrevSearch="" // pass in the previously searched value
          selected={selected} // selected rows
          columns={columns} // columns returned by react-table instance
          onSearchChange={e => console.log(e)}
        />
        <ReactTable
          data={data}
          columns={COLUMNS} // pass in our table columns
          setSelected={setSelected} // sets selected row information
          setColumns={setColumns} // sets columns returned by react-table instance
          onSortChange={e => console.log(e)}
          updateCellData={updateCellData}
          rowClickHandler={row => console.log('row click', row)}
          columnClickHandler={cell =>
            console.log('col click', cell.column.id, cell.row)
          }
        />
        <TablePagination
          pageData={defaultPaginationData}
          showSizeChanger={true}
          onPageChange={data => console.log(data)}
          dataLength={data.length}
        />
      </Box>
    </>
  )
}

export default TableDemo
