//Library imports
import React, {useState} from 'react'
import {Box, Text, ThemeContext, Button, Anchor} from 'grommet'
import styled from 'styled-components'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'

//Application imports
import CreateCampaign from './CreateCampaign'
// import {campaigneditapi} from 'alr/controllers/loanReview'
// import {getDashBoardTable} from 'dashboard/controllers/dashboard'
const TableWrapper = styled(Box)`
  overflow-x: auto;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 180px;
`
const bodyValueSelect = {
  button: {
    padding: {
      vertical: '0px',
      horizontal: '0px',
    },
  },
}
const COLUMNS = [
  {
    accessor: 'month',
    Header: 'Name',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
    disableSortBy: true,
  },

  {
    accessor: 'month_year',
    Header: 'Month',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
    disableSortBy: true,
  },
  {
    accessor: 'total_clients',
    Header: 'Total Clients',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
    disableSortBy: true,
  },
  {
    accessor: 'total_loan',
    Header: 'Total Loans',
    Cell: ({cell: {value}, ...rest}) => {
      return <TextCell {...rest} data={value} editMode={false} color="text-2" />
    },
    disableSortBy: true,
  },
  {
    accessor: 'new',
    Header: 'Gather Loan Details',
    Cell: ({cell: {value, row}, handlecolumnClick, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Anchor
              onClick={() =>
                handlecolumnClick(
                  '/gatherloandetails?' +
                    'month_year=' +
                    row.original.month +
                    '-' +
                    row.original.year +
                    '&' +
                    'year=' +
                    row.original.year +
                    '&' +
                    'campaign_id=' +
                    row.original.campaign_id,
                )
              }
              label={<Text>{value}</Text>}
            />
          }
          editMode={false}
          color="text-2"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'contacting',
    Header: 'Notify Client Of Review',
    Cell: ({
      cell: {value, row, column},
      handlecolumnClick,
      updateCellData,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Anchor
              onClick={() =>
                handlecolumnClick(
                  '/notifyclientofreview?status=Contacting&' +
                    'month_year=' +
                    row.original.month +
                    '-' +
                    row.original.year +
                    '&' +
                    'year=' +
                    row.original.year +
                    '&' +
                    'campaign_id=' +
                    row.original.campaign_id,
                )
              }
              label={<Text>{value}</Text>}
            />
          }
          editMode={false}
          color="text-2"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'Negotiate_Pricing',
    Header: 'Negotiate Pricing',
    Cell: ({
      cell: {value, row, column},
      handlecolumnClick,
      updateCellData,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Anchor
              onClick={() =>
                handlecolumnClick(
                  '/negotiatepricing?status=All_Pricing&' +
                    'month_year=' +
                    row.original.month +
                    '-' +
                    row.original.year +
                    '&' +
                    'year=' +
                    row.original.year +
                    '&' +
                    'campaign_id=' +
                    row.original.campaign_id,
                )
              }
              label={<Text>{value}</Text>}
            />
          }
          editMode={false}
          color="text-2"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'Contact_Client_Of_Result',
    Header: 'Contact Client Of Result',
    Cell: ({
      cell: {value, row, column},
      handlecolumnClick,
      updateCellData,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Anchor
              onClick={() =>
                handlecolumnClick(
                  '/contactclientofresult?status=Closed&' +
                    'month_year=' +
                    row.original.month +
                    '-' +
                    row.original.year +
                    '&' +
                    'year=' +
                    row.original.year +
                    '&' +
                    'campaign_id=' +
                    row.original.campaign_id,
                )
              }
              label={<Text>{value}</Text>}
            />
          }
          editMode={false}
          color="text-2"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'completed',
    Header: 'Completed',
    Cell: ({
      cell: {value, row, column},
      handlecolumnClick,
      updateCellData,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <Anchor
              onClick={() =>
                handlecolumnClick(
                  '/completed?status=All_Completed&' +
                    'month_year=' +
                    row.original.month +
                    '-' +
                    row.original.year +
                    '&' +
                    'year=' +
                    row.original.year +
                    '&' +
                    'campaign_id=' +
                    row.original.campaign_id,
                )
              }
              label={<Text>{value}</Text>}
            />
          }
          editMode={false}
          color="text-2"
        />
      )
    },
    disableSortBy: true,
  },
]

const OALRTable = ({
  tableData,
  event,
  navigateToParams,
  // setLoader,
  // handleRefresh,
  eventEmitter,
  // syncvisible,
  filterData: {page, page_size: size},
  total,
}) => {
  const history = useHistory()
  const location = useLocation()
  const [popup, setPopup] = useState(false)
  const [data, setData] = useState([
    // {
    //   main_pk: 1,
    //   pk: 1,
    //   name: 'Cusotmer',
    //   email: '',
    //   phone: '',
    //   balance: 0,
    //   outcome: '1',
    //   date: '15-Apr-2020',
    // },
    {
      month: 'June',
      year: '2021',
      total_clients: 0,
      total_loans: 0,
      new: 0,
      contacting: 0,
      needs_attention: 0,
      in_review: 0,
      completed: 0,
      campaign_id: 0,
    },
  ])

  //To get the data sorted on the basis of campaign_id in descending order
  tableData = tableData.sort((a, b) => {
    return b.campaign_id - a.campaign_id
  })

  const updateCellData = (rowId, column, value) => {
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  const handleRowClicked = row => {}

  const handlecolumnClick = link => {
    history.push(link)
  }

  // const handleCampaignForm = () => {
  //   campaigneditapi(eventEmitter)
  //   handleRefresh()
  // }

  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }

  // const handleSort = useCallback(
  //   props => {
  //     const col = props[0].id
  //     const parms = {}
  //     props[0].desc
  //       ? (parms['ordering'] = '-' + col)
  //       : (parms['ordering'] = col)
  //     getDashBoardTable(event, parms)
  //   },
  //   [event],
  // )

  // const handleSort = sortOrder => {
  //   if (!sortOrder.length) navigateToParams({ordering: null})
  //   else {
  //     const [{id, desc}] = sortOrder
  //     setLoader(true)
  //     navigateToParams({ordering: desc ? '-' + id : id})
  //   }
  // }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  return (
    <Box>
      {/* {syncvisible && ( */}
      <Box margin={{bottom: 'small'}} alignSelf="end">
        <ThemeContext.Extend value={bodyValueSelect}>
          <ButtonStyle
            primary
            label="Generate Campaign"
            onClick={() => setPopup(true)}
            // onClick={handleCampaignForm}
          />
        </ThemeContext.Extend>
      </Box>
      {/* )} */}
      <TableWrapper>
        <ReactTable
          initialSortOrder={getInitialSorting()}
          data={tableData}
          columns={COLUMNS}
          // onSortChange={handleSort}
          updateCellData={updateCellData}
          rowClickHandler={row => handleRowClicked(row)}
          handlecolumnClick={handlecolumnClick}
        />
      </TableWrapper>
      {popup && (
        <CreateCampaign
          setPopup={() => setPopup(false)}
          eventEmitter={eventEmitter}
        />
      )}
      {tableData.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            <Text color="dark-6">
              {total < size
                ? total + ' out of ' + total
                : tableData.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={tableData.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default OALRTable
