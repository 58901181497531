import {createSelector} from 'reselect'

import createReducer from 'utils/ducks/create-reducer'

// ActionsNames - <NOUN>/<VERB>_<NOUN>
const UPDATE_SIDEBAR = 'accounts/sidebar/UPDATE_SIDEBAR'
const UPDATE_COLLAPSED = 'accounts/sidebar/UPDATE_COLLAPSED'
// Action creator name: <VERB><NOUN>
// Export actions
export function updateSidebar(accessData) {
  return {
    type: UPDATE_SIDEBAR,
    data: accessData,
  }
}

// state name - <NOUN>
const defaultState = {
  access: [],
  collapsed:true
}

// Reducers - <NOUN>_<VERB>_REDUCER
function UPDATE_SIDEBAR_REDUCER(state, action) {
  return Object.assign({}, state, {
    access: action.data,
  })
}

function UPDATE_COLLAPSED_REDUCER(state, action) {
  return Object.assign({}, state, {
    collapsed: action.data,
  })
}

const handlers = {
  [UPDATE_SIDEBAR]: UPDATE_SIDEBAR_REDUCER,
  [UPDATE_COLLAPSED]: UPDATE_COLLAPSED_REDUCER
}

// Selectors
const sidebarSelector = state => state.accounts.sidebar

export const sidebarItems = createSelector(
  sidebarSelector,
  sidebar => sidebar.access,
)

export const sidebarCollapsed = createSelector(
  sidebarSelector,
  sidebar => sidebar.collapsed,
)

// export reducers as default
export default createReducer(defaultState, handlers)
