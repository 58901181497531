class LoanReview {
  constructor({
    pk,
    applicant,
    lender,
    security,
    status,
    term,
    loan_limit,
    balance,
    rate,
    reply_choice,
    rate_type_choice,
    out_come,
    loan_package,
    is_active,
    modified_date,
    settlement_date,
    purpose,
    review_cycle,
    discount,
    repayment_type,
    product,
    off_set,
    repayment_amount,
    repayment_date,
    account_number,
    fixed_expiry,
    io_expiry,
    new_rate,
    savings,
    loan,
    security_address,
  }) {
    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }
    this.pk = pk

    this.applicant_pk = applicant ? applicant.pk : ''
    this.applicant_name = applicant ? applicant.name : ''
    this.applicant_email = applicant ? applicant.email : ''
    this.applicant_phone = applicant ? applicant.phone : ''
    this.lender_pk = lender.pk
    this.lender_name = lender.name
    this.lender_bsb_code = lender.bsb_code

    this.security_pk = security.pk
    this.security_name =
      (security[0].address_line_1 === null ? '' : security[0].address_line_1) +
      ' ' +
      (security[0].address_line_2 === null ? '' : security[0].address_line_2) +
      ' ' +
      (security[0].city === null ? '' : security[0].city) +
      ' ' +
      (security[0].state === null || security[0].state === '' ? '' : statesAbbr[security[0].state]) +
      ' ' +
      (security[0].postcode === null ? '' : security[0].postcode)
    this.status = status
    this.term = term

    this.loan_limit = loan_limit
    this.fixed_expiry = fixed_expiry
    this.io_expiry = io_expiry
    this.balance = balance

    this.rate = rate
    this.reply_choice = reply_choice
    this.rate_type_choice = rate_type_choice
    this.out_come = out_come

    this.loan_package = loan_package === true ? 'Yes' : 'No'
    this.is_active = is_active
    this.modified_date = modified_date
    this.settlement_date = settlement_date

    this.purpose = purpose
    this.review_cycle = review_cycle
    this.discount = discount
    this.repayment_type = repayment_type

    this.product_expiry = product.expiry
    this.product_name = product.name
    this.off_set_account = off_set
    this.repayment_amount = repayment_amount

    this.repayment_date = repayment_date
    this.account_bsb_no = `${account_number} / ${lender.bsb_code}`
    this.old_rate = rate
    this.new_rate = new_rate
    this.savings = savings
  }
}

export default LoanReview
