// Lib imports
import React, {useEffect} from 'react'
import {useTable, useRowSelect, useSortBy} from 'react-table'
import {Table, Text, Box} from 'grommet'
import _ from 'lodash'
import PropTypes from 'prop-types'
// Core imports
import Header from './Header'
import Body from './Body'
import Row from './Row'

const ReactTable = ({
  data,
  columns,
  setSelected,
  setColumns,
  onSortChange,
  updateCellData,
  rowClickHandler,
  columnClickHandler,
  initialSortOrder,
  rowError,
  erroredRowPk,
  ...rest
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    selectedFlatRows,
    allColumns,
    state: {hiddenColumns, sortBy},
  } = useTable(
    {
      columns,
      data,
      initialState: {sortBy: initialSortOrder}, // initial state can be provided for the table
      manualSortBy: true,
      updateCellData, // this function will be passed down to each Table Cell
      ...rest,
    },
    useSortBy,
    useRowSelect,
  )

  // To keep track of column visibility state
  useEffect(() => {
    setColumns([...allColumns])
  }, [hiddenColumns, allColumns, setColumns])

  // To keep track of selected rows
  useEffect(() => {
    if (selectedFlatRows.length === 0) setSelected([])
    else setSelected(selectedFlatRows.map(row => row.original))
  }, [selectedFlatRows, setSelected])

  // To keep track of sorting order
  useEffect(() => {
    if (!_.isEqual(sortBy, initialSortOrder)) onSortChange(sortBy)
  }, [sortBy, onSortChange, initialSortOrder])
  return (
    <>
      <Table {...getTableProps()}>
        <Header headerGroups={headerGroups} />
        <Body {...getTableBodyProps()}>
          {data &&
            rows.map((row, i) => {
              prepareRow(row) //mandatory to pass row through this function
              return (
                <Row
                  background={
                    (row.original && row.original.rowActive) ||
                    (rowError && row.original.pk === erroredRowPk)
                      ? rowError
                        ? 'row_error'
                        : 'rowactive'
                      : i % 2 === 0
                      ? 'brand-light1'
                      : 'light-1'
                  }
                  row={row}
                  {...row.getRowProps()}
                  onClick={() => rowClickHandler(row)}
                  columnClickHandler={columnClickHandler}
                />
              )
            })}
        </Body>
      </Table>
      {data.length <= 0 && (
        <Box
          width="100%"
          // border={{ color: 'brand', size: 'large' }}
          pad="large"
          alignContent="center"
        >
          <Text alignSelf="center" size="14px" color="text-1">
            You have reached level zero
          </Text>
        </Box>
      )}
    </>
  )
}

ReactTable.defaultProps = {
  initialSortOrder: [],
  updateCellData: () => {},
  columnClickHandler: () => {},
  rowClickHandler: () => {},
  setColumns: () => {},
  setSelected: () => {},
  onSortChange: () => {},
}

ReactTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  setSelected: PropTypes.func,
  setColumns: PropTypes.func,
  onSortChange: PropTypes.func,
  updateCellData: PropTypes.func,
  rowClickHandler: PropTypes.func,
  columnClickHandler: PropTypes.func,
  initialSortOrder: PropTypes.array,
}

export default ReactTable
