const ADD_CLIENT_MESSAGES = {
  GET_CLIENT_SUCCESS: 'Success!',
  GET_CLIENT_FAILURE: 'Failure!',
  GET_CLIENT_LOAN_DETAILS_SUCCESS: 'Success!',
  GET_CLIENT_LOAN_DETAILS_FAILURE: 'Failed!',
  GET_CLIENTS_SUCCESS: 'Success!',
  GET_CLIENTS_FAILURE: 'Failed!',
}

export default ADD_CLIENT_MESSAGES
