import LoanAPI from 'clientloandetail/gateways/loan-api'
import CLIENT_LOAN_DETAIL_EVENTS from 'pages/clientloandetails/constants'
import ADD_APPLICANT_EVENTS from 'pages/addApplicants/constants'

async function getLoan(eventEmitter, loanid) {
  try {
    const response = await LoanAPI.getLoan(loanid)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_FAILURE  ,err.message)
  }
}

async function updateLoanInfo(eventEmitter, values, id, particularloanDetail, userProfile) {
  try {
    const response = await LoanAPI.updateLoanData(
      values,
      id,
      particularloanDetail,
      userProfile,
    )
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_UPDATE_SUCCESS,
      response,
    )
    await getLoan(eventEmitter, id)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_UPDATE_FAILURE,
      err.message,
    )
  }
}

async function dischargeLoan(eventEmitter, id) {
  try {
    const response = await LoanAPI.dischargeLoan(id)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_UPDATE_SUCCESS,
      response,
    )
    await getLoan(eventEmitter, id)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_DETAIL_UPDATE_FAILURE,
      err.message,
    )
  }
}

async function getApplicants(eventEmitter, loanid) {
  try {
    const response = await LoanAPI.getApplicants(loanid)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_APPLICANTS_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_APPLICANTS_FAILURE,
      err.message,
    )
  }
}

async function getScreenACtivity(params, eventEmitter) {
  try {
    const response = await LoanAPI.getLoanScreenActivity(params)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_SCREEN_ACTIVITY_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_SCREEN_ACTIVITY_FAILURE,
      err.message,
    )
  }
}

async function getClientSavings(eventEmitter, clientId, loanid) {
  let params = {
    applicant_id: clientId,
    loan_id: loanid,
    loan_total_saving: true,
  }
  try {
    const response = await LoanAPI.getSavings(params)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_SAVINGS_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_SAVINGS_FAILURE,
      err.message,
    )
  }
}

async function addLoan(eventEmitter, clientId, vlaues) {
  // await LoanAPI.lendermanipulate(clientId, vlaues)
  try {
    const response = await LoanAPI.addLoanApi(clientId, vlaues)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_ADD_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_ADD_FAILURE,
      err.message,
    )
  }
}

async function getMailTemplateList(eventEmitter, name, clientid) {
  try {
    const response = await LoanAPI.getMailTemplateListAPI(name, clientid)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_GET_MAIL_TEMPLATE_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_LOAN_GET_MAIL_TEMPLATE_FAILURE,
      err.message,
    )
  }
}

async function mailClientInfo(eventEmitter, values, name, Id, loan_id) {
  try {
    const response = await LoanAPI.mailClientInfo(name, Id, values, loan_id)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_SEND_MAIL_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_SEND_MAIL_FAILURE,
      err.message,
    )
  }
}

async function updateLoanInfoOverview(
  eventEmitter,
  values,
  id,
  particularloanDetail,
) {
  try {
    const response = await LoanAPI.updateLoanInfoOverviewAPI(
      values,
      id,
      particularloanDetail,
    )
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_UPDATE_OVERVIEW_SUCCESS,
      response,
    )
    await getLoan(eventEmitter, id)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_UPDATE_OVERVIEW_FAILURE,
      err.message,
    )
  }
}

async function removeApplicant(
  eventEmitter,
  applicant_id,
  loan_id,
  applicant_mpping,
) {
  try {
    const response = await LoanAPI.removeApplicantAPI(
      applicant_id,
      loan_id,
      applicant_mpping,
    )
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_REMOVE_APPLICANT_SUCCESS,
      response,
    )
    await getApplicants(eventEmitter, loan_id)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.CLIENT_REMOVE_APPLICANT_FAILURE,
      err.message,
    )
  }
}

async function addApplicant(
  eventEmitter,
  selected,
  client_id,
  loan_id,
  payload,
  history,
) {
  try {
    const response = await LoanAPI.addApplicantAPI(payload)
    history.push('/clients/' + client_id + '/' + loan_id)

    eventEmitter.emit(ADD_APPLICANT_EVENTS.ADD_APPLICANT_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(ADD_APPLICANT_EVENTS.ADD_APPLICANT_FAILURE, err.message)
  }
}

async function getSecurities(eventEmitter, clientId, loanId) {
  try {
    const response = await LoanAPI.getSecurityAPI(clientId, loanId)

    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_GET_SECURITY_SUCCESS,
      response
    )
 
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_GET_SECURITY_FAILURE,
      err.message,
    )
  }
}

async function addSecurity(eventEmitter, payload) {
  try {
    const response = await LoanAPI.addSecurityAPI(payload)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_ADD_SECURITY_SUCCESS,
      response,
    )
    await getSecurities(eventEmitter, payload.client_id, payload.loan)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_ADD_SECURITY_FAILURE,
      err.message,
    )
  }
}

async function updateSecurity(eventEmitter, payload) {
  try {
    const response = await LoanAPI.updateSecurityAPI(payload)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_UPDATE_SECURITY_SUCCESS,
      response,
    )
    await getSecurities(eventEmitter, payload.client_id, payload.loan)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_UPDATE_SECURITY_FAILURE,
      err.message,
    )
  }
}

async function deleteSecurity(eventEmitter, security_id, clientId, loanId) {
  try {
    const response = await LoanAPI.deleteSecurityAPI(security_id)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_DELETE_SECURITY_SUCCESS,
      response,
    )
    await getSecurities(eventEmitter, clientId, loanId)
  } catch (err) {
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_DELETE_SECURITY_FAILURE,
      err.message,
    )
  }
}

async function getLenders(eventEmitter, loanid) {
  try {
    const response = await LoanAPI.getLendersAPI(loanid)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.LOAN_GET_ALL_LENDERS_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(CLIENT_LOAN_DETAIL_EVENTS.LOAN_GET_ALL_LENDERS_FAILURE  ,err.message)
  }
}

async function updateApplicantType(eventEmitter, loanId, value, id) {
  try {
    const response = await LoanAPI.updateApplicantType(id, value)
    eventEmitter.emit(
      CLIENT_LOAN_DETAIL_EVENTS.UPDATE_APPLICANT_TYPE_SUCCESS,
      response,
    )
    await getApplicants(eventEmitter, loanId)
  } catch (err) {
    eventEmitter.emit(CLIENT_LOAN_DETAIL_EVENTS.UPDATE_APPLICANT_TYPE_FAILURE, err.message)
  }
}

export {
  getLoan,
  updateLoanInfo,
  dischargeLoan,
  getApplicants,
  getScreenACtivity,
  getClientSavings,
  addLoan,
  getMailTemplateList,
  mailClientInfo,
  updateLoanInfoOverview,
  removeApplicant,
  addApplicant,
  getSecurities,
  addSecurity,
  updateSecurity,
  deleteSecurity,
  getLenders,
  updateApplicantType,
}
