import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {Box, Button, Collapsible, Text, Image} from 'grommet'
import {FormDown} from 'grommet-icons/icons/FormDown'
import {FormNext} from 'grommet-icons/icons/FormNext'
import Tooltip from 'granite/components/Tooltip'

const MenuButton = ({
  children,
  label,
  id,
  icon,
  level,
  isActive,
  activeColor,
  isExpandable,
  isExpanded: defaultIsExpanded,
  hoverIndicatorOptions,
  onClick,
  isCollapsed,
  textColor,
  name,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultIsExpanded)
  const handleClick = () => {
    setIsExpanded(!isExpanded)
    onClick(!isExpanded)
  }
  const [hover, setHover] = useState(false)
  const handleMouseLeave = () => {
    setHover(false)
  }
  const handleMouseEnter = () => {
    setHover(true)
  }

  return (
    <>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        direction="row"
        align="center"
        justify="between"
        background={isActive ? activeColor : null}
      >
        <Button
          onClick={handleClick}
          as="div"
          fill
          size="small"
          hoverIndicator={
            isActive
              ? false
              : hoverIndicatorOptions
              ? hoverIndicatorOptions
              : true
          }
          {...rest}
        >
          <Box
            // border={{ color: 'green', size: 'small' }}
            pad={{
              vertical: 'small',
              horizontal: isCollapsed ? 'small' : 'medium',
            }}
            direction="row"
            justify={isCollapsed ? 'center' : 'start'}
            style={{
              marginLeft: !isCollapsed ? `${16 * level}px` : '0px',
            }}
          >
            <Box>
              {isCollapsed ? (
                <Tooltip content={label} borderColor="light1" round={false}>
                  <Button
                    plain
                    as="div"
                    icon={
                      icon && typeof icon === 'string' ? (
                        <span dangerouslySetInnerHTML={{__html: icon}} />
                      ) : isActive ? (
                        <Image
                          alignSelf="start"
                          alt="logo"
                          height="20px"
                          src={
                            process.env.PUBLIC_URL +
                            '/img/' +
                            name +
                            '_white.png'
                          }
                        ></Image>
                      ) : (
                        <Image
                          alignSelf="start"
                          alt="logo"
                          height="20px"
                          src={
                            process.env.PUBLIC_URL +
                            '/img/' +
                            name +
                            '.png'
                          }
                        ></Image>
                      )
                    }
                    label={
                      isCollapsed ? (
                        ''
                      ) : hover && !isActive ? (
                        <Text size="small" color="dark-6">
                          {label}
                        </Text>
                      ) : (
                        <Text size="small">{label}</Text>
                      )
                    }
                  />
                </Tooltip>
              ) : (
                <Button
                  plain
                  as="div"
                  icon={
                    icon && typeof icon === 'string' ? (
                      <span dangerouslySetInnerHTML={{__html: icon}} />
                    ) : isActive ? (
                      <Image
                        alignSelf="start"
                        alt="logo"
                        height="20px"
                        src={
                          process.env.PUBLIC_URL +
                          '/img/' +
                          name +
                          '_white.png'
                        }
                      ></Image>
                    ) : (
                      <Image
                        alignSelf="start"
                        alt="logo"
                        height="20px"
                        src={
                          process.env.PUBLIC_URL + '/img/' + name + '.png'
                        }
                      ></Image>
                    )
                  }
                  label={
                    isCollapsed ? (
                      ''
                    ) : hover && !isActive ? (
                      <Text size="small" color="dark-6">
                        {label}
                      </Text>
                    ) : (
                      <Text size="small">{label}</Text>
                    )
                  }
                />
              )}
            </Box>

            {!isCollapsed &&
              isExpandable &&
              (isExpanded ? <FormDown /> : <FormNext />)}
          </Box>
        </Button>
      </Box>
      <Collapsible open={isExpanded}>{children}</Collapsible>
    </>
  )
}

MenuButton.defaultProps = {
  icon: null,
  isActive: false,
  activeColor: 'brand',
  isCollapsed: false,
  isExpandable: false,
  isExpanded: false,
  level: 0,
  onClick: () => {},
  textColor: 'text',
}

MenuButton.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.object,
  ]),
  isActive: PropTypes.bool,
  activeColor: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isExpandable: PropTypes.bool,
  isExpanded: PropTypes.bool,
  level: PropTypes.number || PropTypes.string,
  onClick: PropTypes.func,
  textColor: PropTypes.string,
}

export default MenuButton
