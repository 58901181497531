//Library imports
import React from 'react'
import {Text, Box} from 'grommet'

//Core imports
import Slider from 'granite/components/Slider'

const RiskRate = ({risk}) => {
  return (
    <Box>
      <Text
        margin={{top: 'small', left: 'small'}}
        color="dark-1"
        size="10px"
        weight="bold"
      >
        Risk Rate Settings
      </Text>
      <Box direction="row" basis="100%">
        <Box margin={{top: 'medium'}}>
          <Box
            width="100%"
            margin={{bottom: 'small'}}
            direction="row"
            gap="small"
          >
            <Box width="25%" margin={{right: 'small'}}>
              <Text
                size="12px"
                style={{fontWeight: 500}}
                margin={{left: 'small'}}
                color="dark-1"
              >
                OOP & I
              </Text>
            </Box>
            <Box width="75%" margin={{right: 'small'}}>
              <Slider
                value={risk.oop_and_i}
                background="comp-3"
                thumb="comp-3"
                height="3px"
                thumbWidth="10px"
                thumbHeight="10px"
                edit={false}
              />
            </Box>
          </Box>

          <Box
            width="100%"
            margin={{bottom: 'small'}}
            direction="row"
            gap="small"
          >
            <Box width="25%" margin={{right: 'small'}}>
              {' '}
              <Text
                size="12px"
                style={{fontWeight: 500}}
                margin={{left: 'small'}}
                color="dark-1"
              >
                OO/IO
              </Text>
            </Box>
            <Box width="75%" margin={{right: 'small'}}>
              {' '}
              <Slider
                value={risk.oo_io}
                background="comp-3"
                thumb="comp-3"
                height="3px"
                thumbWidth="10px"
                thumbHeight="10px"
                edit={false}
              />
            </Box>
          </Box>

          <Box
            width="100%"
            margin={{bottom: 'small'}}
            direction="row"
            gap="small"
          >
            <Box width="25%" margin={{right: 'small'}}>
              {' '}
              <Text
                size="12px"
                style={{fontWeight: 500}}
                margin={{left: 'small'}}
                color="dark-1"
              >
                INV P&I
              </Text>
            </Box>
            <Box width="75%" margin={{right: 'small'}}>
              {' '}
              <Slider
                value={risk.inv_p_and_i}
                background="comp-3"
                thumb="comp-3"
                height="3px"
                thumbWidth="10px"
                thumbHeight="10px"
                edit={false}
              />
            </Box>
          </Box>
          <Box
            width="100%"
            margin={{bottom: 'small'}}
            direction="row"
            gap="small"
          >
            <Box width="25%" margin={{right: 'small'}}>
              {' '}
              <Text
                size="12px"
                style={{fontWeight: 500}}
                margin={{left: 'small'}}
                color="dark-1"
              >
                INV IO
              </Text>
            </Box>
            <Box width="75%" margin={{right: 'small'}}>
              {' '}
              <Slider
                value={risk.inv_io}
                background="comp-3"
                thumb="comp-3"
                height="3px"
                thumbWidth="10px"
                thumbHeight="10px"
                edit={false}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default RiskRate
