import dashBoardAPI from 'dashboard/gateways/dashboard-api'
import DASHBOARD_EVENTS from '../../pages/dashboard/constants'

async function getPanels(values, eventEmitter) {
  try {
    const response = await dashBoardAPI.getPanels(values)
    eventEmitter.emit(
      DASHBOARD_EVENTS.DASHBOARD_GET_PANELS_SUCCESS,
      response,
    )
  } catch (err) {
    eventEmitter.emit(
      DASHBOARD_EVENTS.DASHBOARD_GET_PANELS_FAILURE,
      err.message,
    )
  }
}

async function getDashBoardTable(eventEmitter, values) {
  try {
    const response = await dashBoardAPI.getdashboardList(values)
    eventEmitter.emit(DASHBOARD_EVENTS.DASHBOARD_GET_LIST_SUCCESS, response)
  } catch (err) {
    eventEmitter.emit(DASHBOARD_EVENTS.DASHBOARD_GET_LIST_FAILURE ,err.message)
  }
}

async function getCampaign( eventEmitter) {
    try {
      const response = await dashBoardAPI.getCampaignDetails()
      eventEmitter.emit(DASHBOARD_EVENTS.GET_CAMPAIGN_SUCCESS, response)
      return response
    } catch (err) {
       eventEmitter.emit(DASHBOARD_EVENTS.GET_CAMPAIGN_FAILURE, err.message)
      // eventEmitter.emit(CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE)
    }
  // try {
  //   const response = await dashBoardAPI.getPanels(values)
  //   eventEmitter.emit(DASHBOARD_EVENTS.DASHBOARD_GET_PANELS_SUCCESS, response)
  // } catch (err) {
  //   eventEmitter.emit(
  //     DASHBOARD_EVENTS.DASHBOARD_GET_PANELS_FAILURE,
  //     err.message,
  //   )
  // }
}

export {getPanels, getDashBoardTable, getCampaign}
