// Lib Imports
import React from 'react'
import {Box, Button, Image, Heading} from 'grommet'
import {Link} from 'react-router-dom'

const SuccessOnPasswordSubmit = () => {
  return (
    <Box>
      <Box fill="horizontal" elevation="none" align="center">
        <Image
          alignSelf="center"
          alt="logo"
          height="50px"
          src={process.env.PUBLIC_URL + '/img/success.png'}
        />
      </Box>
      <Box direction="column" gap="medium">
        <Heading
          textAlign="center"
          color="dark-1"
          level={4}
          margin={{bottom: 'none'}}
        >
          Your password has been updated successfully
        </Heading>
        <Link to="/login">
          <Box>
            <Button alignSelf="center" label="Login" primary />
          </Box>
        </Link>
      </Box>
    </Box>
  )
}

export default SuccessOnPasswordSubmit
