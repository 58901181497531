const CLOSED_EVENTS = {
  GET_CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  GET_LOAN_DETAILS_SUCCESS: 'GET_LOAN_SUCCESS',
  GET_LOAN_DETAILS_FAILURE: 'GET_LOAN_FAILURE',
  STATUS_CHANGED_SUCCESS: 'STATUS_CHANGED_SUCCESS',
  STATUS_CHANGED_FAILURE: 'STATUS_CHANGED_FAILURE',
  GET_SEARCH_SUCCESS: 'GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE: 'GET_SEARCH_FAILURE',
  SHOW_DATA_CHANGED: 'DATA_CHANGED_SUCCESS',
  GET_MORE_DETAILS_SUCCESS: 'GET_MORE_DETAILS_SUCCESS',
  GET_MORE_DETAILS_FAILURE: 'GET_MORE_DETAILS_FAILURE',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  GET_RISK_SUCCESS: 'GET_RISK_SUCCESS',
  GET_RISK_FAILURE: 'GET_RISK_FAILURE',
  GET_SAVINGS_SUCCESS: 'GET_SAVINGS_SUCCESS',
  GET_SAVINGS_FAILURE: 'GET_SAVINGS_FAILURE',

  GET_MAIL_LIST_SUCCESS: 'GET_MAIL_LIST_SUCCESS',
  GET_MAIL_LIST_FAILURE: 'GET_MAIL_LIST_FAILURE',

  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SEND_MAIL_FAILURE: 'SEND_MAIL_FAILURE',
  GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
  GET_CAMPAIGN_LIST_FAILURE: 'GET_CAMPAIGN_LIST_FAILURE',
}

export default CLOSED_EVENTS
