class CampaignListCommon {
  constructor({created_by, date, month, name, pk}) {
    this.date = date.split('-')[2]
    this.month = month
    this.month_year = month + '-' + date.split('-')[2]
    this.pk = pk
  }
}

export default CampaignListCommon
