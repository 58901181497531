class CampaignDetail {
  constructor({pk, month, name, date, created_by}) {
    this.pk = pk
    this.month = month
    this.name = name
    this.date = date
    this.created_by = created_by.name
    this.month_year = month + '-' + date.split('-')[2]
  }
}
export default CampaignDetail
