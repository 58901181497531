const SIGNUP_MESSAGES = {
  INVALID_CREDENTIALS: 'Email & Password are required.',
  LOGIN_SUCCESS: 'Logged in successfully',
  LOGIN_FAILURE: "Email and Password don't match",
  EMAIL_TOO_SHORT: 'Too Short',
  EMAIL_TOO_LONG: 'Too Long',
  EMPLOYEE_NAME_TOO_SHORT: 'Too Short',
  EMPLOYEE_NAME_TOO_LONG: 'Too Long',
  EMAIL_INVALID: 'Invalid Email',
  EMAIL_REQUIRED: 'Email is required',
  EMPLOYEE_NAME_REQUIRED: 'Employee Name is required',
  PASS_TOO_SHORT: 'Password is too short - should be 6 chars minimum.',
  PASS_TOO_LONG: 'Too Long!',
  PASS_REQUIRED: 'Password is required',
  EMAIL_LABEL: 'Email Address',
  EMAIL_PLACEHOLDER: 'Email ',
  EMPLOYEE_NAME_LABEL: 'Employee Name',
  NEW_PASS_LABEL: 'New Password',
  EMPLOYEE_NAME_PLACEHOLDER: 'Employee Name',
  NEW_PASS_PLACEHOLDER: 'New Password',
  CONFIRM_PASS_LABEL: 'Confirm Password',
  CONFIRM_PASS_PLACEHOLDER: 'Confirm Password',
  SUBMIT_BUTTON_LABEL_SUBMITTING: 'Sign in...',
  SUBMIT_BUTTON_LABEL: 'SignUp',
  PASSWORD_SUBMIT_LABEL_SUBMITTING: 'Submitting...',
  PASSWORD_SUBMIT_LABEL: 'Submit',
  FORGOT_PASS_LINK_LABEL: 'Forgot password?',
  REGISTER_LINK_LABEL: 'Create new account',
  RESET_PASSWORD_FAILURE: 'Password Reset Failed!',
  RESET_PASSWORD_SUCCESS: 'Password Updated Successfully!',
  RESET_SUCCESS: 'Success!',
  RESET_FAILURE: 'Failed!',
}

export default SIGNUP_MESSAGES
