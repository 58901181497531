import React from 'react'
import {Box, Select, Text, TextInput, Button} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import styled from 'styled-components'
import LOAN_DETAIL_MESSAGE from 'messages/clientloandetails'

const TextInput1 = styled(TextInput)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  top: 115px;
  left: 237px;
  width: 90%;
  height: 33px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  opacity: 1;
`

const StyledSelect = styled(Select)`
top: 404px;
left: 478px;
width: 90%;
height: 32px;
color:  #212121;
border-top: 1px solid #37236A important
opacity: 1
font-size: 12px;
padding-left:8px !important;
padding-right:8px !important;
padding-top:8px !important;
padding-bottom:8px !important;
`
const SelectWrapper = styled(Box)`
  bacground: #b8b8b8;
  border: 1px solid #b8b8b8;
  border-radius: 4px 4px 4px 4px;
  padding-left: 8px !important;
  top: 115px;
  left: 237px;
  width: 90%;
  height: 33px;
`
const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const OverviewForm = ({onSubmit, particularloanDetail, edit, setEdit, alrSavings}) => {
  const validationSchema = Yup.object().shape({
    campaign_month: Yup.string().required('Campaign month required'),
    outcome: Yup.string().required('Outcome required'),
    status: Yup.string().required('Status required'),
    rate: Yup.number()
      .required('Rate is required')
      .typeError('Rate must be a number'),
    // rate: Yup.number()
    //   .required('Old rate required')
    //   .typeError('Old rate must be a number'),
    // new_discount: Yup.number()
    //   // .required('New rate required')
    //   .typeError('New Discount must be a number'),
  })

  return (
    <>
      <Formik
        initialValues={{
          campaign_month: particularloanDetail.review_cycle || '',
          outcome: particularloanDetail.out_come || '',
          status: particularloanDetail.status || '',
          // new_rate: particularloanDetail.new_rate || '',
          discount: particularloanDetail.discount || '',
          // new_discount: particularloanDetail.new_discount || '',
          rate: particularloanDetail.rate || '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              fill="horizontal"
              direction="row"
              elevation="none"
              justify="between"
            ></Box>
            <Box>
              <Box
                width="100%"
                pad={{
                  left: 'small',
                  bottom: 'none',
                  right: 'none',
                  top: 'small',
                }}
              >
                <FormField
                  name="campaign_month"
                  label="Campaign Month"
                  required
                  error={touched.campaign_month && errors.campaign_month}
                >
                  <Box
                    width="90%"
                    background={
                      values.outcome !== '' ? 'brand-white' : 'brand-light1'
                    }
                    round="4px"
                  >
                    {edit ? (
                    <TextInput
                      color="dark"
                      placeholder="Campaign Month"
                      size="small"
                      value={values.campaign_month}
                      disabled={edit}
                      style={{opacity:'1', fontSize:'small'}}
                    />
                    ) : (
                    <StyledSelect
                      options={[
                        'JANUARY',
                        'FEBRUARY',
                        'MARCH',
                        'APRIL',
                        'MAY',
                        'JUNE',
                        'JULY',
                        'AUGUST',
                        'SEPTEMBER',
                        'OCTOBER',
                        'NOVEMBER',
                        'DECEMBER',
                      ]}
                      color="#212121"
                      disabled={edit}
                      placeholder={'Campaign Month'}
                      value={values.campaign_month}
                      onChange={({option}) =>
                        setFieldValue('campaign_month', option)
                      }
                    />
                    )}
                  </Box>
                </FormField>
              </Box>
              <Box
                width="100%"
                pad={{
                  left: 'small',
                  bottom: 'none',
                  right: 'none',
                  top: 'none',
                }}
              >
                <FormField
                  name="outcome"
                  label=""
                  required
                  error={touched.outcome && errors.outcome}
                >
                  <Box
                    width="90%"
                    background={
                      values.outcome !== '' ? 'brand-white' : 'brand-light1'
                    }
                    round="4px"
                  >
                    {edit ? (
                    <TextInput
                      color="dark"
                      placeholder="Outcome"
                      size="small"
                      value={values.outcome}
                      disabled={edit}
                      style={{opacity:'1', fontSize:'small'}}
                    />
                    ) : (
                    <StyledSelect
                      options={[
                        '--No Changes--',
                        '$!Money saved, no further action',
                        '$!Money saved, further action required',
                        '$!No money saved, further action required',
                      ]}
                      disabled={edit}
                      placeholder={'Outcome'}
                      value={values.outcome}
                      onChange={({option}) => setFieldValue('outcome', option)}
                    />
                    )}
                  </Box>
                </FormField>
              </Box>
              <Box
                width="100%"
                pad={{
                  left: 'small',
                  bottom: 'none',
                  right: 'none',
                  top: 'none',
                }}
              >
                <FormField
                  name="status"
                  label=""
                  required
                  error={touched.status && errors.status}
                >
                  <Box
                    width="90%"
                    background={
                      values.status !== '' ? 'brand-white' : 'brand-light1'
                    }
                    round="4px"
                  >
                    {edit ? (
                    <TextInput
                      color="dark"
                      placeholder="Status"
                      size="small"
                      value={values.status}
                      disabled={edit}
                      style={{opacity:'1', fontSize:'small'}}
                    />
                    ) : (
                    <StyledSelect
                      options={[
                        // 'Accepted',
                        // 'Contacting',
                        // 'Closed',
                        // 'In Review',
                        // 'Rejected',
                        // 'InProcess',
                        'Broker Review','Variation','No Action'
                      ]}
                      placeholder="Status"
                      value={values.status}
                      disabled={edit}
                      onChange={({option}) => setFieldValue('status', option)}
                    />
                    )}
                  </Box>
                </FormField>
              </Box>

              {/* <Box widthe="100%" pad="small">
                <Box gap="small">
                  <Text color="dark-6" weight="bold" size="12px">
                    {LOAN_DETAIL_MESSAGE.OLD_RATE}
                  </Text>
                  <Text color="dark-1" size="12px">
                    {particularloanDetail.rate}
                    {'%'}
                  </Text>
                </Box>
              </Box> */}

              {false && (
                <Box width="100%" pad="small">
                  <FormField
                    name="rate"
                    label="Old Rate"
                    required
                    error={touched.rate && errors.rate}
                  >
                    <SelectWrapper
                      background={
                        values.new_rate !== '' ? 'brand-white' : 'brand-light1'
                      }
                    >
                      <TextInput1
                        pad="rate"
                        type="rate"
                        name="rate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Old Rate"
                        size="small"
                        value={values.rate}
                        disabled={edit}
                      />
                    </SelectWrapper>
                  </FormField>
                </Box>
              )}
              <Box width="100%" pad="small">
                <FormField
                  name="rate"
                  label="Rate"
                  required
                  error={touched.rate && errors.rate}
                >
                  <SelectWrapper
                    background={
                      values.rate !== '' ? 'brand-white' : '#f6f6f69e'
                    }
                  >
                    <Box direction="row">
                      <TextInput1
                        pad
                        type="rate"
                        name="rate"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Rate"
                        size="small"
                        value={values.rate}
                        disabled={edit}
                      />
                      <Text margin="4px">%</Text>
                    </Box>
                  </SelectWrapper>
                </FormField>
              </Box>

              <Box width="100%" pad="small">
                <FormField
                  name="discount"
                  label="Discount"
                  required
                  error={touched.discount && errors.discount}
                >
                  <SelectWrapper
                    background={
                      values.discount !== '' ? 'brand-white' : '#f6f6f69e'
                    }
                  >
                    <Box direction="row">
                      <TextInput1
                        pad="discount"
                        type="discount"
                        name="discount"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder="Discount"
                        size="small"
                        value={values.discount}
                        disabled={edit}
                      />
                      <Text margin="4px">%</Text>
                    </Box>
                  </SelectWrapper>
                </FormField>
              </Box>

              {/* <Box width="100%" pad="small">
                <FormField
                  name="new_discount"
                  label="New Discount"
                  required
                  error={touched.new_discount && errors.new_discount}
                >
                  <SelectWrapper
                    background={
                      values.new_discount !== ''
                        ? 'brand-white'
                        : '#f6f6f69e'
                    }
                  >
                    <TextInput1
                      pad="new_discount"
                      type="new_discount"
                      name="new_discount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="New Discount"
                      size="small"
                      value={values.new_discount}
                      disabled={edit}
                    />
                  </SelectWrapper>
                </FormField>
              </Box> */}

              <Box widthe="100%" pad="small">
                <Box gap="small">
                  <Text color="savings" size="12px">
                    {LOAN_DETAIL_MESSAGE.SAVINGS}
                  </Text>
                  <Text color="dark-1" size="12px">
                    {alrSavings && alrSavings}
                  </Text>
                </Box>
              </Box>

              {false && (
                <Box widthe="100%" pad="small">
                  <Box gap="small">
                    <Text
                      color="dark-6"
                      weight="bold"
                      size="12px"
                      height="11px"
                    >
                      {LOAN_DETAIL_MESSAGE.PERCENTAGE}{' '}
                    </Text>
                    <Text color="dark-1" size="12px">
                      0%{' '}
                    </Text>
                  </Box>
                </Box>
              )}
            </Box>

            <Box width="100%">
              {!edit && (
                <ButtonStyle
                  margin={{vertical: 'small'}}
                  type="submit"
                  alignSelf="end"
                  label={isSubmitting ? 'Saving...' : 'Save'}
                  disabled={isSubmitting}
                  primary
                />
              )}
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
          </form>
        )}
      </Formik>
    </>
  )
}
export default OverviewForm
