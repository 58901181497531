const BROKER_MESSAGES = {
  GET_BROKER_SUCCESS: 'Success!',
  GET_BROKER_FAILURE: 'Failed!',

  ADD_CLICK_SUCCESS: 'New broker added successfully! ',
  ADD_CLICK_FAILURE: 'Failed!',

  EDIT_CLICK_SUCCESS: 'Changes saved successfully! ',
  EDIT_CLICK_FAILURE: 'Failed!',

  DELETE_CLICK_SUCCESS: 'Broker deleted successfully! ',
  DELETE_CLICK_FAILURE: 'Failed!',
}
export default BROKER_MESSAGES
