//Library imports
import React, {useState, useEffect} from 'react'
import {Box, Text} from 'grommet'
import {useHistory} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'

//Core imports
import EventEmitter from 'utils/event-emitter'
import Toast from 'granite/components/Toast'
import LoaderUI from 'granite/components/NumLoader'
import NewHeader from 'granite/components/HeaderWithDropDown'
//Application imports
import {getLoanBook} from 'clientlist/controllers/client'
import CLIENT_LIST_IMPORT_EVENTS from './constants'
import LoanBookTable from './components/LoanBookTable'
import ErroredRowsTable from './components/ErroredRowsTable'
// import CustomUpload from './components/customUpload'
import {getErroredRecords} from 'clientlist/controllers/client'
import CLIENT_LIST_MESSAGES from 'messages/ClientList'

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setLoanBookData,
  setErroredTableData,
  setToastData,
  setloader,
  filterData,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case CLIENT_LIST_IMPORT_EVENTS.GET_LOAN_BOOK_SUCCESS:
        setLoanBookData({
          data: event.data.loanBookData,
          loan_total: event.data.rowsCount,
        })
        break
      case CLIENT_LIST_IMPORT_EVENTS.GET_LOAN_BOOK_FAILURE:
        break

      case CLIENT_LIST_IMPORT_EVENTS.GET_ERRORED_RECORDS_SUCCESS:
        setErroredTableData({
          data: event.data.erroredRowsData,
          error_total: event.data.rowsCount,
        })
        break
      case CLIENT_LIST_IMPORT_EVENTS.GET_ERRORED_RECORDS_FAILURE:
        break

      case CLIENT_LIST_IMPORT_EVENTS.INCORRECT_DOWNLOAD_SUCCESS:
        if (event.data) {
          window.open(event.data, '_blank')
        } else {
          setToastData({
            open: true,
            message: CLIENT_LIST_MESSAGES.NO_DATA_TO_DOWNLOAD,
          })
          setTimeout(() => {
            setToastData({open: false, message: ''})
          }, 5000)
        }
        break
      case CLIENT_LIST_IMPORT_EVENTS.INCORRECT_DOWNLOAD_FAILURE:
        break

      case CLIENT_LIST_IMPORT_EVENTS.SAMPLE_DOWNLOAD_SUCCESS:
        if (event.data) {
          window.open(event.data, '_blank')
          setToastData({
            open: true,
            message: CLIENT_LIST_MESSAGES.SAMPLE_DOWNLOAD_SUCCESS,
          })

          setTimeout(() => {
            setToastData({open: false, message: ''})
          }, 5000)
        }
        break
      case CLIENT_LIST_IMPORT_EVENTS.SAMPLE_DOWNLOAD_FAILURE:
        setToastData({
          open: true,
          message: CLIENT_LIST_MESSAGES.SAMPLE_DOWNLOAD_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case CLIENT_LIST_IMPORT_EVENTS.FILE_UPLOAD_SUCCESS:
        const obj = {
          page: filterData.loan_page,
          page_size: filterData.loan_size,
          ordering: filterData.ordering ? filterData.ordering : '-create_date',
        }
        getLoanBook(eventEmitter, obj)
        setloader(false)
        setToastData({
          open: true,
          message: CLIENT_LIST_MESSAGES.FILE_UPLOAD_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_LIST_IMPORT_EVENTS.FILE_UPLOAD_FAILURE:
        setloader(false)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_LIST_MESSAGES.FILE_UPLOAD_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break

      default:
        break
    }
  })
  return subscription
}

const ClientListImport = ({userProfile}) => {
  const [loanBookData, setLoanBookData] = useState({data: [], loan_total: ''})
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [erroredTableData, setErroredTableData] = useState({
    data: [],
    error_total: '',
  })
  const history = useHistory()
  const [showLoanBookTable, setShowLoanBookTable] = useState(true)
  const [showErroredTable, setShowErroredTable] = useState(false)
  const [status, setStatus] = useState('In Progress')
  const [fileData, setFileData] = useState({})
  const [loader, setloader] = useState(false)

  let defaultFilterData = {
    loan_page: 1,
    loan_size: 20,
    error_page: 1,
    error_size: 20,
  }

  let location = useLocation()
  const {search} = location

  let queryParam = queryString.parse(search)

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  const handleRefresh = () => {
    setloader(true)
    if (showLoanBookTable) {
      const obj = {
        page: filterData.loan_page,
        page_size: filterData.loan_size,
        ordering: filterData.ordering ? filterData.ordering : '-create_date',
      }
      getLoanBook(eventEmitter, obj)
    } else if (showErroredTable) {
      const obj = {
        page: filterData.error_page,
        page_size: filterData.error_size,
        ordering: filterData.ordering ? filterData.ordering : 'row_number',
        is_successful: false,
      }
      getErroredRecords(eventEmitter, fileData.pk, obj)
    }
    setTimeout(() => {
      setloader(false)
    }, 1000)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setloader,
        setLoanBookData,
        setErroredTableData,
        filterData,
      })
      if (showLoanBookTable) {
        const obj = {
          page: filterData.loan_page,
          page_size: filterData.loan_size,
          ordering: filterData.ordering ? filterData.ordering : '-create_date',
        }
        getLoanBook(eventEmitter, obj)
      } else if (showErroredTable) {
        const obj = {
          page: filterData.error_page,
          page_size: filterData.error_size,
          ordering: filterData.ordering ? filterData.ordering : 'row_number',
          is_successful: false,
        }
        getErroredRecords(eventEmitter, fileData.pk, obj)
      }

      return subscription
    }

    let endsubscriber = init()
    return () => {
      endsubscriber.unsubscribe()
    }
  }, [location, showErroredTable, showLoanBookTable, fileData, filterData])
  return (
    <Box flex={{grow: 1}}>
      {loader ? (
        <Box align="center" justify="center" fill>
          <LoaderUI />
        </Box>
      ) : (
        <>
          <NewHeader
            pageTitle={'Clients > Import'}
            userName={`${userProfile.title} ${userProfile.name}`}
            handleRefresh={handleRefresh}
          />
          <Box
            pad={{
              left: 'medium',
              right: 'medium',
              top: 'none',
              bottom: 'medium',
            }}
            overflow={{vertical: 'auto'}}
            fill
          >
            {false && (
              <Box margin={{top: 'small'}} alignSelf="start">
                <Text size="16px" weight="bold" color="brand">
                  {CLIENT_LIST_MESSAGES.CLIENTS_IMPORT}
                </Text>
              </Box>
            )}

            {/* <CustomUpload eventEmitter={eventEmitter}></CustomUpload>*/}
            {showLoanBookTable && (
              <Box pad={{top: 'medium'}}>
                <LoanBookTable
                  data={loanBookData}
                  navigateToParams={navigateToParams}
                  setShowErroredTable={setShowErroredTable}
                  setShowLoanBookTable={setShowLoanBookTable}
                  setFileData={setFileData}
                  filterData={filterData}
                  eventEmitter={eventEmitter}
                  setloader={setloader}
                  location={location}
                  setToastData={setToastData}
                  setStatus={setStatus}
                />
              </Box>
            )}
            {showErroredTable && (
              <Box pad={{top: 'medium'}}>
                <ErroredRowsTable
                  data={erroredTableData}
                  navigateToParams={navigateToParams}
                  setShowErroredTable={setShowErroredTable}
                  setShowLoanBookTable={setShowLoanBookTable}
                  fileData={fileData}
                  filterData={filterData}
                  eventEmitter={eventEmitter}
                  location={location}
                  status={status}
                />
              </Box>
            )}
          </Box>
        </>
      )}
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})

// export default ClientListImport
export default connect(mapStateToProps)(ClientListImport)
