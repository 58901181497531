//Library imports
import React, {useState} from 'react'
import {Box, Layer} from 'grommet'
import styled from 'styled-components'

//Core imports
import Form from './mailform'

//Application imports
import {mailClientInfo} from 'clientloandetail/controllers/loan'

const LayerStyle = styled(Layer)`
  width: 50%;
`

const MailTemplate = ({
  event,
  route,
  showmailmodal,
  setshowmailmodal,
  maillist,
  loggedinuser,
  client,
  clientfirdtname,
  sendername,
  designation,
  templatename,
  selectedapplicant,
  tempcontent,
}) => {
  const [view, setView] = useState(true)

  const onSubmit = (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    mailClientInfo(
      event,
      {...values},
      templatename,
      selectedapplicant.applicant_mail_pk,
      route.params.id,
    )
    setSubmitting(false)
    setView(!view)
    setshowmailmodal(false)
  }

  return (
    <Box>
      <LayerStyle position="center" onEsc={() => setshowmailmodal(false)}>
        <Box width="100%" pad={{bottom: 'small'}}>
          <Form
            onSubmit={onSubmit}
            setshowmailmodal={setshowmailmodal}
            loggedinuser={'clientservices@indigofinance.com.au'}
            client={client}
            clientfirdtname={clientfirdtname}
            sendername={sendername}
            designation={designation}
            maillist={maillist}
            showmailmodal={showmailmodal}
            tempcontent={tempcontent}
          ></Form>
        </Box>
      </LayerStyle>
    </Box>
  )
}

export default MailTemplate
