import React from 'react'
import {connect} from 'react-redux'
import {Route, Redirect} from 'react-router-dom'
import {Box} from 'grommet'
import LoaderUI from 'granite/components/NumLoader'
import userAPIGateway from 'accounts/gateways/user-api'
import * as UserDucks from 'accounts/ducks/user'
import {getAuthToken, setOrganisation} from 'utils/auth-singleton'
import LinearLayout from 'granite/components/LinearLayout'
import DashboardLayout from 'granite/components/DashboardLayout'

function fetchUserProfile(dispatch) {
  userAPIGateway
    .myProfile()
    .then(response => {
      setOrganisation(response.user.organisation_id)
      dispatch({
        type: 'accounts/user/UPDATE_PROFILE',
        data: response.user,
      })
    })
    .catch(err => {
      //TODO: show error in Toast or modal
      console.error(err)
    })
  return (
    <Box
      background="brand-light"
      flex="grow"
      width="100%"
      align="center"
      justify="center"
      height="100%"
    >
      <LoaderUI></LoaderUI>
    </Box>
  )
}

const PrivateRoute = ({
  component: Component,
  layout,
  roles,
  userProfile,
  dispatch,
  ...rest
}) => {
  const authToken = getAuthToken()

  //TODO: add two more checks so that spinner is shown while organisation and selectedOrganisation are being set
  if (authToken && !userProfile.username) {
    return fetchUserProfile(dispatch)
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (!userProfile.username) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{pathname: '/login', state: {from: props.location}}}
            />
          )
        }

        // TODO:
        // check if route is restricted by role
        // if (roles && roles.indexOf(userProfile.role) === -1) {
        // role not authorised so redirect to home page
        // return <Redirect to={{pathname: '/'}} />
        // }

        // authorised so return component
        if (layout === 'HeaderLayout') {
          return (
            <LinearLayout
              pathname={props.location.pathname}
              history={props.history}
            >
              <Component {...props} />
            </LinearLayout>
          )
        }
        return (
          <DashboardLayout
            pathname={props.location.pathname}
            history={props.history}
          >
            <Component {...props} />
          </DashboardLayout>
        )
      }}
    />
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
