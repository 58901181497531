import React from 'react'
import {Box, Image, Text} from 'grommet'
import OverviewForm from './overviewform'
import styled from 'styled-components'
import LOAN_DETAIL_MESSAGE from 'messages/clientloandetails'
import {updateLoanInfoOverview} from 'clientloandetail/controllers/loan'
const TextStyle = styled(Text)`
  font-weight: 700;
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
`

const Overview = ({
  clientData,
  event,
  clientId,
  ID,
  particularloanDetail,
  eventEmitter,
  edit,
  setEdit,
  route,
  setToastWarning,
  setToastData,
  alrSavings,
}) => {
  const month = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
  ]

  const handleOverviewSunbmit = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    if (
      month.includes(values.campaign_month.toLowerCase()) &&
      values.outcome &&
      values.status &&
      // values.new_rate &&
      values.rate
    ) {
      try {
        await updateLoanInfoOverview(
          eventEmitter,
          values,
          route.params.id,
          particularloanDetail,
        )
      } catch (e) {
        console.log('Error', e)
      }
    } else {
      setToastWarning(true)
      setToastData({
        open: true,
        message: 'Please enter valid month',
      })
      setTimeout(() => {
        setToastData({open: false, message: ''})
      }, 1000)
    }

    setSubmitting(false)
  }

  return (
    <BoxWrapper
      width="90%"
      background="light-1"
      elevation="medium"
      pad="small"
      gap="small"
    >
      <Box direction="row" width="100%">
        <Box pad="small" gap="medium" direction="row">
          <Box align="start">
            <Image
              fit="contain"
              src={process.env.PUBLIC_URL + '/img/stacked.svg'}
              style={{cursor: 'pointer'}}
            />
          </Box>
          <TextStyle weight="bold" color="dark-1" size="16px">
            {LOAN_DETAIL_MESSAGE.OVERVIEW}
          </TextStyle>
        </Box>
      </Box>
      <OverviewForm
        particularloanDetail={particularloanDetail}
        edit={edit}
        setEdit={setEdit}
        onSubmit={handleOverviewSunbmit}
        alrSavings={alrSavings}
      />
    </BoxWrapper>
  )
}

export default Overview
