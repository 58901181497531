//Library Imports
import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Box, Image, Text, Button, CheckBox, Layer, ThemeContext} from 'grommet'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'

//Application imports
import {loadCampaignDetails} from 'alr/controllers/campaign'
import {CurrencyToInt} from 'granite/CurrencyConvertor'

const AddLoanWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-bottom: 2px solid #37236a;
`
const AddLoanTextWrapper = styled(Text)`
  font-weight: 700;
`
const ButtonStyle = styled(Button)`
height:36px
width:110px
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`

const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}

const COLUMNS = [
  {
    accessor: 'selection',
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    // optional: to render own custom component for table body cell
    Cell: ({row}) => (
      <span title={'Select'}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'applicant_name',
    Header: 'Applicant',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <Text color="dark-6" size="14px">
                {value}
              </Text>
              <Text color="text-1" size="10px">
                {value}
              </Text>
            </Box>
          }
          editMode={false}
          color="dark-6"
          style={{fontWeight: '500'}}
        />
      )
    },
  },
  {
    accessor: 'lender_name',
    Header: 'Lender',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'lender_bsb_code',
    Header: 'Acc No/BSB No',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <Text color="dark-6" size="14px">
                {value}
              </Text>
              <Text color="text-1" size="10px">
                {value}
              </Text>
            </Box>
          }
          editMode={false}
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'settlement_date',
    Header: 'Settlement Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'loan_limit',
    Header: 'Loan Limit',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'balance',
    Header: 'Balance',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'rate',
    Header: 'Rate',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value + '% p.a'}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'term',
    Header: 'Term',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'purpose',
    Header: 'Purpose',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'repayment_type',
    Header: 'Repayment Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'rate_type_choice',
    Header: 'Rate Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'security_name',
    Header: 'Security Address',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'modified_date',
    Header: 'Modified',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
]

const AddLoan = ({
  layer,
  setLayer,
  loanData,
  eventEmitter,
  campaignDetails,
  customerId,
  setCustomerId,
  newCustomerId,
  addLoanLoader,
  newAddedLoans,
  setNewAddedLoans,
}) => {
  const [selected, setSelected] = useState([])

  const handleAddLoansClicked = () => {
    if (selected.length > 0) {
      console.log('Selected Loans', selected)
      setCustomerId([...customerId, newCustomerId])
      setLayer(false)
      const selectedLoans = selected.map(item => {
        const newItem = {
          applicant: item.applicant_pk,
          lender: item.lender_pk,
          loan: item.pk,
          campaign_id: campaignDetails.pk,
          status: 'InProcess',
          security: item.security_pk,
          new_rate: item.rate,
          balance: CurrencyToInt(item.balance),
        }
        return newItem
      })
      setNewAddedLoans([...newAddedLoans, ...selectedLoans])
    }
  }

  useEffect(() => {
    loadCampaignDetails(eventEmitter)
  }, [eventEmitter])

  return (
    <Box>
      <Layer
        onClickOutside={() => setLayer(!layer)}
        onEsc={() => setLayer(!layer)}
      >
        <Box>
          <AddLoanWrapper direction="row" justify="between">
            <AddLoanTextWrapper
              color="dark-6"
              size="16px"
              margin={{left: '20px', top: '15px', bottom: '15px'}}
              alignSelf="start"
            >
              Add Loans
            </AddLoanTextWrapper>

            <Box
              margin={{right: '20px', top: '15px', bottom: '15px'}}
              widht="20px"
              height="20px"
            >
              <Image
                src={process.env.PUBLIC_URL + 'img/Cross.png'}
                onClick={() => setLayer(!layer)}
                fit="contain"
                style={{cursor: 'pointer'}}
              />
            </Box>
          </AddLoanWrapper>
          <Box
            margin={{top: 'small', left: '20px', right: '20px'}}
            overflow="auto"
          >
            {addLoanLoader ? (
              <Box align="center">
                <LoaderUI />
              </Box>
            ) : (
              <BoxWrapper
                margin={{top: 'medium'}}
                overflow={{horizontal: 'auto'}}
              >
                <Box style={{minWidth: '1250px'}}>
                  <ReactTable
                    data={loanData}
                    columns={COLUMNS}
                    setSelected={setSelected}
                  />
                </Box>
              </BoxWrapper>
            )}
          </Box>

          <Box
            alignSelf="end"
            gap="small"
            direction="row"
            margin={{top: 'medium', bottom: 'medium', right: 'medium'}}
          >
            <ButtonStyle
              primary
              label="Add"
              color={selected.length > 0 ? 'brand' : 'text-1'}
              disabled={selected.length > 0 ? false : true}
              onClick={() => {
                handleAddLoansClicked()
              }}
            />
            <ButtonStyle
              primary
              label="Cancel"
              onClick={() => {
                setLayer(false)
              }}
            />
          </Box>
        </Box>
      </Layer>
    </Box>
  )
}

export default AddLoan
