import {CapitalMonthObject} from 'granite/Months_Object'
class LOANINFO {
  constructor(item) {
    const OutcomeVAlues = {
      1: '--No Changes--',
      2: '$!Money saved, no further action',
      3: '$!Money saved, further action required',
      4: '$!No money saved, further action required',
    }
    const loanStatusValues = {
      1: 'Variation',
      2: 'No Action',
      3: 'Broker Review',
    }
    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }

    this.account_number = item.account_number || ''
    this.applicant = item.applicant || ''
    this.balance = item.balance || ''
    this.off_set = item.off_set || ''
    this.discount = item.discount || ''
    this.is_active = item.is_active || ''
    this.lender = item.lender ? item.lender : ''
    this.lender_pk = item.lender ? item.lender.pk : ''
    this.lender_name = item.lender ? item.lender.name : ''
    this.lender_bsb_code = item.lender ? item.lender.bsb_code : ''

    this.fixed_expiry = item.fixed_expiry || ''
    this.product = item.product ? item.product : ''
    this.product_name = item.product ? item.product.name : ''
    this.product_pk = item.product ? item.product.pk : ''
    this.product_expiry = item.product ? item.product.expiry : ''

    this.bsb_code = item.lender.bsb_code || ''
    this.loan_limit = item.loan_limit || ''
    this.loan_package = item.package ? 'YES' : 'NO'
    this.modified_date = item.modified_date || ''
    this.out_come = item.out_come || ''
    this.pk = item.pk || ''
    this.purpose = item.purpose || ''
    this.rate = item.rate || ''

    this.repayment_amount = item.repayment_amount || ''
    this.repayment_account = item.repayment_account || ''
    this.repayment_date = item.repayment_date || ''
    this.repayment_period = item.repayment_period || ''
    this.reply_choice = item.reply_choice || ''
    this.io_expiry = item.io_expiry || ''
    this.term = item.term || ''
    this.repayment_type = item.repayment_type || ''
    this.rate_type_choice = item.rate_type_choice || ''

    this.reply_choice = item.repayment_choice
    this.review_cycle = item.review_cycle
    this.security = item.security ? item.security : ''
    this.security_pk = item.security ? item.security.pk : ''
    this.security_name = item.security
      ? (item.security.street_number === null ? '' : item.security.street_number) +
        ' ' +
       ( item.security.street_name === null ? '' : item.security.street_name) +
        ' ' +
       ( item.security.street_type === null ? '' : item.security.street_type) +
        ' ' +
       ( item.security.state === null  || item.security.state === '' ? '' : statesAbbr[item.security.state]) +
        ' ' +
       ( item.security.city === null ? '' : item.security.city) +
        ' ' +
        (item.security.postcode === null ? '' : item.security.postcode) +
        ' '
      : ''
    this.settlement_date = item.settlement_date
    // this.status = item.status || ''
    this.status = loanStatusValues[item.loan_status] || ''
    this.term = item.term || ''

    this.review_cycle = CapitalMonthObject[item.review_cycle] || ''
    this.out_come = OutcomeVAlues[item.out_come] || ''
    this.new_rate = item.new_rate || ''
    // this.company_name = item.company_name || ''
    this.savings = item.savings || ''
    this.new_security = ['']
    this.discount = item.discount
    this.new_discount = item.new_discount
    this.loan_expiry_date = item.loan_expiry_date
    this.redraw_fund = item.redraw_fund
  }
}

export default LOANINFO
