//Library imports
import {connect} from 'react-redux'
import React, {useState, useEffect} from 'react'
import {Box, Image, Text} from 'grommet'
import styled from 'styled-components'

//Core imports
import UP from 'granite/components/userProfile'
import * as UserDucks from 'accounts/ducks/user'
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'

//Application import
import LOGOUT_MESSAGES from 'messages/logout'
import {resetAuthToken} from 'utils/auth-singleton'
import LOGOUT_EVENTS from 'pages/header/constants'
import Divider from 'granite/components/Divider'
import {logout} from 'accounts/controllers/logout'
import DropMenu from 'granite/components/DropMenu'

const LoanDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const DropMenuNew = styled(Box)`
  cursor: pointer;
  background: transparent;
  &:hover {
    background-color: #37236a;
    color: white;
  }
`

let eventEmitter = new EventEmitter()

function listenEvents({eventEmitter, setToastData, setToastWarning}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case LOGOUT_EVENTS.LOGOUT_SUCCESS:
        setToastData({
          open: true,
          message: LOGOUT_MESSAGES.LOGOUT_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        resetAuthToken()
        window.open('logout', '_self')
        break
      case LOGOUT_EVENTS.LOGOUT_FAILURE:
        setToastWarning(true)
        setToastData({
          open: true,
          message: LOGOUT_MESSAGES.LOGOUT_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        break
      default:
        break
    }
  })
  return subsciption
}

const Header = ({
  pageTitle,
  showTotalSavings,
  showAlrSavings,
  userName,
  handleRefresh,
  totalSavings,
  alrSavings,
  showSavings,
  hideAlrSavings,
}) => {
  const [show, setshow] = useState(false)
  const [toastWarning, setToastWarning] = useState(false)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)

  const handleLogout = () => {
    logout(eventEmitter)
  }

  const handleprofile = () => {
    setshow(!show)
  }

  const formatNumber = num => {
    if (num.includes('-')) {  //in case of negative savings
      num = parseInt(num.slice(2)).toFixed(0) //to remove decimal
      return '-$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      num = parseInt(num.slice(1)).toFixed(0) //to remove decimal
      return '$' + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }  
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastWarning,
        setToastData,
      })
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [])

  return (
    <>
      <Box direction="row" justify="between" pad="medium">
        <Box>
          <LoanDetailsTextWrapper size="16px" color="brand">
            {pageTitle}
          </LoanDetailsTextWrapper>
        </Box>
        <Box direction="row" gap="small" alignSelf="end">
          {showSavings && (
            <Box direction="row" justify="end" alignSelf="end">
              <Image
                fit="contain"
                src={process.env.PUBLIC_URL + '/img/totalSaving.png'}
              />
              <Box margin={{left: '3px'}}>
                <Text size="15px" color="brand" weight="bold">
                  Total Savings
                </Text>
                <Text size="15px" color="brand" weight="bold">
                  {`${totalSavings ? formatNumber(totalSavings) : 0}`}
                </Text>
              </Box>
            </Box>
          )}
          {showSavings && !hideAlrSavings && (
            <Box
              direction="row"
              margin={{left: 'medium'}}
              alignSelf="end"
              justify="end"
            >
              <Image
                fit="contain"
                src={process.env.PUBLIC_URL + '/img/alrSaving.png'}
              />
              <Box margin={{left: '3px'}} justify="end">
                <Text size="15px" color="comp-3" weight="bold">
                  Campaign Savings
                </Text>
                <Text size="15px" color="comp-3" weight="bold">
                  {`${alrSavings ? formatNumber(alrSavings) : 0}`}
                </Text>
              </Box>
            </Box>
          )}
          <Box direction="row" gap="medium" margin={{left: 'medium'}}>
            <DropMenu
              renderItems={() => (
                <Box>
                  {!show && (
                    <Box>
                      <DropMenuNew
                        onClick={handleprofile}
                        pad={{
                          top: 'small',
                          bottom: 'small',
                          left: 'small',
                          right: 'xxlarge',
                        }}
                      >
                        <Text size="xsmall"> Profile Settings</Text>
                      </DropMenuNew>
                      <Divider background="light-4" />
                      <DropMenuNew
                        onClick={handleLogout}
                        pad={{
                          top: 'small',
                          bottom: 'small',
                          left: 'small',
                          right: 'large',
                        }}
                      >
                        <Text size="xsmall">Logout</Text>
                      </DropMenuNew>
                    </Box>
                  )}{' '}
                </Box>
              )}
              // hoverIndicatorOptions={{color: 'blue'}}
              label={userName}
              reverse={true}
              labelcolor={'brand'}
              buttonicon={
                <Image src={process.env.PUBLIC_URL + '/img/down_arrow.png'} />
              }
            />
            <Box>
              <Image
                onClick={handleRefresh}
                src={process.env.PUBLIC_URL + '/Images/Refresh.png'}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {show && (
        <Box>
          <UP setshow={setshow} />
        </Box>
      )}

      {toastData.open ? (
        toastWarning ? (
          <Toast text={toastData.message} background="warning" />
        ) : (
          <Toast text={toastData.message} />
        )
      ) : null}
    </>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
