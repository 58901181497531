const baseSpacing = 30

const theme = {
  anchor: {
    fontWeight: 400,
    extend: {
      fontSize: '14px',
      color: '#0F2BB4',
    },
  },
  select: {
    control: {
      extend: {
        borderTop: 'none !important',
        borderLeft: 'none !important',
        borderRight: 'none !important',
      },
    },
    options: {
      text: {size: '14px'},
    },
  },
  radioButton: {
    gap: 'xsmall',
    extend: {
      direction: 'row',
      fontSize: '14px',
      fontWeight: 400,
      color: 'dark-4',
      gap: 'medium',
    },
    icon: {
      size: '16px',
      extend: {
        flexDirection: 'row',
        gap: 'medium',
      },
    },
    check: {
      extend: {
        gap: 'medium',
      },
    },
  },
  button: {
    color: '#FFFFFF',
    extend: {
      fontWeight: 400,
      fontSize: '14px',
      margin: {bottom: '2.25rem'},
    },
    padding: {
      horizontal: '2rem',
    },
    border: {
      radius: '4px',
    },
  },
  formField: {
    label: {
      size: '14px',
      margin: {horizontal: 'none', vertical: 'small'},
      color: 'dark-1',
      weight: 700,
    },
    error: {
      margin: {horizontal: 'none', vertical: 'small'},
      weight: 400,
      size: '0.875rem',
    },
  },
  global: {
    opacity: {
      medium: '1px',
    },
    animation: {
      duration: '0.2s',
    },
    borderSize: {
      xsmall: '0.5px',
      small: '2px',
      medium: '4px',
      large: '6px',
      xlarge: '12px',
    },
    drop: {
      shadowSize: 'large',
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '0.125rem',
      xsmall: '0.25rem',
      small: '0.5rem',
      xxlarge: '8rem',
      xxlargePercentage: '8%',
    },
    hover: {
      background: {
        color: '#37236A',
      },
    },
    size: {
      xxsmall: '32px',
      xsmall: '64px',
      small: '128px',
      medium: '256px',
      large: '512px',
      xlarge: '768px',
      full: '100%',
      hair: '15px',
      thin: '27px',
    },
    colors: {
      sidebar_hover: '#D6C6E9',
      'accent-1': '#162D6E',
      brand: '#37236A',
      'brand-1p': '#0F2BB4',
      'brand-3p': '#ccdef04f',
      'brand-20p': '#ccdef0',
      'brand-30p': '#F7F7F7',
      'brand-40p': '#F6F6F6',
      'brand-light': '#F6F6F6',
      'brand-white': '#FFFFFF',
      'brand-light1': '#EEF1F4',
      'brand-light12': '#1E1044',
      'background-sidebar': '#FAFAFA',
      'table-header': '#1E1044',
      border: '#CBCBCB',
      'comp-1': '#2d6e16',
      'comp-2': '#6e162d',
      'comp-3': '#3EB049',
      'comp-4': '#982C40',
      'dark-1': '#212121',
      'dark-6': '#000000',
      'dark-2': '#707070',
      'dark-3': '#646464',
      'dark-4': '#1E1044',
      'dark-5': '#8B8B8B',
      'dark-7': '#B1B8C0',
      'dark-8': '#F05151',
      'dark-9': '#EEF1F4',
      dark: '#000000',
      'savings-1': '#37236A',
      'savings-2': '#3EB049',
      warning: 'red',
      warningButton: '#d41a3d',

      rowactive: '#b8b8b8',
      savings: '#3EB049',

      'round-border': '#b8b8b8',
      row_error: '#FA8072',

      panel_2_side: '#C6E9C6 ',
      panel_2_text: '#44B544',

      panel_1_side: '#AC98C5 ',
      panel_1_text: '#37236A',

      panel_3_side: '#FFEFB2 ',
      panel_3_text: '#FFCB00',

      panel_4_side: '#F8A8A8 ',
      panel_4_text: '#EF3637',

      panel_5_side: '#C5DEF6',
      panel_5_text: '#1A7CDC',

      boxTop: '#37236a',
      hover: '#ccdef0',
      'light-1': '#FFFFFF',
      'light-2': '#EFEFEF',
      'light-3': '#EEF1F4',
      'blue-email': '#1964AC',
      'text-1': '#8B8B8B',
      'text-2': '#000000',
      placeholder: '##B1B8C0',
      secondary: 'rgb(68,68,68)',
      // text: {dark: '#ffffff', light: '#ffffff'},
      text: {dark: 'white', light: '#707070'},
    },
    focus: {
      border: {
        color: 'none',
      },
    },
    font: {
      family: "'Roboto', sans-serif",
      /* size: '16px',
	height: '20px', */
    },
    input: {
      padding: `${baseSpacing / 2}px`,
      weight: 400,
    },
  },
  heading: {
    responsiveBreakpoint: 'xsmall',
    extend: {
      marginTop: 25,
    },
    font: {
      weight: 500,
      family: "'Roboto', sans-serif;",
    },
    level: {
      1: {
        font: {
          weight: 500,
        },
        // default size to heading level 1
        medium: {
          size: '30px',
          maxWidth: 'none',
        },
      },
      margin: '20px',
    },
  },
  paragraph: {
    // default size to paragraph
    medium: {
      size: '1.125rem',
      height: '1.5rem',
      maxWidth: 'none',
    },
    extend: {
      fontWeight: 300,
    },
  },
  calendar: {
    extend: {
      color: '#000',
    },
    small: {
      daySize: '18px',
    },
    medium: {
      daySize: '36px',
    },
    large: {
      daySize: '72px',
    },
  },
  text: {
    alert: {
      size: '0.8rem',
      maxWidth: 'none',
    },
    medium: {
      size: '1rem',
      height: '1.5rem',
      maxWidth: 'none',
    },
    heroDesc: {
      size: '1.875rem',
      height: 'normal',
      maxWidth: 'none',
    },
  },
  textInput: {
    extend: {
      fontSize: '1rem',
      borderRadius: 0,
      borderTop: 'none !important',
      borderRight: 'none !important',
      borderLeft: 'none !important',
      paddingLeft: '0 !important',
      boxShadow: 'none',
    },
    placeholder: {
      extend: {
        fontWeight: '400',
      },
    },
  },
  maskedInput: {
    extend: {
      background: 'white',
      fontSize: '1rem',
    },
  },
}

export default theme
