const REVIEW_MESSAGES = {
  GET_LOAN_DETAILS_SUCCESS: 'Successfully Get!',
  GET_LOAN_DETAILS_FAILURE: 'Failed!',
  GET_SEARCH_SUCCESS: 'Successfully Get!',
  GET_SEARCH_FAILURE: 'Failed!',
  GET_CAMPAIGN_SUCCESS: 'Successfully Get!',
  GET_CAMPAIGN_FAILURE: 'Failed!',
  STATUS_SAVE_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",
  STATUS_OUTCOME_BOTH_WARNING: 'Please Update Outcome and Status Both',
  // OUTCOME_MAIL_SUCCESS: 'Email Successfully Sent!',
  // OUTCOME_MAIL_FAILURE:
  //   "We're sorry, something went wrong. We are working on fixing it as soon as possible",
  SEND_MAIL_SUCCESS: 'Email sent successfully!',
  SEND_MAIL_FAILURE:
    "We're sorry, something went wrong. We are working on fixing it as soon as possible",  
}

export default REVIEW_MESSAGES
