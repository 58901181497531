import React from 'react'
import styled from 'styled-components'
import {Box} from 'grommet'
import Footer from 'granite/components/Footer'

const StyledBox = styled(Box)`
  min-height: 100%;
`

const FullScreenLayout = ({children}) => {
  return (
    <StyledBox>
      {children}
      <Footer />
    </StyledBox>
  )
}

export default FullScreenLayout
