
class ClientDetails {
  constructor({
    pk,
    name,
    email,
    phone,
    title,
    review_cycle,
    first_name,
    last_name,
    company_name,
    company_abn,
    client_type,
  }) {
    const month = {
      1: 'January',
      2: 'February',
      3: 'March',
      4: 'April',
      5: 'May',
      6: 'June',
      7: 'July',
      8: 'August',
      9: 'September',
      10: 'October',
      11: 'November',
      12: 'Decemeber',
    }

    const Client_Type_Mapping = {
      Individual: 'IN',
      Company: 'CO',
      INDIVIDUAL: 'IN',
      COMPANY: 'CO',
      IN: 'Individual',
      CO: 'Company',
    }

    this.pk = pk
    this.user_name = name
    this.user_email = email
    this.first_name = first_name
    this.last_name = last_name
    this.user_phone = phone
    this.title = title
    this.review_cycle = month[review_cycle]
    this.company_name = company_name
    this.company_ABN = company_abn
    this.client_type = Client_Type_Mapping[client_type]
  }
}
export default ClientDetails
