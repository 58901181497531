import React, {useState} from 'react'
import {Box, Image, Text, Button, Layer} from 'grommet'
import styled from 'styled-components'
import Form from './notesForm'
import {addClientDetailsNotes} from 'clientDetails/controllers/client'
import {dischargeLoan} from 'clientloandetail/controllers/loan'
import LOAN_DETAIL_MESSAGE from 'messages/clientloandetails'

const LayerStyle = styled(Layer)``

const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const Notes = ({layer, setLayer, notesData, event, route, permissions, loanNotesLayer, setLoanNotesLayer}) => {
  const [view, setView] = useState(true)

  const handleAddNotes = () => {
    setView(!view)
    setLayer(!layer)
  }

  const dischargeLoanSubmit = (values, {setSubmitting, setErrors}) => {
    let clientid = route.params.clientid
    let loanid = route.params.id
    setErrors({})
    setSubmitting(true)
    addClientDetailsNotes(event, {...values}, clientid, route, 'clientLoanDetails')
    dischargeLoan(event, loanid)
    // setView(!view)
    // setLayer(!layer)
    setLoanNotesLayer(false)
    setSubmitting(false)
    setView(!view)
  }

  return (
    <>
      {view && (
        <Box>
          <LayerStyle
            onEsc={() => setLayer(false)}
            onClickOutside={() => setLayer(false)}
          >
            <Box width="100%" height="100%">
              <MoreDetailsWrapper direction="row" justify="between">
                <MoreDetailsTextWrapper
                  color="dark-6"
                  size="16px"
                  margin={{left: '20px', top: '15px', bottom: '15px'}}
                >
                  {LOAN_DETAIL_MESSAGE.DISCHARGE_LOAN}
                </MoreDetailsTextWrapper>

                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                  }
                  onClick={() => setLayer(!layer)}
                />
              </MoreDetailsWrapper>

              <Box
                margin={{
                  top: 'small',
                  left: 'small',
                  right: 'small',
                  bottom: 'none',
                }}
                direction="column"
                gap="medium"
                pad={{bottom: 'none'}}
              >
                <Text>{LOAN_DETAIL_MESSAGE.DISCHARGE_LOAN_WARNING}</Text>
              </Box>
            </Box>
            <Box margin={{bottom: 'medium'}}>
              <Form
                onSubmit={dischargeLoanSubmit}
                handleAddNotes={handleAddNotes}
              />
            </Box>
          </LayerStyle>
        </Box>
      )}
    </>
  )
}

export default Notes
