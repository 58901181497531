import React, {useState, useEffect} from 'react'
import {Text, Box, Button, TextInput, Select, Image, CheckBox} from 'grommet'
import styled from 'styled-components'
import Slider from 'granite/components/Slider'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'
import {riskRateClicked} from 'alr/controllers/risk'
import {getDataBasedOnRiskFilter} from 'apiriskreportsettings/controllers/riskreportcontroller'
import Toast from '../../granite/components/Toast'
import RISK_RATE_MESSAGES from 'messages/riskRate'
import RISK_RATE_EVENTS from './constants'
import EventEmitter from 'utils/event-emitter'
import NewHeader from 'granite/components/HeaderWithDropDown'
import LoaderUI from 'granite/components/NumLoader'
import ReportTable from './components/ReportTable'
import {useHistory, useLocation} from 'react-router-dom'
import {riskreportloadCampaignDetails} from 'apiriskreportsettings/controllers/riskreportcontroller'
import queryString from 'query-string'
import MoreDetails from 'pages/contacting/components/MoreDetails'
import CONTACTING_EVENTS from 'pages/contacting/constants'
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
  border-top: 2px solid #37236a;
`
const TextWrapper = styled(Text)`
  font-weight: 500;
`

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
  color:#000000;
`

const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 3px #00000029;
  border: 1px solid #b8b8b8;
  border-radius-right: 4px !important;
  padding-left: 14px !important;
`

const SelectWrapper = styled(Select)`
  border: 0.5px solid #b1b8c0;
  border-radius: 4px;
  padding-left: 14px !important;
  height: 36px !important;
  border: none !important;
  font-size: 14px;
  color: #000000;
`
let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setRisk,
  setLoader,
  settableData,
  setcampaignData,
  filterData,
  setMoreData,
  setMoreLoader,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case RISK_RATE_EVENTS.GET_RISK_SUCCESS:
        setRisk(event.data)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break

      case RISK_RATE_EVENTS.GET_RISK_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.GET_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break

      case RISK_RATE_EVENTS.SAVE_RISK_SUCCESS:
        setToastData({
          open: true,
          message: RISK_RATE_MESSAGES.SAVE_RISK_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case RISK_RATE_EVENTS.SAVE_RISK_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.SAVE_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case RISK_RATE_EVENTS.GET_LOAN_TABLE_BASED_ON_RISK_DATA_SUCCESS:
        settableData(event.data)
        setTimeout(() => {
          setLoader(false)
        }, 1000)

        break

      case RISK_RATE_EVENTS.GET_LOAN_TABLE_BASED_ON_RISK_DATA_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.SAVE_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break

      case RISK_RATE_EVENTS.ADD_LOAN_BASED_ON_RISK_RATE_SUCCESS:
        setToastData({
          open: true,
          message: event.data,
        })
        setTimeout(() => {
          setLoader(false)
        }, 5000)
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        const obj1 = {
          params: filterData,
        }
        getDataBasedOnRiskFilter(eventEmitter, obj1)
        break

      case RISK_RATE_EVENTS.ADD_LOAN_BASED_ON_RISK_RATE_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.SAVE_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case RISK_RATE_EVENTS.GET_CAMPAIGN_SUCCESS:
        setcampaignData(event.data)
        break

      case RISK_RATE_EVENTS.GET_CAMPAIGN_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.SAVE_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CONTACTING_EVENTS.GET_MORE_DETAILS_SUCCESS:
        setMoreData(event.data)
        setMoreLoader(false)
        break
      case CONTACTING_EVENTS.GET_MORE_DETAILS_FAILURE:
        break

      default:
        break
    }
  })
  return subscription
}

const Risk = ({userProfile}) => {
  const [loader, setLoader] = useState(true)
  let location = useLocation()
  let history = useHistory()
  const {search} = location
  let queryParam = queryString.parse(search)
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [campaignData, setcampaignData] = useState()
  const [risk, setRisk] = useState({})
  const [moreData, setMoreData] = useState([])
  const [moreLoader, setMoreLoader] = useState(false)
  const [moreDetailLayer, setMoreDetailLayer] = useState(false)
  const [riekRate, setriekRate] = useState({
    riskRate1: 0,
    checked_riskRate1: queryParam.oop_and_i ? true : false,
    riskRate2: 0,
    checked_riskRate2: queryParam.oo_io ? true : false,
    riskRate3: 0,
    checked_riskRate3: queryParam.inv_p_and_i ? true : false,
    riskRate4: 0,
    checked_riskRate4: queryParam.inv_io ? true : false,
    rate_type_choice: queryParam.rate_type_choice
      ? queryParam.rate_type_choice
      : '',
  })
  const [change, setchange] = useState(true)
  const [tableData, settableData] = useState([])

  let defaultFilterData = {
    page: 1,
    page_size: 20,
  }

  const [selcetdcampaign, setselcetdcampaign] = useState(
    queryParam.month_year ? queryParam.month_year : '',
  )
  const [statusValue, setStatusValue] = useState(
    queryParam.rate_type_choice ? queryParam.rate_type_choice : '',
  )
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })

  const [filterData, setFilterData] = useState(defaultFilterData)
  const [filterImageUrl, setFilterImageUrl] = useState(
    process.env.PUBLIC_URL + '/img/down_arrow.png',
  )

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setRisk,
        setLoader,
        setchange,
        settableData,
        setcampaignData,
        filterData,
        setMoreData,
        setMoreLoader,
      })
      riskRateClicked(eventEmitter)
      const obj = {
        params: filterData,
      }
      getDataBasedOnRiskFilter(eventEmitter, obj)
      riskreportloadCampaignDetails(eventEmitter)
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [filterData])

  useEffect(() => {
    setriekRate({
      riskRate1: queryParam.oop_and_i ? queryParam.oop_and_i : risk.oop_and_i,
      riskRate2: queryParam.oo_io ? queryParam.oo_io : risk.oo_io,
      riskRate3: queryParam.inv_p_and_i
        ? queryParam.inv_p_and_i
        : risk.inv_p_and_i,
      riskRate4: queryParam.inv_io ? queryParam.inv_io : risk.inv_io,
      checked_riskRate1: queryParam.oop_and_i ? true : false,
      checked_riskRate2: queryParam.oo_io ? true : false,
      checked_riskRate3: queryParam.inv_p_and_i ? true : false,
      checked_riskRate4: queryParam.inv_io ? true : false,
      rate_type_choice: queryParam.rate_type_choice
        ? queryParam.rate_type_choice
        : '',
    })
  }, [
    risk,
    queryParam.oop_and_i,
    queryParam.oo_io,
    queryParam.inv_p_and_i,
    queryParam.inv_io,
    queryParam.rate_type_choice,
  ])

  // const handleSetChecked = (value, name) => {
  //   riekRate[name] = value
  // }

  const handleSearchCancel = () => {
    setSearchValue('')
    // navigateToParams({page: 1, page_size: 10, search: null})
  }

  const handleSearchClicked = () => {
    setLoader(true)
    // const obj = {
    //   params: filterData,
    // }
    // obj.params['search'] = searchValue
    // obj.params['page'] = 1
    // obj.params['page_size'] = 10
    // obj.params['rate_type_choice'] = statusValue === 'All' ? '' : statusValue
    // if (riekRate.checked_riskRate1) {
    //   obj.params['oop_and_i'] = riekRate.riskRate1
    // } else delete obj.params.oop_and_i
    // if (riekRate.checked_riskRate2) {
    //   obj.params['oo_io'] = riekRate.riskRate2
    // } else delete obj.params.oo_io
    // if (riekRate.checked_riskRate3) {
    //   obj.params['inv_p_and_i'] = riekRate.riskRate3
    // } else delete obj.params.inv_p_and_i
    // if (riekRate.checked_riskRate4) {
    //   obj.params['inv_io'] = riekRate.riskRate4
    // } else delete obj.params.inv_io

    navigateToParams({
      sorting: null,
      page: 1,
      page_size: 20,
      search: searchValue,
      rate_type_choice: riekRate.rate_type_choice,
      oop_and_i: riekRate.checked_riskRate1 ? riekRate.riskRate1 : '',
      oo_io: riekRate.checked_riskRate2 ? riekRate.riskRate2 : '',
      inv_p_and_i: riekRate.checked_riskRate3 ? riekRate.riskRate3 : '',
      inv_io: riekRate.checked_riskRate4 ? riekRate.riskRate4 : '',
    })
    // getDataBasedOnRiskFilter(eventEmitter, obj)
  }

  const handleMouseEnter = () => {
    if (statusValue !== '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/search_cancel.png')
    }
  }
  const handleMouseLeave = () => {
    if (statusValue === '') {
      setFilterImageUrl(process.env.PUBLIC_URL + '/img/down_arrow.png')
    }
  }
  const handleFilter = value => {
    // navigateToParams({rate_type_choice: value, search: null})
  }

  const handleRefresh = () => {
    setSearchValue('')
    setStatusValue('')
    filterData.search = ''
    filterData.rate_type_choice = ''
    setLoader(true)
    riskRateClicked(eventEmitter)
    navigateToParams({
      sorting: null,
      page: 1,
      page_size: 20,
      search: '',
      rate_type_choice: '',
      oop_and_i: '',
      oo_io: '',
      inv_p_and_i: '',
      inv_io: '',
    })
    // getDataBasedOnRiskFilter(eventEmitter, obj)
  }

  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'Risk Rate Report'}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
      />
      <Box overflow={{vertical: 'auto'}} fill>
        {loader ? (
          <Box align="center" justify="center" fill>
            <LoaderUI />
          </Box>
        ) : (
          <Box
            width="100%"
            height="100%"
            pad={{
              top: 'none',
              bottom: 'none',
              left: 'medium',
              right: 'medium',
            }}
          >
            <Box width="100%" margin={{top: '25px', bottom: 'medium'}}>
              <BoxWrapper width="100%" background="light-1">
                <Box direction="row">
                  <Box alignSelf="start" basis="3/4">
                    <TextWrapper
                      size="14px"
                      margin={{top: 'small', left: 'small'}}
                      textAlign="start"
                      color="dark-1"
                    >
                      Risk Rate Report
                    </TextWrapper>
                  </Box>
                  <Box
                    basis="1/4"
                    margin={{top: 'small', right: 'small'}}
                    justify="end"
                    gap="medium"
                    height="36px"
                    direction="row"
                  >
                    <ButtonStyle
                      type="submit"
                      alignSelf="end"
                      label={'Search'}
                      primary
                      disabled={false}
                      onClick={() => handleSearchClicked()}
                    />
                  </Box>
                </Box>
                <Box width="100%" gap="xsmall">
                  <Box pad={{top: 'xsmall', left: 'small', right: 'small'}}>
                    <Box
                      gap="large"
                      direction="row"
                      justify="between"
                      width="100%"
                      margin={{top: 'medium', bottom: 'small'}}
                    >
                      <Box gap="medium" direction="row" width="50%">
                        <SearchBoxWrapper
                          width="100%"
                          direction="row"
                          height="36px"
                          align="end"
                          round="4px"
                        >
                          <TextInputWrapper
                            placeholder=" Name, Phone No., Email Search"
                            value={searchValue}
                            onChange={event => {
                              setSearchValue(event.target.value)
                            }}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                handleSearchClicked()
                              }
                            }}
                          />
                          <Button
                            alignSelf="center"
                            icon={
                              <Image
                                src={
                                  process.env.PUBLIC_URL +
                                  '/img/search_cancel.png'
                                }
                                fit="contain"
                              />
                            }
                            onClick={handleSearchCancel}
                          />
                        </SearchBoxWrapper>
                        <Box
                          height="36px"
                          background={statusValue ? 'light-1' : 'brand-light1'}
                          direction="row"
                          border={{color: 'round-border'}}
                          round="4px"
                          onMouseEnter={() => handleMouseEnter()}
                          onMouseLeave={() => handleMouseLeave()}
                          elevation="small"
                        >
                          <SelectWrapper
                            options={['All', 'FIXED', 'VAR']}
                            icon={
                              <Image
                                src={filterImageUrl}
                                fit="contain"
                                onClick={event => {
                                  if (statusValue !== '') {
                                    // navigateToParams({rate_type_choice: null})
                                    setStatusValue('')
                                    event.stopPropagation()
                                  }
                                }}
                              />
                            }
                            placeholder="Rate Type"
                            value={riekRate.rate_type_choice}
                            onChange={({option}) => {
                              // setStatusValue(option)
                              setriekRate({
                                riskRate1: riekRate.riskRate1,
                                riskRate2: riekRate.riskRate2,
                                riskRate3: riekRate.riskRate3,
                                riskRate4: riekRate.riskRate4,
                                checked_riskRate1: riekRate.checked_riskRate1,
                                checked_riskRate2: riekRate.checked_riskRate2,
                                checked_riskRate3: riekRate.checked_riskRate3,
                                checked_riskRate4: riekRate.checked_riskRate4,
                                rate_type_choice: option,
                              })
                              handleFilter(option)
                            }}
                          />
                        </Box>
                      </Box>
                      {false && (
                        <Box
                          align="end"
                          gap="medium"
                          height="36px"
                          direction="row"
                        >
                          <ButtonStyle
                            type="submit"
                            alignSelf="end"
                            label={'Search'}
                            primary
                            disabled={false}
                            onClick={() => handleSearchClicked()}
                            // onClick={handleAddLoan}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box direction="row" width="100%" gap="medium">
                    <Box basis="1/2" pad="small">
                      <Box direction="row" gap="small" margin={{top: 'medium'}}>
                        <Box>
                          <CheckBox
                            checked={riekRate.checked_riskRate1}
                            label={
                              <TextWrapper
                                size="12px"
                                alignText="start"
                                color="dark-1"
                              >
                                {'OO/P&I'}
                              </TextWrapper>
                            }
                            onChange={event => {
                              setriekRate({
                                riskRate1: riekRate.riskRate1,
                                riskRate2: riekRate.riskRate2,
                                riskRate3: riekRate.riskRate3,
                                riskRate4: riekRate.riskRate4,
                                checked_riskRate1: event.target.checked,
                                checked_riskRate2: riekRate.checked_riskRate2,
                                checked_riskRate3: riekRate.checked_riskRate3,
                                checked_riskRate4: riekRate.checked_riskRate4,
                                rate_type_choice: riekRate.rate_type_choice,
                              })
                            }}
                          />
                        </Box>

                        <Box
                          width="501px"
                          pad={{left: '4px'}}
                          margin={{top: 'xsmall'}}
                        >
                          <Slider
                            toast={true}
                            background="comp-3"
                            thumb="comp-3"
                            height="7px"
                            thumbWidth="20px"
                            thumbHeight="20px"
                            value={parseFloat(riekRate.riskRate1)}
                            edit={change}
                            max={10.0}
                            step={0.01}
                            onChange={value => {
                              if (value <= 10) {
                                setriekRate({
                                  riskRate1: value,
                                  riskRate2: riekRate.riskRate2,
                                  riskRate3: riekRate.riskRate3,
                                  riskRate4: riekRate.riskRate4,
                                  checked_riskRate1: riekRate.checked_riskRate1,
                                  checked_riskRate2: riekRate.checked_riskRate2,
                                  checked_riskRate3: riekRate.checked_riskRate3,
                                  checked_riskRate4: riekRate.checked_riskRate4,
                                  rate_type_choice: riekRate.rate_type_choice,
                                })
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <Box direction="row" gap="small" margin={{top: 'medium'}}>
                        <Box>
                          <CheckBox
                            checked={riekRate.checked_riskRate2}
                            label={
                              <TextWrapper
                                size="12px"
                                alignText="start"
                                color="dark-1"
                              >
                                {'OO/IO'}
                              </TextWrapper>
                            }
                            onChange={event =>
                              setriekRate({
                                riskRate1: riekRate.riskRate1,
                                riskRate2: riekRate.riskRate2,
                                riskRate3: riekRate.riskRate3,
                                riskRate4: riekRate.riskRate4,
                                checked_riskRate1: riekRate.checked_riskRate1,
                                checked_riskRate2: event.target.checked,
                                checked_riskRate3: riekRate.checked_riskRate3,
                                checked_riskRate4: riekRate.checked_riskRate4,
                                rate_type_choice: riekRate.rate_type_choice,
                              })
                            }
                          />
                        </Box>

                        <Box
                          width="501px"
                          pad={{left: '4px'}}
                          margin={{top: 'xsmall'}}
                        >
                          <Slider
                            toast={true}
                            background="comp-3"
                            thumb="comp-3"
                            height="7px"
                            thumbWidth="20px"
                            thumbHeight="20px"
                            max={10.0}
                            step={0.01}
                            value={parseFloat(riekRate.riskRate2)}
                            edit={change}
                            onChange={value => {
                              if (value <= 10) {
                                setriekRate({
                                  riskRate1: riekRate.riskRate1,
                                  riskRate2: value,
                                  riskRate3: riekRate.riskRate3,
                                  riskRate4: riekRate.riskRate4,
                                  checked_riskRate1: riekRate.checked_riskRate1,
                                  checked_riskRate2: riekRate.checked_riskRate2,
                                  checked_riskRate3: riekRate.checked_riskRate3,
                                  checked_riskRate4: riekRate.checked_riskRate4,
                                  rate_type_choice: riekRate.rate_type_choice,
                                })
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box basis="1/2" pad="small">
                      <Box direction="row" gap="small" margin={{top: 'medium'}}>
                        <Box>
                          <CheckBox
                            checked={riekRate.checked_riskRate3}
                            label={
                              <TextWrapper
                                size="12px"
                                alignText="start"
                                color="dark-1"
                              >
                                {' INV/P&I'}
                              </TextWrapper>
                            }
                            onChange={event =>
                              setriekRate({
                                riskRate1: riekRate.riskRate1,
                                riskRate2: riekRate.riskRate2,
                                riskRate3: riekRate.riskRate3,
                                riskRate4: riekRate.riskRate4,
                                checked_riskRate1: riekRate.checked_riskRate1,
                                checked_riskRate2: riekRate.checked_riskRate2,
                                checked_riskRate3: event.target.checked,
                                checked_riskRate4: riekRate.checked_riskRate4,
                                rate_type_choice: riekRate.rate_type_choice,
                              })
                            }
                          />
                        </Box>

                        <Box
                          width="501px"
                          pad={{left: '4px'}}
                          margin={{top: 'xsmall'}}
                        >
                          <Slider
                            toast={true}
                            background="comp-3"
                            thumb="comp-3"
                            height="7px"
                            thumbWidth="20px"
                            thumbHeight="20px"
                            max={10.0}
                            step={0.01}
                            value={parseFloat(riekRate.riskRate3)}
                            edit={change}
                            onChange={value => {
                              if (value <= 10) {
                                setriekRate({
                                  riskRate1: riekRate.riskRate1,
                                  riskRate2: riekRate.riskRate2,
                                  riskRate3: value,
                                  riskRate4: riekRate.riskRate4,
                                  checked_riskRate1: riekRate.checked_riskRate1,
                                  checked_riskRate2: riekRate.checked_riskRate2,
                                  checked_riskRate3: riekRate.checked_riskRate3,
                                  checked_riskRate4: riekRate.checked_riskRate4,
                                  rate_type_choice: riekRate.rate_type_choice,
                                })
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      <Box direction="row" gap="small" margin={{top: 'medium'}}>
                        <Box>
                          <CheckBox
                            checked={riekRate.checked_riskRate4}
                            label={
                              <TextWrapper
                                size="12px"
                                alignText="start"
                                color="dark-1"
                              >
                                {'INV/IO'}
                              </TextWrapper>
                            }
                            onChange={event =>
                              setriekRate({
                                riskRate1: riekRate.riskRate1,
                                riskRate2: riekRate.riskRate2,
                                riskRate3: riekRate.riskRate3,
                                riskRate4: riekRate.riskRate4,
                                checked_riskRate1: riekRate.checked_riskRate1,
                                checked_riskRate2: riekRate.checked_riskRate2,
                                checked_riskRate3: riekRate.checked_riskRate3,
                                checked_riskRate4: event.target.checked,
                                rate_type_choice: riekRate.rate_type_choice,
                              })
                            }
                          />
                        </Box>

                        <Box
                          margin={{top: 'xsmall'}}
                          width="501px"
                          pad={{left: 'xsmall'}}
                        >
                          <Slider
                            toast={true}
                            background="comp-3"
                            thumb="comp-3"
                            height="7px"
                            thumbWidth="20px"
                            thumbHeight="20px"
                            value={parseFloat(riekRate.riskRate4)}
                            edit={change}
                            max={10.0}
                            step={0.01}
                            onChange={value => {
                              if (value <= 10) {
                                setriekRate({
                                  riskRate1: riekRate.riskRate1,
                                  riskRate2: riekRate.riskRate2,
                                  riskRate3: riekRate.riskRate3,
                                  riskRate4: value,
                                  checked_riskRate1: riekRate.checked_riskRate1,
                                  checked_riskRate2: riekRate.checked_riskRate2,
                                  checked_riskRate3: riekRate.checked_riskRate3,
                                  checked_riskRate4: riekRate.checked_riskRate4,
                                  rate_type_choice: riekRate.rate_type_choice,
                                })
                              }
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </BoxWrapper>

              <ReportTable
                navigateToParams={navigateToParams}
                tableData={tableData}
                eventEmitter={eventEmitter}
                campaignData={campaignData}
                filterData={filterData}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                statusValue={statusValue}
                setStatusValue={setStatusValue}
                selcetdcampaign={selcetdcampaign}
                setselcetdcampaign={setselcetdcampaign}
                setLoader={setLoader}
                setToastData={setToastData}
                setMoreDetailLayer={setMoreDetailLayer}
                setMoreLoader={setMoreLoader}
                riekRate={riekRate}
              />
            </Box>
          </Box>
        )}

        {moreDetailLayer && (
          <MoreDetails
            setMoreDetailLayer={setMoreDetailLayer}
            moreData={moreData}
            moreLoader={moreLoader}
          />
        )}
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(Risk)
