import ChangePassword from 'pages/changePassword'
import Dashboard from 'pages/dashboard'
import ForgetPassword from 'pages/forgotpassword'
// import Home from 'pages/home'
import Login from 'pages/login'
import SignUp from 'pages/signup'
import Risk from 'pages/risk'
import RiskReport from 'pages/riskratingreport'
import AddUser from 'pages/adduser'
import ProfilePage from 'pages/profile'
import Register from 'pages/register'
import VerifyAccount from 'pages/verifyAccount'
import UserProfile from 'pages/userProfile'
import Campaign from 'pages/campaign'
import ResetPasswordForm from 'pages/resetpasswordforms'
import Logout from 'pages/Logout'
// import ALR from 'pages/alr'
import Contacting from 'pages/contacting'
import NegotiatePricing from 'pages/negotiatePricing'
import Review from 'pages/review'
import Closed from 'pages/closed'
import ClientList from 'pages/clientList'
import ClientDetail from 'pages/clientdetails'
import ClientLoanDetail from 'pages/clientloandetails'
import ErrorPage from 'granite/components/error'
import ReviewApplicant from 'pages/reviewApplicant'
import TableDemo from 'pages/tableDemo'
import AddClients from 'pages/addClients'
import AddApplicants from 'pages/addApplicants'
import ClientImport from 'pages/clientListImport'
// import AlrData from 'pages/alrData'
import BrokerDetails from 'pages/brokerDetails'
import ErrorPage404 from 'granite/components/Error404'
export default [
  {
    path: '/',
    // exact: true,
    // component: Home,
    // layout: 'HeaderLayout',
    exact: true,
    publicOnly: true,
    component: Login,
    layout: 'HeaderLayout',
  },
  {
    path: '/tableDemo',
    exact: true,
    component: TableDemo,
    layout: 'HeaderLayout',
  },
  {
    path: '/addClients',
    exact: true,
    private: true,
    component: AddClients,
    layout: 'DashboardLayout',
  },
  //AddApplicants
  {
    path: '/addApplicants/:clientid/:loanid',
    exact: true,
    private: true,
    component: AddApplicants,
    layout: 'DashboardLayout',
  },
  {
    path: '/contactclientofresult/:clientid',
    exact: true,
    private: true,
    component: ReviewApplicant,
    layout: 'DashboardLayout',
  },
  {
    path: '/error',
    exact: true,
    private: true,
    component: ErrorPage,
    layout: 'HeaderLayout',
  },
  {
    path: '/risk_rate_settings',
    exact: true,
    private: true,
    component: Risk,
    layout: 'DashboardLayout',
  },
  {
    path: '/risk_report',
    exact: true,
    private: true,
    component: RiskReport,
    layout: 'DashboardLayout',
  },
  {
    path: '/broker',
    exact: true,
    private: true,
    component: BrokerDetails,
    layout: 'DashboardLayout',
  },
  // {
  //   path: '/campaigns',
  //   exact: true,
  //   private: true,
  //   component: ALR,
  //   layout: 'DashboardLayout',
  // },
  // {
  //   path: '/campaigns/:id',
  //   exact: true,
  //   private: true,
  //   component: AlrData,
  //   layout: 'DashboardLayout',
  // },
  {
    path: '/completed',
    exact: true,
    private: true,
    component: Closed,
    layout: 'DashboardLayout',
  },
  {
    path: '/user_roles',
    exact: true,
    private: true,
    component: AddUser,
    layout: 'DashboardLayout',
  },
  {
    path: '/login',
    exact: true,
    publicOnly: true,
    component: Login,
    layout: 'HeaderLayout',
  },
  {
    path: '/userprofile',
    exact: true,
    private: true,
    component: UserProfile,
  },
  {
    path: '/gatherloandetails',
    exact: true,
    private: true,
    component: Campaign,
    layout: 'DashboardLayout',
  },
  {
    path: '/notifyclientofreview',
    exact: true,
    private: true,
    component: Contacting,
    layout: 'DashboardLayout',
  },
  {
    path: '/negotiatepricing',
    exact: true,
    private: true,
    component: NegotiatePricing,
    layout: 'DashboardLayout',
  },
  {
    path: '/contactclientofresult',
    exact: true,
    private: true,
    component: Review,
    layout: 'DashboardLayout',
  },
  {
    path: '/signup',
    exact: true,
    publicOnly: true,
    component: SignUp,
    layout: 'HeaderLayout',
  },
  {
    path: '/register',
    exact: true,
    publicOnly: true,
    component: Register,
    layout: 'HeaderLayout',
  },
  {
    path: '/verifyaccount',
    exact: true,
    public: true,
    component: VerifyAccount,
    layout: 'HeaderLayout',
  },
  {
    path: '/dashboard',
    exact: true,
    private: true,
    component: Dashboard,
    layout: 'DashboardLayout',
  },
  {
    path: '/profile',
    exact: true,
    private: true,
    component: ProfilePage,
    layout: 'DashboardLayout',
  },
  {
    path: '/changepassword',
    exact: true,
    private: true,
    component: ChangePassword,
    layout: 'DashboardLayout',
  },
  {
    path: '/forgotpassword',
    exact: true,
    publicOnly: true,
    component: ForgetPassword,
    layout: 'HeaderLayout',
  },
  {
    path: '/resetpassword',
    exact: true,
    publicOnly: true,
    component: ResetPasswordForm,
    layout: 'HeaderLayout',
  },
  {
    path: '/logout',
    publicOnly: true,
    component: Logout,
    layout: 'FullScreenLayout',
  },
  {
    path: '/clients/:clientid/addloan',
    exact: true,
    private: true,
    component: ClientLoanDetail,
    layout: 'DashboardLayout',
  },

  {
    path: '/clients/:clientid/:id',
    exact: true,
    private: true,
    component: ClientLoanDetail,
    layout: 'DashboardLayout',
  },
  {
    path: '/clients/add',
    exact: true,
    private: true,
    component: ClientDetail,
    layout: 'DashboardLayout',
  },
  {
    path: '/clients/:clientid',
    exact: true,
    private: true,
    component: ClientDetail,
    layout: 'DashboardLayout',
  },
  {
    path: '/clients_import',
    exact: true,
    private: true,
    component: ClientImport,
    layout: 'DashboardLayout',
  },
  {
    path: '/clients',
    exact: true,
    private: true,
    component: ClientList,
    layout: 'DashboardLayout',
  },
  {
    path: '/error404',
    exact: true,
    publicOnly: true,
    component: ErrorPage404,
    layout: 'HeaderLayout',
  },
  {
    path: '',
    exact: true,
    publicOnly: true,
    component: ErrorPage404,
    layout: 'HeaderLayout',
  },
]
