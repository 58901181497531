class Employee {
  constructor({
    name,
    user_id,
    organisation_id,
    designation,
    employee_id,
    address,
    role,
    is_active,
    pk,
    user,
    roles,
    count,
    next_page,
    previous_page,
  }) {
    this.name = name
    this.user_id = user_id
    this.organisation_id = organisation_id
    this.designation = designation
    this.employee_id = employee_id
    this.address = address
    this.role = role
    this.is_active = is_active
    this.pk = pk
    this.user__email = user.email
    this.user = user
    this.roles = roles
    this.count = count
    this.next_page = next_page
    this.previous_page = previous_page
  }
}

export default Employee
