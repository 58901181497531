//Library imports
import React, {useState} from 'react'
import {
  Box,
  Button,
  Text,
  TextInput,
  Select,
  ThemeContext,
  Image,
} from 'grommet'

import 'react-phone-input-2/lib/style.css'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

//Core imports
import FormField from 'granite/components/FormField'

const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const SelectWrapper = styled(Select)`
  // padding-left: 14px !important;
  height: 29px;
  // top: 279px;
  // left: 1117px;
  font-size: 12px;
  round:'none'
  border-bottom: none;
  opacity:0;
  
 
`

const CustomData = styled(Box)`
  max-heigh: '300px';
`

const SubjectWrapper = styled(Box)`
  position: absolute;
`
const MailForm = ({
  onSubmit,
  setshowmailmodal,
  loggedinuser,
  client,
  clientfirdtname,
  sendername,
  maillist,
  showmailmodal,
  templatecontent, 
  designation,

}) => {
  const [mailtext, setmailtext] = useState('')
  const [ccvisible, setccvisible] = useState(false)
  const [bccvisible, setbccvisible] = useState(false)
  const [subjectmain, setsubjectmain] = useState('')
  const [selectedoption, setselectedoption] = useState('')
  const [customisedMailContent, setcustomisedMailContent] = useState('')
  const ButtonStyle = styled(Button)`
    height: 36px;
    width: 110px;
  `

  // const TextAreaWrapper = styled(TextArea)`
  //   height: 300px;
  //   // top: 279px;
  //   // left: 1117px;
  //   // font-size: 12px;
  //   // round:'none'
  //   border-bottom: none;
  // `
  // const mailWrapper = styled(Box)`
  //   border-bottom: 1px solid black;
  //   min-height: 150px;
  // `
 console.log(mailtext, selectedoption)
  const MoreDetailsWrapper = styled(Box)`
    border-bottom: 1px solid #707070;
  `
  const TextInput1 = styled(TextInput)`
    borderradius: 10;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    padding-bottom: 6px;
    padding-top: 0px;
    font-weight: 400;
    font-size: 12px;
    width:100%
    color: #000000;
  `

  const bodyValue = {
    select: {
      control: {
        extend: {
          borderBottom: 'none',
        },
      },
    },
  }

  const validationSchema = Yup.object().shape({
    from: Yup.string()
      .email('Invalid email')
      .required('From Required'),
    // to: Yup.string()
    //   .email('Invalid email')
    //   .required('To Required'),
    subject: Yup.string().required('Subject Required'),
  })

  const handleCancel = () => {
    setshowmailmodal(false)
  }

  const handleTypeChange = (option, setFieldValue) => {
    if (option === 'Notify to Review') {
      setsubjectmain('Indigo Finance – Notify to Review')
      setmailtext(`Dear ${clientfirdtname},

I hope this email finds you well.  

This is just a quick email to touch base as it is the anniversary of your loan - how time flies!

It is important to check in with our Client Services Team for your complimentary Annual Loan Health Check Up to ensure your loan remains on the most competitive product / rate in the market and the current set up still meets your needs.

At Indigo Finance we know you have far more exciting things in life to focus on so leave this one to us.

We will be in touch with you shortly

Thank you once again for choosing Indigo Finance.

We look forward to continuing to provide you with peace of mind around your lending.

Kind Regards
${sendername}
`)
    } else if (option === 'No Action Required ALR') {
      setsubjectmain('Indigo Finance – No Action Required')
      setmailtext(`Dear ${clientfirdtname},
 
Thank you for taking the time out to discuss your annual Lending Review with us; As per our discussion you are happy with the way we have your mortgages set up through Indigo Finance and agree that your product remains competitive in the market.
 
WHAT CAN CLIENT SERVICES DO FOR YOU?…
 
 - Set up new offset accounts or credit cards

 - Make variations to your existing loan such us fixed rates

 - Apply for additional pricing concessions with your lender

CONTACT
 
Should you have any questions our Client Services team would be more than happy to assist. Contact details for our team are clientservices@indigofinance.com.au or 02 9194 2260. 
 
Kind Regards
${sendername}
`)
    } else if (option === 'Rate Reduction ALR Email') {
      setsubjectmain('Indigo Finance – Rate Reduction')
      setmailtext(`Dear  ${clientfirdtname},

Thank you for taking the time out to discuss your annual Lending Review with us; we trust you found it of value.
 
YOUR REVIEW…
 
As life sometimes gets the better of us we have taken it onboard to keep a close eye on your mortgage with us to ensure you are still on a competitive product in the market. 
 
When we review your loan, we take a good look at your loan type and your product; along with your interest rate so we can determine if this is the most suitable loan for you and your future goals.
 
At this point we realized we could better your position and were able to obtain a rate reduction of XX.XX% taking your final rate with [INSERT BANK] down to XX.XX%
 
We are so pleased we could assist with this and as per our discussion you are happy with the way we have your mortgages set up through Indigo Finance and agree that your product remains competitive in the market.
 

TIME TO RELAX….
 
So for now, expect to hear from us within 12 month for your next annual Lending Review and if you hope to take a step closer to your future dreams and we would be pleased to off you our expertise again in Lending.
 
QUESTIONS?
 
Should you have any questions please do not hesitate to contact myself or your Lending Adviser Melanie Cunliffe on 02 9194 2260. We are here to help at any time. 
 
Kind Regards
${sendername}

`)
    } else if (option === 'Interest / Fixed Expiry Template') {
      setsubjectmain('Indigo Finance – Your Review is Due')
      setmailtext(`Dear  ${clientfirdtname},

I tried to call you today to book in your Lending Review as your Interest Only / Fixed Period is expiring and we need to review your loan structure asap. 

As life sometimes gets the better of us we have taken it onboard to keep a close eye on your mortgage to ensure that you are still on the most competitive product in the market, please call me on 02 9194 2260 to discuss. 
 
I look forward to hearing from you.
 
Kind Regards,
${sendername}
`)
    } else if (option === 'Missed Call Template') {
      setsubjectmain('Indigo Finance – Your Review is Due')
      setmailtext(`Dear  ${clientfirdtname},

I tried to call you today to discuss your Lending Review as your loan anniversary is fast approaching.
 
As life sometimes gets the better of us we have taken it onboard to keep a close eye on your mortgage to ensure that you are still on the most competitive product in the market, please call me on 02 9194 2260 to have a chat. 
 
I look forward to hearing from you.
 
Kind Regards,
${sendername}
 
`)
    } else if (option === 'Actions to be completed ALR Email') {
      setsubjectmain('Indigo Finance – Your Review Summary')
      let tempcontent = templatecontent['Actions to be completed ALR Email']
      tempcontent = tempcontent.replace('{applicant_name}', clientfirdtname)
      tempcontent = tempcontent.replace('{user_name}', sendername)
      tempcontent = tempcontent.replace('{designation}', designation)
      setcustomisedMailContent(
        //  templatecontent[
        //    'Actions to be completed ALR Email'
        //  ],
        tempcontent,
      )
      setFieldValue('content',tempcontent )
      //  customisedMailContent = customisedMailContent.replace(
      //    '{applicant_name}',
      //    clientfirdtname,
      //  )
      //  customisedMailContent = customisedMailContent.replace(
      //    '{user_name}',
      //    sendername,
      //  )
    } else if (option === 'Recently Dealt with Indigo ALR Email') {
      let tempcontent = templatecontent['Recently Dealt with Indigo ALR Email']
      tempcontent = tempcontent.replace('{applicant_name}', clientfirdtname)
      tempcontent = tempcontent.replace('{user_name}', sendername)
      tempcontent = tempcontent.replace('{designation}', designation)
      setcustomisedMailContent(tempcontent)
      setFieldValue('content',tempcontent )
      setsubjectmain('Indigo Finance – Your Loan Review')
    }
    setFieldValue('subject', option)
  }

  const hnadleccClick = () => {
    setccvisible(!ccvisible)
  }

  const hnadlebccClick = () => {
    setbccvisible(!bccvisible)
  }

  let myRef = React.createRef()

  return (
    <Box>
      <Formik
        initialValues={{
          from: loggedinuser,
          to: client,
          subject: '',
          cc: '',
          bcc: '',
          content: customisedMailContent,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <MoreDetailsWrapper
              direction="row"
              justify="between"
              background="brand"
            >
              <MoreDetailsTextWrapper
                color="#FFFFFF"
                size="16px"
                margin={{left: '20px', top: '15px', bottom: '15px'}}
              >
                New Mail
              </MoreDetailsTextWrapper>

              <Button
                margin={{right: 'small', bottom: 'small', top: 'small'}}
                icon={
                  <Image src={process.env.PUBLIC_URL + '/img/whitecross.png'} />
                }
                onClick={() => setshowmailmodal(!showmailmodal)}
              />
            </MoreDetailsWrapper>

            <Box>
              {ccvisible && false && (
                <Box direction="row">
                  <MoreDetailsWrapper
                    direction="row"
                    width="100%"
                    // border={{color: 'brand', size: 'small'}}
                    gap="small"
                  >
                    <Box width="8%" pad={{left: 'medium'}}>
                      <FormField
                        name="cc"
                        label="CC:"
                        required
                        error={touched.cc && errors.cc}
                      />
                    </Box>
                    <Box
                      direction="row"
                      gap="large"
                      width="100%"
                      // border={{color: 'red', size: 'small'}}
                      align="end"
                      pad={{bottom: 'small'}}
                    >
                      <TextInput1
                        pad="none"
                        type="cc"
                        name="cc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder=""
                        size="large"
                        value={values.cc}
                        disabled={false}
                      />
                    </Box>
                  </MoreDetailsWrapper>
                </Box>
              )}
              {bccvisible && false && (
                <Box direction="row">
                  <MoreDetailsWrapper
                    direction="row"
                    width="100%"
                    // border={{color: 'brand', size: 'small'}}
                    gap="small"
                  >
                    <Box width="8%" pad={{left: 'medium'}}>
                      <FormField
                        name="bcc"
                        label="Bcc:"
                        required
                        error={touched.bcc && errors.bcc}
                      />
                    </Box>
                    <Box
                      direction="row"
                      gap="large"
                      width="100%"
                      // border={{color: 'red', size: 'small'}}
                      align="end"
                      pad={{bottom: 'small'}}
                    >
                      <TextInput1
                        pad="none"
                        type="bcc"
                        name="bcc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder=""
                        size="large"
                        value={values.bcc}
                        disabled={false}
                      />
                    </Box>
                  </MoreDetailsWrapper>
                </Box>
              )}
              <Box
                width="100%"
                direction="row"
                // border={{color: 'brand', size: 'small'}}
              >
                <MoreDetailsWrapper
                  width="100%"
                  direction="row"
                  gap="small"
                  pad={{left: 'small'}}
                >
                  <Box width="3%">
                    <FormField
                      name="to"
                      label="To"
                      required
                      error={touched.to && errors.email}
                    />
                  </Box>

                  <Box width="100%" pad={{bottom: 'none', top: 'xsmall'}}>
                    <TextInput1
                      pad="none"
                      type="to"
                      name="to"
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      color="white"
                      placeholder=""
                      size="large"
                      value={values.to}
                      disabled={false}
                    />
                  </Box>
                </MoreDetailsWrapper>
              </Box>
            </Box>

            <Box direction="row">
              <MoreDetailsWrapper direction="row" width="100%" gap="small">
                <Box width="8%" pad={{left: 'small'}}>
                  <FormField
                    name="from"
                    label="From:"
                    required
                    error={touched.from && errors.from}
                  />
                </Box>
                <Box
                  direction="row"
                  gap="large"
                  width="100%"
                  // border={{color: 'red', size: 'small'}}
                  align="end"
                  pad={{bottom: 'xsmall'}}
                >
                  <TextInput1
                    pad="none"
                    type="from"
                    name="from"
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    color="dark"
                    placeholder=""
                    size="large"
                    value={values.from}
                    disabled={false}
                  />
                  {false && (
                    <Box direction="row" gap="small" pad={{right: 'small'}}>
                      <Box onClick={() => hnadleccClick()}>
                        <Text>{'CC'}</Text>
                      </Box>
                      <Box
                        onClick={() => hnadlebccClick()}
                        pad={{right: 'small'}}
                      >
                        <Text>{'BCC'}</Text>
                      </Box>
                    </Box>
                  )}{' '}
                </Box>
              </MoreDetailsWrapper>
            </Box>

            <Box
              direction="row"
              // border={{color: 'green', size: 'small'}}
            >
              <MoreDetailsWrapper
                width="100%"
                direction="row"
                // border={{color: 'brand', size: 'small'}}
              >
                <Box width="9%" pad={{left: 'small', right: 'none'}}>
                  <FormField
                    name="subject"
                    label="Subject"
                    required
                    error={touched.subject && errors.subject}
                  />
                </Box>

                <Box
                  direction="row"
                  width="100%"
                  pad={{top: 'small', left: 'small'}}
                >
                  <Text size="xsmall" color="dark">
                    {subjectmain}
                  </Text>
                  <SubjectWrapper
                    // border={{color: 'green', size: 'medium'}}
                    pad={{bottom: 'small', right: 'small'}}
                    width="90%"
                    // flex = "grow"
                  >
                    <Box width="100%" >
                      <ThemeContext.Extend value={bodyValue}>
                        <SelectWrapper
                          options={maillist}
                          value={values.subject}
                          placeholder={''}
                          onChange={({option}) => {
                            setselectedoption(option)
                            handleTypeChange(option, setFieldValue)
                          }}
                        />
                      </ThemeContext.Extend>
                    </Box>
                  </SubjectWrapper>
                </Box>
              </MoreDetailsWrapper>
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
            {/*  <mailWrapper pad={{left: 'small'}}>
              <TextAreaWrapper
                value={mailtext}
                size="small"
                color="dark"
                fill="true"
              ></TextAreaWrapper>
                        </mailWrapper> */}

            <CustomData
              size="medium"
              margin="small"
              overflow="auto"
              // border={{color: 'red', size: 'small'}}
            >
              <div
                refs={myRef}
                onInput={e => {
                  // setmailcontent(e.target.innerHTML)
                  setFieldValue('content', e.target.innerHTML)
                  // console.log('Html is', e.target.innerHTML)
                }}
                style={{
                  fontSize: '14px',
                  outline: 0,
                  overflow: 'auto',
                  height: '200px',
                  width: '100%',
                }}
                contentEditable="true"
                dangerouslySetInnerHTML={{__html: customisedMailContent}}
              ></div>
            </CustomData>
            <Box
              direction="row"
              gap="small"
              pad={{right: 'small', top: 'small'}}
              justify="end"
            >
              <ButtonStyle
                alignSelf="end"
                label={'Cancel'}
                disabled={isSubmitting}
                primary
                onClick={handleCancel}
              />
              <ButtonStyle
                type="submit"
                alignSelf="end"
                label={isSubmitting ? 'Sending...' : 'Send'}
                disabled={isSubmitting}
                primary
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
export default MailForm
