import React, {useState} from 'react'
import {Box, Button, Text, TextInput, Select, CheckBox, Image} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import {editUserButtonClicked} from 'accounts/controllers/user'
import {saveAddUserButtonClicked} from 'accounts/controllers/user'
import styled from 'styled-components'

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;s
`
const UserProfileWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const TextWrapper = styled(Text)`
  font-weight: 500;
`

const CommonUserForm = props => {
  const parentevent = props.event
  const setUserLayer = props.setUserLayer
  const setEditLayer = props.setEditLayer
  const editLayer = props.editLayer
  const userLayer = props.userLayer
  const editPayload = props.editPayload || null
  const designation = props.data.designation || 'User'
  const [value, setValue] = useState(designation)
  const [checked, setChecked] = useState(false)
  const validationSchema = Yup.object().shape({
    user_name: Yup.string().required('User name is required'),
    email: Yup.string()
      .min(2, 'Too short')
      .max(50, 'Too long')
      .email('Invalid Email')
      .required('Email is Required'),
    // title: Yup.string().required('Please select title'),
    roles: Yup.string().required('Please select access'),
  })

  const adduserRole = async (values, {setSubmitting, setErrors}) => {
    values.roles = values.roles.filter(function(element) {
      return element != null
    })
    setUserLayer(false)
    let pk = props.pk || ''
    setErrors({})
    setSubmitting(true)
    await saveAddUserButtonClicked(values, pk, parentevent)
    setSubmitting(false)
  }

  const editUserRole = (values, {setSubmitting, setErrors}) => {
    setEditLayer(false)
    setErrors({})
    setSubmitting(true)
    editUserButtonClicked(values, editPayload, parentevent)
    setSubmitting(false)
  }


  return (
    <Box overflow="auto">
      <Formik
        initialValues={{
          user_name: props.data.name,
          title: props.data.designation,
          email: props.data.email,
          access: props.data.designation,
          roles: props.data.role,
        }}
        validationSchema={validationSchema}
        onSubmit={editPayload == null ? adduserRole : editUserRole}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <UserProfileWrapper direction="row" justify="between">
                <TextWrapper
                  color="dark-4"
                  size="16px"
                  margin={{left: '20px', top: 'medium', bottom: '15px'}}
                >
                  {editPayload == null ? 'Add User' : 'Edit User'}
                </TextWrapper>

                <Button
                  margin={{
                    top: 'small',
                    bottom: '15px',
                    right: 'small',
                  }}
                  icon={
                    <Image src={process.env.PUBLIC_URL + 'img/Cross.png'} />
                  }
                  onClick={() => {
                    editPayload == null
                      ? setUserLayer(!userLayer)
                      : setEditLayer(!editLayer)
                  }}
                />
              </UserProfileWrapper>
            </Box>

            <Box margin={{left: 'medium', right: 'medium'}}>
              <FormField
                name="user_name"
                label={<Text color="dark-4">{'Username'}</Text>}
                error={touched.user_name && errors.user_name}
              >
                <TextInput
                  placeholder="Username"
                  type="user_name"
                  name="user_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user_name}
                />
              </FormField>
              <FormField
                name="title"
                label={<Text color="dark-4">{'Title'}</Text>}
                error={touched.title && errors.title}
              >
                <TextInput
                  placeholder="Username"
                  type="title"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                />
              </FormField>
              {false && (
                <FormField
                  name="title"
                  label={<Text color="dark-4">{'Job Title'}</Text>}
                  error={touched.title && errors.title}
                >
                  <Select
                    placeholder="Select Job Title"
                    options={['Admin', 'User']}
                    value={value}
                    onChange={({option}) => {
                      setFieldValue('title', option)
                      setValue(option)
                      if (option === 'Admin') {
                        props.roleList.forEach(item => {
                          if (item.name === 'Full Access') {
                            values.roles.push(item.pk)
                          }
                        })
                      } else if (option === 'User') {
                        props.roleList.forEach(item => {
                          if (item.name === 'Full Access') {
                            delete values.roles[values.roles.indexOf(item.pk)]
                          }
                        })
                      }
                    }}
                  />
                </FormField>
              )}
              <FormField
                name="email"
                label={<Text color="dark-4">{'Email Address'}</Text>}
                error={touched.email && errors.email}
              >
                <TextInput
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  // disabled={props.data.email ? true : false}
                />
              </FormField>
              <FormField
                name="roles"
                label="Access"
                error={touched.roles && errors.roles}
              >
                <Box
                  gap="medium"
                  // height="120px"
                  // wrap={true}
                  margin={{top: 'small'}}
                >
                  {props.roleList.map((item, index) => (
                    <Box gap = "xsmall">
                      <CheckBox
                        key={item.pk}
                        name={item.pk}
                        checked={values.roles.includes(item.pk) ? true : false}
                        label={item.name}
                        onChange={event => {
                          if (values.roles.includes(item.pk)) {
                            // delete values.roles[values.roles.indexOf(item.pk)]
                          } else {
                            values.roles = []
                            // setFieldValue(values.roles, [])
                            // console.log('B values.roles', values.roles)
                            values.roles.push(item.pk)
                            // console.log('A >> values.roles', values.roles)
                          }
                          setChecked(!checked)
                        }}
                      />
                      <Text size="small" margin = {{left:'medium'}}>{item.description}</Text>
                    </Box>
                  ))}
                </Box>
              </FormField>
              <Box align="end" margin={{bottom: 'medium', top: 'small'}}>
                <ButtonStyle
                  primary
                  disabled={isSubmitting}
                  label="Save"
                  type="submit"
                />
              </Box>
              <Text as="div" color="status-critical">
                {errors.non_field}
              </Text>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default CommonUserForm
