//Library imports
import React, {useState} from 'react'
import {Box, Select, Text, CheckBox, ThemeContext, Image, Anchor} from 'grommet'
import styled from 'styled-components'
//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
import Pagination from 'granite/components/Pagination'
import ConfirmationLayer from 'granite/components/ConfirmationLayer'
//Application Imports
import {
  applicantLoanStatusChanged,
  applicantLoanOutcomeChanged,
} from 'alr/controllers/loanReview'
import REVIEW_APPLICANT_MESSAGES from 'messages/reviewApplicant'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`
const SelectWrapper = styled(Select)`
  font-size: 14px;
`

const headerValue = {
  checkBox: {
    hover: {
      border: {
        color: 'white',
      },
    },
    border: {
      color: {
        light: 'white',
        dark: 'white',
      },
    },
    color: 'white',
    size: '18px',
  },
}

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}
const COLUMNS = [
  {
    accessor: 'selection',
    Header: ({getToggleAllRowsSelectedProps}) => (
      <span title={'Select All'}>
        <ThemeContext.Extend value={headerValue}>
          <CheckBox {...getToggleAllRowsSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),

    Cell: ({row}) => (
      <span title={'Select'}>
        <ThemeContext.Extend value={bodyValue}>
          <CheckBox {...row.getToggleRowSelectedProps()} />
        </ThemeContext.Extend>
      </span>
    ),
    disableSortBy: true,
  },
  {
    accessor: 'ation',
    Header: 'Action',
    Cell: ({cell: {value, row}, handleLoan, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'small'}}>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  window.open(
                    '/clients/' + row.original.applicant_pk + '/' + row.original.pk,
                  )
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'account_no',
    Header: 'Account No.',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'applicant_type',
    Header: 'Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'lender_name',
    Header: 'Lender',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'modified_date',
    Header: 'Edit Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'rate',
    Header: 'Old Rate',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <Text size="14px">{value}% p.a</Text>
            </Box>
          }
          editMode={false}
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'new_rate',
    Header: 'New Rate',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value + '% p.a'}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'out_come',
    Header: 'Outcome',
    Cell: ({
      cell: {value, row, column},
      updateCellData,
      handleOutcomeChanged,
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            <ThemeContext.Extend value={selectWidthValue}>
              <SelectWrapper
                options={[
                  '$!Money saved, no further action',
                  '$!Money saved, Further action required',
                  '!No Money Saved, further action',
                  '--No Changes--',
                ]}
                value={value}
                onChange={({value}) => {
                  handleOutcomeChanged(value, row.original.pk)
                }}
              />
            </ThemeContext.Extend>
          }
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'loan_status',
    Header: 'Status',
    Cell: ({
      cell: {value, row}, 
      setOpen, 
      setLayer,
      setShow,
      setParameters,
      // handleStatusChanged, 
      ...rest
    }) => {
      return (
        <TextCell
          {...rest}
          data={
            // <SelectWrapper
            //   options={['Refinancing', 'Applying New Rates', 'Retention']}
            //   value={value}
            //   onChange={({value}) => {
            //     handleStatusChanged(value, row.original.pk, row.original.newpk)
            //   }}
            // />
            <Box direction='row'>
              <Anchor 
                onClick={()=>{
                  setOpen(true)
                  setParameters({
                    string: 'Refinancing', 
                    pk: row.original.pk,
                    newPk: row.original.newpk
                  })
                }} 
                label='Refinancing'
                margin={{right:'xsmall'}}
              />{' | '}
              <Anchor 
                onClick={()=>{
                  setLayer(true)
                  setParameters({
                    string: 'Applying New Rates', 
                    pk: row.original.pk,
                    newPk: row.original.newpk
                  })
                }}
                label='Applying New Rates'
                margin={{left:'xsmall', right:'xsmall'}}
              />{' | '}
              <Anchor 
                onClick={()=>{
                  setShow(true)
                  setParameters({
                    string: 'Retention', 
                    pk: row.original.pk,
                    newPk: row.original.newpk
                  })
                }}
                label='Retention'
                margin={{left:'xsmall'}}
              />
            </Box> 
          }
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'savings',
    Header: 'Savings',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
]

const selectWidthValue = {
  select: {
    control: {
      extend: {
        width: '100%',
      },
    },
  },
}
const ApplicantLoanTable = ({
  eventEmitter,
  loader,
  setData,
  navigateToParams,
  setToastData,
  setSelected,
  selected,
  data: {data, total},
  filterData: {page, page_size: size},
}) => {
  const [open, setOpen] = useState(false)
  const [layer, setLayer] = useState(false)
  const [show, setShow] = useState(false)
  const [parameters, setParameters] = useState({})
  const [rowsPk, setRowsPk] = useState([])
  const [rowError, setRowError] = useState(false)
  const [erroredRowPk, setErroredRowPk] = useState()
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }
  const onConfirm = parameters => {
    handleStatusChanged(parameters.string, parameters.pk, parameters.newPk)
    setOpen(false)
    setLayer(false)
    setShow(false)
    setParameters({})
  }
  const handleStatusChanged = (value, id, newpk) => {
    if (selected.length === 0) {
      if (rowsPk.length === 0) {
        setRowError(false)
        applicantLoanStatusChanged(
          eventEmitter,
          value,
          id,
          data,
          setData,
          total,
          newpk,
        )
        rowsPk.push(id)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0 && rowsPk.includes(id)) {
        setRowError(false)
        applicantLoanStatusChanged(
          eventEmitter,
          value,
          id,
          data,
          setData,
          total,
          newpk,
        )
        const index = rowsPk.indexOf(id)
        rowsPk.splice(index, 1)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0) {
        setRowError(true)
        setErroredRowPk(rowsPk[0])
        setToastData({
          open: true,
          message: REVIEW_APPLICANT_MESSAGES.STATUS_OUTCOME_BOTH_WARNING,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 2000)
      }
    }
  }

  const handleOutcomeChanged = (value, id) => {
    if (selected.length === 0) {
      if (rowsPk.length === 0) {
        setRowError(false)
        applicantLoanOutcomeChanged(
          eventEmitter,
          value,
          id,
          data,
          setData,
          total,
        )
        rowsPk.push(id)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0 && rowsPk.includes(id)) {
        setRowError(false)
        applicantLoanOutcomeChanged(
          eventEmitter,
          value,
          id,
          data,
          setData,
          total,
        )
        const index = rowsPk.indexOf(id)
        rowsPk.splice(index, 1)
        setRowsPk(rowsPk)
      } else if (rowsPk.length > 0) {
        setRowError(true)
        setErroredRowPk(rowsPk[0])
        setToastData({
          open: true,
          message: REVIEW_APPLICANT_MESSAGES.STATUS_OUTCOME_BOTH_WARNING,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 2000)
      }
    }
  }

  return (
    <Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <BoxWrapper overflow={{horizontal: 'auto'}}>
          <Box style={{minWidth: '1250px'}}>
            <ReactTable
              data={data}
              columns={COLUMNS} // pass in our table columns
              setSelected={setSelected} // sets selected row information
              rowError={rowError}
              erroredRowPk={erroredRowPk}
              handleOutcomeChanged={handleOutcomeChanged}
              // handleStatusChanged={handleStatusChanged}
              setOpen={setOpen}
              setLayer={setLayer}
              setShow={setShow}
              setParameters={setParameters}
            />
          </Box>
        </BoxWrapper>
      )}
      {open && (
        <ConfirmationLayer 
          setOpen={setOpen}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Refinancing'}
          layerBody={'Refinancing'}
        />
      )}
      {layer && (
        <ConfirmationLayer 
          setOpen={setLayer}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Applying New Rates'}
          layerBody={'Applying New Rates'}
        />
      )}
      {show && (
        <ConfirmationLayer 
          setOpen={setShow}
          parameters={parameters}
          onConfirm={onConfirm}
          layerHeader={'Retention'}
          layerBody={'Retention'}
        />
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              select={true}
              pageData={{page, size, total}}
              dataLength={data.length}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ApplicantLoanTable
