class Stats {
    constructor(item) {
      this.month = item.month
      this.year = item.year
      this.month_year = item.month + ', ' + item.year
      this.campaign_id = item.campaign_id
      this.completed = item.completed
      this.contacting = item.contacting
      this.in_review = item.in_review
      this.needs_attention = item.needs_attention
      this.new = item.new
      this.total_clients = item.total_clients
      this.total_loan = item.total_loan
      this.Negotiate_Pricing = item.Negotiate_Pricing
      this.Contact_Client_Of_Result = item.Contact_Client_Of_Result
    }
  }
  export default Stats
  