import {Box, Button, Text} from 'grommet'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'
import styled, {withTheme} from 'styled-components'
import Tooltip from 'granite/components/Tooltip'
import Toast from 'granite/components/Toast'
import SLIDER_MESSAGES from 'messages/slider'

const StyledInp = styled.input`
  -webkit-appearance: none;
  height: ${props => props.height};
  border-radius: 4px;
  background: ${props =>
    `linear-gradient(90deg, ${props.theme.global.colors[props.background]} ${
      props.percentage
    }%, ${
      props.background_back ? props.background_back : '#E5FFE5'
    } ${props.percentage + 0.1}%)`};
  outline: none;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: ${props => props.thumbWidth};
    height:${props => props.thumbHeight}
    border-radius: 50%;
    border: 2px solid #fbfafc;
    background: ${props => props.theme.global.colors[props.thumb]};
    cursor: pointer;
    transition: background 0.15s ease-in-out;
    &:hover {
      background: ${props => props.theme.global.colors[props.thumb]};
      box-shadow: ${props =>
        `0 0 0 3px white, 0 0 0 6px ${props.theme.global.colors[props.thumb]}`};
    }
    &:active {
      background: ${props => props.theme.global.colors[props.thumb]};
    }
  }
`
const TextWrapper = styled(Text)`
  font-weight: 400;
`
const Slider = ({
  getTooltipPopupContainer,
  max,
  min,
  name,
  onChange,
  showButtons,
  showValue,
  step,
  theme,
  edit,
  toast,
  value: defaultValue,
  ...rest
}) => {
  const [value, setValue] = useState(defaultValue)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const handleDecBtnClick = () => {
    if (value - step >= min) setValue(value - step)
  }
  const handleIncBtnClick = () => {
    if (value + step <= max) setValue(value + step)
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  const handleEdit = e => {
    if (edit) {
      handleSliderChange(e)
    } else {
      if (toast) {
        setToastData({
          open: true,
          message: SLIDER_MESSAGES.SLIDER_WARNING_RISK_RATE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
      }
    }
  }
  const handleSliderChange = e => {
    const updatedValue = e.target.value
    setValue(updatedValue)
    onChange(updatedValue)
  }
  const inpJsx = (
    <Box flex="grow">
      <StyledInp
        type="range"
        value={value}
        min={min}
        max={max}
        onInput={e => handleEdit(e)}
        percentage={(100.0 * (value - min)) / (max - min)}
        step={step}
        theme={theme}
        name={name}
        {...rest}
      />
    </Box>
  )
  return (
    <Box align="center" direction="row" gap="xsmall">
      {showButtons && <Button label="-" onClick={handleDecBtnClick} />}
      {getTooltipPopupContainer ? (
        <Tooltip
          align="top"
          content={value + ''}
          debounced={false}
          margin="small"
          justify="between"
        >
          {inpJsx}
        </Tooltip>
      ) : (
        inpJsx
      )}

      {showValue && (
        <Box
          margin={{left: 'medium'}}
          align="center"
          round="small"
          width="40px"
        >
          <TextWrapper color="dark-1" size="12px">
            {value}%
          </TextWrapper>
        </Box>
      )}
      {showButtons && <Button label="+" onClick={handleIncBtnClick} />}
      {toastData.open ? <Toast text={toastData.message} /> : null}
    </Box>
  )
}

Slider.defaultProps = {
  getTooltipPopupContainer: false,
  max: 100.0,
  min: 0.0,
  onChange: () => {},
  showButtons: false,
  showValue: true,
  step: 0.0,
  value: 0.0,
}

Slider.propTypes = {
  getTooltipPopupContainer: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  showButtons: PropTypes.bool,
  showValue: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.number,
}

export default withTheme(Slider)
