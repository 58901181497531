class UserRole {
  constructor({pk, name}) {
    console.log("Coming name is ",name)
    this.pk = pk
    this.name = name
    this.description =
      name === 'Manager'
        ? 'Team Member Access Plus Data Export'
        : name === 'Team Member'
        ? "Operational Access ie Dashboard, Complete Client & Loan Management, Loan Reviews & Import of Loans"
        : name === 'Admin'
        ? 'Manager Access Plus User Management, Campaign Management & Risk Rating'
        : ''
  }
}

export default UserRole
