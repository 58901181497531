const RISK_RATE_EVENTS = {
  GET_RISK_SUCCESS: 'GET_RISK_SUCCESS',
  GET_RISK_FAILURE: 'GET_RISK_FAILURE',
  SAVE_RISK_SUCCESS: 'SAVE_RISK_SUCCESS',
  SAVE_RISK_FAILURE: 'SAVE_RISK_FAILURE',
  GET_LOAN_TABLE_BASED_ON_RISK_DATA_SUCCESS:
    'GET_LOAN_TABLE_BASED_ON_RISK_DATA_SUCCESS',
  GET_LOAN_TABLE_BASED_ON_RISK_DATA_FAILURE:
    'GET_LOAN_TABLE_BASED_ON_RISK_DATA_FAILURE',
  ADD_LOAN_BASED_ON_RISK_RATE_SUCCESS: 'ADD_LOAN_BASED_ON_RISK_RATE_SUCCESS',
  ADD_LOAN_BASED_ON_RISK_RATE_FAILURE: 'ADD_LOAN_BASED_ON_RISK_RATE_FAILURE',
  GET_CAMPAIGN_SUCCESS: 'GET_CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'GET_CAMPAIGN_FAILURE',
}

export default RISK_RATE_EVENTS
