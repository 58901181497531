// Core imports
import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'

// Application imports
import User from 'accounts/entities/user'
// import Risk from 'accounts/entities/risk'
import UserRole from 'accounts/entities/userRole'
import Employee from 'accounts/entities/employee'
// import Organisation from 'organisations/entities/organisation'

async function login(email, password) {
  const payload = {
    email,
    password,
  }
  try {
    const response = await GraniteAPIClient().post(
      '/account/login/oauth/',
      payload,
    )
    return response.data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getRolesAPI() {
  try {
    const response = await GraniteAPIClient().get('/organisation/roles/')
    const userRoles = response.data.map(item => new UserRole(item))
    return userRoles
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function editUser(
  {user_name, email, roles,title},
  {pk, user, name, designation, role},
) {
  const userPayload = {
    pk: user.pk,
    email: email,
  }
  const payload = {
    pk: pk,
    user: userPayload,
    name: user_name,
    email: email,
    designation: title,
    // role: roles.filter(function(el) {
    //   return el != null
    // }),
    role: roles
  }
  // console.log("Payload 1", user_name, email)
  // console.log("Payloads 2",  pk, user, name, designation, role, title )
  // console.log("PAyloads", payload )
  try {
    const response = await GraniteAPIClient().post(
      '/organisation/employees/' + pk + '/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getUserDetails(pk) {
  try {
    const response = await GraniteAPIClient().get(
      'organisation/employees/' + pk + '/',
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// TO ADD NEW USER IN THE SYSTEM
async function saveAddUser({user_name, email, title, roles}, {pk}) {
  const payload = {
    user: {
      first_name: user_name,
      email: email,
    },
    role: roles,
    designation: title,
  }
  try {
    const response = await GraniteAPIClient().post(
      '/organisation/employees/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// TO GET LIST OF USER IN USER ROLES
async function getUsers(params) {
  try {
    const response = await GraniteAPIClient().get('/organisation/employees/', {
      params: params,
    })
    const rows = response.data.count
    const employee = response.data.results.map(item => new Employee(item))
    return {employee, rows}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function register({email, first_name, last_name, password, ...data}) {
  const payload = {
    email,
    first_name,
    last_name,
    password,
  }
  try {
    const response = await GraniteAPIClient().post(
      '/company/register/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function myProfile() {
  try {
    const {data} = await GraniteAPIClient().get('/organisation/employee/')
    const userData = {
      loggedinUserId: data.pk,
      title: data.title,
      username: data.user.username,
      name: data.name,
      email: data.user.email,
      organisation_id: data.organisation_id,
      designation: data.designation,
    }

    const user = new User(userData)

    // TODO: update it while switching to granite api
    return {user}
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

async function updateMyProfile(email, first_name, last_name, username) {
  try {
    const userData = {
      first_name,
      last_name,
      email,
      username,
    }
    const response = await GraniteAPIClient().put('/t2b/accounts/me/', userData)
    return response.data
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

async function requestToken(name) {
  try {
    const payload = {
      name: 'Gary',
      email: 'shobhit.bhatnagar@thoughts2binary.com',
    }
    await GraniteAPIClient().post('/account/recover-password/request/', payload)
    // await GraniteAPIClient().post('/t2b/accounts/forgot-password123/', payload)
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function requestTokenFP(email) {
  try {
    const payload = {email}
    await GraniteAPIClient().post('/account/recover-password/request/', payload)
    // await GraniteAPIClient().post('/t2b/accounts/forgot-password123/', payload)
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function submitToken(token) {
  const payload = {
    token,
  }
  try {
    const response = await GraniteAPIClient().post(
      '/t2b/accounts/forgot-password/submit/',
      payload,
    )
    return response.data.uid
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function resetPassword(jwt_token, new_password, confirm_password) {
  const payload = {
    jwt_token,
    new_password,
    confirm_password,
  }
  try {
    await GraniteAPIClient().post(
      '/account/verification-token/new-password/',
      payload,
    )
  } catch (error) {
    console.log(error)
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

async function resetPasswordVerify(token, type) {
  const payload = {
    type,
    token,
    // email,
  }
 
  try {
    const response = await GraniteAPIClient().post(
      '/account/verification-token/verify/',
      payload,
    )
  
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function changePassword(old_password, new_password, re_password) {
  try {
    const payload = {
      old_password: old_password,
      new_password: new_password,
      re_password: re_password,
    }
    await GraniteAPIClient().post('/account/change-password/', payload)
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    // FIXME: show proper error
    throw new GraniteError(errorMessage)
  }
}

/**
 *  Logout API Gateway.
 *

 */
async function LogoutAPIGateway() {
  try {
    const payload = {}
    const response = await GraniteAPIClient().post(
      '/t2b/accounts/revoke-token/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

// TO UPDATE USER NAME / TITLE / PASSWORD
async function updateUserProfileInfoAPI(values) {
  console.log('GAteway', values)
  try {
    if (values.hasOwnProperty('old_password')) {
      const payload = {
        old_password: values.old_password,
        new_password: values.new_password,
        confirm_new_password: values.re_password,
      }
      await GraniteAPIClient().post('/account/change-password/', payload)
    }

    if (values.user_name) {
      const payload = {
        name: values.user_name,
        title: values.title,
      }
      await GraniteAPIClient().put('organisation/employee/', payload)
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

async function signup(token) {
  //console.log("Token ans emails in gateway is ", token,email )
  try {
    const response = await GraniteAPIClient().post(
      '/organisation/employee/register/',
      {
        // email: email,
        token: token,
        type: 'EMAIL',
      },
    )
    return response
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    throw new GraniteError(errorMessage)
  }
}

async function requestTokenOnSignup(name, email) {
  console.log('At gatewy email ', email, name)
  try {
    const payload = {
      name: name,
      email: email,
    }
    return await GraniteAPIClient().put('/organisation/employee/', payload)
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function setNewPassword(old_password, new_password, re_password) {
  try {
    const payload = {
      new_password: new_password,
      confirm_new_password: re_password,
      old_password: old_password,
    }
    await GraniteAPIClient().post('/account/change-password/', payload)
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message : error
    // FIXME: show proper error
    throw new GraniteError(errorMessage)
  }
}




async function deleteUser(params) {
  try {
    const response = await GraniteAPIClient().delete('/organisation/employees/' + params  + '/')
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}



async function activeInactiveUser(payload, userId) {
  try {
    const response = await GraniteAPIClient().post(
      '/organisation/employees/' + userId + '/',
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}



export default {
  changePassword,
  login,
  register,
  myProfile,
  resetPassword,
  requestToken,
  requestTokenOnSignup,
  submitToken,
  updateMyProfile,
  signup,
  getRolesAPI,
  getUsers,
  saveAddUser,
  editUser,
  getUserDetails,
  resetPasswordVerify,
  LogoutAPIGateway,
  requestTokenFP,
  updateUserProfileInfoAPI,
  setNewPassword,
  deleteUser,
  activeInactiveUser,
}
