//Library imports
import React from 'react'
import {Box, Image, Text} from 'grommet'
import styled from 'styled-components'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
import Pagination from 'granite/components/Pagination'

//Application imports
// import {loadClientLoanDetails} from 'alr/controllers/loanReview'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`
const COLUMNS = [
  {
    accessor: 'user_name',
    Header: 'Name',
    Cell: ({cell: {value, row}, customerId, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box direction="row" gap="xsmall">
              <Box alignSelf="center">
                {customerId.includes(row.original.pk) && (
                  <Image src={process.env.PUBLIC_URL + '/img/check.png'} />
                )}
              </Box>
              <Box margin={{left: 'small'}}>
                <Text color="dark-6" size="14px">
                  {value}
                </Text>
                <Text color="text-1" size="10px">
                  {value}
                </Text>
              </Box>
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="12px"
          style={{fontWeight: '500'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'user_phone',
    Header: 'Phone No.',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <Text color="dark-6" size="14px">
                {value}
              </Text>
              <Text color="text-1" size="10px">
                {value}
              </Text>
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="12px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'user_email',
    Header: 'Email Address',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="blue-email"
          size="14px"
          style={{textDecoration: 'underline'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'client_type',
    Header: 'Client Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'company_name',
    Header: 'Company Name',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'company_ABN',
    Header: 'ABN',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  // {
  //   accessor: 'review_cycle',
  //   Header: 'Review Cycle',
  //   Cell: ({cell: {value}, ...rest}) => {
  //     return (
  //       <TextCell
  //         {...rest}
  //         data={value}
  //         editMode={false}
  //         color="dark-6"
  //         size="14px"
  //       />
  //     )
  //   },
  //   disableSortBy: true,
  // },
  {
    accessor: 'loan_detail',
    Header: 'Loan Detail',
    Cell: ({cell: {row}, handleGetClientLoans, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Image
              height="14px"
              src={process.env.PUBLIC_URL + '/img/addClient.png'}
              onClick={() => {
                handleGetClientLoans(row.original.pk)
              }}
              style={{cursor: 'pointer'}}
            />
          }
        />
      )
    },
    disableSortBy: true,
  },
]

const ClientTable = ({
  setLayer,
  eventEmitter,
  loader,
  customerId,
  setNewCustomerId,
  navigateToParams,
  data: {data, total},
  filterData: {page, page_size: size, select_page, select_page_size},
  campaignId,
}) => {
  const handleGetClientLoans = id => {
    setNewCustomerId(id)
    setLayer(true)
    navigateToParams({select_page: 1, select_page_size: 20})
    // loadClientLoanDetails(eventEmitter, id, month, campaignId, select_page, select_page_size)
  }
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }

  // const date = new Date()
  // const month = date.getMonth()

  return (
    <Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <BoxWrapper overflow={{horizontal: 'auto'}}>
          <Box>
            <ReactTable
              data={data}
              columns={COLUMNS} // pass in our table columns
              handleGetClientLoans={handleGetClientLoans}
              customerId={customerId}
            />
          </Box>
        </BoxWrapper>
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ClientTable
