import axios from 'axios'
import GraniteError from 'utils/granite-error'
import {getAuthToken, getOrganisation} from 'utils/auth-singleton'

/**
 * NOTES:-
 *  - API client return axios instance.
 *  - we can call axios methods on this instance.
 *  - additional standard headers & configs can be passed
 *    to create this instance.
 */
export default (url, headers, ...config) => {
  let defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  const authToken = getAuthToken()
  const organisation = getOrganisation()

  if (authToken && localStorage.getItem('authToken') !== null) {
    defaultHeaders['Authorization'] = `Bearer ${authToken}`
  }
  if (organisation && localStorage.getItem('organisation') !== null) {
    defaultHeaders['Organisation'] = `${organisation}`
  }

  if (!authToken) {
    defaultHeaders['Client'] = process.env.REACT_APP_CLIENT_ID
  }
  // TODO :- in case of timeout, use interceptor to throw error format - its crashing
  // TODO :- request cancellation support on unmount
  let defaultConfig = {
    timeout: 500000,
  }
  if (headers) {
    defaultHeaders = {...defaultHeaders, ...headers}
  }
  if (headers) {
    defaultConfig = {...defaultConfig, ...config}
  }
  const axiosInstance = axios.create({
    baseURL:
      url === undefined ? process.env.REACT_APP_GRANITE_API_BASE_URL : url,
    headers: defaultHeaders,
    ...defaultConfig,
  })

  axiosInstance.interceptors.response.use(
    response => Promise.resolve(response),
    err => {
      let data = err.response.data
      // console.log('Data', data)
      if (err.response && err.response.status === 401) {
        localStorage.clear()
        window.location.href = '/login'
      } else if (err.response && err.response.status === 404) {
        window.location.href = '/error404'
      } else if (err.response && err.response.status === 422) {
        localStorage.clear()
        window.location.href = '/login'
      } else if (err.response && err.response.status === 500) {
        window.location.href = '/error'
      } else if (err.response && err.response.status === 501) {
        window.location.href = '/error'
      } else if (err.response && err.response.status === 502) {
        window.location.href = '/error'
      } else if (err.response && err.response.status === 503) {
        window.location.href = '/error'
      }
      if (!data.code) {
        //  console.log(' I am in 21')
        // throw new GraniteError(
        //   {api_error: err.response.status + ', Something went wrong'},
        //   true,
        // )
        throw new GraniteError(data, true)
      } else if (data.code === 'multi_field_error') {
        // console.log(" I am in 2")
        let message = {}
        data.message.forEach(item => {
          message[item.code] = item.message
        })
        throw new GraniteError(message, true)
      } else {
        throw new GraniteError(
          {field_error: data.message, [data.code]: data.message},
          true,
        )
      }
    },
  )

  return axiosInstance
}
