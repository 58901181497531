//Library imports
import React from 'react'
import {
  Box,
  Text,
  Select,
  ThemeContext,
  Button,
  Image,
  TextInput,
} from 'grommet'
import styled from 'styled-components'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-top: 2px solid #37236a;
`
const CampaignHeading = styled(Text)`
  font-weight: 500;
`
const CampaignData = styled(Text)`
  font-weight: 400;
`
const SelectWrapper = styled(Select)`
  padding-left: 14px !important;
  height: 29px;
  top: 279px;
  left: 1117px;
  font-size: 12px;
  round:'none'
  borderBottom:'none'
`
const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  padding-left: 14px !important;
`
const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
  color:#000000;
`
const ButtonStyleSearch = styled(Button)`
  height: 35px;
  width: 110px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
`
const bodyValue = {
  select: {
    control: {
      extend: {
        // borderBottom:'none'
      },
    },
  },
}

const CampaignInfo = ({
  campaignDetails,
  selcetdcampaign,
  setselcetdcampaign,
  selectcampaignlist,
  history,
  navigateToParams,
  filterData,
  searchValue,
  setSearchValue,
}) => {
  const selectsimpledata = campaignDetails ? [campaignDetails.month_year] : []
  const handleSelectCampaihn = option => {
    setselcetdcampaign(option)
    selectcampaignlist.campaignData.forEach(item => {
      if (item.month_year === option) {
        navigateToParams({
          month_year: item.month_year,
          year: item.date,
          campaign_id: item.pk,
        })
      }
    })
  }

  const handleSelectPaginate = () => {
    navigateToParams({
      select_page: filterData.select_page,
      select_page_size: filterData.select_page_size + 10,
    })
  }

  const handleSearch = () => {
    if (searchValue !== '') {
      navigateToParams({search: searchValue, page: 1})
    }
  }

  const handleSearchCancel = () => {
    setSearchValue('')
    navigateToParams({page: 1, page_size: 10, search: null})
  }

  return (
    <Box>
      <BoxWrapper
        pad={{bottom: '32px'}}
        background="light-1"
      >
        <Box
          direction="row"
          gap="medium"
          margin={{left: 'small', top: 'small'}}
          pad={{left: 'small'}}
        >
          <Text size="16px" weight="bold" color="dark-6">
            Campaign Details
          </Text>
        </Box>
        <Box direction="row" pad={{top: 'medium', left: 'small'}}>
          <Box width="20%" pad={{left: 'medium', right: 'medium'}}>
            <CampaignHeading size="14px" color="dark-6">
              Campaign Name
            </CampaignHeading>
            {!campaignDetails ? (
              ''
            ) : (
              <CampaignData size="12px" margin={{top: 'small'}} color="dark-6">
                {campaignDetails.name}
              </CampaignData>
            )}
          </Box>
          <Box width="20%" pad={{left: 'medium', right: 'medium'}}>
            <CampaignHeading size="14px" color="dark-6">
              Campaign Month
            </CampaignHeading>
            <ThemeContext.Extend value={bodyValue}>
              <SelectWrapper
                options={
                  selectcampaignlist.temp.length > 0
                    ? selectcampaignlist.temp
                    : selectsimpledata
                }
                value={selcetdcampaign ? selcetdcampaign : selectsimpledata}
                placeholder={'Campaign Month'}
                onChange={({option}) => handleSelectCampaihn(option)}
                dropHeight="small"
                onMore={() => handleSelectPaginate()}
              />
            </ThemeContext.Extend>
          </Box>
          <Box width="20%" pad={{left: 'medium', right: 'medium'}}>
            <CampaignHeading size="14px" color="dark-6">
              Created By
            </CampaignHeading>
            {!campaignDetails ? (
              ''
            ) : (
              <CampaignData size="12px" margin={{top: 'small'}} color="dark-6">
                {campaignDetails.created_by}
              </CampaignData>
            )}
          </Box>
          <Box
            pad={{left: 'medium', right: 'medium'}}
            direction="row"
            width="40%"
          >
            <SearchBoxWrapper
              width="100%"
              direction="row"
              height="36px"
              align="end"
              round="4px"
            >
              <TextInputWrapper
                placeholder=" Name, Phone No., Email Search"
                value={searchValue}
                onChange={event => {
                  setSearchValue(event.target.value)
                }}
                onKeyPress={event => {
                  if (event.key === 'Enter') {
                    handleSearch()
                  }
                }}
              />
              <Button
                alignSelf="center"
                icon={
                  <Image
                    src={process.env.PUBLIC_URL + '/img/search_cancel.png'}
                    fit="contain"
                  />
                }
                onClick={handleSearchCancel}
              />
              <ButtonStyleSearch
                type="submit"
                label={'Search'}
                onClick={() => {
                  handleSearch()
                }}
                primary
              />
            </SearchBoxWrapper>
          </Box>
        </Box>
      </BoxWrapper>
    </Box>
  )
}

export default CampaignInfo
