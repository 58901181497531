//Library imports
import React, {useState} from 'react'
import {useLocation, useHistory} from 'react-router-dom'
import styled from 'styled-components'
import {Box, Text, Image} from 'grommet'

//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import Pagination from 'granite/components/Pagination'
import MoreSecurityInfo from './moresecuritydetail'

//Application imports
// import {getLoanListOnFilterparams} from 'clientDetails/controllers/client'
import {showMoreDetails} from 'alr/controllers/loanReview'

import queryString from 'query-string'
const TableWrapper = styled(Box)`
  overflow-x: auto;
`

const COLUMNS = [
  {
    accessor: 'ation',
    Header: 'Action',
    Cell: ({cell: {value, row}, handleLoan, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'small'}}>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  let data = row.original.pk
                  handleLoan(data)
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/edit.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: 'preview',
    Header: 'Preview',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box pad={{left: 'medium'}}>
              <Box
                width="15px"
                height="15px"
                onClick={() => {
                  handleApplicant(value, row.original.pk)
                }}
              >
                <Image
                  src={process.env.PUBLIC_URL + '/img/more_info.png'}
                  fit="contain"
                  style={{cursor: 'pointer'}}
                />
              </Box>
            </Box>
          }
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'name',
    Header: 'Client',
    Cell: ({cell: {value, row}, handleApplicant, ...rest}) => {
      return (
        <Box style={{whiteSpace: 'pre-line'}}>
          <TextCell
            {...rest}
            // data={
            //   <Box direction="row" gap="small">
            //     <Box
            //       width="15px"
            //       height="15px"
            //       onClick={() => {
            //         handleApplicant(value, row.original.pk)
            //       }}
            //     >
            //       <Image
            //         src={process.env.PUBLIC_URL + '/img/more_info.png'}
            //         fit="contain"
            //         style={{cursor: 'pointer'}}
            //       />
            //     </Box>
            //     <Box>
            //       <Text size="14px">{value}</Text>
            //     </Box>
            //   </Box>
            // }
            data={value}
            editMode={false}
            size="14px"
            color="dark-6"
            style={{cursor: 'pointer'}}
          />
        </Box>
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: `securityArraayString`,
    Header: 'Security Address',
    Cell: ({cell: {value}, showSecurity, hideSecurity, ...rest}) => {
      return (
        <Box style={{whiteSpace: 'pre-line'}}>
          <TextCell
            {...rest}
            data={value}
            editMode={false}
            size="14px"
            color="dark-6"
            style={{cursor: 'pointer'}}
          />
          {/* {rest.row.original.new_security_array.length > 1 && (
            <Text
              size="small"
              color="brand"
              style={{cursor: 'pointer', textDecoration: 'underline'}}
              onMouseLeave={() => hideSecurity()}
              onMouseOut={() => hideSecurity()}
              onMouseOver={event => {
                showSecurity(rest.row.original, event.clientX, event.clientY)
              }}
            >
              {'view more'}
            </Text>
          )} */}
        </Box>
      )
    },
    disableSortBy: true,
  },

  {
    accessor: `lender`,
    Header: 'Lender',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `applicant_type`,
    Header: 'Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: `loan__account_number`,
    Header: 'Acc no/BSB no',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `loan__loan_limit`,
    Header: 'Loan Limit',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `loan__balance`,
    Header: 'Balance',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `rate`,
    Header: 'Rate',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value + '%'}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `loan_expiry_date`,
    Header: 'Loan Expiry Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `modified`,
    Header: 'Last Updated Date',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `purpose`,
    Header: 'Purpose',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: `rate_type_choice`,
    Header: 'Rate Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: `fixed_expiry`,
    Header: 'Fixed Expiry',
    Cell: ({cell: {value}, hideSecurity, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
          onMouseEnter={() => hideSecurity()}
        />
      )
    },
    // disableSortBy: true,
  },

  {
    accessor: `repayment`,
    Header: 'Repayment',
    Cell: ({cell: {value}, hideSecurity, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
          onMouseEnter={() => hideSecurity()}
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: `io_expiry`,
    Header: 'IO Expiry',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },
  {
    accessor: `out_come`,
    Header: 'Outcome',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
          style={{cursor: 'pointer'}}
        />
      )
    },
    // disableSortBy: true,
  },
]

const OALRTable = ({
  tableData,
  event,
  page,
  rows,
  size,
  setPage,
  setSize,
  navigateToParams,
  setLoader,
  setMoreDetailLayer,
  setMoreLoader,
  filterData: {select_page, select_page_size},
}) => {
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState([
    {
      main_pk: 1,
      pk: 1,
      name: 'Cusotmer',
      email: '',
      phone: '',
      balance: 0,
      outcome: '1',
      date: '15-Apr-2020',
    },
  ])
  const [hovervisibility, sethovervisibility] = useState(false)
  const [securityinfo, setsecurityinfo] = useState('')

  const updateCellData = (rowId, column, value) => {
    setData(
      data.map((row, index) => {
        if (row.id === parseInt(rowId)) {
          return {
            ...row,
            [column]: value,
          }
        }
        return row
      }),
    )
  }

  const handleApplicant = (value, id) => {
    showMoreDetails(event, id)
    setMoreLoader(true)
    setMoreDetailLayer(true)
  }

  const handlecolumnClick = cell => {
    // console.log('cell', cell)
    // let path = window.location.pathname
    // let id = path.split('/')
    // let clientId = id[id.length - 1]
    // history.push('/clients/' + clientId + '/' + tableData[cell.row.id].pk)
  }

  const handleLoan = loan_id => {
    let path = window.location.pathname
    let id = path.split('/')
    let clientId = id[id.length - 1]
    history.push('/clients/' + clientId + '/' + loan_id)
  }

  const handleSort = sortOrder => {
    if (!sortOrder.length) navigateToParams({ordering: null})
    else {
      const [{id, desc}] = sortOrder
      setLoader(true)
      navigateToParams({ordering: desc ? '-' + id : id})
    }
  }

  // const handlePagination = (val, event) => {
  //   function fetchLoan(val, event) {
  //     let params = {}
  //     params['page'] = val.page
  //     params['page_size'] = val.size
  //     getLoanListOnFilterparams(event, params)
  //   }
  //   fetchLoan(val, event)
  // }
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({select_page: page, select_page_size: page_size})
  }

  const showSecurity = (props, positionX, positionY) => {
    sethovervisibility(!hovervisibility)
    setsecurityinfo({props: props, positionX: positionX, positionY: positionY})
  }
  const hideSecurity = () => {
    sethovervisibility(!hovervisibility)
  }

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  function prepare_Seurities(tableData) {
    tableData.forEach(element => {
      element.new_security_array.forEach(address => {
        address.complete_addr =
          (address.address_line_1 === null ? '' : address.address_line_1) +
          ' ' +
          (address.address_line_2 === null ? '' : address.address_line_2) +
          ' ' +
          (address.city === null ? '' : address.city) +
          ' ' +
          (address.state === null ? '' : address.state) +
          ' ' +
          (address.postcode === null ? '' : address.postcode) +
          ' ' +
          (address.country === null ? '' : address.country)
      })
    })
    return tableData
  }
  prepare_Seurities(tableData)

  return (
    <Box>
      <TableWrapper>
        <ReactTable
          initialSortOrder={getInitialSorting()}
          data={tableData}
          columns={COLUMNS}
          onSortChange={handleSort}
          updateCellData={updateCellData}
          rowClickHandler={row => {}}
          columnClickHandler={cell => handlecolumnClick(cell)}
          showSecurity={showSecurity}
          hideSecurity={hideSecurity}
          handleApplicant={handleApplicant}
          handleLoan={handleLoan}
        />

        {hovervisibility && (
          <MoreSecurityInfo
            sethovervisibility={sethovervisibility}
            hovervisibility={hovervisibility}
            securityinfo={securityinfo}
          />
        )}
      </TableWrapper>
      <Box margin={{top: 'small'}}>
        {tableData.length > 0 ? (
          <Box direction="row" width="100%" pad="small">
            <Box width="50%" alignContent="end" align="start">
              <Text color="dark-6">
                {rows < select_page_size ? rows + ' out of ' + rows : tableData.length + ' out of ' + rows}
              </Text>
            </Box>
            <Box width="50%" alignContent="end" align="end">
              {tableData.length > 0 ? (
                <Pagination
                  onPageChange={handlePagination}
                  showSizeChanger
                  pageData={{page: select_page, size: select_page_size, total: rows}}
                  select={true}
                />
              ) : (
                <Box></Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box></Box>
        )}
      </Box>
    </Box>
  )
}

export default OALRTable
