import React from 'react'
import {Link} from 'react-router-dom'
import {Box, Image} from 'grommet'
import styled from 'styled-components'
import logoImage from 'assets/indigo_logo.png'
import {getAuthToken} from 'utils/auth-singleton'

const HeaderWrap = styled(Box)`
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
`

const Header = () => {
  const authToken = getAuthToken()
  return (
    <HeaderWrap
      fill="horizontal"
      direction="row"
      align="start"
      as="header"
      alignContent="center"
      justify="start"
      elevation="small"
      pad={{horizontal: 'xxlarge', vertical: 'medium'}}
      flex={{shrink: 0}}
    >
      {!authToken && (
        <Link to="/login">
          <Image
            margin={{top: '7px', left: '42px'}}
            alt="logo"
            height="50px"
            src={logoImage}
          />
        </Link>
      )}
    </HeaderWrap>
  )
}

export default Header
