// import GraniteError from 'utils/granite-error'
import {
  getPermissionsAndSideMenuItems,
  getPermissions,
} from 'accounts/gateways/sidebar-api'
import {resetAuthToken} from 'utils/auth-singleton'
// import SidemenuEntity from 'accounts/entities/sidemenu'

async function loadMenuItemsAndPermissions() {
  try {
    const permissions = await getPermissions()
    const response = await getPermissionsAndSideMenuItems()
    const sidemenuItems = response
    let MenuItems = []
    sidemenuItems.forEach(element => {
      if (element.parent_id === null) {
        sidemenuItems.forEach(item => {
          if (item.parent_id && element.pk === item.parent_id) {
            if (element.children) {
              element.children.push(item)
            } else {
              element.children = []
              element.children.push(item)
            }
          }
        })
        MenuItems.push(element)
      }
    })

    // console.log('Sidemenu items  controller', sidemenuItems)
    //  console.log('MenuItems items  controller', MenuItems)
    return {sidemenuItems, MenuItems, permissions}
  } catch (error) {
    // const errorMessage = error.response.data.message
    // throw new GraniteError(errorMessage)
  }
}

function removeAuthToken() {
  resetAuthToken()
}

export {loadMenuItemsAndPermissions, removeAuthToken}
