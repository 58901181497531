class LENDERS {
  constructor(item) {
    this.lender_name = item.name
    this.lender_pk = item.pk
    this.lender_email= item.email
    this.lender_phone = item.phone
    this.lender_address = item.address
    this.lender_broker_pk = item.broker.pk
    this.lender_broker_name = item.broker.name
  }
}
export default LENDERS
