import React from 'react'
import {Box, Button, Text, TextInput, Image} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import {updateBrokerDetails} from 'brokerDetails/controllers/brokerDetails'
import {addBrokerDetails} from 'brokerDetails/controllers/brokerDetails'
import styled from 'styled-components'

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;s
`
const UserProfileWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const TextWrapper = styled(Text)`
  font-weight: 500;
`

const CommonUserForm = props => {
 
  const parentevent = props.event
  const setUserLayer = props.setUserLayer
  const setEditLayer = props.setEditLayer
  const editLayer = props.editLayer
  const userLayer = props.userLayer
  const editPayload = props.editPayload || null
  // const designation = props.data.designation || 'User'
 // const [value, setValue] = useState(designation)
 // const [checked, setChecked] = useState(false)
  const validationSchema = Yup.object().shape({
    // user_name: Yup.string().required('User name is required'),
    // email: Yup.string()
    //   .min(2, 'Too short')
    //   .max(50, 'Too long')
    //   .email('Invalid Email')
    //   .required('Email is Required'),
    // title: Yup.string().required('Please select title'),
    // roles: Yup.string().required('Please select aceess'),
  })

  const adduserRole = async (values, {setSubmitting, setErrors}) => {
   
    setUserLayer(false)
    // let pk = props.pk || ''
    setErrors({})
    setSubmitting(true)
    await addBrokerDetails(values, parentevent)
    setSubmitting(false)
  }

  const editUserRole = (values, {setSubmitting, setErrors}) => {
  
    setEditLayer(false)
    setErrors({})
    setSubmitting(true)
    updateBrokerDetails(values, parentevent)
    setSubmitting(false)
  }

  return (
    <Box overflow="auto">
      <Formik
        initialValues={{
          // user_name: props.data.name,
          // title: props.data.designation,
          // email: props.data.email,
          // access: props.data.designation,
          bank: props.data.bank,
          a_code: props.data.a_code,
          t_code: props.data.t_code,
          m_code: props.data.m_code,
          // roles: props.data.role,
        }}
        validationSchema={validationSchema}
        onSubmit={editPayload == null ? adduserRole : editUserRole}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box>
              <UserProfileWrapper direction="row" justify="between">
                <TextWrapper
                  color="dark-4"
                  size="16px"
                  margin={{left: '20px', top: 'medium', bottom: '15px'}}
                >
                  {editPayload == null ? 'Add Broker' : 'Edit Broker'}
                </TextWrapper>

                <Button
                  margin={{
                    top: 'small',
                    bottom: '15px',
                    right: 'small',
                  }}
                  icon={
                    <Image src={process.env.PUBLIC_URL + 'img/Cross.png'} />
                  }
                  onClick={() => {
                    editPayload == null
                      ? setUserLayer(!userLayer)
                      : setEditLayer(!editLayer)
                  }}
                />
              </UserProfileWrapper>
            </Box>

            <Box margin={{left: 'medium', right: 'medium'}}>
              <FormField
                name="bank"
                label={<Text color="dark-4">{'Bank'}</Text>}
                error={touched.bank && errors.bank}
              >
                <TextInput
                  placeholder="Bank"
                  type="bank"
                  name="bank"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.bank}
                />
              </FormField>

              <FormField
                name="a_code"
                label={<Text color="dark-4">{'Donna Broker Code'}</Text>}
                error={touched.a_code && errors.a_code}
              >
                <TextInput
                  placeholder="Donna Broker Code"
                  name="a_code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.a_code}
                  // disabled={props.data.a_code ? true : false}
                />
              </FormField>

              <FormField
                name="t_code"
                label={<Text color="dark-4">{'Mark Broker Code'}</Text>}
                error={touched.t_code && errors.t_code}
              >
                <TextInput
                  placeholder="Mark Broker Code"
                  name="t_code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.t_code}
                  // disabled={props.data.email ? true : false}
                />
              </FormField>

              <FormField
                name="m_code"
                label={<Text color="dark-4">{'Peter Broker Code'}</Text>}
                error={touched.m_code && errors.m_code}
              >
                <TextInput
                  placeholder="Peter Broker Code"
                  name="m_code"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.m_code}
                  // disabled={props.data.email ? true : false}
                />
              </FormField>

              <Box align="end" margin={{bottom: 'medium', top: 'small'}}>
                <ButtonStyle
                  primary
                  disabled={isSubmitting}
                  label="Save"
                  type="submit"
                />
              </Box>
              <Text as="div" color="status-critical">
                {errors.non_field}
              </Text>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}

export default CommonUserForm
