import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {getAuthToken} from 'utils/auth-singleton'
import LinearLayout from 'granite/components/LinearLayout'
import FullScreenLayout from 'granite/components/FullScreenLayout'

const PublicOnlyRoute = ({component: Component, layout, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => {
        const authToken = getAuthToken()
        if (authToken) {
          // ONLY non-logged in users are allowed to access this route.
          // return <Redirect to={{pathname: '/dashboard'}} />
          return <Redirect to={{pathname: '/dashboard'}} />
        }
        if (layout === 'HeaderLayout') {
          return (
            <LinearLayout>
              <Component {...props} />
            </LinearLayout>
          )
        } else {
          return (
            <FullScreenLayout>
              <Component {...props} />
            </FullScreenLayout>
          )
        }
      }}
    />
  )
}

export default PublicOnlyRoute
