// lib imports
import React from 'react'
import {Button, Box} from 'grommet'
import {Edit, Trash} from 'grommet-icons'

// CORE imports
import Popconfirm from 'granite/components/Popconfirm'

const ActionsCell = ({data: buttons, ...rest}) => {
  if (Boolean(buttons) === false) return null
  const buttonsJsx = buttons.map((btn, i) => {
    switch (btn.name) {
      case 'edit':
        return <Button key={i} icon={<Edit size="small" />} {...btn} />
      case 'delete':
        const {onClick, ...rest} = btn
        return (
          <Popconfirm key={i} align="right" onConfirm={onClick}>
            <Button icon={<Trash size="small" />} {...rest} />
          </Popconfirm>
        )
      default:
        return null
    }
  })
  return <Box direction="row">{buttonsJsx}</Box>
}

export default ActionsCell
