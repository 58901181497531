//Library Imports
import React from 'react'
import {Box, Text} from 'grommet'
import styled from 'styled-components'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-top: 2px solid #37236a;
`
const ClientHeading = styled(Text)`
  font-weight: 500;
`
const ClientData = styled(Text)`
  font-weight: 400;
`

const ClientInfo = ({clientInformation, campaignDetails, customMonth}) => {
  return (
    <Box>
      <BoxWrapper
        pad={{bottom: 'medium'}}
        margin={{bottom: 'small'}}
        background="light-1"
      >
        <Box margin={{left: 'small', top: 'small'}} pad={{left: 'small'}}>
          <Text size="16px" weight="bold" color="dark-6">
            Client Information
          </Text>
        </Box>
        <Box direction="row" pad={{top: 'medium', left: 'small'}}>
          <Box pad={{left: 'medium', right: 'medium'}}>
            <ClientHeading size="14px" color="dark-6">
              Name
            </ClientHeading>

            {clientInformation && (
              <ClientData size="12px" margin={{top: 'small'}} color="dark-6">
                {clientInformation.user_name}
              </ClientData>
            )}
          </Box>
          <Box pad={{left: 'xxlarge', right: 'medium'}}>
            <ClientHeading size="14px" color="dark-6">
              Phone No.
            </ClientHeading>

            {clientInformation && (
              <ClientData size="12px" margin={{top: 'small'}} color="dark-6">
                {clientInformation.user_phone}
              </ClientData>
            )}
          </Box>
          <Box pad={{left: 'xxlarge', right: 'medium'}}>
            <ClientHeading size="14px" color="dark-6">
              Email
            </ClientHeading>

            {clientInformation && (
              <ClientData size="12px" margin={{top: 'small'}} color="dark-6">
                {clientInformation.user_email}
              </ClientData>
            )}
          </Box>

          <Box pad={{left: 'xxlarge', right: 'medium'}}>
            <ClientHeading size="14px" color="dark-6">
              Campaign Month
            </ClientHeading>
            {!campaignDetails ? (
              ''
            ) : (
              <ClientData size="12px" margin={{top: 'small'}} color="dark-6">
                {customMonth !== 'null' ? customMonth : campaignDetails.month}
              </ClientData>
            )}
          </Box>
        </Box>
      </BoxWrapper>
    </Box>
  )
}

export default ClientInfo
