class Risk {
  constructor({pk, oop_and_i, oo_io, inv_p_and_i, inv_io, organisation_id}) {
    this.pk = pk
    this.oop_and_i = oop_and_i
    this.oo_io = oo_io
    this.inv_p_and_i = inv_p_and_i
    this.inv_io = inv_io
    this.organisation_id = organisation_id
  }
}

export default Risk
