const CapitalMonthObject = {
  '1': 'JANUARY',
  '2': 'FEBRUARY',
  '3': 'MARCH',
  '4': 'APRIL',
  '5': 'MAY',
  '6': 'JUNE',
  '7': 'JULY',
  '8': 'AUGUST',
  '9': 'SEPTEMBER',
  '10': 'OCTOBER',
  '11': 'NOVEMBER',
  '12': 'DECEMBER',
}

  const ReverseCamelCasemonthObject = {
    January: '1',
    February: '2',
    March: '3',
    April: '4',
    May: '5',
    June: '6',
    July: '7',
    August: '8',
    September: '9',
    October: '10',
    November: '11',
    December: '12',
  }


   const LowerCaseMonthObject = {
     january: 1,
     february: 2,
     march: 3,
     april: 4,
     may: 5,
     june: 6,
     july: 7,
     august: 8,
     september: 9,
     october: 10,
     november: 11,
     december: 12,
   }
export {CapitalMonthObject, ReverseCamelCasemonthObject, LowerCaseMonthObject}