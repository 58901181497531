//Library imports
import React, {useState, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Box} from 'grommet'
import queryString from 'query-string'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'

//Core imports
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'

//Application imports
import CLIENT_LIST_MESSAGES from 'messages/ClientList'
import CLIENT_LIST from './constants'
import ClientTable from './components/clientListTable'
import {fetchEmployee} from 'clientlist/controllers/client'
import NewHeader from 'granite/components/HeaderWithDropDown'

let eventEmitter = new EventEmitter()

function listenEvents({
  eventEmitter,
  setData,
  setCount,
  setToastData,
  setTotalRows,
  setSize,
  setLoader,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case CLIENT_LIST.GET_CLIENTSLIST_SUCCESS:
        if (event.data.data.length) {
          setData(event.data.data)
          setTotalRows(event.data.count)
          setLoader(false)
          setCount(Math.ceil(event.data.count / 10))
        } else {
          setLoader(false)
          setData([])
        }
        break
      case CLIENT_LIST.GET_CLIENTSLIST_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_LIST_MESSAGES.CLIENT_LIST_GET_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setLoader(false)
        break
      case CLIENT_LIST.GET_CLIENTSLIST_EXPORT_SUCCESS:
        setLoader(false)
        setToastData({
          open: true,
          message:  CLIENT_LIST_MESSAGES.GET_CLIENTSLIST_EXPORT_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case CLIENT_LIST.GET_CLIENTSLIST_EXPORT_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLIENT_LIST_MESSAGES.GET_CLIENTSLIST_EXPORT_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setLoader(false)
        break
      default:
        break
    }
  })
  return subsciption
}

const Clientlist = ({userProfile, permissions}) => {
  const [count, setCount] = useState(10)
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [rows, setTotalRows] = useState(10)
  let [data, setData] = useState([])
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [loader, setLoader] = useState(true)
  let defaultFilterData = {
    page: 1,
    page_size: 20,
    ordering: 'phone',
  }
  let history = useHistory()
  let location = useLocation()
  const {search} = location

  let queryParam = queryString.parse(search)
  const [value, setValue] = useState(queryParam.search ? queryParam.search : '')
  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)

  const handleRefresh = () => {
    if (!loader) {
      setSize(20)
      setLoader(true)
      setValue('')
      navigateToParams({
        page: 1,
        page_size: 20,
        search: null,
      })
    }
  }

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setData,
        setCount,
        setTotalRows,
        setSize,
        setLoader,
      })
      const obj = {
        params: filterData,
      }
      fetchEmployee(eventEmitter, obj.params)
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [location, filterData])

  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'Client List'}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
      />
      <Box overflow={{vertical: 'auto'}} fill>
        <Box>
          <ClientTable
            tableData={data}
            event={eventEmitter}
            page={page}
            rows={rows}
            size={size}
            setPage={setPage}
            setSize={setSize}
            eventEmitter={eventEmitter}
            userProfile={userProfile}
            setLoader={setLoader}
            loader={loader}
            count={count}
            value={value}
            navigateToParams={navigateToParams}
            permissions={permissions}
          />
        </Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})
export default connect(mapStateToProps)(Clientlist)
