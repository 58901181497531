const CONTACTING_EVENTS = {
  GET_CAMPAIGN_SUCCESS: 'CAMPAIGN_SUCCESS',
  GET_CAMPAIGN_FAILURE: 'CAMPAIGN_FAILURE',
  GET_LOAN_DETAILS_SUCCESS: 'GET_LOAN_SUCCESS',
  GET_LOAN_DETAILS_FAILURE: 'GET_LOAN_FAILURE',
  STATUS_CHANGED: 'STATUS_CHANGED_SUCCESS',
  GET_SEARCH_SUCCESS: 'GET_SEARCH_SUCCESS',
  GET_SEARCH_FAILURE: 'GET_SEARCH_FAILURE',
  SHOW_DATA_CHANGED: 'DATA_CHANGED_SUCCESS',
  GET_CLIENT_LOAN_DETAILS_SUCCESS: 'GET_CLIENT_LOAN_DETAILS_SUCCESS',
  GET_CLIENT_LOAN_DETAILS_FAILURE: 'GET_CLIENT_LOAN_DETAILS_FAILURE',
  STATUS_CHANGED_SUCCESS: 'STATUS_CHANGED_SUCCESS',
  STATUS_CHANGED_FAILURE: 'STATUS_CHANGED_FAILURE',
  ALR_DATA_SUCCESS: 'ALR_DATA_SUCCESS',
  ALR_DATA_FAILURE: 'ALR_DATA_FAILURE',
  CAMPAIGN_LIST_SUCCESS: 'CAMPAIGN_LIST_SUCCESS',
  CAMPAIGN_LIST_FAILURE: 'CAMPAIGN_LIST_FAILURE',
  GET_MORE_DETAILS_SUCCESS: 'GET_MORE_DETAILS_SUCCESS',
  GET_MORE_DETAILS_FAILURE: 'GET_MORE_DETAILS_FAILURE',
  MESSAGE_ARRIVED: 'MESSAGE_ARRIVED',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_ADD_LOAN_LOADER: 'SHOW_ADD_LOAN_LOADER',
  HIDE_ADD_LOAN_LOADER: 'HIDE_ADD_LOAN_LOADER',
  GET_RISK_SUCCESS: 'GET_RISK_SUCCESS',
  GET_RISK_FAILURE: 'GET_RISK_FAILURE',
  RATE_CHANGED_SUCCESS: 'RATE_CHANGED_SUCCESS',
  RATE_CHANGED_FAILURE: 'RATE_CHANGED_FAILURE',
  DISCOUNT_CHANGED_SUCCESS: 'DISCOUNT_CHANGED_SUCCESS',
  DISCOUNT_CHANGED_FAILURE: 'DISCOUNT_CHANGED_FAILURE',
  GET_SAVINGS_SUCCESS: 'GET_SAVINGS_SUCCESS',
  GET_SAVINGS_FAILURE: 'GET_SAVINGS_FAILURE',
  GET_CAMPAIGN_LIST_SUCCESS: 'GET_CAMPAIGN_LIST_SUCCESS',
  GET_CAMPAIGN_LIST_FAILURE: 'GET_CAMPAIGN_LIST_FAILURE',
  GET_MAIL_TEMPLATE_LIST_SUCCESS: 'GET_MAIL_TEMPLATE_LIST_SUCCESS',
  GET_MAIL_TEMPLATE_LIST_FAILURE: 'GET_MAIL_TEMPLATE_LIST_FAILURE',
  SEND_MAIL_FAILURE: 'SEND_MAIL_FAILURE',
  SEND_MAIL_SUCCESS: 'SEND_MAIL_SUCCESS',
  SAVE_NEW_CAMPAIGN_SUCCESS: 'SAVE_NEW_CAMPAIGN_SUCCESS',
  SAVE_NEW_CAMPAIGN_FAILURE: 'SAVE_NEW_CAMPAIGN_FAILURE',
}

export default CONTACTING_EVENTS
