import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import Client from 'clientlist/entities/clientList'
import LoanBook from 'clientlist/entities/loanBook'
import ErroredRows from 'clientlist/entities/erroredRows'

async function getCustomerList(params) {
  try {
    const response = await GraniteAPIClient().get(`/loan/applicants/`, {
      params: params,
    })
    const count = response.data.count
    const next_page = response.data.next_page

    const previous_page = response.data.previous_page
    const data = response.data.results.map(item => {
      return new Client(item)
    })
    return {data, count, next_page, previous_page}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function loanBook(data) {
  try {
    const response = await GraniteAPIClient().get('/aggregate/loan-book/', {
      params: data,
    })
    const loanBookData = response.data.results.map(item => {
      return new LoanBook(item)
    })
    const rowsCount = response.data.count
    return {loanBookData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function erroredRecords(pk, values) {
  try {
    const response = await GraniteAPIClient().get(
      '/aggregate/loan-book/rows/' + pk + '/',
      {
        params: values,
      },
    )
    const erroredRowsData = response.data.results.map(item => {
      return new ErroredRows(item)
    })
    const rowsCount = response.data.count
    return {erroredRowsData, rowsCount}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function fileDownload(fileId) {
  try {
    const response = await GraniteAPIClient().get(
      '/aggregate/loan-book/invalid-records/' + fileId + '/',
    )
    return response.data.url
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getSampleFileDownload() {
  try {
    const response = await GraniteAPIClient().get('/aggregate/bulk/loan-book/')
    return response.data.url
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function fileImport(fileData) {
  try {
    let url
    let headers = {
      'Content-Type': 'multipart/form-data',
    }

    const response = await GraniteAPIClient(url, headers).post(
      '/aggregate/bulk/loan-book/',
      fileData,
    )
    return response.data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// async function ExportCutomerList(fileData) {
//   try {
//     const response = await GraniteAPIClient().post(
//       `aggregate/export-loan-book/`,
//     )
//     return response
//   } catch (error) {
//     const errorMessage = error.errors.title ? error.errors.title : ''
//     throw new GraniteError(errorMessage)
//   }
// }

export default {
  getCustomerList,
  loanBook,
  erroredRecords,
  fileDownload,
  getSampleFileDownload,
  fileImport,
  // ExportCutomerList,
}
