class Savings {
  constructor({purple, green}) {
    this.totalSavings =
      purple !== 'None'
        ? purple.includes('-')
          ? '-$' + purple.replace('-', '')
          : '$' + purple
        : '$' + 0
    this.alrSavings = green !== "None"
      ? green.includes('-')
        ? '-$' + green.replace('-', '')
        : '$' + green
      : '$' + 0
  }
}

export default Savings
