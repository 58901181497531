import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'

async function LogoutAPI(value) {
  try {
    const response = await GraniteAPIClient().delete(`/account/logout/`)
    return response
  } catch (error) {
    const errorMessage = error.response.data.message
    throw new GraniteError(errorMessage)
  }
}

export default {
  LogoutAPI,
}
