const REVIEW_APPLICANT_MESSAGES = {
  GET_LOAN_DETAILS_SUCCESS: 'Successfully fetched user!',

  GET_LOAN_DETAILS_FAILURE:
    'We are sorry something went wrong, please refresh this page!',

  GET_SEARCH_SUCCESS: 'Successfully fetched user!',

  GET_SEARCH_FAILURE: 'We are sorry something went wrong, please try again!',

  GET_CAMPAIGN_SUCCESS: 'Successfully fetched user!',

  GET_CAMPAIGN_FAILURE:
    'We are sorry something went wrong, please click on the sync button or refresh the page!',

  STATUS_SAVE_FAILURE: "We're sorry, something went wrong. Please try again!",

  STATUS_OUTCOME_BOTH_WARNING:
    'Please update outcome and loan status to proceed!',

  SEND_MAIL_SUCCESS: 'Email sent successfully',

  SEND_MAIL_FAILURE:
    "We're sorry, something went wrong, or you don’t have permission to perform this action.",
  SEND_SMS_SUCCESS: 'Message sent successfully',

  SEND_SMS_FAILURE:
    "We're sorry, something went wrong, or you don’t have permission to perform this action.",
}

export default REVIEW_APPLICANT_MESSAGES
