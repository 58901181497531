import React from 'react'
import {Box, TextInput, Button, Text, Image, Heading} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import styled from 'styled-components'
import {useHistory} from 'react-router-dom'

const RequestTokenForm = ({onSubmit, emailerror, setEmail}) => {
  const history = useHistory()
  const TextInput1 = styled(TextInput)`
    borderradius: 0;
    border-top: none;
    border-right: none;
    border-left: none;
    padding-left: 0px;
  `

  const ButtonStyle = styled(Button)`
    height: 36px;
    // width: 200px;
  `

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .email('Invalid email')
      .required('Required Email'),
  })

  return (
    <>
      <Box direction="row">
        <Box
          width="470px"

          //  border={{ color: 'red', size: 'large' }}
        >
          <Box>
            <Box
              width="2%"
              align="start"
              margin={{bottom: 'small'}}
              onClick={() => {
                history.push('/login')
              }}
            >
              <Image
                fit="contain"
                src={process.env.PUBLIC_URL + '/img/back.png'}
                style={{cursor: 'pointer'}}
              />
            </Box>
          </Box>
          <Heading
            textAlign="left"
            color="brand"
            level={2}
            margin={{bottom: 'medium'}}
          >
            {'Forgot Password?'}
          </Heading>
          <Heading color="dark-2" level={4} margin={{bottom: 'large'}}>
            {
              "Enter the email address you  used when you joined and we'll send you instructions to reset your password"
            }
          </Heading>
          <Formik
            initialValues={{email: ''}}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit,
              error,
              email,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormField
                  name="email"
                  label="Email Address"
                  required
                  error={touched.email && errors.email}
                >
                  <TextInput1
                    pad="none"
                    type="email"
                    name="email"
                    onChange={event => {
                      handleChange(event.target.value)
                      setFieldValue('email', event.target.value)
                      setEmail('')
                    }}
                    onBlur={handleBlur}
                    color="white"
                    placeholder="Email Address"
                    size="medium"
                    value={values.email}
                    disabled={false}
                  />
                </FormField>
                <Text as="div" color="status-critical">
                  {emailerror}
                </Text>
                <Box
                  fill="horizontal"
                  direction="column"
                  elevation="none"
                  justify="between"
                  pad="none"
                >
                  <ButtonStyle
                    type="submit"
                    alignSelf="end"
                    margin={{vertical: 'small'}}
                    label={
                      isSubmitting
                        ? 'Sending Email...'
                        : 'Send Reset Instruction'
                    }
                    disabled={isSubmitting}
                    primary
                  />
                </Box>
                <Text as="div" color="status-critical">
                  {errors.non_field}
                </Text>
              </form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  )
}

export default RequestTokenForm
