class AlrDetails {
  constructor({pk, loan, applicant, lender, product, new_rate}) {
    this.pk = pk

    this.loan_pk = loan.pk
    this.loan_purpose = loan.loan_purpose
    this.loan_term = loan.term
    this.loan_settlement_date = loan.settlement_date
    this.loan_limit = loan.loan_limit
    this.loan_balance = loan.balance
    this.loan_reply_choice = loan.reply_choice
    this.loan_rate_type_choice = loan.rate_type_choice
    this.loan_status = loan.status
    this.loan_out_come = loan.out_come
    this.loan_package = loan.loan_package
 

    this.applicant_pk = loan.applicant ? loan.applicant.pk : ''
    this.applicant__name = loan.applicant ? loan.applicant.name : ''
    this.applicant_email = loan.applicant ? loan.applicant.email : ''
    this.applicant_phone = loan.applicant ? loan.applicant.phone : ''

    this.lender_pk = loan.lender.pk
    this.lender_name = loan.lender.name
    this.lender_bsb_code = loan.lender.bsb_code
    this.product_name = loan.product.name
    this.product_pk = loan.product.pk
    this.product_expiry = loan.product.expiry

    this.status = loan.status
    this.new_rate = parseFloat(new_rate).toFixed(2)
    this.io_expiry = loan.io_expiry
    this.purpose = loan.purpose

    if (loan.out_come === '1') this.loan_out_come = '--No Changes--'
    else if (loan.out_come === '2')
      this.loan_out_come = '$!Money saved, no further action'
    else if (loan.out_come === '3')
      this.loan_out_come = '$!Money saved, Further action required'
    else if (loan.out_come === '4')
      this.loan_out_come = '!No Money Saved, further action'

    this.repayment_type = loan.repayment_type
    this.repayment_choice = loan.repayment_choice
    if (this.purpose === 'INV') {
      if (this.repayment_type === 'P&I') this.selected_risk = 'inv_p_and_i'
      else if (
        this.repayment_type === 'IO' ||
        this.repayment_type === 'IO in Adv'
      )
        this.selected_risk = 'inv_io'
    } else if (this.purpose === 'OO') {
      if (this.repayment_type === 'P&I') this.selected_risk = 'oop_and_i'
      else if (
        this.repayment_type === 'IO' ||
        this.repayment_type === 'IO in Adv'
      )
        this.selected_risk = 'oo_io'
    }

    this.loan_rate = {
      current_rate: parseFloat(loan.rate),
      selected_risk: this.selected_risk,
      new_rate: this.new_rate,
    }
  }
}

export default AlrDetails
