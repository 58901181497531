import React, {useState} from 'react'
import {withTheme} from 'styled-components'
import {Box, Button, MaskedInput} from 'grommet'
import PropTypes from 'prop-types'
import {Add, Subtract} from 'grommet-icons'

const NumberInput = ({
  step,
  error,
  max,
  min,
  onChange: userOnChange,
  placeholder,
  showButtons,
  theme,
  type,
  ...rest
}) => {
  const [val, setVal] = useState('')
  const borderColor = theme.global.colors['status-critical']
  const pColor = theme.global.colors['brand']

  const isTypePhone = type === 'phone'

  let masks = []
  if (isTypePhone) {
    masks = [
      {fixed: '('},
      {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: 'xxx',
      },
      {fixed: ')'},
      {fixed: ' '},
      {
        length: 3,
        regexp: /^[0-9]{1,3}$/,
        placeholder: 'xxx',
      },
      {fixed: '-'},
      {
        length: 4,
        regexp: /^[0-9]{1,4}$/,
        placeholder: 'xxxx',
      },
    ]
  } else {
    masks.push({
      regexp: /^[0-9]*$/,
      placeholder: placeholder,
    })
  }

  const validateAndSetValue = (event, val) => {
    if (isTypePhone) {
      setVal(val)
      userOnChange(event, val)
    } else {
      val = parseInt(val)

      if (isNaN(val)) {
        setVal('')
        userOnChange(event, val)
      }

      if (val <= max && val >= min) {
        setVal(val)
        userOnChange(event, val)
      }
    }
  }

  return (
    <Box
      direction="row"
      align="center"
      round="xsmall"
      border={{color: error ? borderColor : null}}
      pad="hair"
    >
      <MaskedInput
        mask={masks}
        onChange={event => {
          const value = event.target.value
          validateAndSetValue(event, value)
        }}
        plain
        value={val.toString()}
        {...rest}
      />
      {!isTypePhone && showButtons && (
        <Box direction="row" margin={{right: 'small'}}>
          <Button
            hoverIndicator
            icon={<Subtract color={pColor} size="small" />}
            onClick={() => {
              val === ''
                ? validateAndSetValue(-1)
                : validateAndSetValue(val - step)
            }}
          />
          <Button
            icon={<Add color={pColor} size="small" />}
            onClick={() => {
              val === ''
                ? validateAndSetValue(1)
                : validateAndSetValue(val + step)
            }}
            hoverIndicator
          />
        </Box>
      )}
    </Box>
  )
}

NumberInput.defaultProps = {
  error: null,
  step: 1,
  max: Number.MAX_SAFE_INTEGER,
  min: Number.MIN_SAFE_INTEGER,
  onChange: () => {},
  placeholder: '',
  showButtons: false,
  theme: null,
  type: null,
}

NumberInput.propTypes = {
  error: PropTypes.object,
  step: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  showButtons: PropTypes.bool,
  theme: PropTypes.object,
  type: PropTypes.oneOf(['phone']),
}

export default withTheme(NumberInput)
