// Lib Imports
import React from 'react'
import {TableRow, TableCell} from 'grommet'
import styled from 'styled-components'

const StyledRow = styled(TableRow)`
  background-color: ${props => {
    return props.theme.global.colors[props.background]
  }};
  border-bottom: ${props =>
    `1px solid ${props.theme.global.colors['brand-3p']}`};

  &:hover {
    background-color: #d6c6e9;
  }
`

function Row({row, columnClickHandler, ...rest}) {
  return (
    <StyledRow background="white" {...rest}>
      {row.cells.map((cell, i) => {
        return (
          <TableCell
            width={cell.column.width}
            onClick={e => {
              if (typeof columnClickHandler === 'function') {
                e.stopPropagation()
                columnClickHandler(cell)
              }
            }}
            {...cell.getCellProps()}
            plain
            scope="row"
            align={cell.column.align || 'center'}
            border="right"
          >
            {cell.render('Cell')}
          </TableCell>
        )
      })}
    </StyledRow>
  )
}

export default Row
