import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import LOANINFO from 'clientloandetail/entities/LoanInfo'
import LOANAPPLICANT from 'clientloandetail/entities/loanapplicants'
import ScreenActivity from 'clientloandetail/entities/screenactivity'
import Savings from 'clientloandetail/entities/savings'
import SecurityAddress from 'clientloandetail/entities/security_address'
import MailList from 'clientloandetail/entities/mailtemplatelist'
import ALL_LENDERS from 'clientloandetail/entities/loanLenders'
import {CurrencyToInt, New_Date_Convertor} from 'granite/CurrencyConvertor'
import {
  ReverseCamelCasemonthObject,
  LowerCaseMonthObject,
} from 'granite/Months_Object'
async function getLoan(loanid) {
  const dataToProcess = []
  try {
    const response = await GraniteAPIClient().get(
      'loan/loan-info/' + loanid + '/',
    )
    dataToProcess.push(response.data)
    const data = dataToProcess.map(item => {
      return new LOANINFO(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function updateLoanData(values, id, particularloanDetail, userProfile) {
  let settlement_date = await New_Date_Convertor(values.settlementDate)
  let fixed_expiry = await New_Date_Convertor(values.exp_date)
  let io_expiry = await New_Date_Convertor(values.io_expiry)
  let prd_exp_date = await New_Date_Convertor(values.product_exp)
  let loan_expiry = await New_Date_Convertor(values.loan_expiry_date)

  const payload = {
    status: values.status,
    account_number: values.account,
    lender: {
      pk: particularloanDetail.lender.pk,
      name: values.lender_name,
      bsb_code: values.bsb_code,
    },
    bsb_code: values.bsb_code,
    rate_type_choice: values.rate_type_choice,
    balance: values.balance ? parseFloat(CurrencyToInt(values.balance)) : 0,
    settlement_date: settlement_date,
    loan_limit: parseFloat(CurrencyToInt(values.loanlimit)),
    rate: parseFloat(values.loanrate),
    purpose: values.purpose,
    fixed_expiry: fixed_expiry,
    loan_term: values.loan_term,
    offset_amount: values.offset_amount,
    package: values.package === 'YES' ? true : false,
    term: values.loan_term,
    ratetype: values.ratetype,
    repayment_amount: parseFloat(CurrencyToInt(values.re_payment_amount)),
    repayment_account: values.repayment_account,
    repayment_date: values.re_payment_date,
    repayment_period: values.repayment_period,
    re_payment_inv: values.re_payment_inv,
    repayment_choice: values.re_payment_type,
    repayment_type: values.re_payment_type,
    off_set: values.offset_amount,
    io_expiry: io_expiry,
    loan_expiry_date: loan_expiry,
    redraw_fund: parseFloat(CurrencyToInt(values.redraw_fund)),
    // company_name: values.company_name,
  }

  console.log("Coming data in API is ", values)

  const payload_Lender = {
    name: values.lender_name,
    bsb_code: values.bsb_code,
    loan_id: id,
  }

  const payload_product = {
    name: values.product,
    description: '',
    expiry: prd_exp_date,
    loan_id: id,
  }

  try {
    const handlingLender = async payload_Lender => {
      try {
        let getLenderDetails = await GraniteAPIClient().get('loan/lenders/lender/?name=' + payload_Lender.name)
        return getLenderDetails.data
      } catch (error) {
        // let createLender  = await GraniteAPIClient().post('loan/lenders/',temp_lender)
        // return createLender.data
      }

     
    }

    let lenderInfo =  await handlingLender(payload_Lender)
    payload.lender = lenderInfo
    // const response_Lender = await GraniteAPIClient().put(
    //   '/loan/lenders/' + particularloanDetail.lender.pk + '/',
    //   payload_Lender,
    // )

    const response_product = await GraniteAPIClient().put(
      '/loan/products/' + particularloanDetail.product.pk + '/',
      payload_product,
    )
    const response = await GraniteAPIClient().put(
      '/loan/loan-info/' + id + '/',
      payload,
    )
    return {response_product, response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function dischargeLoan(id) {
  const payload = {
    is_active: 'False',
  }
  try {
    await GraniteAPIClient().put('/loan/loan-info/' + id + '/', payload)
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getApplicants(loanid) {
  try {
    const response = await GraniteAPIClient().get(
      'loan/loan-applicants/' + loanid + '/',
    )
    const loaninfo = await GraniteAPIClient().get(
      'loan/loan-info/' + loanid + '/',
    )
    const data = response.data.results.map(item => {
      return new LOANAPPLICANT(item)
    })
    data.forEach(element => {
      element['lender'] = loaninfo.data.lender.name
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getLoanScreenActivity(params) {
  try {
    const response = await GraniteAPIClient().get('common/audits/', {
      params: params,
    })

    const data = response.data.results.map(item => {
      return new ScreenActivity(item)
    })

    return {
      tableData: data,
      count: response.data.count,
      next_page: response.data.next_page,
      previous_page: response.data.previous_page,
      rows: data ? data.length : 0,
    }
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getSavings(params) {
  try {
    const response = await GraniteAPIClient().get(`/alr/savings/`, {
      params: params,
    })
    const entityData = []
    const tempResponse = response.data
    entityData.push(tempResponse)
    const data = entityData.map(item => {
      return new Savings(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}
//addLoanApi

async function addLoanApi(clientId, values) {
  const Type = {
    B: 'BORROWER',
    G: 'GUARANTOR',
    TT: 'TRUSTEE',
    SG: 'SIGNATORY',
    SF: 'SUPERFUND',
    BORROWER: 'B',
    GUARANTOR: 'G',
    TRUSTEE: 'TT',
    SIGNATORY: 'SG',
    SUPERFUND: 'SF',
  }

  const payload_product = {
    name: values.product,
    description: '',
    expiry: values.product_exp ? values.product_exp : null,
  }
  const payload_Lender = {
    name: values.lender_name,
    bsb_code: values.bsb_code,
  }
  try {
    const handlingLender = async payload_Lender => {
      try {
        let getLenderDetails = await GraniteAPIClient().get('loan/lenders/lender/?name=' + payload_Lender.name)
        return getLenderDetails.data
      } catch (error) {
    
      }

     
    }

    let lenderInfo =  await handlingLender(payload_Lender)
  

    var response_product = await GraniteAPIClient().post(
      '/loan/products/',
      payload_product,
    )

    const payload_loan = {
      applicant: clientId,
      applicant_type: Type[values.applicant_type],
      bsb_code:values.bsb_code,
      // company_name: values.company_name,
      account_number: values.account,
      loan_expiry_date: values.loan_expiry_date,
      balance: values.balance ? parseFloat(CurrencyToInt(values.balance)) : 0,
      fixed_expiry: values.exp_date ? values.exp_date : null,
      io_expiry: values.io_expiry ? values.io_expiry : null,
      // lender: response_Lender.data.pk,
      rate: values.rate,
      discount: values.discount,
      repayment_account: values.repayment_account
        ? parseFloat(CurrencyToInt(values.repayment_account))
        : 0,
      redraw_fund: values.redraw_fund
        ? parseFloat(CurrencyToInt(values.redraw_fund))
        : 0,
      loan_limit: parseFloat(CurrencyToInt(values.loanlimit)),
      loan_status: '1',
      new_rate: 0,
      off_set: values.offset_amount,
      package: values.package === 'YES' ? true : false,
      product: response_product.data.pk,
      purpose: values.purpose,
      rate_type_choice: values.rate_type_choice,
      repayment_type: values.re_payment_type,
      repayment_amount: values.re_payment_amount
        ? parseFloat(CurrencyToInt(values.re_payment_amount))
        : 0,
      repayment_date: values.re_payment_date,
      repayment_period: values.repayment_period,
      // security: response_security.data.pk,
      settlement_date: values.settlementDate,
      term: values.loan_term,
      review_cycle: ReverseCamelCasemonthObject[values.review_month],
    }

    payload_loan.lender = lenderInfo.pk

    const response_loan = await GraniteAPIClient().post(
      'loan/loans/',
      payload_loan,
    )

    const payload_security = {
      applicant: clientId,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      city: values.city,
      state: values.state,
      postcode: values.postcode,
      country: values.country,
      loan: response_loan.data.pk,
    }

    await GraniteAPIClient().post('/loan/security-address/', payload_security)

    const payload_loan_mpping = [
      {
        applicant: clientId,
        loan: response_loan.data.pk,
        applicant_type: Type[values.applicant_type],
        is_primary_applicant: true,
      },
    ]

    await GraniteAPIClient().post('loan/bulk-applicants/', payload_loan_mpping)
    return {response_loan}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function mailClientInfo(name, Id, values, loan_id) {
  const payload = {
    template_name: name,
    content: values.content,
    loan_id: parseInt(loan_id),
  }
  try {
    const response = await GraniteAPIClient().post(
      `loan/applicant_mail/` + Id + `/`,
      payload,
    )
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function getMailTemplateListAPI(name, clientid) {
  const params = {
    screen_name: name,
  }
  try {
    const response = await GraniteAPIClient().get(
      `loan/applicant_mail/` + clientid + '/',
      {params: params},
    )
    const data = response.data.map(item => {
      return new MailList(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function lendermanipulate(clientId, values) {
  try {
    var response_Lender = await GraniteAPIClient().get(
      'loan/lenders/lender/?bsb_code=' + values.bsb_code,
    )
    return response_Lender
  } catch (error) {
    const payload = {
      name: values.lender_name,
      phone: '',
      address: {},
      bsb_code: values.bsb_code,
      email: '',
    }
    var create_Lender = await GraniteAPIClient().post('loan/lenders/', payload)
    return create_Lender
  }
}

async function updateLoanInfoOverviewAPI(values, id, particularloanDetail) {
  function Outcome_Value(outcome) {
    if (outcome === '--No Changes--') return '1'
    else if (outcome === '$!Money saved, no further action') return '2'
    else if (outcome === '$!Money saved, further action required') return '3'
    else if (outcome === '$!No money saved, further action required') return '4'
  }
  function statusValue(status) {
    if (status === 'Variation') return '1'
    else if (status === 'No Action') return '2'
    else if (status === 'Broker Review') return '3'
  }

  const payload = {
    review_cycle: LowerCaseMonthObject[values.campaign_month.toLowerCase()],
    out_come: await Outcome_Value(values.outcome),
    // status: values.status,
    loan_status: statusValue(values.status),
    // new_rate: parseFloat(values.new_rate),
    rate: parseFloat(values.rate),
    discount: values.discount ? values.discount : 0,
    // new_discount: values.new_discount ? parseFloat(values.new_discount) : 0,
  }

  try {
    const response = await GraniteAPIClient().put(
      '/loan/loan-info/' + id + '/',
      payload,
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// removeApplicantAPI

async function removeApplicantAPI(applicant_id, loan_id, applicant_mpping) {
  const payload = {loan: loan_id, applicant: applicant_id}
  try {
    const response = await GraniteAPIClient().delete(
      'loan/applicants-loan/' + applicant_mpping + '/',
      payload,
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function addApplicantAPI(payload) {
  try {
    const response = await GraniteAPIClient().post(
      '/loan/bulk-applicants/',
      payload,
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

// Security Address CRUD

// getSecurityAPI
async function getSecurityAPI(clientId, loanId) {
  try {
    const response = await GraniteAPIClient().get(
      '/loan/security-address/?loan_id=' + loanId,
    )
    const data = response.data.results.map(item => {
      return new SecurityAddress(item)
    })
    return data
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}
async function addSecurityAPI(payload) {
  try {
    const response = await GraniteAPIClient().post(
      '/loan/security-address/',
      payload,
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}
async function updateSecurityAPI(payload) {
  try {
    const response = await GraniteAPIClient().put(
      'loan/security-address/' + payload.security_id + '/',
      payload,
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}
async function deleteSecurityAPI(security_id) {
  try {
    const response = await GraniteAPIClient().delete(
      'loan/security-address-delete/' + security_id + '/',
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}


async function getLendersAPI() {
  let defaultParam = {page:1,
  page_size:200}
  try {
    const response = await GraniteAPIClient().get('loan/lenders/',  {
      params: defaultParam,
    })
    const data = response.data.results.map(item => {
      return new ALL_LENDERS(item)
    })
    return {data}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

async function updateApplicantType(id, value) {
  function type_value(value) {
    if (value === 'BORROWER') return 'B'
    else if (value === 'GUARANTOR') return 'G'
    else if (value === 'TRUSTEE') return 'TT'
    else if (value === 'SIGNATORY') return 'SG'
    else if (value === 'SUPERFUND') return 'SF'
  }

  const payload = {
    applicant_type: type_value(value),
  }

  try {
    const response = await GraniteAPIClient().put(
      '/loan/applicants-loan/' + id + '/',
      payload,
    )
    return {response}
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }
}

export default {
  getLoan,
  updateLoanData,
  dischargeLoan,
  getApplicants,
  getLoanScreenActivity,
  getSavings,
  addLoanApi,
  mailClientInfo,
  getMailTemplateListAPI,
  lendermanipulate,
  updateLoanInfoOverviewAPI,
  removeApplicantAPI,
  addApplicantAPI,
  getSecurityAPI,
  addSecurityAPI,
  updateSecurityAPI,
  deleteSecurityAPI,
  getLendersAPI,
  updateApplicantType,
}
