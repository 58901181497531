// lib imports
import React from 'react'
import {Box} from 'grommet'

// CORE imports
import Sidebar from 'granite/components/Sidebar'

// application imports
import Footer from 'granite/components/Footer'
// import Alerts from './components/Alerts'
// import DashboardHeader from './components/DashboardHeader'

const DashboardLayout = ({children, pathname, history}) => {
  return (
    <>
      <Box direction="row" fill background="brand-light">
        <Sidebar
          collapsible
          pathname={pathname}
          history={history}
          background="background-sidebar"
        />
        <Box fill overflow={{vertical: 'auto'}}>
          {children}
          <Footer />
        </Box>
      </Box>
    </>
  )
}

export default DashboardLayout
