const CLIENT_DETAIL_EVENTS = {
  CLIENT_DETAIL_SUCCESS: 'CLIENT_DETAIL_GET_SUCCESS',
  CLIENT_DETAIL_FAILURE: 'CLIENT_DETAIL_GET_FAILURE',

  CLIENT_DETAIL_SAVE_SUCCESS: 'CLIENT_DETAIL_SAVE_SUCCESS',
  CLIENT_DETAIL_SAVE_FAILURE: 'CLIENT_DETAIL_SAVE_FAILURE',

  CLIENT_DETAIL_MAIL_SUCCESS: 'CLIENT_DETAIL_MAIL_SUCCESS',
  CLIENT_DETAIL_MAIL_FAILURE: 'CLIENT_DETAIL_MAIL_FAILURE',

  CLIENT_DETAIL_EXPORT_SUCCESS: 'CLIENT_DETAIL_EXPORT_SUCCESS',
  CLIENT_DETAIL_EXPORT_FAILURE: 'CLIENT_DETAIL_EXPORT_FAILURE',

  CLIENT_GET_LOAN_DETAIL_SUCCESS: 'CLIENT_GET_LOAN_DETAIL_SUCCESS',
  CLIENT_GET_LOAN_DETAIL_FAILURE: 'CLIENT_GET_LOAN_DETAIL_FAILURE',

  CLIENT_GET_LOAN_DETAIL_SELECT_SUCCESS: 'CLIENT_GET_LOAN_DETAIL_SUCCESS',
  CLIENT_GET_LOAN_DETAIL_SELECT_FAILURE: 'CLIENT_GET_LOAN_DETAIL_FAILURE',

  CLIENT_GET_SCREEN_ACTIVITY_SUCCESS: 'CLIENT_GET_SCREEN_ACTIVITY_SUCCESS',
  CLIENT_GET_SCREEN_ACTIVITY_FAILURE: 'CLIENT_GET_SCREEN_ACTIVITY_FAILURE',

  CLIENT_NOTES_SAVE_SUCCESS: 'CLIENT_NOTES_SAVE_SUCCESS',
  CLIENT_NOTES_SAVE_FAILURE: 'CLIENT_NOTES_SAVE_FAILURE',

  CLIENT_NOTES_GET_SUCCESS: 'CLIENT_NOTES_GET_SUCCESS',
  CLIENT_NOTES_GET_FAILURE: 'CLIENT_NOTES_GET_FAILURE',

  CLIENT_NOTES_DELETE_SUCCESS: 'CLIENT_NOTES_DELETE_SUCCESS',
  CLIENT_NOTES_DELETE_FAILURE: 'CLIENT_NOTES_DELETE_FAILURE',

  CLIENT_NOTES_ADD_SUCCESS: 'CLIENT_NOTES_ADD_SUCCESS',
  CLIENT_NOTES_ADD_FAILURE: 'CLIENT_NOTES_ADD_FAILURE',

  CLIENT_SAVINGS_SUCCESS: 'CLIENT_SAVINGS_SUCCESS',
  CLIENT_SAVINGS_FAILURE: 'CLIENT_SAVINGS_FAILURE',

  CLIENT_ADD_NEW_SUCCESS: 'CLIENT_ADD_NEW_SUCCESS',
  CLIENT_ADD_NEW_FAILURE: 'CLIENT_ADD_NEW_FAILURE',

  CLIENT_GET_MAIL_TEMPLATES_SUCCESS: 'CLIENT_GET_MAIL_TEMPLATES_SUCCESS',
  CLIENT_GET_MAIL_TEMPLATES_FAILURE: 'CLIENT_GET_MAIL_TEMPLATES_FAILURE',

  CLIENT_LOAN_LIST_EXPORT_SUCCESS: 'CLIENT_LOAN_LIST_EXPORT_SUCCESS',
  CLIENT_LOAN_LIST_EXPORT_FAILURE: 'CLIENT_LOAN_LIST_EXPORT_FAILURE',

  GET_MORE_DETAILS_SUCCESS: 'GET_MORE_DETAILS_SUCCESS',
  GET_MORE_DETAILS_FAILURE: 'GET_MORE_DETAILS_FAILURE',
}
export default CLIENT_DETAIL_EVENTS
