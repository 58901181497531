class Notes {
  constructor(item) {
    this.create_date = item.create_date
    this.entity_id = item.entity_id
    this.entity_type_id = item.entity_type_id
    this.note = item.note
    this.pk = item.pk
    this.user = item.user.first_name + ' ' + item.user.last_name
  }
}

export default Notes
