import React, {useState} from 'react'
import {Box, Text, Tabs, Tab, Image, Select} from 'grommet'
import styled from 'styled-components'
import LOAN_DETAIL_MESSAGE from 'messages/clientloandetails'
import {updateApplicantType} from 'clientloandetail/controllers/loan'

const ClientHeading = styled(Text)`
  font-weight: 500;
`
const ClientInformation = styled(Text)`
  font-weight: 400;
`
const TextStyle = styled(Text)`
  font-weight: 700;
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-top: 2px solid #37236a;
  min-height: 7%;
`
const StyledSelect = styled(Select)`
  top: 404px;
  left: 478px;
  width: 90%;
  height: 12px;
  color:  #212121;
  border-top: 1px solid #37236A important
  opacity: 1
  font-size: 12px;
  padding-left:8px !important;
  padding-right:8px !important;
  padding-top:8px !important;
  padding-bottom:8px !important;
`
const ClientInfo = ({
  applicants,
  setselectedapplicant,
  permissions,
  applicantwarningpopup,
  setapplicantwarningpopup,
  isLoanActive,
  setselectedapplicanttoremove,
  eventEmitter,
  loanId,
  editableType,
  setEditableType
}) => {
  const totalApplicants = applicants.length
  const [activeaTab, setactiveaTab] = useState(0)
  const [type, setType] = useState()
  
  const handleUnlinkApplicant = props => {
    setapplicantwarningpopup(true)
    setselectedapplicanttoremove(props)
  } 
  const handleTypeEdit = (id) => {
    if (type) {
      updateApplicantType(eventEmitter, loanId, type, id)
    } else {
      setEditableType(false)
    }  
  }

  return (
    <>
      {totalApplicants > 0 ? (
        <BoxWrapper
          width="100%"
          background="light-1"
          elevation="medium"
          pad="small"
          gap="small"
          alignSelf="start"
        >
          <Tabs
            alignSelf="start"
            justify="start"
            width="100%"
            onActive={id => {
              setactiveaTab(id)
              setselectedapplicant(applicants[id])
            }}
          >
            {applicants.map((value, index) => {            
              return (
                <Tab
                  key={index}
                  plain={activeaTab === index ? false : true}
                  margin={{right: 'small'}}
                  alignSelf="start"
                  title={
                    <Box direction="row" gap="xsmall">
                      {!value.primary &&
                        permissions !== null &&
                        permissions.includes('loan_info_edit') && (
                          <Text
                            size="small"
                            weight="bold"
                            color="warning"
                            onClick={() => {
                              if (isLoanActive) {
                                handleUnlinkApplicant(value)
                              }
                            }}
                          >
                            {' x '}
                          </Text>
                        )}
                      {value.primary ? (
                        <TextStyle
                          weight="bold"
                          color={isLoanActive ? 'brand' : 'brand'}
                          size="small"
                        >
                          {value.name}
                        </TextStyle>
                      ) : (
                        <TextStyle
                          weight="bold"
                          color={isLoanActive ? 'dark' : 'dark-2'}
                          size="small"
                        >
                          {value.name}
                        </TextStyle>
                      )}
                    </Box>
                  }
                >
                  <Box>
                    <Box
                      pad={{
                        left: 'medium',
                        top: 'medium',
                        bottom: 'small',
                        right: 'small',
                      }}
                      width="100%"
                      gap="xsmall"
                      direction="row"
                      wrap
                    >
                      <Box width="12%">
                        <ClientHeading
                          color="dark-1"
                          size="small"
                          margin={{bottom: 'small'}}
                        >
                          {'Client Type'}
                        </ClientHeading>
                        <ClientInformation color="dark" size="12px">
                          {value.client_type}
                        </ClientInformation>
                      </Box>
                      <Box width="12%">
                        <ClientHeading
                          color="dark-1"
                          size="small"
                          margin={{bottom: 'small'}}
                        >
                          {'Name'}
                        </ClientHeading>
                        <ClientInformation color="dark" size="12px">
                          {value.name}
                        </ClientInformation>
                      </Box>
                      <Box width="16%">
                        <ClientHeading
                          color="dark-1"
                          size="small"
                          margin={{bottom: 'small'}}
                        >
                          {'Type'}
                        </ClientHeading>
                        <Box direction="row" gap="small">                          
                          {editableType ? (
                            <Box direction="row" gap="small">
                              <StyledSelect
                                options={[
                                  'BORROWER',
                                  'GUARANTOR',
                                  'TRUSTEE',
                                  'SIGNATORY',
                                  'SUPERFUND',                    
                                ]}
                                color="#212121"
                                placeholder={'Type'}
                                value={type}
                                onChange={({option}) =>
                                  setType(option)
                                }
                              />
                              <Box margin={{right:'xxsmall', top:'small'}}>
                                <Image
                                  width="14px"
                                  height="15px"
                                  style={{cursor: 'pointer'}}
                                  src={process.env.PUBLIC_URL + '/Images/save.png'}
                                  onClick={() => {handleTypeEdit(value.pk)}}
                                />
                              </Box>
                              <Box margin={{right:'medium', top:'small'}}>
                                <Image
                                  height="14px"
                                  width="11px"
                                  style={{cursor: 'pointer'}}
                                  src={process.env.PUBLIC_URL + '/img/Cross.png'}
                                  onClick={() => {
                                    setEditableType(false)
                                  }}
                                />
                              </Box>
                            </Box>  
                          ) : (
                            <ClientInformation color="dark" size="12px">
                              {value.applicant_type}
                            </ClientInformation>
                          )}
                          {!editableType &&
                           permissions !== null &&
                           permissions.includes('loan_info_edit') && (
                            <Box>
                              <Image
                                height="12px"
                                style={{cursor: 'pointer'}}
                                src={process.env.PUBLIC_URL + '/img/edit.png'}
                                onClick={() => {
                                  if (isLoanActive) {
                                    setEditableType(true)
                                  }
                                }}
                              />
                            </Box>
                          )}
                        </Box>    
                      </Box>
                      {value.client_type === 'Company' && (
                        <Box width="12%">
                          <ClientHeading
                            color="dark-1"
                            size="small"
                            margin={{bottom: 'small'}}
                          >
                            {'Contact Person'}
                          </ClientHeading>
                          <ClientInformation color="dark" size="12px">
                            {value.contact_person}
                          </ClientInformation>
                        </Box>
                      )}
                      {value.client_type === 'Company' && (
                        <Box width="12%">
                          <ClientHeading
                            color="dark-1"
                            size="small"
                            margin={{bottom: 'small'}}
                          >
                            {'Designation'}
                          </ClientHeading>
                          <ClientInformation color="dark" size="12px">
                            {value.designation}
                          </ClientInformation>
                        </Box>
                      )}{' '}
                      <Box width="12%">
                        <ClientHeading
                          color="dark-1"
                          size="small"
                          margin={{bottom: 'small'}}
                        >
                          {'Lender'}
                        </ClientHeading>
                        <ClientInformation color="dark" size="12px">
                          {value.lender}
                        </ClientInformation>
                      </Box>
                      <Box width={ value.phone ? "12%": "0%"}>
                      { value.phone && <ClientHeading
                          color="dark-1"
                          size="small"
                          margin={{bottom: 'small'}}
                        >
                          {'Phone no.'}
                        </ClientHeading>}
                        <ClientInformation color="dark" size="12px">
                          {value.phone}
                        </ClientInformation>
                      </Box>
                      { value.client_type === "Company" && value.company_name !== "" && (
                        <Box width={value.client_type === "Company" && value.company_name !== ""  ? "12%": "0%"}>
                          <ClientHeading
                            color="dark-1"
                            size="small"
                            margin={{bottom: 'small'}}
                          >
                            {'Company'}
                          </ClientHeading>
                          <ClientInformation color="dark" size="12px">
                            {value.company_name}
                          </ClientInformation>
                        </Box>
                      )}
                      <Box width="18%">
                      { value.email &&  <ClientHeading
                          color="dark-1"
                          size="small"
                          margin={{bottom: 'small'}}
                        >
                          {'Email'}
                        </ClientHeading>}
                        <ClientInformation color="dark" size="12px">
                          {value.email}
                        </ClientInformation>
                      </Box>
                     
                    </Box>
                  </Box>{' '}
                </Tab>
              )
            })}
          </Tabs>
        </BoxWrapper>
      ) : (
        <BoxWrapper
          width="100%"
          background="light-1"
          elevation="medium"
          pad="small"
          gap="small"
          alignSelf="start"
        >
          <Box alignSelf="start">
            <Tabs alignSelf="start" justify="start" width="100%">
              <Tab
                title={
                  <TextStyle weight="bold" color="dark" size="small">
                    {LOAN_DETAIL_MESSAGE.APPLICANT}
                  </TextStyle>
                }
              >
                <Box pad="medium"></Box>
              </Tab>
            </Tabs>
          </Box>
        </BoxWrapper>
      )}
    </>
  )
}

export default ClientInfo
