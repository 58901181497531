import GraniteError from 'utils/granite-error'
import GraniteAPIClient from 'utils/granite-client'
import CampaignDetail from '../entities/campaignDetail'
async function getCampaignDetails() {
  try {
    const response = await GraniteAPIClient().get('/alr/campaign/')
    const campaign = new CampaignDetail(response.data)
    return campaign
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }

}

async function saveCampaign(monthFull, formattedDate, monthNumber, year) {
  const payload = {
    date: formattedDate,
    name: `${monthFull} ${year}`,
    month: monthNumber,
  }
  try {
    const response = await GraniteAPIClient().post('/alr/campaign/', payload)
    return response
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }

}

async function getCampaignDetailsWithId(id) {
  try {
    const response = await GraniteAPIClient().get('/alr/campaign/'+ id + "/")
    const campaign = new CampaignDetail(response.data)
    return campaign
  } catch (error) {
    const errorMessage = error.errors.title ? error.errors.title : ''
    throw new GraniteError(errorMessage)
  }

}
export default {getCampaignDetails, saveCampaign, getCampaignDetailsWithId}
