//Library imports
import React, {useState} from 'react'
import {Box, Button, Text} from 'grommet'
import Form from './Form'
import styled from 'styled-components'
import Tooltip from 'granite/components/Tooltip'
//Core imports
import {editClientInfo} from 'clientDetails/controllers/client'

const ClientHeading = styled(Text)`
  font-weight: 500;
`
const ClientInformation = styled(Text)`
  font-weight: 400;
`
const TextStyle = styled(Text)`
  font-weight: 700;
`
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 06px #00000029;
  border-top: 2px solid #37236a;
`

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const ClientInfo = ({
  clientData,
  event,
  clientId,
  ID,
  setnotesvisibility,
  setLoader,
  eventEmitter,
  showmailmodal,
  setshowmailmodal,
  permissions,
  edit,
  setedit,
}) => {

  const [showToolTip, setshowToolTip] = useState(false)
   const [showToolTipindex, setshowToolTipindex] = useState('500')
 

  const handleEdit = () => {
    setedit(false)
    setnotesvisibility(false)
  }

  const handleMail = () => {
    setshowmailmodal(true)
  }

  const heading = 'Client Information'

  const editDetail = (values, {setSubmitting, setErrors}) => {
    let orignalFormData = {
      first_name: clientData[0][1],
      last_name: clientData[0][1],
      phone: clientData[1][1],
      email: clientData[2][1],
      company_name: clientData[3][1],
      company_ABN: clientData[0][1] === 'Company' ? clientData[4][1] : '',
      client_type: clientData[0][1] === 'Company' ? clientData[5][1] : '',
    }
    for (var key in values) {
      if (values[key] !== orignalFormData[key]) {
        setLoader(true)
        setErrors({})
        setSubmitting(true)
        editClientInfo(event, {...values}, ID, clientId)
        setSubmitting(false)
        setedit(true)
        break
      } else {
        setedit(true)
      }
    }
  }

  return (
    <BoxWrapper
      width="100%"
      background="light-1"
      elevation="medium"
      pad="small"
      gap="small"
    >
      {edit && (
        <Box direction="row" width="100%">
          <Box pad="small" width="50%" gap="xlarge">
            <TextStyle weight="bold" color="dark" size="16px">
              {' '}
              {heading}{' '}
            </TextStyle>
          </Box>
          <Box width="50%" direction="row" justify="end" pad="none">
            {edit ? (
              <Box direction="row" gap="small" pad="none">
                <Box pad="none">
                  {permissions !== null &&
                    permissions.includes('applicant_edit') && (
                      <ButtonStyle
                        type="submit"
                        alignSelf="end"
                        gap="small"
                        margin={{vertical: 'small'}}
                        label={'Edit'}
                        primary
                        onClick={handleEdit}
                      />
                    )}
                </Box>

                <Box pad="none">
                  <ButtonStyle
                    type="submit"
                    alignSelf="end"
                    gap="small"
                    margin={{vertical: 'small'}}
                    label={'Mail'}
                    onClick={handleMail}
                    primary
                  />
                </Box>
              </Box>
            ) : (
              <Box
                width="100%"
                alignSelf="end"
                alignContent="end"
                pad="xxsmall"
              >
                <ButtonStyle
                  type="submit"
                  size="small"
                  alignSelf="end"
                  gap="small"
                  margin={{vertical: 'small'}}
                  label={'Save'}
                  primary
                />
              </Box>
            )}
          </Box>
        </Box>
      )}{' '}
      {edit ? (
        <Box direction="row" pad="small" gap = "small">
          {clientData.map((value, index) => {
            if (index !== 2 && index !== 3) {
              return (
                <Box
                  direction="column"
                  width="33%"
                  overflow="hidden"
                  key={index + 1}
                >
                  <ClientHeading
                    color="dark-1"
                    size="14px"
                    margin={{bottom: 'small'}}
                  >
                    {value[0]}
                  </ClientHeading>
                  <ClientInformation
                    color="dark"
                    size="12px"
                    truncate={true}
                    onMouseEnter={() => {
                      setshowToolTip(true)
                      setshowToolTipindex(index)
                    }}
                    onMouseLeave={() => {
                      setshowToolTip(false)
                      setshowToolTipindex('500')
                    }}
                  >
                    {value[1]}
                  </ClientInformation>
                  {false && showToolTip && showToolTipindex === index && (
                    <Tooltip content={value[1]}>
                      <Button plain label={value[1]} />
                    </Tooltip>
                  )}
                </Box>
              )
            } else return <></>
          })}
        </Box>
      ) : (
        <Form
          clientData={{
            client_type: clientData[0][1],
            name: clientData[1][1],
            first_name: clientData[2][1],
            last_name: clientData[3][1],
            phone: clientData[4][1],
            email: clientData[5][1],
            company_name:
              clientData[0][1] === 'Company' ? clientData[6][1] : '',
            company_ABN: clientData[0][1] === 'Company' ? clientData[7][1] : '',
          }}
          onSubmit={editDetail}
        />
      )}
    </BoxWrapper>
  )
}

export default ClientInfo
