// CORE IMPORTS
import React, {useEffect, useState} from 'react'
import {Box, Image, Text, Layer, Button} from 'grommet'

//COMPONENTS IMPORTS
import {connect} from 'react-redux'
import styled from 'styled-components'
import UserProfileForm from './components/UserProfileForm'
import UPDATE_EVENTS from './constants'
import Toast from 'granite/components/Toast'
import EventEmitter from 'utils/event-emitter'
import * as UserDucks from 'accounts/ducks/user'

//API IMPORTS
import userAPIGateway from 'accounts/gateways/user-api'
import {updateUserProfileInfo} from 'accounts/controllers/user'
let eventEmitter = new EventEmitter()

// Styled Component
const UserProfileWrapper = styled(Box)`
  border-color: #37236a;
  border-width: 2px;
  border-top: none;
  border-left: none;
  border-right: none;
`
function listenEvents({
  eventEmitter,
  setToastData,
  setError,
  history,
  dispatch,
  setLayer,
  layer,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case UPDATE_EVENTS.UPDATE_SUCCESS:
        function fetchUserProfile(dispatch) {
          userAPIGateway
            .myProfile()
            .then(response => {
              dispatch({
                type: 'accounts/user/UPDATE_PROFILE',
                data: response.user,
              })
            })
            .catch(err => {

            })
        }
        fetchUserProfile(dispatch)
        history.push(localStorage.getItem('link'))
        localStorage.removeItem('link')
        // window.history.back();

        break
      case UPDATE_EVENTS.UPDATE_FAILURE:
        setError(true)
        setToastData({
          open: true,
          message: 'ERROR OCCURED',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        setLayer(!layer)

        // window.history.back();
        break
      default:
        break
    }
  })
  return subsciption
}

const UserProfile = ({userProfile, history, dispatch}) => {
  const [layer, setLayer] = useState(true)

  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [error, setError] = useState(false)

  const handleClick = () => {
    setLayer(!layer)
    window.history.back()
  }

  const handleSubmit = (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    updateUserProfileInfo(values, eventEmitter)
    setSubmitting(false)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setError,
        history,
        dispatch,
        setLayer,
        layer,
      })
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [history, dispatch, layer])

  return (
    <Box>
      {layer && (
        <Layer
          onEsc={() => {
            setLayer(!layer)
            window.history.back()
          }}
          onClickOutside={() => {
            setLayer(!layer)
            window.history.back()
          }}
        >
          <Box width="358px" gap="small">
            <UserProfileWrapper direction="row" justify="between" border="10px">
              <Text
                color="dark-4"
                weight="500"
                size="16px"
                margin={{left: 'small', top: 'small', bottom: 'small'}}
              >
                User Profile
              </Text>
              <Button
                margin={{right: 'small', bottom: 'small'}}
                icon={<Image src={process.env.PUBLIC_URL + 'img/Cross.png'} />}
                onClick={() => handleClick()}
              />
            </UserProfileWrapper>
            <Box>
              {toastData.open ? (
                error ? (
                  <Toast background="warning" text={toastData.message} />
                ) : (
                  <Toast text={toastData.message} />
                )
              ) : null}
            </Box>
            <Box margin={{left: 'medium', right: 'medium'}}>
              <UserProfileForm
                onSubmit={handleSubmit}
                name={userProfile.name}
                title={userProfile.title}
              />
            </Box>
          </Box>
        </Layer>
      )}
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

const mapDispatchToProps = dispatch => ({
  dispatch: dispatch,
})
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)
