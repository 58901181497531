import React from 'react'
import {Box, Image, Text} from 'grommet'
import {useHistory} from 'react-router-dom'
const BackNavigation = ({label}) => {
  const history = useHistory()
  const handleBackNavigation = () => {
    history.goBack()
  }

  return (
    <Box pad={{bottom: 'small'}}>
      <Box
        direction="row"
        alignSelf="start"
        gap="small"
        onClick={() => {
          handleBackNavigation()
        }}
      >
        <Image
          fit="contain"
          src={process.env.PUBLIC_URL + '/img/left_arrow.png'}
          style={{cursor: 'pointer'}}
        />
        <Text weight="bold" color="dark" size="14px">
          {`${label ? label : ''}`}
        </Text>
      </Box>
    </Box>
  )
}

export default BackNavigation
