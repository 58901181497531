const CLIENT_LIST_IMPORT_EVENTS = {
  GET_LOAN_BOOK_SUCCESS: 'GET_LOAN_BOOK_SUCCESS',
  GET_LOAN_BOOK_FAILURE: 'GET_LOAN_BOOK_FAILURE',
  GET_ERRORED_RECORDS_SUCCESS: 'GET_ERRORED_RECORDS_SUCCESS',
  GET_ERRORED_RECORDS_FAILURE: 'GET_ERRORED_RECORDS_FAILURE',
  INCORRECT_DOWNLOAD_SUCCESS: 'INCORRECT_DOWNLOAD_SUCCESS',
  INCORRECT_DOWNLOAD_FAILURE: 'INCORRECT_DOWNLOAD_FAILURE',
  SAMPLE_DOWNLOAD_SUCCESS: 'SAMPLE_DOWNLOAD_SUCCESS',
  SAMPLE_DOWNLOAD_FAILURE: 'SAMPLE_DOWNLOAD_FAILURE',
  FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
  FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',
}

export default CLIENT_LIST_IMPORT_EVENTS
