//Library imports
import React, {useState, useEffect} from 'react'
import {Box} from 'grommet'
import {connect} from 'react-redux'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'

//Core imports
import EventEmitter from 'utils/event-emitter'
import Toast from 'granite/components/Toast'
import Header from 'granite/components/HeaderWithDropDown'
import TabComponent from 'granite/components/TabComponent'
import BackNavigation from 'granite/components/BackNavigation'
import MailTemplate from './components/mailmodal'
//Application imports
import CampaignInfo from 'pages/closed/components/CampaignInfo'
import CLOSED_EVENTS from 'pages/closed/constants'
import CLOSED_MESSAGES from 'messages/closed'
import ClosedTable from 'pages/closed/components/ClosedTable'
import MoreDetails from 'pages/contacting/components/MoreDetails'
import RiskRate from 'pages/alr/components/RiskRate'
import CAMPAIGN_EVENTS from 'pages/campaign/constants'
import {fetchClosedData, campaignListcommon} from 'alr/controllers/loanReview'
import * as UserDucks from 'accounts/ducks/user'

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setCampaignDetails,
  setData,
  setLoader,
  setMoreData,
  setMoreLoader,
  setSavings,
  setToastData,
  setmaillist,
  settemplatename,
  settemplatecontent,
  setselectcampaignlist,
  setMoreDataEdit,
  setTabs,
  setRisk,
  filterData,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case CLOSED_EVENTS.GET_RISK_SUCCESS:
        setRisk(event.data)
        break

      case CLOSED_EVENTS.GET_RISK_FAILURE:
        break

      case CAMPAIGN_EVENTS.GET_CAMPAIGN_SUCCESS:
        setCampaignDetails(event.data)
        break
      case CAMPAIGN_EVENTS.GET_CAMPAIGN_FAILURE:
        break

      case CLOSED_EVENTS.GET_LOAN_DETAILS_SUCCESS:
        setTabs({
          new: event.data.tabs_count.new,
          contacting: event.data.tabs_count.contacting,
          Negotiate_Pricing: event.data.tabs_count.Negotiate_Pricing,
          completed: event.data.tabs_count.completed,
          Contact_Client_Of_Result: event.data.tabs_count.Contact_Client_Of_Result,
        })
        setData({data: event.data.loanData, total: event.data.rowsCount})
        setLoader(false)
        break
      case CLOSED_EVENTS.GET_LOAN_DETAILS_FAILURE:
        break

      case CLOSED_EVENTS.GET_MORE_DETAILS_SUCCESS:
        setMoreData(event.data.moreDetails)
        setMoreDataEdit(event.data)
        setMoreLoader(false)
        break
      case CLOSED_EVENTS.GET_MORE_DETAILS_FAILURE:
        break
      case CLOSED_EVENTS.SHOW_LOADER:
        setLoader(true)
        break
      case CLOSED_EVENTS.HIDE_LOADER:
        setLoader(false)
        break
      case CLOSED_EVENTS.GET_SAVINGS_SUCCESS:
        setSavings(event.data)
        break
      case CLOSED_EVENTS.GET_SAVINGS_FAILURE:
        break

      //Get
      case CLOSED_EVENTS.GET_MAIL_LIST_SUCCESS:
        const temparr = []
        const templatenamearr = {}
        const tempcontentarr = {}
        if (event.data.length > 0) {
          event.data.forEach(item => {
            temparr.push(item.email_template.name)
            templatenamearr[item.email_template.name] = item.activity_name
          })
        }
        setmaillist(temparr)
        settemplatename(templatenamearr)

        if (event.data.length > 0) {
          event.data.forEach(item => {
            tempcontentarr[item.email_template.name] =
              item.email_template.content
          })
        }
        settemplatecontent(tempcontentarr)
        break
      case CLOSED_EVENTS.GET_MAIL_LIST_FAILURE:
        break

      //SEND
      case CLOSED_EVENTS.SEND_MAIL_SUCCESS:
        setToastData({
          open: true,
          message: CLOSED_MESSAGES.SEND_MAIL_SUCCESS,
          background: '',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case CLOSED_EVENTS.SEND_MAIL_FAILURE:
        setToastData({
          open: true,
          message: event.data ? event.data : CLOSED_MESSAGES.SEND_MAIL_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case CLOSED_EVENTS.GET_CAMPAIGN_LIST_SUCCESS:
        // console.log('Coming dtaa', event.data.temp)
        setselectcampaignlist(event.data)
        break
      case CLOSED_EVENTS.GET_CAMPAIGN_LIST_FAILURE:
        break
      case CLOSED_EVENTS.STATUS_CHANGED_SUCCESS:
        setToastData({
          open: true,
          message: `Loan(s) Marked In Process Successfully`,
        })
        const obj = {
          // params: {page: 1, page_size: 20},
          params: filterData
        }
        fetchClosedData(eventEmitter, obj, filterData.campaign_id)
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)  
        break
      case CLOSED_EVENTS.STATUS_CHANGED_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : CLOSED_MESSAGES.STATUS_SAVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      default:
        break
    }
  })
  return subscription
}

const Closed = ({userProfile}) => {
  const [tabs, setTabs] = useState({
    new: 0,
    contacting: 0,
    Negotiate_Pricing: 0,
    completed: 0,
    Contact_Client_Of_Result: 0,
  })
  const [campaignDetails, setCampaignDetails] = useState()
  const [risk, setRisk] = useState({})
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [data, setData] = useState({data: [], total: ''})
  const [loader, setLoader] = useState(false)
  const [moreDetailLayer, setMoreDetailLayer] = useState(false)
  const [moreData, setMoreData] = useState([])
  const [savings, setSavings] = useState()
  const [moreLoader, setMoreLoader] = useState(false)
  const [showmailmodal, setshowmailmodal] = useState(false)
  const [applicantsids, setapplicantsids] = useState([])
  const [moreDataEdit, setMoreDataEdit] = useState()
  const [maillist, setmaillist] = useState([
    'Notify to Review ALR',
    'No Action Required ALR',
    'Rate Reduction ALR Email',
    'Interest / Fixed Expiry Template',
    'Missed Call Template',
    'Actions to be completed ALR Email',
    'Recently Dealt with Indigo ALR Email',
  ])
  const [templatename, settemplatename] = useState('CLOSED')
  const [clientfirdtname, setclientfirdtname] = useState('')
  const [applicantsemail, setapplicantsemail] = useState()
  const [tempcontent, settemplatecontent] = useState('')
  const [selectcampaignlist, setselectcampaignlist] = useState({
    campaignData: '',
    temp: [],
  })

  let defaultFilterData = {
    page: 1,
    page_size: 20,
    select_page: 1,
    select_page_size: 20,
    status: 'All_Completed',
    sorting: 'lender'
  }

  let location = useLocation()
  const history = useHistory()
  const {search} = location

  let queryParam = queryString.parse(search)
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )

  const [selcetdcampaign, setselcetdcampaign] = useState(
    queryParam.month_year ? queryParam.month_year : '',
  )

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [statusValue, setStatusValue] = useState(
    queryParam.status ? queryParam.status : '',
  )
  const [filterData, setFilterData] = useState(defaultFilterData)
  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  const handleRefresh = () => {
    setLoader(true)
    setStatusValue('All_Completed')
    setSearchValue('')
    navigateToParams({page: 1, page_size: 20, search: null, status: 'All_Completed', sorting: 'lender'})
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setCampaignDetails,
        setToastData,
        setData,
        setLoader,
        setMoreData,
        setMoreLoader,
        setSavings,
        setmaillist,
        settemplatename,
        settemplatecontent,
        setselectcampaignlist,
        setMoreDataEdit,
        setTabs,
        setRisk,
        filterData,
      })
      const obj = {
        params: filterData,
      }
      fetchClosedData(eventEmitter, obj, filterData.campaign_id)
      let select_params = {
        page: filterData.select_page,
        page_size: filterData.select_page_size,
      }
      campaignListcommon(eventEmitter, 'Closed', select_params)
      // return () => {
      //   subscription.unsubscribe()
      // }
      return subscription
    }
    let temp = init()
    return () => {
      temp.unsubscribe()
    }
  }, [location, filterData])

  return (
    <Box flex={{grow: 1}}>
      <Header
        pageTitle="Loan Review / Completed"
        userName={`${userProfile.title} ${userProfile.name}`}
        showSavings={true}
        handleRefresh={handleRefresh}
        alrSavings={savings ? savings.alrSavings : ''}
        totalSavings={savings ? savings.totalSavings : ''}
      />
      <Box
        overflow={{vertical: 'auto'}}
        fill
        pad={{bottom: 'medium', left: 'medium', right: 'medium'}}
      >
        <BackNavigation label={'Back'} />

        <Box direction="row-responsive" width="100%" gap="small">
          <Box width="75%">
            <CampaignInfo
              campaignDetails={campaignDetails}
              selcetdcampaign={selcetdcampaign}
              setselcetdcampaign={setselcetdcampaign}
              selectcampaignlist={selectcampaignlist}
              history={history}
              filterData={filterData}
              navigateToParams={navigateToParams}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
          <Box width="25%" background="light-3">
            <RiskRate risk={risk} />
          </Box>
        </Box>

        <TabComponent stats={tabs} history={history} />
        {showmailmodal && (
          <MailTemplate
            setshowmailmodal={setshowmailmodal}
            showmailmodal={showmailmodal}
            event={eventEmitter}
            // route={route}
            loggedinuser={userProfile.email}
            client={applicantsemail}
            clientfirdtname={clientfirdtname}
            sendername={userProfile.name}
            designation={userProfile.designation}
            maillist={maillist}
            templatename={templatename}
            applicantsids={applicantsids}
            templatecontent={tempcontent}
          ></MailTemplate>
        )}

        <Box>
          <ClosedTable
            data={data}
            eventEmitter={eventEmitter}
            loader={loader}
            setMoreDetailLayer={setMoreDetailLayer}
            setMoreLoader={setMoreLoader}
            navigateToParams={navigateToParams}
            statusValue={statusValue}
            setStatusValue={setStatusValue}
            filterData={filterData}
            setshowmailmodal={setshowmailmodal}
            setapplicantsids={setapplicantsids}
            setclientfirdtname={setclientfirdtname}
            setapplicantsemail={setapplicantsemail}
            setToastData={setToastData}
          />
        </Box>
        {moreDetailLayer && (
          <MoreDetails
            setMoreDetailLayer={setMoreDetailLayer}
            moreData={moreData}
            moreLoader={moreLoader}
            moreDataEdit={moreDataEdit}
          />
        )}
      </Box>
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(Closed)
