import React, {useState, useEffect, useCallback} from 'react'
import {withRouter} from 'react-router-dom'
import {Box, Heading} from 'grommet'
import Toast from 'granite/components/Toast'
import PiggyImage from 'granite/components/PiggyImage'
import EventEmitter from 'utils/event-emitter'
import {loginClicked} from 'accounts/controllers/user'
import LoginForm from './components/LoginForm'
import LOGIN_EVENTS from './constants'


const Login = ({dispatch, history}) => {
  const eventEmitter = new EventEmitter()
  const defaultToastData = {open: false, message: ''}
  const [error, setError] = useState(false)

  // Define/Connect state
  const [toastData, setToastData] = useState(defaultToastData)

  // Internal utils methods
  const hideToast = () => setToastData({open: false, message: ''})
  const goToDashboard = useCallback(() => history.push('/dashboard'), [history])

  /**
   * Effect: subscribe and listen to Rx events
   * Cleanup: unsubscribe to Rx observable
   * Deps: eventEmitter, goToDashboard
   */
  useEffect(() => {
    const subscription = eventEmitter.getObservable().subscribe(event => {
      switch (event.type) {
        case LOGIN_EVENTS.LOGIN_SUCCESS:
          setError(false)
          setTimeout(() => {
            hideToast()
            goToDashboard()
          }, 1000)
          break
        case LOGIN_EVENTS.LOGIN_FAILURE:
          setError(true)
          setTimeout(() => {
            hideToast()
          }, 1000)
          break
        default:
          break
      }
    })
    return () => subscription.unsubscribe()
  }, [eventEmitter, goToDashboard])

  return (
    <>
      <Box
        align="center"
        background="light-1"
        flex="grow"
        basis="full"
        justify="center"
        margin="none"
        pad="medium"
      >
        <Box
          width="100%"
          direction="row"
          animation="fadeIn"
          pad="medium"
          gap="xxsmall"
        >
          <PiggyImage basis="1/2" fill={true} justify="center" />
          <Box direction="column" basis="1/4" fill={true}>
            <Heading color="brand" level={1} margin={{bottom: 'small'}}>
              Login
            </Heading>
            <LoginForm
              loginClicked={loginClicked}
              eventEmitter={eventEmitter}
              error={error}
            />
          </Box>
        </Box>
        {toastData.open ? <Toast text={toastData.message} /> : null}
      </Box>
    </>
  )
}

export default withRouter(Login)
