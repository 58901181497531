// Core imports
// import GraniteError from 'utils/granite-error'
// import GraniteAPIClient from 'utils/granite-client'


// Get Broker Details

async function getBrokerDetails(params) {

  // try {
  //   const response = await GraniteAPIClient().get(
  //     '/api_path',
  //   )
  //   return response
  // } catch (error) {
  //   const errorMessage = error.response.data.message
  //   throw new GraniteError(errorMessage)
  // }
}

// ADD BROKER DETAILS 
async function addBrokerDetails(payload) {
 
  // try {
  //   const response = await GraniteAPIClient().get('/api_path')
  //   return response
  // } catch (error) {
  //   const errorMessage = error.response.data.message
  //   throw new GraniteError(errorMessage)
  // }
}

// Update Broker Details 
async function updateBrokerDetails(payload) {
 
  // try {
  //   const response = await GraniteAPIClient().patch(
  //     'api_path',
  //   )
  //   return response
  // } catch (error) {
  //   const errorMessage = error.response.data.message
  //   throw new GraniteError(errorMessage)
  // }
}

// Delete Broker Details 
async function deleteBrokerDetails(params) {

  // try {
  //   const response = await GraniteAPIClient().delete(
  //     'api_path',
  //   )
  //   return response
  // } catch (error) {
  //   const errorMessage = error.response.data.message
  //   throw new GraniteError(errorMessage)
  // }
}

export default {
  getBrokerDetails,
  addBrokerDetails,
  updateBrokerDetails,
  deleteBrokerDetails

}
