import React, {useEffect, useState} from 'react'
import {withRouter} from 'react-router-dom'
import {Box, Image} from 'grommet'
import EventEmitter from 'utils/event-emitter'
import Steps from 'granite/components/Steps'
import Toast from 'granite/components/Toast'
import RequestTokenForm from './components/RequestTokenForm'
import EmailSentSuccess from './components/EmailSentSuccess'
import {requestTokenClickedForgotPassword} from 'accounts/controllers/user'
import FP from './constants'
import FPM from 'messages/forgotPasswordMessage'

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setActiveStep,
  setError,
  history,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case FP.FP_SUCCESS:
        setToastData({
          open: true,
          message: FPM.FPM_SUCCESS,
        })
        setTimeout(() => {
          setActiveStep(1)
          setToastData({open: false, message: ''})
        }, 1000)
        break
      case FP.FP_FAILURE:
        setError(true)
        setToastData({
          open: true,
          message: FPM.FPM_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 2000)
        setError(false)
        break
      default:
        break
    }
  })
  return subsciption
}

const ResetPassword = ({dispatch, history}) => {
  const defaultActiveStep = 0
  const [activeStep, setActiveStep] = useState(defaultActiveStep)
  const defaultToastData = {open: false, message: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [emailOrPhone, setEmailOrPhone] = useState({email: undefined})
  const [email, setEmail] = useState('')
  const [resendemail, setresendemail] = useState()
  const [error, setError] = useState(false)

  const getSteps = () => {
    const onResendToken = () => {
      reqTokenFormOnSubmit(
        {email: emailOrPhone},
        {
          setErrors: () => null,
          setSubmitting: () => null,
        },
      )
    }

    const resendLink = () => {}

    const reqTokenFormOnSubmit = async (values, {setSubmitting, setErrors}) => {
      setErrors({})
      setSubmitting(true)
      try {
        await requestTokenClickedForgotPassword(values)
        setEmailOrPhone(values.email)
        setresendemail(values.email)
        eventEmitter.emit(FP.FP_SUCCESS)
      } catch (e) {
        setEmail(values.email)
        setEmail('Please enter registered email address')
        eventEmitter.emit(FP.FP_FAILURE)
        if (e.errors) setErrors(e.errors)
      }
      setSubmitting(false)
    }

    const submitTokenFormOnSubmit = async ({setSubmitting, setErrors}) => {
      let values = {email: resendemail}
      try {
        await requestTokenClickedForgotPassword(values)
        setEmail(values.email)
        eventEmitter.emit(FP.FP_SUCCESS)
      } catch (e) {
        setEmail(values.email)
        eventEmitter.emit(FP.FP_FAILURE)
        if (e.errors) setErrors(e.errors)
      }
    }

    return [
      {
        content: (
          <RequestTokenForm
            onSubmit={reqTokenFormOnSubmit}
            emailerror={email}
            setEmail={setEmail}
          />
        ),
        icon: <></>,
      },
      {
        content: (
          <EmailSentSuccess
            onSubmit={submitTokenFormOnSubmit}
            onResendToken={onResendToken}
            resendLink={resendLink}
          />
        ),
        icon: <></>,
      },
    ]
  }

  useEffect(
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setActiveStep,
        history,
        setError,
      })
      return () => {
        subscription.unsubscribe()
      }
    },
    [history],
  )

  return (
    <>
      <Box
        direction="row"
        width="100%"
        background=""
        flex="grow"
        justify="center"
        margin="none"
      >
        <Box
          align="center"
          background=""
          flex="grow"
          justify="center"
          margin="none"
          pad="medium"
        >
          <Image
            alt="logo"
            height="400px"
            src={process.env.PUBLIC_URL + '/Images/Piggy.png'}
          />
        </Box>
        <Box
          align="center"
          background=""
          flex="grow"
          justify="center"
          margin="none"
          pad="large"
        >
          <Box
            // border={{ color: 'brand', size: 'medium' }}
            width="100%"
            animation="fadeIn"
            pad="large"
          >
            <Steps
              margin="xsmall"
              steps={getSteps()}
              activeStep={activeStep}
              onChange={() => false}
              navigationVisible={false}
            />
            <Box>
              {toastData.open ? (
                error ? (
                  <Toast text={toastData.message} background="warning" />
                ) : (
                  <Toast text={toastData.message} />
                )
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default withRouter(ResetPassword)
