const CLIENT_LIST_MESSAGES = {
  CLIENT_LIST_GET_SUCCESS: 'Details fetched successfully!',

  CLIENT_LIST_GET_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_LIST_PAGE_SUCCESS: 'Details fetched successfully!',

  CLIENT_LIST_PAGE_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_LIST_SEARCH_SUCCESS: 'Details fetched successfully!',

  CLIENT_LIST_SEARCH_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_LIST_SORT_SUCCESS: 'Details fetched successfully!',

  CLIENT_LIST_SORT_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_LIST_EXPORT_SUCCESS: 'Details fetched successfully!',

  CLIENT_LIST_EXPORT_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENT_LIST_IMPORT_SUCCESS: 'File import successfull.',

  CLIENT_LIST_IMPORT_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",

  CLIENTS_IMPORT: ' Clients > Import',

  IMPORT_RECORDS: ' Import Records',

  IMPORT_LOAN_BOOK_RECORDS: 'Import loanbook to IndigoHub',

  BROWSE_FILE_PATH:
    'Browse the file path (file should be in .csv format only).',

  BROWSE_PLACEHOLDER: 'Choose file...',

  BROWSE_BUTTON_LABEL: 'Browse',

  IMPORT_BUTTON_LABEL: 'Import',

  DOWNLOAD_SAMPLE_FILE: ' Click here to download sample file.',

  RECORDS_CREATED_SUCCESSFULLY: ' Records created successfully',

  RECORDS_CREATION_FAILED: 'Records creation failed',

  RECORDS_UPLOADED_SUCCESSFULLY: 'Records uploaded successfully',

  RECORDS_UPDATION_FAILED: 'Records updation failed',

  NO_DATA_TO_DOWNLOAD: 'There are no incorrect records to download',

  SAMPLE_DOWNLOAD_SUCCESS: 'The sample file has been downloaded successfully!',

  SAMPLE_DOWNLOAD_FAILURE: 'Please try again!',

  FILE_UPLOAD_SUCCESS: 'The file has uploaded successfully!',

  FILE_UPLOAD_FAILURE: 'Failed! Please check the format of the file.',

  GET_CLIENTSLIST_EXPORT_SUCCESS:
    'We have sent you the file on your registered email address!',

  GET_CLIENTSLIST_EXPORT_FAILURE:
    "We're sorry, something went wrong.Please refresh the page and try again!",
}

export default CLIENT_LIST_MESSAGES
