import React from 'react'
import {Provider as ReduxProvider} from 'react-redux'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import {Grommet} from 'grommet'

import theme from 'granite/theme'
import PrivateRoute from 'granite/components/PrivateRoute'
import PublicOnlyRoute from 'granite/components/PublicOnlyRoute'
import PublicRoute from 'granite/components/PublicRoute'

import configureStore from 'utils/ducks/configure-store'
import {loadAuthToken, loadOrganisation} from 'utils/auth-singleton'
import routes from './routes'

// Init Auth Singleton at load
loadAuthToken()
loadOrganisation()

// Create our Redux store.
const store = configureStore()

const App = () => {
  return (
    <ReduxProvider store={store}>
      <Grommet theme={theme} full>
        <Router>
          <Switch>
            {routes.map(route => {
              if (route.private) {
                return <PrivateRoute key={route.path} {...route} />
              } else if (route.publicOnly) {
                return <PublicOnlyRoute key={route.path} {...route} />
              } else {
                return <PublicRoute key={route.path} {...route} />
              }
            })}
          </Switch>
        </Router>
      </Grommet>
    </ReduxProvider>
  )
}
export default App
