import React from 'react'
import {Box, Image, Text, Button} from 'grommet'
import styled from 'styled-components'

const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const MoreSecurityDetail = ({
  sethovervisibility,
  hovervisibility,
  securityinfo,
}) => {
  return (
    <>
      {true && (
        <Box
          style={{
            position: 'absolute',
            left: securityinfo.positionX - 200,
            top: securityinfo.positionY,
            zindex: '50',
            opacity: 1,
          }}
          border={{color: 'brand', size: 'small'}}
          elevation="medium"
          background="white"
          pad="xsmall"
        >
          {false && (
            <MoreDetailsWrapper direction="row" justify="between">
              <MoreDetailsTextWrapper
                color="dark-6"
                size="8px"
                margin={{top: 'small'}}
              >
                More Securities
              </MoreDetailsTextWrapper>

              <Button
                icon={
                  <Image
                    height="small"
                    src={process.env.PUBLIC_URL + '/Images/Cross.png'}
                  />
                }
                onClick={() => sethovervisibility(!hovervisibility)}
              />
            </MoreDetailsWrapper>
          )}

          {securityinfo && securityinfo.props &&
            securityinfo.props.new_security_array.map((value, index) => {
              return (
                <Box width="100%" pad={{top: 'xsmall', bottom: 'xsmall'}}>
                  <Box elevation="none" justify="between" direction="row">
                    <Box direction="row" gap="small">
                      {false && (
                        <Box width="30%">
                          <Text color="dark" weight="bold" size="13px">
                            Security Address :
                          </Text>
                        </Box>
                      )}
                      <Box
                        width="100%"
                        gap={{bottom: 'medium'}}
                        margin={{left: 'small'}}
                      >
                        <Text color="dark" size="13px">
                          {value.complete_addr}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )
            })}
        </Box>
      )}
    </>
  )
}

export default MoreSecurityDetail
