import React from 'react'
import {Box} from 'grommet'
import {useLocation} from 'react-router-dom'
import queryString from 'query-string'

const TabComponent = ({stats, history}) => {
    
    let {pathname, search} = useLocation()
    let queryParam = queryString.parse(search)

    return (
        <Box 
            direction='row' 
            gap='xxsmall' 
            margin={{bottom:'small', top:'15px'}} 
            height='45px' 
            border={{side:'bottom', color:'#0142aa'}}
            style={{
                textAlign:'center', 
                whiteSpace:'nowrap', 
                overflow:'hidden', 
                textOverflow:'ellipsis', 
                fontSize:'14px'
            }}
        >
            <Box 
                pad="xsmall" 
                onClick={()=>history.push({
                    pathname:'/gatherloandetails', 
                    search: queryString.stringify({
                        ...queryParam,
                        status: null,
                        sorting: 'lender'
                  })
                })} 
                background={pathname === '/gatherloandetails' ? '#0142aa' : '#d4e4fe'}
                border={{color: pathname === '/gatherloandetails' ? '#0142aa' : '#d4e4fe'}} 
                style={{width:'20%', paddingTop:'10px', color: pathname === '/gatherloandetails' ? 'white' : 'black'}}
            >
                Gather Loan Details ({stats.new})
            </Box>
            <Box 
                pad="xsmall" 
                onClick={()=>history.push({
                    pathname:'/notifyclientofreview', 
                    search: queryString.stringify({
                        ...queryParam,
                        status: 'Contacting',
                        sorting: 'name'
                  })
                })} 
                background={pathname === '/notifyclientofreview' ? '#0142aa' : '#d4e4fe'}
                border={{color: pathname === '/notifyclientofreview' ? '#0142aa' : '#d4e4fe'}} 
                style={{width:'20%', paddingTop:'10px', color: pathname === '/notifyclientofreview' ? 'white' : 'black'}}
            >
                Notify Client Of Review ({stats.contacting})
            </Box>
            <Box 
                pad="xsmall" 
                onClick={()=>history.push({
                    pathname:'/negotiatepricing', 
                    search: queryString.stringify({
                        ...queryParam,
                        status: 'All_Pricing',
                        sorting: 'lender'
                  })
                })} 
                background={pathname === '/negotiatepricing' ? '#0142aa' : '#d4e4fe'}
                border={{color: pathname === '/negotiatepricing' ? '#0142aa' : '#d4e4fe'}} 
                style={{width:'20%', paddingTop:'10px', color: pathname === '/negotiatepricing' ? 'white' : 'black'}}
            >
                Negotiate Pricing ({stats.Negotiate_Pricing})
            </Box>
            <Box 
                pad="xsmall" 
                onClick={()=>history.push({
                    pathname:'/contactclientofresult', 
                    search: queryString.stringify({
                        ...queryParam,
                        status: 'Closed',
                        sorting: 'name'
                  })
                })} 
                background={pathname === '/contactclientofresult' ? '#0142aa' : '#d4e4fe'} 
                border={{color: pathname === '/contactclientofresult' ? '#0142aa' : '#d4e4fe'}} 
                style={{width:'20%', paddingTop:'10px', color: pathname === '/contactclientofresult' ? 'white' : 'black'}}
            >
                Contact Client Of Result ({stats.Contact_Client_Of_Result})
            </Box>
            <Box 
                pad="xsmall" 
                onClick={()=>history.push({
                    pathname:'/completed', 
                    search: queryString.stringify({
                        ...queryParam,
                        status: 'All_Completed',
                        sorting: 'lender'
                  })
                })} 
                background={pathname === '/completed' ? '#0142aa' : '#d4e4fe'} 
                border={{color: pathname === '/completed' ? '#0142aa' : '#d4e4fe'}} 
                style={{width:'20%', paddingTop:'10px', color: pathname === '/completed' ? 'white' : 'black'}}
            >
                Completed ({stats.completed})
            </Box>
        </Box>
    )
}

export default TabComponent