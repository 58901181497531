import React, {useState, useEffect} from 'react'
import {Box, Image, Text, Layer, Button, TextInput} from 'grommet'
import styled from 'styled-components'
import {getRoles, fetchUsers, getUserDetails} from 'accounts/controllers/user'
import CommonForm from './components/commonform'
import UserTable from './components/table'
import EventEmitter from 'utils/event-emitter'
import Toast from '../../granite/components/Toast'
import ADD_USER_MESSAGES from 'messages/addUser'
import ADD_USER_EVENTS from './constants'
import Pagination from 'granite/components/Pagination'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'
import NewHeader from 'granite/components/HeaderWithDropDown'
import LoaderUI from 'granite/components/NumLoader'
import {useHistory, useLocation} from 'react-router-dom'
import queryString from 'query-string'

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`

const TextInputWrapper = styled(TextInput)`

  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
`

const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius: 0px 4px 4px 0px;
  padding-left: 14px !important;
  top: 115px;
  left: 237px;
  width: 500px;
  height: 38px;
`

const SearchButtonStyle = styled(Button)`
  height: 38px;
  width: 110px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 0px 4px 4px 0px;
  padding: 0px !important;
  margin: 0px !important;
`

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setData,
  setUserRole,
  setCount,
  setTotalRows,
  setUserLayer,
  setLoader,
  setSize,
  setEditLayer,
  customisedMessage,
  filterData,
}) {
  const observable = eventEmitter.getObservable()
  const subsciption = observable.subscribe(event => {
    switch (event.type) {
      case ADD_USER_EVENTS.GET_USERS_SUCCESS:
        setUserLayer(false)
        setEditLayer(false)
        setData(event.data.employee)
        setTotalRows(event.data.rows)
        setCount(Math.ceil(event.data.rows / 10))
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 1000)
        break

      case ADD_USER_EVENTS.GET_USERS_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.GET_USERS_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case ADD_USER_EVENTS.GET_USERS_ROLES_SUCCESS:
        event.data ? setUserRole(event.data) : setUserRole([])
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break
      case ADD_USER_EVENTS.GET_USERS_ROLES_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.GET_USERS_ROLES_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case ADD_USER_EVENTS.ACTIVE_SUCCESS:
        const obj_1 = {
          params: filterData,
        }
        fetchUsers(eventEmitter, obj_1)
        getRoles(eventEmitter)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        setToastData({
          open: true,
          message: customisedMessage,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 3000)

        break
      case ADD_USER_EVENTS.ACTIVE_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.GET_USERS_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break
      case ADD_USER_EVENTS.LOGGEINUSER_ACTIVE_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.LOGGEINUSER_ACTIVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      // COMMON USER FORM EVENTS

      case ADD_USER_EVENTS.ADD_CLICK_SUCCESS:
        setToastData({
          open: true,
          message: ADD_USER_MESSAGES.ADD_CLICK_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case ADD_USER_EVENTS.ADD_CLICK_FAILURE:
        setToastData({
          open: true,
          background: 'warning',
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.ADD_CLICK_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case ADD_USER_EVENTS.EDIT_CLICK_SUCCESS:
        setToastData({
          open: true,
          message: ADD_USER_MESSAGES.EDIT_CLICK_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case ADD_USER_EVENTS.EDIT_CLICK_FAILURE:
        setToastData({
          open: true,
          background: 'warning',
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.EDIT_CLICK_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case ADD_USER_EVENTS.ADD_SUCCESS:
        const obj_2 = {
          params: filterData,
        }
        fetchUsers(eventEmitter, obj_2)
        setToastData({
          open: true,
          message: ADD_USER_MESSAGES.ADD_CLICK_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case ADD_USER_EVENTS.ADD_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.EDIT_CLICK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case ADD_USER_EVENTS.EDIT_SUCCESS:
        const obj_3 = {
          params: filterData,
        }
        fetchUsers(eventEmitter, obj_3)
        setToastData({
          open: true,
          message: ADD_USER_MESSAGES.EDIT_CLICK_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      case ADD_USER_EVENTS.EDIT_FAILURE:
        setToastData({
          open: true,
          background: 'warning',
          message: event.data
            ? event.data
            : ADD_USER_MESSAGES.EDIT_CLICK_FAILURE,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      default:
        break
    }
  })
  return subsciption
}

const AddUser = ({userProfile, permissions}) => {
  let location = useLocation()
  const history = useHistory()
  let defaultFilterData = {
    page: 1,
    page_size: 20,
    ordering: 'name',
  }
  const [filterData, setFilterData] = useState(defaultFilterData)
  const {search} = location

  let queryParam = queryString.parse(search)

  const [searchvalue, setSearch] = useState(
    queryParam.search ? queryParam.search : '',
  )

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })

  const [userLayer, setUserLayer] = useState(false)
  const [editLayer, setEditLayer] = useState(false)
  const [userRole, setUserRole] = useState([])
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [editPayload, setEditPayload] = useState({})
  let [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [rows, setTotalRows] = useState(10)
  const [count, setCount] = useState(10)
  const [value, setValue] = useState('')
  const [loader, setLoader] = useState(true)
  const [customisedMessage, setcustomisedMessage] = useState('')

  let [formData, setformData] = useState({
    name: '',
    email: '',
    title: '',
    role: [],
  })

  const handleAddUser = () => {
    setformData({
      name: '',
      email: '',
      title: '',
      role: [],
    })
  }

  const handleEditButton = pk => {
    getUserDetails(eventEmitter, pk)
  }

  const handleRefresh = () => {
    if (!loader) {
      setLoader(true)
      navigateToParams({page: 1, page_size: 20, ordering: 'name', search: null})
    }
  }

  const role =
    userRole.length > 0 ? userRole.map(item => item.pk) : handleRefresh

  const handlePagination = (eventEmitter, val) => {
    setLoader(true)
    let params = {}
    params['page'] = val.page
    params['page_size'] = val.size
    navigateToParams({page: val.page, page_size: val.size, ordering: 'name'})
  }

  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setData,
        setUserRole,
        setCount,
        setTotalRows,
        setUserLayer,
        setEditLayer,
        setLoader,
        setSize,
        customisedMessage,
        filterData,
      })
      const obj = {
        params: filterData,
      }
      fetchUsers(eventEmitter, obj)
      getRoles(eventEmitter)
      // return () => {
      //   subscription.unsubscribe()
      // }
      return subscription
    }
    // init()
    let subscription = init()
    return () => {
      subscription.unsubscribe()
    }
  }, [location, customisedMessage, filterData])

  const selectedUser = (pk, user, name, designation, role) => {
    let data = []
    role.forEach(element => {
      data.push(element.pk)
    })

    const edit_payload = {
      pk: pk,
      user: user,
      name: name,
      designation: designation,
      role: data,
    }
    setEditPayload(edit_payload)
    setformData({
      name: name,
      email: user.email,
      designation: designation,
      role: data,
    })
  }

  const handleSearch = () => {
    setLoader(true)
    navigateToParams({search: searchvalue, page: 1})
  }

  const handleSearchCancel = () => {
    setLoader(true)
    setSearch('')
    navigateToParams({page: 1, page_size: 20, ordering: 'name', search: null})
  }

  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'User Roles'}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
      />
      <Box overflow={{vertical: 'auto'}} fill>
        <Box>
          <Box>
            <Box
              pad={{
                top: 'none',
                bottom: 'none',
                left: 'medium',
                right: 'medium',
              }}
            >
              <Box direction="row">
                <Box width="70%" alignSelf="start" gap="medium" direction="row">
                  <SearchBoxWrapper
                    align="end"
                    width="380px"
                    direction="row"
                    height="36px"
                    background="light-1"
                  >
                    <TextInputWrapper
                      placeholder="Name, Role, Email Search"
                      value={searchvalue}
                      onChange={event => {
                        setSearch(event.target.value)
                      }}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          handleSearch()
                        }
                      }}
                    />
                    <Button
                      alignSelf="center"
                      icon={
                        <Image
                          src={
                            process.env.PUBLIC_URL + '/img/search_cancel.png'
                          }
                          fit="contain"
                        />
                      }
                      onClick={handleSearchCancel}
                    />

                    <SearchButtonStyle
                      onClick={handleSearch}
                      type="submit"
                      label={'Search'}
                      primary
                    />
                  </SearchBoxWrapper>
                </Box>

                <Box width="30%" align="end">
                  <ButtonStyle
                    primary
                    label="Add"
                    onClick={() => {
                      setUserLayer(!userLayer)
                      handleAddUser()
                    }}
                  />
                </Box>
              </Box>
              {userLayer && (
                <Layer
                  onEsc={() => setUserLayer(!userLayer)}
                  onClickOutside={() => setUserLayer(!userLayer)}
                >
                  <Box width="358px" gap="small">
                    <Box>
                      <CommonForm
                        pk={role[0]}
                        roleList={userRole}
                        data={formData}
                        event={eventEmitter}
                        setUserLayer={setUserLayer}
                        userLayer={userLayer}
                        setToastData={setToastData}
                        editLayer={editLayer}
                        add={'add'}
                      />
                    </Box>
                  </Box>
                </Layer>
              )}
              {editLayer && (
                <Layer
                  onEsc={() => setEditLayer(!editLayer)}
                  onClickOutside={() => setEditLayer(!editLayer)}
                >
                  <Box width="358px" gap="small">
                    <Box>
                      <CommonForm
                        editPayload={editPayload}
                        roleList={userRole}
                        data={formData}
                        event={eventEmitter}
                        setEditLayer={setEditLayer}
                        setToastData={setToastData}
                        editLayer={editLayer}
                        edit={'edit'}
                      />
                    </Box>
                  </Box>
                </Layer>
              )}
              {loader ? (
                <Box align="center" justify="center" fill>
                  <LoaderUI />
                </Box>
              ) : (
                <Box margin={{top: 'medium'}}>
                  <UserTable
                    tableData={data}
                    handleEditButton={handleEditButton}
                    selectedUser={selectedUser}
                    setEditLayer={setEditLayer}
                    editLayer={editLayer}
                    event={eventEmitter}
                    userProfile={userProfile}
                    setLoader={setLoader}
                    setcustomisedMessage={setcustomisedMessage}
                    navigateToParams={navigateToParams}
                    count={count}
                    value={value}
                  ></UserTable>
                  {data.length > 0 ? (
                    <Box direction="row" width="100%" margin={{top: 'medium'}}>
                      <Box width="50%" alignContent="end" align="start">
                        {/* <Text color="dark-6">
                          Page {page} out of {Math.ceil(rows / size)}
                        </Text> */}
                        <Text color="dark-6">
                          {rows < size ? rows + ' out of ' + rows : data.length + ' out of ' + rows}
                        </Text>
                      </Box>
                      <Box width="50%" alignContent="end" align="end">
                        <Pagination
                          onPageChange={val => {
                            async function Sample() {
                              setValue('')
                              setPage(val.page)
                              setSize(val.size)
                              await handlePagination(eventEmitter, val)
                            }
                            setPage(val)
                            Sample()
                          }}
                          showSizeChanger
                          pageData={{
                            page: page,
                            size: filterData.page_size,
                            total: rows,
                          }}
                          select={true}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box></Box>
                  )}
                </Box>
              )}{' '}
            </Box>
          </Box>
        </Box>

        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
  permissions: UserDucks.permissions(state),
})

export default connect(mapStateToProps)(AddUser)
