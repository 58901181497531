//Library imports
import React, {useState} from 'react'
import {
  Box,
  Button,
  Text,
  TextInput,
  Select,
  ThemeContext,
  Image,
} from 'grommet'

import 'react-phone-input-2/lib/style.css'
import {Formik} from 'formik'
import * as Yup from 'yup'
import styled from 'styled-components'

//Core imports
import FormField from 'granite/components/FormField'

const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`

const SelectWrapper = styled(Select)`
  // padding-left: 14px !important;
  height: 29px;
  // top: 279px;
  // left: 1117px;
  font-size: 12px;
  round:'none'
  border-bottom: none;
  opacity:0;
  
 
`

const SubjectWrapper = styled(Box)`
  position: absolute;
`
const MailForm = ({
  onSubmit,
  setshowmailmodal,
  loggedinuser,
  client,
  clientfirdtname,
  sendername,
  maillist,
  showmailmodal,
  tempcontent,
  designation,
}) => {
  const [ccvisible, setccvisible] = useState(false)
  const [bccvisible, setbccvisible] = useState(false)
  const [subjectmain, setsubjectmain] = useState('')
  const [customisedcontent, setcustomisedcontent] = useState('')
  let myRef = React.createRef()
  const ButtonStyle = styled(Button)`
    height: 36px;
    width: 110px;
  `

  const mailWrapper = styled(Box)`
    border-bottom: 1px solid black;
    min-height: 150px;
  `
  const CustomData = styled(Box)`
    max-heigh: '300px';
  `

  const MoreDetailsWrapper = styled(Box)`
    border-bottom: 1px solid #707070;
  `
  const TextInput1 = styled(TextInput)`
    borderradius: 10;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: none;
    padding-bottom: 6px;
    padding-top: 0px;
    font-weight: 400;
    font-size: 12px;
    width:100%
    color: #000000;
  `

  const bodyValue = {
    select: {
      control: {
        extend: {
          borderBottom: 'none',
        },
      },
    },
  }

  const validationSchema = Yup.object().shape({
    from: Yup.string()
      .email('Invalid email')
      .required('From Required'),
    // to: Yup.string()
    //   .email('Invalid email')
    //   .required('To Required'),
    subject: Yup.string().required('Subject Required'),
  })

  const handleCancel = () => {
    setshowmailmodal(false)
  }

  const handleTypeChange = (option, setFieldValue) => {
    if (option === 'Notify to Review') {
      setsubjectmain('Indigo Finance – Notify to Review')
      let temp = tempcontent['Notify to Review']
      // temp = temp.replace('{applicant_name}', clientfirdtname)
      temp = temp.replace('{user_name}', sendername)
      temp = temp.replace('{designation}', designation)
      setcustomisedcontent(temp)
      setFieldValue('content', temp)
    } else if (option === 'No Action Required ALR') {
      setsubjectmain('Indigo Finance – No Action Required')
    } else if (option === 'Rate Reduction ALR Email') {
      setsubjectmain('Indigo Finance – Rate Reduction')
    } else if (option === 'Interest / Fixed Expiry Template') {
      setsubjectmain('Indigo Finance – Your Review is Due')
    } else if (option === 'Missed Call Template') {
      setsubjectmain('Indigo Finance – Your Review is Due')
    } else if (option === 'Actions to be completed ALR Email') {
      setsubjectmain('Indigo Finance – Your Review Summary')
    } else if (option === 'Recently Dealt with Indigo ALR Email') {
      setsubjectmain('Indigo Finance – Your Loan Review')
    }
    setFieldValue('subject', option)
  }

  const hnadleccClick = () => {
    setccvisible(!ccvisible)
  }

  const hnadlebccClick = () => {
    setbccvisible(!bccvisible)
  }

  return (
    <Box>
      <Formik
        initialValues={{
          from: loggedinuser,
          to: client,
          subject: '',
          cc: '',
          bcc: '',
          content: customisedcontent,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <MoreDetailsWrapper
              direction="row"
              justify="between"
              background="brand"
            >
              <MoreDetailsTextWrapper
                color="#FFFFFF"
                size="16px"
                margin={{left: '20px', top: '15px', bottom: '15px'}}
              >
                New Mail
              </MoreDetailsTextWrapper>

              <Button
                margin={{right: 'small', bottom: 'small', top: 'small'}}
                icon={
                  <Image src={process.env.PUBLIC_URL + '/img/whitecross.png'} />
                }
                onClick={() => setshowmailmodal(!showmailmodal)}
              />
            </MoreDetailsWrapper>

            <Box>
              {ccvisible && false && (
                <Box direction="row">
                  <MoreDetailsWrapper
                    direction="row"
                    width="100%"
                    // border={{color: 'brand', size: 'small'}}
                    gap="small"
                  >
                    <Box width="8%" pad={{left: 'medium'}}>
                      <FormField
                        name="cc"
                        label="CC:"
                        required
                        error={touched.cc && errors.cc}
                      />
                    </Box>
                    <Box
                      direction="row"
                      gap="large"
                      width="100%"
                      // border={{color: 'red', size: 'small'}}
                      align="end"
                      pad={{bottom: 'small'}}
                    >
                      <TextInput1
                        pad="none"
                        type="cc"
                        name="cc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder=""
                        size="large"
                        value={values.cc}
                        disabled={false}
                      />
                    </Box>
                  </MoreDetailsWrapper>
                </Box>
              )}
              {bccvisible && false && (
                <Box direction="row">
                  <MoreDetailsWrapper
                    direction="row"
                    width="100%"
                    // border={{color: 'brand', size: 'small'}}
                    gap="small"
                  >
                    <Box width="8%" pad={{left: 'medium'}}>
                      <FormField
                        name="bcc"
                        label="Bcc:"
                        required
                        error={touched.bcc && errors.bcc}
                      />
                    </Box>
                    <Box
                      direction="row"
                      gap="large"
                      width="100%"
                      // border={{color: 'red', size: 'small'}}
                      align="end"
                      pad={{bottom: 'small'}}
                    >
                      <TextInput1
                        pad="none"
                        type="bcc"
                        name="bcc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        color="dark"
                        placeholder=""
                        size="large"
                        value={values.bcc}
                        disabled={false}
                      />
                    </Box>
                  </MoreDetailsWrapper>
                </Box>
              )}
              <Box
                width="100%"
                direction="row"
                // border={{color: 'brand', size: 'small'}}
              >
                <MoreDetailsWrapper
                  width="100%"
                  direction="row"
                  gap="small"
                  pad={{left: 'small'}}
                >
                  <Box width="3%">
                    <FormField
                      name="to"
                      label="To"
                      required
                      error={touched.to && errors.email}
                    />
                  </Box>

                  <Box width="100%" pad={{bottom: 'none', top: 'xsmall'}}>
                    <TextInput1
                      pad="none"
                      type="to"
                      name="to"
                      // onChange={handleChange}
                      onBlur={handleBlur}
                      color="white"
                      placeholder=""
                      size="large"
                      value={values.to}
                      disabled={false}
                    />
                  </Box>
                </MoreDetailsWrapper>
              </Box>
            </Box>

            <Box direction="row">
              <MoreDetailsWrapper direction="row" width="100%" gap="small">
                <Box width="8%" pad={{left: 'small'}}>
                  <FormField
                    name="from"
                    label="From:"
                    required
                    error={touched.from && errors.from}
                  />
                </Box>
                <Box
                  direction="row"
                  gap="large"
                  width="100%"
                  // border={{color: 'red', size: 'small'}}
                  align="end"
                  pad={{bottom: 'xsmall'}}
                >
                  <TextInput1
                    pad="none"
                    type="from"
                    name="from"
                    // onChange={handleChange}
                    onBlur={handleBlur}
                    color="dark"
                    placeholder=""
                    size="large"
                    value={values.from}
                    disabled={false}
                  />
                  {false && (
                    <Box direction="row" gap="small" pad={{right: 'small'}}>
                      <Box onClick={() => hnadleccClick()}>
                        <Text>{'CC'}</Text>
                      </Box>
                      <Box
                        onClick={() => hnadlebccClick()}
                        pad={{right: 'small'}}
                      >
                        <Text>{'BCC'}</Text>
                      </Box>
                    </Box>
                  )}{' '}
                </Box>
              </MoreDetailsWrapper>
            </Box>

            <Box
              direction="row"
              // border={{color: 'green', size: 'small'}}
            >
              <MoreDetailsWrapper
                width="100%"
                direction="row"
                // border={{color: 'brand', size: 'small'}}
              >
                <Box width="9%" pad={{left: 'small', right: 'none'}}>
                  <FormField
                    name="subject"
                    label="Subject"
                    required
                    error={touched.subject && errors.subject}
                  />
                </Box>

                <Box
                  direction="row"
                  width="100%"
                  pad={{top: 'small', left: 'small'}}
                >
                  <Text size="xsmall" color="dark">
                    {subjectmain}
                  </Text>
                  <SubjectWrapper
                    // border={{color: 'green', size: 'medium'}}
                    pad={{bottom: 'small', right: 'small'}}
                    width="90%"
                    // flex = "grow"
                  >
                    <Box width="100%">
                      <ThemeContext.Extend value={bodyValue}>
                        <SelectWrapper
                          options={maillist}
                          value={values.subject}
                          placeholder={''}
                          onChange={({option}) =>
                            handleTypeChange(option, setFieldValue)
                          }
                        />
                      </ThemeContext.Extend>
                    </Box>
                  </SubjectWrapper>
                </Box>
              </MoreDetailsWrapper>
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>
            <mailWrapper pad={{left: 'small'}}>
              <Box alignContent = 'center'>
             {values.subject && <Text alignSelf="center" size ='medium' color='red'>Please dont edit "applicant_name"</Text>}
              </Box>

              <CustomData size="medium" margin="small" overflow="auto">
                <div
                  refs={myRef}
                  onInput={e => {
                    setFieldValue('content', e.target.innerHTML)
                  }}
                  style={{
                    fontSize: '14px',
                    outline: 0,
                    overflow: 'auto',
                    height: '200px',
                    width: '100%',
                  }}
                  contentEditable="true"
                  dangerouslySetInnerHTML={{__html: customisedcontent}}
                ></div>
              </CustomData>
            </mailWrapper>
            <Box
              direction="row"
              gap="small"
              pad={{right: 'small', top: 'small'}}
              justify="end"
            >
              <ButtonStyle
                alignSelf="end"
                label={'Cancel'}
                disabled={isSubmitting}
                primary
                onClick={handleCancel}
              />
              <ButtonStyle
                type="submit"
                alignSelf="end"
                label={isSubmitting ? 'Sending...' : 'Send'}
                disabled={isSubmitting}
                primary
              />
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )
}
export default MailForm
