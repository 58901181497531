class NewLoanDetail {
  constructor({pk, applicant, lender, loan_package, product, status, loan}) {

    const GenerateString = data => {
      let temp_arr = []
      data.forEach(element => {
        let state = element.state ? statesAbbr[element.state] : ''
        let addr =
          element.address_line_1 +
          ' ' +
          element.address_line_2 +
          ' ' +
          element.city +
          ' ' +
          state +
          ' ' +
          element.postcode
        temp_arr.push(addr)
      })
      return temp_arr.join('\r\n\n')
    }

    const statesAbbr = {
      'New South Wales': 'NSW',
      'Victoria': 'VIC',
      'Queensland': 'QLD',
      'Tasmania': 'TAS',
      'South Australia': 'SA',
      'Western Australia': 'WA',
      'Northern Territory': 'NT',
      'Australian Capital Territory': 'ACT',
      'NSW': 'New South Wales',
      'VIC': 'Victoria',
      'QLD': 'Queensland',
      'TAS': 'Tasmania',
      'SA': 'South Australia',
      'WA': 'Western Australia',
      'NT': 'Northern Territory',
      'ACT': 'Australian Capital Territory',
    }

    this.pk = pk
    this.applicant_pk = loan.applicant ? loan.applicant.pk : ''
    this.name = loan.applicant ? loan.applicant.name : ''
    this.first_name = loan.applicant ? loan.applicant.first_name : ''
    this.last_name = loan.applicant ? loan.applicant.last_name : ''
    this.applicant_email = loan.applicant ? loan.applicant.email : ''
    this.applicant_phone = loan.applicant ? loan.applicant.phone : ''
    this.lender_pk = loan.lender.pk
    this.lender = loan.lender.name
    this.lender_bsb_code = loan.lender.bsb_code
    this.loan_limit = loan.loan_limit
    this.balance = loan.balance
    this.package = loan.loan_package
    this.product_name = loan.product.name
    this.product_pk = loan.product.pk
    this.product_expiry = loan.product.expiry
    this.settlement_date = loan.settlement_date
    this.status = loan.status
    this.rate = loan.rate
    this.io_expiry = loan.io_expiry
    this.loan_pk = loan.pk
    this.fixed_expiry = loan.fixed_expiry
    this.account_number = `${loan.account_number}`
    this.security = GenerateString(loan.security)
    this.email = loan.applicant ? loan.applicant.email : ''
  }
}

export default NewLoanDetail
