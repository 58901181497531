// CORE Imports
import React from 'react'
import {Box, Button, Text, TextArea} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import styled  from 'styled-components'

const NotesForm = ({onSubmit}) => {
  const AddNotes = styled(TextArea)`
    top: 163px;
    left: 428px;
    width: 507px;
    height: 241px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
  `

  const validationSchema = Yup.object().shape({
    notes: Yup.string(),
    // .min(0, '')
    // .required(''),
  })

  return (
    <>
      <Formik
        initialValues={{
          notes: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box direction="row">
              <Box width="100%" pad="small">
                <FormField
                  name="notes"
                  label=""
                  // required
                  // error={touched.notes && errors.notes}
                >
                  <Box>
                    <AddNotes
                      type="notes"
                      name="notes"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="dark"
                      placeholder="Write here...."
                      size="small"
                      value={values.notes}
                      disabled={false}
                    />
                  </Box>
                </FormField>
              </Box>
            </Box>
            <Text as="div" color="status-critical">
              {errors.non_field}
            </Text>

            <Box
              fill="horizontal"
              direction="row"
              elevation="none"
              justify="between"
            >
              <Box></Box>
              <Button
                type="submit"
                alignSelf="end"
                label={isSubmitting ? 'Saving...' : 'Save'}
                disabled={isSubmitting}
                primary
              />
            </Box>
          </form>
        )}
      </Formik>
    </>
  )
}
export default NotesForm
