class OutComeInnerList {
  constructor({
    pk,
    applicant,
    lender,
    security,
    term,
    status,
    loan_limit,
    balance,
    rate,
    reply_choice,
    rate_type_choice,
    out_come,
    is_active,
    modified_date,
    settlement_date,
    purpose,
    new_rate,
    account_number,
    repayment_type,
    loan_status,
    savings,
    loan,
  }) {
    this.newpk = pk
    this.pk = loan.pk
    this.applicant_pk = loan.applicant.pk
    this.applicant_name = loan.applicant.name
    this.first_name = loan.applicant.first_name
    this.last_name = loan.applicant.last_name
    this.applicant_email = loan.applicant.email
    this.applicant_phone = loan.applicant.phone

    this.lender_pk = loan.lender.pk
    this.lender_name = loan.lender.name
    this.lender_bsb_code = loan.lender.bsb_code

    this.status = status
    this.term = loan.term
    this.loan_limit = loan.loan_limit
    this.balance = loan.balance
    this.rate = loan.rate
    this.reply_choice = loan.reply_choice
    this.rate_type_choice = loan.rate_type_choice

    this.out_come = out_come
    this.new_rate = loan.new_rate
    this.is_active = loan.is_active
    this.modified_date = modified_date
    this.settlement_date = loan.settlement_date
    this.purpose = loan.purpose

    this.account_no = loan.account_number
    this.repayment_type = repayment_type
    this.savings = savings
    if (loan.out_come === '1') this.out_come = '--No Changes--'
    else if (loan.out_come === '2')
      this.out_come = '$!Money saved, no further action'
    else if (loan.out_come === '3')
      this.out_come = '$!Money saved, Further action required'
    else if (loan.out_come === '4')
      this.out_come = '!No Money Saved, further action'

    if (loan.loan_status === '1') this.loan_status = 'Applying New Rate'
    else if (loan.loan_status === '2') this.loan_status = 'Retention'
    else if (loan.loan_status === '3') this.loan_status = 'Refinancing'

    if (loan.applicant.applicant_type === 'B') this.applicant_type = 'Borrower'
    else if (loan.applicant.applicant_type === 'G')
      this.applicant_type = 'Gurantor'
    else if (loan.applicant.applicant_type === 'TT')
      this.applicant_type = 'Trustee'
    else if (loan.applicant.applicant_type === 'SG')
      this.applicant_type = 'Signatory'
    else if (loan.applicant.applicant_type === 'SF')
      this.applicant_type = 'Superfund'
  }
}
export default OutComeInnerList
