import React from 'react'
import {Box, Button, Text, Select, TextInput, Layer, Image} from 'grommet'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FormField from 'granite/components/FormField'
import {
  addSecurity,
  updateSecurity,
  deleteSecurity,
} from 'clientloandetail/controllers/loan'
import SECURITY_MESSAGES from 'messages/Security_form'
import styled from 'styled-components'

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;s
`
const TextInput1 = styled(TextInput)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  opacity: 1;
  top: 115px;
  left: 237px;
  width: 100%;
  height: 30.8px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`

const BoxTextField = styled(Box)`
  border-top: 1px solid #b1b8c0;
  border-bottom: 1px solid #b1b8c0;
  border-right: 1px solid #b1b8c0;
`
const UserProfileWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const TextWrapper = styled(Text)`
  font-weight: 500;
`
const LayerStyle = styled(Layer)`
  width: 50%;
  height: 55%;
`

const LayerStyleDelete = styled(Layer)`
  width: 35%;
`
const MoreDetailsWrapper = styled(Box)`
  border-bottom: 2px solid #37236a;
`
const MoreDetailsTextWrapper = styled(Text)`
  font-weight: 700;
`
const StyledSelect = styled(Select)`
  borderradius: 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  padding-top: 0px;
  font-weight: 400;
  font-size: 12px;
  color: #212121;
  opacity: 1;
  top: 115px;
  left: 237px;
  width: 100%;
  height: 30.8px;
  padding-left: 4px !important;
  padding-right: 8px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
`

const SecurityAddressForm = ({
  event,
  route,
  setToastData,
  setToastWarning,
  setsecurityAddressFormVisibility,
  securityAddressFormVisibility,
  formdata,
}) => {
  const validationSchema = Yup.object().shape({
    postcode: Yup.number('Must be valid number')
      .typeError('Postal code must be valid number')
      .required('Postcode required'),
  })

  const adduserRole = async (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    await addSecurity(event, values)
    setSubmitting(false)
  }

  const editUserRole = (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    updateSecurity(event, values)
    setSubmitting(false)
  }

  const handledeleteSecurity = securityAddressFormVisibility => {
    deleteSecurity(event, securityAddressFormVisibility.formdata.security_id, route.params.clientid, route.params.id)
  }

  const handleCanceldeleteSecurity = () => {
    setsecurityAddressFormVisibility(!securityAddressFormVisibility.show)
  }

  return (
    <>
      {(securityAddressFormVisibility.type === 'Add' ||
        securityAddressFormVisibility.type === 'Edit') && (
        <LayerStyle
          position="center"
          onEsc={() =>
            setsecurityAddressFormVisibility(
              !securityAddressFormVisibility.show,
            )
          }
          onClickOutside={() =>
            setsecurityAddressFormVisibility(
              !securityAddressFormVisibility.show,
            )
          }
        >
          <Box overflow="auto">
            <Formik
              initialValues={{
                address_line_1:
                  securityAddressFormVisibility.formdata.addr_line_1,
                address_line_2:
                  securityAddressFormVisibility.formdata.addr_line_2,
                city: securityAddressFormVisibility.formdata.city,
                state: securityAddressFormVisibility.formdata.state,
                postcode: securityAddressFormVisibility.formdata.postcode,
                country: securityAddressFormVisibility.formdata.country,
                loan: route.params.id,
                client_id: route.params.clientid,
                security_id: securityAddressFormVisibility.formdata.security_id,
              }}
              validationSchema={validationSchema}
              onSubmit={
                securityAddressFormVisibility.type === 'Add'
                  ? adduserRole
                  : editUserRole
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
                setFieldValue,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Box>
                      <UserProfileWrapper direction="row" justify="between">
                        <TextWrapper
                          color="dark-4"
                          size="16px"
                          margin={{left: '20px', top: 'medium', bottom: '15px'}}
                        >
                          {securityAddressFormVisibility.type === 'Add'
                            ? SECURITY_MESSAGES.ADD_SECURITY_POP_UP_HEADING
                            : SECURITY_MESSAGES.EDIT_SECURITY_POP_UP_HEADING}
                        </TextWrapper>

                        <Button
                          margin={{
                            top: 'small',
                            bottom: '15px',
                            right: 'small',
                          }}
                          icon={
                            <Image
                              src={process.env.PUBLIC_URL + '/Images/Cross.png'}
                            />
                          }
                          onClick={() => {
                            setsecurityAddressFormVisibility(
                              (securityAddressFormVisibility.show = false),
                            )
                          }}
                        />
                      </UserProfileWrapper>
                    </Box>

                    <Box margin={{left: 'medium', right: 'medium'}}>
                      <Box margin={{top: 'small'}}>
                        <Box direction="row">
                          <Box width="25%">
                            <FormField
                              name="address_line_1"
                              label={SECURITY_MESSAGES.ADDRESS_LINE_1}
                              error={
                                touched.address_line_1 && errors.address_line_1
                              }
                              backgroundLabel="dark-9"
                            />
                          </Box>
                          <Box width="75%">
                            <BoxTextField margin={{bottom: 'small'}}>
                              {' '}
                              <TextInput1
                                type="address_line_1"
                                name="address_line_1"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                color="dark"
                                placeholder={SECURITY_MESSAGES.ADDRESS_LINE_1}
                                value={values.address_line_1}
                              />
                            </BoxTextField>
                          </Box>
                        </Box>
                        <Box direction="row">
                          <Box width="25%">
                            <FormField
                              name="address_line_2"
                              label={SECURITY_MESSAGES.ADDRESS_LINE_2}
                              error={
                                touched.address_line_2 && errors.address_line_2
                              }
                              backgroundLabel="dark-9"
                            />
                          </Box>
                          <Box width="75%">
                            <BoxTextField margin={{bottom: 'small'}}>
                              {' '}
                              <TextInput1
                                type="address_line_2"
                                name="address_line_2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                color="dark"
                                placeholder={SECURITY_MESSAGES.ADDRESS_LINE_2}
                                value={values.address_line_2}
                              />
                            </BoxTextField>
                          </Box>
                        </Box>

                        <Box direction="row">
                          <Box width="25%">
                            <FormField
                              name="city"
                              label={SECURITY_MESSAGES.CITY}
                              error={touched.city && errors.city}
                              backgroundLabel="dark-9"
                            />
                          </Box>
                          <Box width="75%">
                            <BoxTextField margin={{bottom: 'small'}}>
                              {' '}
                              <TextInput1
                                type="city"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                color="dark"
                                placeholder={SECURITY_MESSAGES.CITY}
                                value={values.city}
                              />
                            </BoxTextField>
                          </Box>
                        </Box>
                        <Box direction="row">
                          <Box width="25%">
                            <FormField
                              name="state"
                              label={SECURITY_MESSAGES.STATE}
                              error={touched.state && errors.state}
                              backgroundLabel="dark-9"
                            />
                          </Box>
                          <Box width="75%">
                            <BoxTextField margin={{bottom: 'small'}}>
                              <StyledSelect
                                options={[
                                  'New South Wales',
                                  'Victoria',
                                  'Queensland',
                                  'Tasmania',
                                  'South Australia',
                                  'Western Australia',
                                  'Northern Territory',
                                  'Australian Capital Territory',
                                ]}
                                value={values.state}
                                dropHeight="small"
                                size="small"
                                placeholder={SECURITY_MESSAGES.STATE}
                                onChange={({option}) =>
                                  setFieldValue('state', option)
                                }
                              />
                            </BoxTextField>
                          </Box>
                        </Box>

                        <Box direction="row">
                          <Box width="25%">
                            <FormField
                              name="postcode"
                              label={SECURITY_MESSAGES.POSTCODE}
                              error={touched.postcode && errors.postcode}
                              backgroundLabel="dark-9"
                              required={'yes'}
                            />
                          </Box>
                          <Box width="75%">
                            <BoxTextField margin={{bottom: 'small'}}>
                              {' '}
                              <TextInput1
                                type="postcode"
                                name="postcode"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                color="dark"
                                placeholder={SECURITY_MESSAGES.POSTCODE}
                                value={values.postcode}
                              />
                            </BoxTextField>
                          </Box>
                        </Box>
                        <Box direction="row">
                          <Box width="25%">
                            <FormField
                              name="country"
                              label={SECURITY_MESSAGES.COUNTRY}
                              error={touched.country && errors.country}
                              backgroundLabel="dark-9"
                              // required={'yes'}
                            />
                          </Box>
                          <Box width="75%">
                            <BoxTextField margin={{bottom: 'small'}}>
                              <StyledSelect
                                options={['Australia']}
                                value={values.country}
                                dropHeight="small"
                                size="small"
                                placeholder={SECURITY_MESSAGES.COUNTRY}
                                onChange={({option}) =>
                                  setFieldValue('country', option)
                                }
                              />
                            </BoxTextField>
                          </Box>
                        </Box>
                      </Box>
                      <Box align="end" margin={{top: 'small', bottom: 'small'}}>
                        <ButtonStyle
                          primary
                          disabled={isSubmitting}
                          label={SECURITY_MESSAGES.SECURITY_ADDRESS_SUBMIT}
                          type="submit"
                        />
                      </Box>
                      <Text as="div" color="status-critical">
                        {errors.non_field}
                      </Text>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </LayerStyle>
      )}
      {securityAddressFormVisibility.type === 'Delete' && (
        <LayerStyleDelete
          position="center"
          onEsc={() =>
            setsecurityAddressFormVisibility(
              !securityAddressFormVisibility.show,
            )
          }
          onClickOutside={() =>
            setsecurityAddressFormVisibility(
              !securityAddressFormVisibility.show,
            )
          }
        >
          <Box pad={{bottom: 'small'}}>
            <Box width="100%" height="100%">
              <MoreDetailsWrapper direction="row" justify="between">
                <MoreDetailsTextWrapper
                  color="dark-6"
                  size="16px"
                  margin={{left: '20px', top: '15px', bottom: '15px'}}
                >
                  {SECURITY_MESSAGES.DELETE_SECURITY}
                </MoreDetailsTextWrapper>

                <Button
                  margin={{right: 'small', bottom: 'small'}}
                  icon={
                    <Image src={process.env.PUBLIC_URL + '/Images/Cross.png'} />
                  }
                  onClick={() =>
                    setsecurityAddressFormVisibility(
                      !securityAddressFormVisibility.show,
                    )
                  }
                />
              </MoreDetailsWrapper>

              <Box
                margin={{
                  top: 'small',
                  bottom: 'small',
                  left: 'small',
                  right: 'small',
                }}
              >
                <Box
                  margin={{
                    top: 'small',
                    left: 'small',
                    right: 'small',
                    bottom: 'small',
                  }}
                  direction="column"
                  gap="medium"
                  pad={{bottom: 'medium'}}
                >
                  <Text>
                    {SECURITY_MESSAGES.SECURITY_ADDRESS_DELETE_WARNING_MESSAGE}
                  </Text>
                </Box>

                <Box
                  pad={{right: 'small'}}
                  gap="medium"
                  fill="horizontal"
                  direction="row"
                  elevation="none"
                  justify="end"
                  margin={{top: 'medium'}}
                >
                  <Button
                    alignSelf="end"
                    label={SECURITY_MESSAGES.SECURITY_ADDRESS_DELETE}
                    onClick={() =>
                      handledeleteSecurity(securityAddressFormVisibility)
                    }
                    primary
                  />
                  <Button
                    onClick={() => handleCanceldeleteSecurity()}
                    label={SECURITY_MESSAGES.SECURITY_ADDRESS_CANCEL}
                    primary
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </LayerStyleDelete>
      )}
    </>
  )
}

export default SecurityAddressForm
