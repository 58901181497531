//Library imports
import React, {useState, useEffect} from 'react'
import {Box} from 'grommet'
import {connect} from 'react-redux'
import queryString from 'query-string'
import {useHistory, useLocation} from 'react-router-dom'

//Core imports
import EventEmitter from 'utils/event-emitter'
import Toast from 'granite/components/Toast'
import Header from 'granite/components/HeaderWithDropDown'
import TabComponent from 'granite/components/TabComponent'

//Application imports
import * as UserDucks from 'accounts/ducks/user'
import ReviewTable from 'pages/review/components/ReviewTable'
import {fetchOutcomeData, campaignListcommon} from 'alr/controllers/loanReview'
import REVIEW_MESSAGES from 'messages/review'
import CampaignInfo from './components/CampaignInfo'
import REVIEW_EVENTS from './constants'
import MoreDetails from 'pages/contacting/components/MoreDetails'
import MailTemplate from './components/mailmodal'
import RiskRate from 'pages/alr/components/RiskRate'
import BackNavigation from 'granite/components/BackNavigation'

let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setData,
  setCampaignDetails,
  setLoader,
  setMoreData,
  setMoreLoader,
  setSavings,
  setselectcampaignlist,
  setMoreDataEdit,
  setTabs,
  setRisk,
  setmaillist,
  settemplatename,
  settemplatecontent,
  filterData,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case REVIEW_EVENTS.GET_RISK_SUCCESS:
        setRisk(event.data)
        break

      case REVIEW_EVENTS.GET_RISK_FAILURE:
        break

      case REVIEW_EVENTS.GET_CAMPAIGN_SUCCESS:
        setCampaignDetails(event.data)
        break
      case REVIEW_EVENTS.GET_CAMPAIGN_FAILURE:
        break
      case REVIEW_EVENTS.GET_LOAN_DETAILS_SUCCESS:
        setTabs({
          new: event.data.tabs_count.new,
          contacting: event.data.tabs_count.contacting,
          Negotiate_Pricing: event.data.tabs_count.Negotiate_Pricing,
          completed: event.data.tabs_count.completed,
          Contact_Client_Of_Result: event.data.tabs_count.Contact_Client_Of_Result,
        })
        setData({data: event.data.loanData, total: event.data.rowsCount})
        setLoader(false)
        break

      case REVIEW_EVENTS.GET_LOAN_DETAILS_FAILURE:
        break

      case REVIEW_EVENTS.GET_MORE_DETAILS_SUCCESS:
        setMoreData(event.data.moreDetails)
        setMoreDataEdit(event.data)
        setMoreLoader(false)
        break
      case REVIEW_EVENTS.GET_MORE_DETAILS_FAILURE:
        break

      case REVIEW_EVENTS.SHOW_LOADER:
        setLoader(true)
        break
      case REVIEW_EVENTS.HIDE_LOADER:
        setLoader(false)
        break

      case REVIEW_EVENTS.STATUS_CHANGED_SUCCESS:
        setToastData({
          open: true,
          message: `Client(s) Marked Contacted Successfully`,
        })
        const obj = {
          // params: {page: 1, page_size: 20},
          params: filterData
        }
        fetchOutcomeData(eventEmitter, obj, filterData.campaign_id)
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)

        break
      case REVIEW_EVENTS.STATUS_CHANGED_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : REVIEW_MESSAGES.STATUS_SAVE_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case REVIEW_EVENTS.GET_SAVINGS_SUCCESS:
        setSavings(event.data)
        break
      case REVIEW_EVENTS.GET_SAVINGS_FAILURE:
        break

      //OUTCOME_MAIL_SUCCESS
      case REVIEW_EVENTS.GET_MAIL_TEMPLATE_LIST_SUCCESS:
          const temparr = []
          const templatenamearr = {}
          const tempcontentarr = {}
          if (event.data.length > 0) {
            event.data.forEach(item => {
              temparr.push(item.email_template.name)
              templatenamearr[item.email_template.name] = item.activity_name
            })
          }
          if (event.data.length > 0) {
            event.data.forEach(item => {
              tempcontentarr[item.email_template.name] =
                item.email_template.content
            })
          }
          settemplatecontent(tempcontentarr)
          setmaillist(temparr)
          settemplatename(templatenamearr)
          break
      case REVIEW_EVENTS.GET_MAIL_TEMPLATE_LIST_FAILURE:
        break
      case REVIEW_EVENTS.SEND_MAIL_SUCCESS:
        setToastData({
          open: true,
          message: REVIEW_MESSAGES.SEND_MAIL_SUCCESS,
          background: '',
        })
        const object = {
          params: filterData,
        }
        fetchOutcomeData(eventEmitter, object, filterData.campaign_id)
        setTimeout(() => {
          setToastData({
            open: false,
            message: '',
          })
        }, 5000)
        break
      case REVIEW_EVENTS.SEND_MAIL_FAILURE:
        setToastData({
          open: true,
          message: REVIEW_MESSAGES.SEND_MAIL_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({
            open: true,
            message: event.data
              ? event.data
              : REVIEW_MESSAGES.SEND_MAIL_FAILURE,
            background: 'warning',
          })
        }, 5000)
          break
      case REVIEW_EVENTS.GET_CAMPAIGN_LIST_SUCCESS:
        setselectcampaignlist(event.data)
        break
      case REVIEW_EVENTS.GET_CAMPAIGN_LIST_FAILURE:
        break
      default:
        break
    }
  })
  return subscription
}

const Review = ({userProfile}) => {
  const [tabs, setTabs] = useState({
    new: 0,
    contacting: 0,
    Negotiate_Pricing: 0,
    completed: 0,
    Contact_Client_Of_Result: 0,
  })
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [data, setData] = useState({data: [], total: ''})
  const [campaignDetails, setCampaignDetails] = useState()
  const [risk, setRisk] = useState({})
  const [loader, setLoader] = useState(false)
  const [moreDetailLayer, setMoreDetailLayer] = useState(false)
  const [moreData, setMoreData] = useState([])
  const [moreLoader, setMoreLoader] = useState(false)
  const [savings, setSavings] = useState()
  const [rowsPk, setRowsPk] = useState([])
  const [erroredRowPk, setErroredRowPk] = useState()
  const [moreDataEdit, setMoreDataEdit] = useState()
  const [selectedapplicants, setselectedapplicants] = useState([])
  const [clientfirstname, setclientfirstname] = useState('')
  const [applicantids, setapplicantids] = useState([])
  const [applicantsemail, setapplicantsemail] = useState()
  const [showmailmodal, setshowmailmodal] = useState(false)
  const [templatename, settemplatename] = useState()
  const [tempcontent, settemplatecontent] = useState('')
  const [maillist, setmaillist] = useState([
    'Notify to Review ALR',
    'No Action Required ALR',
    'Rate Reduction ALR Email',
    'Interest / Fixed Expiry Template',
    'Missed Call Template',
    'Actions to be completed ALR Email',
    'Recently Dealt with Indigo ALR Email',
  ])
  const [selectcampaignlist, setselectcampaignlist] = useState({
    campaignData: '',
    temp: [],
  })
  const handleRefresh = () => {
    setLoader(true)
    // setRowsPk([])
    // setErroredRowPk()
    setStatusValue('Closed')
    setSearchValue('')
    navigateToParams({
      page: 1,
      page_size: 20,
      search: null,
      status: 'Closed',
      sorting: 'name'
      // outcome: null,
    })
  }

  const handleApplicantClick = selectedClient => {
    let campaign_id = filterData.campaign_id ? filterData.campaign_id : null
    let month_year = filterData.month_year ? filterData.month_year : null
    history.push(
      `/contactclientofresult/${selectedClient.applicant_pk}?campaign_id=${campaign_id}&month_year=${month_year}`,
    )
  }

  let defaultFilterData = {
    page: 1,
    page_size: 20,
    select_page: 1,
    select_page_size: 20,
    status: 'Closed',
    sorting: 'name'
  }

  let location = useLocation()
  const history = useHistory()
  const {search} = location

  let queryParam = queryString.parse(search)
  const [statusValue, setStatusValue] = useState(
    queryParam.status ? queryParam.status : '',
  )
  const [searchValue, setSearchValue] = useState(
    queryParam.search ? queryParam.search : '',
  )
  const [selcetdcampaign, setselcetdcampaign] = useState(
    queryParam.month_year ? queryParam.month_year : '',
  )

  Object.keys(queryParam).forEach(key => {
    if (!isNaN(parseInt(queryParam[key]))) {
      defaultFilterData[key] = parseInt(queryParam[key])
    } else defaultFilterData[key] = queryParam[key]
  })
  const [filterData, setFilterData] = useState(defaultFilterData)
  const navigateToParams = params => {
    const queryParams = queryString.parse(search)
    let pathname = location.pathname
    let pathObj = {
      pathname,
      search: queryString.stringify({
        ...queryParams,
        ...params,
      }),
    }
    setFilterData({...filterData, ...params})
    history.push(pathObj)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setData,
        setCampaignDetails,
        setLoader,
        setMoreData,
        setMoreLoader,
        setSavings,
        setselectcampaignlist,
        setMoreDataEdit,
        setTabs,
        setRisk,
        setmaillist,
        settemplatename,
        settemplatecontent,
        filterData,
      })
      const obj = {
        params: filterData,
      }

      fetchOutcomeData(eventEmitter, obj, filterData.campaign_id)
      let select_params = {
        page: filterData.select_page,
        page_size: filterData.select_page_size,
      }
      campaignListcommon(eventEmitter, 'Outcome', select_params)
      // return () => {
      //   subscription.unsubscribe()
      // }
      return subscription
    }
    let temp = init()
    return () => {
      temp.unsubscribe()
    }
  }, [location, filterData])

  return (
    <Box flex={{grow: 1}}>
      <Header
        pageTitle="Loan Review / Contact Client Of Result"
        userName={`${userProfile.title} ${userProfile.name}`}
        showSavings={true}
        handleRefresh={handleRefresh}
        alrSavings={savings ? savings.alrSavings : ''}
        totalSavings={savings ? savings.totalSavings : ''}
      />

      <Box overflow={{vertical: 'auto'}} fill pad="medium">
        <BackNavigation label={'Back'} />

        <Box direction="row-responsive" width="100%" gap="small">
          <Box width="75%">
            <CampaignInfo
              campaignDetails={campaignDetails}
              selcetdcampaign={selcetdcampaign}
              setselcetdcampaign={setselcetdcampaign}
              selectcampaignlist={selectcampaignlist}
              history={history}
              navigateToParams={navigateToParams}
              filterData={filterData}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
          </Box>
          <Box width="25%" background="light-3">
            <RiskRate risk={risk} />
          </Box>
        </Box>

        <TabComponent stats={tabs} history={history} />
        <Box>
        {showmailmodal && (
          <MailTemplate
            setshowmailmodal={setshowmailmodal}
            showmailmodal={showmailmodal}
            event={eventEmitter}
            loggedinuser={userProfile.email}
            client={applicantsemail}
            clientfirstname={clientfirstname}
            sendername={userProfile.name}
            designation={userProfile.designation}
            maillist={maillist}
            templatename={templatename}
            tempcontent={tempcontent}
            applicantids={applicantids}
            selectedapplicants={selectedapplicants}
            campaignId={filterData.campaign_id}
          />
        )}
          <ReviewTable
            data={data}
            eventEmitter={eventEmitter}
            loader={loader}
            setMoreDetailLayer={setMoreDetailLayer}
            setData={setData}
            setMoreLoader={setMoreLoader}
            navigateToParams={navigateToParams}
            filterData={filterData}
            statusValue={statusValue}
            setStatusValue={setStatusValue}
            handleApplicantClick={handleApplicantClick}
            setToastData={setToastData}
            rowsPk={rowsPk}
            setRowsPk={setRowsPk}
            erroredRowPk={erroredRowPk}
            setErroredRowPk={setErroredRowPk}
            setshowmailmodal={setshowmailmodal}
            setapplicantsemail={setapplicantsemail}
            setapplicantids={setapplicantids}
            setclientfirstname={setclientfirstname}
            campaignId={filterData.campaign_id}
            setselectedapplicants={setselectedapplicants}
          />
        </Box>
        {moreDetailLayer && (
          <MoreDetails
            setMoreDetailLayer={setMoreDetailLayer}
            moreData={moreData}
            moreLoader={moreLoader}
            moreDataEdit={moreDataEdit}
          />
        )}
      </Box>
      <Box>
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}
const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(Review)
