//Library imports
import React, {useState} from 'react'
import {
  Box,
  Image,
  Select,
  CheckBox,
  TextInput,
  Text,
  ThemeContext,
  Button,
  RadioButtonGroup,
} from 'grommet'
import styled from 'styled-components'
import queryString from 'query-string'
import {useLocation} from 'react-router-dom'
//Core imports
import TextCell from 'granite/components/Table/TextCell'
import ReactTable from 'granite/components/Table'
import LoaderUI from 'granite/components/NumLoader'
import Pagination from 'granite/components/Pagination'

//Application imports
import {loadClientLoanDetails} from 'alr/controllers/loanReview'
import {addApplicant} from 'clientloandetail/controllers/loan'
import Form from './addApplicantForm'
const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
`

const TextInputWrapper = styled(TextInput)`
  border-bottom: none;
  padding-bottom: 8px !important
  padding-right: 8px !important
  padding-top: 8px !important;
  color:#000000;
`

const SearchBoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #b8b8b8;
  border-radius-right: 4px !important;
  padding-left: 14px !important;
`
const ButtonStyle = styled(Button)`
height:36px
width:280px
`
const ButtonStyleSearch = styled(Button)`
  height: 35px;
  width: 110px;
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
`
const RadioButtonGroupWrap = styled(RadioButtonGroup)`
  flex-direction: row;
  row-gap: large;
`

const SelectWrapper = styled(Select)`
  padding-left: 14px !important;
  height: 29px;
  width: 130px;
`
// const headerValue = {
//   checkBox: {
//     border: {
//       color: {
//         light: 'white',
//         dark: 'white',
//       },
//     },
//     color: 'black',
//     size: '18px',
//   },
// }

const bodyValue = {
  checkBox: {
    border: {
      color: {
        light: 'grey',
        dark: 'white',
      },
    },
    color: 'black',
    size: '18px',
  },
}
const bodyValueSelect = {
  select: {
    options: {
      text: {
        size: 'small',
      },
    },
  },
}
const COLUMNS = [
  // {
  //   accessor: 'resdd',
  //   Header: 'Radio Group',
  //   // optional: to render own custom component for table body cell
  //   Cell: ({row, handleRadio, selectedrow}) => {
  //     let option = row.original.user_name
  //     let value = selectedrow
  //     return (
  //       <RadioButtonGroup
  //         name="doc"
  //         options={[option]}
  //         value={value}
  //         onClick={() => {
  //           handleRadio(row)
  //         }}
  //       />
  //     )
  //   },
  //   // ,
  //   // disableSortBy: true,
  // },
  // {
  //   accessor: 'selection',
  //   Header: ({getToggleAllRowsSelectedProps}) => (
  //     <span title={'Select All'}>
  //       <ThemeContext.Extend value={headerValue}>
  //         <CheckBox {...getToggleAllRowsSelectedProps()} />
  //       </ThemeContext.Extend>
  //     </span>
  //   ),
  //   // optional: to render own custom component for table body cell
  //   Cell: ({row}) => (
  //     <span title={'Select'}>
  //       <Box align="center">
  //         <ThemeContext.Extend value={bodyValue}>
  //           <CheckBox {...row.getToggleRowSelectedProps()} />
  //         </ThemeContext.Extend>
  //       </Box>
  //     </span>
  //   ),
  //   disableSortBy: true,
  // },

  {
    accessor: 'new_select',
    Header: '',
    // align : "end",
    Cell: ({
      cell: {value, row},
      // customerId,
      // handleRadio,
      handelNewSelect,
      selectedrow,
      ...rest
    }) => {
      // let option = row.original.user_name
      // let radiovalue = selectedrow
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <ThemeContext.Extend value={bodyValue}>
                <CheckBox
                  checked={row.original.pk === selectedrow}
                  onClick={event => {
                    handelNewSelect(row)
                  }}
                />
              </ThemeContext.Extend>
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="12px"
          style={{fontWeight: '500'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'user_name',
    Header: 'Name',
    // align : "end",
    Cell: ({
      cell: {value, row},
      customerId,
      handleRadio,
      selectedrow,
      ...rest
    }) => {
      let option = row.original.user_name
      let radiovalue = selectedrow
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              {false && (
                <RadioButtonGroupWrap
                  name="doc"
                  options={[option]}
                  value={radiovalue}
                  // gap="medium"
                  // pad="small"
                  onClick={() => {
                    handleRadio(row)
                  }}
                />
              )}

              <Text color="dark-6" size="14px">
                {value}
              </Text>
              <Text color="text-1" size="10px">
                {value}
              </Text>
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="12px"
          style={{fontWeight: '500'}}
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'user_phone',
    Header: 'Phone No.',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={
            <Box>
              <Text color="dark-6" size="14px">
                {value}
              </Text>
              {false && (
                <Text color="text-1" size="10px">
                  {value}
                </Text>
              )}
            </Box>
          }
          editMode={false}
          color="dark-6"
          size="12px"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'user_email',
    Header: 'Email Address',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="blue-email"
          size="14px"
          style={{textDecoration: 'underline'}}
        />
      )
    },
    disableSortBy: true,
  },
  //client_type
  {
    accessor: 'client_type',
    Header: 'Client Type',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'company_name',
    Header: 'Company Name',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },

  {
    accessor: 'company_ABN',
    Header: 'ABN',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          size="14px"
          color="dark-6"
        />
      )
    },
    disableSortBy: true,
  },
  {
    accessor: 'review_cycle',
    Header: 'Review Cycle',
    Cell: ({cell: {value}, ...rest}) => {
      return (
        <TextCell
          {...rest}
          data={value}
          editMode={false}
          color="dark-6"
          size="14px"
        />
      )
    },
    disableSortBy: true,
  },
]

const ClientTable = ({
  setLayer,
  eventEmitter,
  loader,
  customerId,
  setNewCustomerId,
  navigateToParams,
  data: {data, total},
  setselectedclient,
  selectedclient,
  setSearchValue,
  searchValue,
  handleSearchClicked,
  handleSearchCancel,
  filterData: {page, page_size: size},
  route,
  history,
  setToastData,
}) => {
  const [selected, setSelected] = useState([])
  const [selectedrow, setselectedrow] = useState(2033)
  const [type, setType] = useState('')
  const location = useLocation()
  const [markprimary, setmarkprimary] = useState(false)
  const filterqueryParams = queryString.parse(location.search)
  const [client_type, setclient_type] = useState(
    filterqueryParams.client_type === 'CO'
      ? 'Company'
      : filterqueryParams.client_type === 'IN'
      ? 'Individual'
      : '',
  )
  const applicantType = {
    BORROWER: 'B',
    GUARANTOR: 'G',
    TRUSTEE: 'TT',
    SIGNATORY: 'SG',
    SUPERFUND: 'SF',
  }

  const handleRadio = props => {
    setselectedrow(props.original.user_name)
    setSelected([props.original])
  }
  const handelNewSelect = props => {
    setselectedrow(props.original.pk)
    setSelected([props.original])
  }
  const handleGetClientLoans = id => {
    setNewCustomerId(id)
    setLayer(true)
    loadClientLoanDetails(eventEmitter, id, month)
  }
  const handlePagination = ({page, size: page_size}) => {
    navigateToParams({page, page_size})
  }

  const handleSort = props => {
    if (props === 'Individual') {
      navigateToParams({client_type: 'IN'})
    } else if (props === 'Company') {
      navigateToParams({client_type: 'CO'})
    }
  }

  const date = new Date()
  const month = date.getMonth()

  const getInitialSorting = () => {
    const queryParams = queryString.parse(location.search)
    if (queryParams['ordering']) {
      let colName
      let desc
      if (queryParams['ordering'].indexOf('-') === 0) {
        colName = queryParams['ordering'].substring(1)
        desc = true
      } else {
        colName = queryParams['ordering'].substring(0)
        desc = false
      }
      return [{id: colName, desc}]
    }
    return []
  }

  const onSubmit = (values, {setSubmitting, setErrors}) => {
    const checkconditions = validatedetails(values)
    if (checkconditions) {
      let payload = []
      selected.forEach(item => {
        payload.push({
          applicant: item.pk,
          loan: route.params.loanid,
          applicant_type: applicantType[values.type],
          is_primary_applicant: markprimary,
          contact_person: values.contact_person,
          designation: values.designation,
        })
      })
      addApplicant(
        eventEmitter,
        selected,
        route.params.clientid,
        route.params.loanid,
        payload,
        history,
      )
    } else {
      setSubmitting(false)
    }
  }

  function validatedetails(values) {
    if ((client_type === 'Company' || markprimary) && selected.length > 1) {
      setToastData({
        open: true,
        message: 'Only one applicant can be selected.',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 5000)
      return false
    } else if (
      client_type === 'Individual' &&
      markprimary &&
      selected.length > 1
    ) {
      setToastData({
        open: true,
        message: 'Only one applicant can be selected.',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 5000)
      return false
    } else if (client_type === '' && markprimary && selected.length > 1) {
      setToastData({
        open: true,
        message: 'Only one applicant can be selected.',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 5000)
      return false
    } else if (
      client_type === 'Company' &&
      (values.contact_person.length === 0 || values.designation.length === 0)
    ) {
      setToastData({
        open: true,
        message: 'Please enter contact person and designation.',
        background: 'warning',
      })
      setTimeout(() => {
        setToastData({
          open: false,
          message: '',
        })
      }, 5000)
      return false
    } else {
      return true
    }
  }

  return (
    <Box>
      {loader ? (
        <Box align="center">
          <LoaderUI />
        </Box>
      ) : (
        <>
          <Box
            gap="small"
            direction="row"
            justify="between"
            width="100%"
            margin={{top: 'medium'}}
            //  border={{color: 'red', size: 'small'}}
          >
            <Box
              gap="medium"
              direction="row"
              width="40%"
              // border={{color: 'green', size: 'small'}}
            >
              <SearchBoxWrapper
                width="100%"
                direction="row"
                height="36px"
                align="end"
                round="4px"
              >
                <TextInputWrapper
                  placeholder=" Name, Phone No., Email Search"
                  value={searchValue}
                  onChange={event => {
                    setSearchValue(event.target.value)
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      handleSearchClicked()
                    }
                  }}
                />
                <Button
                  alignSelf="center"
                  icon={
                    <Image
                      src={process.env.PUBLIC_URL + '/img/search_cancel.png'}
                      fit="contain"
                    />
                  }
                  onClick={handleSearchCancel}
                />
                <ButtonStyleSearch
                  type="submit"
                  label={'Search'}
                  onClick={() => handleSearchClicked()}
                  primary
                />
              </SearchBoxWrapper>
            </Box>
            <Box
              alignSelf="start"
              // border = {{color:'brand', size:'small'}}
              width="60%"
              align="start"
              margin={{bottom: 'medium', left: 'large'}}
              direction="row"
              gap="medium"
            >
              <Box border={{color: 'round-border', size: '1px'}} round="4px">
                <ThemeContext.Extend value={bodyValueSelect}>
                  <SelectWrapper
                    options={['Individual', 'Company']}
                    placeholder={'Client Type'}
                    value={client_type}
                    onChange={({option}) => {
                      setclient_type(option)
                      handleSort(option)
                    }}
                    // size="small"
                  />
                </ThemeContext.Extend>
              </Box>
              {false && (
                <Box border={{color: 'round-border', size: '1px'}} round="4px">
                  <SelectWrapper
                    options={[
                      'BORROWER',
                      'GUARANTOR',
                      'TRUSTEE',
                      'SIGNATORY',
                      'SUPERFUND',
                    ]}
                    // size="small"
                    value={type}
                    placeholder={'Type'}
                    onChange={({option}) => setType(option)}
                  />
                </Box>
              )}

              {false && (
                <ButtonStyle
                  primary
                  color={selected.length ? 'brand' : 'text-1'}
                  disabled={selected.length > 0 ? false : true}
                  label="Associate Client to Loan"
                  onClick={() => {
                    // handleAdd()
                  }}
                />
              )}
            </Box>
          </Box>

          <Box direction="row" pad={{bottom: 'medium'}}>
            <Form
              onSubmit={onSubmit}
              selected={selected}
              client_type={client_type}
              setmarkprimary={setmarkprimary}
              markprimary={markprimary}
            />
          </Box>

          <BoxWrapper overflow={{horizontal: 'auto'}}>
            <Box>
              <ReactTable
                initialSortOrder={getInitialSorting()}
                data={data}
                columns={COLUMNS} // pass in our table columns
                setSelected={setSelected} // sets selected row information
                onSortChange={handleSort}
                handleGetClientLoans={handleGetClientLoans}
                customerId={customerId}
                handleRadio={handleRadio}
                selectedrow={selectedrow}
                handelNewSelect={handelNewSelect}
              />
            </Box>
          </BoxWrapper>
        </>
      )}
      {data.length > 0 ? (
        <Box direction="row" width="100%" margin={{top: 'medium'}}>
          <Box width="50%" alignContent="end" align="start">
            {/* <Text color="dark-6">
              Page {page} out of {Math.ceil(total / size)}
            </Text> */}
            <Text color="dark-6">
              {total < size ? total + ' out of ' + total : data.length + ' out of ' + total}
            </Text>
          </Box>
          <Box width="50%" alignContent="end" align="end">
            <Pagination
              onPageChange={handlePagination}
              showSizeChanger
              pageData={{page, size, total}}
              dataLength={data.length}
              select={true}
            />
          </Box>
        </Box>
      ) : (
        ''
      )}
    </Box>
  )
}

export default ClientTable
