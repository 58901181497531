//Library imports
import React, {useState} from 'react'
import {Box, Layer} from 'grommet'
import styled from 'styled-components'

//Core imports
import Form from './mailform'

//Application imports
import {mailClientInfoClosed} from 'alr/controllers/loanReview'
// import {GETFIRSTNAME} from 'granite/name_split'

const LayerStyle = styled(Layer)`
  width: 50%;
`

const MailTemplate = ({
  event,
  route,
  showmailmodal,
  setshowmailmodal,
  maillist,
  loggedinuser,
  client,
  clientfirdtname,
  sendername,
   designation,
  templatename,
  applicantsids,
  templatecontent,


}) => {
  const [view, setView] = useState(true)

  const onSubmit = (values, {setSubmitting, setErrors}) => {
    setErrors({})
    setSubmitting(true)
    mailClientInfoClosed(
      event,
      {...values},
      templatename[values.subject],
      applicantsids,
    )
    setSubmitting(false)
    setView(!view)
    setshowmailmodal(false)
  }

  return (
    <Box>
      <LayerStyle
        position="center"
        onEsc={() => setshowmailmodal(false)}
        // onClickOutside={() => setshowmailmodal(false)}
      >
        <Box
          width="100%"
          pad={{bottom: 'small'}}
          //   border={{color: 'red', size: 'small'}}
        >
          <Form
            onSubmit={onSubmit}
            setshowmailmodal={setshowmailmodal}
            loggedinuser={'clientservices@indigofinance.com.au'}
            client={client}
            clientfirdtname={clientfirdtname}
            sendername={sendername}
            designation={designation}
            maillist={maillist}
            showmailmodal={showmailmodal}
            templatecontent={templatecontent}
          ></Form>
        </Box>
      </LayerStyle>
    </Box>
  )
}

export default MailTemplate
