import React, {useState, useEffect} from 'react'
import {Text, Box, Button} from 'grommet'
import styled from 'styled-components'
import Slider from 'granite/components/Slider'
import * as UserDucks from 'accounts/ducks/user'
import {connect} from 'react-redux'
import {riskRateClicked, saveRiskRateClicked} from 'alr/controllers/risk'
import Toast from '../../granite/components/Toast'
import RISK_RATE_MESSAGES from 'messages/riskRate'
import RISK_RATE_EVENTS from './constants'
import EventEmitter from 'utils/event-emitter'
import NewHeader from 'granite/components/HeaderWithDropDown'
import LoaderUI from 'granite/components/NumLoader'

const BoxWrapper = styled(Box)`
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
  border-top: 2px solid #37236a;
`
const TextWrapper = styled(Text)`
  font-weight: 500;
`

const ButtonStyle = styled(Button)`
  height: 36px;
  width: 110px;
`
let eventEmitter = new EventEmitter()
function listenEvents({
  eventEmitter,
  setToastData,
  setRisk,
  setLoader,
  setchange,
}) {
  const observable = eventEmitter.getObservable()
  const subscription = observable.subscribe(event => {
    switch (event.type) {
      case RISK_RATE_EVENTS.GET_RISK_SUCCESS:
        setRisk(event.data)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)

        break

      case RISK_RATE_EVENTS.GET_RISK_FAILURE:
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.GET_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        setTimeout(() => {
          setLoader(false)
        }, 1000)
        break

      case RISK_RATE_EVENTS.SAVE_RISK_SUCCESS:
        setchange(false)
        setToastData({
          open: true,
          message: RISK_RATE_MESSAGES.SAVE_RISK_SUCCESS,
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break

      case RISK_RATE_EVENTS.SAVE_RISK_FAILURE:
        setchange(false)
        setToastData({
          open: true,
          message: event.data
            ? event.data
            : RISK_RATE_MESSAGES.SAVE_RISK_FAILURE,
          background: 'warning',
        })
        setTimeout(() => {
          setToastData({open: false, message: ''})
        }, 5000)
        break
      default:
        break
    }
  })
  return subscription
}

const Risk = ({userProfile}) => {
  const [loader, setLoader] = useState(true)
  const [editButtonColor, setEditButtonColor] = useState('brand')
  const [buttonColor, setButtonColor] = useState('dark-5')
  const defaultToastData = {open: false, message: '', background: ''}
  const [toastData, setToastData] = useState(defaultToastData)
  const [risk, setRisk] = useState({})
  const [riekRate, setriekRate] = useState({
    riskRate1: 0,
    riskRate2: 0,
    riskRate3: 0,
    riskRate4: 0,
  })
  const [change, setchange] = useState(false)

  const handleSaveButton = () => {
    saveRiskRateClicked(eventEmitter, risk, riekRate)
  }
  const handleCancelButton = () => {
    setchange(false)
    setriekRate({
      riskRate1: risk.oop_and_i,
      riskRate2: risk.oo_io,
      riskRate3: risk.inv_p_and_i,
      riskRate4: risk.inv_io,
    })
  }

  const handleRefresh = () => {
    setLoader(true)
    riskRateClicked(eventEmitter)
  }

  useEffect(() => {
    function init() {
      const subscription = listenEvents({
        eventEmitter,
        setToastData,
        setRisk,
        setLoader,
        setchange,
      })
      riskRateClicked(eventEmitter)
      return () => {
        subscription.unsubscribe()
      }
    }
    init()
  }, [])

  useEffect(() => {
    setriekRate({
      riskRate1: risk.oop_and_i,
      riskRate2: risk.oo_io,
      riskRate3: risk.inv_p_and_i,
      riskRate4: risk.inv_io,
    })
  }, [risk])

  const handleEditClicked = () => {
    setchange(true)
    setButtonColor('brand')
    setEditButtonColor('dark-5')
  }
  return (
    <Box flex={{grow: 1}}>
      <NewHeader
        pageTitle={'Risk Rate Settings'}
        userName={`${userProfile.title} ${userProfile.name}`}
        handleRefresh={handleRefresh}
      />
      <Box
        overflow={{vertical: 'auto'}}
        fill
        // border={{color: 'green', size: 'small'}}
      >
        {loader ? (
          <Box align="center" justify="center" fill>
            <LoaderUI />
          </Box>
        ) : (
          <Box
            width="100%"
            height="100%"
            pad={{
              top: 'none',
              bottom: 'none',
              left: 'medium',
              right: 'medium',
            }}
          >
            <Box
              width="100%"
              alignSelf="center"
              margin={{top: '25px', bottom: 'medium'}}
            >
              <Box alignSelf="end" margin={{bottom: 'medium'}}>
                <ButtonStyle
                  label="Edit"
                  color={editButtonColor}
                  primary
                  onClick={() => {
                    handleEditClicked()
                  }}
                  disabled={editButtonColor === 'dark-5' ? true : false}
                />
              </Box>
              <BoxWrapper
                width="100%"
                align="center"
                alignSelf="center"
                background="light-1"
              >
                <Box alignSelf="start">
                  <TextWrapper
                    size="14px"
                    margin={{top: 'small', left: 'small'}}
                    textAlign="start"
                    color="dark-1"
                  >
                    Risk Rate Settings
                  </TextWrapper>
                </Box>

                <Box direction="column">
                  <Box direction="row" margin={{top: 'medium'}}>
                    <Box
                      alignSelf="start"
                      margin={{right: 'medium'}}
                      width="10%"
                    >
                      <TextWrapper
                        size="12px"
                        //margin={{left: 'small'}}
                        alignText="start"
                        color="dark-1"
                      >
                        OOP & I
                      </TextWrapper>
                    </Box>
                    <Box width="501px" pad={{left: '4px'}}>
                      <Slider
                        toast={true}
                        background="comp-3"
                        thumb="comp-3"
                        height="7px"
                        thumbWidth="20px"
                        thumbHeight="20px"
                        value={parseFloat(riekRate.riskRate1)}
                        edit={change}
                        max={10.0}
                        step={0.01}
                        // showButtons
                        onChange={value => {
                          if (value <= 10) {
                            setriekRate({
                              riskRate1: value,
                              riskRate2: riekRate.riskRate2,
                              riskRate3: riekRate.riskRate3,
                              riskRate4: riekRate.riskRate4,
                            })
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box direction="row" margin={{top: 'medium'}}>
                    <Box
                      alignSelf="start"
                      margin={{right: 'medium'}}
                      width="10%"
                    >
                      <TextWrapper
                        size="12px"
                        //margin={{left: 'small'}}
                        alignText="start"
                        color="dark-1"
                      >
                        OO/IO
                      </TextWrapper>
                    </Box>
                    <Box width="501px" pad={{left: '4px'}}>
                      <Slider
                        toast={true}
                        background="comp-3"
                        thumb="comp-3"
                        height="7px"
                        thumbWidth="20px"
                        thumbHeight="20px"
                        max={10.0}
                        step={0.01}
                        value={parseFloat(riekRate.riskRate2)}
                        edit={change}
                        onChange={value => {
                          if (value <= 10) {
                            setriekRate({
                              riskRate1: riekRate.riskRate1,
                              riskRate2: value,
                              riskRate3: riekRate.riskRate3,
                              riskRate4: riekRate.riskRate4,
                            })
                          }
                        }}
                      />
                    </Box>
                  </Box>

                  <Box direction="row" margin={{top: 'medium'}}>
                    <Box
                      alignSelf="start"
                      margin={{right: 'medium'}}
                      width="10%"
                    >
                      <TextWrapper
                        size="12px"
                        //margin={{left: 'small'}}
                        alignText="start"
                        color="dark-1"
                      >
                        INV P&I
                      </TextWrapper>
                    </Box>
                    <Box width="501px" pad={{left: '4px'}}>
                      <Slider
                        toast={true}
                        background="comp-3"
                        thumb="comp-3"
                        height="7px"
                        thumbWidth="20px"
                        thumbHeight="20px"
                        max={10.0}
                        step={0.01}
                        value={parseFloat(riekRate.riskRate3)}
                        edit={change}
                        onChange={value => {
                          if (value <= 10) {
                            setriekRate({
                              riskRate1: riekRate.riskRate1,
                              riskRate2: riekRate.riskRate2,
                              riskRate3: value,
                              riskRate4: riekRate.riskRate4,
                            })
                          }
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    direction="row"
                    margin={{top: 'medium', bottom: 'medium'}}
                  >
                    <Box
                      alignSelf="start"
                      margin={{right: 'medium'}}
                      width="10%"
                    >
                      <TextWrapper
                        size="12px"
                        //margin={{left: 'small'}}
                        alignText="start"
                        color="dark-1"
                      >
                        INV IO
                      </TextWrapper>
                    </Box>
                    <Box width="501px">
                      <Slider
                        toast={true}
                        background="comp-3"
                        thumb="comp-3"
                        height="7px"
                        thumbWidth="20px"
                        thumbHeight="20px"
                        value={parseFloat(riekRate.riskRate4)}
                        edit={change}
                        max={10.0}
                        step={0.01}
                        onChange={value => {
                          if (value <= 10) {
                            setriekRate({
                              riskRate1: riekRate.riskRate1,
                              riskRate2: riekRate.riskRate2,
                              riskRate3: riekRate.riskRate3,
                              riskRate4: value,
                            })
                          }
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </BoxWrapper>
              <Box direction="row" gap="medium" alignSelf="end">
                <Box alignSelf="end" margin={{top: 'medium'}}>
                  <ButtonStyle
                    primary
                    label="Cancel"
                    color={buttonColor}
                    onClick={() => {
                      setButtonColor('dark-5')
                      setEditButtonColor('brand')
                      handleCancelButton()
                    }}
                    disabled={editButtonColor === 'brand' ? true : false}
                  />
                </Box>
                <Box alignSelf="end" margin={{top: 'medium'}}>
                  <ButtonStyle
                    primary
                    label="Save"
                    color={buttonColor}
                    onClick={() => {
                      handleSaveButton()
                      setButtonColor('dark-5')
                      setEditButtonColor('brand')
                    }}
                    disabled={editButtonColor === 'brand' ? true : false}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {toastData.open && (
          <Toast
            text={toastData.message}
            background={toastData.background}
            onClose={() => {
              setToastData({
                open: false,
                message: '',
              })
            }}
          />
        )}
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  userProfile: UserDucks.profile(state),
})

export default connect(mapStateToProps)(Risk)
