// Lib imports
import React, {useState, useEffect} from 'react'
import {Box, Button, CheckBox, Form, Layer, TextInput} from 'grommet'
import {FormSearch, FormTrash, List} from 'grommet-icons'

// Core imports

// Application imports

const TableFilter = ({PrevSearch, selected, onSearchChange, columns}) => {
  const [search, setSearch] = useState(PrevSearch)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!search && PrevSearch) onSearchChange({search: ''})
  }, [search, PrevSearch, onSearchChange])

  return (
    <>
      <Box direction="row" gap="small" justify="between">
        <Box size="small">
          <Form
            onSubmit={e =>
              search ? onSearchChange({search}) : e.preventDefault()
            }
          >
            <Box direction="row">
              <TextInput
                style={{width: '240px', fontSize: 'small'}}
                placeholder={'Search by name, email, phone'}
                type="search"
                size="small"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
              <Button type="submit" icon={<FormSearch />} />
            </Box>
          </Form>
        </Box>
        <Box direction="row" gap="medium">
          <span title={'Bulk delete'}>
            <Button
              disabled={!selected.length}
              onClick={() => {
                alert(`Number of rows selected ${selected.length}`)
            
              }}
              icon={<FormTrash />}
              color="brand"
              primary
            />
          </span>
          <span title={'show/hide columns'}>
            <Button onClick={() => setShow(true)} icon={<List />} />
          </span>
        </Box>
      </Box>
      {show && (
        <Layer
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          Show/Hide Columns
          <Box pad="large" gap="large">
            {columns.map(column => {
              if (column.id === 'selection' || column.id === 'actions') return null;
              return (
                <CheckBox
                  key={column.id}
                  label={`${column.Header}`}
                  toggle
                  disabled={column.id === 'firstName'}
                  {...column.getToggleHiddenProps()}
                />
              )
            })}
            <Button
              label="submit"
              onClick={() => {
                let hiddenColumns = columns
                  .filter(column => column.isVisible === false)
                  .map(column => column.id) // by default, id is same as column accessor,
                // but can be overridden if necessary.
                alert(`Number of hidden columns : ${hiddenColumns.length}`)

              }}
              primary
            />
          </Box>
        </Layer>
      )}
    </>
  )
}

export default TableFilter
