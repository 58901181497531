const ADD_BROKER_EVENTS = {

  GET_BROKER_SUCCESS: 'GET_BROKER_SUCCESS',
  GET_BROKER_FAILURE: 'GET_BROKER_FAILURE',

  ADD_BROKER_SUCCESS: 'ADD_BROKER_SUCCESS',
  ADD_BROKER_FAILURE: 'ADD_BROKER_FAILURE',

  UPDATE_BROKER_SUCCESS: 'UPDATE_BROKER_SUCCESS',
  UPDATE_BROKER_FAILURE: 'UPDATE_BROKER_FAILURE',

  DELETE_BROKER_SUCCESS: 'DELETE_BROKER_SUCCESS',
  DELETE_BROKER_FAILURE: 'DELETE_BROKER_FAILURE',
}
export default ADD_BROKER_EVENTS
